import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {MyProfileService} from '../../../../services/my-profile.service';
import {IAppConfig} from '../../../../config/app-config.interface';
import {APP_CONFIG, PROFILE_ARRAY, PROFILE_NAME_FROM_TYPE} from '../../../../config/app-config.constants';
import {isPlatformBrowser} from '@angular/common';
import {appConfig} from '../../../frontend/app.config';
//import { readlinkSync } from 'fs';

declare var $: any;

@Component({
    selector: 'upgrade-investor',
    templateUrl: './upgrade-investor.component.html',
    styles: [
        `
        .bx-payment-mode li { 
            list-style:none
        }
        .bx-payment-mode li{
            float:left;
            padding:0 10px;
        }
        .paymentOptions{
            padding: 10px 0;
        }
        .paymentOptions .require{
            color: #a94442;
        }
        .abr,.addondisable {
            position: absolute;
            width: 100%;
            background-color: rgba(0,0,0,.8);
            z-index: 9;
            height: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            padding: 40px 15px 10px;
        }
        `
    ]

})
export class UpgradeInvestorComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    ProfileSelected;
    unique_str;
    unique_profile;
    responsevalue;
    private isViewChecked = false;
    planDetails = [];
    addOnDetails = [];
    public profiles = PROFILE_NAME_FROM_TYPE;
    public couponResponse = {status: '', message: '', coupon_id: ''};
    isSubmitted = false;


    constructor(private myprofileService: MyProfileService,
                private http: HttpClient, route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        route.paramMap.subscribe(params => this.unique_str = params.get('userUniqueId'));
        route.paramMap.subscribe(params => this.unique_profile = params.get('profile'));
    }

    saveUpgradeAccount(data, formName) {
        const formSelector = $('form[name="' + formName + '"]');
        this.isSubmitted = true;
        const payment_mode = $("input[name='payment_mode']:checked").val();
        
        if (formName === 'upgradeSellerForm' && (formSelector.validator('validate').has('.has-error').length || !data.membership_plan || payment_mode==undefined)) {
            return;
        }
        data.user_id = this.userID;
        data.profile_type = this.ProfileSelected.profile_type;
        data.profile_id = this.ProfileSelected.profile_id;
        data.membership_plan = (data.membership_plan) ? data.membership_plan : 500; // addon plan
		data.payment_mode = $("input[name='payment_mode']:checked").val();
        formSelector.find('button[type="submit"]').button('loading');

        // Post values here
        this.http.post(this.config.apiEndpoint + '/upgrade/' + this.unique_str, data).subscribe(
            response => {
                //console.log('registration successfull');
                this.responsevalue = response;


            if(data.payment_mode==undefined || data.payment_mode==''){
                
                    appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
                        key: this.responsevalue.merchant_key,
                        hash: this.responsevalue.hash,
                        txnid: this.responsevalue.txnId,
                        surl: this.responsevalue.surl,
                        furl: this.responsevalue.furl,
                        curl: this.responsevalue.curl,
                        amount: this.responsevalue.amount,
                        firstname: this.responsevalue.firstname,
                        email: this.responsevalue.email,
                        phone: this.responsevalue.phone,
                        productinfo: this.responsevalue.productinfo,
                        city: this.responsevalue.city,
                        country: this.responsevalue.country
                    });

                }else if(data.payment_mode =='Paytm'){
                  let paytmData = this.responsevalue.paytmList;
                   appConfig.submitPayFORM('https://securegw.paytm.in/theia/processTransaction', 'POST',paytmData);

                }else{
                    appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
                        access_code: this.responsevalue.access_code,
                        encRequest: this.responsevalue.encRequest, 
                    });
                }

               

            },
            error => {
                console.log('registration failed');
                formSelector.find('button[type="submit"]').button('reset');
            });

    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
            this.getPlanDetails(this.userID);
        }
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="upgradeSellerForm"]').validator();
        }
    }

    getPlanDetails(userId): void {
        this.myprofileService.getPlanDetails(userId)
            .then((result) => {
                //console.log(result.plans)
                this.planDetails = result.plans;
                this.addOnDetails = result.addOns;
            });
    }


    submitCoupon(data) {
        const formSelector = $('form[name="upgradeSellerForm"]');
        if (data.coupon_code === '' || $('input[name="membership_plan"]:checked').length === 0) {
            this.couponResponse.status = 'error';
            this.couponResponse.message = (data.coupon_code === '') ? 'Please enter the coupon code' : 'Please choose a membership';
            return;
        }
        formSelector.find('.prombtn').button('loading');
        this.couponResponse.message = '';
        this.http.post(this.config.apiEndpoint + '/apply-coupon',
            {
                user_id: this.userID,
                coupon_code: data.coupon_code,
                membership: data.membership_plan,
                profile_type: +this.ProfileSelected.profile_type
            })
            .subscribe(
                (response: any) => {
                    this.couponResponse = response;
                    formSelector.find('.prombtn').button('loading');
                },
                error => {
                    this.couponResponse = error.error;
                    formSelector.find('.prombtn').button('reset');
                });
    }

    removeCoupon() {
        this.couponResponse.message = '';
        this.couponResponse.status = '';
        this.couponResponse.coupon_id = '';
    }

}
