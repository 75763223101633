import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import {MyProfileService} from '../../../../services/my-profile.service';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

    jwtHelper = new JwtHelperService();
    users: any;
    UserId;
    UserEmail;
    regmodel: any = {};
    token;
    decode;
    profileObjects;
    Profile;
    profileType;
    returnUrl: string;

    constructor(meta: Meta, title: Title, private http: HttpClient,
                private myprofileService: MyProfileService,
                route: ActivatedRoute, private router: Router,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Change Password - BusinessEx');

        this.checkLoginToken();
    }

    checkLoginToken(): void {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.UserId = this.decode.sub;
            this.UserEmail = this.decode.email;
        } else {
            this.Profile = [{
                'profile_id': 0,
                'profile_type': '',
                'profile_status': '',
                'profile_name': '',
                'profile_str': '',
                'payment_status': 0
            }];
            this.UserId = '';
            this.UserEmail = '';
        }
    }

    isLogout(): void {
        this.token = null;
        localStorage.removeItem('currentUser');
        this.returnUrl = '/';
        this.router.navigate([this.returnUrl]);
        setTimeout(function () {
            $('.msg-change-pwd').delay(0).slideToggle('slow');
            $('.msg-change-pwd').delay(5000).slideToggle('slow');

        }, 100);
    }

    changePasswordSubmit(data) {
        data.user_id = this.UserId;
        data.user_email = this.UserEmail;
        this.http.post(this.config.apiEndpoint + '/auth/changePassword/' + this.UserId, data).subscribe(
            data => {
                //console.log('Password Details Updated Successfully');
                $('#contactUsForm .form-control').val('');
                this.isLogout();
            },
            error => {
                //console.log('Please Enter Correct Details');
            });
    }

    ngOnInit() {

    }

}
