import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {IAppConfig} from '../../../../config/app-config.interface';
import {APP_CONFIG, DESIGNATION} from '../../../../config/app-config.constants';
import {isPlatformBrowser} from '@angular/common';
import {appConfig} from "../../../frontend/app.config";


declare var $: any;

@Component({
    selector: 'startup-team-details',
    templateUrl: './startup-team-details.component.html'
})
export class StartupTeamDetailsComponent implements OnInit, AfterViewChecked {

    profile_str;
    public startupTeamDetailsdata;
    public startupProfileSuccessError;
    public startupProfileFailError;
    private isViewChecked = false;
    designationinf = DESIGNATION;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Startup Team Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    startupMyAccountSubmit(data) {
        const formSelector = $('form[name="startupForm"]');
        formSelector.validator('update');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        data.startup_profile_str = this.profile_str;
        data.mgt_team_inf = appConfig.prepareTeamJson('#team_detail input');
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/updatestartupteam/' + this.profile_str, data).subscribe(
            data => {
                this.startupProfileFailError = "Startup Profile Updated Successfully";
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
            },
            error => {
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
                this.startupProfileFailError = "Please Enter Correct Details";
            });
    }

    getstartupTeamDetails(profile_str: any): void {
        this.myprofileService.getstartupTeamDetails(this.profile_str)
            .then((result) => {
                this.startupTeamDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getstartupTeamDetails(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="startupForm"]').validator();
        }
    }

}
