import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG, LOAN_INTEREST, REPAYMENT_PERIOD, SUPPORT_FIELD} from "../../../../config/app-config.constants";
import {IAppConfig} from "../../../../config/app-config.interface";
import {isPlatformBrowser} from "@angular/common";
import {ContactService} from "../../../../services/contact.service";
import {appConfig} from "../../../frontend/app.config";

declare var $: any;

@Component({
    selector: 'startup-requirement',
    templateUrl: './startup-requirement.component.html'
})
export class StartupRequirementComponent implements OnInit, AfterViewChecked {

    profile_str;
    public startupRequirementData;
    public startupProfileSuccessError;
    public startupProfileFailError;

    private isViewChecked = false;
    public isLookingFor = false;
    public mentorCategoryData;

    constructor(meta: Meta, title: Title, private http: HttpClient,
                private myprofileService: MyProfileService,
                route: ActivatedRoute, @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private contactService: ContactService) {
        title.setTitle('Startup Requirement - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    repayment_period = REPAYMENT_PERIOD;
    loan_interest = LOAN_INTEREST;
    support_period = SUPPORT_FIELD;

    startupMyAccountSubmit(data) {

        if ($('form[name="startupForm"]').validator('validate').has('.has-error').length || this.isLookingFor) {
            return;
        }
        if ($('input[name="seeking_mentorship"]').is(':checked') && $('#mentor_industry').val().length ===0) {
            $('#mentor_industry').parent().css('border','1px solid red');
            return;
        }
        $('#mentor_industry').parent().css('border','none');
        if ($('input[name="seeking_incubators"]').is(':checked') && $('#accel_support_industry').val().length ===0) {
            $('#accel_support_industry').parent().css('border','1px solid red');
            return;
        }
        $('#accel_support_industry').parent().css('border','none');

        data.accel_support_industry = appConfig.getFormattedValues('#accel_support_industry', this.mentorCategoryData);
        data.mentor_industry = appConfig.getFormattedValues('#mentor_industry', this.mentorCategoryData);

        data.startup_profile_str = this.profile_str;
        this.http.post(this.config.apiEndpoint + '/updatestartuprequirement/' + this.profile_str, data).subscribe(
            data => {
                console.log("Startup Other Details Updated Successfully");
                $("#contactUsForm .form-control").val("");

                this.startupProfileSuccessError = "Startup Other Details Updated Successfully";

                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
                window.scrollTo(0, 0);
            },
            error => {
                console.log("Please Enter Correct Details");
                console.log(error);
                this.startupProfileFailError = "Please Enter Correct Details";
                window.scrollTo(0, 0);
            });
    }

    getstartupRequirement(profile_str: any): void {
        this.myprofileService.getstartupRequirement(this.profile_str)
            .then((result) => {
                this.startupRequirementData = result;
                console.log(this.startupRequirementData);
                if (isPlatformBrowser(this.platformId)) {
                    setTimeout(() => {
                        if (this.startupRequirementData.requirement.seeking_investors) {
                            $('.invester').slideToggle().find('input, textarea, select').attr('required', 'required');
                        }
                        if (this.startupRequirementData.requirement.seeking_loan) {
                            $('.loansseeking').slideToggle().find('input, textarea, select').attr('required', 'required');
                        }
                        if (this.startupRequirementData.requirement.seeking_incubators) {
                            $('.incubatorsaccelerator').slideToggle().find('input, textarea, select').not('.multitag input').attr('required', 'required');
                        }
                        if (this.startupRequirementData.requirement.seeking_acquirers) {
                            $('.businesssale').slideToggle().find('input, textarea, select').attr('required', 'required');
                        }
                        if (this.startupRequirementData.requirement.seeking_mentorship) {
                            $('.mentorsearch').slideToggle().find('input, textarea, select').not('.multitag input').attr('required', 'required');
                        }
                        /*creating default tags*/
                        this.startupRequirementData.accel_support_industry.forEach(item => {
                            $('#accel_support_industry').tagit("createTag", item.name);
                        });
                        this.startupRequirementData.mentor_industry.forEach(item => {
                            $('#mentor_industry').tagit("createTag", item.name);
                        });
                    }, 1000)
                }
            });
    }

    ngOnInit() {
        this.getstartupRequirement(this.profile_str);
        this.getMentorSector();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="startupForm"]').validator();
        }
    }

    isChecked(inputName, toggleClass) {
        this.isLookingFor = ($('.other-detail input[type="checkbox"]:checked').length <= 0);
        if ($('input[name="' + inputName + '"]').is(':checked')) {
            $('.' + toggleClass).find('input, textarea, select').not('.multitag input').attr('required', 'required');
        } else {
            $('.' + toggleClass).find('input, textarea, select').removeAttr('required');
        }
        $('form[name="startupForm"]').validator('update');
    }

    getMentorSector(): void {
        this.contactService.getMentorSector()
            .then((result) => {
                this.mentorCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.mentorCategoryData);
                    setTimeout(function () {
                        $('#mentor_industry, #accel_support_industry').tagit({
                            tagLimit: 5,
                            availableTags: aTags,
                            showAutocompleteOnFocus: false,
                            allowSpaces: true,
                            beforeTagAdded: function (event, ui) {
                                if (aTags.indexOf(ui.tagLabel) < 0) {
                                    return false;
                                }
                            }
                        });
                    }, 1000)
                }
            });
    }

}
