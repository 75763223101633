import { isPlatformBrowser, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { APP_CONFIG, BASE_URL } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import {faTimesCircle as faTimesCircle,faShareAlt as faShareAlt} from '@fortawesome/free-solid-svg-icons/'


declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  faShareAlt = faShareAlt;
  faTimesCircle = faTimesCircle;
  currentUrl=window.location.href;
  public title = 'Business News';
  public news_id;
  public bxNewsDetailData;
  public bxnewsimilardata;
  public bxlength;
  public newstitle;
  public bxbannertop = '';
  public bxbannerleft = '';
  public bxbannerrightside = '';
  public bxbannercenter = '';
  public bxbannerbottom = '';
  public bannerpage = 15;
  public isViewChecked: boolean;
  public appConfig = appConfig;
  loading = true;
  submitMessage;

  constructor(element: ElementRef,
    private linkService: LinkService,
    private meta: Meta, private titleService: Title,
    private http: HttpClient,
    private contactService: ContactService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_CONFIG) private config: IAppConfig,
    private historyLocation: Location) {
    this.isViewChecked = false;
  }

  saveComment(data) {
 
    const formSelector = $('form[name="commentForm"]');
    if (formSelector.validator('validate').has('.has-error').length) {
      return;
    }
    data.news_id = this.news_id;
    formSelector.find('button[type="submit"]').button('loading');
    this.http.post(this.config.apiEndpoint + '/newsComment', data).subscribe(
      response => {
        $('#newscontainer .form-control').val('');
        $('#newscontainer').css('display', 'none');
        $('#newsthankscontainer').css('display', 'block');
      },
      error => {
        appConfig.scrollToError(false);
        this.submitMessage = error.error;
        formSelector.find('button[type="submit"]').button('reset');
      });
  }

  recomendateNews: any
  latestNews: any;

  bxnewssimilar(news_id: any): void {
    this.homeService.bxnewssimilar(this.news_id)
      .then((result) => {
        this.bxnewsimilardata = result.bxnewssimilardata;


        this.recomendateNews = result.bxnewssimilardata.sort((a, b) => a.views - b.views).slice(0, 3);

        this.latestNews = result.bxnewssimilardata.sort((a, b) => a.news_id - b.news_id).slice(0, 3);

        this.bxlength = this.bxnewsimilardata.length;

      });
  }



  ngOnInit() {
    this.route.paramMap.subscribe((params: Params) => {
      this.contactService.getBanners(this.bannerpage)
        .then((result) => {
           this.loading = false;
            this.bxbannerbottom = result.bottom;
            this.bxbannercenter = result.middle;
            this.bxbannerleft = result.left;
            this.bxbannerrightside = result.right;
            this.bxbannertop = result.top;
        });
            this.news_id = params.get('newsId');
            this.newstitle = params.get('newstitle');
            this.homeService.getnewsDetails(this.news_id)
              .then((result) => {
                this.bxNewsDetailData = result.bxnewsdetaildata;
              // console.log(this.bxNewsDetailData);
                if(this.bxNewsDetailData.url !== this.newstitle){
                  this.router.navigate(['/news']);
                }
                this.setMetaTitle();
                this.bxnewssimilar(this.news_id);
              });
    });
    /** Title Ellipsis **/
    /** Page Scroll Animation **/
    if (isPlatformBrowser(this.platformId)) {
      appConfig.pagescrollanimation();
    }
  }

  private setMetaTitle() {
    this.titleService.setTitle(this.bxNewsDetailData.seo_title + '- BusinessEx');

     this.meta.updateTag({ name: 'Keywords', content: this.bxNewsDetailData.seo_keywords });
     this.meta.updateTag({ name: 'Description', content: this.bxNewsDetailData.shortDesc });

    /*this.meta.updateTag({ name: 'news_keywords', content: this.bxNewsDetailData.seo_keywords });
    this.meta.updateTag({ name: 'Description', content: this.bxNewsDetailData.shortDesc })
    this.meta.updateTag({ itemprop: 'description', content: this.bxNewsDetailData.shortDesc });
    this.meta.updateTag({ property: 'article:section', itemprop: "articleSection", content: '' });
    this.meta.updateTag({ property: 'article:tag', content: this.bxNewsDetailData.seo_keywords });
    this.meta.updateTag({
      property: 'article:published_time',
      itemprop: "datePublished",
      content: this.bxNewsDetailData.time
    });
    this.meta.updateTag({
      property: 'article:modified_time',
      itemprop: "dateModified",
      content: this.bxNewsDetailData.time
    });*/

    this.meta.updateTag({ name: 'fb:pages', content: '84775983858482' });
    this.meta.updateTag({ name: 'fb:app_id', content: '59517604401875' });
    this.meta.updateTag({ itemprop: 'name', content: this.bxNewsDetailData.seo_title });
    this.meta.updateTag({ itemprop: 'image', content: this.bxNewsDetailData.image });
    this.meta.updateTag({ name: 'original-source', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ property: 'site_name', content: 'BusinessEx' });
    this.meta.updateTag({ property: 'og:title', content: this.bxNewsDetailData.seo_title });
    this.meta.updateTag({ property: 'og:type', content: 'news' });
    this.meta.updateTag({ property: 'og:url', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ property: "og:image", content: this.bxNewsDetailData.image });
    this.meta.updateTag({ property: "og:image:secure_url", content: this.bxNewsDetailData.image });
    this.meta.updateTag({ property: 'og:description', content: this.bxNewsDetailData.shortDesc });
    this.meta.updateTag({ property: 'og:image:width', content: '770' });
    this.meta.updateTag({ property: 'og:image:height', content: '434' });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ property: 'twitter:title', content: this.bxNewsDetailData.seo_title });
    this.meta.updateTag({ name: 'twitter:image', content: this.bxNewsDetailData.image });
    this.meta.updateTag({ name: 'twitter:description', content: this.bxNewsDetailData.shortDesc });
    this.meta.updateTag({ name: 'twitter:url', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ name: 'twitter:image:src', content: this.bxNewsDetailData.image });
    this.meta.updateTag({ name: 'twitter:site', content: '@BusinesEx' });
    this.meta.updateTag({ name: 'twitter:domain', content: 'businessex.com' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@businesex' });

    this.linkService.removeCanonicalLink();
    this.linkService.addTag({
      rel: 'canonical',
      href: BASE_URL + this.historyLocation.path()
    });

    // this.linkService.addTag({
    //   rel: 'amphtml',
    //   href: BASE_URL + '/amp' + this.historyLocation.path()
    // });
  }

  ngAfterViewChecked(): void {
    if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
      this.isViewChecked = true;
      //$('form[name="commentForm"]').validator();
    }
  }


  onclickFooterPage() {
    appConfig.scrollToError(true);
  }

}
