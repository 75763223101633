
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                                
                     <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                     <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">   
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE BUSINESS INFORMATION                                          
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/brokerConfidential/{{profile_str}}">Confidential Info</a></li>
                                        <li class="selected"><a routerLink="/dashboard/brokerBusiness/{{profile_str}}">Business Details</a></li>
                                        <li><a routerLink="/dashboard/brokerLocation/{{profile_str}}">Location Info</a></li>
                                        <li><a routerLink="/dashboard/brokerOther/{{profile_str}}">Other Info</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>         
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                    <form name="brokerForm" #brokerForm="ngForm" (ngSubmit)="brokerForm.form.valid && brokerMyAccountSubmit(brokerForm.value)" class="form-horizontal" role="form">
                                        <div class="sec-slide-effect">

                                            <div class="msg-pnl_sales" *ngIf="brokerProfileSuccessError" style="margin-top:10px; text-align:center;">{{brokerProfileSuccessError}}</div> 
                                            <div class="msg-pnl_sales" *ngIf="brokerProfileFailError" style="margin-top:10px; text-align:center;">{{brokerProfileFailError}}</div> 
                                            
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-12">
                                                    <div class="frm-sec-ttl">Broker Business Information</div>
                                                </div>
                                            </div>                                            
                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Name<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Enter Company Name" name="broker_company"  [ngModel]="BrokerBusinessdata?.broker_company" data-error="Please enter company." required>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Establishment Year<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <select class="form-control" name="estb_year" [ngModel]="BrokerBusinessdata?.estb_year" data-error="Please select establish year." required>                                                    
                                                            <option *ngFor="let empYear of estblish_year" [value]="empYear" [selected]="empYear == 'BrokerBusinessdata.estb_year'">{{empYear}}</option>
                                                        </select>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>                                          
                                                    </div>
                                                    <div class="help-block with-errors"></div> 
                                                </div>
                                            </div>
                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Employee Count<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <select class="form-control" name="emp_count" [ngModel]="BrokerBusinessdata?.emp_count" data-error="Please select employee count." required>                                        
                                                            <option *ngFor="let empCount of empployee_count" [value]="empCount.name" [selected]="empCount.name == 'BrokerBusinessdata.emp_count'">{{empCount.name}}</option>
                                                        </select>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div> 
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">                                      
                                                        <input type="text" minlength="3" class="form-control" name="company_city"  [ngModel]="BrokerBusinessdata?.location" (setAddress) = "getAddress($event)" placeholder="Enter Your Location" data-error="Please select location." googleplace required>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>                                           
                                            <div class="form-group has-feedback">
                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Website<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" minlength="10" maxlength="75" name="company_website"  [ngModel]="BrokerBusinessdata?.company_website" placeholder="Enter Company Website" data-error="Please enter website." required>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Logo<span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <img *ngIf="BrokerBusinessdata?.broker_company_logo != '' && BrokerBusinessdata?.broker_company_logo != null"
                                                         src="{{BrokerBusinessdata?.broker_company_logo}}" height="150" width="150">
                                                    <div class="input-group image-preview">
                                                        <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                                                        <input type="hidden" name="broker_company_logo_old" [ngModel]="BrokerBusinessdata?.broker_company_logo">
                                                        <span class="input-group-btn">
                                            <button type="button" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                                    <span class="glyphicon glyphicon-remove"></span> Clear
                                                </button>
                                                            <!-- image-preview-input -->
                                            <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                                <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                <input type="file" accept="image/png, image/jpeg, image/gif" id="inputImage" (change)="logoChangeEvent($event)"/> <!-- rename it -->
                                            </div>
                                            </span>
                                                 </div>
                                                </div>
                                            </div>

                                        </div>                                        
                                        <div class="sec-slide-effect txt-cen">                                  
                                            <div class="submitfrm">
                                                <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                                            </div>
                                        </div>
                                    </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>                                
            </div>
        </div>
    </div>                    
</div>
<!-- End Start Body Part -->