import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'priceShort'
})

export class PriceShortPipe implements PipeTransform {
    transform(amount: number): string {
        if (amount) {

            /*if (amount >= 10000 && amount < 100000) {
                return Math.round(amount / 1000) + ' Thousand';
            } else if (amount >= 100000 && amount < 10000000) {
                return Math.round(amount / 100000) + ' Lakh';
            } else if (amount >= 10000000) {
                return Math.round(amount / 10000000) + ' Crore';
            }*/

            if (amount >= 10000 && amount < 100000) {
                return (amount / 1000).toFixed(1) + ' Thousand';
            } else if (amount >= 100000 && amount < 10000000) {
                return (amount / 100000).toFixed(1) + ' Lakh';
            } else if (amount >= 10000000) {
                return (amount / 10000000).toFixed(1) + ' Crore';
            }

            return amount + '';
        }
        return '';
    }
}