<div class="headerplan sec-slide-effect">
  <div class="container">
    <div class="globaltxt">A <span>Goal</span> without <br>
      a <span>Plan</span> is Just a dream!</div>
    <div class="frmblk sec-slide-effect">

      <div class="row custom_error" style="margin-top:20px;">
        <div class="col-xs-12">
          <div class="msg-pnl_sales" *ngIf="submitMessage">
            <div *ngFor="let message of submitMessage | keyspipe : true">
              {{message}}
            </div>
          </div>
        </div>
      </div>

      <h1>Create a Business Plan</h1>
      <form [formGroup]="businessValuation" (ngSubmit)="businessValuationSubmit()">

        <ul class="frmsec">
          <li>
            <input type="text" class="form-control" placeholder="Your Name" formControlName="name"
              [ngClass]="{'invalid-border': submitted && bForm.name.errors}">
          </li>
          <li><input type="email" class="form-control" placeholder="Your Email" formControlName="email"
              [ngClass]="{'invalid-border': submitted && bForm.email.errors}">
          </li>
          <li><input type="text" class="form-control" placeholder="Your Mobile" formControlName="mobile"
              [ngClass]="{'invalid-border': submitted && bForm.mobile.errors}">
          </li>
          <li>
            <input type="text" class="form-control" placeholder="Your Company" formControlName="company"
              [ngClass]="{'invalid-border': submitted && bForm.company.errors}">
          </li>
          <li>
            <select formControlName="payment_mode" class="form-control rmv-bdr" placeholder="Select Payment Mode"
              [ngClass]="{'invalid-border': submitted && bForm.payment_mode.errors}">
              <option value="" selected="selected">Select Payment Mode</option>
              <option value="OPTCRDC">Credit Card</option>
              <option value="OPTDBCRD">Debit Card</option>
              <option value="OPTNBK">Net Banking</option>
              <option value="Paytm">Paytm</option>
            </select>
          </li>
        </ul>
        <input type="submit" class="btnst" value="Submit">
      </form>
    </div>
  </div>


</div>

<div class="planabout sec-slide-effect">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 abtblk">
        <img src="./assets/images/officeplan.jpg">
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 abt">
        <h2 class="abtplan">What is a
          <span>Business Plan?</span></h2>
        <p class="abttxt">Business Plan is a powerful strategic tool for Startups and Businesses.
          It is one of the most significant documents to be shared with
          investors, lenders, buyers and team members.</p>
        <p class="abttxt">Regardless of the intent of the business plan, the type of company and the stage at which it
          is operating, a Business Plan is a company’s roadmap to strategic growth and potential success.</p>
        <p class="abttxt">
          <strong><strong>Get complete Business Plan assistance from our professional Team
              in order to position your business strongly and portray the most
              impressive highlights of your firm.</strong></strong></p>
      </div>
    </div>
  </div>
</div>


<div class="planmiddle sec-slide-effect">
  <div class="container">
    <h2 class="abtplan cetre">When do you need a
      <span>Business Plan?</span></h2>
    <ul class="listplan">
      <li><img src="./assets/images/seeking-loan.png">
        <span>Seeking a loan</span>
      </li>
      <li><img src="./assets/images/investment-capital.png" alt="Looking for
investment capital">
        <span>Looking for
          investment capital</span>
      </li>
      <li><img src="./assets/images/buying-business.png" alt="Buying a new
business">
        <span>Buying a new
          business</span>
      </li>
      <li><img src="./assets/images/major-purchase.png" alt="Making a major
purchase">
        <span>Making a major
          Purchase</span>
      </li>
      <li><img src="./assets/images/recruiting-team.png">
        <span>Recruiting a
          team member</span>
      </li>
      <li><img src="./assets/images/planning-stratgcly.png">
        <span>Planning
          Strategically</span>
      </li>
      <li><img src="./assets/images/expanding-company.png">
        <span>Expanding the
          Company</span>
      </li>
      <li><img src="./assets/images/identifying-weekness.png">
        <span>Identifying
          potential weaknesses or pitfalls</span>
      </li>
      <li><img src="./assets/images/goal-setting.png">
        <span>Goal setting
          for the company</span>
      </li>
      <li><img src="./assets/images/tracking-prgress.png">
        <span>Tracking
          progress</span>
      </li>
      <li><img src="./assets/images/securing-assets.png">
        <span>Securing
          assets</span>
      </li>
    </ul>
  </div>
</div>


<!---->
<div class="planstatsbg sec-slide-effect">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 abt">
        <h2 class="abtplan">A Business Plan
          <span>Doubles Your Chances to Success</span></h2>
        <p class="abttxt">According to a survey by Palo Alto, entrepreneurs who created and followed business plans were
          about twice as likely in growing their business or obtaining capital, as compared to those who did not create
          a plan. Creating a plan correlated with increased success in each of the goals mentioned in the study.</p>

      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 abtblk">
        <img src="./assets/images/planstats.jpg">
      </div>
    </div>





  </div>
</div>


<!---->


<div class="bottomplan sec-slide-effect">
  <div class="container">

    <h2 class="abtplan cetre">Different studies have shown </h2>

    <div class="planback">



      <ul class="planli sec-slide-effect">
        <li>The companies that do business planning expand 30% faster than their competitors, who do not invest in
          business planning</li>
        <li>92% increase in sales revenue has been found in the fastest-growing organizations who follow their business
          plan, across the globe</li>
        <li>By creating a plan for the business model, entrepreneurs increase the probability of realizing their dream
          venture by 152%
        <li>Entrepreneurs, who formulate a business plan, are 129% surging upward and also, transiting their
          startup company for the next phase</li>
        <li>Companies adhering to a business plan probably receive funding earlier than their counterparts who
          do not form any business plan</li>
        <li>By following a business plan, companies can attain regular goals, track their progress on time and
          also, make changes in the business plan according to the shifting market conditions</li>

      </ul>
    </div>
  </div>


</div>

<!-- start code -->
<section id="faqs" class="sec-slide-effect">
  <header class="section-header  sec-slide-effect">
    <h3>Frequently Asked Questions</h3>

  </header>

  <div class="container">
    <div class="row about-container">

      <button class="accordion">What is a Business Plan?</button>
      <div class="panel faq-ans">
        <p>A Business Plan is a formally written document that includes a company's goals, the methods on how these
          goals must
          be reached, the progress made till date and the timelines within which these results will be achieved. </p>
      </div>

      <button class="accordion accordian-arrow">How long will it take to prepare a Business Plan by BusinessEx?</button>
      <div class="panel faq-ans">
        <p>It takes about 20 business days to prepare a Business Plan document from the date we receive all the relevant
          information, along with the requisite fees.</p>
      </div>

      <button class="accordion accordian-arrow">How much does BusinessEx charge for their Business Plan
        service?</button>
      <div class="panel faq-ans">
        <p>BusinessEx charges Rs 15,000 exclusive of taxes, to prepare a Business Plan document.</p>
      </div>

      <button class="accordion accordian-arrow">What will happen when I make the payment at BusinessEx?</button>
      <div class="panel faq-ans">
        <p>Once you make the payment, a representative from our Team will contact you within 2 working days to explain
          the next steps. We will then give you more information about the process and the information required by us to
          deliver the
          Business Plan.</p>
      </div>
    </div>
  </div>
</section>

<div class="faqmapbg">
  <h2 class="abtplan cetre">Contact Us</h2>
  <div class="container bord">

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 mapblkl">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.637887213309!2d77.28094571440371!3d28.490449997249474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1f7c135a39d%3A0x2e7cf3031d63171a!2sBusinessEx%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1568023334445!5m2!1sen!2sin"
          width="100%" height="312" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 mapblk2">
        <div class="bhh"><span><i class="fa fa-map-marker fa-lg" aria-hidden="true"></i></span>

          <div class="bhhright"><strong>BusinessEx Solutions Private Limited,</strong><br>
            Unit No. 601, 6th Floor Pinnacle Tower Behind The Atrium,<br>
            Suraj Kund Rd,map-marker
            Faridabad, Haryana 121009, India
          </div>

        </div>

        <div class="bhh"><span><i class="fa fa-envelope-o fa-lg" aria-hidden="true"></i></span>

          <div class="bhhright"><a href="#" onclick="return false;">support@businessex.com</a></div>
        </div>

        <div class="bhh"><span><i class="fa fa-phone fa-lg" aria-hidden="true"></i></span>
          <div class="bhhright">+91 8929353325</div>
        </div>


      </div>
    </div>

  </div>
</div>