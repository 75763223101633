
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    INVESTOR PROFILE
                                </div>
                            </div>
                            <div class="margin-20 cat-detail investor">
                                <div class="row form-sec">
                                    <!-- Investor Profile data start here -->
                                    <div class="col-xs-12 col-sm-12 col-md-12 scroll-ph sec-slide-effect"
                                         *ngIf="investorProfileData">

                                        <div class="page-ttl" *ngIf="investorProfileData?.inv_headline !== '' ">
                                            <h1>{{investorProfileData?.inv_headline}}</h1>
                                        </div>

                                        <div *ngIf="investorProfileData?.membership_paid==1 && investorProfileData?.membership_plan==1" class="shrt-desc tp-border Premium">
                                            <div class="investor-tag">
                                                <img src="assets/images/premium_inv.png">
                                            </div>
                                        </div>

                                        <div *ngIf="investorProfileData?.membership_paid==1 && investorProfileData?.membership_plan==2" class="shrt-desc tp-border Gold">
                                            <div class="investor-tag">
                                                <img src="assets/images/gold_inv.png">
                                            </div>
                                        </div>

                                        <div *ngIf="investorProfileData?.membership_paid==1 && investorProfileData?.membership_plan==3" class="shrt-desc tp-border Platinum">
                                            <div class="investor-tag">
                                                <img src="assets/images/platinum_inv.png">
                                            </div>
                                        </div>

                                        <div class="page-ttl"
                                             *ngIf="investorProfileData?.inv_headline == '' || investorProfileData?.inv_headline == 'NULL'">
                                            <h1>Individual Investor from {{ (investorProfileData?.inv_city !=='')
                                                ? investorProfileData?.inv_city : ("India") }} wanting to
                                                investment between {{ (investorProfileData?.invest_size_min !=
                                                "") ? (investorProfileData?.invest_size_min | priceShort)
                                                : ("N/A") }} and {{ (investorProfileData?.invest_size_max != "")
                                                ? (investorProfileData?.invest_size_max | priceShort) :
                                                ("N/A") }}</h1>
                                        </div>

                                        <div class="shrt-desc tp-border">
                                            {{ (investorProfileData?.inv_intro !== '') ?
                                            investorProfileData?.inv_intro : ("N/A") }}
                                        </div>

                                        <!-- Investor Details After Interaction Start Here -->
                                        <div class="row">
                                            <!-- Investor profile pic -->
                                            <div class="col-xs-12 col-sm-4 col-md-4">
                                                <div class="investor-profile">

                                                    <img class="img-responsive"
                                                         *ngIf="investorProfileData?.inv_profile_pic_path != ''"
                                                         src={{investorProfileData?.inv_profile_pic_path}}/>
                                                    <img class="img-responsive"
                                                         *ngIf="investorProfileData?.inv_profile_pic_path == '' || investorProfileData?.inv_profile_pic_path == null"
                                                         src="assets/images/userprofilepic.gif"/>

                                                </div>
                                                <a [routerLink]="" class="company-logo"
                                                   *ngIf="investorProfileData?.company_logo_path != '' && investorProfileData?.company_logo_path != null">
                                                    <img *ngIf="investorProfileData?.company_logo_path != '' && investorProfileData?.company_logo_path != null"
                                                         src={{investorProfileData?.company_logo_path}}
                                                         alt="company logo"/>
                                                </a>
                                            </div>
                                            <!-- Company intraction detaild info -->

                                            <div class="col-xs-12 col-sm-8 col-md-8">
                                                <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                    <div class="investor-name">
                                                        {{ (investorProfileData?.inv_name !== '') ?
                                                        investorProfileData?.inv_name : ("N/A") }}
                                                        <span class="pipe">|</span>
                                                        <span class="location">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ (investorProfileData?.inv_city !== '') ? investorProfileData?.inv_city : ("N/A") }},
                            {{ (investorProfileData?.inv_state !== '' && investorProfileData?.inv_state != 'NULL') ? investorProfileData?.inv_state : ("N/A") }}
                        </span>
                                                    </div>
                                                    <div class="overview">
                                                        {{ (investorProfileData?.company_designation !== '') ?
                                                        investorProfileData?.company_designation : ("N/A") }},
                                                        {{ (investorProfileData?.company_name !== '') ?
                                                        investorProfileData?.company_name : ("N/A") }}
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                    <div class="investor-contacts">
                                                        <div class="contact-ph">MOBILE
                                                            <span>
                                {{ (investorProfileData?.inv_mobile !== '' && investorProfileData?.inv_mobile != 'NULL') ? investorProfileData?.inv_mobile : ("N/A") }}
                            </span>
                                                        </div>
                                                        <div class="contact-email">EMAIL
                                                            <span>
                                {{ (investorProfileData?.inv_email !== '') ? investorProfileData?.inv_email : ("N/A") }}
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                    Investor Type:
                                                    <div class="overview inline">
                                                        <span *ngIf="investorProfileData?.inv_type == '1'">Firm</span>
                                                        <span *ngIf="investorProfileData?.inv_type == '2'">Individual Investor</span>
                                                        <span *ngIf="investorProfileData?.inv_type == '1'"
                                                              class="pipe">|</span>
                                                        <span class="location"
                                                              *ngIf="investorProfileData?.inv_type == '1'">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ (investorProfileData?.company_city !== '') ? investorProfileData?.company_city : ("N/A") }},
                            {{ (investorProfileData?.company_state !== '') ? investorProfileData?.company_state : ("N/A") }}
                        </span>
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect already-visible come-in"
                                                     *ngIf="investorProfileData?.inv_type == '1'">
                                                    Firm Type:
                                                    <div class="overview inline">
                                                        <span *ngIf="investorProfileData?.firm_type == '1'">Corporate Investment Firm</span>
                                                        <span *ngIf="investorProfileData?.firm_type == '2'">Private Equity Firm</span>
                                                        <span *ngIf="investorProfileData?.firm_type == '3'">Venture Capital Firm</span>
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect already-visible come-in" *ngIf="investorProfileData?.company_sector !== '' && investorProfileData?.company_sector !== null">
                                                    Company Sector:
                                                    <div class="overview inline">
                                                        {{ (investorProfileData?.company_sector !== '') ?
                                                        investorProfileData?.company_sector : ("N/A") }}
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in" *ngIf="investorProfileData?.company_summary !== ''">
                                                    Company Summary
                                                    <div class="overview" [innerHTML]="investorProfileData?.company_summary"></div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in">
                                                    Professional Summary
                                                    <div class="overview">
                                                        {{ (investorProfileData?.inv_abt_urself !== '') ?
                                                        investorProfileData?.inv_abt_urself : ("N/A") }}
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in"
                                                     *ngIf="investorProfileData?.invest_pref == '1'">
                                                    Investment Size:
                                                    <div class="overview inline">
                                                        {{ (investorProfileData?.invest_size_min != "") ?
                                                        (investorProfileData?.invest_size_min | priceShort)
                                                        : ("N/A") }}
                                                        - <i class="fa fa-inr" aria-hidden="true"></i>
                                                        {{ (investorProfileData?.invest_size_max != "") ?
                                                        (investorProfileData?.invest_size_max | priceShort)
                                                        : ("N/A") }}
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in">
                                                    Investment Stake Preference:
                                                    <div class="overview inline">
                                                        {{investorProfileData?.invest_stake}} %
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in">
                                                    Investment Preference:
                                                    <div class="overview inline">
                                                        <span *ngIf="investorProfileData?.invest_pref == '1'">Investment</span>
                                                        <span *ngIf="investorProfileData?.invest_pref == '1' && investorProfileData?.full_acquisition == '1'">/ </span>
                                                        <span *ngIf="investorProfileData?.full_acquisition == '1'">Full Acquisition</span>
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in">
                                                    Sector Preference
                                                    <div class="overview">
                    <span *ngFor="let industryPreference of investorIndustryPref">
                        {{industryPreference.category_name}}
                    </span>
                                                    </div>
                                                </div>
                                                <div class="business-detail-sec sec-slide-effect come-in">
                                                    Location Preference
                                                    <div class="overview">
                    <span *ngFor="let locationPreference of investorLocationPref">
                    {{locationPreference.location_name}}
                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Investor Details After Interaction End Here -->

                                    </div>
                                    <!-- Investor Profile data end here -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
