
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE OTHER REQUIREMENT
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                                        <li><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                                        <li><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                                        <li><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                                        <li class="selected"><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                                        <li><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form #startupForm="ngForm"
                                              (ngSubmit)="startupMyAccountSubmit(startupForm.value)"
                                              class="form-horizontal" name="startupForm" role="form">

                                            <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect" style="position:relative; z-index: 1;">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">Other Details</div>
                                                    </div>
                                                </div>

                                                <div class="row form-group other-detail has-feedback">
                                                    <label class="col-xs-12 col-sm-3 col-md-4 control-label">I am
                                                        looking for<span class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-8 col-md-7" [ngClass]="{'has-error': isLookingFor==true}">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6">
                                                                <div class="checkbox input-group">
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                                type="checkbox" name="seeking_investors"
                                                                                [ngModel]="startupRequirementData?.requirement?.seeking_investors"
                                                                                value="invester" (change)="isChecked('seeking_investors','invester')"
                                                                                data-error="Please Select atleast one"
                                                                                #seeking_investors="ngModel" ngModel>Investors for my business</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12"
                                                                           data-error="Please Select atleast one">
                                                                        <input
                                                                                type="checkbox" name="seeking_mentorship"
                                                                                [ngModel]="startupRequirementData?.requirement?.seeking_mentorship"
                                                                                value="mentorsearch" (change)="isChecked('seeking_mentorship','mentorsearch')"
                                                                                data-error="Please Select atleast one"
                                                                                #seeking_mentorship="ngModel" ngModel>Mentorship for my business</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                                type="checkbox" name="seeking_acquirers"
                                                                                [ngModel]="startupRequirementData?.requirement?.seeking_acquirers"
                                                                                value="businesssale" (change)="isChecked('seeking_acquirers','businesssale')"
                                                                                #seeking_acquirers="ngModel" ngModel>Acquirers for my startup</label>                                                                    
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                                type="checkbox" name="seeking_loan"
                                                                                [ngModel]="startupRequirementData?.requirement?.seeking_loan"
                                                                                (change)="isChecked('seeking_loan','loansseeking')"
                                                                                value="loansseeking" data-error="Please Select atleast one"
                                                                                #seeking_loan="ngModel" ngModel>Loan for my business</label>
                                                                    
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                                type="checkbox" name="seeking_incubators"
                                                                                [ngModel]="startupRequirementData?.requirement?.seeking_incubators"
                                                                                value="incubatorsaccelerator" (change)="isChecked('seeking_incubators','incubatorsaccelerator')"
                                                                                data-error="Please Select atleast one"
                                                                                #seeking_incubators="ngModel" ngModel>Incubators / Accelerators for my startup</label>
                                                                    
                                                                    
                                                                </div>
                                                            </div>
                                                            <!--<div class="col-xs-12 col-sm-6 col-md-6">
                                                                <div class="checkbox rm-pading-tp">

                                                                </div>
                                                            </div>-->
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="help-block with-errors" *ngIf="isLookingFor">Please Select atleast one</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--<div class="col-xs-12 col-sm-8 col-md-7">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6">
                                                                <div class="checkbox">
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input type="checkbox" name="seeking_investors"
                                                                               [ngModel]="startupRequirementData?.requirement.seeking_investors"
                                                                               value="1"
                                                                               data-error="Please Select atleast one"
                                                                               required>Investors</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input type="checkbox" name="seeking_mentorshiphip"
                                                                               [ngModel]="startupRequirementData?.requirement.seeking_mentorshiphip"
                                                                               value="1"
                                                                               data-error="Please Select atleast one"
                                                                               required>Mentorship for my
                                                                        startup</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input type="checkbox" name="seeking_acquirers"
                                                                               [ngModel]="startupRequirementData?.requirement.seeking_acquirers"
                                                                               value="1"
                                                                               data-error="Please Select atleast one"
                                                                               required>Acquirers for my startup</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6 col-md-6">
                                                                <div class="checkbox rm-pading-tp">
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input type="checkbox" name="seeking_loan"
                                                                               [ngModel]="startupRequirementData?.requirement.seeking_loan"
                                                                               value="1"
                                                                               data-error="Please Select atleast one"
                                                                               required>Loan for my startup</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input type="checkbox" name="seeking_incubators"
                                                                               [ngModel]="startupRequirementData?.requirement.seeking_incubators"
                                                                               value="1"
                                                                               data-error="Please Select atleast one"
                                                                               required>Incubators / Accelerators for my startup</label>

                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                    </div>-->

                                                </div>

                                                <!-- For Investor search -->
                                                <div class="lookingfr invester">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Investor search
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Amount
                                                                of investment you are looking for <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="inv_asking_price"
                                                                           [ngModel]="startupRequirementData?.requirement.inv_asking_price"
                                                                           class="form-control"
                                                                           placeholder="Enter Amount">

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business
                                                                stake of the investment <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="inv_stake"
                                                                           [ngModel]="startupRequirementData?.requirement.inv_stake"
                                                                           class="form-control"
                                                                           placeholder="Enter Stake">

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Reason
                                                                for investment <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="inv_reason"
                                                                           [ngModel]="startupRequirementData?.requirement.inv_reason"
                                                                           class="form-control"
                                                                           placeholder="Enter Your Reason for Investment">

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--For Loans seeking -->
                                                <div class="lookingfr loansseeking">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Loans seeking
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Collateral
                                                                details <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="loan_collateral_details"
                                                                           [ngModel]="startupRequirementData?.requirement.loan_collateral_details"
                                                                           class="form-control"
                                                                           placeholder="Enter details">

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Loan
                                                                amount seeking <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="loan_amount"
                                                                           [ngModel]="startupRequirementData?.requirement.loan_amount"
                                                                           class="form-control"
                                                                           placeholder="Enter Amount">

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Possible
                                                                repayment period <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <select class="form-control"
                                                                            name="loan_repayment_period"
                                                                            [ngModel]="startupRequirementData?.requirement.loan_repayment_period">
                                                                        <option *ngFor="let repayPeriod of repayment_period"
                                                                                [value]="repayPeriod.id"
                                                                                [selected]="repayPeriod.id == 'startupRequirementData.requirement.loan_repayment_period'">
                                                                            {{repayPeriod.name}}
                                                                        </option>
                                                                    </select>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Expected
                                                                Interest Rate <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <select class="form-control"
                                                                            name="loan_interest_rate"
                                                                            [ngModel]="startupRequirementData?.requirement.loan_interest_rate">
                                                                        <option *ngFor="let loanInterest of loan_interest"
                                                                                [value]="loanInterest.id"
                                                                                [selected]="loanInterest.id == 'startupRequirementData.requirement.loan_interest_rate'">
                                                                            {{loanInterest.name}}
                                                                        </option>
                                                                    </select>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Reason
                                                                for loan <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="loan_reason"
                                                                           [ngModel]="startupRequirementData?.requirement.loan_reason"
                                                                           class="form-control"
                                                                           placeholder="Enter Reason">

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- For Incubators / Accelerator -->
                                                <div class="lookingfr incubatorsaccelerator">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Incubators /
                                                            Accelerator
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Accelerator
                                                                requirement details <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="accel_req_details"
                                                                           [ngModel]="startupRequirementData?.requirement.accel_req_details"
                                                                           class="form-control"
                                                                           placeholder="Enter your Req">

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Investment
                                                                Requirement <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="accel_inv_req"
                                                                           [ngModel]="startupRequirementData?.requirement.accel_inv_req"
                                                                           class="form-control"
                                                                           placeholder="Enter your Amount">

                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Field
                                                                of support needed<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group multitag">
                                                         <!--            <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Specify the subject expertise field that the incubator should support for your growth"><i class="fa fa-info" aria-hidden="true"></i></div> -->
                                                                    <!--<div style="position:relative;">-->
                                                                        <input class="form-control"
                                                                                #accel_support_industry="ngModel" ngModel id="accel_support_industry" name="accel_support_industry" style="display:none;">
                                                                            <!--<option *ngFor="let supportIndus of startupRequirementData?.accel_support_industry" value="{{supportIndus.id}}_{{supportIndus.name}}_{{supportIndus.pid}}" class="selected" selected="selected">{{supportIndus.name}}</option>-->
                                                                    <!--</div>-->
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>

                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Time
                                                                period of support needed <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <select class="form-control"
                                                                            name="accel_time_period"
                                                                            [ngModel]="startupRequirementData?.requirement.accel_time_period">
                                                                        <option *ngFor="let supportPeriod of support_period"
                                                                                [value]="supportPeriod.id"
                                                                                [selected]="supportPeriod.id == 'startupRequirementData.requirement.accel_time_period'">
                                                                            {{supportPeriod.name}}
                                                                        </option>
                                                                    </select>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- For full Business Sale -->
                                                <div class="lookingfr businesssale">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For acquirers</div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Selling
                                                                (Asking) Price <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="buyer_sell_price"
                                                                           [ngModel]="startupRequirementData?.requirement.buyer_sell_price"
                                                                           class="form-control"
                                                                           placeholder="Enter Asking Price">

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Reason
                                                                for Selling <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="buyer_sell_reason"
                                                                           [ngModel]="startupRequirementData?.requirement.buyer_sell_reason"
                                                                           class="form-control"
                                                                           placeholder="Enter Your Reason for selling">

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- For Mentor search -->
                                                <div class="lookingfr mentorsearch">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Mentor search
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Mentorship
                                                                Requirement Details <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <textarea name="mentor_req_details"
                                                                              [ngModel]="startupRequirementData?.requirement.mentor_req_details"
                                                                              class="form-control" rows="3"
                                                                              minlength="15" maxlength="150"
                                                                              placeholder="Mentorship Requirement Details"
                                                                              data-error="Please fill out this field."
                                                                              #buyer_sell_reason="ngModel" ngModel></textarea>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <!--<div class="input-group">
                                                                    <textarea class="form-control"
                                                                              #mentor_req_details="ngModel" ngModel
                                                                              [ngModel]="startupRequirementData?.requirement.mentor_req_details"
                                                                              rows="3" minlength="15" maxlength="150"
                                                                              placeholder="Mentorship Requirement Details"></textarea>
                                                                </div>-->
                                                            </div>
                                                        </div>

                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Field of Mentorship
                                                                <span class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group multitag">
                                                                    <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Enter your text"><!-- <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                                    <!--<div style="position:relative;">-->
                                                                        <input class="form-control" name="mentor_industry" id="mentor_industry" #mentor_industry="ngModel" ngModel style="display: none">
                                                                    <!--</div>-->
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">One line
                                                        pitch for your business<span class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="business_pitch"
                                                                      [ngModel]="startupRequirementData?.requirement.business_pitch"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="One line pitch for your business"
                                                                      data-error="Please fill out this field."
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->