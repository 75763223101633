<main id="main" class="minheigh">
    <div class="container bex-main">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <ul class="brunnar">
                    <li><a routerLink="/">Home</a></li>
                    <li>/</li>
                    <li>Pricing</li>
                    
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div class="msg-pnl_sales" *ngIf="submitMessage">
                    {{submitMessage}}
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12" *ngIf="profileExist === 2">
                <h1 class="headblk">Choose a Plan</h1>
                <p class="statictxt">Create your profile & find the correct solution for your business</p>
                <form class="frmall" [formGroup]="pricingForm" (ngSubmit)="priceFormSubmit()">
                    <div class="pestion">
                        <div class="prtxt">Profile Type:</div>
                        <div class="lineval">

                            <!-- <div class="radio-item">
                                <input type="radio" id="ritema" formControlName="profile_type" value="1"
                                    [ngClass]="{'invalid-border' : submitted && pForm.profile_type.errors}"
                                    checked="checked">
                                <label for="ritema">Business</label>
                            </div>

                            <div class="radio-item">
                                <input type="radio" id="id3" formControlName="profile_type" value="7"
                                    [ngClass]="{'invalid-border' : submitted && pForm.profile_type.errors}">
                                <label for="id3">Startup</label>
                            </div>

                            <div class="radio-item">
                                <input type="radio" id="id4" formControlName="profile_type" value="2"
                                    [ngClass]="{'invalid-border' : submitted && pForm.profile_type.errors}">
                                <label for="id4">Investor</label>
                            </div> -->
 
                            <div class="radio-item" *ngFor="let profType of create_profile_type">
                                <input type="radio" id="ritemb{{profType.id}}" formControlName="profile_type"
                                    [value]="profType.id"
                                    [ngClass]="{'invalid-border' : submitted && pForm.profile_type.errors}" 
                                    (click)="mentorAndInvesot(profType.id)"
                                    checked="checked">
                                <label for="ritemb{{profType.id}}">{{profType.name}}</label>
                            </div>

                            <div class="radio-item">
                                <div *ngIf="submitted && pForm.profile_type.errors" class="invalid-feedback">
                                    <div *ngIf="pForm.profile_type.errors.required">
                                        Please Choose Profile</div>
                                </div>
                            </div>



                        </div>

                    </div>

                    <div class="row marsettop">
                        <div class="col-12 col-sm-6 col-md-6 modfy1">
                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt mandatory">Your Name</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <input 
                                        type="text" 
                                        formControlName="name"
                                        [ngClass]="{'invalid-border' : submitted && pForm.name.errors}"
                                        class="form-control modysel" placeholder="Enter name">

                                    <div *ngIf="submitted && pForm.name.errors" class="invalid-feedback">
                                        <div *ngIf="pForm.name.errors.required">
                                            Please Enter Your Name</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 modfy2">
                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt mandatory">Mobile No.</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <input type="text" class="form-control modysel" 
                                        placeholder="Enter Mobile"
                                        formControlName="mobile"
                                        [ngClass]="{'invalid-border' : submitted && pForm.mobile.errors}">

                                    <div *ngIf="submitted && pForm.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="pForm.mobile.errors.required">
                                            Please Enter Your mobile</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marsettop">
                        <div class="col-12 col-sm-6 col-md-6 modfy1">
                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt mandatory">Email id</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <input class="form-control modysel"
                                        placeholder="Enter Email id" formControlName="email"
                                        [ngClass]="{'invalid-border' : submitted && pForm.email.errors}">

                                    <div *ngIf="submitted && pForm.email.errors" class="invalid-feedback">
                                        <div *ngIf="pForm.email.errors.required">
                                            Please enter your email id</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 modfy2">
                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt mandatory">Company Name</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8"><input class="form-control modysel"
                                        placeholder="Enter Company Name" formControlName="company"
                                        [ngClass]="{'invalid-border' : submitted && pForm.company.errors}">

                                    <div *ngIf="submitted && pForm.company.errors" class="invalid-feedback">
                                        <div *ngIf="pForm.company.errors.required">
                                            Please Enter Your Company Name</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="row marsettop">
                        <div class="col-12 col-sm-6 col-md-6 modfy1">
                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt">Promo Code</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8 poss">
                                    <input class="form-control modysel"
                                        placeholder="{{(couponResponse?.status !== 'success') ? 'Enter Promo Code': ''}}"
                                        #coupon_code formControlName="coupon_code">

                                    <button class="alybtn prombtn" *ngIf="couponResponse?.status !== 'success'"
                                        (click)="submitCoupon(coupon_code.value)">Apply</button>
                                    <button class="alybtn"
                                        *ngIf="couponResponse?.status == 'success'">{{coupon_code.value}}
                                        <i class="fa fa-times" aria-hidden="true" (click)="removeCoupon()"></i>
                                    </button>

                                    <div class="comman"
                                        [ngClass]="{ 'successshow': couponResponse?.status == 'success', 'invalid-feedback': couponResponse?.status == 'error' } "
                                        *ngIf="couponResponse?.message">
                                        {{couponResponse?.message}}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 modfy2">
                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt mandatory">Payment Mode</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <select class="form-control myselectclasscat"
                                        data-error="Please select investor type." id="investorType12" name="inv_type"
                                        formControlName="payment_mode">
                                        <option disabled="" hidden="" selected="" value="">Select Payment Mode</option>
                                        <option value="OPTCRDC">Credit Card</option>
                                        <option value="OPTDBCRD">Debit Card</option>
                                        <option value="OPTNBK">Net Banking</option>
                                        <option value="Paytm">Paytm</option>

                                    </select>

                                    <div *ngIf="submitted && pForm.payment_mode.errors" class="invalid-feedback">
                                        <div *ngIf="pForm.payment_mode.errors.required">
                                            Please Choose Payment Mode</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <app-alert></app-alert>

                    <div class="priclist businessPricelist" *ngIf="changeMentorAndStartupPlan==0">
                        <!-- basic start here  -->
                        <div class="priclistinner priclist_trail">
                            <div class="container_banner"><span>TRIAL</span></div>
                            <div class="inhieght">
                                <div class="shead">Trial Offer
                                     <input type="hidden" formControlName="membership_plan"  value="0">
                                     <span class="dll"> Asking Price - less than 2Cr</span>
                                </div>
                               
                                <div class="mainpri"><i class="fa fa-inr" aria-hidden="true"></i> 999 <span>(+18% GST)</span></div>
                                <div class="mtxt">45 Days</div>

                                <div class="bntls">
                                    <input type="submit" value="Buy Now" class="btnpric"
                                        (click)="onChagePlanActive($event,0)" *ngIf="!formSubmitted">
    
                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                                        
                                </div>

                            </div>



                               <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-check" aria-hidden="true"></i> Profile
                                        Activation<i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                    <span class="tooltiptextfrm">Get your profile activated within 5 working days of sharing all relevant information.</span>
                                </div>

                                 <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-check" aria-hidden="true"></i> <b>5 Business Proposals</b><i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                    <span class="tooltiptextfrm">Reach out to 5 Businesses/Startups registered with BusinessEx, based on your business requirements</span>
                                </div>
    
                                 <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-check" aria-hidden="true"></i> Receive Unlimited Investment Proposals<i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                    <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Businesses/Startups registered with BusinessEx</span>
                                </div>                         

                            
                        </div>
                        <!-- basic end here  -->
                        <!-- Premium -->
                        <div class="priclistinner {{priclistinnerActive=='premium'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Premium <input type="hidden" formControlName="membership_plan"
                                        value="1">
                                        <span class="dll"> Asking Price - less than 2Cr</span>
                                </div>
                                

                                <div class="mainpri"><i class="fa fa-inr" aria-hidden="true"></i> 5,999 <span>(+18% GST)</span>
                                </div>

                                <!-- <div class="savcol"><i class="fa fa-inr" aria-hidden="true"></i>4000 Savings</div> -->

                                <div class="mtxt">3 Months</div>

                                <div class="bntls">
                                    <input type="submit" value="Buy Now" class="btnpric"
                                        (click)="onChagePlanActive($event,1)" *ngIf="!formSubmitted">
    
                                        <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;" >Loading....</span> 
                                        </button>
                                </div>
                            </div>
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Speedy Profile Activation<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Send 50 Business Proposals</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Reach out to 50 Businesses/Startups registered with BusinessEx, based on your business requirements.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Receive Unlimited Investment Proposals<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Business Buyers/Investors/Mentors registered with BusinessEx</span>
                            </div>  

                            

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Continuous Email Support</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>
<!--  -->
                             

                                                
                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>10 Website Visitor Queries</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Unlock 10 Investment Proposals from Website Visitors who are interested in your listing.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Profile Views<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>     

                           

                        </div>
                        <!--  -->
                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='gold'?'active':''}}">

                            <div class="topset">
                                <div class="shbbtxt">Most Recommended</div>
                            </div>

                            <div class="inhieght">
                                <div class="shead">Gold <input type="hidden" formControlName="membership_plan"
                                        value="2">
                                        <span class="dll"> Asking Price - less than 2Cr</span>    
                                </div>
                                    
                                

                                <div class="mainpri">
                                    <!-- <del class="dll"><i class="fa fa-inr" aria-hidden="true"></i> 23,999</del> -->
                                    <i class="fa fa-inr" aria-hidden="true"></i> 11,999 <span>(+18% GST)</span>
                                </div>

                                <!-- <div class="savcol"><i class="fa fa-inr" aria-hidden="true"></i> 4,000 Savings</div> -->

                                <div class="mtxt">12 Months</div>

                                <div class="bntls">
                                    <input type="submit" value="Buy Now" class="btnpric"
                                        (click)="onChagePlanActive($event,2)" *ngIf="!formSubmitted">
                                    
                                        <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                        </button>
                                </div>

                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Speedy Profile Activation <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b> 100 Business Proposals </b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Reach out to 100 Businesses/Startups registered with BusinessEx, based on your business requirements.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Receive Unlimited Investment Proposals<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Business Buyers/Investors/Mentors registered with BusinessEx</span>
                            </div>       
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Continuous Email Support</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>20 Website Visitor Queries</b><i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                <span class="tooltiptextfrm">Unlock 20 Investment Proposals from Website Visitors who are interested in your listing.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Profile Views<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>  


                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Profile Promotion <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms, along with dedicated marketing campaigns</span>
                            </div>  




                             <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Key Account Manager <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Assignment of a KAM to manage your profile on BusinessEx</span>
                            </div>   -->

                            

                           

                        </div>
                        <!--  -->

                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='platinum'?'active':''}} platinum" >

                            <div class="inhieght">
                                <div class="shead">Platinum <input type="hidden" formControlName="membership_plan"
                                        value="3">
                                        <span class="dll"> Asking Price - no limit</span>
                                </div>
                               

                                <div class="mainpri"><i class="fa fa-inr" aria-hidden="true"></i> 99,999 <span>(+Brokerage and GST)</span>
                                </div>

                                <!-- <div class="savcol"><i class="fa fa-inr" aria-hidden="true"></i> 5,000 Savings</div> -->

                                <div class="mtxt">12 Months</div>


                            <div class="bntls">

                                    <!-- <input type="submit" value="Contact To Execute" class="btnpric"
                                    (click)="onChagePlanActive($event,3)" *ngIf="!formSubmitted"> -->

                                    <button type="button" class="btnpric" data-toggle="modal"
                                    data-target="#contactModel">Contact To Executive</button>

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                            </div>

                            <div class="pricetooltipfrm">
                                <b>Everything in Gold Plan</b>
                                <p><b>+</b></p>
                            </div> 

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Key Account Manager</b>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx</span>
                            </div> 

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Top Bussinesses/Startups</b>
                                    <i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div> 

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Acceleration Marketing</b>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Accelerated Marketing: Upto 10,000 marketing emails sent to registered members of BusinessEx.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i><b>Businesses Valution </b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Certified Businesses Valuation</span>
                            </div>


                        </div>
                        <!--  -->
                    </div>

                    <div class="priclist" *ngIf="changeMentorAndStartupPlan==1">
                        <!-- basic start here  -->
                        <div class="priclistinner basicClass">
                            <div class="inhieght">
                                <div class="shead">Basic <input type="hidden" formControlName="membership_plan"
                                        value="0"></div>
                        <!--         <del class="dll"><i class="fas fa-rupee-sign"></i> 1000</del> -->

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 999 <span>(+18% GST)</span></div>

                                <!--  <div class="savcol"><i class="fas fa-rupee-sign"></i> 500 Savings</div> -->
                            </div>

                            <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile
                                        Activation</strong>
                                    <span class="tooltiptextfrm">Get your profile activated within 5 working days of sharing all relevant information.</span>
                                </div>
    
                                    <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                    <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                                </div>
    <!--  -->
                                    <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 5 Business Proposals</strong>
                                    <span class="tooltiptextfrm">Reach out to 5 Businesses/Startups registered with BusinessEx, based on your business requirements</span>
                                </div>
    
                                    <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                    <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Businesses/Startups registered with BusinessEx</span>
                                </div>                         
    
                            

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,0)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!-- basic end here  -->
                        <!-- Premium -->
                        <div class="priclistinner premiumClass {{priclistinnerActive=='premium'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Premium <input type="hidden" formControlName="membership_plan"
                                        value="1"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 4,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 2,499 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 2500 Savings</div>

                                <div class="mtxt">3 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 50 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 50 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 10 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 10 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>                          


                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,1)" *ngIf="!formSubmitted">
                                
                                <button type="button" class="btnpric" *ngIf="formSubmitted">
                                <span style="color:#fe7806;">Loading....</span> 
                                </button>

                            </div>

                        </div>
                        <!--  -->
                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='gold'?'active':''}}">

                            <div class="topset">
                                <div class="shbbtxt">Most Recommended</div>
                            </div>

                            <div class="inhieght">
                                <div class="shead">Gold <input type="hidden" formControlName="membership_plan"
                                        value="2"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 7,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 3,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 4,000 Savings</div>

                                <div class="mtxt">6 months</div>
                            </div>

                          
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 100 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 100 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 20 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 20 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines and chooses Top Profiles</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>



                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,2)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!--  -->

                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='platinum'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Platinum <input type="hidden" formControlName="membership_plan"
                                        value="3"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 11,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 5,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 6,000 Savings</div>

                                <div class="mtxt">9 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send Unlimited Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to Unlimited Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Unlimited Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock Unlimited Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Dedicated marketing campaign for promotion.</strong>
                                <span class="tooltiptextfrm">Dedicated marketing campaign for promotion.</span>
                            </div> -->


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Monthly Reports along with analysis for potential improvements.</strong>
                                <span class="tooltiptextfrm">Monthly Reports along with analysis for potential improvements.</span>
                            </div> -->

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Top Businesses/Startups</strong>
                                <span class="tooltiptextfrm">Recommendation as “Top Recommended” on our website.</span>
                            </div>

                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Accelerated Marketing: Upto 10,000 marketing emails sent to registered members of BusinessEx.</span>
                            </div> -->





    <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Upto 10,000 marketing emails sent to registered members of BusinessEx</span>
                            </div>  


    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Business Valuation / Business Plan</strong>
                                <span class="tooltiptextfrm">Certified Business Valuation / Business Plan Report</span>
                            </div>   -->


                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,3)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!--  -->
                    </div>

                    <div class="priclist" *ngIf="changeMentorAndStartupPlan==2">
                        <!-- basic start here  -->
                        <div class="priclistinner basicClass">
                            <div class="inhieght">
                                <div class="shead">Basic <input type="hidden" formControlName="membership_plan"
                                        value="0"></div>
                        <!--         <del class="dll"><i class="fas fa-rupee-sign"></i> 1000</del> -->

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 999 <span>(+18% GST)</span></div>

                                <!--  <div class="savcol"><i class="fas fa-rupee-sign"></i> 500 Savings</div> -->
                            </div>

                            <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile
                                        Activation</strong>
                                    <span class="tooltiptextfrm">Get your profile activated within 5 working days of sharing all relevant information.</span>
                                </div>
    
                                    <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                    <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                                </div>
    <!--  -->
                                    <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 5 Business Proposals</strong>
                                    <span class="tooltiptextfrm">Reach out to 5 Businesses/Startups registered with BusinessEx, based on your business requirements</span>
                                </div>
    
                                    <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                    <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Businesses/Startups registered with BusinessEx</span>
                                </div>                         
    
                            

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,0)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!-- basic end here  -->
                        <!-- Premium -->
                        <div class="priclistinner  premiumClass {{priclistinnerActive=='premium'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Premium <input type="hidden" formControlName="membership_plan"
                                        value="1"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 9,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 5,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 4,000 Savings</div>

                                <div class="mtxt">3 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 50 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 50 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 10 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 10 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>                         


                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,1)" *ngIf="!formSubmitted">
                                
                                <button type="button" class="btnpric" *ngIf="formSubmitted">
                                <span style="color:#fe7806;">Loading....</span> 
                                </button>

                            </div>

                        </div>
                        <!--  -->
                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='gold'?'active':''}}">

                            <div class="topset">
                                <div class="shbbtxt">Most Recommended</div>
                            </div>

                            <div class="inhieght">
                                <div class="shead">Gold <input type="hidden" formControlName="membership_plan"
                                        value="2"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 15,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 11,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 4,000 Savings</div>

                                <div class="mtxt">6 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 100 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 100 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 20 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 20 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines and chooses Top Profiles</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>


                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,2)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!--  -->

                        <!-- Platinum -->
                        <div class="priclistinner {{priclistinnerActive=='platinum'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Platinum <input type="hidden" formControlName="membership_plan"
                                        value="3"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 74,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 39,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 35,000 Savings</div>

                                <div class="mtxt">6 months</div>
                            </div>


                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send Unlimied Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to Unlimited Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Unlimited Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock Unlimited Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Dedicated marketing campaign for promotion.</strong>
                                <span class="tooltiptextfrm">Dedicated marketing campaign for promotion.</span>
                            </div> -->


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Monthly Reports along with analysis for potential improvements.</strong>
                                <span class="tooltiptextfrm">Monthly Reports along with analysis for potential improvements.</span>
                            </div> -->

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Top Businesses/Startups</strong>
                                <span class="tooltiptextfrm">Recommendation as “Top Recommended” on our website.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Accelerated Marketing: Upto 10,000 marketing emails sent to registered members of BusinessEx.</span>
                            </div>


                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Business Plan</strong>
                                <span class="tooltiptextfrm">Business Plan Report</span>
                            </div>



                            



    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Upto 10,000 marketing emails sent to registered members of BusinessEx</span>
                            </div>   -->


    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Business Valuation / Business Plan</strong>
                                <span class="tooltiptextfrm">Certified Business Valuation / Business Plan Report</span>
                            </div>   -->
                


                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,3)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!--  -->
                    </div>
                

                </form>
            </div>

            <div class="col-12 col-sm-12 col-md-12" *ngIf="profileExist === 1">
                <h1 class="headblk">Upgrade Plan</h1>
                <p class="statictxt">Upgrade your profile & find the correct solution for your business</p>
                <form class="frmall" [formGroup]="pricingFormUgrade" (ngSubmit)="saveUpgradeAccount()">
                    <div class="pestion">
                        <div class="prtxt">Profile Type:</div>
                        <div class="lineval">
                            <div class="radio-item"
                                *ngFor="let profType of profileObjects | nonBrokerProfileTypeFilter">
                                <input type="radio" id="ritemb{{profType.profile_type}}" formControlName="profile_type"
                                    [value]="profType.profile_type"
                                    [ngClass]="{'invalid-border' : submitted && pFormUpgrade.profile_type.errors}" (click)="mentorAndInvesot(profType.profile_type)">
                                <label for="ritemb{{profType.profile_type}}">{{profType.profile_name}}</label>
                            </div>

                            <div class="radio-item">
                                <div *ngIf="submitted && pFormUpgrade.profile_type.errors" class="invalid-feedback">
                                    <div *ngIf="pFormUpgrade.profile_type.errors.required">
                                        Please Choose Profile</div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row marsettop">
                        <div class="col-12 col-sm-6 col-md-6 modfy1">

                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt">Promo Code</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8 poss">
                                    <input class="form-control modysel"
                                        placeholder="{{(couponResponse?.status !== 'success') ? 'Enter Promo Code': ''}}"
                                        #coupon_code formControlName="coupon_code">

                                    <button class="alybtn prombtn" *ngIf="couponResponse?.status !== 'success'"
                                        (click)="submitCouponUpgrage(coupon_code.value)">Apply</button>
                                    <button class="alybtn"
                                        *ngIf="couponResponse?.status == 'success'">{{coupon_code.value}}
                                        <i class="fa fa-times" aria-hidden="true" (click)="removeCoupon()"></i>
                                    </button>

                                    <div class="comman"
                                        [ngClass]="{ 'successshow': couponResponse?.status == 'success', 'invalid-feedback': couponResponse?.status == 'error' } "
                                        *ngIf="couponResponse?.message">
                                        {{couponResponse?.message}}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 modfy2">

                            <div class="row">
                                <label class="col-12 col-sm-6 col-md-3 frmtxt mandatory">Payment Mode</label>
                                <div class="d-none d-md-block col-md-1">:</div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <select class="form-control myselectclasscat"
                                        data-error="Please select investor type." id="investorType12" name="inv_type"
                                        formControlName="payment_mode">
                                        <option disabled="" hidden="" selected="" value="">Select Payment Mode</option>
                                        <option value="OPTCRDC">Credit Card</option>
                                        <option value="OPTDBCRD">Debit Card</option>
                                        <option value="OPTNBK">Net Banking</option>
                                        <option value="Paytm">Paytm</option>

                                    </select>

                                    <div *ngIf="submitted && pFormUpgrade.payment_mode.errors" class="invalid-feedback">
                                        <div *ngIf="pFormUpgrade.payment_mode.errors.required">
                                            Please Choose Payment Mode</div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>


                    <app-alert></app-alert>

                    <div class="priclist businessPricelist" *ngIf="changeMentorAndStartupPlan==0">
                        <!-- basic start here  --> 
                        <div class="priclistinner" *ngIf="showBasicPlan">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="container_banner"><span>TRIAL</span></div>

                            <div class="inhieght">
                                <div class="shead">Trial Offer
                                     <input type="hidden" formControlName="membership_plan" value="0">
                                     <span class="dll"> Asking Price - less than 2Cr</span>
                                </div>
                               
                                <div class="mainpri"><i class="fa fa-inr" aria-hidden="true"></i> 999 <span>(+18% GST)</span></div>
                                <div class="mtxt">45 Days</div>

                                <div class="bntls">
                                    <input type="submit" value="Buy Now" class="btnpric"
                                        (click)="onChagePlanActive($event,0)" *ngIf="!formSubmitted">
    
                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                                        
                                </div>

                            </div>



<div class="pricetooltipfrm">
                                    <strong><i class="fa fa-check" aria-hidden="true"></i> Profile
                                        Activation<i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                    <span class="tooltiptextfrm">Get your profile activated within 5 working days of sharing all relevant information.</span>
                                </div>

                                 <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-check" aria-hidden="true"></i> <b>5 Business Proposals</b><i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                    <span class="tooltiptextfrm">Reach out to 5 Businesses/Startups registered with BusinessEx, based on your business requirements</span>
                                </div>
    
                                 <div class="pricetooltipfrm">
                                    <strong><i class="fa fa-check" aria-hidden="true"></i> Receive Unlimited Investment Proposals<i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                    <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Businesses/Startups registered with BusinessEx</span>
                                </div> 

                        </div>
                        <!-- basic end here  -->
                        <!-- Premium -->
                        <div class="priclistinner {{priclistinnerActive=='premium'?'active':''}}">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="inhieght">
                                <div class="shead">Premium <input type="hidden" formControlName="membership_plan"
                                        value="1">
                                        <span class="dll"> Asking Price - less than 2Cr</span>
                                </div>
                                

                                <div class="mainpri"><i class="fa fa-inr" aria-hidden="true"></i> 5,999 <span>(+18% GST)</span>
                                </div>

                                <!-- <div class="savcol"><i class="fa fa-inr" aria-hidden="true"></i>4000 Savings</div> -->

                                <div class="mtxt">3 Months</div>

                                <div class="bntls">
                                    <input type="submit" value="Buy Now" class="btnpric"
                                        (click)="onChagePlanActive($event,1)" *ngIf="!formSubmitted">
    
                                        <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;" >Loading....</span> 
                                        </button>
                                </div>
                            </div>
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Speedy Profile Activation<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Send 50 Business Proposals</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Reach out to 50 Businesses/Startups registered with BusinessEx, based on your business requirements.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Receive Unlimited Investment Proposals<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Business Buyers/Investors/Mentors registered with BusinessEx</span>
                            </div>  

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Continuous Email Support</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>
             
                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>10 Website Visitor Queries</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Unlock 10 Investment Proposals from Website Visitors who are interested in your listing.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Profile Views<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>    


                        </div>
                        <!--  -->
                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='gold'?'active':''}}">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 2 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="topset">
                                <div class="shbbtxt">Most Recommended</div>
                            </div>

                           
                            <div class="inhieght">
                                <div class="shead">Gold <input type="hidden" formControlName="membership_plan"
                                        value="2">
                                        <span class="dll"> Asking Price - less than 2Cr</span>    
                                </div>
                                    
                                

                                <div class="mainpri">
                                    <!-- <del class="dll"><i class="fa fa-inr" aria-hidden="true"></i> 23,999</del> -->
                                    <i class="fa fa-inr" aria-hidden="true"></i> 11,999 <span>(+18% GST)</span>
                                </div>

                                <!-- <div class="savcol"><i class="fa fa-inr" aria-hidden="true"></i> 4,000 Savings</div> -->

                                <div class="mtxt">12 Months</div>

                                <div class="bntls">
                                    <input type="submit" value="Buy Now" class="btnpric"
                                        (click)="onChagePlanActive($event,2)" *ngIf="!formSubmitted">
                                    
                                        <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                        </button>
                                </div>

                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Speedy Profile Activation <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b> 100 Business Proposals </b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Reach out to 100 Businesses/Startups registered with BusinessEx, based on your business requirements.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Receive Unlimited Investment Proposals<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Business Buyers/Investors/Mentors registered with BusinessEx</span>
                            </div>       
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Continuous Email Support</b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>20 Website Visitor Queries</b><i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                <span class="tooltiptextfrm">Unlock 20 Investment Proposals from Website Visitors who are interested in your listing.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Profile Views<i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>  


                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Profile Promotion <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms, along with dedicated marketing campaigns</span>
                            </div>  




                             <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> Key Account Manager <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Assignment of a KAM to manage your profile on BusinessEx</span>
                            </div>   -->

                        </div>
                        <!--  -->

                        <!-- platinym start -->
                        <div class="priclistinner {{priclistinnerActive=='platinum'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Platinum <input type="hidden" formControlName="membership_plan"
                                        value="3">
                                        <span class="dll"> Asking Price - no limit</span>
                                </div>
                               

                                <div class="mainpri"><i class="fa fa-inr" aria-hidden="true"></i> 99,999 <span>(+Brokerage and GST)</span>
                                </div>

                                <!-- <div class="savcol"><i class="fa fa-inr" aria-hidden="true"></i> 5,000 Savings</div> -->

                                <div class="mtxt">12 Months</div>


                            <div class="bntls">

                                    <!-- <input type="submit" value="Contact To Execute" class="btnpric"
                                    (click)="onChagePlanActive($event,3)" *ngIf="!formSubmitted"> -->

                                    <button type="button" class="btnpric" data-toggle="modal"
                                    data-target="#contactModel">Contact To Executive</button>

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                            </div>

                            <div class="pricetooltipfrm">
                                <b>Everything in Gold Plan</b>
                                <p><b>+</b></p>
                            </div> 

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Key Account Manager</b>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx</span>
                            </div> 

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Top Bussinesses/Startups</b>
                                    <i class="fa fa-info-circle" aria-hidden="true" ></i></strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div> 

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i> <b>Acceleration Marketing</b>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Accelerated Marketing: Upto 10,000 marketing emails sent to registered members of BusinessEx.</span>
                            </div>

                             <div class="pricetooltipfrm">
                                <strong><i class="fa fa-check" aria-hidden="true"></i><b>Business Valution </b><i class="fa fa-info-circle" aria-hidden="true"></i></strong>
                                <span class="tooltiptextfrm">Certified Business Valuation </span>
                            </div>


                        </div>
                        <!--  -->
                    </div>

                    <div class="priclist"  *ngIf="changeMentorAndStartupPlan==1">
                        <!-- basic start here  -->
                        <div class="priclistinner basicClass" *ngIf="showBasicPlan">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="inhieght">
                                <div class="shead">Basic <input type="hidden" formControlName="membership_plan"
                                        value="0"></div>
                                <!-- <del class="dll"><i class="fas fa-rupee-sign"></i> 1000</del> -->

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 999 <span>(+18% GST)</span></div>

                                <!--         <div class="savcol"><i class="fas fa-rupee-sign"></i> 500 Savings</div> -->
                                <div class="mtxt">1 Month</div>
                            </div>




                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile
                                    Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 5 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>
    <!--  -->
                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 5 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 5 Businesses/Startups registered with BusinessEx, based on your business requirements</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Businesses/Startups registered with BusinessEx</span>
                            </div>                         


                

                            

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,0)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!-- basic end here  -->
                        <!-- Premium -->
                        <div class="priclistinner premiumClass {{priclistinnerActive=='premium'?'active':''}}">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="inhieght">
                                <div class="shead">Premium<input type="hidden" formControlName="membership_plan"
                                        value="1"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 4,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 2,499 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 2500 Savings</div>

                                <div class="mtxt">3 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 50 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 50 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 10 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 10 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>   

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,1)" *ngIf="!formSubmitted">

                                
                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                        </button>
                            </div>

                        </div>
                        <!--  -->
                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='gold'?'active':''}}">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 2 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="topset">
                                <div class="shbbtxt">Most Recommended</div>
                            </div>

                            <div class="inhieght">
                                <div class="shead">Gold <input type="hidden" formControlName="membership_plan"
                                        value="2"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 7,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 3,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 4,000 Savings</div>

                                <div class="mtxt">6 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 100 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 100 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 20 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 20 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines and chooses Top Profiles</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>



                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,2)" *ngIf="!formSubmitted">
                                
                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                        </button>
                            </div>

                        </div>
                        <!--  -->

                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='platinum'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Platinum <input type="hidden" formControlName="membership_plan"
                                        value="3"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 11,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 5,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 6,000 Savings</div>

                                <div class="mtxt">9 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send Unlimited Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to Unlimited Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Unlimited Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock Unlimited Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div>
                             -->
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Dedicated marketing campaign for promotion.</strong>
                                <span class="tooltiptextfrm">Dedicated marketing campaign for promotion.</span>
                            </div> -->


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Monthly Reports along with analysis for potential improvements.</strong>
                                <span class="tooltiptextfrm">Monthly Reports along with analysis for potential improvements.</span>
                            </div> -->

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Top Businesses/Startups</strong>
                                <span class="tooltiptextfrm">Recommendation as “Top Recommended” on our website.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Accelerated Marketing: Upto 10,000 marketing emails sent to registered members of BusinessEx.</span>
                            </div>





    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Upto 10,000 marketing emails sent to registered members of BusinessEx</span>
                            </div>   -->


    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Business Valuation / Business Plan</strong>
                                <span class="tooltiptextfrm">Certified Business Valuation / Business Plan Report</span>
                            </div>   -->


                        

                    

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,3)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!--  -->
                    </div>

                    <div class="priclist"  *ngIf="changeMentorAndStartupPlan==2">
                        <!-- basic start here  -->
                        <div class="priclistinner basicClass" *ngIf="showBasicPlan">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="inhieght">
                                <div class="shead">Basic <input type="hidden" formControlName="membership_plan"
                                        value="0"></div>
                                <!-- <del class="dll"><i class="fas fa-rupee-sign"></i> 1000</del> -->

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 999 <span>(+18% GST)</span></div>

                                <!--         <div class="savcol"><i class="fas fa-rupee-sign"></i> 500 Savings</div> -->
                                <div class="mtxt">1 Month</div>
                            </div>




                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile
                                    Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 5 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>
    <!--  -->
                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 5 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 5 Businesses/Startups registered with BusinessEx, based on your business requirements</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Accept or reject unlimited Investment Proposals from genuine Businesses/Startups registered with BusinessEx</span>
                            </div>                         


                

                            

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,0)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                    <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!-- basic end here  -->
                        <!-- Premium -->
                        <div class="priclistinner premiumClass {{priclistinnerActive=='premium'?'active':''}}">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="inhieght">
                                <div class="shead">Premium<input type="hidden" formControlName="membership_plan"
                                        value="1"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 9,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 5,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 4,000 Savings</div>

                                <div class="mtxt">3 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 50 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 50 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 10 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 10 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div>   

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,1)" *ngIf="!formSubmitted">

                                
                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                        </button>
                            </div>

                        </div>
                        <!--  -->
                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='gold'?'active':''}}">

                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 2 && ProfileSelected?.membership_type != 3">
                            </div>

                            <div class="topset">
                                <div class="shbbtxt">Most Recommended</div>
                            </div>

                            <div class="inhieght">
                                <div class="shead">Gold <input type="hidden" formControlName="membership_plan"
                                        value="2"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 15,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 11,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 4,000 Savings</div>

                                <div class="mtxt">6 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send 100 Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to 100 Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> 20 Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock 20 Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines and chooses Top Profiles</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>




                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,2)" *ngIf="!formSubmitted">
                                
                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                        </button>
                            </div>

                        </div>
                        <!--  -->

                        <!-- Gold start -->
                        <div class="priclistinner {{priclistinnerActive=='platinum'?'active':''}}">

                            <div class="inhieght">
                                <div class="shead">Platinum <input type="hidden" formControlName="membership_plan"
                                        value="3"></div>
                                <del class="dll"><i class="fas fa-rupee-sign"></i> 74,999</del>

                                <div class="mainpri"><i class="fas fa-rupee-sign"></i> 39,999 <span>(+18% GST)</span>
                                </div>

                                <div class="savcol"><i class="fas fa-rupee-sign"></i> 35,000 Savings</div>

                                <div class="mtxt">6 months</div>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Speedy Profile Activation</strong>
                                <span class="tooltiptextfrm">Get your profile activated within 2 working days of sharing all relevant information.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Continuous Email Support</strong>
                                <span class="tooltiptextfrm">BusinessEx team is available to resolve any queries through email</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Send Unlimited Business Proposals</strong>
                                <span class="tooltiptextfrm">Reach out to Unlimited Business Buyers/Investors registered with BusinessEx, based on your business requirement.</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Receive Unlimited Investment Proposals</strong>
                                <span class="tooltiptextfrm">Receive Unlimited Investment Proposals from genuine Business Buyers/Investors registered with BusinessEx.</span>
                            </div>                         

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Unlimited Website Visitor Queries</strong>
                                <span class="tooltiptextfrm">Unlock Unlimited Investment Proposals from Website Visitors who are interested in your listing..</span>
                            </div>

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Real time information of Profile Views
                                </strong>
                                <span class="tooltiptextfrm">Real time information of Profile Views on your listing.</span>
                            </div> 

                                <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Profile Promotion</strong>
                                <span class="tooltiptextfrm">Profile promotions in regular newsletters and social media platforms.</span>
                            </div>  
                            
                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Key Account Manager</strong>
                                <span class="tooltiptextfrm">Assignment of a Key Account Manager (KAM) for your profile on BusinessEx.</span>
                            </div> -->
                            
                            
                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>BusinessEx refines</strong>
                                <span class="tooltiptextfrm">BusinessEx refines and chooses Top Profiles for your requirement and sends them your Investment Proposal.</span>
                            </div>


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Dedicated marketing campaign for promotion.</strong>
                                <span class="tooltiptextfrm">Dedicated marketing campaign for promotion.</span>
                            </div> -->


                            <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Monthly Reports along with analysis for potential improvements.</strong>
                                <span class="tooltiptextfrm">Monthly Reports along with analysis for potential improvements.</span>
                            </div> -->

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i>Top Businesses/Startups</strong>
                                <span class="tooltiptextfrm">Recommendation as “Top Recommended” on our website.</span>
                            </div>

                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Accelerated Marketing: Upto 10,000 marketing emails sent to registered members of BusinessEx.</span>
                            </div>


                            <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Business Plan</strong>
                                <span class="tooltiptextfrm">Business Plan Report</span>
                            </div>

                           





    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Accelerated Marketing</strong>
                                <span class="tooltiptextfrm">Upto 10,000 marketing emails sent to registered members of BusinessEx</span>
                            </div>   -->


    <!-- <div class="pricetooltipfrm">
                                <strong><i class="fa fa-hand-o-right" aria-hidden="true"></i> Business Valuation / Business Plan</strong>
                                <span class="tooltiptextfrm">Certified Business Valuation / Business Plan Report</span>
                            </div>   -->



                    

                            <div class="bntls">
                                <input type="submit" value="Buy Now" class="btnpric"
                                    (click)="onChagePlanActive($event,3)" *ngIf="!formSubmitted">

                                    <button type="button" class="btnpric" *ngIf="formSubmitted">
                                        <span style="color:#fe7806;">Loading....</span> 
                                    </button>
                            </div>

                        </div>
                        <!--  -->
                    </div>
                  


                </form>
            </div>

            <div class="col-12 col-sm-12 col-md-12">
                <!-- price features   start   -->
                <div class="feblkcontainer">

                    <div class="feblk_head"> <a href="#" onclick="return false;">Full Plan Comparison</a>
                        <!-- <span
                            class="cirplusminus minus"></span> -->
                    </div>

                    <div class="feblk_body" style="display: block;">
                        <ul class="priceplan">
                            <li>
                                <div class="reghead tleft">Feature</div>
                                <div class="iconblk htset tleft">Profile Activation</div>
                                <div class="iconblk htset tleft">Continuous Email Support</div>
                                <div class="iconblk htset tleft">Send Business Proposals</div>
                                <div class="iconblk htset tleft">Receive Business Proposals</div>
                                <div class="iconblk htset tleft">Unlock Website Visitor Queries</div>
                                <div class="iconblk htset tleft">Profile Views</div>
                                <div class="iconblk htset tleft">Profile Promotion</div>
                                <div class="iconblk htset tleft">Bussiness Plan</div>
                                <div class="iconblk htset tleft">Top Recommendations</div>
                                <div class="iconblk htset tleft">Accelerated Marketing</div>
                                <div class="iconblk htset tleft" *ngIf="!changeMentorAndStartupPlan">Business Valuation / Business Plan</div>
                            </li>

                            <li>
                                <div class="reghead">Basic</div>
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <div class="pvalue">5</div></div>
                                <div class="iconblk htset"> <div class="pvalue">Unlimited</div></div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset" *ngIf="!changeMentorAndStartupPlan"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                            </li>

                            <li>
                                <div class="reghead">Premium</div>
                                
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset">
                                    <div class="pvalue">50</div>
                                </div>
                                <div class="iconblk htset">
                                    <div class="pvalue">Unlimited</div>
                                </div>

                                <div class="iconblk htset">
                                    <div class="pvalue">10</div>
                                </div>

                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                 <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>                          
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                  <div class="iconblk htset" *ngIf="!changeMentorAndStartupPlan"> <i class="fa fa-minus" aria-hidden="true"></i> </div>  
                            </li>       
                            <li>
                                <div class="reghead">Gold</div>
                            
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset">
                                    <div class="pvalue">100</div>
                                </div>
                                                                <div class="iconblk htset">
                                    <div class="pvalue">Unlimited</div>
                                </div>
                                <div class="iconblk htset">
                                    <div class="pvalue">20</div>
                                </div>
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                                
                                <div class="iconblk htset" *ngIf="!changeMentorAndStartupPlan"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset" *ngIf="changeMentorAndStartupPlan!=2"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                                <div class="iconblk htset" *ngIf="changeMentorAndStartupPlan==2"> <i class="fa fa-minus" aria-hidden="true"></i> </div>

                      
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                                <div class="iconblk htset" *ngIf="!changeMentorAndStartupPlan"> <i class="fa fa-minus" aria-hidden="true"></i> </div>
                            </li>

                            <li>
                                <div class="reghead">Platinum</div>
                                
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset">
                                <div class="pvalue">Unlimited</div>
                                </div>
                                <div class="iconblk htset">
                                <div class="pvalue">Unlimited</div>
                                </div>
                                <div class="iconblk htset">
                                <div class="pvalue">Unlimited</div>
                                </div>



                                <div class="iconblk htset"><i class="fa fa-check" aria-hidden="true"></i></div>
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                               
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                                
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                                <div class="iconblk htset"> <i class="fa fa-check" aria-hidden="true"></i> </div>
                                <div class="iconblk htset" *ngIf="!changeMentorAndStartupPlan"> <i class="fa fa-check" aria-hidden="true"></i> </div>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <!--  end -->

        </div>
    </div>
</main>




<!-- contact -->
<div class="modal fade" id="contactModel" tabindex="-1" role="dialog" aria-labelledby="loginLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
       <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
          </button>
 
          <div class="modal-body">
               <app-contact-popup></app-contact-popup>
          </div>
       </div>
    </div>
 </div>