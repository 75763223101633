import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'payment-success',
    templateUrl: './payment-success.component.html'
})
export class PaymentSuccessComponent implements OnInit {

    constructor(public router: Router,
                private route: ActivatedRoute) {
    }

    title = 'Membership Payment Successful';
    message = 'Thank you !!! Your payment has been successfully done !!!';
    subheading = 'Please click on the link below to access the profile';
    dashboardLink;

    ngOnInit() {
        this.route.paramMap.subscribe((params: Params) => {
            // direct page access not allowed
            if (document.referrer === '') {
                this.router.navigate(['/']);
            }
        });
        this.route.queryParams.subscribe((params: Params) => {
            if (params['ref'] === 'bv') {
                this.title = 'Business Valuation Payment Successfully done';
                this.message = 'Thank you for making your payment.';
                this.subheading = 'A representative from our Team will get in touch with you shortly to let you know the next steps.';
                this.dashboardLink = false;
            } else {
                this.dashboardLink = true;
            }
        });
    }
}
