
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                                
<!-- Profile Detail Left Panel start here -->
    <profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
<div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
<div class="bdr">   
<div class="cat-list">

<div class="cat-sec-ttl">
    <div *ngIf="userProfileType?.reg_profile == 'Other' || userProfileType?.reg_profile == null || userProfileType?.reg_profile == ''">
        MANAGE FINANCIAL INFORMATION      
    </div>    
    <div *ngIf="userProfileType?.reg_profile == 'Broker'">
         <div><a routerLink="/dashboard/brokerbusinesslisting">Business Listing</a></div>
        {{BusinessAdvDetailsdata.advmt_headline}}                               
    </div>    
</div>    

<!-- search keywords start -->
<div class="colorstrip">
    <ul>
        <li><a routerLink="/dashboard/businessConfidential/{{profile_str}}">Confidential Info</a></li>
        <li><a routerLink="/dashboard/businessAdvDetails/{{profile_str}}">Advert Details</a></li>
        <li><a routerLink="/dashboard/businessInformation/{{profile_str}}">Business Info</a></li>
        <li class="selected"><a routerLink="/dashboard/businessFinanceDetails/{{profile_str}}">Financial Details</a></li>
        <li><a routerLink="/dashboard/businessTeamDetails/{{profile_str}}">Team Details</a></li>
        <li><a routerLink="/dashboard/businessLocationDetails/{{profile_str}}">Headquarters</a></li>
        <li><a routerLink="/dashboard/businessRequirement/{{profile_str}}">Requirements</a></li>
        <li><a routerLink="/dashboard/businessAttachment/{{profile_str}}">Attachments</a></li>
    </ul>
</div>
<!-- search keywords end -->

</div>         
<div class="margin-20">
<div class="row form-sec">
<div class="col-xs-12 col-sm-12 col-md-12">
<form #businessForm="ngForm" (ngSubmit)="businessForm.form.valid && businessMyAccountSubmit(businessForm.value)" class="form-horizontal"  name="businessForm" role="form">
<div class="lookingfr dflt">

    <div class="msg-pnl_sales" *ngIf="businessProfileSuccessError" style="margin-bottom:20px; text-align:center;">{{businessProfileSuccessError}}</div> 
    <div class="msg-pnl_sales" *ngIf="businessProfileFailError" style="margin-bottom:20px; text-align:center;">{{businessProfileFailError}}</div> 
     
    <!-- <div class="row">
        <div class="col-xs-12"><div class="frm-sec-ttl">Financial details</div></div>                                          
    </div> -->

    <div class="input-sec">                                        
        <div class="row txt-center">
            <div class="col-xs-12 col-sm-4  col-md-4">
                <div class="">
                    <div class="form-group rm-pad has-feedback">
                        <label class="col-xs-12 col-sm-12 col-md-12 control-label">Annual Sales<span class="star">*</span></label>
                        <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                            <div class="input-group">
                                <input type="text" pattern="[0-9]+" minlength="5" maxlength="12" class="form-control" name="annual_sales"  [ngModel]="BusinessFinancialdata?.annual_sales" placeholder="" data-error="Please enter annual sales." required>
                                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                </div>                                                                
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="">
                    <div class="form-group rm-pad">
                        <label class="col-xs-12 col-sm-12 col-md-12 control-label">Inventory Value<span class="star">*</span></label>
                        <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                            <div class="input-group">
                                <input type="text" pattern="[0-9]+" minlength="5" maxlength="12" name="inventory_value"  [ngModel]="BusinessFinancialdata?.inventory_value" class="form-control" placeholder="" data-error="Please enter inventory value." required>
                                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                            </div>  
                            <div class="help-block with-errors"></div>                                                              
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="">
                <div class="form-group rm-pad has-feedback">
                    <label class="col-xs-12 col-sm-12 col-md-12 control-label">Gross Income<span class="star">*</span></label>
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <div class="input-group">
                            <input pattern="[0-9]+" minlength="5" maxlength="12" class="form-control" name="gross_profit"  [ngModel]="BusinessFinancialdata?.gross_profit" placeholder="" type="text" data-error="Please enter gross income." required>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                </div>
            </div>                                                
        </div>
        <div class="row txt-center">
            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="">
                    <div class="form-group rm-pad has-feedback">
                        <label class="col-xs-12 col-sm-12 col-md-12 control-label">EBITDA<span class="star">*</span></label>
                        <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                            <div class="input-group">
                                <input type="text" pattern="[0-9]+" class="form-control" name="ebitda"  [ngModel]="BusinessFinancialdata?.ebitda" placeholder="" data-error="Please enter ebitda." required>
                                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="">
                    <div class="form-group rm-pad">
                        <label class="col-xs-12 col-sm-12 col-md-12 control-label">EBITDA Margin<span class="star">*</span></label>
                        <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                            <div class="input-group">
                                <input type="text" pattern="[0-9]+" minlength="1" maxlength="2" name="ebitda_margin" [ngModel]="BusinessFinancialdata?.ebitda_margin" class="form-control" placeholder="" data-error="Please enter ebitda margin." required>
                                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>                                                                
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="">
                    <div class="form-group rm-pad">
                        <label class="col-xs-12 col-sm-12 col-md-12 control-label">Rentals<span class="star">*</span></label>
                        <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                            <div class="input-group">
                                <input pattern="[0-9]+" minlength="5" maxlength="12" class="form-control" name="rentals"  [ngModel]="BusinessFinancialdata?.rentals" placeholder="" type="text" data-error="Please enter rentals." required>
                                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>                                     
<div class="sec-slide-effect txt-cen">                                  
    <div class="submitfrm">
        <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
    </div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>            
</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->