
import {Pipe} from '@angular/core';
import { ceil } from 'lodash';

@Pipe({
    name: 'readWordTime'
})
export class ReadWordTime {
    
    transform(value: any): any {

        if(value==null){
            return 0;
        }
        
        var timePerMinute = value.length / 400;
        let limit = timePerMinute ? timePerMinute : 0;
        return ceil(limit);
        

    }
}
