<div *ngIf="loading" class="loading">
    <div style="display:table-cell; vertical-align: middle;">
       <div class="sk-circle">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
       </div>
    </div>
 </div>
 
<main id="main">
    <!-- running   -->
    <div class="runbg">
        <div class="container bex-main">
            <ul class="brunnar">
                <li><a [routerLink]="['/']">Home</a></li>
                <li>/</li>
                <li><a [routerLink]="['/startupslisting']">Startup</a></li>
                <li>/</li>
                <li><a
                        [routerLink]="['/startupslisting/'+appConfig.getSlug(startupProfileData?.industry)+'-startups-in-India/'+startupProfileData?.parentCatId+'/s0/st0']">{{startupProfileData?.industry}}</a>
                </li>
                <li>/</li>
                <li class="breadcrumb-item"><a
                        [routerLink]="['/startupslisting/'+appConfig.getSlug(startupProfileData?.subindustry)+'-startups-in-India/'+startupProfileData?.parentCatId+'/'+startupProfileData?.catId+'/st0']">{{startupProfileData?.subindustry}}</a>
                </li>
                <li>/</li>
                <li class="breadcrumb-item active">{{startupProfileData?.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shortdes">
        <div class="container">
            <h1 class="headblk">{{ (startupProfileData?.title !== '') ? startupProfileData?.title : ("N/A") }}</h1>
            <p class="statictxt">{{ (startupProfileData?.description !== '') ? startupProfileData?.description : ("N/A")
            }}</p>
        </div>

        <div class="container">
            <span *ngIf="bxProposalResult?.status >= 2">
                <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                {{ (startupProfileData?.seller_company !== '') ? startupProfileData?.seller_company : ("N/A") }}
            </span>
            <span *ngIf="bxProposalResult?.status < 2">
                <i class="fa fa-lock" aria-hidden="true"></i>
                Business Name (Available after interaction)
            </span>
            <span
                *ngIf="startupProfileData?.city !== '' && startupProfileData?.state !== '' && startupProfileData?.country !== ''">
                <span class="pipe">|</span>
                <span class="location"><i class="fa fa-map-marker" aria-hidden="true"></i>
                    {{ (startupProfileData?.city !== '') ? startupProfileData?.city : ("N/A") }}
                    {{ (startupProfileData?.state !== '') ? startupProfileData?.state : ("N/A") }}
                    {{ (startupProfileData?.country !== '') ? startupProfileData?.country : ("N/A") }}
                </span>
                <span class="pipe">|</span>
                <span class="location">{{startupProfileData?.listedBy}}</span>
                <!-- <span class="pipe">|</span>
            <span class="location">Last Login {{startupProfileData.lastLogin}}</span> -->
            </span>
        </div>
    </div>





    <div class="tobnavblk" id="landfix">
        <div class="container">
            <ul class="tabScroll">
                <li>
                    <a [routerLink]="" id="businesssaleoverview_tab"
                        onclick="setPos('businesssaleoverview', this.id)" class="">Overview</a>
                </li>
                <li>
                    <a [routerLink]="" id="businesssaledetails_tab"
                        onclick="setPos('businesssaledetails', this.id)">Details</a>
                </li>

                <li>
                    <a [routerLink]="" id="businesssalefinancials_tab"
                        onclick="setPos('businesssalefinancials', this.id)">Financials</a>
                </li>

                <li>
                    <a [routerLink]="" id="businesssalerequirement_tab"
                        onclick="setPos('businesssalerequirement', this.id)">Requirement</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="detailcontent">
        <div class="container">
            <div class="row">

                <div class="col-12 col-sm-12 col-md-9">

                    <ul class="mainsecdet">
                        <li>
                            <span class="brk-word">{{startupProfileData?.priceLabel}}</span>
                            <span class="keynote" *ngIf="startupProfileData?.askingPrice !== 0">
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                {{ startupProfileData?.askingPrice }}
                            </span>
                            <span class="keynote" *ngIf="startupProfileData?.askingPrice == 0">
                                {{ "N/A" }}
                            </span>
                        </li>
                        <li class="linesh">|</li>
                        <li><span>Annual Sales/Turnover</span>
                            <span class="keynote" *ngIf="startupProfileData?.annualsale !== 0">
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                {{ startupProfileData?.annualsale | priceShort }}
                            </span>
                            <span class="keynote" *ngIf="startupProfileData?.annualsale == 0">
                                {{ "N/A" }}
                            </span>
                        </li>
                        <li class="linesh">|</li>
                        <li><span>Gross Income</span>
                            <span class="keynote" *ngIf="startupProfileData?.grossprofit !== 0">
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                {{ startupProfileData?.grossprofit | priceShort }}
                            </span>
                            <span class="keynote" *ngIf="startupProfileData?.grossprofit == 0">
                                {{ "N/A" }}
                            </span>
                        </li>
                    </ul>

                    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

                        <ol class="carousel-indicators">
                            <li data-target="#carousel-example-generic" *ngFor="let image of startupImages;let i=index;"
                                [attr.data-slide-to]="i" [ngClass]="{'active': i==0}"></li>
                        </ol>

                        <div class="carousel-inner">

                            <div *ngFor="let image of startupImages;let j=index;" class="carousel-item"
                                [ngClass]="{'active': j==0}">
                                <img [src]="image.imageUrl" *ngIf="j==0" title="{{image.title}}"
                                    alt="{{image.imageName!=undefined?image.imageName:image.title}}" itemprop="image"
                                    itemscope="" itemtype="https://schema.org/ImageObject">
                                <img [src]="image.imageUrl" *ngIf="j!=0"
                                    alt="{{image.imageName!=undefined?image.imageName:image.title}}">
                                <div class="carousel-caption">{{image.title}}</div>
                            </div>

                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>

             

                        <div class="ribbonblk finalpage" *ngIf="startupProfileData?.membership_paid==1">
                                    <div class="ribbonblkinner" *ngIf="startupProfileData?.membership_plan==1">Premium</div>
                                    <div class="ribbonblkinner" *ngIf="startupProfileData?.membership_plan==2">Premium</div>
                                    <div class="ribbonblkinner" *ngIf="startupProfileData?.membership_plan==3">Platinum</div>
                                 </div>

                    </div>


                    <div class="fulldetailpage">
                        <div class="tab-content">

                            <div class="" id="businesssaleoverview">
                                <!-- Start here -->
                                <div class="boxsect">
                                    <div class="boxsecthead">Overview</div>
                                    <div class="fullshblk">
                                        <div class="">

                                            <div class="indetailmodfy"
                                                *ngIf="startupProfileData?.company_summary !== null">
                                                <span class="ds1">Summary </span>
                                                <span class="ds2">
                                                    <div class="overview"
                                                        [innerHTML]="startupProfileData?.company_summary">
                                                    </div>
                                                </span>
                                            </div>

                                            <div class="indetailmodfy"
                                                *ngIf="startupProfileData?.facilities_desc !== null">
                                                <span class="ds1">Facilities </span>
                                                <span class="ds2">
                                                    <div class="overview"
                                                        [innerHTML]="startupProfileData?.facilities_desc"></div>
                                                </span>
                                            </div>


                                            <div class="indetailmodfy"><span class="ds1">Director/CEO
                                                    Information</span><span class="ds2">

                                                   

                                                    <div class="locked-ins" *ngIf="bxProposalResult?.status < 2">
                                                        <i class="fa fa-lock" aria-hidden="true"></i>
                                                        Available after Interaction
                                                    </div>

                                                    <div class="inafter" *ngIf="bxProposalResult?.status >= 2">

                                                        <i class="fa fa-unlock" aria-hidden="true"></i>
                                                        
                                                        <ul class="innerlab">
                                                            <li><label>Name</label></li>
                                                            <li><label>Designation</label></li>
                                                            <li><label>Email ID</label></li>
                                                        </ul>

                                                        <ul class="innerlab">
                                                            <li>{{ (startupProfileData.director_name !== '') ?
                                                                startupProfileData?.director_name : ("N/A") }}</li>
                                                            <li>{{ (startupProfileData.director_designation !== '') ?
                                                                startupProfileData?.director_designation : ("N/A") }}
                                                            </li>
                                                            <li><a href="{{ (startupProfileData.director_email !== '') ?
                                                                startupProfileData?.director_email : ('N/A') }">{{ (startupProfileData.director_email !== '') ?
                                                                startupProfileData?.director_email : ("N/A") }}</a>
                                                            </li>
                                                        </ul>
                                                    </div>


                                                </span>
                                            </div>

                                            <div class="indetailmodfy">
                                                <span class="ds1">Management Information</span>

                                                <span class="ds2">
                                                    <div class="locked-ins" *ngIf="bxProposalResult?.status >= 2">
                                                       

                                                        <span *ngIf="sellerMgtTeamDetails.length == 0">N/A</span>

                                                        <div class="inafter" *ngIf="sellerMgtTeamDetails?.length != 0">
                                                            <ul class="innerlab">
                                                                <li><label>Name</label></li>
                                                                <li><label>Designation</label></li>
                                                                <li><label>Email ID</label></li>
                                                            </ul>

                                                            <ul class="innerlab"
                                                                *ngFor="let sellerMgt of sellerMgtTeamDetails">
                                                                <li>{{ (sellerMgt.mgmt_name !== '') ? sellerMgt.mgmt_name : ("N/A") }}
                                                                </li>
                                                                <li>{{ (sellerMgt.mgmt_designation !== '') ? sellerMgt.mgmt_designation
                                                                : ("N/A") }}</li>
                                                                <li><a
                                                                        href="
                                                                    {{ (sellerMgt.mgmt_email !== '') ? sellerMgt.mgmt_email : ('N/A') }}">{{ (sellerMgt.mgmt_email !== '') ? sellerMgt.mgmt_email : ("N/A") }}</a>
                                                            </li>
                                                        </ul>

                                                    </div>
                                            </div>

                                            <div class="locked-ins" *ngIf="bxProposalResult?.status < 2">
                                                <i class="fa fa-lock" aria-hidden="true"></i>
                                                Available after Interaction
                                            </div>

                                            </span>
                                        </div>

                                        <div class="indetailmodfy">
                                            <span class="ds1">Business Documents </span>

                                            <span class="ds2">
                                                <div class="download-documents" *ngIf="bxProposalResult?.status >= 2">
                                                    <i class="fa fa-lock" aria-hidden="true"></i>
                                                    <ul>
                                                        <li *ngFor="let document of businessDocuments;let j=index;">
                                                            <div id="business-doc{{j}}" class="doc-name ellipsis"
                                                                onmouseover="scrollText(this.id);"
                                                                onmouseout="returnScroll(this.id);">
                                                                {{appConfig.getDocumentName(document)}}
                                                            </div>
                                                            <div><a href="{{document}}" target="_blank"><i
                                                                        class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                    Download</a></div>
                                                        </li>
                                                    </ul>

                                                </div>
                                                <div class="locked-ins" *ngIf="bxProposalResult?.status < 2">
                                                    <i class="fa fa-lock" aria-hidden="true"></i>
                                                    Available after Interaction
                                                </div>
                                            </span>

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <!-- End here  -->


                        </div>

                        <div class="" id="businesssaledetails">
                            <!-- Start here -->
                            <div class="boxsect">
                                <div class="boxsecthead">Details</div>
                                <div class="fullshblk">
                                    <div class="">

                                        <!--    <div class="sminhead">Individual</div> -->
                                        <div class="indetailmodfy"><span class="ds1">Establishment Year</span><span
                                                class="ds2">{{ (startupProfileData?.estb_date !==
                                                        0) ? startupProfileData?.estb_date : ("Undisclosed") }}
                                            </span></div>
                                        <div class="indetailmodfy"><span class="ds1">Employees</span><span class="ds2">{{ (startupProfileData?.emp_count !==
                                                        0) ? startupProfileData?.emp_count : ("Undisclosed")
                                                        }}</span></div>
                                        <div class="indetailmodfy"><span class="ds1">Entity Type</span><span
                                                class="ds2">{{
                                                        (startupProfileData?.nature_of_entity !== 0) ?
                                                        startupProfileData?.nature_of_entity : ("Undisclosed")
                                                        }}
                                            </span></div>
                                        <div class="indetailmodfy"><span class="ds1">Business Sector</span><span
                                                class="ds2">{{ (startupProfileData?.industry !==
                                                        '') ? startupProfileData?.industry : ("Undisclosed")
                                                        }}
                                                - {{ (startupProfileData?.subindustry !== '') ?
                                                        startupProfileData?.subindustry : ("Undisclosed") }}</span>
                                        </div>
                                        <div class="indetailmodfy"><span class="ds1">Business Type</span><span
                                                class="ds2">{{ (startupProfileData?.business_type
                                                        !== 0) ? startupProfileData?.business_type :
                                                        ("Undisclosed") }}</span></div>
                                        <div class="indetailmodfy"><span class="ds1">Website</span><span class="ds2"><i
                                                    class="fa fa-lock" aria-hidden="true"></i>

                                                <div class="key-val" *ngIf="bxProposalResult?.status >= 2">{{
                                                    (startupProfileData?.business_website !== null) ?
                                                    startupProfileData?.business_website : ("N/A") }}
                                                </div>
                                                <div class="key-val" *ngIf="bxProposalResult?.status < 2"><i
                                                        aria-hidden="true" class="fa fa-lock"
                                                        style="color:#2377d3;"></i> Available after
                                                    interaction
                                                </div>

                                            </span></div>
                                        <div class="indetailmodfy"><span class="ds1">Social Media Links</span><span
                                                class="ds2"><i class="fa fa-lock" aria-hidden="true"></i> Available
                                                after Interaction</span></div>
                                    </div>

                                </div>

                            </div>
                            <!-- End here  -->
                        </div>

                        <div class="" id="businesssalefinancials">

                            <!-- Start here -->
                            <div class="boxsect">
                                <div class="boxsecthead">Financial</div>
                                <div class="fullshblk">
                                    <div class="">

                                        <div class="indetailmodfy">
                                            <span class="ds1">Annual Sales</span>
                                            <span class="ds2" *ngIf="startupProfileData?.annualsale !== 0">
                                                <i class="fa  fa-rupee-sign" aria-hidden="true"></i>
                                                {{ startupProfileData?.annualsale | priceShort }}
                                            </span>
                                            <span class="ds2" *ngIf="startupProfileData?.annualsale == 0">
                                                {{ "N/A" }}
                                            </span>

                                        </div>

                                        <div class="indetailmodfy">
                                            <span class="ds1">EBITDA</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i>
                                                {{ (startupProfileData?.ebitda !== 0) ?
                                                        startupProfileData?.ebitda : ("Undisclosed") }}</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">EBITDA Margin</span>
                                            <span class="ds2">{{(startupProfileData?.ebitdamargin
                                                    !== 0) ? startupProfileData?.ebitda_margin :
                                                    ("Undisclosed") }}</span>
                                        </div>

                                        <div class="indetailmodfy">
                                            <span class="ds1">Inventory Value</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i> {{(startupProfileData?.inventory_value
                                                    !== null) ? startupProfileData?.inventory_value :
                                                    ("Undisclosed") }}
                                            </span>
                                        </div>

                                        <div class="indetailmodfy">
                                            <span class="ds1">Rentals</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i>
                                                {{(startupProfileData?.rentals !==
                                                        null) ? startupProfileData?.rentals : ("Undisclosed") }}</span>
                                        </div>

                                        <div class="indetailmodfy"><span class="ds1">Gross Income</span>

                                            <span class="ds2" *ngIf="startupProfileData?.grossprofit !== 0">
                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                {{ startupProfileData?.grossprofit | priceShort }}
                                            </span>
                                            <span class="ds2" *ngIf="startupProfileData?.grossprofit == 0">
                                                {{ "N/A" }}
                                            </span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- End here  -->

                        </div>

                        <div class="" id="businesssalerequirement">

                            <div class="boxsect">
                                <div class="boxsecthead">Business Requirement</div>
                                <div class="fullshblk">
                                    <div class="halkblknew">
                                        <div class="indetailmodfy"><span class="ds1">One-line Business
                                                Pitch</span><span class="ds2"> {{ (startupProfileData?.business_pitch !==
                                                '') ?
                                                startupProfileData?.business_pitch : ("N/A") }}</span>
                                        </div>
                                    </div>


                                    <div class="halkblknew" *ngIf="startupProfileData?.seeking_buyers == '1'">
                                        <div class="sminhead">Requirement</div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Looking For</span>
                                            <span class="ds2"> Sale</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Amount</span>
                                            <span class="ds2">
                                                <i class="fas fa-rupee-sign"></i>
                                                {{startupProfileData?.saleAmount}}</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Reason</span><span class="ds2">
                                                {{startupProfileData?.buyer_sell_reason}}</span>
                                        </div>
                                    </div>


                                    <div class="halkblknew" *ngIf="startupProfileData?.seeking_investors == 1">
                                        <div class="sminhead">Requirement</div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Looking For</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i> Investment</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Amount</span><span class="ds2"><i
                                                    class="fas fa-rupee-sign"></i>
                                                {{startupProfileData?.investmentsize}} <span
                                                    *ngIf="startupProfileData?.inv_stake">at
                                                    {{startupProfileData?.inv_stake}} % stake</span>
                                            </span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Reason</span><span class="ds2">
                                                {{startupProfileData?.inv_reason}}</span>
                                        </div>
                                    </div>


                                    <div class="halkblknew" *ngIf="startupProfileData?.seeking_loan != 0">
                                        <div class="sminhead">Requirement</div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Looking For</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i> Loans</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Amount</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i>
                                                <span>{{startupProfileData?.loanAmount}} <span
                                                        *ngIf="startupProfileData?.loan_interest_rate"> at
                                                        {{startupProfileData?.loan_interest_rate}} % interest
                                                        rate</span></span>
                                            </span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Reason</span><span class="ds2">
                                                {{startupProfileData?.loan_reason}}</span>
                                        </div>

                                        <div class="indetailmodfy"
                                            *ngIf="startupProfileData?.loan_collateral_details !== null">
                                            <span class="ds1">Collateral Details</span><span class="ds2">
                                                {{startupProfileData?.loan_collateral_details}</span>
                                            </div>

                                            <div class="indetailmodfy" *ngIf="startupProfileData?.loan_repayment_period !== null">
                                                <span class="ds1">Possible Repayment Period</span><span class="ds2">
                                                    {{startupProfileData?.loan_repayment_period}}</span>
                                        </div>

                                    </div>


                                    <div class="halkblknew" *ngIf="startupProfileData?.seeking_mentors == 1">
                                        <div class="sminhead">Support</div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Looking For</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i> Mentorship</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Field of Support</span>
                                            <span class="ds2">
                                                <span class="comma" *ngFor="let mentorSupport of sellerMentorSupport">
                                                    {{mentorSupport.mentor_category_name}}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Support Req.</span><span class="ds2">
                                                {{startupProfileData?.mentor_req_details}}</span>
                                        </div>


                                    </div>


                                    <div class="halkblknew" *ngIf="startupProfileData?.seeking_accelerators == 1">
                                        <div class="sminhead">Support</div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Looking For</span>
                                            <span class="ds2"><i class="fas fa-rupee-sign"></i> Incubator
                                                Support</span>
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Field of Support</span>
                                            (for {{startupProfileData?.accel_time_period}})
                                        </div>
                                        <div class="indetailmodfy">
                                            <span class="ds1">Support Req.</span>
                                            <span class="comma" *ngFor="let accelSupport of sellerAccelSupport">
                                                {{accelSupport.mentor_category_name}}
                                            </span>
                                        </div>


                                    </div>


                                </div>
                            </div>
                            <!-- End here  -->





                        </div>

                        <!-- <div class="btnconatblk">
                                <a href="#contactfrm" class="btnconat">Contact Startup </a>
                            </div> -->

                        <div class="btnconatblk"
                            *ngIf="profilePendingStatus != '' && profileActiveStatus == '' && moderatorContactStatus !=4 ">
                            <a [routerLink]="[appConfig.getPendingProfileUrl(profilePendingStatus, pendingProfileStr)]"
                                class="btnconat">contact </a>
                        </div>


                    </div>
                </div>

            </div>


            <div class="col-12 col-sm-12 col-md-3">


                <!-- If User have no profile, creating required profile for sending insta query-->
                <div id="contactform" class="contact-seller bm-mark form-sec sec-slide-effect"
                    *ngIf="(ProfileCheck == 0 || moderatorContactStatus == 4) && bxContactCount == 0 && singleDayCount < singleDayLimit && startupProfileExistCheck == ''">
                    <div class="contact-ttl">{{title}}</div>

                    <div class="msg-pnl_sales" *ngIf="instaContactError" style="text-align:center;">
                        <div *ngFor="let message of instaContactError | keyspipe : true">
                            {{message}}
                        </div>
                    </div>

                    <form name="contactFormStartup" #contactFormStartup="ngForm"
                        (ngSubmit)="contactFormStartupSubmit(contactFormStartup.value, startupProfileData?.userId, startupProfileData?.businessUniqStr)"
                        class="form-horizontal contact-seler-form" role="form">
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Full
                                Name<span class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-7">
                                <div class="input-group">
                                    <input type="text" name="contact_name" #contact_name="ngModel" ngModel
                                        class="form-control" placeholder="Enter Name" data-error="Please enter name."
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Designation<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-7">
                                <div class="input-group">
                                    <input type="text" name="contact_designation" #contact_designation="ngModel" ngModel
                                        class="form-control" placeholder="Enter Designation"
                                        data-error="Please enter designation." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Mobile<span class="star">*</span>
                                <span>:</span></label>
                            <div class="col-xs-12 col-sm-7">
                                <div class="input-group">
                                    <input type="text" pattern="[56789][0-9]{9}" minlength="10" maxlength="10"
                                        onkeypress="return isNumber(event)" name="contact_mobile"
                                        #contact_mobile="ngModel" ngModel class="form-control"
                                        placeholder="Enter Mobile" data-error="Please enter valid mobile number."
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Email<span class="star">*</span>
                                <span>:</span></label>
                            <div class="col-xs-12 col-sm-7">
                                <div class="input-group">
                                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        name="contact_email" #contact_email="ngModel" ngModel class="form-control"
                                        placeholder="Enter Email" data-error="Please enter email." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Company<span class="star">*</span>
                                <span>:</span></label>
                            <div class="col-xs-12 col-sm-7">
                                <div class="input-group">
                                    <input type="text" name="contact_company" #contact_company="ngModel" ngModel
                                        class="form-control" placeholder="Enter Company"
                                        data-error="Please enter company." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Investment<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-7">
                                <div class="input-group">
                                    <input type="text" pattern="[0-9]+" minlength="5" maxlength="12"
                                        onkeypress="return isNumber(event)" name="contact_investment"
                                        #contact_investment="ngModel" ngModel class="form-control"
                                        placeholder="Enter Contribution" data-error="Please enter valid contribution."
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-5 col-md-5 control-label">Purchase Time<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <select class="form-control" name="contact_purchase_time"
                                        #contact_purchase_time="ngModel" data-error="Please select purchase time"
                                        required ngModel>
                                        <option value="" disabled selected hidden>Select Purchase Time
                                        </option>
                                        <option *ngFor="let purchaseTime of purchase_time" [value]="purchaseTime.name">
                                            {{purchaseTime.name}}
                                        </option>
                                    </select>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Profile Type <span
                                    class="star">*</span><span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <select class="form-control" name="profile_type" #profile_type="ngModel"
                                        data-error="Please select profile type" required ngModel>
                                        <option value="" disabled selected hidden>Select Profile Type
                                        </option>
                                        <option *ngFor="let profileType of contact_profile_type"
                                            [value]="profileType.id">{{profileType.name}}
                                        </option>
                                    </select>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <div class="col-xs-12 col-sm-12">
                                <div class="input-group">
                                    <textarea class="form-control" onkeypress="return lettersOnly(event)"
                                        name="contact_comment" #contact_comment="ngModel" ngModel rows="3"
                                        placeholder="Enter your Comment" data-error="Please enter comment."
                                        required></textarea>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <div class="col-xs-12 col-sm-12">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" name="subscribe" #subscribe="ngModel" checked="checked"
                                            ngModel> Subscribe for Daily Updates
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group rm-margin">
                            <div class="col-xs-12 col-sm-12 btn-center">
                                <button type="submit" class="contact-btn mrgn-top">Contact Startup
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="bookmark-landing">
                        <a [routerLink]="">
                            <i *ngIf="bxBookmarkStatus === 0 " (click)="toggle(1)" class="fa fa-bookmark-o fa-6"
                                aria-hidden="true"></i>
                            <i *ngIf="bxBookmarkStatus === 1 " (click)="toggle(0)" class="fa fa-bookmark"
                                aria-hidden="true"></i>Bookmark
                        </a>
                    </div>

                </div>
                <!-- If User have no profile, creating profile for sending insta query end here-->

                <div *ngIf="startupProfileExistCheck == ''">

                    <!-- Single/total day contact profile limit exceeded -->
                    <div *ngIf="(ProfileCheck == 0 || moderatorContactStatus == 4) && bxContactCount != 1 &&
                            (singleDayCount >= singleDayLimit || totalCount >= overallLimit)"
                        class="contact-seller  form-sec sec-slide-effect">
                        <div class="contact-ttl">Contact Startup</div>
                        <div class="added-info btnpad">
                            <span *ngIf="ProfileCheck == 0">
                                {{contactLimitExceedMessage}}
                                Please <a [routerLink]="['/registration/quick-registration']">create a profile</a>
                                to send more queries.
                            </span>
                            <span *ngIf="moderatorContactStatus == 4">
                                {{contactLimitExceedMessage}}
                                Get Your profile <a [routerLink]="['/myaccount/dashboard']">activated</a> to send more
                                queries.
                            </span>
                        </div>
                    </div>
                    <!-- Single day contact profile limit exceeded -->

                    <!-- Already contacted message after contact business form -->
                    <div *ngIf="(ProfileCheck == 0 || moderatorContactStatus == 4) && bxContactCount == 1"
                        id="pendingContactedProposal" class="contact-seller  form-sec sec-slide-effect">
                        <div class="contact-ttl">Contact Startup</div>
                        <div class="added-info btnpad">You have already contacted this Profile.</div>
                    </div>
                    <!-- Already contacted message after contact business form -->

                    <!-- Thank you message after sending insta query Here -->
                    <div class="contact-seller form-sec sec-slide-effect" id="contactthankyou" style="display: none;"
                        *ngIf="userID !== 0 && (ProfileCheck == 0 || moderatorContactStatus == 4)">
                        <div class="contact-ttl">{{title}}</div>
                        <div class="added-info btnpad">Your details have been shared with the Business.
                            You will be directly contacted by the Business through your shared Email ID
                            and Mobile Number, if they are interested in your proposal.
                        </div>
                    </div>
                    <!-- Thank you message after sending insta query End Here -->

                    <!-- If Profile is pending start here -->
                    <div class="contact-seller  form-sec sec-slide-effect"
                        *ngIf="profilePendingStatus !== '' && profileActiveStatus == '' &&  moderatorContactStatus != 4">
                        <div class="added-info btnpad">Complete your profile to contact</div>
                        <div class="inbtn">
                            <a [routerLink]="[appConfig.getPendingProfileUrl(profilePendingStatus, pendingProfileStr)]"
                                class="contact-btn">Click here</a>
                        </div>
                    </div>
                    <!-- If Profile is pending end here -->

                    <!-- If User have at least one profile but required profile for sending proposal not exist -->
                    <div class="contact-seller  form-sec sec-slide-effect"
                        *ngIf="ProfileCheck != 0 && startupProfileExistCheck == '' && profileActiveStatus == '' && profilePendingStatus == ''">
                        <div class="added-info btnpad">To contact this business, Please create a
                            Investor/Mentor/Lender/Incubation profile
                        </div>
                        <div class="listed-by">
                            <div class="create-prf">Please create a profile for better Business
                                Opportunity.
                            </div>
                            <form #contactProfileTypeForm="ngForm" name="contactProfileTypeForm"
                                (ngSubmit)="contactProfileTypeSubmit(contactProfileTypeForm.value)"
                                class="form-horizontal contact-seler-form" role="form">
                                <div class="form-group">
                                    <label for="profile_type" class="col-xs-12 col-sm-5 col-md-5 control-label">Profile
                                        Type <span>:</span></label>
                                    <div class="col-xs-12 col-sm-7">
                                        <select class="form-control" name="profile_type" id="profile_type"
                                            #profile_type="ngModel" ngModel data-error="Please select profile."
                                            required>
                                            <option *ngFor="let profType of contact_profile_type"
                                                [value]="profType.name" [selected]="profType.name == '1'">
                                                {{profType.name}}
                                            </option>
                                        </select>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="form-group mgn-tp">
                                    <div class="col-xs-12 col-sm-12 btn-center">
                                        <button type="submit" class="contact-btn mrgn-top">Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- If User have at least one profile but required profile for sending proposal not exist end here -->

                </div>

                <!-- Business Proposal Section Start Here For Basic Plan Seller -->
                <div id="startupproposalForm" class="contact-seller bm-mark form-sec sec-slide-effect"
                    *ngIf="profileActiveStatus != '' && startupProfileExistCheck == '' && userID !== 0 && ProfileCheck != 0 && bxProposalResult?.count == 0">
                    <div class="contact-ttl">{{title}}</div>

                    <div class="msg-pnl_sales" *ngIf="instaProposalError" style="text-align:center;">
                        <div *ngFor="let message of instaProposalError | keyspipe : true">
                            {{message}}
                        </div>
                    </div>

                    <form name="contactProposalForm" #contactProposalForm="ngForm"
                        (ngSubmit)="contactProposalFormSubmit(contactProposalForm.value, startupProfileData?.userId, startupProfileData?.businessUniqStr)"
                        class="form-horizontal contact-seler-form" role="form">
                        <div class="form-group has-feedback">
                            <div class="">
                                <div class="input-group">
                                    <select class="form-control" name="ProfileSelected" #ProfileSelected="ngModel"
                                        ngModel (ngModelChange)="onChangeObj($event)"
                                        data-error="Please select profile." required>
                                        <option value="" disabled selected hidden>Select Profile
                                        </option>
                                        <option *ngFor="let Profiledata of Profile | sellerProfileTypeFilter"
                                            [value]="Profiledata.profile_type">
                                            {{Profiledata.profile_name}}
                                        </option>
                                    </select>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>

                        <div class="mainPayment">
                            <div class="abr" *ngIf="profileCreditBalance <= 0">
                                Your don't have any credit. Kindly Upgrade your profile.
                            </div>
                            <div class="form-group has-feedback">
                                <div class="">
                                    <div class="input-group">
                                        <textarea class="form-control" onkeypress="return lettersOnly(event)" rows="3"
                                            name="msg" #msg="ngModel" ngModel placeholder="Enter your Comment"
                                            data-error="Please enter comment." required></textarea>
                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                    </div>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-xs-12 col-sm-12 btn-center">
                                    <button type="submit" class="contact-btn">Contact Startup</button>
                                </div>
                            </div>
                        </div>

                        <!--don't have any credit, showing upgrade message start here-->
                        <div class="form-group added-info" *ngIf="profileCreditBalance <= 0">
                            <div class="col-xs-12 col-sm-12 btn-center">
                                <a [routerLink]="['/dashboard/upgradeInv/'+invUpgProfUniqStr]" class="contact-btn">Upgrade
                                    Plan</a>
                            </div>
                        </div>
                        <!--don't have any credit, showing upgrade message end here-->

                        <div class="bookmark-landing">
                            <a [routerLink]="">
                                <i *ngIf="bxBookmarkStatus === 0 " (click)="toggle(1)" class="fa fa-bookmark-o fa-6"
                                    aria-hidden="true"></i>
                                <i *ngIf="bxBookmarkStatus === 1 " (click)="toggle(0)" class="fa fa-bookmark"
                                    aria-hidden="true"></i> Bookmark
                            </a>
                        </div>
                    </form>
                </div>
                <!-- Business Proposal Section End Here For Basic Plan Seller -->

                <div *ngIf="startupProfileExistCheck == ''">

                    <!-- Membership plan upgrade section start here -->
                    <!--<div class="contact-seller  form-sec sec-slide-effect"
                                         *ngIf="userID !== 0 && bxProposalResult?.status ==0 && profileActiveStatus !='' &&  ProfileCheck != 0 && ((profileMembership == 0 && totalbxCount > 4) ||
                                         (profileMembership == 1 && totalbxCount > 49) || (profileMembership == 2 && totalbxCount > 99) ||
                                         (profileMembership == 4 && totalbxCount > 49))">
                                        <div class="contact-ttl">{{title}}</div>
                                        <div class="added-info btnpad">You don't have any credit limits So please
                                            Upgrade your plan.
                                        </div>
                                        <div><a routerLink="/dashboard/upgradeInv/{{invUpgProfUniqStr}}"
                                                class="contact-btn">Upgrade Plan</a></div>
                                    </div>-->

                    <!-- Membership plan upgrade section end here -->

                    <!-- Thank you message after business proposal -->
                    <div id="thankyouproposal" class="contact-seller  form-sec sec-slide-effect" style="display:none;">
                        <div class="contact-ttl">{{title}}</div>
                        <div class="added-info btnpad">
                            Your details have been shared with the Business.
                            If they are interested, you'll get a notification in your BX Inbox. You'll
                            also be reached by the person through your registered Email ID or Mobile
                            number
                        </div>
                    </div>
                    <!-- Thank you message after business proposal -->

                    <!-- Already contacted message after business proposal -->
                    <div *ngIf="bxProposalResult?.count == '1' && bxProposalResult?.status == '1'"
                        id="pendingContactedProposal1" class="contact-seller  form-sec sec-slide-effect">
                        <div class="contact-ttl">Contact Startup</div>
                        <div class="added-info btnpad">You have already contacted this Profile.</div>
                    </div>
                    <!-- Already contacted message after business proposal -->

                    <!-- After business proposal accepted start here -->
                    <div *ngIf="bxProposalResult?.count == '1' && bxProposalResult?.status == '2'"
                        id="activeContactedProposal" class="contact-seller  form-sec sec-slide-effect">
                        <div class="inbtn"><a [routerLink]="['/dashboard/myinteraction']" class="contact-btn">GO TO BX
                                MAIL</a></div><br>
                        <div class="added-info">Your proposal has been accepted, Click the above link to
                            continue the Interaction
                        </div>
                        <div class="listed-by">
                            <div class="profile-pic">
                                <img class="img-responsive"
                                    *ngIf="startupProfileData?.startup_prof_pic != '' && startupProfileData?.startup_prof_pic != null"
                                    src={{startupProfileData.startup_prof_pic}} alt="profile pic" />
                                <img class="img-responsive"
                                    *ngIf="startupProfileData?.startup_prof_pic == '' || startupProfileData?.startup_prof_pic === null"
                                    src="assets/images/userprofilepic.gif" alt="profile pic" />
                            </div>
                            <div class="profile-detail">
                                <div class="title">Business Listed By:</div>
                                <div class="name">{{startupProfileData?.startup_name}}</div>
                                <div class="position">{{startupProfileData?.startup_designation}}</div>
                                <div class="industry">{{startupProfileData?.name_of_entity}}</div>
                                <div class="contacts">
                                    <div class="number">Mobile: <span>{{startupProfileData?.startup_mobile}}</span>
                                    </div>
                                    <div class="email">
                                        Email:<span>{{startupProfileData?.startup_email}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- After business proposal accepted end here -->

                    <!-- business proposal interaction already start here -->
                    <div *ngIf="bxProposalResult?.count == '1' && bxProposalResult?.status == '3'"
                        class="contact-seller  form-sec sec-slide-effect">
                        <div class="inbtn">
                            <a [routerLink]="['/dashboard/myinteraction']" *ngIf="bxProposalResult?._status == 2"
                                class="contact-btn">
                                GO TO BX INBOX
                            </a>
                            <a [routerLink]="['/dashboard/bxproposal']" *ngIf="bxProposalResult?._status == 1"
                                class="contact-btn">
                                GO TO BX PROPOSAL
                            </a>
                        </div>
                        <div class="added-info btnpad">
                            {{ (bxProposalResult?._status == 1) ?
                                            'You have not yet responded to this proposal. Please accept or reject it by clicking above.' :
                                            'Interaction has already started with your profile.' }}
                        </div>
                    </div>
                    <!-- business proposal interaction already end here -->

                </div>

                <div *ngIf="startupProfileExistCheck === 'UserProfile'">
                    <div class="contact-seller  form-sec sec-slide-effect">
                        <div class="inbtn"><a [routerLink]="['/dashboard/myaccount']" class="contact-btn">GO TO
                                DASHBOARD</a></div><br>
                        <div class="added-info btnpad">This is your Startup Profile. Click above link
                            to update startup profile
                        </div>
                    </div>
                </div>


            </div>




        </div>
    </div>
    </div>


</main>