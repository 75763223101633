import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'broker-confidential',
    templateUrl: './broker-confidential.component.html'
})
export class BrokerConfidentialComponent implements OnInit, AfterViewChecked {
    profile_str;
    public BrokerConfidentialdata;
    public brokerProfileSuccessError;
    public brokerProfileFailError;

    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Broker Profile Confidential Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    brokerMyAccountSubmit(data) {
        if ($('form[name="brokerForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.broker_profile_str = this.profile_str;
        this.http.post(this.config.apiEndpoint + '/updateBrokerDetails/' + this.profile_str, data).subscribe(
            data => {
                //console.log("Confidential Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.brokerProfileSuccessError = data;
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.brokerProfileFailError = "Please Enter Correct Details";
            });
    }

    getbrokerConfidential(profile_str: any): void {
        this.myprofileService.getbrokerConfidential(this.profile_str)
            .then((result) => {
                this.BrokerConfidentialdata = result;
            });
    }

    ngOnInit() {
        this.getbrokerConfidential(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="brokerForm"]').validator();
        }
    }

}
