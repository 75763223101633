
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <loader [loading]="messageLoading"></loader>
                        <div class="row">
                            <div class="col-md-12">

                                <div class="bdr">
                                    <div class="cat-list " *ngIf="ProfileSelected?.membership_type >= 0">
                                        <div class="cat-sec-ttl down-list">
                                            Insta Response
                                            <div class="pull-right insta-ph"
                                                 style="font-family: Lato-Regular; font-size: 14px; text-transform: capitalize;">
                                                Total Credits : <span style="font-family: 'Lato-Bold';">{{instaTotalCount}}</span>,
                                                Revealed Credits {{instaRevealCount}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="instant-list" *ngIf="instaResponseLength && ProfileSelected?.membership_type >= 0">
                                        <div class="tabulardata insta-width">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email / Phone</th>
                                                    <th>Available Capital</th>
                                                    <th *ngIf="ProfileSelected.profile_type == profiles.Broker">Business</th>
                                                    <th class="cmnt-area">Comment</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let insta of instaResponse">
                                                    <td data-th="Name">
                                                        <div class="response-name">{{insta.contact_name}}</div>
                                                        <div class="response-company-name">{{insta.contact_company}}
                                                        </div>
                                                    </td>

                                                    <!-- <td data-th="Email" *ngIf="instaTotalCount == instaRevealCount">
                                                        <div class="contact-btn">
                                                            <a [routerLink]="" data-toggle="modal" data-target="#contact-info">View</a>
                                                        </div>
                                                    </td> -->

                                                    <td data-th="Email"
                                                        *ngIf="insta.contact_viewed == 0 && ProfileSelected?.membership_type >= 0">
                                                        <div *ngIf="insta.contact_id == contactSelId"
                                                             id="{{insta.contact_id}}">
                                                            <div class="response-name">{{insta.contact_email}}</div>
                                                            <div class="response-company-name">
                                                                {{insta.contact_mobile}}
                                                            </div>
                                                        </div>
                                                        <div class="contact-btn" style="width: 82px;position: relative;"
                                                             *ngIf="insta.contact_id != contactSelId">
                                                            <div class="addondisable" *ngIf="instaRevealCount >= instaTotalCount"></div>
                                                            <a [routerLink]="" data-toggle="modal"
                                                               (click)="ViewConfirmInstaUpdate(insta.contact_id)">View</a>
                                                        </div>
                                                    </td>

                                                    <td data-th="Email"
                                                        *ngIf="insta.contact_viewed == 1 && ProfileSelected?.membership_type >= 0">
                                                        <div class="response-name">{{insta.contact_email}}</div>
                                                        <div class="response-company-name">{{insta.contact_mobile}}
                                                        </div>
                                                    </td>

                                                    <td data-th="Available Capital">{{insta.contact_investment}}</td>
                                                    <td data-th="Business" *ngIf="ProfileSelected.profile_type == profiles.Broker">{{insta?.seller_company}}</td>
                                                    <td data-th="Comment" style="word-break:break-word;">{{insta.contact_comment}}</td>
                                                </tr>
                                                <tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="ttl-viewd" *ngIf="instaRevealCount >= instaTotalCount">
                                        <div class="desc">You don't have any <!--{{instaTotalCount}}--> insta credits. Please upgrade account to reveal insta contacts.
                                        </div>
                                    </div>
                                    <div class="upgrade-btn" *ngIf="instaRevealCount >= instaTotalCount">
                                        <div class="submitfrm">
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 1">
                                                <a [ngClass]="{active : activeMenu === 'upgrade'}"
                                                   routerLink="/dashboard/upgrade/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 2">
                                                <a [ngClass]="{active : activeMenu === 'upgradeInv'}"
                                                   routerLink="/dashboard/upgradeInv/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 3">
                                                <a [ngClass]="{active : activeMenu === 'upgradeInv'}"
                                                   routerLink="/dashboard/upgradeInv/Lender/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 4">
                                                <a [ngClass]="{active : activeMenu === 'upgradeInv'}"
                                                   routerLink="/dashboard/upgradeInv/Mentor/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 5">
                                                <a [ngClass]="{active : activeMenu === 'upgradeInv'}"
                                                   routerLink="/dashboard/upgradeInv/Incubation/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 7">
                                                <a [ngClass]="{active : activeMenu === 'upgradeInv'}"
                                                   routerLink="/dashboard/upgradeInv/Startup/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="instant-list nodata" *ngIf="!instaResponseLength && !messageLoading">
                                        No Instant Response Found !
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->

<div class="modal fade lg-panel" id="contact-info" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="contact-conf">
                    <div class="msg">Please upgrade account to view the contact information?</div>
                    <div class="action">
                        <a *ngIf="ProfileSelected?.profile_type == '1'" routerLink="/dashboard/upgrade/{{ProfileSelected?.profile_str}}" class="btn btn-blue"
                           style="padding: 5px 20px;">Upgrade Account</a>
                        <a *ngIf="ProfileSelected?.profile_type == '2'" routerLink="/dashboard/upgradeInv/{{ProfileSelected?.profile_str}}" class="btn btn-blue"
                           style="padding: 5px 20px;">Upgrade Account</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>