
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {LoginGuard} from '../../helper/login-guard';
import { BusinessListingComponent } from './components/business-listing/business-listing.component';
import { InvestorListingComponent } from './components/investor-listing/investor-listing.component';
import { StartupListingComponent } from './components/startup-listing/startup-listing.component';
import { MentorListingComponent } from './components/mentor-listing/mentor-listing.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { BusinessValuationComponent } from './components/business-valuation/business-valuation.component';
import { BusinessPlanComponent } from './components/business-plan/business-plan.component';
import { DuediligenceComponent } from './components/duediligence/duediligence.component';
import { ArticleComponent } from './components/article/article.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { InsightContentComponent } from './components/insight-content/insight-content.component';
import { ArticleListingComponent } from './components/article-listing/article-listing.component';
import { NewsListingComponent } from './components/news-listing/news-listing.component';
import { NewsComponent } from './components/news/news.component';
import { BexShortsComponent } from './components/bex-shorts/bex-shorts.component';
import { BusinessContactComponent } from './components/business-contact/business-contact.component';
import { StartupContactComponent } from './components/startup-contact/startup-contact.component';
import { InvestorContactComponent } from './components/investor-contact/investor-contact-component';
import { MentorContactComponent } from './components/mentor-contact/mentor-contact.component';
import { CommonThanksComponent } from '../shared/components/common-thanks/common-thanks.component';
import { PaymentSuccessComponent } from '../shared/components/payment-success/payment-success.component';
import { PaymentFailedComponent } from '../shared/components/payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from '../shared/components/payment-cancelled/payment-cancelled.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'aboutus',
        component: AboutusComponent
    },
    {
        path: 'disclaimer',
        component: DisclaimerComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: 'contact-us',
        component: ContactUsComponent
    },
    {
        path:'registration',
        loadChildren: () => import('../registration/registration.module').then(m => m.RegistrationModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:[LoginGuard]

    },

    {
        path: 'businesslisting',
        component: BusinessListingComponent
    },
    {
        path: 'businesslisting/:businesstitle/:selecat/:selesubcat/:state',
        component: BusinessListingComponent
    },
    {
        path: 'businesslisting/:businessType',
        component: BusinessListingComponent
    },
    {
        path: 'businesslisting/:businesstitle/:selecat/:selesubcat/:state/:city',
        component: BusinessListingComponent
    },
    {
        path: 'businesslisting/:business-industry/:catname/:sellercatId',
        component: BusinessListingComponent
    },
    {
        path: 'businesslisting/:business-opportunities/:businessprice',
        component: BusinessListingComponent
    },
    {
        path: 'businesslisting/:business-opportunities-location/:locationid',
        component: BusinessListingComponent
    },
    {
        path: 'startupslisting',
        component: StartupListingComponent
    },

    {
        path: 'startupslisting/:businesstitle/:selecat/:selesubcat/:state',
        component: StartupListingComponent
    },
    {
        path: 'startupslisting/:businesstitle/:selecat/:selesubcat/:state/:city',
        component: StartupListingComponent
    },

    {
        path: 'investorlisting',
        component: InvestorListingComponent
    },
    {
        path: 'investorlisting/:businesstitle/:selecat/:selesubcat/:state',
        component: InvestorListingComponent
    },
    {
        path: 'investorlisting/:businesstitle/:selecat/:selesubcat/:state/:city',
        component: InvestorListingComponent
    },
    {
        path: 'investorlisting/:investor-type/:investortype',
        component: InvestorListingComponent
    },

    {
        path: 'mentorlisting',
        component: MentorListingComponent
    },

    {
        path: 'mentorlisting/:businesstitle/:selecat/:selesubcat/:state',
        component: MentorListingComponent
    },
    {
        path: 'mentorlisting/:businesstitle/:selecat/:selesubcat/:state/:city',
        component: MentorListingComponent
    },

    {
        path: 'business/:category/:sellerUniqueId',
        component: BusinessContactComponent
    },
    {
        path: 'startup/:category/:sellerUniqueId',
        component: StartupContactComponent
    },
    {
        path: 'investor/:category/:investorUniqueId',
        component: InvestorContactComponent
    },

    {
        path: 'mentor/:mentorUniqueId',
        component: MentorContactComponent
    },
    {
        path: 'mentor/:category/:mentorUniqueId',
        component: MentorContactComponent
    },
    

    {
        path: 'pricing',
        component: PricingComponent,
        data: { showBasicPlan: true}
    },
    {
        path: 'service/business-valuation',
        component: BusinessValuationComponent
    },
    {
        path: 'service/business-plan',
        component: BusinessPlanComponent
    },
    {
        path: 'service/due-diligence',
        component: DuediligenceComponent
    },
    {
        path: 'article',
        component: ArticleListingComponent
    },
    /*{
        path: 'article/:articleTagSlug',
        component: ArticleListingComponent
    },*/

    {
        path: 'article/:articletitle/:articleId',
        component: ArticleComponent
    },

    {
        path: 'news',
        component: NewsListingComponent
    },

    {
        path: 'bex-shorts',
        component: BexShortsComponent
    },

    /*{
        path: 'news/:newsTagSlug',
        component: NewsListingComponent
    },*/

    {
        path: 'news/:newstitle/:newsId',
        component: NewsComponent
    },
    /*{
        path: 'amp',
        loadChildren: '../amp/amp.module#AmpModule',
        data: { showHeader: false}
    },
    
    {
        path:'amp',
        loadChildren: () => import('../amp/amp.module').then(m => m.AmpModule),
        data: { showHeader: false}
    },*/
    
    {
        path: 'thanks',
        component: CommonThanksComponent
    },
    {
        path: 'paymentsuccess',
        component: PaymentSuccessComponent
    },
    {
        path: 'paymentfailure',
        component: PaymentFailedComponent
    },
    {
        path: 'paymentcancelled',
        component: PaymentCancelledComponent
    },
    
    // {
    //     path: 'newsletter/:updateStatus/:emailId',
    //     component: UpdateNewsletterSubscriptionComponent
    // },


    {
        path: 'content',
        component: InsightContentComponent
    },
    {
        path: '**', 
        component: PageNotFoundComponent
    }

    
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class FrontendRoutingModule {
}

export const routedComponents = [
    HomeComponent,
    AboutusComponent
]



