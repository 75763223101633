import {NgModule} from '@angular/core';
import {DashboardComponent} from './components/dashboard/dashboard-component';
import {dashboardRoutedComponents, DashboardRoutingModule} from './dashboard-routing.module';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ProfileDetailComponent} from './components/profile-detail/profile-detail.component';
import {SharedModule} from '../shared/shared.module';
import {RightSectionComponent} from './components/right-section/right-section.component';
import {BrokerProfileTypeFilter} from '../../pipes/broker-profile-type-filter.pipe';


@NgModule({
    declarations: [
        dashboardRoutedComponents,
        DashboardComponent,
        ProfileDetailComponent,
        RightSectionComponent,
        BrokerProfileTypeFilter,
    ],
    imports: [
        SharedModule,
        CommonModule,
        DashboardRoutingModule,
        FormsModule
    ],
    exports: [BrokerProfileTypeFilter,ProfileDetailComponent],
    providers: [],
    bootstrap: [DashboardComponent]
})
export class DashboardModule {
}


