import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'payment-failed',
    templateUrl: './payment-failed.component.html'
})
export class PaymentFailedComponent implements OnInit {

    constructor(public router: Router,
                private route: ActivatedRoute) {
    }

    title = 'Membership Payment Failed';
    message = 'Failed !!! Your payment has been failed !!!';

    ngOnInit() {
        this.route.paramMap.subscribe((params: Params) => {
            // direct page access not allowed
            if (document.referrer === '') {
                this.router.navigate(['/']);
            }
        });
    }
}
