<main id="main" class="minheigh">
    <div class="container bex-main">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <ul class="brunnar">
                    <li><a [routerLink]="['/']">Home</a></li>
                    <li>/</li>
                    <li>Terms Of Use</li>
                </ul>
            </div>
        </div>

        <div class="page-ttl">
            <h1> Terms Of Use</h1>
        </div>

        <div class="row backbg">

            <div class="col-12 col-xs-12 col-sm-12 col-md-12">

                <div class="shrt-desc">
                    <p>These Terms of Use (these "Terms") govern the web pages of the <a routerlink="/"
                            href="/">www.BusinessEx.com</a> ("Website"). This document is an electronic record in terms
                        of Information Technology Act, 2000 and rules there under as applicable and the amended
                        provisions pertaining to electronic records in various statutes as amended by the Information
                        Technology Act, 2000. This electronic record is generated by a computer system and does not
                        require any physical or digital signatures.</p>
                    <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information
                        Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
                        regulations, privacy policy and Terms of Use for access or usage of the Website.</p>
                    <p>The domain name <a routerlink="/" href="/">www.Businessex.com</a> is owned by BusinessEx a
                        business unit of Franchise India Brands Limited a company incorporated under the Companies Act,
                        1956 with its registered office at F-89/11, Okhla Phase-I, New Delhi-110020, India (hereinafter
                        referred to as "BusinessEx").</p>
                    <p>By using this Website, you (the "User") are agreeing to these terms and these terms of use shall
                        be deemed as End User Service Agreement between you and BusinessEx for using the Website and/or
                        availing the Services or purchasing the Products that are being offered through this Website by
                        BusinessEx and/or its affiliates/group companies. If you do not agree to these terms, then you
                        are not allowed to use this Website and should immediately terminate such usage.</p>

                    <ul>
                        <li>
                            <div>ACKNOWLEDGMENT AND ACCEPTANCE OF AGREEMENT</div> The Services/Products provided by
                            BusinessEx are provided to the User under the terms and conditions of this Agreement, any
                            amendments to this Agreement, and any operating rules or policies that may be published from
                            time to time by BusinessEx, all of which are hereby incorporated by reference. This
                            Agreement comprises the entire agreement between a User and BusinessEx and supersedes any
                            prior agreements pertaining to the subject matter contained herein.
                        </li>
                        <li>
                            <div>REGARDING SERVICES/PRODUCTS</div> BusinessEx provides free as well as paid
                            Services/Products and for the Services/Products it charges, the fee of related thereto shall
                            be subject to revisions at higher side in future. BusinessEx reserves the right to modify or
                            discontinue, temporarily or permanently, the Services/Products with or without notice to the
                            User. The User agrees that BusinessEx shall not be liable to the User or any third party for
                            any modification or discontinuance of the Services/Products. Any and all payment made
                            towards the paid Services and/or Products shall be non-refundable in any and all
                            circumstances whatsoever.
                        </li>
                        <li>
                            <div>USER'S REGISTRATION OBLIGATIONS</div> The User must be at least eighteen (18) years old
                            to register for the Services/Products. In consideration of use of the Services/Products, the
                            User agrees to: (a) provide true, accurate, current, and complete information about the User
                            as prompted by the registration form; and (b) to maintain and update this information to
                            keep it true, accurate, current, and complete. If any information provided by the User
                            ("Registration Data") is untrue, inaccurate, not current, or incomplete, BusinessEx has the
                            right to terminate User's account (without refund in case of paid Services/Products) and
                            refuse any and all current and/or future use of the Services/Products.
                        </li>
                        <li>
                            <div>USER DATA</div> The User acknowledges that Registration Data is to be stored with
                            BusinessEx. This Agreement includes the terms and conditions of the Privacy Policy, details
                            of which are available on the website, and which is hereby incorporated by reference. The
                            User acknowledges that <b>BusinessEx is not a "network service provider" under Section 79 of
                                the Information Technology Act, 2000</b> and that the content posted to public community
                            areas is publicly available and that BusinessEx does not take any responsibility for such
                            content. However, BusinessEx reserves the right to remove any public content posted by a
                            User that violates any law or condition of this Agreement, upon notice of such violation.
                            The User understands and agrees that technical (and sometimes manual) processing of email
                            communications, search requests, community postings, and any other information supplied by
                            the User is and may be required: (a) to send and receive messages; (b) to conform to the
                            technical requirements of connecting networks; (c) to conform to the limitations of the
                            Services; or (d) to conform to other, similar technical requirements. The User acknowledges
                            and agrees that BusinessEx does not endorse the content of any User communications and is
                            not responsible or liable for any unlawful, harassing, libelous, privacy invading, abusive,
                            threatening, harmful, vulgar, obscene, indecent, tortious, or otherwise objectionable
                            content, or content that infringes or may infringe the intellectual property or other rights
                            of another.
                        </li>
                        <li>
                            <div>COMMUNNICATION TO USERS</div> BusinessEx may communicate its registered users/ members
                            via promotional and transactional Emails, SMS's and calls to deliver information about its
                            services/products, renewal, promo offers of it's partners and alliances.
                        </li>
                        <li>
                            <div>USER NAME, MEMBER ACCOUNT, PASSWORD AND SECURITY</div> The User may be asked to choose
                            the first part of his/her user name, which will be followed by the "@" symbol and
                            BusinessEx's domain name (Example: YourNameHere@Example.com). The User agrees to choose a
                            user name which is unique, not obscene, unlawful, or otherwise objectionable, in
                            BusinessEx's sole discretion. BusinessEx shall own the User's complete user name. The User
                            will receive a designated password and account upon completing the registration process for
                            the Services/Product. The User is responsible for maintaining the confidentiality of the
                            password and account, and is fully responsible for all activities that occur under the
                            User's account. The User agrees to immediately notify BusinessEx of any unauthorized use of
                            the User's password or account or of any other breach of security.
                        </li>
                        <li>
                            <div>USER CONDUCT &amp; OBLIGATIONS</div>
                            <ul class="sub-list">
                                <li>The User agrees to abide by all applicable local, state, national, and international
                                    laws and regulations during use of the Services/Products, and agrees not to
                                    interfere with the use and enjoyment of the Services/Products by other users. The
                                    User agrees to be solely responsible for the contents of the User's private and
                                    public communications, whether uploaded, posted, emailed, or otherwise transmitted
                                    through the Services/Products.</li>
                                <li>The User further agrees that the user shall be liable for all of the User's private
                                    and public communications, whether uploaded, posted, emailed, or otherwise
                                    transmitted through the Services/Products. The User further undertakes that
                                    he/she/it shall comply with all the provisions of the Information Technology Act,
                                    2000 and also all rules, regulations and notifications made thereunder.</li>
                                <li>The User agrees:(i) not to use the Services/Products for illegal purposes; (ii) not
                                    to interfere with or disrupt the Services/Products or servers or networks connected
                                    to the Services/Products; (iii) to comply with all requirements, procedures,
                                    policies, and regulations of networks connected to the Services/Products; <b>(iv)
                                        not to resell the Services/Products or use of or access to the
                                        Services/Products;</b> and (v) to comply with all applicable laws regarding the
                                    transmission of technical data exported from India.</li>
                                <li>The User agrees not to upload, post, email, or otherwise transmit through the
                                    Services/Products: (i) any unlawful, harassing, libelous, privacy invading, abusive,
                                    threatening, harmful, vulgar, obscene, indecent, tortuous, or otherwise
                                    objectionable material of any kind; (ii) any material that violates the rights of
                                    another, including, but not limited to, the intellectual property rights of another;
                                    (iii) any material that violates any applicable local, state, national, or
                                    international law or regulation; or (iv) unsolicited or unauthorized advertisements,
                                    promotional materials, "junk mail," "spam," "chain letters," or other forms of
                                    solicitation.</li>
                                <li>The User agrees not to attempt to gain unauthorized access to other computer systems
                                    or networks connected to the Services. The User acknowledges and agrees that
                                    BusinessEx may ban the User from future use of the Services if the User does not
                                    comply with standards of conduct of BusinessEx. Furthermore, the User acknowledges
                                    and agrees that BusinessEx may recover damages from User if User violates these
                                    terms or any provisions of the Information Technology Act, 2000. Further, the User
                                    can be reported for his illegal conduct to the law enforcement agencies for
                                    appropriate action under the Information Technology Act, 2000, India Penal Code and
                                    such other laws as may be applicable. If in case the User comes to know of any of
                                    the above acts being done by another User or any of the provisions of this Agreement
                                    being violated by another user, then the User shall be under an obligation to inform
                                    BusinessEx of such acts or violations so that appropriate action in this regard can
                                    be taken by BusinessEx.</li>
                                <li>IF the User has availed any of the Services/Products, it shall ensure: (i) to share
                                    any/ all information with BusinessEx including but not limited to
                                    documents/information in relation to the business or business requirements of User,
                                    its current status, marketing plans, all financial documents (as applicable), and
                                    any other information critical to the User or his/her/its business for facilitating
                                    the purpose of the Services/Products; (ii) not circumvent the role of BusinessEx at
                                    any point in time by directly dealing with the prospective buyer/seller (as the case
                                    may be; (iii) that all dealings and negotiations qua the prospective buyer/seller
                                    (as the case may be in relation to the Services availed or Products purchased by the
                                    User) including third party offers directly made to the User are routed through the
                                    BusinessEx.</li>
                            </ul>
                        </li>
                        <li>
                            <div>INDEMNITY</div> The User agrees to indemnify and hold BusinessEx and its parents,
                            subsidiaries, affiliates, officers, and employees, harmless from any claim or demand,
                            including reasonable attorneys' fees, made by any third party due to or arising out of
                            User's use of the Services/Product, User's connection to the Services/Product, User's
                            violation of this Agreement, or User's violation of any rights of another party.
                        </li>
                        <li>
                            <div>STORAGE OF COMMUNICATIONS</div> BusinessEx assumes no responsibility for the deletion
                            or failure to store email messages, communications, or other content maintained or
                            transmitted through the Services. BusinessEx may establish in its sole discretion an upper
                            limit on the extent of message storage it will maintain for the User.
                        </li>
                        <li>
                            <div>TERMINATION</div>
                            <ul class="sub-list">
                                <li>The User agrees that BusinessEx, may terminate the User's password, account, or use
                                    of the Services/Products if BusinessEx, believes: (i) that the User has violated or
                                    acted inconsistently with the letter or spirit of this Agreement; or (ii) that the
                                    User has violated the rights of BusinessEx, or their third party service providers
                                    or other Users or parties.</li>
                                <li>The User further agrees that BusinessEx may terminate the User's password, account,
                                    or use of the Services/Products if the User (i) fails to provide requisite support
                                    to BusinessEx; or (ii) If the User fails to pay the consideration to BusinessEx
                                    against the accrued payments or towards the payments already due; or (iii) If the
                                    User misrepresents and provides incorrect information to BusinessEx; or (iv) If any
                                    of the representations and warranties given by the User under this Agreement or
                                    Website is found to be untrue or misleading; or (v) If the User is in breach of its
                                    obligations under this Agreement and such breach remains uncured during the notice
                                    period, requiring it to remedy the same. The User also acknowledges and agrees that
                                    termination of any of the Services/Products may be effected without prior notice.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div>LINKS</div> The Services/Products may provide, or users may include in email or
                            community postings, links to other websites or resources. However, the User agrees not to
                            include in email or community postings (or elsewhere via the Services/Products) any "deep
                            link" which leads to a web page, other than the home page, of another party's web site
                            unless such a link is authorized by the owner of that web site. The User acknowledges and
                            agrees that BusinessEx is not responsible for the availability of such external sites or
                            resources, or for the User's use of deep links, and that BusinessEx does not endorse and is
                            not responsible or liable for any content, advertising, products, or other materials on or
                            available from such sites or resources.
                        </li>
                        <li>
                            <div>PROPRIETARY RIGHTS of BUSINESSEX</div> The User acknowledges and agrees that content,
                            including, but not limited to, text, software, music, sound, photographs, graphics, video,
                            or other material contained in sponsor advertisements or information presented to the User
                            through the Services or third party advertisers is protected by copyrights, trademarks,
                            service marks, patents, or other proprietary rights and laws. The User acknowledges and
                            agrees that the User is permitted to use this material and information only as expressly
                            authorized by BusinessEx, or advertisers, as applicable, and may not copy, reproduce,
                            transmit, distribute, or create derivative works of such content or information without such
                            express authorization.
                        </li>
                        <li>
                            <div>DISCLAIMER OF WARRANTIES</div>
                            <ul class="sub-list">
                                <li>The user expressly agrees that use of the services is at the user's sole risk. The
                                    services are provided on an "as is" and "as available" basis.</li>
                                <li>Businessex expressly disclaims all warranties of any kind, whether express or
                                    implied, including, but not limited to, the implied warranties of merchantability,
                                    fitness for a particular purpose, and non-infringement. Businessex also disclaims
                                    any guarantee of success of the transaction contemplated under/vide the
                                    services/products offered by businessex.</li>
                                <li>Businessex makes no warranty that the services/products will meet the user's
                                    requirements or that the services will be uninterrupted, timely, secure, or error
                                    free; nor does businessex make any warranty as to the results that may be obtained
                                    from the use of the services/products, or as to the accuracy or reliability of any
                                    information obtained through the services/products.</li>
                                <li>The user understands and agrees that any information, content, data, or other
                                    material downloaded or otherwise obtained through or from the services is obtained
                                    at the user's own discretion and risk, and that the user will be solely responsible
                                    for any damage to the user's computer system or any loss of data that results from
                                    the download of such material or data.</li>
                                <li>Businessex makes no warranty regarding any services or products purchased or
                                    obtained through or from the services or any transactions entered into through the
                                    services.</li>
                                <li>No advice or information, whether oral or written, obtained by the user from
                                    businessex or through or from the services, shall create any warranty not expressly
                                    stated herein.</li>
                                <li>The user acknowledges that businessex is providing services/products that are
                                    advisory in nature. Thus, the user shall conduct its own due diligence or evaluation
                                    qua the prospective buyer/seller before materializing the transaction contemplated
                                    against the respective services/products and not relying upon the representation
                                    given by businessex.</li>
                            </ul>
                        </li>
                        <li>
                            <div>LIMITATION OF LIABILITY</div>
                            <ul class="sub-list">
                                <li>The user agrees that businessex, and their third party service providers shall not
                                    be liable for any indirect, incidental, special or consequential damages resulting
                                    from the use or the inability to use the services/products, or for cost of
                                    procurement of substitute services/products, or resulting from any services/products
                                    purchased or obtained, or from messages received or transactions entered into
                                    through or from the services/products, or resulting from unauthorized access to or
                                    alteration of user's transmissions or data, including, but not limited to, damages
                                    for loss of profits, use, data, or other intangibles, even if businessex has been
                                    advised of the possibility of such damages.</li>
                                <li>The user further agrees that businessex, and their third party service providers
                                    shall not be liable for any damages arising from interruption, suspension, or
                                    termination of services/products, including, but not limited to, direct, indirect,
                                    incidental, special consequential, or exemplary damages, whether such interruption,
                                    suspension, or termination was justified or not, negligent or intentional,
                                    inadvertent or advertent.</li>
                                <li>The user acknowledges and agrees that in no event shall businessex be liable for any
                                    damages, whether in contract or tort, including, but not limited to, direct,
                                    indirect, incidental, or consequential damages, or lost profits or cost of
                                    procurement of substitute goods or services, arising out of compliance or reasonable
                                    attempts to: (i) comply with the indian copyright act.</li>
                                <li>In no event shall businessex be liable for any and all matters relating to this
                                    agreement for any aggregate amount in excess of rs.100/-.</li>
                            </ul>
                        </li>
                        <li>
                            <div>AMENDMENT</div> BusinessEx may modify this Agreement at any time, and such
                            modifications shall be effective immediately upon posting and continued access or use of the
                            Services/Products shall be deemed to be the User's conclusive acceptance of the modified
                            Agreement.
                        </li>
                        <li>
                            <div>GENERAL</div> This Agreement and the relationship between User and BusinessEx shall be
                            governed by the laws of INDIA without regard to its conflict of law provisions. User and
                            BusinessEx, agree to submit to the personal and exclusive jurisdiction of the courts located
                            within NEW DELHI. The failure of BusinessEx, and their third party service providers to
                            exercise or enforce any right or provision of this Agreement shall not constitute a waiver
                            of such right or provision. If any provision of this Agreement is found by a court of
                            competent jurisdiction to be invalid, the parties nevertheless agree that the court should
                            endeavor to give effect to the parties' intentions as reflected in the provision and rule
                            that the other provisions of this Agreement remain in full force and effect. The User agrees
                            that regardless of any statute or law to the contrary, any claim or cause of action arising
                            out of or related to use of the Services/Products or this Agreement must be filed within one
                            (1) year after such claim or cause of action arose or be forever barred.
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

</main>