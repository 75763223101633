import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

// declare var gtag;

@Component({
    selector: 'common-thanks',
    templateUrl: './common-thanks.component.html'
})
export class CommonThanksComponent implements OnInit {

    constructor(
        private route: ActivatedRoute) {
    }

    title = 'Thank You';
    message = 'Thank You !!!';

    ngOnInit() {
        /*if (this.route.snapshot.url[0].path === 'thanks') {
            console.log('navigation end');
            gtag('config', 'AW-723817542');
        }*/
    }
}
