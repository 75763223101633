<div *ngIf="loading" class="loading">
   <div style="display:table-cell; vertical-align: middle;">
      <div class="sk-circle">
         <div class="sk-circle1 sk-child"></div>
         <div class="sk-circle2 sk-child"></div>
         <div class="sk-circle3 sk-child"></div>
         <div class="sk-circle4 sk-child"></div>
         <div class="sk-circle5 sk-child"></div>
         <div class="sk-circle6 sk-child"></div>
         <div class="sk-circle7 sk-child"></div>
         <div class="sk-circle8 sk-child"></div>
         <div class="sk-circle9 sk-child"></div>
         <div class="sk-circle10 sk-child"></div>
         <div class="sk-circle11 sk-child"></div>
         <div class="sk-circle12 sk-child"></div>
      </div>
   </div>
</div>


<div class="container bex-main">
   <div class="row">
      <div class="col-md-12">
         <ol class="breadcrumb ">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item" *ngIf="catind.length === 1"><a [routerLink]="['/investorlisting']">Investor</a></li>
                <li class="breadcrumb-item" *ngIf="catind.length === 0 || catind.length > 1">Investor</li>
                <li class="breadcrumb-item active" *ngIf="subcatind.length === 1"><a [routerLink]="['/investorlisting/'+getIndustryName(catind[0])?.industrySlug+'-investment-opportunities/'+catind[0]+'/s0/st0']">{{getIndustryName(catind[0])?.industry}}</a></li>
                <li class="breadcrumb-item active" *ngIf="catind.length === 1 && subcatind.length > 1">{{getIndustryName(catind[0])?.industry}}</li>
                <li class="breadcrumb-item active" *ngIf="subcatind.length === 1">{{getIndustryName(catind[0], subcatind[0])?.subindustry}}</li>
         </ol>
      </div>
      <!-- <div class="col-md-12 pt-b15">
         <div class="bex-search-section">
          <span class="mhides">
               <i class="fa fa-bolt" aria-hidden="true"></i> Trending Searches:
            </span>
            <span>
               <ul class="bex-trending-search-tab">
                  <li><a href="#">Hospitality</a></li>
                  <li><a href="#">Hotels</a></li>
                  <li><a href="#">Management</a></li>
                  <li><a href="#">Education</a></li>
                  <li><a href="#">Pre-School</a></li>
                  <li><a href="#">Restaurants</a></li>
                  <li><a href="#">Food Parlor</a></li>
               </ul>
            </span>
         </div>
      </div> -->
      
   </div>


   <div class="row catfull">
      <div class="filter" id="showftr">Apply Filter</div>

      <div class="col-md-3 catsh">
         <div class="catleft">
            <div id="closeftr" class="closebtn"><i class="fa fa-times fa-2x" aria-hidden="true"></i></div>
            <div class="mainleftdiv">
               <div class="subhead">Filters</div>

               <div class="accordion_container">
                  <div class="accordion_head"> <a href="#" onclick="return false;">Investor types</a> <span class="plusminus minus"></span>
                  </div>
                  <div class="accordion_body" style="display: block;">
                     <ul class="sub-menu">
                        <li *ngFor="let categorylist of investorOccupation; let i = index ">
                           <label>
                              <input 
                                 class="sub-gen"
                                 type="checkbox"
                                 (click)="investorChange(categorylist.investorOccupationId, categorylist.investorOccupation)"
                                 [(ngModel)]="filterinvestorType[categorylist.investorOccupation]"
                              >
                              {{categorylist.investorOccupation}}
                          </label>

                        </li>
                     </ul>
                  </div>
               </div>

               <div class="accordion_container">
                  <div class="accordion_head"> <a href="#" onclick="return false;">Investment Size</a> <span class="plusminus minus"></span>
                  </div>
                  <div class="accordion_body" style="display: block;">
                     <div class="leftfrmblk">
                        <form>
                           <div class="priblk">

                              <div id="html5"></div>

                              <!-- <nouislider #slider name="slider" [config]="someKeyboardConfig" [(ngModel)]="sliderRange" (end)="priceFilter($event)"></nouislider> -->

                              <div class="fbl">
                                 <div class="prlic1" id="prlic1">
                                    <i class="fas fa-rupee-sign"></i> {{minInvestmentValue}} </div>
                                  <div class="prlic2" id="prlic2"><i class="fas fa-rupee-sign"></i>
                                    {{maxInvestmentValue}}
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>

               <!-- Location -->
               <div class="accordion_container">

                  <div class="accordion_head"><span class="plusminus minus"></span> <a [routerLink]="">Location</a>
                  </div>
                  <div class="accordion_body" style="display: block;">


                     <div class="accordianHead" (click)="appConfig.locationtypeaccordianCheck($event)" *ngFor="let statelist of filterLocation12 | unique: 'inv_state'; let i = index">
                  
                        <div class="accordion_headmain">
                              <span class="rightdown downval"></span>

                              <input type="checkbox" attr.id="{{statelist.state}}"
                                       class="brand-filter" 
                                       attr.class="state{{statelist.state}}"
                                       (click)="includeCityNew(statelist.inv_state, statelist.state,'', statelist.cityId, statelist.inv_state)"
                                       [(ngModel)]="filterLocationstate[statelist.inv_state]">
                              <a class="clicked"
                                    attr.id="state{{statelist.state}}"
                                    href="investorlisting/investment-opportunities-in-{{statelist.stateSlug}}/c0/s0/{{statelist.state}}"
                                    (click)="$event.preventDefault()">
                                    {{statelist.inv_state | limitTo: 20}}
                                    
                              </a>
                        </div>

                        <div class="accordion_bodymain" style="display: none;">
                           <div class="more">
                              <ul class="sub-menu">
                                 <li *ngFor="let citylist of filterLocation12 | filterBy:['inv_state']:statelist.inv_state  | unique:'inv_city'; let j=index">  
                                     <input type="checkbox"
                                       class="sub-gen"
                                                attr.class="city{{statelist.state}}{{j}}"
                                                attr.id="{{statelist.state}}"
                                                (click)="includeCityNew(statelist.inv_state ,statelist.state, citylist.inv_city, citylist.cityId, citylist.inv_city)"
                                                [(ngModel)]="filterLocation[citylist.inv_city]">
                                       <a class="clicked"
                                          attr.id="city{{statelist.state}}{{j}}"
                                          href="investorlisting/investment-opportunities-in-{{citylist.citySlug}}/c0/s0/{{citylist.state}}/{{citylist.cityId}}"
                                          (click)="$event.preventDefault()"
                                          >
                                          {{citylist.inv_city | limitTo: 20}}
                                       </a>
                                 
                                 </li>
                              </ul>
                           </div>
                        </div>
                        
                     </div>

                     <!-- <div class="morcity"><a href="#">25 More</a></div> -->

                  </div>
               </div>

               <!-- industries -->

               <div class="accordion_container">
                  <div class="accordion_head"> 
                     <a [routerLink]="">Industries</a> 
                     <span class="plusminus add"></span></div>

                  <div class="accordion_body" style="display: block;">
                        <div class="accordianHead" (click)="appConfig.locationtypeaccordianCheck($event)" *ngFor="let catindustry of industrySeller | sortBy : 'industry' | unique:'industry'; let i = index">
                     
                           <div class="accordion_headmain">
                                 <span class="rightdown downval"></span>
                                 <input class="brand-filter"
                                 attr.id="{{catindustry.industryid}}" 
                                 type="checkbox"
                                            attr.class="class{{catindustry.industryid}}"
                                            [(ngModel)]="filterIndustrymain[catindustry.industryid]"
                                            (click)="includeindustry(catindustry.industryid, '', catindustry.industry,'','')">
                                    <a class="clicked"
                                        attr.id="class{{catindustry.industryid}}"
                                        href="investorlisting/{{catindustry.industrySlug}}-investment-opportunities/{{catindustry.industryid}}/s0/st0"
                                        (click)="$event.preventDefault()"
                                        >
                                        {{catindustry.industry | limitTo: 20}}
                                    </a>
                           </div>
   
                           <div class="accordion_bodymain" style="display: none;">
                              <div class="more">
                                 <ul class="sub-menu">
                                    <li *ngFor="let subCategory of industrySeller | sortBy : 'subindustry' | filterBy:['industry' ]: catindustry.industry | unique:'subindustry'">  

                                    <input class="sub-gen"
                                        attr.id="{{catindustry.industryid}}"
                                       attr.class="class{{subCategory.subIndustryid}}"
                                       value="" type="checkbox"
                                       (click)="includeindustry(catindustry.industryid, subCategory.subIndustryid, subCategory.subindustry, catindustry.industry, subCategory.subIndustryid)"
                                       [(ngModel)]="filterIndustry[subCategory.subIndustryid]">
                                       <a class="clicked"
                                          attr.id="class{{subCategory.subIndustryid}}"
                                          href="investorlisting/{{subCategory.subIndustrySlug}}-investment-opportunities/{{catindustry.industryid}}/{{subCategory.subIndustryid}}/st0"
                                          (click)="$event.preventDefault()"
                                          >
                                          {{subCategory.subindustry | limitTo: 20}}
                                       </a>
  
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           
                        </div>
   
                        <!-- <div class="morcity"><a href="#">25 More</a></div> -->
                  </div>

               </div>

               <!--- annual sales -->
               <!-- <div class="accordion_container">
                  <div class="accordion_head"> <a href="#">Annual Sales</a> <span class="plusminus minus"></span></div>
                  <div class="accordion_body" style="display: block;">
                     <div class="frmright">

                        <select class="form-control">
                           <option value="50 - 100 Crores">50 - 100 Crores</option>
                        </select>
                     </div>
                  </div>
               </div> -->

               <!--- extablishement year -->
               <!-- <div class="accordion_container">
                  <div class="accordion_head"> <a href="#">Establishment year</a> <span class="plusminus minus"></span>
                  </div>
                  <div class="accordion_body" style="display: block;">
                     <div class="frmright">

                        <select class="form-control">
                           <option value="2001 - 2010">2001 - 2010</option>
                           <option value="2001 - 2010">2011 - 2020</option>
                        </select>
                     </div>
                  </div>
               </div> -->

               <!--- Entity Types -->
               <!-- <div class="accordion_container">
                  <div class="accordion_head"> <a href="#">Entity Type</a> <span class="plusminus add"></span></div>
                  <div class="accordion_body" style="display: none;">
                     More Filters
                  </div>
               </div> -->
               
               <!--- Emply count -->
               <!-- <div class="accordion_container">
                  <div class="accordion_head"> <a href="#">Employee Count</a> <span class="plusminus add"></span></div>
                  <div class="accordion_body" style="display: none;">
                     More Filters
                  </div>
               </div> -->

               <!--- Business Type -->
               <!-- <div class="accordion_container">
                  <div class="accordion_head"> <a href="#">Business Type</a> <span class="plusminus add"></span></div>
                  <div class="accordion_body" style="display: none;">
                     More Filters
                  </div>
               </div> -->

            </div>


         </div>
      </div>


      <div class="col-12 col-sm-9 col-md-9 mdy">
         <div class="row">
            <div class="col-12 col-sm-6 col-md-9 setheading">
               <h1 class="headblk">{{hititle}}</h1>
            </div>
            <div class="col-12 col-sm-6 col-md-3 float-right setmob">
               <div class="form-group">
                  <select class="form-control modysel myselectclass" (ngModelChange)="onChangeObj($event)" name="ProfileSelected" #ProfileSelected="ngModel" ngModel>
                     <option value="" disabled selected hidden>Sort By</option>
                     <option   [ngValue]="option.value" *ngFor="let option of options">{{option.label}}</option>
                 </select>
               </div>
            </div>
         </div>


         <div class="row">
            <!-- <div class="col-md-12">
               <p [innerHTML]="subheadline"></p>
            </div> -->

            <div class="col-md-12 shrt-desc sec-hide" id="shrinkMe">
               <p [innerHTML]="subheadline"></p>
               <a id="more" onclick="shrink(this.id)" class="pos" [routerLink]="">...More</a>
               <a onclick="shrink(this.id)" id="less" class="pos" [routerLink]="">...Less</a>
           </div>

         </div>


         <div class="row setvto">

            <ul class="listop investlist">

               <li class="sec-slide-effect" *ngFor="let investordata of investor; let last = last" [isLast]="last"
               (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">
                  <div class="ribbonblk">
                     <container-element [ngSwitch]="investordata.membership_plan">
                           <div class="ribbonblkinner" *ngSwitchCase="1">Premium</div>
                           <div class="ribbonblkinner" *ngSwitchCase="2">Gold</div>
                           <div class="ribbonblkinner" *ngSwitchCase="3">Platinum</div>
                           <!-- <div class="ribbonblkinner" *ngSwitchDefault></div> -->
                     </container-element>

                  </div>
                  <div class="fullb settmar">
                     <div class="fbleft ">
                        <div class="cname">
                           <div class="cnameinner">
                              {{(investordata.membership_paid==1) ? investordata.investorName :investordata.investorName}}
                              <div class="inet"><span>{{investordata.designation}},{{investordata.companyName}}</span> </div>
                           </div>
                        </div>
                     </div>
                     <div class="fbright">
                        <div class="compper">
                           <img
                              src="{{investordata?.investorProfPic}}">
                        </div>
                     </div>
                  </div>
            

                  <div class="sdd">
                     <div class="sddinner tooltipLP"  title="">
                           <div class="top {{investordata.mobVerifyStatus == 0?'':'active'}}">
                          {{investordata.mobVerifyStatus == 0?'Not Verified Mobile':'Verified Mobile'}}
                           </div>
                               <i class="fa fa-phone-square" aria-hidden="true"></i>
                     </div>

                     <div class="sddinner">
                               <i class="fa fa-envelope" aria-hidden="true"></i>
                     </div>


                         <div class="sddinner">
                               <a href="#" class="bookmar"><i class="fa fa-bookmark" aria-hidden="true"></i> </a>
                     </div>
          



                     <div class="sddinner setwva" *ngIf="investordata.investorCity != '' && investordata.investorCity != 'NULL'">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <span>{{investordata.investorCity}}</span>
                     </div>

                  </div>
                  <div class="fullb summtxt" *ngIf="investordata.investorSummary != ''">
                     <span>Summary</span>
                     {{investordata.investorSummary | limitTo: 80}}
                  </div>
                   <!--      <div class="fullb contxt">
                     {{ (investordata.sectorPref !== '') ? (investordata.sectorPref | limitTo: 30) : ("N/A") }}
                  </div>
 -->
                  <!-- <div class="fullb citytxt">
                     New Delhi, DL, India
                  </div>
                  <div class="tagv">
                     <div class="tagvinner">Hospitality</div>
                     <div class="tagvinner">Hotels</div>
                     <div class="tagvinner">Management</div>
                  </div> -->


                  <div class="backv">
                     <div class="inblk">
                        Inv. Preference 
                        <span *ngIf="investordata.investmentPref != ''">{{investordata.investmentPref}}</span>

                     </div>
                     <div class="inblk">
                        Investment Size <span>
                           <i class="fa fa-inr" aria-hidden="true"></i>
                           {{ investordata.minInvestment }}
                           -
                           {{ investordata.maxInvestment }}
                       </span>
                     </div>
                     <!-- <div class="inblk">
                        Industry Preference <span>{{ (investordata.sectorPref !== '') ? investordata.sectorPref : ("N/A") }}</span>
                     </div> -->

                     <div class="inblk tooltipLPshow">
                        Location Preference 

               
                          <span class="tooltipLPshow">
                             {{ (investordata.locations !== '') ? (investordata.locations | limitTo:20) : ("N/A") }}
                             {{investordata.locations !==''?'+':''}}
                           </span>

                            <div class="top">
                              {{ (investordata.locations !== '') ? investordata.locations : ("N/A") }}
                           </div>

                     </div>

                     <div class="inblk tooltipLPshow">
                        Sector Preference 
                           <span>{{ (investordata.sectorPref !== '') ? (investordata.sectorPref | limitTo:20) : ("N/A") }} 
                              {{investordata.sectorPref !==''?'+':''}}
                           </span>
                            <div class="top">
                              {{ (investordata.sectorPref !== '') ? investordata.sectorPref : ("N/A") }}
                           </div>
                     </div>
                  </div>

                  <div class="inbtn"><a [routerLink]="['/investor/'+investordata.investorurl+'/'+investordata.investorStr]">Send Proposal</a></div>

               </li>

            </ul>

         </div>


         <div class="row pt30 pb-30">
            <div class="col-md-12">

               <listing-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="5"
                  [page]="currentPage" [perPage]="itemsPerPage" [count]="totalItems">
               </listing-pagination>


            </div>
         </div>
      </div>

   </div>


</div>