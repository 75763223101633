import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { appConfig } from '../../../frontend/app.config';
import { trigger, transition, state, animate, style, AnimationEvent } from '@angular/animations';
import { AuthenticationService } from 'projects/frontend/src/app/services/authentication.service';
import { MapsAPILoader } from '@agm/core';
import { Subscription } from 'rxjs';
import { UniquePipe } from '../../../../pipes/unique.pipe';
import {LinkService} from '../../../../services/link.service';
import { Meta, Title } from '@angular/platform-browser';


declare var $: any;
declare var jQuery: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        height: '100px',
        opacity: 0.5,
        backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
      transition('* => closed', [
        animate('1s')
      ]),
      transition('* => open', [
        animate('0.5s')
      ]),
      transition('open <=> closed', [
        animate('0.5s')
      ]),
      transition('* => open', [
        animate('1s',
          style({ opacity: '*' }),
        ),
      ]),
      transition('* => *', [
        animate('1s')
      ]),
    ]),
  ],
  providers: [UniquePipe]

})


export class HomeComponent implements OnInit {

  bxNewsHome: any;
  bxArticleHome: any;
  bxIndustryReport: any;
  seller: any;
  investor: any;
  mentor: any;
  startup: any;
  quickRegistration: FormGroup;
  subscribeForm: FormGroup;
  submitted: boolean = false;
  submittedSubscribe: boolean = false;
  submitMessage: any;
  dataResponse;
  subscribeMessage = false;
  loading: boolean = false
  totalBussiness: any = 0;
  totalBussinessIndustries: any = 0;
  totalActiveInvestors: any = 0;
  totalInvestorIndustries: any = 0;
  totalStartupIndustries: any = 0;
  totalAtiveStartup: any = 0;
  totalActiveMentor:any=0;
  currentDate = new Date();
  imagePathName: any;
  public utm_source: any;
  public utm_campaign: any;


  constructor(
    private homeService: HomeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    private mapsAPILoader: MapsAPILoader,
    private UniquePipe: UniquePipe,
    private linkService: LinkService,
    private meta: Meta,
    private title: Title

  ) { 


      linkService.removeCanonicalLink();
      linkService.addTag({rel: 'canonical', href: 'https://www.businessex.com/'});
      title.setTitle('Business for Sale & Investors in India - BusinessEx');

      this.meta.updateTag({ name: 'Keywords', content: 'business for sale, sell my business, buying a business, business brokers'});
      this.meta.updateTag({ name: 'Description', content: 'BusinessEx serves as the perfect catalyst for all those who seek to establish their business in sectors like F&B, Retail, Beauty & Wellness and Education. BusinessEx welcomes potential startups,incubators, lenders, mentors, brokers and investors to establish their own communities for smooth transaction.' });
      this.meta.updateTag({ name: 'site_name', content: 'www.businessex.com' });
      this.meta.updateTag({ property: 'og:type', content: 'website' });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.businessex.com' });
      this.meta.updateTag({ property: 'og:title', content: 'Business for Sale &amp; Investors in India - BusinessEx' });
      this.meta.updateTag({ property: 'og:description', content: 'BusinessEx serves as the perfect catalyst for all those who seek to establish their business in sectors like F&amp;B, Retail, Beauty &amp; Wellness and Education. BusinessEx welcomes potential startups, incubators, lenders, mentors, brokers and investors to establish their own communities for smooth transaction..' });

      this.meta.updateTag({ property: 'og:image', content: 'https://www.businessex.com/images/' });
      this.meta.updateTag({ property: 'og:image:height', content: '174' });
      this.meta.updateTag({ property: 'fb:admins', content: '847759838584827' });
      this.meta.updateTag({ property: 'fb:page_id', content: '865253970178641' });
      this.meta.updateTag({ property: 'og:locale', content: 'en_IN' });
      this.meta.updateTag({ property: 'og:country-name', content: 'India' });
      this.meta.updateTag({ property: 'og:site_name', content: 'www.businessex.com' });
      this.meta.updateTag({ name: 'p:domain_verify', content: '015f41e2cbc522e63f274e0c4a64aea3' });
      this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
      this.meta.updateTag({ name: 'twitter:site', content: '@businessex.com' });
      this.meta.updateTag({ name: 'twitter:title', content: 'Business for Sale &amp; Investors in India - BusinessEx' });
      this.meta.updateTag({ name: 'twitter:description', content: 'BusinessEx serves as the perfect catalyst for all those who seek to establish their business in sectors like F&amp;B, Retail, Beauty &amp; Wellness and Education. BusinessEx welcomes potential startups, incubators, lenders, mentors, brokers and investors to establish their own communities for smooth transaction.' });
      this.meta.updateTag({ name: 'contact_addr', content: 'sales@businessex.com' });
      this.meta.updateTag({ name: 'language', content: 'en-USA' });
      this.meta.updateTag({ name: 'owner', content: 'businessex' });
      this.meta.updateTag({ name: 'distribution', content: 'GLOBAL' });
      this.meta.updateTag({ name: 'city', content: 'Faridabad' });
      this.meta.updateTag({ name: 'country', content: 'India' });
      this.meta.updateTag({ name: 'state', content: 'Haryana' });
      this.meta.updateTag({ name: 'zipcode', content: '121009' });
      this.meta.updateTag({ name: 'geo.position', content: '7.676590;-81.103650' });
      this.meta.updateTag({ name: 'geo.placename', content: 'Faridabad, Haryana' });
      this.meta.updateTag({ name: 'geo.region', content: 'India' });
      this.meta.updateTag({ name: 'rating', content: 'general' });
      this.meta.updateTag({ name: 'DC.Publisher', content: 'businessex' });

      this.route.queryParams.subscribe(params => {
          this.utm_source = params['utm_source'];
          this.utm_campaign = params['utm_campaign'];
      });

      this.imagePathName = [
      {
          id: 1,
          title_slug: 'automobile',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/automobile.jpg',
          title: 'Automobile',
          cat_slug: 'Automobile',
          cat_id: 1,
          listingPageImage: 'AngularJS',
          detailPageImage: 'AngularJS',
          iconPath: 'automotive.png',
      },
      {
          id: 2,
          title_slug: 'fmcg',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/fmcg.jpg',
          title: 'FMCG',
          cat_slug: 'FMCG',
          listingPageImage: 'AngularJS',
          cat_id: 2,
          detailPageImage: 'AngularJS',
          iconPath: 'fmcg.png',
      },
      {
          id: 3,
          title_slug: 'education',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/education.jpg',
          title: 'Education',
          cat_slug: 'Education',
          listingPageImage: 'AngularJS',
          cat_id: 3,
          detailPageImage: 'AngularJS',
          iconPath: 'education.png',
      },
      {
          id: 4,
          title_slug: 'beauty-health-wellness',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/beauty.jpg',
          title: 'Beauty, Health & Wellness',
          cat_slug: 'beauty-health-wellness',
          cat_id: 4,
          listingPageImage: 'AngularJS',
          detailPageImage: 'AngularJS',
          iconPath: 'beauty.png',
      },
      {
          id: 5,
          title_slug: 'business-services',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/business.jpg',
          title: 'Business Services',
          cat_slug: 'business-services',
          listingPageImage: 'AngularJS',
          cat_id: 5,
          detailPageImage: 'AngularJS',
          iconPath: 'business.png',
      },
      {
          id: 6,
          title_slug: 'food-beverage',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/food.jpg',
          title: 'Food & Beverage',
          cat_slug: 'food-beverage',
          listingPageImage: 'AngularJS',
          cat_id: 6,
          detailPageImage: 'AngularJS',
          iconPath: 'food.png',
      },
      {
          id: 7,
          title_slug: 'fashion',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/fashion.jpg',
          title: 'Fashion',
          cat_slug: 'fashion',
          listingPageImage: 'AngularJS',
          cat_id: 7,
          detailPageImage: 'AngularJS',
          iconPath: 'fashion.png',
      },
      {
          id: 8,
          title_slug: 'building-construction-home-products',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/building.jpg',
          title: 'Construction & Maintenance',
          cat_slug: 'building-construction-home-products',
          cat_id: 8,
          listingPageImage: 'AngularJS',
          detailPageImage: 'AngularJS',
          iconPath: 'buildingconstruction.png',
      },
      {
          id: 9,
          title_slug: 'travel-tourism',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/travel.jpg',
          title: 'Travel & Tourism',
          cat_slug: 'travel-tourism',
          listingPageImage: 'AngularJS',
          cat_id: 9,
          detailPageImage: 'AngularJS',
          iconPath: 'travel.png',
      },
      {
          id: 10,
          title_slug: 'leisure-entertainment',
          sub_cat_id: '170-171',
          homePageImage: 'assets/images/category/small/entertainment.jpg',
          title: 'Leisure & Entertainment',
          cat_slug: 'leisure-entertainment',
          cat_id: 10,
          listingPageImage: 'AngularJS',
          detailPageImage: 'AngularJS',
          iconPath: 'entertainment.png',
      },
      {
          id: 11,
          title_slug: 'finance',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/finance.jpg',
          title: 'Finance',
          cat_slug: 'finance',
          listingPageImage: 'AngularJS',
          cat_id: 11,
          detailPageImage: 'AngularJS',
          iconPath: 'financenew.png',
      },
      {
          id: 12,
          title_slug: 'energy-environment',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/energy.jpg',
          title: 'Energy & Environment',
          cat_slug: 'energy-environment',
          cat_id: 12,
          listingPageImage: 'AngularJS',
          detailPageImage: 'AngularJS',
          iconPath: 'energy.png',
      },
      {
          id: 13,
          title_slug: 'industrial-machinery-manufacturing',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/manufacturing.jpg',
          title: 'Machinery & Manufacturing',
          cat_slug: 'industrial-machinery-manufacturing',
          cat_id: 13,
          listingPageImage: 'AngularJS',
          detailPageImage: 'AngularJS',
          iconPath: 'industrial.png',
      },
      {
          id: 14,
          title_slug: 'retail',
          sub_cat_id: 's0',
          homePageImage: 'assets/images/category/small/retail.jpg',
          title: 'Retail',
          cat_slug: 'retail',
          listingPageImage: 'AngularJS',
          cat_id: 14,
          detailPageImage: 'AngularJS',
          iconPath: 'retail.png',
      }
      ];
  }

  initQrForm() {

    this.quickRegistration = this.formBuilder.group(
      {
        business_name: ['', Validators.required],
        s_name: ['', Validators.required],
        s_mobile: ['', Validators.required],
        s_email: ['', [Validators.required, Validators.email]],
        s_company: ['', Validators.required],
      }
    );

    /*this.subscribeForm = this.formBuilder.group({
      email:['',[Validators.required,Validators.email]]
    })*/

  }

  get qRform() { return this.quickRegistration.controls; }

  profileSubmit(value) {

    switch(value){
      case '1': {
        this.router.navigate(['/registration/create-business-profile']);
      break;
      }
      case '2': {
        this.router.navigate(['/registration/create-investor-profile']);
      break;
      }
      case '4': {
       this.router.navigate(['/registration/create-mentor-profile']);
      break;
      }
      default: { 
        this.router.navigate(['/registration/create-startup-profile']);
        break; 
     } 

    }
  }

  qRFormSubmit() {

    this.submitted = true;

    if (this.quickRegistration.invalid) {
      return;
    }

    let data = {
      'business_name': this.quickRegistration.controls.business_name.value,
      's_name': this.quickRegistration.controls.s_name.value,
      's_mobile': this.quickRegistration.controls.s_mobile.value,
      's_email': this.quickRegistration.controls.s_email.value,
      's_company': this.quickRegistration.controls.s_company.value,
    }

    let endPointUrl = 'salesSellerProfile'
    switch (this.quickRegistration.controls.business_name.value) {
      case ('sell'):
        endPointUrl = 'salesSellerProfile';
        break;
      case ('start-up'):
        endPointUrl = 'salesStartupProfile';
        break;
      case ('invest'):
        endPointUrl = 'salesInvestorProfile';
        break;
      case ('mentor'):
        endPointUrl = 'salesMentorProfile';
        break;
      case ('incubate'):
        endPointUrl = 'salesIncubatorProfile';
        break;
      default:
        endPointUrl = 'salesSellerProfile';
        break;
    }

    this.homeService.quickRegistration(endPointUrl, data).subscribe(
      dataResponse => {
        this.dataResponse = dataResponse;

        appConfig.setNewProfileDetails(this.dataResponse.data.user_id, this.dataResponse.data.profile_type, this.dataResponse.data.profile_id);

        this.router.navigate(['/registration/quick-registration-thankyou']);
      },
      error => {
        this.quickRegistration.reset()
        this.handleError(error);
      }
    );

  }

  handleError(error) {
    this.submitMessage = error.error;

    /*$('html, body').animate({
        // awc is an example. Use the id of your destination on the page
        scrollTop: $('#awc').offset().top - 450
    }, 'slow');*/

    setTimeout(function () {
      $('.msg-pnl_sales').delay(0).slideToggle('slow');
      $('.msg-pnl_sales').delay(5000).slideToggle('slow');
    }, 50);

    // showing login popup if user already exist. Don't change the message
    /*if (error.error === 'User already exists. Kindly login and create profile') {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                $('#modalLogin_sales').modal({
                    backdrop: 'static',
                    keyboard: false
                });
            }, 3000);
        }
    }*/
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
        if (this.utm_source !== '' && this.utm_source !== undefined) {
            localStorage.setItem('trackid', this.utm_source);
            localStorage.setItem('utm_source', this.utm_source);
            localStorage.setItem('utm_campaign', this.utm_campaign);
        }
    }

    this.initQrForm();

    if (isPlatformBrowser(this.platformId)) {
      this.setCarouselConfig();
    }

    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }

    });

    this.locationBasedBusiness();
    this.getInvestor();
    this.getStartup();
    this.getMentor();

  }

  ngAfterViewInit(): void {

    if (isPlatformBrowser(this.platformId)) {

      /*if(screen.width>767){
        
        setTimeout(function() {
          $('#eventlogin').modal({
            backdrop: 'static',
            keyboard: false
          })
        }, 2000);

      }*/

    }

    this.getBxarticlehome();
    this.getBxnewshome();
    //this.getBxindustryreport();

  }

  getBxnewshome(): void {
    this.homeService.getBxnewshome().subscribe((res: Response) => {
      let resData: any = res;
      this.bxNewsHome = resData.code === 200 ? [] : resData.bxnewshomedata
    });

  }

  getBxarticlehome(): void {
    this.homeService.getBxarticlehome().subscribe((res: Response) => {
      let resData: any = res;
      this.bxArticleHome = resData.code === 200 ? [] : resData.bxarticlehomedata
      //console.log(this.bxArticleHome);
    })
  }

  /*getBxindustryreport(): void {
    this.homeService.getBxindustryreport().subscribe((res: Response) => {
      let resData: any = res;
      this.bxIndustryReport = resData.code === 200 ? [] : resData.bxindustryreportdata
    })
  }*/


  getSeller(stateShortName, cityName): void {

    this.loading = true;

    this.homeService.replymySubjectBusinessFooter.subscribe((data) => {
      this.totalBussinessIndustries = this.UniquePipe.transform(data, 'industry').length;
    });

    /*this.homeService.getTotalActiveBussiness().subscribe((res: Response) => {
      let resData: any = res;
      this.totalBussiness = resData.total;
    })*/

    this.homeService.getSeller(stateShortName, cityName).subscribe((res: Response) => {
      let resData: any = res;
      this.seller = resData.sellerdatalist;
      this.totalBussiness = resData.total;

      this.loading = false;

      if (resData.userCurrentLocation['city'] !== undefined && resData.userCurrentLocation['state'] !== undefined) {
        localStorage.setItem('city', resData.userCurrentLocation.city);
        localStorage.setItem('state', resData.userCurrentLocation.state);
      }

    })



  }

  getInvestor(): void {

    this.homeService.replymySubjectInvestorFooter.subscribe((data) => {
      this.totalInvestorIndustries = this.UniquePipe.transform(data, 'industry').length;
      //this.totalInvestorsSubIndustry = this.UniquePipe.transform(data, 'subindustry').length;
    });

    /*this.homeService.getTotalActiveInvestors().subscribe((res: Response) => {
      let resData: any = res;
      this.totalActiveInvestors = resData.total;
    })*/

    this.loading = true;
    this.homeService.getInvestor().subscribe((res: Response) => {
      console.log(res)
      let resData: any = res;
      this.loading = false;
      this.investor = resData.code === 200 ? [] : resData.featuredInvestorsData
      this.totalActiveInvestors = resData.code === 200 ? 0 :resData.total;
    })
  }

  getMentor(): void {
    this.loading = true;

    /*this.homeService.getTotalActiveMentors().subscribe((res: Response) => {
      let resData: any = res;
      this.totalActiveMentor = resData.total;
    })*/

    this.homeService.getMentor().subscribe((res: Response) => {
      let resData: any = res;
      this.loading = false;
      this.mentor = resData.code === 200 ? [] : resData.mentorList
      this.totalActiveMentor = resData.code === 200 ? 0 :resData.total;
    })
  }

  getStartup(): void {

    this.homeService.replymySubjectStartupFooter.subscribe((data) => {
      this.totalStartupIndustries = this.UniquePipe.transform(data, 'industry').length;
    });

    /*this.homeService.getTotalActiveStartups().subscribe((res: Response) => {
      let resData: any = res;
      this.totalAtiveStartup = resData.total;
    })*/


    this.loading = true;
    this.homeService.getStartup().subscribe((res: Response) => {
      this.loading = false;
      let resData: any = res;
      this.startup = resData.code === 200 ? [] : resData.sellerdatalist
      this.totalAtiveStartup = resData.code === 200 ? 0 : resData.total
      

    })

  }

  private locationBasedBusiness() {

    //console.log('----locationBasedBusiness----');

    /*if (localStorage.getItem('lat') === null) {
      if (localStorage.getItem('state') !== null && localStorage.getItem('city') !== null) {
        console.log('passing city & state got from server side using 3rd party');
        this.getSeller(localStorage.getItem('state'), localStorage.getItem('city'));
      } else {
        console.log('not waiting for user location permission');
        this.getSeller('', '');
      }
    }*/


   /* if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          if (localStorage.getItem('lat') !== null && localStorage.getItem('lat') == position.coords.latitude.toString()) {
            this.getSeller(localStorage.getItem('state'), localStorage.getItem('city'));
          } else {
            this.getSeller('', '');
           /* this.mapsAPILoader.load().then(() => {
              let geocoder = new google.maps.Geocoder();
              let latlng = { location: { lat: position.coords.latitude, lng: position.coords.longitude } };
              // let latlng = {location: {lat: 28.978064, lng: 77.699928}};
              geocoder.geocode(latlng, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                  if (results[0] != null) {
                    //console.log('calling goggle api First time!');
                    let rsltAdrComponent = results[0].address_components;
                    let state = rsltAdrComponent[rsltAdrComponent.length - 3].short_name;
                    let city = rsltAdrComponent[rsltAdrComponent.length - 4].short_name;
                    localStorage.setItem('lat', position.coords.latitude.toString());
                    localStorage.setItem('city', city);
                    localStorage.setItem('state', state);
                    // window.location.reload();
                    this.getSeller(state, city);
                  } else {
                    console.log('No address available!');
                  }
                } else {
                  console.log('GEO Status is not OK');
                }
              });
            });
          }
        }
      );
    }else{*/

      if (localStorage.getItem('lat') === null) {
        if (localStorage.getItem('state') !== null && localStorage.getItem('city') !== null) {
          //console.log('passing city & state got from server side using 3rd party');
          this.getSeller(localStorage.getItem('state'), localStorage.getItem('city'));
        } else {
         // console.log('not waiting for user location permission');
          this.getSeller('', '');
        }
      }else{
        this.getSeller('', '');
      }

    //}
  }



  featuredLisitingBusiness(className): void {

    if (isPlatformBrowser(this.platformId)) {
     setTimeout(() => {
        $('.' + className).owlCarousel({
          margin: 0,
          autoplay: true,
          nav: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeInUp',
          navText: ['<div class="bex-arrow-section"><div><a</div><i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i></div>'],
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items:2
            },

            1024: {
              items: 4
            },

            1366: {
              items: 4
            }
          }
        });

      }, 1000);
    }

  }



  featuredLisiting(className): void {

    if (isPlatformBrowser(this.platformId)) {
     // setTimeout(() => {
        $('.' + className).owlCarousel({
          margin: 0,
          autoplay: true,
          nav: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeInUp',
          navText: ['<div class="bex-arrow-section"><div><a</div><i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i></div>'],
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items:2
            },

            1024: {
              items: 4
            },

            1366: {
              items: 4
            }
          }
        });
      //}, 3000);
    }

  }

  private setCarouselConfig() {

    var nav = $('nav');
    var navHeight = nav.outerHeight();
    // Preloader
    $(window).on('load', function () {
      if ($('#preloader').length) {
        $('#preloader').delay(100).fadeOut('slow', function () {
          $(this).remove();
        });
      }
    });

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });
    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo');
      return false;
    });

    /*--/ ScrollReveal /Easy scroll animations for web and mobile browsers /--*/

    /* window.sr = ScrollReveal();
     sr.reveal('.foo', {
       duration: 1000,
       delay: 15
     });*/


    /*--/ Carousel owl /--*/
    $('#carousel').owlCarousel({
      loop: true,
      margin: -1,
      items: 1,
      nav: true,
      navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true
    });

    /*--/ Animate Carousel /--*/
    $('.intro-carousel').on('translate.owl.carousel', function () {
      $('.intro-content .intro-title').removeClass('animate__zoomIn animate__animated').hide();
      $('.intro-content .intro-price').removeClass('animate__fadeInUp animate__animated').hide();
      $('.intro-content .intro-title-top, .intro-content .spacial').removeClass('animate__fadeIn animate__animated').hide();
    });

    $('.intro-carousel').on('translated.owl.carousel', function () {
      $('.intro-content .intro-title').addClass('animate__zoomIn animate__animated').show();
      $('.intro-content .intro-price').addClass('animate__fadeInUp animate__animated').show();
      $('.intro-content .intro-title-top, .intro-content .spacial').addClass('animate__fadeIn animate__animated').show();
    });

    /*--/ Navbar Collapse /--*/
    $('.navbar-toggle-box-collapse').on('click', function () {
      $('body').removeClass('box-collapse-closed').addClass('box-collapse-open');
    });
    $('.close-box-collapse, .click-closed').on('click', function () {
      $('body').removeClass('box-collapse-open').addClass('box-collapse-closed');
      $('.menu-list ul').slideUp(700);
    });

    /*--/ Navbar Menu Reduce /--*/
    $(window).trigger('scroll');
    $(window).bind('scroll', function () {
      var pixels = 50;
      var top = 1200;
      if ($(window).scrollTop() > pixels) {
        $('.navbar-default').addClass('navbar-reduce');
        $('.navbar-default').removeClass('navbar-trans');
      } else {
        $('.navbar-default').addClass('navbar-trans');
        $('.navbar-default').removeClass('navbar-reduce');
      }
      if ($(window).scrollTop() > top) {
        $('.scrolltop-mf').fadeIn(1000, "easeInOutExpo");
      } else {
        $('.scrolltop-mf').fadeOut(1000, "easeInOutExpo");
      }
    });

    /*--/ Property owl /--*/
    $('#property-carousel').owlCarousel({
      loop: true,
      margin: 30,
      responsive: {
        0: {
          items: 1,
        },
        769: {
          items: 1,
        },
        992: {
          items: 3,
        }
      }
    });

    /*--/ Property owl owl /--*/
    $('#property-single-carousel').owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
      responsive: {
        0: {
          items: 1,
        }
      }
    });

    /*--/ News owl /--*/
    $('#new-carousel').owlCarousel({
      loop: true,
      margin: 30,
      responsive: {
        0: {
          items: 1,
        },
        769: {
          items: 1,
        },
        992: {
          items: 3,
        }
      }
    });

    /*--/ Testimonials owl /--*/
    $('#bex-bussiness-carousel').owlCarousel({
      margin: 0,
      autoplay: true,
      nav: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeInUp',
      navText: ['<div class="bex-arrow-section"><i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i></div>'],
      autoplayTimeout: 8000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1
        },

        1024: {
          items: 3
        },

        1366: {
          items: 3
        }
      }
    });

    //bex-upcoming-past-events-carousel
    $('#bex-upcoming-past-events-carousel').owlCarousel({
      margin: 0,
      autoplay: false,
      nav: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeInUp',
      navText: ['<div class="bex-arrow-sectionm"><i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i></div>'],
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1
        },

        1024: {
          items: 2
        },

        1366: {
          items: 2
        }
      }
    });

    


    $('#bex-dealership-carousel,#topfranchiseopportunities').owlCarousel({
      margin: 0,
      autoplay: true,
      nav: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeInUp',
      navText: ['<div class="bex-arrow-section"><div><a</div><i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i></div>'],
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items:2
        },

        1024: {
          items: 4
        },

        1366: {
          items: 4
        }
      }
    });
   
    $('#clientssay').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
          0: {
              items: 1
          },
          600: {
              items: 2
          },
          1000: {
              items: 3
          }
        }
    })

  }

  pricingPage(value) {
    this.router.navigate(['/pricing'],{ queryParams:{membership:value}});
  }



}
