
        <div class="row backbg">
            <div class="col-12 col-sm-12 col-md-12 contbg">
                <h2 class="stati2chead">Contact to Executive</h2>
                <form name="contactUsForm" #contactUsForm="ngForm"
                    (ngSubmit)="contactUsForm.form.valid && contactUsFormSubmit(contactUsForm.value)"
                    class="form-horizontal" role="form">
                    <div class="sec-slide-effect">
                        <div class="row">
                            <div class="msg-pnl_sales custom_error" *ngIf="ContactError"
                                style=" text-align: center; clear: both; margin: 0px 15px 20px;">
                                <div *ngFor="let message of ContactError | keyspipe : true">
                                    {{message}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-12 col-md-12 control-label">Your Name<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="input-group">
                                    <input type="text" name="contact_name" #contact_name="ngModel" ngModel
                                        class="form-control" placeholder="Enter Your Name"
                                        data-error="Please fill out Name" required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <div class="col-xs-12 col-sm-12 col-md-12 control-label">Email Address<span
                                    class="star">*</span> <span>:</span></div>
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="input-group">
                                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        name="contact_email" #contact_email="ngModel" ngModel class="form-control"
                                        placeholder="Enter Your Email ID" data-error="Please fill out Email"
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-12 col-md-12 control-label">Mobile Number<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="input-group">
                                    <input type="text" name="contact_mobile" #contact_mobile="ngModel" ngModel
                                        pattern="[56789][0-9]{9}" class="form-control"
                                        placeholder="Enter Your Mobile Number"
                                        data-error="Please enter valid mobile number" required
                                        onkeypress="return isNumber(event)">
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-12 col-md-12 control-label">Comments<span
                                    class="star">*</span><span>:</span></label>
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="input-group">
                                    <textarea name="contact_comment" #contact_comment="ngModel" ngModel
                                        class="form-control" rows="3" minlength="15" maxlength="150" 
                                        placeholder="Enter Your Name" data-error="Please fill out Comment"
                                        required></textarea>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <!-- <div class="row marsettop">
                            <label class="col-12 col-sm-12 col-md-12 frmtxt"></label>
                            <div class="col-xs-12 col-sm-12 col-offset-6">
                                <div class="checkbox">
                                    <label>
                                        <input type='checkbox' name="subscribe" #subscribe="ngModel" ngModel> Subscribe
                                        for latest news
                                    </label>
                                </div>
                            </div>
                        </div> -->
                    </div>


                    <div class="row setborder">
                        <button type="submit" class="frmbtn">SUBMIT</button>
                    </div>

                    <div class="termstxt">By Clicking Submit you are Accepting <a routerLink="/terms">Terms &
                            Conditions</a></div>




                </form>


            </div>

        </div>


