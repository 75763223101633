<div class="click-closed"></div>
<!-- <div class="box-collapse" style="display:none;">
   <div class="title-box-d">
      <h3 class="title-d">Search Section</h3>
   </div>
   <span class="close-box-collapse right-boxed ion-ios-close"></span>
   <div class="box-collapse-wrap form">
      <ol class="breadcrumb">
         <li class="breadcrumb-item">
            <a href="#">Home</a>
         </li>
         <li class="breadcrumb-item active" aria-current="page">
            Search
         </li>
      </ol>
      <form class="form-a">
         <div class="input-group mb-3">
            <div class="input-group-prepend">
          
               <select class="custom-select" id="inputGroupSelect01">
                  <option selected>Choose...</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
               </select>
            </div>
            <input type="text" class="form-control" aria-label="Text input with dropdown button">
            <div class="input-group-append">
               <button class="btn btn-outline-secondary-filter" type="button">
                  <span class="bex-filter"><i class="fas fa-search"></i> Search</span>
               </button>
               <button class="btn btn-outline-secondary-filter" type="button" data-toggle="collapse"
                  data-target="#filter" aria-expanded="false" aria-controls="filter"><i class="fa fa-filter"
                     aria-hidden="true"></i><span class="bex-filter">Filters</span></button>
            </div>
            <div class="collapse filter-main" id="filter">
               <div class="card card-body card-mt15">
                  <div class="row">
                     <div class="col-md-4 mb-2">
                        <div class="form-group">
                           <label for="Industry_location">Location</label>
                           <select class="form-control form-control-lg form-control-a" id="Industry_location">
                              <option>Location1</option>
                              <option>Location2</option>
                              <option>Location3</option>
                              <option>Location4</option>
                           </select>
                        </div>
                     </div>
                     <div class="col-md-4 mb-2">
                        <div class="form-group">
                           <label for="Industry_1">Industry</label>
                           <select class="form-control form-control-lg form-control-a" id="Industry_1">
                              <option>Industry1</option>
                              <option>Industry2</option>
                              <option>Industry3</option>
                              <option>Industry4</option>
                           </select>
                        </div>
                     </div>
                     <div class="col-md-4 mb-2">
                        <div class="form-group">
                           <label for="bedrooms">Investment Range</label>
                           <div class="d-flex">
                              <b class="bex-amount-left">₹ 25 Lakhs </b> <input id="ex2" type="text" class="span2"
                                 value="" data-slider-min="25" data-slider-max="200" data-slider-step="5"
                                 data-slider-value="[100,150]" /> <b class="bex-amount-right">₹ 200 Crores</b>
                           </div>

                        </div>
                        <div class="bex-input-range-output">
                           <div class="bex-minmax-imput mr-10">
                              <input type="text" class="form-control form-control-a" placeholder="₹500000">
                           </div>
                           <div class="bex-minmax-imput mr-10">
                              <input type="text" class="form-control form-control-a" placeholder="₹500000">
                           </div>
                           <div class="bex-minmax-imput">
                              <button class="btn btn-outline-secondary-filter">GO</button>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-12">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </form>
      <div class="bex-search-section">
         <span>
            <i class="fa fa-bolt" aria-hidden="true"></i> Trending Searches:
         </span>
         <span>
            <ul class="bex-trending-search-tab">
               <li>Hospitality</li>
               <li>Hotels</li>
               <li>Management</li>
               <li>Education</li>
               <li>Pre-School</li>
               <li>Restaurants</li>
               <li>Food Parlor</li>
            </ul>
         </span>
      </div>
      <section class="section-business-ex sectiont2 nav-arrow-a card card-body mt20px">
         <div class="row">
            <div class="col-md-12">
               <div class=" d-flex justify-content-between">
                  <div class="title-box">
                     <h2 class="bex-title-a">Popular Categories</h2>
                  </div>
               </div>
            </div>
         </div>
         <div class="row fnt-16px">
            <div class="col-md-3">
               <div class="title-wrap d-flex justify-content-between">
                  <div class="title-box">
                     <h5 class="bex-title-a">Business</h5>
                     <p class="bex-heading-main">Automobile</p>
                     <ul class="bex-popular-categories">
                        <li>Automobile Maintanance & Repair</li>
                        <li>Automobile Parts</li>
                        <li>Automobile Wash</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class=" d-flex justify-content-between">
                  <div class="title-box">
                     <h5 class="bex-title-a">Business</h5>
                     <p class="bex-heading-main">Automobile</p>
                     <ul class="bex-popular-categories">
                        <li>Automobile Maintanance & Repair</li>
                        <li>Automobile Parts</li>
                        <li>Automobile Wash</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="title-wrap d-flex justify-content-between">
                  <div class="title-box">
                     <h5 class="bex-title-a">Business</h5>
                     <p class="bex-heading-main">Automobile</p>
                     <ul class="bex-popular-categories">
                        <li>Automobile Maintanance & Repair</li>
                        <li>Automobile Parts</li>
                        <li>Automobile Wash</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="title-wrap d-flex justify-content-between">
                  <div class="title-box">
                     <h5 class="bex-title-a">Business</h5>
                     <p class="bex-heading-main">Automobile</p>
                     <ul class="bex-popular-categories">
                        <li>Automobile Maintanance & Repair</li>
                        <li>Automobile Parts</li>
                        <li>Automobile Wash</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</div> -->

<!-- nave-bar-header-start-->
<div class="headblk">
  <nav class="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
    <div class="container set">
      <a
        class="navbar-brand text-brand logblk"
        [routerLink]="['/']"
        (click)="onclickFooterPage()"
      >
        <span><img src="./assets/img/logo.JPG" /></span>
      </a>

      <div class="flefthome">
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="navbar-collapse collapse" id="navbarDefault">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" [routerLink]="['/']">Home</a>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Bx Listings
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" [routerLink]="['/businesslisting']"
                  >Business</a
                >
                <a class="dropdown-item" [routerLink]="['/startupslisting']"
                  >Startup</a
                >
                <a class="dropdown-item" [routerLink]="['/investorlisting']"
                  >Investor</a
                >
                <a class="dropdown-item" [routerLink]="['/mentorlisting']"
                  >Mentor</a
                >
                <a
                  class="dropdown-item"
                  href="https://www.franchiseindia.com/"
                  target="_blank"
                  >Franchise</a
                >
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                [routerLink]=""
                id="registrationDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Registration
              </a>
              <div class="dropdown-menu" aria-labelledby="registrationDropdown">
                <a
                  class="dropdown-item"
                  [routerLink]="['/registration/create-business-profile/']"
                  >Business</a
                >

                <a
                  class="dropdown-item"
                  [routerLink]="['/registration/create-investor-profile/']"
                  >Investor</a
                >

                <a
                  class="dropdown-item"
                  [routerLink]="['/registration/create-mentor-profile/']"
                  >Mentor</a
                >

                <a
                  class="dropdown-item"
                  [routerLink]="['/registration/create-startup-profile/']"
                  >Startup</a
                >
                <a
                  class="dropdown-item"
                  [routerLink]="['/registration/create-lender-profile/']"
                  >Lender</a
                >
                <a
                  class="dropdown-item"
                  href="https://www.franchiseindia.com/franchisor/registration/step/1"
                  >Franchise</a
                >
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['pricing']">Pricing</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['article']"> Bx Insights </a>
            </li>

            <!-- 
                  <li class="nav-item dropdown">
                     <a class="nav-link dropdown-toggle" [routerLink]="" id="articleDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Bx Insights
                     </a>
                     <div class="dropdown-menu" aria-labelledby="articleDropdown">
                        <a class="dropdown-item" [routerLink]="['/article']">Article</a>
                        <a class="dropdown-item" [routerLink]="['/news']">News</a>
                        <a class="dropdown-item" [routerLink]="['/bex-shorts']">BEx Shorts</a>


                     </div>
                  </li> -->

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                [routerLink]=""
                id="servicesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </a>

              <div class="dropdown-menu" aria-labelledby="registrationDropdown">
                <a
                  class="dropdown-item"
                  [routerLink]="['/service/business-valuation/']"
                  >Business valuation</a
                >

                <a
                  class="dropdown-item"
                  [routerLink]="['/service/business-plan/']"
                  >Business Plan</a
                >

                <a
                  class="dropdown-item"
                  [routerLink]="['/service/due-diligence/']"
                  >Due Dilligence</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="frighthome">
        <button
          *ngIf="!authService.isAuthenticated()"
          type="button"
          class="btn btn-b-n mob1"
          data-toggle="modal"
          data-target="#login"
        >
          <img src="./assets/img/account_circle-24px.svg" alt="" />
        </button>

        <div
          class="btn-group"
          role="group"
          aria-label="Basic example"
          *ngIf="authService.isAuthenticated()"
        >
          <ul class="myaccountind">
            <li>
              <a
                href="#"
                aria-expanded="true"
                aria-haspopup="true"
                class="dropdown-toggle"
                data-toggle="dropdown"
                [routerLink]=""
                role="button"
                ><i class="fa fa-comments-o" aria-hidden="true"></i
              ></a>
              <div class="nofy" *ngIf="unReadNotificationcount > 0">
                <div class="nofyin">{{ unReadNotificationcount }}</div>
              </div>
              <div class="dropdown-menu notification-width bx-mail">
                <div class="notification-heading">
                  BX Inbox<a
                    class="pull-right"
                    [routerLink]="['/dashboard/myinteraction']"
                    >View all</a
                  >
                </div>

                <ul class="scrollarea" style="height: 350px; clear: both">
                  <li *ngFor="let msgreadunread of bxinboxnotification">
                    <div class="img-sec">
                      <a
                        style="padding: 0px"
                        routerLink="{{ msgreadunread.profilelink }}"
                      >
                        <img
                          width=""
                          height=""
                          *ngIf="
                            msgreadunread.profileName == 'Business' ||
                            msgreadunread.profileName == 'Startup'
                          "
                          src="{{
                            msgreadunread?.profilepic
                              ? msgreadunread?.profilepic
                              : 'assets/images/business_img_48.jpg'
                          }}"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            msgreadunread.profileName == 'Investor' ||
                            msgreadunread.profileName == 'Mentor'
                          "
                          src="{{
                            msgreadunread?.profilepic
                              ? msgreadunread?.profilepic
                              : 'assets/images/profile-dflt.gif'
                          }}"
                        />
                      </a>
                    </div>
                    <div class="bdy-sec">
                      <div class="profile-type">
                        <div class="pull-left width-alot">
                          {{ msgreadunread.profileName }}
                          <span
                            *ngIf="msgreadunread.location != ''"
                            class="pipe"
                            >|</span
                          >
                          <i
                            *ngIf="msgreadunread.location != ''"
                            class="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                          {{ msgreadunread.location }}
                        </div>
                        <div class="pull-right">
                          {{ msgreadunread.timestamp * 1000 | date }}
                        </div>
                        <div class="name">
                          <a [routerLink]="[msgreadunread.profilelink]"
                            >{{ msgreadunread.name }}
                          </a>
                        </div>
                        <div class="content">
                          <a
                            [routerLink]="['/dashboard/myinteraction']"
                            [queryParams]="{ rid: msgreadunread.request_id }"
                            >{{ msgreadunread.msg }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <a
                href="#"
                aria-expanded="true"
                aria-haspopup="true"
                class="dropdown-toggle"
                data-toggle="dropdown"
                (click)="updateViewedStatus()"
                [routerLink]=""
                role="button"
                ><i class="fa fa fa-pencil-square" aria-hidden="true"></i
              ></a>
              <div class="nofy" *ngIf="totalrequest > 0">
                <div class="nofyin">{{ totalrequest }}</div>
              </div>
              <div
                class="dropdown-menu notification-width bx-mail"
                *ngIf="totalrequest == 0"
              >
                <div class="notification-heading">
                  Business Proposals<a
                    [routerLink]="['/dashboard/bxproposal']"
                    class="pull-right"
                    >View all</a
                  >
                </div>
                <ul class="scrollarea" style="height: 350px; clear: both">
                  <li
                    style="background-color: #fff; padding: 15px; border: 0px"
                  >
                    No Proposals Found
                  </li>
                </ul>
              </div>

              <div
                class="dropdown-menu notification-width bxproposal"
                *ngIf="totalrequest > 0"
              >
                <div class="notification-heading">
                  Business Proposals<a
                    [routerLink]="['/dashboard/bxproposal']"
                    class="pull-right"
                    >View all</a
                  >
                </div>
                <ul class="scrollarea" style="height: 350px; clear: both">
                  <li
                    *ngFor="
                      let proposals of requestlist | slice: 0:5;
                      let i = index
                    "
                  >
                    <div class="img-sec">
                      <a style="padding: 0px" [routerLink]="[proposals.link]">
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic == '' &&
                            proposals.profileName == 'Business'
                          "
                          src="assets/images/business_img_48.jpg"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic != '' &&
                            proposals.profileName == 'Business'
                          "
                          src="{{ proposals.profilepic }}"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic == '' &&
                            proposals.profileName == 'Startup'
                          "
                          src="assets/images/business_img_48.jpg"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic != '' &&
                            proposals.profileName == 'Startup'
                          "
                          src="{{ proposals.profilepic }}"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic == '' &&
                            proposals.profileName == 'Investor'
                          "
                          src="assets/images/profile-dflt.gif"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic != '' &&
                            proposals.profileName == 'Investor'
                          "
                          src="{{ proposals.profilepic }}"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic == '' &&
                            proposals.profileName == 'Mentor'
                          "
                          src="assets/images/profile-dflt.gif"
                        />
                        <img
                          width=""
                          height=""
                          *ngIf="
                            proposals.profilepic != '' &&
                            proposals.profileName == 'Mentor'
                          "
                          src="{{ proposals.profilepic }}"
                        />
                      </a>
                    </div>
                    <div class="bdy-sec">
                      <div class="profile-type">
                        <div class="pull-left width-alot">
                          New {{ proposals.profileName }}
                          <span *ngIf="proposals.city != ''" class="pipe"
                            >|</span
                          >
                          <i
                            *ngIf="proposals.city != ''"
                            class="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                          {{ proposals.city }}
                          <span class="pipe">|</span>
                          {{ proposals.category }}
                        </div>
                        <div class="pull-right">
                          {{ proposals.timestamp * 1000 | date }}
                        </div>
                      </div>
                      <div class="name">
                        <a [routerLink]="[proposals.link]">{{
                          proposals.headline
                        }}</a>
                      </div>
                      <div class="business-req">
                        Business Req.: <span>{{ proposals.requirement }}</span>
                      </div>
                      <div class="business-req">
                        Message: <span>{{ proposals.msg }}</span>
                      </div>
                      <div class="action-btn keepopen">
                        <a
                          [routerLink]=""
                          class="slide-blue-btn keepopen"
                          (click)="handleRequest(proposals, 1)"
                          >ACCEPT</a
                        >
                        <a
                          [routerLink]=""
                          class="slide-blue-btn keepopen"
                          (click)="handleRequest(proposals, 2)"
                          >REJECT</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <a
                [routerLink]=""
                aria-expanded="true"
                aria-haspopup="true"
                class="dropdown-toggle"
                data-toggle="dropdown"
                [routerLink]=""
                role="button"
                ><i class="fa fa-bell" aria-hidden="true"></i
              ></a>

              <div
                class="nofy"
                *ngIf="lenNotification > 0 && updatedate !== 'Update'"
              >
                <div class="nofyin">{{ listingNotification.length }}</div>
              </div>

              <div
                *ngIf="lenNotification > 0"
                class="dropdown-menu notification-width bxproposal"
                style="height: 350px"
              >
                <div class="notification-heading">
                  Notifications
                  <a [routerLink]="['/dashboard/myaccount']" class="pull-right"
                    >View all</a
                  >
                </div>
                <ul class="scrollarea" style="height: 350px; clear: both">
                  <li *ngFor="let bxnotification of listingNotification">
                    <div
                      *ngIf="
                        bxnotification.image == null ||
                        bxnotification.image == ''
                      "
                      class="img-sec"
                    >
                      <img
                        width=""
                        height=""
                        src="assets/images/category/small/{{
                          bxnotification.parentCatId
                        }}.jpg"
                        alt="Featured Business"
                      />
                    </div>
                    <div
                      class="img-sec"
                      *ngIf="
                        bxnotification.image != null &&
                        bxnotification.image != ''
                      "
                    >
                      <img
                        width=""
                        height=""
                        src="{{ bxnotification.image }}"
                        alt="Featured Business"
                      />
                    </div>
                    <div class="bdy-sec">
                      <a
                        [routerLink]="[
                          '/business/' +
                            bxnotification.sellerurl +
                            '/' +
                            bxnotification.profileStr
                        ]"
                      >
                        <div class="profile-type">
                          <div class="pull-left width-alot">
                            New Business <span class="pipe">|</span>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ bxnotification.location }}
                            <span class="pipe">|</span
                            >{{ bxnotification.category }}
                          </div>
                          <div class="pull-right">
                            {{ bxnotification.updatedat }}
                          </div>
                        </div>
                        <div class="name">{{ bxnotification.title }}</div>
                        <div class="business-req">
                          Business Req.:
                          <span>{{ bxnotification.seekingStr }}</span>
                        </div>
                        <div class="business-req">
                          Investment Req.:
                          <span>{{ bxnotification.price }}</span>
                        </div>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <div
                *ngIf="listingNotification === 'null'"
                class="dropdown-menu notification-width bxproposal"
                style="height: 350px"
              >
                <div class="notification-heading">
                  Notifications<a
                    [routerLink]="['/dashboard/myaccount']"
                    class="pull-right"
                    >View all</a
                  >
                </div>
                <ul class="scrollarea" style="height: 350px; clear: both">
                  <li *ngFor="let bxnotification of sellerNewListing">
                    <div
                      *ngIf="
                        bxnotification.image == null ||
                        bxnotification.image == ''
                      "
                      class="img-sec"
                    >
                      <img
                        width=""
                        height=""
                        src="assets/images/category/small/{{
                          bxnotification.parentCatId
                        }}.jpg"
                        alt="Featured Business"
                      />
                    </div>
                    <div
                      class="img-sec"
                      *ngIf="
                        bxnotification.image != null &&
                        bxnotification.image != ''
                      "
                    >
                      <img
                        width=""
                        height=""
                        src="{{ bxnotification.image }}"
                        alt="Featured Business"
                      />
                    </div>
                    <div class="bdy-sec">
                      <a
                        [routerLink]="[
                          '/business/' +
                            bxnotification.sellerurl +
                            '/' +
                            bxnotification.profileStr
                        ]"
                      >
                        <div class="profile-type">
                          <div class="pull-left width-alot">
                            New Business <span class="pipe">|</span>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ bxnotification.location }}
                            <span class="pipe">|</span
                            >{{ bxnotification.industry }}
                          </div>
                          <div class="pull-right">
                            {{ bxnotification.updatedat }}
                          </div>
                        </div>
                        <div class="name">{{ bxnotification.title }}</div>
                        <div class="business-req">
                          Business Req.:
                          <span>{{ bxnotification.seekingStr }}</span>
                        </div>
                        <div class="business-req">
                          Investment Req.:
                          <span>{{ bxnotification.price }}</span>
                        </div>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div class="nav-item dropdown myacc">
            <a
              class="nav-link dropdown-toggle"
              onclick="openNav()"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <!-- <img src="./assets/img/profile-img.svg" alt="" class="profileimg"> -->

              <img
                *ngIf="
                  useraccount?.profile_pic == '' ||
                  useraccount?.profile_pic == null
                "
                src="assets/images/profile-dflt.gif"
                class="userpro"
              />

              <img
                *ngIf="
                  useraccount?.profile_pic != '' &&
                  useraccount?.profile_pic != null
                "
                src="{{ useraccount?.profile_pic }}"
                class="userpro"
              />

              <!-- <img src="./assets/img/chevron_right-24px.svg" alt="" class="userpro"> -->
            </a>

            <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                     <ul class="mylist">

                        <li><a class="dropdown-item" [routerLink]="['/dashboard/myaccount']">
                              <img src="./assets/img/my-account-icon.svg" alt="">My Account</a>
                        </li>

                        <li><span class="showe" *ngIf="useraccount?.name ===''"> <i class="fa fa-envelope-o"
                                 aria-hidden="true"></i> {{useraccount?.email}}</span>
                        </li>
                        <li class="myhidep"><a class="dropdown-item" href="#">
                              <img src="./assets/img/create-profile-icon.svg" alt="">Create Profile</a>
                           <div class="plist">
                              <div class="pinnlist"><a href="#">
                                    <img src="./assets/img/chevron_right-24px.svg" alt="">Business</a></div>
                              <div class="pinnlist"><img src="./assets/img/chevron_right-24px.svg" alt=""><a
                                    href="#">Startup</a></div>
                              <div class="pinnlist"><img src="./assets/img/chevron_right-24px.svg" alt=""><a
                                    href="#">Investor</a></div>
                              <div class="pinnlist"><img src="./assets/img/chevron_right-24px.svg" alt=""><a
                                    href="#">Mentor</a></div>

                           </div>
                        </li>
                        <li><a class="dropdown-item" [routerLink]="" (click)="isLogout()"><img
                                 src="./assets/img/logout-icon.svg" alt="logout"> Logout</a>
                        </li>

                     </ul>

                  </div> -->
          </div>
        </div>
      </div>

      <!-- <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button"
               class="btn btn-b-n navbar-toggle-box-collapse d-none d-md-block" data-toggle="collapse"
               data-target="#navbarTogglerDemo01" aria-expanded="false">
               <img src="./assets/img/search-24px.svg" alt="">
            </button>

            <button *ngIf="!authService.isAuthenticated()" type="button" class="btn btn-b-n  d-none d-md-block"
               data-toggle="modal" data-target="#login">
               <img src="./assets/img/account_circle-24px.svg" alt="">
            </button>

            <span class="btn btn-b-n  d-none d-md-block"
               *ngIf="authService.isAuthenticated()">{{LoginDeatail.email}}</span>
            <button *ngIf="authService.isAuthenticated()" type="button" (click)="isLogout()"
               class="btn btn-b-n  d-none d-md-block">
               Logout
            </button>

         </div> -->
    </div>
  </nav>
</div>
<!--nave-bar-header-end-->

<div id="mySidenav" class="sidenav" *ngIf="authService.isAuthenticated()">
  <div class="ft">
    <div class="mf1">
      <a [routerLink]="['/dashboard/myaccount']" onclick="closeNav()"
        >Dashboard</a
      >
      <a [routerLink]="" (click)="isLogout()" onclick="closeNav()">Logout</a>
    </div>
    <div class="mf2">
      <a [routerLink]="" class="closebtn" onclick="closeNav()">×</a>
    </div>
  </div>
  <div
    *ngIf="authService.isAuthenticated()"
    class="my-account-view"
    style="margin-top: 0"
  >
    <profile-detail></profile-detail>
  </div>
</div>
