<div class="headerplan sec-slide-effect">
    <div class="container">

        <div class="frmblk sec-slide-effect">
            <div class="row custom_error">
                <div class="col-xs-12">
                    <div class="msg-pnl_sales">
                        <div>
                            <div *ngFor="let message of submitMessage | keyspipe : true">
                                {{message}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1>Get Due Diligence Done Today!</h1>

            <form [formGroup]="businessValuation" (ngSubmit)="businessValuationSubmit()">

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Your Name" formControlName="name"
                  [ngClass]="{'invalid-border': submitted && bForm.name.errors}">
                </div>
        
                <div class="form-group">
                  <input type="email" class="form-control" placeholder="Your Email" formControlName="email"
                  [ngClass]="{'invalid-border': submitted && bForm.email.errors}">
                </div>
                
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Your Mobile" formControlName="mobile"
                  [ngClass]="{'invalid-border': submitted && bForm.mobile.errors}">
                </div>
        
                <div class="form-group">
                  <input type="text" class="form-control"  placeholder="Your Company" formControlName="company" [ngClass]="{'invalid-border': submitted && bForm.company.errors}">
                </div>
        
                <div class="form-group has-feedback">
                  <select formControlName="payment_mode" class="form-control rmv-bdr"  placeholder="Select Payment Mode" [ngClass]="{'invalid-border': submitted && bForm.payment_mode.errors}">
                      <option value="" selected="selected">Select Payment Mode</option>
                      <option value="OPTCRDC">Credit Card</option>
                      <option value="OPTDBCRD">Debit Card</option>
                      <option value="OPTNBK">Net Banking</option>
                      <option value="Paytm">Paytm</option>
                  </select>
              </div>
            
                <input type="submit" value="Submit" class="btnst">
        
              </form>

        </div>
        <div class="globaltxt">It's  <span>Never As Bad As They Say</span>,
            <br> It’s <span>Never As Good As They Say!</span>
            <br><br>
            Decide For Yourself. Do Your Due Diligence!
        </div>

    </div>


</div>

<div class="planabout sec-slide-effect">
    <div class="container">
    	<div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 abtblk">
            <img src="../assets/images/diligence-profile.jpg">
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 abt">
            <h2 class="abtplan">What is
                <span>Due Diligence?</span></h2>
            <p class="abttxt">Due diligence refers to an investigation of the business to confirm all facts, or an authentication of the information provided before signing a contract. This includes reviewing all financial records,
                plus anything else deemed material.</p>
            <p class="abttxt">Due diligence is the process of research and analysis that is performedbefore a potentialinvestment, acquisition, bank loan or business partnership, to enable the determination ofany major issues or potential issues in the subject of the due diligence. The prospective investor or acquirer must obtain all the required information to make sure that he makes a sensible investment rather than a costly mistake!</p>
            <p class="abttxt">
                Investors carry out due diligence before buying a security from a company. Due diligence can be performed for mergers and acquisitions, researching hedge funds and startup investments.</p>
        </div>
    </div>
</div>
</div>




<!---->



<!---->


<div class="bottomplan sec-slide-effect">
    <div class="container">

        <h2 class="abtplan cetre">What are the different types of Due Diligence?</h2>

        <div class="planback">


            <ul class="planli sec-slide-effect">
                 <li><strong>Financial Due Diligence:</strong><br>It includes a review and analysis of the Investees’ or seller’stax returns, financial statements,financial trends and accounting policies. It basically serves as the starting point for the due diligence process.
                </li>
                <li><strong>Business Due Diligence:</strong><br> It includes the review and analysisof strategic plans and business plans, markets and competition, and customers and products. It serves as a guidance in identifying any change that the industry is about to undergo and the target customer base of the seller, henceshowcasing risk involved in the process prior to closing the transaction.
                </li>
                <li><strong>Legal Due Diligence:</strong><br> It includes a thorough review and analysis of contracts and agreements and corporate documents; pending, ongoing and potential litigation; legal and regulatory compliance and environmental factors.</li>
                <li><strong>Tax due diligence:</strong><br> It includes managing the tax risk at the time when a company goes into a merger, acquires a business or disposes off a non-core business. It is important to focus on risksand opportunities (including quantifications) while providing social security, direct and indirect taxes due diligence, and corporate tax.</li>

                <li><strong>Human Resources Due Diligence:</strong><br> It includes looking at the employee benefits, management and personnel, organization’s structure, and labor matters.</li>
                <li><strong>Operations Due Diligence:</strong><br> It includes the review and analysis of the investees’ or seller’sfixed assets and facilities, technology, insurance coverage and real estate. This step also helps in looking at any significant operational risk that might affectexecuting the deal or pricing.</li>


            </ul>
        </div>
    </div>


</div>


<div class="dueplan sec-slide-effect">
    <div class="container">

        <div class="brglist">
            <div class="colpart">
                <h4>Why is Due Diligence Important?</h4>
                <p>Due Diligence helps you get a thorough understanding of what you are actually investing in or buying. The process helps answer the following questions for you:</p>
                <ul class="innerlistb">
                    <li>Am I aware of all legal cases associated with the company and are the legal costs manageable?</li>
                    <li>Is there any hidden employee or any other liabilities?</li>
                    <li>Are all the licenses associated with the company, in place? Is there any possible threat to the company due to government regulations?</li>
                    <li>Are there any liabilities of the HR like leave entitlements? Is the staff being paid correctly?</li>
                    <li>Is the business currently dependent on any particular staff member? Is the member deciding to stay on when the business changes hands?</li>
                    <li>Identifying cost saving mechanisms that will help you when you take over.</li>
                </ul>


            </div>
            <div class="colpart">
                <h4>How we can help</h4>

                <ul class="innerlistb">
                    <li>Our Teamand Partners conduct due diligences with the sole objective of generating valuablebusiness analyses and due diligence reports for our clients.</li>
                    <li>Our analyses and reports become an integral component of their negotiation and decision-making processes.</li>
                    <li>We offer a sound, confidential and unbiased perspective, becoming the ideal complement to client’s internal resources</li>
                    <li>We focus and aim to provide value-added services that enhancea client’s business decisions by combining a deep understanding of logistics, technologies,finance and corporate strategy, along with an ability to summarize issues that are complex into easily understood and concise terms</li>
                    <li>We conduct a full legal due diligence that results in an identification and assessment of the main risks associated with the business</li>
                    <li>We provide potential solutions that help in reducing the risk and give assistance in drafting the letter of confidentiality/intent along with Share Purchase Agreement (SPA) representations and warranties exclusivity agreements, exclusivity agreements, etc.</li>
                </ul>

            </div>
        </div>

    </div>
</div>






<div class="bottomtag">
    <div class="container">
        "Don’t dread the idea of submitting your business records to due diligence.
        Embrace the process!"
    </div>
</div>



<!--<section id="faqs" class="sec-slide-effect">-->
    <!--<header class="section-header  sec-slide-effect">-->
        <!--<h2 class="abtplan cetre">Frequently Asked Questions</h2>-->

    <!--</header>-->
    <!--<div class="container">-->
        <!--<div class="row about-container">-->

            <!--<button class="accordion">What is a Business Plan?</button>-->
            <!--<div class="panel">-->
                <!--<p>A Business Plan is a formally written document that includes a company's goals, the methods on how-->
                    <!--these goals must-->
                    <!--be reached, the progress made till date and the timelines within which these results will be-->
                    <!--achieved. </p>-->
            <!--</div>-->

            <!--<button class="accordion">How long will it take to prepare a Business Plan by BusinessEx?</button>-->
            <!--<div class="panel">-->
                <!--<p>It takes about 20 business days to prepare a Business Plan document from the date we receive all the-->
                    <!--relevant-->
                    <!--information, along with the requisite fees.</p>-->
            <!--</div>-->

            <!--<button class="accordion">How much does BusinessEx charge for their Business Plan service?</button>-->
            <!--<div class="panel">-->
                <!--<p>BusinessEx charges Rs 15,000 exclusive of taxes, to prepare a Business Plan document.</p>-->
            <!--</div>-->

            <!--<button class="accordion">What will happen when I make the payment at BusinessEx?</button>-->
            <!--<div class="panel">-->
                <!--<p>Once you make the payment, a representative from our Team will contact you within 2 working days to-->
                    <!--explain the next steps. We will then give you more information about the process and the information-->
                    <!--required by us to deliver the-->
                    <!--Business Plan.</p>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
<!--</section>-->
<div class="faqmapbg">
    <h2 class="abtplan cetre">Contact Us</h2>
    <div class="container bord">

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 mapblkl">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.637887213309!2d77.28094571440371!3d28.490449997249474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1f7c135a39d%3A0x2e7cf3031d63171a!2sBusinessEx%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1568023334445!5m2!1sen!2sin"
                        width="100%" height="312" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 mapblk2">
                <div class="bhh"><span><i class="fa fa-map-marker fa-lg" aria-hidden="true"></i></span>

                    <div class="bhhright"><strong>BusinessEx Solutions Private Limited,</strong><br>
                        Unit No. 601, 6th Floor Pinnacle Tower Behind The Atrium,<br>
                        Suraj Kund Rd,map-marker
                        Faridabad, Haryana 121009, India
                    </div>

                </div>

                <div class="bhh"><span><i class="fa fa-envelope-o fa-lg" aria-hidden="true"></i></span>

                    <div class="bhhright"><a href="#" onclick="return false;">support@businessex.com</a></div>
                </div>

                <div class="bhh"><span><i class="fa fa-phone fa-lg" aria-hidden="true"></i></span>
                    <div class="bhhright">+91 8929353325</div>
                </div>


            </div>
        </div>

    </div>
</div>
