
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE ATTACHMENT DETAILS
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/incubatorConfidentials/{{profile_str}}">Confidential
                                            Info</a></li>
                                        <li><a routerLink="/dashboard/incubatorAdvertisement/{{profile_str}}">Advertisement
                                            Details</a></li>
                                        <li><a routerLink="/dashboard/incubatorDetails/{{profile_str}}">Incubator
                                            Details</a></li>
                                        <li class="selected"><a
                                                routerLink="/dashboard/incubatorAttachment/{{profile_str}}">Attachments</a>
                                        </li>
                                        <li>
                                            <a routerLink="/dashboard/incubatorPreference/{{profile_str}}">Preferences </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="incubatorForm" #incubatorForm="ngForm"
                                              (ngSubmit)="incubatorForm.form.valid && incubatorMyAccountSubmit(incubatorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="incubatorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{incubatorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="incubatorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{incubatorProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">

                                                <div class="form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Profile
                                                        Picture<span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <img *ngIf="IncubatorAttachmentdata?.incubator_company_logo != '' && IncubatorAttachmentdata?.incubator_company_logo != null"
                                                             src="{{IncubatorAttachmentdata?.incubator_company_logo}}"
                                                             height="150" width="150">
                                                        <div class="input-group image-preview">
                                                            <input type="text"
                                                                   class="form-control image-preview-filename"
                                                                   placeholder="Accepted formats - png, jpeg, gif"
                                                                   disabled="disabled">
                                                            <input type="hidden" name="incubator_company_logo_old"
                                                                   [ngModel]="IncubatorAttachmentdata?.incubator_company_logo">
                                                            <span class="input-group-btn">
                                    <button type="button" class="btn btn-default rm-img image-preview-clear"
                                            style="display:none;">
                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                        </button>
                                                                <!-- image-preview-input -->
                                    <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                        <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                        <input type="file" accept="image/png, image/jpeg, image/gif"
                                               (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                        <!-- rename it -->
                                    </div>
                                </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company
                                                        Logo <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <img *ngIf="IncubatorAttachmentdata?.signature != '' && IncubatorAttachmentdata?.signature != null"
                                                             src="{{IncubatorAttachmentdata?.signature}}" height="150"
                                                             width="150">
                                                        <div class="input-group logo-preview">
                                                            <input type="text"
                                                                   class="form-control logo-preview-filename"
                                                                   placeholder="Accepted formats - png, jpeg, gif"
                                                                   disabled="disabled">
                                                            <input type="hidden" name="signature_old"
                                                                   [ngModel]="IncubatorAttachmentdata?.signature">
                                                            <span class="input-group-btn">
                                    <button type="button" class="btn btn-default rm-img logo-preview-clear"
                                            style="display:none; height:40px;">
                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                        </button>
                                                                <!-- image-preview-input -->
                                    <div class="btn btn-default btn-blue btn-file rm-img logo-preview-input">
                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                        <span class="logo-preview-input-title" style="margin-left: 5px;">Browse</span>
                                        <input type="file" id="cin" name="cin" file-model="nbfc_corporate_profile_path"
                                               (change)="logoChangeEvent($event)"
                                               accept="image/png, image/jpeg, image/gif"/> <!-- rename it -->
                                    </div>
                                </span>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->