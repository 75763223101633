import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'nonBrokerProfileTypeFilter'})
export class NonBrokerProfileTypeFilter implements PipeTransform {
    transform(profileObjects: any[]): any {

        return profileObjects.filter(profileObject => (profileObject.profile_type < 6 || profileObject.profile_type > 6));

    }
}
