import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG, NBFC_TYPE} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'nbfc-lender',
    templateUrl: './nbfc-lender.component.html'
})
export class NbfcLenderComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    profile_str;
    files;
    logos;
    public nbfcLenderdata;
    public lenderProfileSuccessError;
    public lenderProfileFailError;
    public nbfc_city;
    public nbfc_state;
    public nbfc_country;
    public bxLenderType;
    private isViewChecked = false;
    nbfc_types = NBFC_TYPE;

    getAddress(place: Object) {
        this.nbfc_city = place['address_components'][0].long_name;
        this.nbfc_state = place['address_components'][2].short_name;
        this.nbfc_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('NBFC Lender Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    lenderMyAccountSubmit(data) {
        if ($('form[name="lenderForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.lender_profile_str = this.profile_str;
        data.nbfc_city = this.nbfc_city;
        data.nbfc_state = this.nbfc_state;
        data.nbfc_country = this.nbfc_country;

        const formData: any = new FormData();
        if (this.files != undefined) {
            formData.append('lender_profile_pic_path', this.files[0], this.files[0].name);
            //console.log(this.files[0]);
        }
        if (this.logos != undefined) {
            formData.append('nbfc_corporate_profile_path', this.logos[0], this.logos[0].name);
            //console.log(this.logos[0]);
        }
        if (data.profile_pic_path_old != '') {
            formData.append('profile_pic_path_old', data.profile_pic_path_old);
            //console.log(data.profile_pic_path_old);
        }
        if (data.nbfc_corporate_profile_path_old != '') {
            formData.append('nbfc_corporate_profile_path_old', data.nbfc_corporate_profile_path_old);
            //console.log(data.nbfc_corporate_profile_path_old);
        }

        formData.append('lender_profile_str', this.profile_str);
        formData.append('nbfc_contact_name', data.nbfc_contact_name);
        formData.append('nbfc_contact_designation', data.nbfc_contact_designation);
        formData.append('nbfc_comp_name', data.nbfc_comp_name);
        formData.append('nbfc_type', data.nbfc_type);
        formData.append('nbfc_branch', data.nbfc_branch);
        formData.append('nbfc_city', this.nbfc_city);
        formData.append('nbfc_state', this.nbfc_state);
        formData.append('nbfc_country', this.nbfc_country);
        formData.append('nbfc_pincode', data.nbfc_pincode);
        formData.append('nbfc_website', data.nbfc_website);
        formData.append('nbfc_about', data.nbfc_about);
        formData.append('lending_capacity', data.lending_capacity);
        formData.append('prof_summary', data.prof_summary);
        formData.append('rbi_registered', data.rbi_registered);
        formData.append('rbi_registered_no', data.rbi_registered_no);

        this.http.post(this.config.apiEndpoint + '/updatelenderNBFC/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("NBFC Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.lenderProfileSuccessError = data;
                $("html, body").animate({scrollTop: 0}, "slow");
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.lenderProfileFailError = "Please Enter Correct Details";
            });
    }

    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.files = fileInput.target.files;
        }
    }

    logoChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.logos = fileInput.target.files;
        }
    }

    getnbfcLender(profile_str: any): void {
        this.myprofileService.getnbfcLender(this.profile_str)
            .then((result) => {
                this.nbfcLenderdata = result;
            });
    }

    getcheckBxLenderType(userID: any, profile_str: any): void {
        this.myprofileService.getcheckBxLenderType(this.userID, this.profile_str)
            .then((result) => {
                this.bxLenderType = result;
            });
    }

    ngOnInit() {
        this.getnbfcLender(this.profile_str);
        this.getcheckBxLenderType(this.userID, this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="lenderForm"]').validator();
        }
    }

}
