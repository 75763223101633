import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG, PROFESSIONAL_EXPERIENCE} from "../../../../config/app-config.constants";
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from "@angular/common";
import {ContactService} from "../../../../services/contact.service";
import {appConfig} from "../../../frontend/app.config";

declare var $: any;

@Component({
    selector: 'mentor-preference',
    templateUrl: './mentor-preference.component.html'
})
export class MentorPreferenceComponent implements OnInit, AfterViewChecked {

    profile_str;
    public MentorPreferencedata;
    public mentorProfileSuccessError;
    public mentorProfileFailError;
    private isViewChecked = false;
    public industryCategoryData;
    private mentorCategoryData;
    professional_exp_year = PROFESSIONAL_EXPERIENCE;

    constructor(meta: Meta, title: Title, private http: HttpClient,
                private myprofileService: MyProfileService, route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private contactService: ContactService) {
        title.setTitle('Mentor Preference - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    mentorMyAccountSubmit(data) {
        const formSelector = $('form[name="mentorForm"]');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        if ($('#mentor_subject_expertise').val().length === 0) {
            $('#mentor_subject_expertise').parent().css('border', '1px solid red');
            return;
        }
        $('#mentor_subject_expertise').parent().css('border', 'none');
        if ($('#mentor_sector_preference').val().length === 0) {
            $('#mentor_sector_preference').parent().css('border', '1px solid red');
            return;
        }
        $('#mentor_sector_preference').parent().css('border', 'none');
        data.mentor_subject_expertise = appConfig.getFormattedValues('#mentor_subject_expertise', this.mentorCategoryData);
        data.mentor_sector_preference = appConfig.getFormattedValues('#mentor_sector_preference', this.industryCategoryData);
        data.mentor_profile_str = this.profile_str;
        data.professional_experience = appConfig.prepareTeamJson('#parent0 select', 2);
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/updatementorPreference/' + this.profile_str, data).subscribe(
            data => {
                this.mentorProfileSuccessError = "Mentor Sector Preferences Updated Successfully";
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
            },
            error => {
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
                this.mentorProfileFailError = "Please Enter Correct Details";
            });
    }

    getmentorPreference(profile_str: any): void {
        this.myprofileService.getmentorPreference(this.profile_str)
            .then((result) => {
                this.MentorPreferencedata = result;
                if (isPlatformBrowser(this.platformId)) {
                    $('#mentor_subject_expertise').tagit({tagLimit: 5, allowSpaces: true});
                    this.MentorPreferencedata.mentor_subject_expertise.forEach(item => {
                        $('#mentor_subject_expertise').tagit("createTag", item.name);
                    });
                    $('#mentor_sector_preference').tagit({tagLimit: 5, allowSpaces: true});
                    this.MentorPreferencedata.mentor_sector_preference.forEach(item => {
                        $('#mentor_sector_preference').tagit("createTag", item.name);
                    });
                }
            });
    }

    ngOnInit() {
        this.getmentorPreference(this.profile_str);
        this.getMentorSector();
        this.getIndustrySector();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="mentorForm"]').validator();
        }
    }

    getMentorSector(): void {
        this.contactService.getMentorSector()
            .then((result) => {
                this.mentorCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.mentorCategoryData);
                    setTimeout(function () {
                        $('#mentor_subject_expertise').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }

    getIndustrySector(): void {
        this.contactService.getIndustrySector()
            .then((result) => {
                this.industryCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.industryCategoryData);
                    setTimeout(function () {
                        $('#mentor_sector_preference').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }

}
