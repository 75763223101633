// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiEndpoint: 'https://bxapi.businessex.com/bexapi',
  googleClientId: '770175068252-fm5j7e7j68tn8ch49s0rjopn5q4g4hpj.apps.googleusercontent.com',
  facebookClientId: '595176044018751',
  linkedinClientId: '81ztq4ujjp861k',
  chatServerPath: 'https://www.businessex.com:4000',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
