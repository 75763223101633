
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE ADVERTISEMENT INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/mentorConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li class="selected"><a routerLink="/dashboard/mentorAdvDetails/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/mentorDetails/{{profile_str}}">Profile Information</a></li>
                                        <li><a routerLink="/dashboard/mentorPreference/{{profile_str}}">Preferences </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="mentorForm" #mentorForm="ngForm"
                                              (ngSubmit)="mentorForm.form.valid && mentorMyAccountSubmit(mentorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="mentorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{mentorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="mentorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{mentorProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">
                                                <!-- <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Advertisement Details</div></div>
                                                </div>                         -->
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Advertisement
                                                        Headline<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group tl-tip">
                                                            <div class="input-group-addon grey-tooltip"
                                                                 data-toggle="tooltip" data-placement="top"
                                                                 title="Enter your text"><!-- <i class="fa fa-info"
                                                                                            aria-hidden="true"></i> -->
                                                            </div>
                                                            <input type="text" name="mentor_adv_headline"
                                                                   [ngModel]="mentorAdvDetailsdata?.mentor_adv_headline"
                                                                   class="form-control"
                                                                   placeholder="Enter Advertisement Headline"
                                                                   data-error="Please enter advertisement headline."
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Introduction<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group tl-tip">
                                                            <div class="input-group-addon grey-tooltip"
                                                                 data-toggle="tooltip" data-placement="top"
                                                                 title="Enter your text">


                                                                 <!-- <i class="fa fa-info"
                                                                                            aria-hidden="true"></i> -->
                                                            </div>
                                                            <input type="text" name="mentor_intro"
                                                                   [ngModel]="mentorAdvDetailsdata?.mentor_intro"
                                                                   class="form-control" placeholder="Enter Introduction"
                                                                   data-error="Please enter introduction." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
