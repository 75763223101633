
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 

                    <profile-detail></profile-detail>
                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="row">
                            <div class="col-md-12">

                                <div class="bdr">
                                    <div class="cat-list ">
                                        <div class="cat-sec-ttl">
                                            My Profile
                                            <!--<a href="#" class="dwn-response">Download Response</a>-->
                                        </div>
                                    </div>

                                    <div class="margin-20">
                                        <div class="row cat-detail">


                                            <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="startupProfileData">
                                                <!-- start breadcrumb -->
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                                        <ol class="breadcrumb">
                                                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                                                            <li class="breadcrumb-item"><a href="/startuplisting">Startup</a>
                                                            </li>
                                                            <li class="breadcrumb-item active">
                                                                {{startupProfileData?.title}}
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <!-- End breadcrumb -->

                                                <!-- Page Title -->
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12">

                                                        <div class="page-ttl"><h1>{{ (startupProfileData?.title !== '')
                                                            ? startupProfileData?.title : ("N/A") }}</h1></div>
                                                        <div class="shrt-desc tp-border">
                                                            {{ (startupProfileData?.description !== '') ?
                                                            startupProfileData?.description : ("N/A") }}
                                                        </div>
                                                        <div class="shrt-desc">
                            <span>
                                {{startupProfileData?.name_of_entity}}                            
                            </span>
                                                            <span *ngIf="startupProfileData?.city !== '' && startupProfileData?.state !== '' && startupProfileData?.country !== ''"></span>
                                                            <span class="pipe">|</span>
                                                            <span class="location"><i class="fa fa-map-marker"
                                                                                      aria-hidden="true"></i>
                                {{startupProfileData?.location}}
                            </span>
                                                        </div>

                                                        <div class="row">

                                                            <div class="col-xs-12 col-sm-12 col-md-12 scroll-ph sec-slide-effect">
                                                                <div class="business-detail">
                                                                    <div class="business-key-sec">
                                                                        <div class="business-key col-1">
                                                                            ASKING <span class="brk-word">PRICE</span>
                                                                            <span class="keynote"
                                                                                  *ngIf="startupProfileData?.askingPrice !== 0">
                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                {{ startupProfileData?.askingPrice }}
                                            </span>
                                                                            <span class="keynote"
                                                                                  *ngIf="startupProfileData?.askingPrice == 0">
                                                {{ "N/A" }}
                                            </span>
                                                                        </div>
                                                                        <div class="business-key col-2">
                                                                            ANNUAL
                                                                            <span class="brk-word">SALES/TURNOVER</span>
                                                                            <span class="keynote"
                                                                                  *ngIf="startupProfileData?.annualsale !== 0">
                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                {{ startupProfileData?.annualsale }}
                                            </span>
                                                                            <span class="keynote"
                                                                                  *ngIf="startupProfileData?.annualsale == 0">
                                                {{ "N/A" }}
                                            </span>
                                                                        </div>
                                                                        <div class="business-key col-1 rm-bdr">
                                                                            GROSS <span class="brk-word">INCOME</span>
                                                                            <span class="keynote"
                                                                                  *ngIf="startupProfileData?.grossprofit !== 0">
                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                {{ startupProfileData?.grossprofit | priceShort }}
                                            </span>
                                                                            <span class="keynote"
                                                                                  *ngIf="startupProfileData?.grossprofit == 0">
                                                {{ "N/A" }}
                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect">
                                                                            <div class="img-gallery">
                                                                                <div id="carousel-example-generic" class="carousel slide"
                                                                                     data-ride="carousel">
                                                                                    <!-- Indicators -->
                                                                                    <ol class="carousel-indicators">
                                                                                        <li data-target="#carousel-example-generic"
                                                                                            *ngFor="let image of startupImages;let i=index;"
                                                                                            [attr.data-slide-to]="i" [ngClass]="{'active': i==0}"></li>
                                                                                    </ol>
                                                                                    <!-- Wrapper for slides -->
                                                                                    <div class="carousel-inner" role="listbox">
                                                                                        <div *ngFor="let image of startupImages;let j=index;"
                                                                                             class="item" [ngClass]="{'active': j==0}">
                                                                                            <img [src]="image.imageUrl " alt="{{image.title}}">
                                                                                            <div class="carousel-caption">{{image.title}}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="primiumtag" *ngIf="startupProfileData?.membership_paid==1">

                                                                                    <img *ngIf="startupProfileData?.membership_plan==1" src="assets/images/premium.png">
                                                                                    <img *ngIf="startupProfileData?.membership_plan==2" src="assets/images/gold.png">
                                                                                    <img *ngIf="startupProfileData?.membership_plan==3" src="assets/images/platinum.png">

                                                                                    <span *ngIf="startupProfileData?.membership_plan==1">Premium</span>
                                                                                    <span style="color:#000;" *ngIf="startupProfileData?.membership_plan==2">Gold</span>
                                                                                    <span style="color:#000;" *ngIf="startupProfileData?.membership_plan==3">Platinum</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                                                            <div class="row">
                                                                                <div class="col-xs-12 col-sm-7 col-md-7 sec-slide-effect">
                                                                                    <div class="key-area">
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Website <span>:</span>
                                                                                            </div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.business_website
                                                                                                !== null) ?
                                                                                                startupProfileData?.business_website
                                                                                                : ("N/A") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Established From
                                                                                                <span>:</span></div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.estb_date
                                                                                                !== 0) ?
                                                                                                startupProfileData?.estb_date
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Employees <span>:</span>
                                                                                            </div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.emp_count
                                                                                                !== 0) ?
                                                                                                startupProfileData?.emp_count
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">Entity
                                                                                                type <span>:</span>
                                                                                            </div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.nature_of_entity
                                                                                                !== 0) ?
                                                                                                startupProfileData?.nature_of_entity
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Business Sector
                                                                                                <span>:</span></div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.subindustry
                                                                                                !== '') ?
                                                                                                startupProfileData?.subindustry
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-xs-12 col-sm-5 col-md-5 sec-slide-effect">
                                                                                    <div class="key-area key-sec">
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Business Type
                                                                                                <span>:</span></div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.business_type
                                                                                                !== 0) ?
                                                                                                startupProfileData?.business_type
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">EBITDA
                                                                                                <span>:</span></div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.ebitda
                                                                                                !== 0) ?
                                                                                                startupProfileData?.ebitda
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">EBITDA
                                                                                                Margin <span>:</span>
                                                                                            </div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.ebitdamargin
                                                                                                !== 0) ?
                                                                                                startupProfileData?.ebitda_margin
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Inventory value
                                                                                                <span>:</span></div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.inventory_value
                                                                                                !== null) ?
                                                                                                startupProfileData?.inventory_value
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="key-detail">
                                                                                            <div class="key-word">
                                                                                                Rentals <span>:</span>
                                                                                            </div>
                                                                                            <div class="key-val">{{
                                                                                                (startupProfileData?.rentals
                                                                                                !== null) ?
                                                                                                startupProfileData?.rentals
                                                                                                : ("Undisclosed") }}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                                                            <div class="bdr-tp"></div>
                                                                        </div>
                                                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                                                            <div class="business-pitch sec-slide-effect">
                                                                                One line Business Pitch
                                                                                <div>{{
                                                                                    (startupProfileData?.business_pitch
                                                                                    !== '') ?
                                                                                    startupProfileData?.business_pitch :
                                                                                    ("N/A") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xs-12 col-sm-4 col-md-4">
                                                                            <div class="bdr-left"></div>
                                                                            <div class="business-pitch sec-slide-effect">
                                                                                Company Stage
                                                                                <div>{{
                                                                                    (startupProfileData?.company_stage
                                                                                    !== '') ?
                                                                                    startupProfileData?.company_stage :
                                                                                    ("N/A") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="business-detail-sec sec-slide-effect" *ngIf="startupDocuments.length > 0">
                                                                    Business Documents
                                                                     <div class="download-documents">
                                                                        <ul>
                                                                            <li *ngFor="let document of startupDocuments;let j=index;">
                                                                                <div id="business-doc{{j}}" class="doc-name ellipsis"
                                                                                     onmouseover="scrollText(this.id);"
                                                                                     onmouseout="returnScroll(this.id);">{{appConfig.getDocumentName(document)}}
                                                                                </div>
                                                                                <div><a href="{{document}}"
                                                                                        target="_blank"><i class="fa fa-file-pdf-o"
                                                                                                           aria-hidden="true"></i> Download</a></div>
                                                                            </li>
                                                                        </ul>

                                                                    </div>
                                                                </div>

                                                                <div class="business-detail-sec sec-slide-effect">
                                                                    Business Requirement
                                                                    <!-- For Web Section start here -->
                                                                    <div class="tabulardata hide-ph"
                                                                         *ngIf="startupProfileData?.seeking_acquirers == 1 || startupProfileData?.seeking_investors == 1 || startupProfileData?.seeking_loan == 1">
                                                                        <div class="tbrow tabTitl">
                                                                            <div class="lkfor">Looking For</div>
                                                                            <div class="lkforamnt">Amount</div>
                                                                            <div class="lkforrjn">Reason</div>
                                                                        </div>
                                                                        <div class="tbrow"
                                                                             *ngIf="startupProfileData?.seeking_acquirers == 1">
                                                                            <div class="lkfor">Sale</div>
                                                                            <div class="lkforamnt">
                                                                                {{startupProfileData?.saleAmount}}
                                                                            </div>
                                                                            <div class="lkforrjn">
                                                                                {{startupProfileData?.buyer_sell_reason}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="tbrow"
                                                                             *ngIf="startupProfileData?.seeking_investors == 1">
                                                                            <div class="lkfor">Investment</div>
                                                                            <div class="lkforamnt"><span>{{startupProfileData?.investmentsize}} at {{startupProfileData?.inv_stake}} % stake</span>
                                                                            </div>
                                                                            <div class="lkforrjn"><span>{{startupProfileData?.inv_reason}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="tbrow"
                                                                             *ngIf="startupProfileData?.seeking_loan == 1">
                                                                            <div class="lkfor">Loans</div>
                                                                            <div class="lkforamnt"><span>{{startupProfileData?.loanAmount}} at {{startupProfileData?.loan_interest_rate}} % interest rate</span>
                                                                            </div>
                                                                            <div class="lkforrjn"><span>{{startupProfileData?.loan_reason}}</span>
                                                                            </div>
                                                                            <div class="othr-detail"
                                                                                 *ngIf="startupProfileData?.loan_collateral_details !== null">
                                                                                <span>Collateral Details: {{startupProfileData?.loan_collateral_details}}</span>
                                                                            </div>
                                                                            <div class="othr-detail"
                                                                                 *ngIf="startupProfileData?.loan_repayment_period !== null">
                                                                                <span>Collateral Details: {{startupProfileData?.loan_repayment_period}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="tabulardata hide-ph"
                                                                         *ngIf="startupProfileData?.seeking_mentorship == 1 || startupProfileData?.seeking_incubators == 1">
                                                                        <div class="tbrow tabTitl">
                                                                            <div class="lkfor">Looking For</div>
                                                                            <div class="lkforamnt">Field of Support
                                                                            </div>
                                                                            <div class="lkforrjn">Support Req.</div>
                                                                        </div>
                                                                        <div class="tbrow"
                                                                             *ngIf="startupProfileData?.seeking_mentorship == 1">
                                                                            <div class="lkfor">Mentorship</div>
                                                                            <div class="lkforamnt">
                                                <span class="comma">    
                                                    {{startupProfileData?.mentorCategory}}
                                                </span>
                                                                            </div>
                                                                            <div class="lkforrjn">
                                                                                {{startupProfileData?.mentor_req_details}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="tbrow"
                                                                             *ngIf="startupProfileData?.seeking_incubators == 1">
                                                                            <div class="lkfor">Incubator Support
                                                                                <div class="smhints">(for
                                                                                    {{startupProfileData?.accel_time_period}})
                                                                                </div>
                                                                            </div>
                                                                            <div class="lkforamnt">
                                                <span class="comma">    
                                                    {{startupProfileData?.incubatorCategory}}
                                                </span>
                                                                            </div>
                                                                            <div class="lkforrjn">
                                                                                {{startupProfileData?.accel_req_details}}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <!-- For Web Section end here -->

                                                                    <!-- For Ph Section start here -->
                                                                    <div class="ph-lkfr web-hide">
                                                                        <ul>
                                                                            <li *ngIf="startupProfileData?.seeking_acquirers == '1'">
                                                                                <div class="title">Looking For Sale
                                                                                </div>
                                                                                <div class="sec">
                                                                                    <div class="lst">
                                                                                        Amount
                                                                                        <div>
                                                                                            {{startupProfileData?.saleAmount}}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="lst">
                                                                                        Reason
                                                                                        <div>
                                                                                            {{startupProfileData?.buyer_sell_reason}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li *ngIf="startupProfileData?.seeking_investors == 1">
                                                                                <div class="title">Looking For
                                                                                    Investment
                                                                                </div>
                                                                                <div class="sec">
                                                                                    <div class="lst">
                                                                                        Amount
                                                                                        <div><span>{{startupProfileData?.investmentsize}} at {{startupProfileData?.inv_stake}} % stake</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="lst">
                                                                                        Reason
                                                                                        <div><span>{{startupProfileData?.inv_reason}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li *ngIf="startupProfileData?.seeking_loan != 0">
                                                                                <div class="title">Looking For Loan
                                                                                </div>
                                                                                <div class="sec">
                                                                                    <div class="lst">
                                                                                        Amount
                                                                                        <div><span>{{startupProfileData?.loanAmount}} at {{startupProfileData?.loan_interest_rate}} % interest rate</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="lst">
                                                                                        Reason
                                                                                        <div><span>{{startupProfileData?.loan_reason}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="othr-detail">Collateral
                                                                                        Details:
                                                                                        {{startupProfileData?.loan_collateral_details}}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li *ngIf="startupProfileData?.seeking_mentorship == 1">
                                                                                <div class="title">Looking For
                                                                                    Mentorship
                                                                                </div>
                                                                                <div class="sec">
                                                                                    <div class="lst">
                                                                                        Field of Support
                                                                                        <div>
                                                            <span class="comma">    
                                                                {{startupProfileData?.mentorCategory}}
                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="lst">
                                                                                        Support Req.
                                                                                        <div>
                                                                                            {{startupProfileData?.mentor_req_details}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li *ngIf="startupProfileData?.seeking_incubators == 1">
                                                                                <div class="title">
                                                                                    Looking For Incubator Support
                                                                                    <div class="sub-ttl">(for
                                                                                        {{startupProfileData?.accel_time_period}})
                                                                                    </div>
                                                                                </div>
                                                                                <div class="sec">
                                                                                    <div class="lst">
                                                                                        Field of Support
                                                                                        <div>
                                                            <span class="comma">    
                                                                {{startupProfileData?.incubatorCategory}}
                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="lst">
                                                                                        Support Req.
                                                                                        <div>
                                                                                            {{startupProfileData?.accel_req_details}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <!-- For Ph Section end here -->

                                                                </div>
                                                                <div class="business-detail-sec sec-slide-effect">
                                                                    Director / CEO information
                                                                    <div class="com-member-list">
                                                                        <ul>
                                                                            <li>
                                                                                {{ (startupProfileData?.director_name
                                                                                !== '') ?
                                                                                startupProfileData?.director_name :
                                                                                ("N/A") }},
                                                                                {{
                                                                                (startupProfileData?.director_designation
                                                                                !== '') ?
                                                                                startupProfileData?.director_designation
                                                                                : ("N/A") }}
                                                                                <span class="pipe">|</span>
                                                                                {{ (startupProfileData?.director_email
                                                                                !== '') ?
                                                                                startupProfileData?.director_email :
                                                                                ("N/A") }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="business-detail-sec sec-slide-effect">
                                                                    Management Team information
                                                                    <div class="locked-ins">
                                                                        <i class="fa fa-lock" aria-hidden="true"></i>
                                                                        {{ (sellerMgtTeamDetails.length != 0) ? sellerMgtTeamDetails.length : ("N/A") }}
                                                                    </div>
                                                                </div>                                     -->
                                                                <div class="business-detail-sec sec-slide-effect"
                                                                     *ngIf="startupProfileData?.company_summary !== null">
                                                                    Business Overview
                                                                    <div class="overview">
                                                                        {{startupProfileData?.company_summary}}
                                                                    </div>
                                                                </div>
                                                                <div class="business-detail-sec sec-slide-effect"
                                                                     *ngIf="startupProfileData?.facilities_desc !== null">
                                                                    Facilities Overview
                                                                    <div class="overview" [innerHtml]="startupProfileData?.facilities_desc">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
     
      