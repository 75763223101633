import {ConfigService} from 'ng2-ui-auth';
import {Injectable} from '@angular/core';

@Injectable()
export class UiAuthConfigBackendService extends ConfigService {
    getHttpHost(path = '') {
        return 'https://www.businessex.com';
    }

    isCordovaApp() {
        return false;
    }
}
