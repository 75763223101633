import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, ActivatedRoute } from '@angular/router';
//import { FilterByPipe } from 'projects/frontend/src/app/pipes/filterby.pipe';
//import { SortPipe } from 'projects/frontend/src/app/pipes/sortby';
//import { UniquePipe } from 'projects/frontend/src/app/pipes/unique.pipe';
//import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { BehaviorSubject } from 'rxjs';
import { combineAll } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';


declare var $: any;
declare var jQuery: any;
declare var fakewaffle: any;


@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  //providers: [SortPipe, FilterByPipe, UniquePipe]
})
export class AppComponent implements OnInit {
  title = 'Businessex';
  loadingRouteConfig: boolean =true;
  showHeader = true;

  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    //private homeService: HomeService,
    //private SortPipe: SortPipe,
    //private FilterByPipe: FilterByPipe,
    //private UniquePipe: UniquePipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    //private titleMeta: Title
  ) {

    AppComponent.isBrowser.next(isPlatformBrowser(platformId));


  }

  ngOnInit(): void {

    this.router.events.subscribe((myEvent) => {

      if (myEvent instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {

        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        window.scrollTo(0, 0);


        
        /*if ($(window).width() > 1480) {
            $('.container').css('width', 1200);
            $('.big-screen').css({
                'width': 'auto',
                'max-width': 'auto',
                'margin-right': '15px',
                'margin-left': '15px'
            });
        } else {
            $('.container').css({'width': $(window).width(), 'max-width': 1200});
        }
        $('.w-height').css('display', 'none').fadeIn(1000);
        $('.footer, .footer-sub').css('display', 'block');
        $('#hmurl, #hmurl1, #hmurlval, #hmurlph, #hmurlph1').hide();
        $('#webUrl, #webUrl1, #weburlval, #webUrlph, #webUrlph1').show();
        $('#foo-industry').find('.active').removeClass('active');
        $('#bIndustry_active').addClass('active');
        $('#bIndustry').addClass('active');
        if (myEvent.url.split('?')[0] === '/dashboard/myinteractiion') {
            $('.footer, .footer-sub').css('display', 'none');
        }
        if (myEvent.url === '/') {
            $('#hmurl, #hmurl1, #hmurlval, #hmurlph, #hmurlph1').show();
            $('#webUrl, #webUrl1, #weburlval, #webUrlph, #webUrlph1').hide();
        }
        const footerTab = myEvent.url.split('/')[1];
        if (footerTab === 'startupslisting' || footerTab === 'startup') {
            $('#foo-industry').find('.active').removeClass('active');
            $('#sIndustry_active').addClass('active');z
            $('#sIndustry').addClass('active');
        }
        if (footerTab === 'investorlisting' || footerTab === 'investor') {
            $('#foo-industry').find('.active').removeClass('active');
            $('#iIndustry_active').addClass('active');
            $('#iIndustry').addClass('active');
        }*/


      }

      /*if (this.showHeader) {
        this.homeService.searchFooterBusinessSeller()
          .subscribe(
            result => {
              this.homeService.replymySubjectBusinessFooter.next(result.industrySeller);
            })

        this.homeService.startupIndustrySeller()
          .subscribe(
            result => {
              this.homeService.replymySubjectStartupFooter.next(result.industrySeller);
            })

        this.homeService.searchFooterInvestorSeller()
          .subscribe(
            result => {
              this.homeService.replymySubjectInvestorFooter.next(result.industryPrefInvestor);
            })
      }*/

      if (myEvent instanceof RouteConfigLoadStart) {
        //this.loadingRouteConfig = true;
      } else if (myEvent instanceof RouteConfigLoadEnd) {
        //this.loadingRouteConfig = false;
      }

    });
      /***************** Tab Responsive ********************/
      if (isPlatformBrowser(this.platformId)) {

      }

  }




  ngAfterViewInit() {
  
    this.loadingRouteConfig = false;
  }


public urlCheck:any=[0,1];

  ngAfterViewChecked(){
    this.router.events.subscribe((url:any) => { 
      if(url.url !=undefined){

        if(this.urlCheck != url.url.split("/")[1]){
          //console.log('Irshad');
          this.urlCheck = url.url.split("/")[1];


          this.meta.removeTag("property='og:type'");
          this.meta.removeTag("property='og:url'");
          this.meta.removeTag("property='og:title'");
          this.meta.removeTag("property='og:description'");
          this.meta.removeTag("property='og:image'");
          this.meta.removeTag("property='og:image:height'");
          this.meta.removeTag("property='fb:admins'");
          this.meta.removeTag("property='fb:page_id'");
          this.meta.removeTag("property='og:locale'");
          this.meta.removeTag("property='og:country-name'");
          this.meta.removeTag("property='og:site_name'");
          this.meta.removeTag("name='p:domain_verify'");
          this.meta.removeTag("name='twitter:card'");
          this.meta.removeTag("name='twitter:site'");
          this.meta.removeTag("name='twitter:title'");
          this.meta.removeTag("name='twitter:description'");
          this.meta.removeTag("name='contact_addr'");
          this.meta.removeTag("name='language'");
          this.meta.removeTag("name='owner'");
          this.meta.removeTag("name='distribution'");
          this.meta.removeTag("name='city'");
          this.meta.removeTag("name='country'");
          this.meta.removeTag("name='state'");
          this.meta.removeTag("name='zipcode'");
          this.meta.removeTag("name='geo.position'");
          this.meta.removeTag("name='geo.placename'");
          this.meta.removeTag("name='geo.region'");
          this.meta.removeTag("name='rating'");
          this.meta.removeTag("name='DC.Publisher'");
        }
      }
    })
  }



}
