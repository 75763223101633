<div class="ftrbg">
  <div class="container">
    <div class="title-box">
      <h2 class="bex-title-a">Our Group Companies</h2>
      <!--  <h5>Put Some Base Line Here</h5> -->
    </div>
    <ul class="weblist">
      <li>
        <a href="https://www.franchiseindia.com/" target="_blank"
          ><img src="assets/img/group/franchiseindia.jpg"
        /></a>
      </li>
      <li>
        <a href="https://dealer.franchiseindia.com/" target="_blank"
          ><img src="assets/img/group/diindia.jpg"
        /></a>
      </li>
      <li>
        <a href="https://www.indianretailer.com/" target="_blank"
          ><img src="assets/img/group/indianretailer.jpg"
        /></a>
      </li>

      <li>
        <a href="https://www.restaurantindia.in/" target="_blank"
          ><img src="assets/img/group/restaurantindia.jpg"
        /></a>
      </li>

      <li>
        <a href="https://www.francorp.in/" target="_blank"
          ><img src="assets/img/group/fancorp.jpg"
        /></a>
      </li>

      <li>
        <a href="https://www.franglobal.com/" target="_blank"
          ><img src="assets/img/group/fanglobal.jpg"
        /></a>
      </li>

      <li>
        <a href="https://www.entrepreneur.com/" target="_blank"
          ><img src="assets/img/group/entrepreneure-india.jpg"
        /></a>
      </li>
      <li>
        <a href="https://www.licenseindia.com/" target="_blank"
          ><img src="assets/img/group/license-india.jpg"
        /></a>
      </li>
      <li>
        <a href="https://www.msme.in/" target="_blank"
          ><img src="assets/img/group/isfa1.jpg"
        /></a>
      </li>
    </ul>
  </div>
</div>

<section id="getindustryfirstinsights" class="getindustryfirstinsights ptb">
  <div class="getindustry-inner">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h2>Get Industry First Insights</h2>
          <p>Sign up for our exclusive Newsletter</p>
          <!--  <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</span> -->
        </div>

        <div class="col-md-6">
          <form
            class="row"
            name="subscribeForm"
            [formGroup]="subscribeForm"
            (ngSubmit)="subscribeSubmit()"
          >
            <div class="">
              <ul class="frmlist">
                <li>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    [ngClass]="{
                      'invalid-border': submittedSubscribe && sform.name.errors
                    }"
                    placeholder="Name"
                  />
                </li>
                <li>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    [ngClass]="{
                      'invalid-border': submittedSubscribe && sform.email.errors
                    }"
                    placeholder="Email"
                  />
                </li>
                <li>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="contact"
                    [ngClass]="{
                      'invalid-border':
                        submittedSubscribe && sform.contact.errors
                    }"
                    placeholder="Contact No."
                  />
                </li>
                <li>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="city"
                    [ngClass]="{
                      'invalid-border': submittedSubscribe && sform.city.errors
                    }"
                    placeholder="City"
                  />
                </li>
              </ul>
              <input type="submit" value="Subscribe Now" class="btn-sub" />

              <span class="subscribe-message" style="margin-left: 20px">
                {{ dataResponse }}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="emailbg-inner">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <ul class="socmedia">
          <li class="folltxt">Follow <span>BusinessEx</span></li>
          <li>
            <a
              href="https://www.facebook.com/BusinessEx.co.in/"
              target="_blank"
              class="facebook"
              ><img src="./assets/img/facebook-s2.svg" alt=""
            /></a>
          </li>
          <li>
            <a
              href="https://twitter.com/BusinessExIndia"
              class="twitter"
              target="_blank"
              ><img src="./assets/img/twitter-s2.svg" alt=""
            /></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/business.ex/"
              class="instagram"
              target="_blank"
              ><img src="./assets/img/instagram-s2.svg" alt=""
            /></a>
          </li>
          <li class="linkedin">
            <a
              href="https://www.linkedin.com/company/businessex.com/"
              target="_blank"
              ><img src="./assets/img/linkedinb.svg" alt=""
            /></a>
          </li>

          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLab5dwL9lf4JN4QGCGivjjEkHfHMxGF6v"
              class="youtube"
              target="_blank"
              ><img src="./assets/img/youtube-s2.svg" alt=""
            /></a>
          </li>
        </ul>
      </div>

      <div class="col-md-6 trst">Stay tuned & get updated</div>
    </div>
  </div>
</div>

<!--- sublinks ----->
<section class="shosection">
  <div class="container">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#Business"
          >Business</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#Startup">Startup</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#Investor">Investor</a>
      </li>
    </ul>

    <!-- Tab strat her  -->
    <div class="tab-content">
      <div class="tab-pane container active" id="Business">
        <ul class="mainul">
          <li
            *ngFor="
              let catindustry of industrySeller
                | sortBy: 'industry'
                | unique: 'industry';
              let i = index
            "
          >
            <a
              [routerLink]="[
                'businesslisting/' +
                  catindustry.industrySlug +
                  '-businesses-for-sale/' +
                  catindustry.industryid +
                  '/s0/st0'
              ]"
              (click)="onclickFooterPage()"
              title="{{ catindustry.industry }}"
            >
              {{ catindustry.industry | slice: 0:30
              }}{{ catindustry.industry.length > 30 ? "..." : "" }}
            </a>

            <ul class="submainul" attr.id="industry-{{ i + 1 }}">
              <li
                *ngFor="
                  let subCategory of industrySeller
                    | sortBy: 'subindustry'
                    | filterBy: ['industry']:catindustry.industry
                    | slice: 0:4
                    | unique: 'subindustry';
                  let j = index
                "
              >
                <a
                  [routerLink]="[
                    'businesslisting/' +
                      subCategory.subIndustrySlug +
                      '-businesses-for-sale/' +
                      catindustry.industryid +
                      '/' +
                      subCategory.subIndustryid +
                      '/st0'
                  ]"
                  (click)="onclickFooterPage()"
                >
                  {{ subCategory.subindustry }}
                </a>
              </li>
            </ul>
            <div class="viewmore">
              <a
                [routerLink]="[
                  'businesslisting/' +
                    catindustry.industrySlug +
                    '-businesses-for-sale/' +
                    catindustry.industryid +
                    '/s0/st0'
                ]"
                class="ss"
                (click)="onclickFooterPage()"
                >View all
                <i class="fa fa-angle-double-right" aria-hidden="true"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>

      <div class="tab-pane container" id="Startup">
        <ul class="mainul">
          <li
            *ngFor="
              let catindustry of industryStarttup
                | sortBy: 'industry'
                | unique: 'industry';
              let i = index
            "
          >
            <a
              [routerLink]="[
                'startupslisting/' +
                  catindustry.industrySlug +
                  '-businesses-for-sale/' +
                  catindustry.industryid +
                  '/s0/st0'
              ]"
              (click)="onclickFooterPage()"
              title="{{ catindustry.industry }}"
            >
              {{ catindustry.industry | slice: 0:30
              }}{{ catindustry.industry.length > 30 ? "..." : "" }}
            </a>

            <ul class="submainul" attr.id="industry-{{ i + 1 }}">
              <li
                *ngFor="
                  let subCategory of industryStarttup
                    | sortBy: 'subindustry'
                    | filterBy: ['industry']:catindustry.industry
                    | slice: 0:4
                    | unique: 'subindustry';
                  let j = index
                "
              >
                <a
                  [routerLink]="[
                    'startupslisting/' +
                      subCategory.subIndustrySlug +
                      '-businesses-for-sale/' +
                      catindustry.industryid +
                      '/' +
                      subCategory.subIndustryid +
                      '/st0'
                  ]"
                  (click)="onclickFooterPage()"
                >
                  {{ subCategory.subindustry }}
                </a>
              </li>
            </ul>
            <div class="viewmore">
              <a
                [routerLink]="[
                  'startupslisting/' +
                    catindustry.industrySlug +
                    '-businesses-for-sale/' +
                    catindustry.industryid +
                    '/s0/st0'
                ]"
                class="ss"
                (click)="onclickFooterPage()"
                >View all
                <i class="fa fa-angle-double-right" aria-hidden="true"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>

      <div class="tab-pane container" id="Investor">
        <ul class="mainul">
          <li
            *ngFor="
              let catindustry of industryInvestor
                | sortBy: 'industry'
                | unique: 'industry';
              let i = index
            "
          >
            <a
              [routerLink]="[
                'investorlisting/' +
                  catindustry.industrySlug +
                  '-businesses-for-sale/' +
                  catindustry.industryid +
                  '/s0/st0'
              ]"
              (click)="onclickFooterPage()"
              title="{{ catindustry.industry }}"
            >
              {{ catindustry.industry | slice: 0:30
              }}{{ catindustry.industry.length > 30 ? "..." : "" }}
            </a>

            <ul class="submainul" attr.id="industry-{{ i + 1 }}">
              <li
                *ngFor="
                  let subCategory of industryInvestor
                    | sortBy: 'subindustry'
                    | filterBy: ['industry']:catindustry.industry
                    | slice: 0:4
                    | unique: 'subindustry';
                  let j = index
                "
              >
                <a
                  [routerLink]="[
                    'investorlisting/' +
                      subCategory.subIndustrySlug +
                      '-businesses-for-sale/' +
                      catindustry.industryid +
                      '/' +
                      subCategory.subIndustryid +
                      '/st0'
                  ]"
                  (click)="onclickFooterPage()"
                >
                  {{ subCategory.subindustry }}
                </a>
              </li>
            </ul>
            <div class="viewmore">
              <a
                [routerLink]="[
                  'investorlisting/' +
                    catindustry.industrySlug +
                    '-businesses-for-sale/' +
                    catindustry.industryid +
                    '/s0/st0'
                ]"
                class="ss"
                (click)="onclickFooterPage()"
                >View all
                <i class="fa fa-angle-double-right" aria-hidden="true"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- End #main -->
<!-- ======= Footer ======= -->
<footer>
  <div class="container">
    <div class="setct">
      <div class="row">
        <div class="col-md-6 copyright">
          Copyright © 2021 to 2022 BusinessEx
        </div>
        <div class="col-md-6">
          <ul class="bex-footer-bottom-links">
            <li>
              <a [routerLink]="['/']" (click)="onclickFooterPage()">Home</a>
            </li>
            <li>
              <a [routerLink]="['/aboutus']" (click)="onclickFooterPage()"
                >About Us</a
              >
            </li>
            <li>
              <a [routerLink]="['/disclaimer']" (click)="onclickFooterPage()"
                >Disclaimer</a
              >
            </li>
            <li>
              <a [routerLink]="['/privacy']" (click)="onclickFooterPage()"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a [routerLink]="['/terms']" (click)="onclickFooterPage()"
                >Terms</a
              >
            </li>
            <li>
              <a [routerLink]="['/contact-us']" (click)="onclickFooterPage()"
                >Contact</a
              >
            </li>
            <li>
              <a [routerLink]="['/sitemap']" (click)="onclickFooterPage()"
                >Sitemap</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- view the contact information start here -->
<div
  class="modal fade lg-panel"
  id="edit-info"
  role="dialog"
  aria-labelledby="exampleModalLabel"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form
        #contactForm="ngForm"
        (ngSubmit)="saveUserAccountDetail(contactForm.value)"
        class="form-block"
        name="contactForm"
      >
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Edit Information</h4>
        </div>
        <div class="modal-body">
          <div
            class="msg-pnl_sales"
            *ngIf="userProfileSuccessError"
            style="margin-top: 10px; text-align: center"
          >
            {{ userProfileSuccessError }}
          </div>
          <div
            class="msg-pnl_sales"
            *ngIf="userProfileFailError"
            style="margin-top: 10px; text-align: center"
          >
            {{ userProfileFailError }}
          </div>

          <div class="edit-info">
            <div class="form-group has-feedback">
              <label class="col-xs-12 col-sm-6 col-md-5 control-label"
                >Name<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    name="name"
                    #name="ngModel"
                    value="{{ useraccount?.name }}"
                    ngModel
                    ng-pattern="/^[a-zA-Z\s]*$/"
                    class="form-control"
                    id="inputEmail3"
                    placeholder="Enter Full Name"
                    data-error="Please enter name."
                    required
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="form-group has-feedback">
              <label class="col-xs-12 col-sm-6 col-md-5 control-label"
                >Email<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="email"
                    [readonly]="true"
                    name="email"
                    #email="ngModel"
                    value="{{ useraccount?.email }}"
                    ngModel
                    class="form-control"
                    id="inputPassword31"
                    placeholder="Enter Email"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </div>

            <div
              class="form-group has-feedback"
              *ngIf="useraccount?.mobile != 0"
            >
              <label class="col-xs-12 col-sm-6 col-md-5 control-label"
                >Mobile<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    [readonly]="true"
                    name="mobile"
                    #mobile="ngModel"
                    value="{{ useraccount?.mobile }}"
                    ngModel
                    class="form-control"
                    id="inputPassword32"
                    placeholder="Enter Phone"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </div>

            <div
              class="form-group has-feedback"
              *ngIf="useraccount?.user_location != ''"
            >
              <label class="col-xs-12 col-sm-6 col-md-5 control-label"
                >Location<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    name="user_location"
                    #user_location="ngModel"
                    value="{{ useraccount?.user_location }}"
                    ngModel
                    class="form-control"
                    placeholder="Enter Location"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </div>

            <div class="form-group has-feedback">
              <label class="col-xs-12 col-sm-6 col-md-5 control-label"
                >Designation<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    name="designation"
                    #designation="ngModel"
                    value="{{ useraccount?.designation }}"
                    ngModel
                    class="form-control"
                    id="inputPassword33"
                    placeholder="Enter Designation"
                    data-error="Please enter designation."
                    required
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="form-group has-feedback">
              <label class="col-xs-12 col-sm-6 col-md-5 control-label"
                >Company<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    name="company_name"
                    #company_name="ngModel"
                    ngModel
                    value="{{ useraccount?.company_name }}"
                    class="form-control"
                    id="inputPassword34"
                    placeholder="Enter  Company"
                    data-error="Please enter company."
                    required
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- view the contact information end here -->

<!-- <div class="footrtwhatsapp-icon" *ngIf="checkBrowser">
  <div class="">
    <div class="footerknow-btn">
      <a
        class="whatsapp-plain-round-btn"
        href="https://api.whatsapp.com/send?phone=918860645454&amp;text=Hello,%20I%20am%20interested%20in%20knowing%20more%20about ➞%20https://www.businessex.com/"
        target="_blank"
      >
        <img
          src="https://www.franchiseindia.com/newhomepage/assets/img/whatappsicon.svg"
          class="imgwaaps"
          alt="whatappsicon"
      /></a>
    </div>
  </div>
</div> -->

<!-- Events popups start -->

<!-- <div class="modal" id="eventlogin" tabindex="-1" role="dialog" aria-labelledby="loginLabel" aria-hidden="true" *ngIf="!isCookieCheckedForEvent"> -->

<div
  class="modal"
  id="eventlogin"
  tabindex="-1"
  role="dialog"
  aria-labelledby="loginLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <button
        type="button"
        class="close close-event-warning"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <div class="modal-body">
        <div class="poptopblk"></div>
        <div class="popbottomblk">
          <form
            ngNoForm
            method="get"
            id="eretailRegfrm"
            name="eretailRegfrm"
            action="https://us06web.zoom.us/webinar/register/2716265232705/WN_I2W60r3AQVmHugVpt8BeYw"
            class="pre-visit"
          >
            <!-- <a href="https://campaign.businessex.com/bexangels/bss/" target="_blank"> <img src="./assets/img/events/EXIT_SHOW_Logo.png" alt="" /></a> -->

            <!-- <input type="hidden" value="Startup-Award-Insta-Registration" id="ref" name="ref">
                  <input type="hidden" name="src" id="src" value="Bex Popup">
                  <input type="hidden" value="India Mentors &amp; Business Coaching" name="hidType" id="hidType">
                  <input type="hidden" value="5 May 2021" name="event_year" id="event_year">
                  <input type="hidden" value="Visitor Registration" name="want" id="want"> -->

            <!-- <ul class="evefrm">
                     <li><input type="text" placeholder="Name *" id="txtName" name="txtName" class="form-control"
                           required=""></li>
                     <li><input type="email" placeholder="Email ID *" id="txtEmail" name="txtEmail" class="form-control"
                           required=""></li>
                     <li><input type="text" placeholder="Contact number *" id="txtMobile" name="txtMobile"
                           maxlength="10" class="form-control" required=""></li>
                     <li><input type="text" placeholder="Organization Name *" id="txtCompany" name="txtCompany"
                           class="form-control" required=""></li>
                     <li><input type="text" class="form-control" maxlength="150" value="" id="txtDesig" name="txtDesig"
                           placeholder="Designation *" required=""></li>
                     <li><input type="text" class="form-control" placeholder="City *" id="txtCity" name="txtCity"
                           required=""></li>
                  </ul> -->
            <div class="regblk">
              <input type="submit" class="subty" value="Register Now" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Events popup end -->
