
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE COMPANY INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li class="selected"><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                                        <li><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                                        <li><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                                        <li><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                                        <li><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                                        <li><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="startupForm" id="startupForm" #startupForm="ngForm"
                                              (ngSubmit)="startupForm.form.valid && startupMyAccountSubmit(startupForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">Company Information</div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Name of
                                                        Entity<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   name="name_of_entity"
                                                                   [ngModel]="StartupCompanyDetailsdata?.name_of_entity"
                                                                   placeholder="Enter Company Name"
                                                                   data-error="Please enter name of entity." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business
                                                        Type<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="business_type"
                                                                    [ngModel]="StartupCompanyDetailsdata?.business_type"
                                                                    data-error="Please select business type." required>
                                                                <option *ngFor="let BusinessType of businesstype"
                                                                        [value]="BusinessType.id"
                                                                        [selected]="BusinessType.id == 'StartupCompanyDetailsdata.business_type'">
                                                                    {{BusinessType.name}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Nature Of
                                                        Entity<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="nature_of_entity"
                                                                    [ngModel]="StartupCompanyDetailsdata?.nature_of_entity"
                                                                    data-error="Please select entity type." required>
                                                                <option *ngFor="let entityType of entitytype"
                                                                        [value]="entityType.id"
                                                                        [selected]="entityType.id == 'StartupCompanyDetailsdata.entity_type'">
                                                                    {{entityType.name}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Industry
                                                        Sector<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="industry_sector"
                                                                    [ngModel]="StartupCompanyDetailsdata?.industry_sector"
                                                                    data-error="Please select industry sector."
                                                                    required>
                                                                <option *ngFor="let industry of industryCategoryData"
                                                                        [value]="industry.id"
                                                                        [selected]="industry.id == StartupCompanyDetailsdata?.industry_sector">
                                                                    {{industry.name}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Establishment
                                                        Date<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="estb_date"
                                                                    [ngModel]="StartupCompanyDetailsdata?.estb_date"
                                                                    data-error="Please select establish year." required>
                                                                <option *ngFor="let empYear of estblish_year"
                                                                        [value]="empYear"
                                                                        [selected]="empYear == 'StartupCompanyDetailsdata.estb_date'">
                                                                    {{empYear}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Employee
                                                        Count<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="emp_count"
                                                                    [ngModel]="StartupCompanyDetailsdata?.emp_count"
                                                                    data-error="Please select employee count." required>
                                                                <option *ngFor="let empCount of empployee_count"
                                                                        [value]="empCount.id"
                                                                        [selected]="empCount.id == 'StartupCompanyDetailsdata.emp_count'">
                                                                    {{empCount.name}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business
                                                        Website <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   name="business_website"
                                                                   [ngModel]="StartupCompanyDetailsdata?.business_website"
                                                                   placeholder="Enter Website Url">
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Facilities
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="facilities_desc"
                                                                      [ngModel]="StartupCompanyDetailsdata?.facilities_desc"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Enter your Business Facilities Ex: Shop, Machine Info"></textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">Social media links</div>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Facebook
                                                        Link <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   name="facebook_profile"
                                                                   [ngModel]="StartupCompanyDetailsdata?.facebook_profile"
                                                                   placeholder="Enter Facebook Url">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Twitter
                                                        Link <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   name="twitter_profile"
                                                                   [ngModel]="StartupCompanyDetailsdata?.twitter_profile"
                                                                   placeholder="Enter Twitter Url">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Linkedin
                                                        Link <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   name="linkedin_profile"
                                                                   [ngModel]="StartupCompanyDetailsdata?.linkedin_profile"
                                                                   placeholder="Enter Linkedin Url">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->


