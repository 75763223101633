
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">

                                <div class="cat-sec-ttl">
                                    <div *ngIf="userProfileType?.reg_profile == 'Other' || userProfileType?.reg_profile == null || userProfileType?.reg_profile == ''">
                                        MANAGE BUSINESS INFORMATION
                                    </div>
                                    <div *ngIf="userProfileType?.reg_profile == 'Broker'">
                                        <div><a routerLink="/dashboard/brokerbusinesslisting">Business Listing</a></div>
                                        {{BusinessAdvDetailsdata.advmt_headline}}
                                    </div>
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/businessConfidential/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/businessAdvDetails/{{profile_str}}">Advert Details</a></li>
                                        <li class="selected"><a routerLink="/dashboard/businessInformation/{{profile_str}}">Business Info</a></li>
                                        <li><a routerLink="/dashboard/businessFinanceDetails/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/businessTeamDetails/{{profile_str}}">Team Details</a></li>
                                        <li><a routerLink="/dashboard/businessLocationDetails/{{profile_str}}">Headquarters</a></li>
                                        <li><a routerLink="/dashboard/businessRequirement/{{profile_str}}">Requirements</a></li>
                                        <li><a routerLink="/dashboard/businessAttachment/{{profile_str}}">Attachments</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form #businessForm="ngForm" (ngSubmit)="businessForm.form.valid && businessMyAccountSubmit(businessForm.value)" class="form-horizontal"  name="businessForm" role="form">
                                            <div class="sec-slide-effect">

                                                <div class="msg-pnl_sales" *ngIf="businessProfileSuccessError" style="margin-bottom:20px; text-align:center;">{{businessProfileSuccessError}}</div>
                                                <div class="msg-pnl_sales" *ngIf="businessProfileFailError" style="margin-bottom:20px; text-align:center;">{{businessProfileFailError}}</div>

                                                <!-- <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Business Information</div></div>
                                                </div> -->

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Name<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"  name="seller_company"  [ngModel]="BusinessInformationdata?.seller_company" placeholder="Enter Company Name" data-error="Please enter company name." required>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Establishment Year<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="estb_year" [ngModel]="BusinessInformationdata?.estb_year" data-error="Please select establish year." required>
                                                                <option *ngFor="let empYear of estblish_year" [value]="empYear" [selected]="empYear == 'BusinessInformationdata.estb_year'">{{empYear}}</option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Employee Count<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="emp_count" [ngModel]="BusinessInformationdata?.emp_count" data-error="Please select employee count." required>
                                                                <option *ngFor="let empCount of empployee_count" [value]="empCount.id" [selected]="empCount.id == 'BusinessInformationdata.emp_count'">{{empCount.name}}</option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Entity Type<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="entity_type" [ngModel]="BusinessInformationdata?.entity_type" data-error="Please select entity type." required>
                                                                <option *ngFor="let entityType of entitytype" [value]="entityType.id" [selected]="entityType.id == 'BusinessInformationdata.entity_type'">{{entityType.name}}</option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business Type<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="business_type" [ngModel]="BusinessInformationdata?.business_type" data-error="Please select business type." required>
                                                                <option *ngFor="let BusinessType of businesstype" [value]="BusinessType.id" [selected]="BusinessType.id == 'BusinessInformationdata.business_type'">{{BusinessType.name}}</option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Industry Sector<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="industry_sector" [ngModel]="BusinessInformationdata?.industry_sector" data-error="Please select industry sector." required>
                                                                <option *ngFor="let industry of industryCategoryData" [value]="industry.id" [selected]="industry.id == BusinessInformationdata?.industry_sector">{{industry.name}}</option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business Website <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="business_website" [ngModel]="BusinessInformationdata?.business_website" placeholder="Enter Website Url">
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Facilities <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea  class="form-control" name="facilities_desc"  [ngModel]="BusinessInformationdata?.facilities_desc" rows="3" minlength="15" maxlength="150" placeholder="Enter your Business Facilities Ex: Shop, Machine Info"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Describe about the Business / Company Summary <span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea  class="form-control" name="company_summary"  [ngModel]="BusinessInformationdata?.company_summary" rows="3" minlength="15" maxlength="250" placeholder="Describe about the Business / Company Summary" data-error="Please enter company summary." required></textarea>
                                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->

