import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from "../../../../config/app-config.constants";
import {IAppConfig} from "../../../../config/app-config.interface";
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'startup-attachment',
    templateUrl: './startup-attachment.component.html'
})
export class StartupAttachmentComponent implements OnInit, AfterViewChecked {

    profile_str;
    public startupAttachmentdata;
    public startupProfileSuccessError;
    public startupProfileFailError;
    files = [];
    docs = [];
    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Startup Attachment - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    startupMyAccountSubmit(data) {

        if ($('form[name="startupForm"]').validator('validate').has('.has-error').length) {
            return;
        }

        const formData: any = new FormData();
        for (const file of this.files) {
            formData.append('startup_prof_pic[]', file, file.name);
        }
        for (const doc of this.docs) {
            formData.append('startup_doc_path[]', doc, doc.name);
        }

        formData.append('startup_profile_str', this.profile_str);

        this.http.post(this.config.apiEndpoint + '/updatestartupattachment/' + this.profile_str, formData).subscribe(
            data => {
                console.log("Advertisement Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.startupProfileSuccessError = data;
                //this.startupProfileSuccessError = "Attachments Details Updated Successfully";

                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);

            },
            error => {
                //console.log("Please Enter Correct Details");
                this.startupProfileFailError = "Please Enter Correct Details";
            });
    }

    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            if (this.files.findIndex(item => item.name === fileInput.target.files[0].name) === -1) {
                this.files.push(fileInput.target.files[0]);
                fileInput.srcElement.parentElement.setAttribute('flag', '1');
            } else {
                fileInput.srcElement.parentElement.setAttribute('flag', '0');
            }
        }
    }

    docChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            if (this.docs.findIndex(item => item.name === fileInput.target.files[0].name) === -1) {
                this.docs.push(fileInput.target.files[0]);
                fileInput.srcElement.parentElement.setAttribute('flag', '1');
            } else {
                fileInput.srcElement.parentElement.setAttribute('flag', '0');
            }
        }
    }

    removeUploadEvent(type, object) {
        const fileName = object.srcElement.parentElement.getAttribute('file');
        if (type === 'doc') {
            this.docs = this.docs.filter(item => item.name !== fileName);
        }
        if (type === 'file') {
            this.files = this.files.filter(item => item.name !== fileName);
        }
    }

    gestartupAttachment(profile_str: any): void {
        this.myprofileService.gestartupAttachment(this.profile_str)
            .then((result) => {
                this.startupAttachmentdata = result;
            });
    }

    ngOnInit() {
        this.gestartupAttachment(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="startupForm"]').validator();
        }
    }

}
