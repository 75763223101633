import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BASE_URL } from '../../../../config/app-config.constants';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';
import { STATE } from '../../../../static-data/state';

declare var $: any;

@Component({
    selector: 'app-mentor-listing',
    templateUrl: './mentor-listing.component.html',
    styleUrls: ['./mentor-listing.component.css']
})
export class MentorListingComponent implements OnInit {

    seller = {};
    mentor = {};
    startup = {};
    Business = [];
    subheadline;
    industry = [];
    industryloc = [];
    industryid = [];
    filterLocation12;
    industrySeller;
    country: string;
    currentPage = 1;
    itemsPerPage = 12;
    state: any = [];
    stateArray: any = [];
    cityArray: any = [];
    city: any = [];
    catind: any = [];
    subcatind: any = [];
    loading = false;
    total = 0;
    pagination = false;
    limit = 20;
    hititle;
    checkCurrectCity = false;
    testDataValue:any = [];

    BusinessType = '';
    totalItems = 0;
    minInvestment = 0;
    maxInvestment = 100000000;
    annualsalesmin = 0;
    annualsalesmax = 1000000000;
    minInvestmentValue;
    maxInvestmentValue;
    filterIndustry: any = [];
    filterLocation: any = [];
    filterMentorselect: any = [];
    filterLocationstate: any = [];
    filterIndustrymain: any = [];
    filterMentor: any = [];
    filterMentorArray: any = [];
    mentorOccupation: any = [];
    filters: any;
    value;
    sortby;
    p: any;
    i: any;
    m: any;
    public noResult = false;
    obj = { Title: 'test', Url: 'test12' };

    public bxbannertop = '';
    public bxbannerleft = '';
    public bxbannerrightside = '';
    public bxbannercenter = '';
    public bxbannerbottom = '';
    public bxbannertopmob = '';
    public bannerpage = 7;
    options: Array<Object> = [
        {
            label: 'Recently Listed (desc first)',
            value: 'desc'
        }, {
            label: 'Recently Listed (asc first)',
            value: 'asc'
        }];

    @ViewChild(ListingPaginationComponent)
    private listingPagination: ListingPaginationComponent;
    public seo;
    public isViewChecked = false;
    public appConfig = appConfig;

    @ViewChild('slider', { read: ElementRef }) slider: ElementRef;

    sliderRange; //= FILTER_RANGE.length - 1;

    someKeyboardConfig: any = {
        connect: true,
        start: [this.minInvestment, this.maxInvestment],
        step: 1,
        tooltips: [false, false],
        range: {
            min: this.minInvestment,
            max: this.maxInvestment
        },
        behaviour: 'drag',
    };

    constructor(private linkService: LinkService, private meta: Meta, private title: Title,
        private homeService: HomeService,  private route: ActivatedRoute,
        private router: Router, @Inject(PLATFORM_ID) private platformId: Object,
        private historyLocation: Location) {

        this.minInvestmentValue = appConfig.PriceSlideFormate(this.minInvestment);
        this.maxInvestmentValue = appConfig.PriceSlideFormate(this.maxInvestment);
        linkService.removeCanonicalLink();
        this.filters = {
            query: '',
            type: '',
            tags: [
                {
                    label: 'Sale',
                    name: 'Sale',
                    selected: false
                }
            ]
        };
    }

    private setTitleMeta() {
        this.subheadline = this.seo.description;
        this.hititle = this.seo.title;
        this.title.setTitle(this.seo.title);

        this.meta.updateTag({property: 'og:url', content: BASE_URL + this.historyLocation.path()});
        this.meta.updateTag({name: 'twitter:url', content: BASE_URL + this.historyLocation.path()});
        
        this.meta.updateTag({
            property: 'og:title',
            content: this.seo.title + ' - BusinessEx'
        });


        this.meta.updateTag({
            name: 'twitter:title',
            content: this.seo.title + ' - BusinessEx'
        });

        this.meta.updateTag({
            name: 'Description', content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
                .replace(/(\r\n\t|\n|\r\t)/gm, '')
                .replace('&lsquo;', '').replace('&rsquo;', '')
                .substring(0, 400)
        });

        this.meta.updateTag({ property: 'og:description', 
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
            .replace(/(\r\n\t|\n|\r\t)/gm, '')
            .replace('&lsquo;', '').replace('&rsquo;', '')
            .substring(0, 400)
        });
        this.meta.updateTag({ name: 'twitter:description', 
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
                .replace(/(\r\n\t|\n|\r\t)/gm, '')
                .replace('&lsquo;', '').replace('&rsquo;', '')
                .substring(0, 400)
        });

        this.meta.updateTag({ name: 'Keywords', content: this.seo.keyword });
        this.linkService.removeCanonicalLink();
        //this.linkService.removeAmpHtmlCanonicalLink();
        const totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 0;
        let pNumber = 0;
        if (this.currentPage > 1 && this.currentPage <= totalPages) {
            pNumber = this.currentPage - 1;
            this.linkService.addTag({
                rel: 'prev',
                href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
            });
        }
        this.linkService.addTag({
            rel: 'canonical',
            href: BASE_URL + this.historyLocation.path()
        });

        // this.linkService.addTag({
        //     rel: 'amphtml',
        //     href: BASE_URL + '/amp' + this.historyLocation.path()
        // });

        if (this.currentPage >= 1 && this.currentPage < totalPages) {
            pNumber = this.currentPage + 1;
            this.linkService.addTag({
                rel: 'next',
                href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
            });
        }
    }
    private reInitializeVariables() {
        this.filterIndustrymain = [];
        this.filterIndustry = [];
        this.catind = [];
        this.industry = [];
        this.subcatind = [];
        this.stateArray = [];
        this.filterLocationstate = [];
        this.filterLocation = [];
        this.cityArray = [];
        this.state = [];
        this.city = [];
        this.filterMentorArray = [];
        this.filterMentor = [];
        this.filterMentorselect = [];
    }

    private callMentorService() {
        this.homeService.getMentorlisting(this.currentPage, this.state, this.cityArray, this.filterMentor, this.subcatind,
            this.BusinessType, this.sortby, this.itemsPerPage, this.maxInvestment, this.minInvestment, this.annualsalesmin,
            this.annualsalesmax)
            .subscribe(
                result => {
                    this.Business = result.mentorList;
                    //console.log(this.Business);
                    this.totalItems = result.businessCount;
                    this.seo = result.seo;
                    this.noResult = this.Business.length === 0;
                    this.setTitleMeta();
                    this.loading = false;
                    this.currentPage = (this.Business.length === 0) ? 0 : this.currentPage;
                }
            );
    }

    includeindustry(catind, subcatind, industry1, industrymain, subid) {
        const n = $('input:checkbox[id^=' + catind + ']:checked').length;
        if (n === 1) {
            if (this.filterIndustrymain[catind]) {
                const numbercatid = parseInt(subid, 10);
                if ($.inArray(catind, this.catind) > -1) {
                    this.catind.splice(this.catind.indexOf(catind), 1);
                    $('input:checkbox[id^=' + catind + ']').removeAttr('checked');
                    this.filterIndustrymain[catind] = false;
                }
                if ($.inArray(industrymain, this.industry) > -1) {
                    this.industry.splice(this.industry.indexOf(industrymain), 1);
                }
                if ($.inArray(numbercatid, this.subcatind) > -1) {
                    this.subcatind.splice(this.subcatind.indexOf(numbercatid), 1);
                    this.filterIndustry[numbercatid] = false;
                }
                this.getMentorlisting();
            }
        }

        if (industry1 !== '') {
            if ($.inArray(industry1, this.industry) > -1) {
                this.industry.splice(this.industry.indexOf(industry1), 1);
            } else {
                this.industry.push(industry1);
            }
        }

        if (catind !== '') {
            if ($.inArray(catind, this.catind) > -1 && subcatind === '') {
                if (this.filterIndustrymain[catind]) {
                    $('input:checkbox[id^=' + catind + ']').removeAttr('checked');
                    this.catind.splice(this.catind.indexOf(catind), 1);
                    for (let index = 0; index < this.industrySeller.length; ++index) {
                        const subIndustryid = this.industrySeller[index];
                        if (subIndustryid.industry === industry1) {
                            this.filterIndustry[subIndustryid.subIndustryid] = false;
                            if (this.subcatind.indexOf(subIndustryid.subIndustryid) > -1) {
                                this.subcatind.splice(this.subcatind.indexOf(subIndustryid.subIndustryid), 1);
                            }
                            if (this.industry.indexOf(subIndustryid.subindustry) > -1) {
                                this.industry.splice(this.industry.indexOf(subIndustryid.subindustry), 1);
                            }
                        }
                    }
                }

            } else {
                for (let index = 0; index < this.industrySeller.length; ++index) {
                    const subIndustryid = this.industrySeller[index];
                    if (subIndustryid.industry === industry1) {
                        this.catind.push(catind);
                        this.filterIndustry[subIndustryid.subIndustryid] = true;
                        this.industryid.push(subIndustryid.subIndustryid);
                        this.industry.push(subIndustryid.subindustry);
                        this.subcatind.push(subIndustryid.subIndustryid);
                    } else if (subIndustryid.industryid === catind) {
                        this.industry.push(subIndustryid.industry);
                    }
                }
            }
            if ($.inArray(subcatind, this.subcatind) > -1 && subcatind !== '') {
                this.subcatind.splice(this.subcatind.indexOf(subcatind), 1);
                this.filterIndustry[subcatind] = false;
                this.industry.splice(this.industry.indexOf(industry1), 1);
            } else if (subcatind !== '') {
                this.subcatind.push(subcatind);
                this.filterIndustry[subcatind] = true;
                this.catind.push(catind);
                this.filterIndustrymain[catind] = true;
            }

            this.subcatind = appConfig.unique(this.subcatind);
            this.industry = appConfig.unique(this.industry);
            this.catind = appConfig.unique(this.catind);
            this.getMentorlisting();
        }
    }

    getMentorlisting() {
        this.loading = true;
        function Stringrep(items) {
            return items.replace(/\s/g, '-').toLowerCase();
        }
        const city = (this.stateArray.length === 1) ?
            (this.city.length === 1) ? '-in-' + appConfig.getSlug(this.cityArray[0]) : '-in-' + this.stateArray.map(Stringrep).join('-') : '';

        if (this.filterMentor.length === 0 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting');
            this.industry = [];
        }

        if (this.filterMentor.length === 0 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/mentor' + city + '/c0/s0/' + this.state.join('-'));
        }

        if (this.filterMentor.length === 0 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/mentor' + '-in-India/c0/s0/' + this.state.join('-'));
        }

        if (this.filterMentor.length === 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/' + appConfig.getSlug(this.filterMentorArray[0])
                + '-mentor-in-india/' + this.filterMentor.join('-') + '/s0/st0');
        }

        if (this.filterMentor.length === 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/' + appConfig.getSlug(this.filterMentorArray[0]) + '-mentor' + city + '/' + this.filterMentor.join('-') + '/s0/' + this.state.join('-'));
        }

        if (this.filterMentor.length === 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/' + appConfig.getSlug(this.filterMentorArray[0]) + '-mentor'
                + '-in-India/' + this.filterMentor.join('-') + '/s0/' + this.state.join('-'));
        }

        if (this.filterMentor.length > 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/mentor-in-india/' + this.filterMentor.join('-') + '/s0/st0');
        }

        if (this.filterMentor.length > 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/mentor' + city + '/' + this.filterMentor.join('-')
                + '/s0/' + this.state.join('-'));
        }

        if (this.filterMentor.length > 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'mentorlisting/mentor' + '-in-India/' + this.filterMentor.join('-') + '/s0/'
                + this.state.join('-'));
        }
        if (this.stateArray.length >= 1) {
            const newCat = this.filterLocation12.filter(item => (item.state === this.state[0]));
            if ((this.stateArray.length === 1 && newCat.length !== this.city.length) || this.stateArray.length > 1) {
                history.pushState(this.obj, this.obj.Title, this.historyLocation.path() + '/' + this.city.join('-'));
            }
        }
        if (this.pagination) {
            history.pushState(this.obj, this.obj.Title, this.historyLocation.path() + '?page=' + this.currentPage);
        }
        this.callMentorService();
    }
    parentChange(value) {
        this.currentPage = 1;
        this.BusinessType = value.name;
        this.getMentorlisting();
    }

    onChangeObj(sortby) {
        //  this.currentPage = 1;
        this.sortby = sortby;
        this.getMentorlisting();

    }

    includeCity(State, shortsate, City, CityId, industrymainloc) {
        CityId = parseInt(CityId, 10);
        this.currentPage = 1;
        this.pagination = false;
        if ($('input:checkbox[id^=' + shortsate + ']:checked').length === 1) {
            if (this.filterLocationstate[State]) {
                if ($.inArray(State, this.stateArray) > -1) {
                    this.stateArray.splice(this.stateArray.indexOf(State), 1);
                    this.state.splice(this.state.indexOf(shortsate), 1);
                    this.filterLocationstate[State] = false;
                }
                this.filterLocation12.filter(item => (item.state === shortsate)).forEach(newItem => {
                    if (this.cityArray.indexOf(newItem.mentor_city) > -1) {
                        this.cityArray.splice(this.cityArray.indexOf(newItem.mentor_city), 1);
                        this.city.splice(this.city.indexOf(newItem.CityId), 1);
                        this.filterLocation[newItem.mentor_city] = false;
                    }
                });
                this.getMentorlisting();
                return;
            }
        }
        if (State !== '') {
            if ($.inArray(State, this.stateArray) > -1 && City === '') {
                if (this.filterLocationstate[State]) {
                    $('input:checkbox[id^=' + shortsate + ']').removeAttr('checked');
                    if (this.industryloc.indexOf(State) > -1) {
                        this.industryloc.splice(this.industryloc.indexOf(State), 1);
                    }
                    if (this.stateArray.indexOf(State) > -1) {
                        this.stateArray.splice(this.stateArray.indexOf(State), 1);
                    }
                    if (this.state.indexOf(shortsate) > -1) {
                        this.state.splice(this.state.indexOf(shortsate), 1);
                    }
                    for (let index = 0; index < this.filterLocation12.length; ++index) {
                        const locationFilter1 = this.filterLocation12[index];
                        if (locationFilter1.mentor_state === State) {
                            this.filterLocation[locationFilter1.mentor_city] = false;
                            if (this.cityArray.indexOf(locationFilter1.mentor_city) > -1) {
                                this.cityArray.splice(this.cityArray.indexOf(locationFilter1.mentor_city), 1);
                            }
                            if (this.city.indexOf(parseInt(locationFilter1.cityId, 10)) > -1) {
                                this.city.splice(this.city.indexOf(parseInt(locationFilter1.cityId, 10)), 1);
                            }
                        }
                    }
                }

            } else {
                for (let ml = 0; ml < this.filterLocation12.length; ++ml) {
                    const locationFilter = this.filterLocation12[ml];
                    if (locationFilter.mentor_state === industrymainloc) {
                        this.pushCityState(locationFilter);
                    } else if (locationFilter.mentor_city === City) {
                        this.industryloc.push(locationFilter.mentor_state);
                    }
                }
            }
            if ($.inArray(City, this.cityArray) > -1 && City !== '') {
                if (this.cityArray.indexOf(City) > -1) {
                    this.cityArray.splice(this.cityArray.indexOf(City), 1);
                    this.city.splice(this.city.indexOf(CityId), 1);
                }
            } else if (City !== '') {
                if ($.inArray(City, this.cityArray) === -1) {
                    this.cityArray.push(City);
                }
                if ($.inArray(CityId, this.city) === -1) {
                    this.city.push(CityId);
                }
                if ($.inArray(State, this.stateArray) === -1) {
                    this.stateArray.push(State);
                }
                this.filterLocationstate[State] = true;
            }
            this.stateArray.forEach(newstate => {
                this.state.push(STATE[newstate]);
            });
            this.state = appConfig.unique(this.state);
            this.getMentorlisting();
        }
    }

    mentorChange(mentorOccupationid, mentorOccupation) {
        this.currentPage = 1;
        this.pagination = false;
        if (mentorOccupation !== '') {
            if ($.inArray(mentorOccupationid, this.filterMentor) > -1) {
                this.filterMentor.splice(this.filterMentor.indexOf(mentorOccupationid), 1);
            } else {
                this.filterMentor.push(mentorOccupationid);
            }

            if ($.inArray(mentorOccupation, this.filterMentorArray) > -1) {
                this.filterMentorArray.splice(this.filterMentorArray.indexOf(mentorOccupation), 1);
                this.filterMentorselect[mentorOccupation] = false;
            } else {
                this.filterMentorArray.push(mentorOccupation);
                this.filterMentorselect[mentorOccupation] = true;
            }

            this.filterMentor = appConfig.unique(this.filterMentor);
            this.filterMentorArray = appConfig.unique(this.filterMentorArray);
            this.getMentorlisting();

        }

    }

    goToPage(n: number): void {
        this.currentPage = n;
        this.pagination = true;
        this.getMentorlisting();
    }

    onNext(): void {
        this.currentPage++;
        this.pagination = true;
        this.getMentorlisting();
    }

    onPrev(): void {
        this.currentPage--;
        this.pagination = true;
        this.getMentorlisting();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: Params) => {
            this.loading = true;
            this.reInitializeVariables();
            const param1 = +this.route.snapshot.queryParams['page'];
            if (param1 !== undefined && param1 > 0) {
                this.currentPage = param1;
            }

            this.homeService.getfiltersMentor()
                .subscribe(
                    result => {

                        this.filterLocation12 = result.Location;
                        this.mentorOccupation = result.mentorOccupation;

                        if (params.get('businesstitle') == null) {
                            this.callMentorService();
                        }
                        if (params.get('businesstitle')) {

                            if (params.get('selecat') !== 'c0') {
                                this.filterMentor = params.get('selecat').split('-').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            }

                            this.filterMentor.forEach(function (key) {
                                const mentor = this.mentorOccupation.filter(item => (item.mentorOccupationid === key))[0];
                                if (mentor !== undefined) {
                                    this.filterMentorArray.push(mentor.mentorOccupation);
                                    this.filterMentorselect[mentor.mentorOccupation] = true;
                                    this.filterMentor.push(key);
                                }
                            }, this);
                            this.filterMentor = appConfig.unique(this.filterMentor);
                            this.filterMentorArray = appConfig.unique(this.filterMentorArray);


                            if (params.get('state') !== 'st0') {
                                this.state = params.get('state').split('-');
                            }
                            let paramsCity = [];
                            if (params.get('city') !== null && params.get('city') !== '' && params.get('city') !== 'ct0') {
                                paramsCity = params.get('city').split('-').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            }
                            const newState = [];
                            if (this.state) {
                                Object.keys(STATE).forEach(function (key) {
                                    newState[STATE[key]] = key;
                                });


                                for (let i = 0; i < this.state.length; ++i) {
                                    this.filterLocationstate[newState[this.state[i]]] = true;
                                    for (let ml = 0; ml < this.filterLocation12.length; ++ml) {
                                        const locationFilter = this.filterLocation12[ml];
                                        
                                        if (locationFilter.mentor_state === newState[this.state[i]] &&
                                            (paramsCity.length === 0 || paramsCity.indexOf(locationFilter.cityId) > -1)) {
                                           
                                            this.pushCityState(locationFilter);
                                            this.checkCurrectCity = true;

                                        }else if(locationFilter.mentor_state === newState[this.state[i]]){
                                            this.testDataValue.push(locationFilter)
                                        }
                                        
                                    }
                                }
                                
                                if(paramsCity.length==1 && this.state.length==1 && this.checkCurrectCity ==false && this.testDataValue.length>0){
                                    this.router.navigate(['/mentorlisting/mentor-in-'+this.testDataValue[0].stateSlug+'/c0/s0/'+this.state[0]]);
                                }

                            }
                            this.callMentorService();
                        }

                    });
        });

    }

    removeFilter(filterId, type): void {
        if (type === 1) {
            this.includeCity(filterId, STATE[filterId], '', '', filterId);
        }
        if (type === 2) {
            const mentor = this.mentorOccupation.filter(item => (item.mentorOccupationid === filterId))[0];
            this.mentorChange(mentor.mentorOccupationid, mentor.mentorOccupation);
        }
        if (type === 3) {
            this.loading = true;
            //  removing mentor filter
            this.filterMentor = [];
            this.filterMentorArray = [];
            Object.keys(this.filterMentorselect).forEach(entry => {
                this.filterMentorselect[entry] = false;
            });

            //  removing location filter
            this.stateArray = [];
            this.city = [];
            this.cityArray = [];
            this.state = [];
            Object.keys(this.filterLocationstate).forEach(entry => {
                this.filterLocationstate[entry] = false;
            });
            Object.keys(this.filterLocation).forEach(entry => {
                this.filterLocation[entry] = false;
            });
            this.getMentorlisting();
        }
    }

    getMentorType(mentorId): any {

        if (mentorId === '') {
            return '';
        }
        const mentor = this.mentorOccupation.filter(item => (item.mentorOccupationid === mentorId))[0];
        return (mentor !== '') ? mentor : '';
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            // $('#more').css('display', 'block');
            this.isViewChecked = true;
        }
    }

    pushCityState(locationFilter: any) {
        this.filterLocation[locationFilter.mentor_city] = true;
        if (this.industryloc.indexOf(locationFilter.mentor_state) === -1) {
            this.industryloc.push(locationFilter.mentor_state);
        }
        if (this.stateArray.indexOf(locationFilter.mentor_state) === -1) {
            this.stateArray.push(locationFilter.mentor_state);
        }
        if (this.cityArray.indexOf(locationFilter.mentor_city) === -1) {
            this.cityArray.push(locationFilter.mentor_city);
        }
        if (this.city.indexOf(locationFilter.cityId) === -1) {
            this.city.push(locationFilter.cityId);
        }
    }


    priceFilter(event) {
        this.minInvestmentValue = appConfig.PriceSlideFormate(event[0]);
        this.maxInvestmentValue = appConfig.PriceSlideFormate(event[1]);

        this.minInvestment = event[0];
        this.maxInvestment = event[1];
        this.getMentorlisting();
    }

    ngAfterViewInit() {

        if (isPlatformBrowser(this.platformId)) {

            setTimeout(function () {

                /*var html5Slider = document.getElementById('html5');
                noUiSlider.create(html5Slider, {
                  start: [100, 800],
                  connect: true,
                  range: {
                    'min': 100,
                    'max': 1000
                  }
                });*/

                $(".accordion_head").click(function () {
                    if ($(this).next(".accordion_body").is(':visible')) {
                        $(this).next(".accordion_body").slideUp(300);
                        $(this).children(".plusminus").addClass('add');
                        $(this).children(".plusminus").removeClass('minus');
                    } else {
                        $(this).next(".accordion_body").slideDown(300);
                        $(this).children(".plusminus").addClass('minus');
                        $(this).children(".plusminus").removeClass('add');
                    }
                });

                /*$(".accordion_headmain").click(function () {
    
                    if ($(this).next(".accordion_bodymain").is(':visible')) {
                        $(this).next(".accordion_bodymain").slideUp(300);
                        $(this).children(".rightdown").addClass('rightval');
                        $(this).children(".rightdown").removeClass('downval');
                    } else {
                        $(this).next(".accordion_bodymain").slideDown(300);
                        $(this).children(".rightdown").addClass('downval');
                        $(this).children(".rightdown").removeClass('rightval');
                    }
                });*/

                $("#showftr").click(function () {
                    $(".catleft").show(400);
                });

                $("#closeftr").click(function () {
                    $(".catleft").hide(400);
                });
            }, 50)

        }
    }


    getIndustryName(industryId, subIndustryId = 0): any {
        if (this.industrySeller === undefined || this.industrySeller === '' || industryId === '') {
            return '';
        }
        let myIndustry = this.industrySeller.filter(item => (item.industryid === industryId));
        if (subIndustryId !== 0) {
            myIndustry = this.industrySeller.filter(item => (item.industryid === industryId && item.subIndustryid === subIndustryId));
        }
        return myIndustry[0];
    }


}
