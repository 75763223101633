
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!--   profile_detail left page         -->
                    <profile-detail [profileViewCount]="totalclickthreemonth"></profile-detail>

                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="profile-view-chart">
                                    <div class="">
                                        <!--Business registered under broker profile-->
                                        <div class="" style="margin-bottom: 20px;"
                                             *ngIf="userRegisterProfileType == 'Broker'">
                                            <select class="form-control selectpicker crt-prfile"
                                                    (ngModelChange)="onChangeObj($event)" [ngModel]="ProfileSelected"
                                                    name="profiletype">
                                                <option [ngValue]="profiletype"
                                                        *ngFor="let profiletype of profileObjects">
                                                    {{profiletype.profile_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div #chartTarget>
                                            chart target
                                        </div>
                                    </div>
                                    <!--<div id="container" style="min-width: 310px; height: 400px; margin: 0 auto"></div>-->
                                </div>
                            </div>
                            <div class="col-md-12">
                                <!--Upgrade Plan -->
                                <div class="bdr cat-list business-sec">
                                    <div class="cat-list" style="border-bottom: 1px solid #dfdfdf;overflow: hidden;">
                                        <div class="col-md-6 col-xs-12 cat-sec-ttl">
                                            Total Profile Visits: {{totalclickthreemonth}}
                                        </div>
                                        <div class="col-md-6 col-xs-12 astrick" *ngIf="totalclickthreemonth">(Inclusive
                                            of profile & non-profile users)
                                        </div>
                                    </div>
                                    <div class="clr" style="clear: both"></div>
                                    <div class="ttl-viewd">
                                        <div class="number">{{bxProfileViewlength}} Profile owners viewed your
                                            profile.
                                        </div>
                                        <div class="desc" *ngIf="(ProfileSelected?.membership_type==0 && addOnProfileView == 0)">See the full list
                                            of {{ totalclickthreemonth }} people that viewed your profile by upgrading
                                            your profile or using our Add-on Packages.
                                        </div>
                                        <div class="desc" *ngIf="(ProfileSelected?.membership_type > 0 || addOnProfileView > 0)">See the full list
                                            of profile owners that viewed your profile.
                                        </div>
                                    </div>
                                    <div class="upgrade-btn" *ngIf="(ProfileSelected?.membership_type==0 && addOnProfileView == 0)">
                                        <div class="submitfrm">
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 1 && ProfileSelected?.membership_type == 0">
                                                <a [ngClass]="{active : activeMenu === 'upgrade'}"
                                                   routerLink="/dashboard/upgrade/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                            <div *ngIf="userID != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_type == 2 && ProfileSelected?.membership_type == 0">
                                                <a [ngClass]="{active : activeMenu === 'upgradeInv'}"
                                                   routerLink="/dashboard/upgradeInv/{{ProfileSelected?.profile_str}}"
                                                   class="upgrade btn btn-default btn-blue">UPGRADE ACCOUNT</a>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- Search History Listing Start Here -->
                                    <div role="tabpanel" class="tab-pane" id="search-history"
                                         *ngIf="(ProfileSelected?.membership_type > 0 || addOnProfileView > 0)">
                                        <!-- Search History Business Listing Start Here -->
                                        <div *ngIf="bxProfileViewlength>0">
                                            <ul *ngIf="UserId != ''">
                                                <li *ngFor="let searchhistorydata of bxProfileView | slice:0:searchlimit; let i=index; let last = last"
                                                    [isLast]="last">

                                                    <div class="row"
                                                         *ngIf="searchhistorydata.profileTypeStr == 'Business'">
                                                        <div class="col-xs-12 col-sm-5 col-md-3">
                                                            <a routerLink="/business/{{searchhistorydata.sellerurl}}/{{searchhistorydata.profileStr}}">
                                                                <span *ngIf="searchhistorydata.thumbimage == null">
                                                                <img
                                                                        class="img-responsive"
                                                                        src="assets/images/category/small/{{searchhistorydata.catImage}}"
                                                                        alt="{{searchhistorydata.title}}"
                                                                        title="{{searchhistorydata.title}}"/>
                                                                    </span>
                                                                <span *ngIf="searchhistorydata.thumbimage != null && searchhistorydata.thumbimage != ''">
                                                                <img class="img-responsive"
                                                                     src="{{searchhistorydata.thumbimage}}"
                                                                     alt="{{searchhistorydata.title}}"
                                                                     title="{{searchhistorydata.title}}"/>
                                                                    </span>


                                                            </a>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-7 col-md-4 pviews">
                                                            <div class="cat">
                                                                {{searchhistorydata.industry}}
                                                            </div>
                                                            <div class="cat-ttl"><a
                                                                    routerLink="/business/{{searchhistorydata.sellerurl}}/{{searchhistorydata.profileStr}}">{{searchhistorydata.title}}</a>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-7 col-md-4 pviewsLoc">
                                                            <div class="location"><i class="fa fa-map-marker"
                                                                                     aria-hidden="true"></i>
                                                                {{searchhistorydata.location}}
                                                            </div>
                                                            <div class="investment-price">
                                                                <span *ngIf="searchhistorydata.priceLabel != 'Seeking Mentor'">
                                             <i class="fa fa-inr" aria-hidden="true"
                                                *ngIf="searchhistorydata.price != 'NA'"></i>
                                             {{(searchhistorydata.price != "NA") ? searchhistorydata.price  : ("Undisclosed")  }}
                                            </span>
                                                                <div>{{searchhistorydata?.priceLabel}}</div>
                                                            </div>
                                                            <div class="contact-btn"><a
                                                                    routerLink="/business/{{searchhistorydata.sellerurl}}/{{searchhistorydata.profileStr}}">Contact
                                                                business</a></div>
                                                        </div>
                                                    </div>

                                                    <div class="row"
                                                         *ngIf="searchhistorydata.profileTypeStr == 'Startup'">
                                                        <div class="col-xs-12 col-sm-5 col-md-3">
                                                            <a routerLink="/business/{{searchhistorydata.startupurl}}/{{searchhistorydata.profileStr}}">
                                                                <span *ngIf="searchhistorydata.thumbimage == null">
                                                                <img
                                                                        class="img-responsive"
                                                                        src="assets/images/category/small/{{searchhistorydata.catImage}}"
                                                                        alt="{{searchhistorydata.title}}"
                                                                        title="{{searchhistorydata.title}}"/>
                                                                    </span>
                                                                <span *ngIf="searchhistorydata.thumbimage != null && searchhistorydata.thumbimage != ''">
                                                                <img class="img-responsive"
                                                                     src="{{searchhistorydata.thumbimage}}"
                                                                     alt="{{searchhistorydata.title}}"
                                                                     title="{{searchhistorydata.title}}"/>
                                                                    </span>
                                                            </a>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-7 col-md-4 pviews">
                                                            <div class="cat">
                                                                {{searchhistorydata.industry}}
                                                            </div>
                                                            <div class="cat-ttl"><a
                                                                    routerLink="/startup/{{searchhistorydata.startupurl}}/{{searchhistorydata.profileStr}}">{{searchhistorydata.title}}</a>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-7 col-md-4 pviewsLoc">
                                                            <div class="location"><i class="fa fa-map-marker"
                                                                                     aria-hidden="true"></i>
                                                                {{searchhistorydata.city}}
                                                            </div>
                                                            <div class="investment-price">
                                                                <span *ngIf="searchhistorydata.priceLabel != 'Seeking Mentor'">
                                             <i class="fa fa-inr" aria-hidden="true"
                                                *ngIf="searchhistorydata.price != 'NA'"></i>
                                             {{(searchhistorydata.price != "NA") ? searchhistorydata.price  : ("Undisclosed")  }}
                                            </span>
                                                                <div>{{searchhistorydata?.priceLabel}}</div>
                                                            </div>
                                                            <div class="contact-btn"><a
                                                                    routerLink="/startup/{{searchhistorydata.startupurl}}/{{searchhistorydata.profileStr}}">Contact
                                                                Startup</a></div>
                                                        </div>
                                                    </div>

                                                    <div class="row investorlist"
                                                         *ngIf="searchhistorydata.profileTypeStr == 'Investor'">
                                                        <div class="col-xs-12 col-sm-7 col-md-7 borlef">
                                                            <div class="profile-pic">
                                                                <a href="#"><img
                                                                        src="{{searchhistorydata.investorProfPic}}"
                                                                        class="img-responsive"/></a>
                                                            </div>
                                                            <div class="profile-content">
                                                                <div class="iname">
                                                                    {{ (searchhistorydata.name !=='') ? ("Individual
                                                                    Investor") : ("Individual Investor") }}
                                                                </div>
                                                                <div class="desi">
                                                                    {{ (searchhistorydata.designation) ?
                                                                    searchhistorydata.designation : ("N/A") }},
                                                                    {{ (searchhistorydata.company) ?
                                                                    searchhistorydata.company : ("N/A") }},
                                                                    {{ (searchhistorydata.country !=='' &&
                                                                    searchhistorydata.country != 'NULL') ?
                                                                    searchhistorydata.country : ("India") }}
                                                                </div>
                                                                <div class="summary"><span>Summary:</span>
                                                                    {{ (searchhistorydata.summary !=='') ?
                                                                    searchhistorydata.summary : ("N/A") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-4 col-md-4 marlef">
                                                            <div class="locate"><i class="fa fa-map-marker"
                                                                                   aria-hidden="true"></i>
                                                                {{ (searchhistorydata.city !=='') ?
                                                                searchhistorydata.city : ("N/A") }}
                                                            </div>
                                                            <div class="othera"><span>Inv. Pref.:</span>
                                                                {{ (searchhistorydata.investmentPref !=='') ?
                                                                searchhistorydata.investmentPref : ("N/A") }}
                                                            </div>
                                                            <div class="othera"><span>Investment Size:</span>
                                                                <span>
                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                {{ (searchhistorydata.minInvestment !=='' && searchhistorydata.minInvestment != "0.0000") ? searchhistorydata.minInvestment : ("Undisclosed") }}
                                                - 
                                                {{ (searchhistorydata.maxInvestment !=='' && searchhistorydata.maxInvestment != "0.0000") ? searchhistorydata.maxInvestment : ("Undisclosed") }}
                                            </span>
                                                            </div>
                                                            <div class="contact-btn">
                                                                <a routerLink="/investor/{{searchhistorydata.investorurl}}/{{searchhistorydata.profileStr}}">Send
                                                                    Proposal</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row investorlist"
                                                         *ngIf="searchhistorydata.profileTypeStr == 'Mentor'">
                                                        <div class="col-xs-12 col-sm-7 col-md-7 borlef">
                                                            <div class="profile-pic">
                                                                <a href="#"><img src="assets/images/profile-dflt.jpg"
                                                                                 class="img-responsive"/></a>
                                                            </div>
                                                            <div class="profile-content">
                                                                <div class="iname">
                                                                    {{ (searchhistorydata.mentor_name !=='') ?
                                                                    searchhistorydata.mentor_name : ("N/A") }}
                                                                </div>
                                                                <div class="desi">
                                                                    {{ (searchhistorydata.mentor_designation !=='') ?
                                                                    searchhistorydata.mentor_designation : ("N/A") }},
                                                                    {{ (searchhistorydata.company !=='') ?
                                                                    searchhistorydata.company : ("N/A") }},
                                                                    {{ (searchhistorydata.mentor_company !=='' &&
                                                                    searchhistorydata.mentor_company != 'NULL') ?
                                                                    searchhistorydata.mentor_company : ("India") }}
                                                                </div>
                                                                <div class="summary"><span>Summary:</span>
                                                                    {{ (searchhistorydata.mentor_profile_summary !=='')
                                                                    ? searchhistorydata.mentor_profile_summary : ("N/A")
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-4 col-md-4 marlef">
                                                            <div class="locate"><i class="fa fa-map-marker"
                                                                                   aria-hidden="true"></i>
                                                                {{ (searchhistorydata.city !=='') ?
                                                                searchhistorydata.city : ("N/A") }}
                                                            </div>
                                                            <div class="othera"><span>Occupation:</span>
                                                                {{ (searchhistorydata.occupation !=='') ?
                                                                searchhistorydata.occupation : ("N/A") }}
                                                            </div>
                                                            <div class="othera"><span>Expertise:</span>
                                                                {{ (searchhistorydata.expertise !=='') ?
                                                                searchhistorydata.expertise : ("N/A") }}
                                                            </div>
                                                            <div class="othera"><span>Experience:</span>
                                                                {{ (searchhistorydata.year !=='') ?
                                                                searchhistorydata.year : ("N/A") }}
                                                            </div>
                                                            <div class="contact-btn">
                                                                <a routerLink="/mentor/{{searchhistorydata.mentorurl}}/{{searchhistorydata.profileStr}}">Send
                                                                    Proposal</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </li>
                                            </ul>
                                            <a [routerLink]="" *ngIf="bxProfileViewlength > searchlimit"
                                               id="bxProfileView" class="load-more"
                                               (click)="bxProfileViewLoadMore(bxProfileView)">LOAD MORE</a>
                                            <!-- Search History Business Listing End Here -->
                                        </div>
                                        <div *ngIf="bxProfileViewlength==0">
                                            No Search History Found.
                                        </div>

                                    </div>
                                    <!-- Search History Listing End Here -->


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- bootstrap-responsive tab -->


