
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 

                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->

                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                     Manage Preference Details
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/lenderConfidentials/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/lenderAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li *ngIf="bxLenderType?.type==2"><a routerLink="/dashboard/privateLender/{{profile_str}}">Private Lender Info</a></li>
                                        <li *ngIf="bxLenderType?.type==3"><a routerLink="/dashboard/nbfcLender/{{profile_str}}">NBFC Personnel Info </a></li>
                                        <li class="selected"><a routerLink="/dashboard/lenderPreference/{{profile_str}}">Preferences</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>

                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="lenderForm" #lenderForm="ngForm" (ngSubmit)="lenderForm.form.valid && lenderMyAccountSubmit(lenderForm.value)" class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="lenderProfileSuccessError" style="margin-bottom:20px; text-align:center;">{{lenderProfileSuccessError}}</div>
                                            <div class="msg-pnl_sales" *ngIf="lenderProfileFailError" style="margin-bottom:20px; text-align:center;">{{lenderProfileFailError}}</div>

                                            <div class="sec-slide-effect">

                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Sector Preference
                                                    <span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group multitag">
                                                        <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Enter your text"><i class="fa fa-info" aria-hidden="true"></i></div>
                                                        <!--<div style="position:relative;">-->
                                                            <input class="form-control" multiple="multiple" id="ind_pref" 
                                                                   name="ind_pref" #ind_pref="ngModel" ngModel style="display:none;">
                                                        <!--</div>-->
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location Preference
                                                    <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control LocationPrefrencetagit"
                                                               id="lenderlocationPreference1" placeholder="Enter Location Preference"
                                                               data-error="Please enter location." style="display:none;">
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                </div>
                                            </div>

                                            </div>

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
