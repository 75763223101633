
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BASE_URL } from '../../../../config/app-config.constants';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';
import { isPlatformBrowser, Location } from '@angular/common';
import * as _ from 'lodash';
import {faTimesCircle as faTimesCircle,faShareAlt as faShareAlt} from '@fortawesome/free-solid-svg-icons/'

declare var $: any;

@Component({
  selector: 'app-article-listing',
  templateUrl: './article-listing.component.html',
  styleUrls: ['./article-listing.component.css']
})


export class ArticleListingComponent implements OnInit {
  faShareAlt = faShareAlt;
  faTimesCircle = faTimesCircle;
  public title = 'Articles';
  public currentPage = 1;
  public itemsPerPage = 12;
  public orderBy = 'created_at';
  public commented = '';
  public time = '';
  public totalItems = 0;
  public bxarticlelisting = [];
  public bxarticlelistingRecent = [];
  public homeslider = [];
  public read;
  public bxbannertop;
  public bxbannerleft;
  public bxbannerrightside;
  public bxbannercenter;
  public bxbannerbottom;
  public bxbannertopmob;
  public bannerpage = 12;
  public tagSlug = '';
  public tag = '';
  obj = { Title: 'test', Url: 'test12' };
  pagination = false;
  @ViewChild(ListingPaginationComponent)
  private listingPagination: ListingPaginationComponent;

  constructor(private linkService: LinkService,
    private meta: Meta, private titleService: Title,
    private contactService: ContactService,
    private homeService: HomeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    private historyLocation: Location) {

    linkService.removeCanonicalLink();

    this.route.paramMap.subscribe((params: Params) => {
      const param1 = +this.route.snapshot.queryParams['page'];
      if (param1 !== undefined && param1 > 0) {
        this.currentPage = param1;
      }
      this.tagSlug = params.get('articleTagSlug');
      this.getArticleListing();
    });
  }


  getArticleListing() {
    if (this.pagination) {
      if (this.currentPage > 1) {
        history.pushState(this.obj, this.obj.Title, this.listingPagination.getPathString() + '?page=' + this.currentPage);
      } else {
        history.pushState(this.obj, this.obj.Title, this.listingPagination.getPathString());
      }
    }


    this.homeService.getArticlelisting(this.currentPage, this.itemsPerPage, this.time, this.read, this.commented, this.orderBy, this.tagSlug)
      .then(
        result => {
          this.bxarticlelisting = result.bxarticlelistingdata;
          //console.log(this.bxarticlelisting);

          //this.bxarticlelistingRecent = result.bxarticlelistingdata.sort((a, b) => a.article_id - b.article_id).slice(0,4)

          //this.homeslider = this.bxarticlelisting;
          //this.homeslider = this.homeslider.sort(() => Math.random() - 0.5)
          //console.log(this.homeslider);

          if (this.tagSlug !== '' && this.bxarticlelisting.length === 0) {
            this.router.navigate(['/article']);
          }
          this.totalItems = result.articleCount;
          this.setTitleMeta();
          this.currentPage = (this.bxarticlelisting.length === 0) ? 0 : this.currentPage;
        }
      );
  }

  shortByDate(orderBy) {
    this.pagination = true;
    this.currentPage = 1;
    this.orderBy = orderBy;
    this.getArticleListing();
    $('#recent').addClass('active');
    $('#read').removeClass('active');
    $('#comment').removeClass('active');
    $('#title').removeClass('active');

  }

  shortByRead(read) {
    this.pagination = true;
    this.currentPage = 1;
    this.orderBy = read;
    this.getArticleListing();
    $('#read').addClass('active');
    $('#recent').removeClass('active');
    $('#comment').removeClass('active');
    $('#title').removeClass('active');

  }

  shortByComment(commented) {
    this.pagination = true;
    this.currentPage = 1;
    this.orderBy = commented;
    this.getArticleListing();
    $('#comment').addClass('active');
    $('#read').removeClass('active');
    $('#recent').removeClass('active');
    $('#title').removeClass('active');

  }

  shortByAlpha(alpha) {
    this.pagination = true;
    this.currentPage = 1;
    this.orderBy = alpha;
    this.getArticleListing();
    $('#title').addClass('active');
    $('#read').removeClass('active');
    $('#comment').removeClass('active');
    $('#recent').removeClass('active');

  }

  goToPage(n: number): void {
    this.pagination = true;
    this.currentPage = n;
    this.getArticleListing();

  }

  onNext(): void {
    this.pagination = true;
    this.currentPage++;
    this.getArticleListing();
  }

  onPrev(): void {
    this.pagination = true;
    this.currentPage--;
    this.getArticleListing();
  }

  ngOnInit() {

  }

  articleList():void{

  }

  private setTitleMeta() {

    let title = 'BusinessEx Articles and Information, New Business Ideas - BusinessEx';
    let keywords = 'Business articles, Business articles and information';

    if (this.tagSlug !== null) {
      this.tag = appConfig.revertSlug(this.tagSlug);
      title = this.tag + ' Articles and ' + this.tag + ' Information, New Business Ideas - BusinessEx';
      keywords = 'Business articles,  ' + this.tag + '  articles and ' + this.tag + ' information';
    }

    this.titleService.setTitle(title);

    this.meta.updateTag({ name: 'Keywords', content: keywords });
    this.meta.updateTag({ name: 'Description',  content: 'Discover all the relevant ' + this.tag + ' information you need for start-ups, businesses, investments, mentorship, brokerage, lending, & incubation right here on BusinessEx.com article division. You need any information or want share your thoughts there is a comment section right below the ' + this.tag + ' article.' });
 

    /*this.meta.updateTag({ name: 'fb:pages', content: '84775983858482' });
    this.meta.updateTag({ name: 'fb:app_id', content: '59517604401875' });
    this.meta.updateTag({ name: 'original-source', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ property: 'site_name', content: 'BusinessEx' });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:url', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ property: 'og:image:width', content: '770' });
    this.meta.updateTag({ property: 'og:image:height', content: '434' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:url', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ name: 'twitter:site', content: '@BusinesEx' });
    this.meta.updateTag({ name: 'twitter:domain', content: 'businessex.com' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@businesex' });*/

    this.linkService.removeCanonicalLink();

    const totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 0;
    let pNumber = 0;

    if (this.currentPage > 1 && this.currentPage <= totalPages) {
      pNumber = this.currentPage - 1;
      this.linkService.addTag({
        rel: 'prev',
        href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
      });
    }

    this.linkService.addTag({
      rel: 'canonical',
      href: BASE_URL + this.historyLocation.path()
    });

    /*if (this.currentPage >= 1 && this.currentPage < totalPages) {
      pNumber = this.currentPage + 1;
      this.linkService.addTag({
        rel: 'next',
        href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
      });
    }*/


  }

}

