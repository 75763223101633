import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
//import { threadId } from 'worker_threads';
import { BrowserModule } from '@angular/platform-browser';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './components/app/app.component';
import {
  FrontendRoutingModule,
  routedComponents,
} from './frontend-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { APP_CONFIG, AppConfig } from '../../config/app-config.constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginGuard } from '../../helper/login-guard';
import { AuthInterceptor } from '../../helper/auth-interceptor';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoginComponent } from './components/login/login.component';
import { BusinessListingComponent } from './components/business-listing/business-listing.component';
import { InvestorListingComponent } from './components/investor-listing/investor-listing.component';
import { MentorListingComponent } from './components/mentor-listing/mentor-listing.component';
import { StartupListingComponent } from './components/startup-listing/startup-listing.component';
import { ListingPaginationComponent } from './components/listing-pagination/listing-pagination.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuickRegistrationComponent } from './components/quick-registration/quick-registration.component';
import { NouisliderModule } from 'ng2-nouislider';
import { PricingComponent } from './components/pricing/pricing.component';
import { AdminutilsService } from '../../services/adminutil.service';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { BusinessValuationComponent } from './components/business-valuation/business-valuation.component';
import { BusinessPlanComponent } from './components/business-plan/business-plan.component';
import { DuediligenceComponent } from './components/duediligence/duediligence.component';
import { ArticleComponent } from './components/article/article.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { InsightContentComponent } from './components/insight-content/insight-content.component';
import { ArticleListingComponent } from './components/article-listing/article-listing.component';
import { NewsListingComponent } from './components/news-listing/news-listing.component';
import { NewsComponent } from './components/news/news.component';
import { BexShortsComponent } from './components/bex-shorts/bex-shorts.component';
import { BusinessContactComponent } from './components/business-contact/business-contact.component';
import { StartupContactComponent } from './components/startup-contact/startup-contact.component';
import { InvestorContactComponent } from './components/investor-contact/investor-contact-component';
import { MentorContactComponent } from './components/mentor-contact/mentor-contact.component';
import { SellerProfileTypeFilter } from '../../pipes/seller-profile-type-filter.pipe';
import { InvestorProfileTypeFilter } from '../../pipes/investor-profile-type-filter.pipe';
import { NonPlatinumProfileTypeFilter } from '../../pipes/non-platinum-profile-type-filter.pipe';
import { ShufflePipe } from '../../pipes/shuffle';
import { ConfigService, Ng2UiAuthModule } from 'ng2-ui-auth';
import { UiAuthConfigBackendService } from '../../services/ui-auth-config-backend.service';
import { MyAuthConfig } from '../../config/my-auth-config';
import { DashboardModule } from '../dashboard/dashboard.module';
import { HerocrouselComponent } from './components/herocrousel/herocrousel.component';
import { FooterAmpComponent } from './components/footer-amp/footer-amp.component';
import { HeaderAmpComponent } from './components/header-amp/header-amp.component';
import { ContactComponent } from './model/contact.components';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { SharerMethod } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faTimesCircle as faTimesCircle,
  faShareAlt as faShareAlt,
} from '@fortawesome/free-solid-svg-icons/';

@NgModule({
  declarations: [
    routedComponents,
    HeaderComponent,
    AppComponent,
    FooterComponent,
    PageNotFoundComponent,
    LoginComponent,
    BusinessListingComponent,
    InvestorListingComponent,
    MentorListingComponent,
    StartupListingComponent,
    ListingPaginationComponent,
    QuickRegistrationComponent,
    PricingComponent,
    DisclaimerComponent,
    PrivacyComponent,
    TermsComponent,
    ContactUsComponent,
    BusinessValuationComponent,
    BusinessPlanComponent,
    DuediligenceComponent,
    ArticleComponent,
    InsightContentComponent,
    ArticleListingComponent,
    NewsListingComponent,
    NewsComponent,
    BexShortsComponent,
    BusinessContactComponent,
    StartupContactComponent,
    InvestorContactComponent,
    MentorContactComponent,
    SellerProfileTypeFilter,
    InvestorProfileTypeFilter,
    NonPlatinumProfileTypeFilter,
    ShufflePipe,
    HerocrouselComponent,
    ContactComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    FrontendRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NouisliderModule,
    Ng2UiAuthModule.forRoot(MyAuthConfig),
    DashboardModule,
    OverlayModule,
    ShareButtonsModule.withConfig({
      sharerMethod: SharerMethod.Window,
    }),
    ShareButtonsPopupModule,
    ShareIconsModule,
  ],
  bootstrap: [AppComponent],

  exports: [
    SellerProfileTypeFilter,
    InvestorProfileTypeFilter,
    NonPlatinumProfileTypeFilter,
    ShufflePipe,
  ],

  providers: [
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //{provide : LocationStrategy , useClass: HashLocationStrategy},
    LoginGuard,
    /*{provide: APP_INITIALIZER,
    useFactory: loadCustomerConfigFactory,
    deps: [AdminutilsService],
    multi: true
    }*/
    { provide: ConfigService, useClass: UiAuthConfigBackendService },
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FrontendModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faTimesCircle, faShareAlt);
  }
}

export function loadCustomerConfigFactory(
  myAdminutilsService: AdminutilsService
) {
  return () => myAdminutilsService.loadCustomerConfig();
}
