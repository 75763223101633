import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'keyspipe'})

export class KeysPipe implements PipeTransform {
    transform(value, arg = false): any {
        let keys = [];
        for (let key in value) {
            if (arg) {
                keys.push(value[key][0]);
            }else {
                keys.push({key: key, value: value[key]});
            }
        }
        return keys;
    }
}
