
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE CONFIDENTIAL INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li class="selected"><a routerLink="/dashboard/mentorConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/mentorAdvDetails/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/mentorDetails/{{profile_str}}">Profile Information</a></li>
                                        <li><a routerLink="/dashboard/mentorPreference/{{profile_str}}">Preferences </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="mentorForm" #mentorForm="ngForm"
                                              (ngSubmit)="mentorForm.form.valid && mentorMyAccountSubmit(mentorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="mentorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{mentorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="mentorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{mentorProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">
                                                <!-- <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Confidential Information</div></div>
                                                </div> -->
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Your
                                                        Name<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="mentor_name"
                                                                   [ngModel]="mentorConfidentialdata?.mentor_name"
                                                                   class="form-control" placeholder="Enter Your Name"
                                                                   data-error="Please enter name." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Mobile
                                                        Number<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="mentor_mobile"
                                                                   [ngModel]="mentorConfidentialdata?.mentor_mobile"
                                                                   pattern="[56789][0-9]{9}" class="form-control"
                                                                   placeholder="Enter Your Mobile Number"
                                                                   data-error="Please enter valid mobile number"
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Email
                                                        ID<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="email" name="mentor_email"
                                                                   [ngModel]="mentorConfidentialdata?.mentor_email"
                                                                   class="form-control"
                                                                   placeholder="Enter Your Email ID"
                                                                   data-error="Please enter email id." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text"
                                                                   minlength="3"
                                                                   name="mentor_city"
                                                                   [ngModel]="mentorConfidentialdata?.mentor_location"
                                                                   class="form-control"
                                                                   placeholder="Enter Your Location"
                                                                   (setAddress)="getAddress($event)"
                                                                   data-error="Please select location" googleplace
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->