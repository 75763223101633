import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'startup-location-details',
    templateUrl: './startup-location-details.component.html'
})
export class StartupLocationDetailsComponent implements OnInit, AfterViewChecked {

    profile_str;
    public startupLocationDetailsdata;
    public startupProfileSuccessError;
    public startupProfileFailError;
    private isViewChecked = false;
    public ofc_city;
    public ofc_state;
    public ofc_country;
    public ofc_location: Object;

    getAddress(place: Object) {
        this.ofc_location = place['formatted_address'];
        var location = place['geometry']['location'];
        this.ofc_city = place['address_components'][0].long_name;
        this.ofc_state = place['address_components'][2].short_name;
        this.ofc_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Startup Headquarter Location - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    startupMyAccountSubmit(data) {

        if ($('form[name="startupForm"]').validator('validate').has('.has-error').length) {
            return;
        }

        data.startup_profile_str = this.profile_str;
        data.ofc_city = this.ofc_city;
        data.ofc_state = this.ofc_state;
        data.ofc_country = this.ofc_country;

        this.http.post(this.config.apiEndpoint + '/updatestartuplocation/' + this.profile_str, data).subscribe(
            data => {
                console.log("Location Details Updated Successfully");
                $("#contactUsForm .form-control").val("");

                this.startupProfileSuccessError = "Location Details Updated Successfully";

                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);

            },
            error => {
                console.log("Please Enter Correct Details");
                console.log(error);
                this.startupProfileFailError = "Please Enter Correct Details";
            });
    }

    getstartupLocationDetails(profile_str: any): void {
        this.myprofileService.getstartupLocationDetails(this.profile_str)
            .then((result) => {
                this.startupLocationDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getstartupLocationDetails(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="startupForm"]').validator();
        }
    }

}
