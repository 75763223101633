
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">

                                <div class="cat-sec-ttl">
                                    <div *ngIf="userProfileType?.reg_profile == 'Other' || userProfileType?.reg_profile == null || userProfileType?.reg_profile == ''">
                                        MANAGE BUSINESS REQUIREMENTS INFORMATION
                                    </div>
                                    <div *ngIf="userProfileType?.reg_profile == 'Broker'">
                                        <div><a routerLink="/dashboard/brokerbusinesslisting">Business Listing</a></div>
                                        {{BusinessAdvDetailsdata?.advmt_headline}}
                                    </div>
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/businessConfidential/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/businessAdvDetails/{{profile_str}}">Advert Details</a></li>
                                        <li><a routerLink="/dashboard/businessInformation/{{profile_str}}">Business Info</a></li>
                                        <li><a routerLink="/dashboard/businessFinanceDetails/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/businessTeamDetails/{{profile_str}}">Team Details</a></li>
                                        <li><a routerLink="/dashboard/businessLocationDetails/{{profile_str}}">Headquarters</a></li>
                                        <li class="selected"><a routerLink="/dashboard/businessRequirement/{{profile_str}}">Requirements</a></li>
                                        <li><a routerLink="/dashboard/businessAttachment/{{profile_str}}">Attachments</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form #businessForm="ngForm"
                                              (ngSubmit)="businessMyAccountSubmit(businessForm.value)"
                                              class="form-horizontal" name="businessForm" role="form">

                                            <div class="msg-pnl_sales" *ngIf="businessProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{businessProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="businessProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{businessProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect" style="position:relative; z-index: 1;">
                                                <!-- <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Business Requirements</div></div>                                          
                                                </div> -->
                                                <div class="row form-group other-detail has-feedback">
                                                    <label class="col-xs-12 col-sm-3 col-md-4 control-label">I am
                                                        looking for<span class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-8 col-md-7" [ngClass]="{'has-error': isLookingFor==true}">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6">
                                                                <div class="checkbox input-group">
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                            type="checkbox" name="seeking_investors"
                                                                            [ngModel]="BusinessRequirementData?.requirement?.seeking_investors"
                                                                            value="invester" (change)="isChecked('seeking_investors','invester')"
                                                                            data-error="Please Select atleast one"
                                                                            #seeking_investors="ngModel" ngModel>Investors for my business</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                            type="checkbox" name="seeking_loan"
                                                                            [ngModel]="BusinessRequirementData?.requirement?.seeking_loan"
                                                                            (change)="isChecked('seeking_loan','loansseeking')"
                                                                            value="loansseeking" data-error="Please Select atleast one"
                                                                            #seeking_loan="ngModel" ngModel>Loan for my business</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                            type="checkbox" name="seeking_accelerators"
                                                                            [ngModel]="BusinessRequirementData?.requirement?.seeking_accelerators"
                                                                            value="incubatorsaccelerator" (change)="isChecked('seeking_accelerators','incubatorsaccelerator')"
                                                                            data-error="Please Select atleast one"
                                                                            #seeking_accelerators="ngModel" ngModel>Accelerators for my Business</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12">
                                                                        <input
                                                                            type="checkbox" name="seeking_buyers"
                                                                            [ngModel]="BusinessRequirementData?.requirement?.seeking_buyers"
                                                                            value="businesssale" (change)="isChecked('seeking_buyers','businesssale')"
                                                                            #seeking_buyers="ngModel" ngModel>Buyers for my business</label>
                                                                    <label class="col-xs-12 col-sm-12 col-md-12"
                                                                           data-error="Please Select atleast one">
                                                                        <input
                                                                            type="checkbox" name="seeking_mentors"
                                                                            [ngModel]="BusinessRequirementData?.requirement?.seeking_mentors"
                                                                            value="mentorsearch" (change)="isChecked('seeking_mentors','mentorsearch')"
                                                                            data-error="Please Select atleast one"
                                                                            #seeking_mentors="ngModel" ngModel>Mentorship for my business</label>
                                                                </div>
                                                            </div>
                                                            <!--<div class="col-xs-12 col-sm-6 col-md-6">
                                                                <div class="checkbox rm-pading-tp">

                                                                </div>
                                                            </div>-->
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="help-block with-errors" *ngIf="isLookingFor">Please Select atleast one</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">One line
                                                        pitch for your business<span class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="business_pitch"
                                                                      [ngModel]="BusinessRequirementData?.requirement.business_pitch"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="One line pitch for your business"
                                                                      data-error="Please fill out this field."
                                                                      required
                                                                      #business_pitch="ngModel" ngModel></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <!-- For Investor search -->
                                                <div class="lookingfr invester">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Partial Business
                                                            Sale (investors)
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Amount
                                                                of investment you are looking for<span
                                                                        class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                           name="inv_asking_price"
                                                                           [ngModel]="BusinessRequirementData?.requirement.inv_asking_price"
                                                                           placeholder="Enter Amount"
                                                                           #inv_asking_price="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business
                                                                stake of the investment<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                           name="inv_stake"
                                                                           [ngModel]="BusinessRequirementData?.requirement.inv_stake"
                                                                           placeholder="Enter Amount"
                                                                           #inv_stake="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Reason
                                                                for investment<span class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                           name="inv_reason"
                                                                           [ngModel]="BusinessRequirementData?.requirement.inv_reason"
                                                                           placeholder="Enter Your Reason for Investment"
                                                                           #inv_reason="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--For Loans seeking -->
                                                <div class="lookingfr loansseeking">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Loans seeking
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Collateral
                                                                details<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input name="loan_collateral_details"
                                                                           [ngModel]="BusinessRequirementData?.requirement.loan_collateral_details"
                                                                           type="text" class="form-control"
                                                                           placeholder="Enter details"
                                                                           #loan_collateral_details="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Loan
                                                                amount seeking<span class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="loan_amount"
                                                                           [ngModel]="BusinessRequirementData?.requirement.loan_amount"
                                                                           class="form-control"
                                                                           placeholder="Enter Amount"
                                                                           #loan_amount="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Possible
                                                                repayment period<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <select class="form-control"
                                                                            id="loan_repayment_period"
                                                                            name="loan_repayment_period"
                                                                            [ngModel]="BusinessRequirementData?.requirement.loan_repayment_period"
                                                                            data-error="Please fill out this field."
                                                                            #loan_repayment_period="ngModel" ngModel>
                                                                        <option *ngFor="let repayPeriod of repayment_period"
                                                                                [value]="repayPeriod.id"
                                                                                [selected]="repayPeriod.id == 'BusinessRequirementData.requirement.loan_repayment_period'">
                                                                            {{repayPeriod.name}}
                                                                        </option>
                                                                    </select>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Expected
                                                                Interest Rate<span class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="number" name="loan_interest_rate"
                                                                           [ngModel]="BusinessRequirementData?.requirement.loan_interest_rate"
                                                                           class="form-control" placeholder="Enter %"
                                                                           data-error="Please fill out this field."
                                                                           #loan_interest_rate="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Reason
                                                                for loan<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="loan_reason"
                                                                           [ngModel]="BusinessRequirementData?.requirement.loan_reason"
                                                                           class="form-control"
                                                                           placeholder="Enter Reason"
                                                                           #loan_reason="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- For Incubators / Accelerator -->
                                                <div class="lookingfr incubatorsaccelerator">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Incubators /
                                                            Accelerator
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Accelerator
                                                                requirement details<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                           name="accel_req_details"
                                                                           [ngModel]="BusinessRequirementData?.requirement.accel_req_details"
                                                                           placeholder="Enter your Req"
                                                                           #accel_req_details="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Investment
                                                                Requirement<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                           name="accel_inv_req"
                                                                           [ngModel]="BusinessRequirementData?.requirement.accel_inv_req"
                                                                           placeholder="Enter your Amount"
                                                                           #accel_inv_req="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Field
                                                                of support needed<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group multitag">
                                                                    <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Specify the subject expertise field that the incubator should support for your growth"><i class="fa fa-info" aria-hidden="true"></i></div>
                                                                    <input class="form-control" id="accel_support_industry" name="accel_support_industry" #accel_support_industry="ngModel" ngModel style="display: none">
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Time
                                                                period of support needed<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <select name="accel_time_period" class="form-control" id="accel_time_period"
                                                                            [ngModel]="BusinessRequirementData?.requirement.accel_time_period"
                                                                            data-error="Please fill out this field."
                                                                            #accel_time_period="ngModel" ngModel >
                                                                        <option *ngFor="let supportPeriod of support_period"
                                                                                [value]="supportPeriod.id"
                                                                                [selected]="supportPeriod.id == 'BusinessRequirementData.requirement.accel_time_period'">
                                                                            {{supportPeriod.name}}
                                                                        </option>
                                                                    </select>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- For full Business Sale -->
                                                <div class="lookingfr businesssale">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For full Business
                                                            Sale
                                                        </div>
                                                    </div>
                                                    <div class="input-sec">
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Selling
                                                                (Asking) Price<span class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" name="buyer_sell_price"
                                                                           [ngModel]="BusinessRequirementData?.requirement.buyer_sell_price"
                                                                           class="form-control"
                                                                           placeholder="Enter Asking Price"
                                                                           #buyer_sell_price="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Reason
                                                                for Selling<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                           name="buyer_sell_reason"
                                                                           [ngModel]="BusinessRequirementData?.requirement.buyer_sell_reason"
                                                                           placeholder="Enter Your Reason for selling"
                                                                           #buyer_sell_reason="ngModel" ngModel>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- For Mentor search -->
                                                <div class="lookingfr mentorsearch">
                                                    <div class="row frm-sec-ttl">
                                                        <div class="col-xs-12 col-sm-6 col-md-12">For Mentor search
                                                        </div>
                                                    </div>

                                                    <div class="input-sec">
                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Mentorship
                                                                Requirement Details<span class="star">*</span>
                                                                <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group">
                                                                    <textarea name="mentor_req_details"
                                                                              [ngModel]="BusinessRequirementData?.requirement.mentor_req_details"
                                                                              class="form-control" rows="3"
                                                                              minlength="15" maxlength="150"
                                                                              placeholder="Mentorship Requirement Details"
                                                                              data-error="Please fill out this field."
                                                                              #buyer_sell_reason="ngModel" ngModel></textarea>
                                                                    <span class="glyphicon form-control-feedback"
                                                                          aria-hidden="true"></span>
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>

                                                        <div class="row form-group has-feedback">
                                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Field
                                                                of Mentorship
                                                                <span class="star">*</span> <span>:</span></label>
                                                            <div class="col-xs-12 col-sm-6 col-md-7">
                                                                <div class="input-group multitag">
                                                                    <input class="form-control" id="mentor_industry" name="mentor_industry" #mentor_industry ="ngModel" ngModel style="display: none">
                                                                </div>
                                                                <div class="help-block with-errors"></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->

