

<!-- Start Body Part -->
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
<!--   profile_detail left page         -->
    <profile-detail (ProfileChange)="profileChangeHandler($event)"></profile-detail>
    
<!--   profile_detail left page         -->    
<div class="col-xs-12 col-sm-8 col-md-6 mdy-width-49">
    <div class="cat-list business-sec new-dash">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingThree">
                    <h4 class="panel-title">
                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <!-- <i class="more-less glyphicon glyphicon-plus"></i> -->
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                            My Profiles<span>( Create  |  Edit )</span>
                        </a>
                    </h4>
                </div>
                <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div class="panel-body">
                        <div class="profile-desc">
                            <div class="profile-ttl">Hi <span class="profile-ttl-name">Puneet Sharma</span>,</div>
                            <div class="profile-sht-desc">BusinessEx.com is a Business Networking platform for the Business, Investors,
                                Lenders & Mentors. Create a profile based on your requirements. </div>
                        </div>
                        <div class="row profile-lst">
                            <div class="col-xs-6 col-sm-4" *ngFor="let profile of userProfile; let last = last" [isLast]="last" (lastDone)="lastProfile()">
                                <div class="crt-profile" *ngIf="profile?.profileStatus ===-1">
                                    <div class="img-con">
                                        <img src="../assets/images/{{profile?.profileIcon}}">
                                    </div>
                                </div>
                                <div class="profile-comp" id="profile-comp{{profile.profileType}}" [attr.data-percent]="profile.completionPercentage" *ngIf="profile?.profileStatus !==-1">
                                    <div class="profile-info">
                                        <div class="profile-sec">
                                            <div class="plan">{{profile?.plan}}</div>
                                            <div class="complete-status">Completed</div>
                                            <div class="bdr-spc"></div>
                                            <div [ngClass]="(profile.profileStatus===1)?'active-status':'active-status pending'"
                                            >{{profileStatus[profile.profileStatus]}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sh-desc">
                                    {{profile.title}}
                                    <div class="action" *ngIf="profile?.profileStatus !==-1">
                                        (<a routerLink="{{profile.editUrl}}">EDIT</a>
                                        <span *ngIf="profile?.upgradeUrl">| <a routerLink="{{profile?.upgradeUrl}}">UPGRADE</a></span>
                                        )
                                    </div>
                                    <div class="action" *ngIf="profile?.profileStatus ===-1">
                                        (<a routerLink="{{profile.createUrl}}">CREATE</a>)
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="bdr cat-list business-sec">

<!-- Nav tabs -->
<ul class="nav nav-tabs responsive marginlr20" role="tablist">
<li role="presentation" class="active"><a [routerLink]="" data-target="#new-listings" aria-controls="new-listings" (click)="appConfig.pageScrollAndShortTitle(isViewChecked)" role="tab" data-toggle="tab">New listings</a></li>
<li role="presentation"><a [routerLink]="" data-target="#saved-searches" aria-controls="saved-searches" role="tab" (click)="appConfig.pageScrollAndShortTitle(isViewChecked)" data-toggle="tab">Saved Searches</a></li>
<li role="presentation" ><a [routerLink]="" data-target="#search-history" aria-controls="search-history" role="tab" (click)="appConfig.pageScrollAndShortTitle(isViewChecked)" data-toggle="tab">Search History</a></li>
</ul>
<!-- Tab panes -->

<!-- Tab inner List  code  start here  -->
<div class="tab-content cat-list responsive">
    
    <!-- New Listing Start Here -->
    <div role="tabpanel" class="tab-pane active" id="new-listings">
    <!-- Business Listing start here -->    
    <div>
    <!--<ul ng-if="profileTypeId == '2' && profileTypeId != '1'">-->        
    <ul>
    <li *ngFor="let newlistingdata of sellerNewListing | slice:0:requestlimit; let i=index; let last = last" [isLast]="last" (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">
    <div class="row">                
        <div class="col-xs-12 col-sm-5 col-md-5">
            <a routerLink="/business/{{newlistingdata.sellerurl}}/{{newlistingdata.profileStr}}" >
                <span *ngIf="newlistingdata.thumbimage == null"><img class="img-responsive" src="https://www.businessex.com/assets/images/category/small/{{newlistingdata.parentCatId}}.jpg" alt="Featured Business"/></span>
                <span *ngIf="newlistingdata.thumbimage != null && newlistingdata.thumbimage != ''"><img class="img-responsive" src="{{newlistingdata.thumbimage}}" alt="Featured Business"/></span>

                <div class="primiumtag" *ngIf="newlistingdata.membership_paid == 1">
                    <img *ngIf="newlistingdata.membership_plan==1" src="assets/images/premium.png">
                    <img *ngIf="newlistingdata.membership_plan==2" src="assets/images/gold.png">
                    <img *ngIf="newlistingdata.membership_plan==3" src="assets/images/platinum.png">

                    <span *ngIf="newlistingdata.membership_plan==1">Premium</span>
                    <span style="color:#000" *ngIf="newlistingdata.membership_plan==2">Gold</span>
                    <span style="color:#000" *ngIf="newlistingdata.membership_plan==3">Platinum</span>
                </div>
            </a>
        </div>
        <div class="col-xs-12 col-sm-7 col-md-7">                                                        
            <div class="cat">
                {{newlistingdata.industry}}                
            </div>
            <div class="cat-ttl ttl-ellipsis"><a routerLink="/business/{{newlistingdata.sellerurl}}/{{newlistingdata.profileStr}}">{{newlistingdata.title}}</a></div>
            <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{newlistingdata.location}}</div>
            <div class="investment-price">
                                            <span *ngIf="newlistingdata.priceLabel != 'Seeking Mentor'">
                                             <i class="fa fa-inr" aria-hidden="true" *ngIf="newlistingdata.price != 'NA'"></i>
                                             {{(newlistingdata.price != "NA") ? newlistingdata.price  : ("Undisclosed")  }}
                                            </span>
                <div>{{newlistingdata?.priceLabel}}</div>
            </div>

            <div class="contact-btn">
                <a routerLink="/business/{{newlistingdata.sellerurl}}/{{newlistingdata.profileStr}}">Contact business</a></div>
        </div>
    </div>
    </li>                
    </ul>
    <a [routerLink]="" *ngIf="requestlimit < sellerNewListing.length" class="load-more" (click)="sellerNewListingLoadMore(sellerNewListing)">LOAD MORE</a>
        <a class="load-more" *ngIf="requestlimit > sellerNewListing.length" routerLink="/businesslisting/">Know More</a>
    </div>
    <!-- Business Listing end here -->    
    </div>
    <!-- New Listing End Here -->
    
    <!-- Saved Searches Listing Start Here -->
    <div role="tabpanel" class="tab-pane" id="saved-searches">
        
    <div *ngIf="sellerSavedSearchleng>0">    

    <!-- Saved Search Business Listing Start Here -->
    <ul *ngIf="userID != ''">
    <li *ngFor="let savedsearchdata of sellerSavedSearch | slice:0:savedlimit; let i=index; let last = last" [isLast]="last" (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">
        
        <div class="row" *ngIf="savedsearchdata.profileTypeStr == 'Business'">
            <div class="col-xs-12 col-sm-5 col-md-5">
                <a routerLink="/business/{{savedsearchdata.sellerurl}}/{{savedsearchdata.profileStr}}" >
                    <span *ngIf="savedsearchdata.thumbimage == null"><img class="img-responsive" src="https://www.businessex.com/assets/images/category/small/{{savedsearchdata.parentCatId}}.jpg" alt="Featured Business"/></span>
                    <span *ngIf="savedsearchdata.thumbimage != null && savedsearchdata.thumbimage != ''"><img class="img-responsive" src="{{savedsearchdata.thumbimage}}" alt="Featured Business"/></span>

                    <div class="primiumtag" *ngIf="savedsearchdata.membership_paid == 1">
                        <img *ngIf="savedsearchdata.membership_plan==1" src="assets/images/premium.png">
                        <img *ngIf="savedsearchdata.membership_plan==2" src="assets/images/gold.png">
                        <img *ngIf="savedsearchdata.membership_plan==3" src="assets/images/platinum.png">

                        <span *ngIf="savedsearchdata.membership_plan==1">Premium</span>
                        <span style="color:#000" *ngIf="savedsearchdata.membership_plan==2">Gold</span>
                        <span style="color:#000" *ngIf="savedsearchdata.membership_plan==3">Platinum</span>
                    </div>

                    <!-- <img src="https://www.businessex.com/assets/images/category/small/{{savedsearchdata.parentCatId}}.jpg" class="img-responsive"/> -->
                </a>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-7">                                                        
                <div class="cat">
                    <span>
                        {{savedsearchdata.industry}}
                    </span>
                </div>
                <div class="cat-ttl ttl-ellipsis"><a routerLink="/business/{{savedsearchdata.sellerurl}}/{{savedsearchdata.profileStr}}">{{savedsearchdata.title}}</a></div>
                <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{savedsearchdata.city}}</div>
                <div class="investment-price">
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ (savedsearchdata.price !== '') ? savedsearchdata.price : ("N/A") }} 
                    <div>Asking Price</div>                        
                </div>                                                            
                <div class="contact-btn"><a routerLink="/business/{{savedsearchdata.sellerurl}}/{{savedsearchdata.profileStr}}">Contact business</a></div>
            </div>
        </div>
        
        <div class="row" *ngIf="savedsearchdata.profileTypeStr == 'Startup'">
            <div class="col-xs-12 col-sm-5 col-md-5">
                <a routerLink="/startup/{{savedsearchdata.startupurl}}/{{savedsearchdata.profileStr}}" >
                    <span *ngIf="savedsearchdata.thumbimage == null"><img class="img-responsive" src="https://www.businessex.com/assets/images/category/small/{{savedsearchdata.parentCatId}}.jpg" alt="Featured Business"/></span>
                    <span *ngIf="savedsearchdata.thumbimage != null && savedsearchdata.thumbimage != ''"><img class="img-responsive" src="{{savedsearchdata.thumbimage}}" alt="Featured Business"/></span>

                    <div class="primiumtag" *ngIf="savedsearchdata.membership_paid == 1">
                        <img *ngIf="savedsearchdata.membership_plan==1" src="assets/images/premium.png">
                        <img *ngIf="savedsearchdata.membership_plan==2" src="assets/images/gold.png">
                        <img *ngIf="savedsearchdata.membership_plan==3" src="assets/images/platinum.png">
                        <span *ngIf="savedsearchdata.membership_plan==1">Premium</span>
                        <span style="color:#000" *ngIf="savedsearchdata.membership_plan==2">Gold</span>
                        <span style="color:#000" *ngIf="savedsearchdata.membership_plan==3">Platinum</span>
                    </div>
                    <!-- <img src="https://www.businessex.com/assets/images/category/small/{{savedsearchdata.parentCatId}}.jpg" class="img-responsive"/> -->
                </a>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-7">                                                        
                <div class="cat">
                    <span>
                        {{savedsearchdata.industry}}
                    </span>
                </div>
                <div class="cat-ttl ttl-ellipsis"><a routerLink="/startup/{{savedsearchdata.startupurl}}/{{savedsearchdata.profileStr}}">{{savedsearchdata.title}}</a></div>
                <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{savedsearchdata.city}}</div>
                <div class="investment-price">
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ (savedsearchdata.price !== '') ? savedsearchdata.price : ("N/A") }} 
                    <div>Asking Price</div>                        
                </div>                                                            
                <div class="contact-btn"><a routerLink="/startup/{{savedsearchdata.startupurl}}/{{savedsearchdata.profileStr}}">Contact business</a></div>
            </div>
        </div>
        
        <div class="row investorlist" *ngIf="savedsearchdata.profileTypeStr == 'Investor'">
            <div class="col-xs-12 col-sm-7 col-md-7 borlef"> 
                <div class="profile-pic">
                    <a routerLink="/investor/{{savedsearchdata.investorurl}}/{{savedsearchdata.profileStr}}">
                        <img src="assets/images/profile-dflt.jpg" class="img-responsive"/>
                    </a>
                </div>
                <div class="profile-content">
                    <div class="iname">
                         {{ (savedsearchdata.name !=='') ? savedsearchdata.name : ("Individual Investor") }}
                    </div>
                    <div class="desi">
                        {{ (savedsearchdata.designation !=='') ? savedsearchdata.designation : ("N/A") }},
                        {{ (savedsearchdata.company !=='') ? savedsearchdata.company : ("N/A") }},
                        {{ (savedsearchdata.country !=='' && savedsearchdata.country != 'NULL') ? savedsearchdata.country : ("India") }}
                    </div>
                    <div class="summary sum-ellipsis"><span>Summary:</span> 
                        {{ (savedsearchdata.summary !=='') ? savedsearchdata.summary : ("N/A") }}                                               
                    </div>                        
                </div> 
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 marlef">    
                <div class="locate"><i class="fa fa-map-marker" aria-hidden="true"></i> 
                    {{ (savedsearchdata.city !=='') ? savedsearchdata.city : ("N/A") }}
                </div>
                <div class="othera"><span>Inv. Pref.:</span> 
                    {{ (savedsearchdata.investmentPref !=='') ? savedsearchdata.investmentPref : ("N/A") }}
                </div>
                <div class="othera"><span>Investment Size:</span> 
                    <span>
                        <i class="fa fa-inr" aria-hidden="true"></i>
                        {{ (savedsearchdata.minInvestment !=='') ? savedsearchdata.minInvestment : ("N/A") }}
                         - 
                        {{ (savedsearchdata.maxInvestment !=='') ? savedsearchdata.maxInvestment : ("N/A") }}
                    </span>
                </div>
                <div class="contact-btn">
                    <a routerLink="/investor/{{savedsearchdata.investorurl}}/{{savedsearchdata.profileStr}}">Send Proposal</a>
                </div>   
            </div>
        </div>
        
        <div class="row investorlist" *ngIf="savedsearchdata.profileTypeStr == 'Mentor'">
            <div class="col-xs-12 col-sm-7 col-md-7 borlef">
                <div class="profile-pic">
                    <a routerLink="/mentor/{{savedsearchdata.mentorurl}}/{{savedsearchdata.profileStr}}">
                        <img src="assets/images/profile-dflt.jpg" class="img-responsive"/>
                    </a>
                </div>
                <div class="profile-content">
                    <div class="iname">
                        {{ (savedsearchdata.mentor_name !=='') ? savedsearchdata.mentor_name : ("N/A") }}
                    </div>
                    <div class="desi">
                        {{ (savedsearchdata.mentor_designation !=='') ? savedsearchdata.mentor_designation : ("N/A") }},
                        {{ (savedsearchdata.company !=='') ? savedsearchdata.company : ("N/A") }},
                        {{ (savedsearchdata.mentor_company !=='' && savedsearchdata.mentor_company != 'NULL') ? savedsearchdata.mentor_company : ("India") }}
                    </div>
                    <div class="summary sum-ellipsis"><span>Summary:</span>
                        {{ (savedsearchdata.mentor_profile_summary !=='') ? savedsearchdata.mentor_profile_summary : ("N/A") }}
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 marlef">
                <div class="locate"><i class="fa fa-map-marker" aria-hidden="true"></i>
                    {{ (savedsearchdata.city !=='') ? savedsearchdata.city : ("N/A") }}
                </div>
                <div class="othera"><span>Occupation:</span>
                    {{ (savedsearchdata.occupation !=='') ? savedsearchdata.occupation : ("N/A") }}
                </div>
                <div class="othera"><span>Expertise:</span>
                    {{ (savedsearchdata.expertise !=='') ? savedsearchdata.expertise : ("N/A") }}
                </div>
                <div class="othera"><span>Experience:</span>
                    {{ (savedsearchdata.year !=='') ? savedsearchdata.year : ("N/A") }}
                </div>
                <div class="contact-btn">
                    <a routerLink="/mentor/{{savedsearchdata.mentorurl}}/{{savedsearchdata.profileStr}}">Send Proposal</a>
                </div>
            </div>
        </div>
        
    </li>                
    </ul>
    <a [routerLink]="" *ngIf="savedlimit < sellerSavedSearch.length" id="sellerSavedSearch" class="load-more" (click)="sellerSavedSearchLoadMore(sellerSavedSearch)">LOAD MORE</a>
    </div>    
    <div class="nodata" *ngIf="sellerSavedSearchleng==0 || sellerSavedSearchleng == ''">        
     No Saved Searches Record Found.
    </div>    
    
    <!-- Saved Search Business Listing End Here -->
    </div>
    <!-- Saved Searches Listing End Here -->
    
    <!-- Search History Listing Start Here -->
    <div role="tabpanel" class="tab-pane" id="search-history">        
    <!-- Search History Business Listing Start Here --> 
    
   
    <div *ngIf="sellerSearchHistoryleng>0">    
    <ul *ngIf="userID != ''">
    <li *ngFor="let searchhistorydata of sellerSearchHistory | slice:0:searchlimit; let i=index; let last = last" [isLast]="last" (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">
        
        <div class="row" *ngIf="searchhistorydata.profileTypeStr == 'Business'">                
            <div class="col-xs-12 col-sm-5 col-md-5">
               <a routerLink="/business/{{searchhistorydata.sellerurl}}/{{searchhistorydata.profileStr}}" >
                   <span *ngIf="searchhistorydata.thumbimage == null"><img class="img-responsive" src="assets/images/category/small/{{searchhistorydata.parentCatId}}.jpg" alt="Featured Business"/></span>
                   <span *ngIf="searchhistorydata.thumbimage != null && searchhistorydata.thumbimage != ''"><img class="img-responsive" src="{{searchhistorydata.thumbimage}}" alt="Featured Business"/></span>
                   <div class="primiumtag" *ngIf="searchhistorydata.membership_paid == 1">
                       <img *ngIf="searchhistorydata.membership_plan==1" src="assets/images/premium.png">
                       <img *ngIf="searchhistorydata.membership_plan==2" src="assets/images/gold.png">
                       <img *ngIf="searchhistorydata.membership_plan==3" src="assets/images/platinum.png">
                       <span *ngIf="searchhistorydata.membership_plan==1">Premium</span>
                       <span style="color:#000" *ngIf="searchhistorydata.membership_plan==2">Gold</span>
                       <span style="color:#000" *ngIf="searchhistorydata.membership_plan==3">Platinum</span>
                   </div>
                   <!-- <img src="assets/images/category/small/{{searchhistorydata.parentCatId}}.jpg" class="img-responsive"/> -->
               </a> 
            </div>
            <div class="col-xs-12 col-sm-7 col-md-7">                                                        
                <div class="cat">
                    {{searchhistorydata.industry}}
                </div>
                <div class="cat-ttl ttl-ellipsis"><a routerLink="/business/{{searchhistorydata.sellerurl}}/{{searchhistorydata.profileStr}}">{{searchhistorydata.title}}</a></div>
                <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{ (searchhistorydata.city !== '') ? searchhistorydata.city : ("N/A") }}</div>
                <div class="investment-price">
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ (searchhistorydata.price !== '') ? searchhistorydata.price : ("N/A") }} 
                    <div>Asking Price</div>                        
                </div>                                                            
                <div class="contact-btn"><a routerLink="/business/{{searchhistorydata.sellerurl}}/{{searchhistorydata.profileStr}}">Contact business</a></div>
            </div>
        </div>
                
        <div class="row" *ngIf="searchhistorydata.profileTypeStr == 'Startup'">                
            <div class="col-xs-12 col-sm-5 col-md-5">
                <a routerLink="/startup/{{searchhistorydata.startupurl}}/{{searchhistorydata.profileStr}}" >
                    <span *ngIf="searchhistorydata.thumbimage == null"><img class="img-responsive" src="assets/images/category/small/{{searchhistorydata.parentCatId}}.jpg" alt="Featured Business"/></span>
                    <span *ngIf="searchhistorydata.thumbimage != null && searchhistorydata.thumbimage != ''"><img class="img-responsive" src="{{searchhistorydata.thumbimage}}" alt="Featured Business"/></span>
                    <div class="primiumtag" *ngIf="searchhistorydata.membership_paid == 1">
                        <img *ngIf="searchhistorydata.membership_plan==1" src="assets/images/premium.png">
                        <img *ngIf="searchhistorydata.membership_plan==2" src="assets/images/gold.png">
                        <img *ngIf="searchhistorydata.membership_plan==3" src="assets/images/platinum.png">
                        <span *ngIf="searchhistorydata.membership_plan==1">Premium</span>
                        <span style="color:#000" *ngIf="searchhistorydata.membership_plan==2">Gold</span>
                        <span style="color:#000" *ngIf="searchhistorydata.membership_plan==3">Platinum</span>
                    </div>
                    <!-- <img src="assets/images/category/small/{{searchhistorydata.parentCatId}}.jpg" class="img-responsive"/> -->
                </a>                 
            </div>
            <div class="col-xs-12 col-sm-7 col-md-7">                                                        
                <div class="cat">
                    <span>
                        {{searchhistorydata.industry}}
                    </span>
                </div>
                <div class="cat-ttl ttl-ellipsis"><a routerLink="/startup/{{searchhistorydata.startupurl}}/{{searchhistorydata.profileStr}}">{{searchhistorydata.title}}</a></div>
                <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{searchhistorydata.city}}</div>
                <div class="investment-price">
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {{ (searchhistorydata.price !== '') ? searchhistorydata.price : ("N/A") }} 
                    <div>Asking Price</div>                        
                </div>                                                            
                <div class="contact-btn"><a routerLink="/startup/{{searchhistorydata.startupurl}}/{{searchhistorydata.profileStr}}">Contact business</a></div>
            </div>
        </div>
        
        <div class="row investorlist" *ngIf="searchhistorydata.profileTypeStr == 'Investor'">
            <div class="col-xs-12 col-sm-7 col-md-7 borlef"> 
                <div class="profile-pic">
                    <a routerLink="/investor/{{searchhistorydata.investorurl}}/{{searchhistorydata.profileStr}}">
                        <img src="assets/images/profile-dflt.jpg" class="img-responsive"/>
                    </a>
                </div>
                <div class="profile-content">
                    <div class="iname">
                         {{ (searchhistorydata.name !=='') ? searchhistorydata.name : ("Individual Investor") }}
                    </div>
                    <div class="desi">
                        {{ (searchhistorydata.designation !=='') ? searchhistorydata.designation : ("N/A") }},
                        {{ (searchhistorydata.company !=='') ? searchhistorydata.company : ("N/A") }},
                        {{ (searchhistorydata.country !=='' && searchhistorydata.country != 'NULL') ? searchhistorydata.country : ("India") }}
                    </div>
                    <div class="summary sum-ellipsis"><span>Summary:</span> 
                        {{ (searchhistorydata.summary !=='') ? searchhistorydata.summary : ("N/A") }}                                               
                    </div>                        
                </div> 
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 marlef">    
                <div class="locate"><i class="fa fa-map-marker" aria-hidden="true"></i> 
                    {{ (searchhistorydata.city !=='') ? searchhistorydata.city : ("N/A") }}
                </div>
                <div class="othera"><span>Inv. Pref.:</span> 
                    {{ (searchhistorydata.investmentPref !=='') ? searchhistorydata.investmentPref : ("N/A") }}
                </div>
                <div class="othera"><span>Investment Size:</span> 
                    <span>
                        <i class="fa fa-inr" aria-hidden="true"></i>
                        {{ (searchhistorydata.minInvestment !=='') ? searchhistorydata.minInvestment : ("N/A") }}
                         - 
                        {{ (searchhistorydata.maxInvestment !=='') ? searchhistorydata.maxInvestment : ("N/A") }}
                    </span>
                </div>
                <div class="contact-btn">
                    <a routerLink="/investor/{{searchhistorydata.investorurl}}/{{searchhistorydata.profileStr}}">Send Proposal</a>
                </div>   
            </div>
        </div>        
        
        <div class="row investorlist" *ngIf="searchhistorydata.profileTypeStr == 'Mentor'">
        <div class="col-xs-12 col-sm-7 col-md-7 borlef">
            <div class="profile-pic">
                <a routerLink="/mentor/{{searchhistorydata.mentorurl}}/{{searchhistorydata.profileStr}}">
                    <img src="assets/images/profile-dflt.jpg" class="img-responsive"/>
                </a>
            </div>
            <div class="profile-content">
                <div class="iname">
                    {{ (searchhistorydata.mentor_name !=='') ? searchhistorydata.mentor_name : ("N/A") }}
                </div>
                <div class="desi">
                    {{ (searchhistorydata.mentor_designation !=='') ? searchhistorydata.mentor_designation : ("N/A") }},
                    {{ (searchhistorydata.company !=='') ? searchhistorydata.company : ("N/A") }},
                    {{ (searchhistorydata.mentor_company !=='' && searchhistorydata.mentor_company != 'NULL') ? searchhistorydata.mentor_company : ("India") }}
                </div>
                <div class="summary sum-ellipsis"><span>Summary test:</span>
                    {{ (searchhistorydata.mentor_profile_summary !=='') ? searchhistorydata.mentor_profile_summary : ("N/A") }}
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 marlef">
            <div class="locate"><i class="fa fa-map-marker" aria-hidden="true"></i>
                {{ (searchhistorydata.city !=='') ? searchhistorydata.city : ("N/A") }}
            </div>
            <div class="othera"><span>Occupation:</span>
                {{ (searchhistorydata.occupation !=='') ? searchhistorydata.occupation : ("N/A") }}
            </div>
            <div class="othera"><span>Expertise:</span>
                {{ (searchhistorydata.expertise !=='') ? searchhistorydata.expertise : ("N/A") }}
            </div>
            <div class="othera"><span>Experience:</span>
                {{ (searchhistorydata.year !=='') ? searchhistorydata.year : ("N/A") }}
            </div>
            <div class="contact-btn">
                <a routerLink="/mentor/{{searchhistorydata.mentorurl}}/{{searchhistorydata.profileStr}}">Send Proposal</a>
            </div>
        </div>
        </div>
                
    </li>                
    </ul>
    <a [routerLink]="" *ngIf="searchlimit < sellerSearchHistory.length" id="sellerSearchHistory" class="load-more" (click)="sellerSearchHistoryLoadMore(sellerSearchHistory)">LOAD MORE</a>
    <!-- Search History Business Listing End Here -->      
    </div>    
    <div class="nodata" *ngIf="sellerSearchHistoryleng==0">
        No Search History Record Found.
    </div>
    
    </div>
    <!-- Search History Listing End Here -->
    
</div> 
<!-- Tab inner List  code  start here  -->

</div>                                                                                      
</div>
<!-- Investor profile pic -->

<!-- My Profile Right Section Start Here -->
<right-section [data]="ProfileSelected" ></right-section>
<!-- My Profile Right Section End Here -->

</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->

<!-- Verify your Mobile Number -->
<div class="modal fade lg-panel" id="verified-ph" role="dialog" aria-labelledby="exampleModalLabel">
<div class="modal-dialog" role="document">
  <div class="modal-content">

    <form id="mobilenoverify" name="mobileVerifyForm" #mobileVerifyForm="ngForm" (ngSubmit)="mobileVerifyForm.form.valid && saveMobileVerifyDetail(mobileVerifyForm.value);" class="form-block" role="form">
       <div class="modal-header">
          <h4 class="modal-title">Verify Mobile Number</h4>
        </div>
        <div class="modal-body">
            <div class="edit-info">                    
                <div class="form-group" id="mobnumber"> 
                  <label for="mobilenumber">Mobile Number</label>
                  
                  <input type="text" 
                  pattern="[56789][0-9]{9}"
                  minlength="10"
                  maxlength="10" 
                  name="mobile" 
                  #mobile="ngModel" 
                  ngModel class="form-control" 
                  placeholder="Enter Your Mobile Number" 
                  data-error="Please enter valid mobile number" required>   
                  
                </div>                  
            </div>
        </div>
        <div class="modal-footer">                
          <button type="submit" class="btn btn-primary">Submit</button>
          <!-- <div class="msg-pnl" *ngIf="mobileError">{{mobileError}}</div> -->
          <div *ngIf="mobileError" class="alert alert-danger" style="margin-top:10px; text-align:center;">{{mobileError}}</div> 
        </div>
    </form>
    <form id="mobilecodeverify" class="form-block" name="mobileCodeVerifyForm" #mobileCodeVerifyForm="ngForm" (ngSubmit)="mobileCodeVerifyForm.form.valid && saveMobileOTPVerifyDetail(mobileCodeVerifyForm.value);" style="display:none;" role="form">
        <div class="modal-header">          
          <h4 class="modal-title">Enter One Time Password</h4>
        </div>
        <div class="modal-body">
            <div class="edit-info">
                <div class="form-group">
                    One Time Password (OTP) has been sent to your mobile number, please enter the same here to verify. 
                </div>
                <div class="form-group" id="mobcode">
                  <label for="mobilenumber">Enter OTP</label>
                  
                  <input type="text" 
                  pattern="[0-9]{4}" 
                  minlength="1"
                  maxlength="4" 
                  name="sms_otp" 
                  #sms_otp="ngModel" 
                  ngModel class="form-control" 
                  placeholder="Enter OTP" 
                  data-error="Please enter valid OTP" required>  
                
                </div>  
            </div>
        </div>
        <div class="modal-footer">                
          <button type="submit" class="btn btn-primary">Submit</button>
          <div *ngIf="mobileCodeError" class="alert alert-danger" style="margin-top:10px; text-align:center;">{{mobileCodeError}}</div>
        </div>
    </form> 
    
    <div id="thanksverification" style="display:none;">
    <div class="modal-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span></button>
      <h4 class="modal-title">Mobile Number Verification</h4>
    </div>
    <div class="modal-body">
        <div class="edit-info">                    
            You mobile no has been verified successfully !
        </div>
    </div>
    </div>       
      
  </div>
</div>
</div>
<!-- bootstrap modal static -->



