import {Component, Inject} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ContactService} from '../../../../services/contact.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from "../../../../config/app-config.constants";
import {IAppConfig} from "../../../../config/app-config.interface";
import {JwtHelperService} from '@auth0/angular-jwt';


declare var $: any;

@Component({
    selector: 'lender-profile',
    templateUrl: './lender-profile.component.html'
})
export class MyLenderProfileComponent {

    profile_str;
    jwtHelper = new JwtHelperService();
    users = {};
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileType = this.decode.userProfile;
    profileObjects = this.profileType;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;

    public lenderProfileData;

    constructor(meta: Meta, title: Title, private http: HttpClient, private contactService: ContactService, private myprofileService: MyProfileService, route: ActivatedRoute, @Inject(APP_CONFIG) private config: IAppConfig) {
        title.setTitle('Lender Profile Details - BusinessEx');


        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );

        this.getLenderContactDetails(this.profile_str);

    }

    getLenderContactDetails(profile_str: any): void {
        this.contactService.getLenderContactDetails(this.profile_str)
            .then((result) => {
                this.lenderProfileData = result;
            });
    }

}
