import {AfterViewChecked, Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ContactService} from '../../../../services/contact.service';
import {appConfig} from '../../../frontend/app.config';
import {isPlatformBrowser} from '@angular/common';
import {PROFILE_ARRAY, PROFILE_TYPE} from '../../../../config/app-config.constants';

declare var $: any;

@Component({
    selector: 'right-section',
    templateUrl: './right-section.component.html'
})
export class RightSectionComponent implements OnInit, AfterViewChecked, OnChanges {

    jwtHelper = new JwtHelperService();
    users = {};
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileType = this.decode.userProfile;
    profileObjects = this.profileType;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    public sellerRecommend: any = [];
    public investorRecommend: any = [];
    public startupRecommend: any = [];
    public ProfileSelected;
    public lenderRecommend;
    public bxbannertop = '';
    public bxbannerleft = '';
    public bxbannerrightside = '';
    public bxbannercenter = '';
    public bxbannerbottom = '';
    public bannerpage = 17;
    public appConfig = appConfig;
    public isViewChecked = false;
    @Input() data: any;
    public userRegisterProfileType = '';

    constructor(private contactService: ContactService,
                private myprofileService: MyProfileService,
                @Inject(PLATFORM_ID) private platformId: Object) {
    }

    typecheck() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.userRegisterProfileType = localStorage.getItem('userRegisterProfileType');
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
        }
    }

    getBanners(): void {
        this.contactService.getBanners(this.bannerpage)
            .then((bxresult) => {
                this.bxbannerbottom = bxresult.bottom;
                this.bxbannercenter = bxresult.middle;
                this.bxbannerleft = bxresult.left;
                this.bxbannerrightside = bxresult.right;
                this.bxbannertop = bxresult.top;
            });
    }

    getrecommendSellers(): void {
        this.myprofileService.getrecommendSellers(this.userID, this.ProfileSelected.profile_type)
            .then((result) => {
                this.sellerRecommend = result.seller;
                
                console.log(result.seller);
                console.log(result.startup);

                if (result.startup !== '') {
                    this.startupRecommend = result.startup;
                }
            });
    }

    getrecommendInvestors(): void {
        this.myprofileService.getrecommendInvestors(this.userID, this.ProfileSelected.profile_type)
            .then((result) => {
                this.investorRecommend = result.investor;
                if (result.lender !== '') {
                    this.lenderRecommend = result.lender;
                }
            });
    }

    ngOnInit() {
        this.typecheck();
        this.getBanners();
        this.getRecommendation(this.ProfileSelected.profile_type);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
        }
    }

    /*Please don't delete this method*/
    ngOnChanges(changes: SimpleChanges) {
        // only run when property "data" changed n will not be called on page load
        if (changes['data'] && this.isViewChecked) {
            this.typecheck();
            this.getBanners();
            this.getRecommendation(changes['data'].currentValue.profile_type);
        }
    }

    private getRecommendation(pType: any | string | string) {
        // recommended sellers are not required in case of broker
        if (this.userRegisterProfileType !== '' && this.userRegisterProfileType !== 'Broker') {
            // fetching recommended sellers, if selected profile is Investor/Lender/Mentor
            if (pType === PROFILE_TYPE.Investor || pType === PROFILE_TYPE.Lender || pType === PROFILE_TYPE.Mentor || pType === '') {
                this.getrecommendSellers();
            }
        }
        // fetching recommended investors, if selected profile is Business/Startup/Broker
        if (pType === PROFILE_TYPE.Business || pType === PROFILE_TYPE.Startup || pType === PROFILE_TYPE.Broker) {
            this.getrecommendInvestors();
        }
    }

}
