import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'projects/frontend/src/app/services/alert.service';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { appConfig } from '../../app.config';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-quick-registration',
  templateUrl: './quick-registration.component.html',
  styleUrls: ['./quick-registration.component.css']
})
export class QuickRegistrationComponent implements OnInit {
  quickRegistration: FormGroup;
  submitted: boolean;
  formSubmitted:boolean;
  dataResponse: any;
  submitMessage: any;

  constructor(
    private homeService: HomeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService:AlertService
  ) { }

  initQrForm() {

    this.quickRegistration = this.formBuilder.group(
        {
        business_name: ['', Validators.required],
        s_name: ['', Validators.required],
        s_mobile: ['', Validators.required],
        s_email: ['', [Validators.required,Validators.email]],
        s_company: ['', Validators.required],
      }
    );

  }

  get qRform() { return this.quickRegistration.controls; }
  
  ngOnInit(): void {
    this.initQrForm();
  }

  qRFormSubmit() {
    this.submitted = true;
    if (this.quickRegistration.invalid) {
      return;
    }

    this.formSubmitted =  true;
    let data = {
      'business_name': this.quickRegistration.controls.business_name.value,
      's_name': this.quickRegistration.controls.s_name.value,
      's_mobile': this.quickRegistration.controls.s_mobile.value,
      's_email': this.quickRegistration.controls.s_email.value,
      's_company': this.quickRegistration.controls.s_company.value,
    }

    let endPointUrl = 'salesSellerProfile'
      switch(this.quickRegistration.controls.business_name.value){
        case('sell'):
        endPointUrl = 'salesSellerProfile';
          break;
        case('start-up'):
        endPointUrl = 'salesStartupProfile';
          break;
        case('invest'):
        endPointUrl = 'salesInvestorProfile';
          break;
        case('mentor'):
          endPointUrl = 'salesMentorProfile';
          break;
        case('incubate'):
          endPointUrl = 'salesIncubatorProfile';
          break;
        default:
          endPointUrl ='salesSellerProfile';
          break;
      }

      this.homeService.quickRegistration(endPointUrl,data).subscribe(
        dataResponse => {
            this.dataResponse = dataResponse;
            appConfig.setNewProfileDetails(this.dataResponse.data.user_id, this.dataResponse.data.profile_type,this.dataResponse.data.profile_id);
            this.router.navigate(['/registration/quick-registration-thankyou']);
            this.formSubmitted =  false;
        },
        error => {
            this.formSubmitted =  false;
            this.quickRegistration.reset()
            this.handleError(error);
        }
      );
    
  }


  handleError(error) {
    this.submitMessage = error.error;
    //this.alertService.error('Hello');
    
    /*$('html, body').animate({
        // awc is an example. Use the id of your destination on the page
        scrollTop: $('#awc').offset().top - 450
    }, 'slow');*/

    setTimeout(function () {
        $('.msg-pnl_sales').delay(0).slideToggle('slow');
        $('.msg-pnl_sales').delay(5000).slideToggle('slow');
    }, 50);

    // showing login popup if user already exist. Don't change the message
    /*if (error.error === 'User already exists. Kindly login and create profile') {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                $('#modalLogin_sales').modal({
                    backdrop: 'static',
                    keyboard: false
                });
            }, 3000);
        }
    }*/
}


}
