import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'private-lender',
    templateUrl: './private-lender.component.html'
})
export class PrivateLenderComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;

    profile_str;
    public privateLenderdata;
    public lenderProfileSuccessError;
    public lenderProfileFailError;
    public bxLenderType;

    private isViewChecked = false;

    files;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Pivate Lender Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    lenderMyAccountSubmit(data) {
        if ($('form[name="lenderForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.lender_profile_str = this.profile_str;
        const formData: any = new FormData();
        if (this.files != undefined) {
            formData.append('lender_profile_pic_path', this.files[0], this.files[0].name);
            //console.log(this.files[0]);
        }
        if (data.profile_pic_path_old != '' && data.profile_pic_path_old != null) {
            formData.append('profile_pic_path_old', data.profile_pic_path_old);
            //console.log(data.profile_pic_path_old);
        }
        formData.append('lender_profile_str', this.profile_str);
        formData.append('lender_occupation', data.lender_occupation);
        formData.append('lending_capacity', data.lending_capacity);
        formData.append('lending_interest_rate', data.lending_interest_rate);
        formData.append('prof_summary', data.prof_summary);

        this.http.post(this.config.apiEndpoint + '/updateprivatelenderdetails/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Private Lender Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.lenderProfileSuccessError = data;
                $("html, body").animate({scrollTop: 0}, "slow");
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.lenderProfileFailError = "Please Enter Correct Details";
            });
    }

    getPrivatelender(profile_str: any): void {
        this.myprofileService.getprivateLender(this.profile_str)
            .then((result) => {
                this.privateLenderdata = result;
            });
    }

    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.files = fileInput.target.files;
        }
    }

    getcheckBxLenderType(userID: any, profile_str: any): void {
        this.myprofileService.getcheckBxLenderType(this.userID, this.profile_str)
            .then((result) => {
                this.bxLenderType = result;
            });
    }

    ngOnInit() {
        this.getPrivatelender(this.profile_str);
        this.getcheckBxLenderType(this.userID, this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="lenderForm"]').validator();
        }
    }

}
