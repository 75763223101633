
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE MENTOR INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/mentorConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/mentorAdvDetails/{{profile_str}}">Advertisement Details</a></li>
                                        <li  class="selected"><a routerLink="/dashboard/mentorDetails/{{profile_str}}">Profile Information</a></li>
                                        <li><a routerLink="/dashboard/mentorPreference/{{profile_str}}">Preferences </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="mentorForm" #mentorForm="ngForm"
                                              (ngSubmit)="mentorForm.form.valid && mentorMyAccountSubmit(mentorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="mentorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{mentorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="mentorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{mentorProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">For Mentor</div>
                                                    </div>
                                                </div>

                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Occupation<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="mentor_occupation"
                                                                    [ngModel]="mentorDetailsdata?.mentor_occupation"
                                                                    data-error="Please select occupation." required>
                                                                <option *ngFor="let industry of industry_sector"
                                                                        [value]="industry.id"
                                                                        [selected]="industry.id == 'mentorDetailsdata.mentor_occupation'">
                                                                    {{industry.name}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company /
                                                        Institute<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="mentor_company"
                                                                   [ngModel]="mentorDetailsdata?.mentor_company"
                                                                   class="form-control" placeholder="Enter Company Name"
                                                                   data-error="Please enter company name." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Designation
                                                        / Title<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="mentor_designation"
                                                                   [ngModel]="mentorDetailsdata?.mentor_designation"
                                                                   class="form-control" placeholder="Enter Designation"
                                                                   data-error="Please enter designation." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Professional
                                                        Summary<span class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea name="mentor_profile_summary"
                                                                      [ngModel]="mentorDetailsdata?.mentor_profile_summary"
                                                                      class="form-control" rows="3" minlength="15"
                                                                      maxlength="150"
                                                                      placeholder="Enter Professional Summary"
                                                                      data-error="Please enter professional summary."
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Upload
                                                        Image<span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <img *ngIf="mentorDetailsdata?.mentor_profile_pic != '' && mentorDetailsdata?.mentor_profile_pic != null" src="{{mentorDetailsdata?.mentor_profile_pic}}" height="150" width="150">
                                                        <div class="input-group image-preview">
                                                            <input type="text"
                                                                   class="form-control image-preview-filename"
                                                                   placeholder="Accepted formats - png, jpeg, gif"
                                                                   disabled="disabled">
                                                            <input type="hidden" name="mentor_profile_pic_old" [ngModel]="mentorDetailsdata?.mentor_profile_pic">
                                                            <span class="input-group-btn">
                                        <button type="button" class="btn btn-default rm-img image-preview-clear"
                                                style="display:none;">
                                                <span class="glyphicon glyphicon-remove"></span> Clear
                                        </button>
                                        <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                            <span class="glyphicon glyphicon-folder-open"
                                                  style="margin-top: 5px;"></span>
                                            <span class="image-preview-input-title"
                                                  style="margin-left: 5px;">Browse</span>
                                            <input type="file" accept="image/png, image/jpeg, image/gif"
                                                   (change)="fileChangeEvent($event)" name="input-img-preview"
                                                   data-error="Please fill out this field."/> <!-- rename it -->
                                        </div>
                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">LinkedIn
                                                        Profile <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="mentor_linkedin"
                                                                   [ngModel]="mentorDetailsdata?.mentor_linkedin"
                                                                   class="form-control" placeholder="Enter URL">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
