<div *ngIf="loading" class="loading">
    <div style="display:table-cell; vertical-align: middle;">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>
</div>


<main id="main" *ngIf="!loading">
    <!-- ======= Trending Articles Section ======= -->
    <div class="inneblk">
       
        <h1 class="text-center">{{bxNewsDetailData?.title}}</h1>
        <div class="text-center shrt-desc">{{bxNewsDetailData?.shortDesc}}</div>
        <div class="topimg">
            <img src="{{bxNewsDetailData?.image}}">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-9">

                    <div class="toplbk newsb">
                        <ul class="fle">
                            <li>
                                <div class="primg"><img src="./assets/img/team-3.jpg" alt="" /></div>
                                <span class="artyshead">BY BEx Bureau</span>
                            </li>
                            <li class="stxt">|</li>
                            <li class="stxt">{{bxNewsDetailData?.time}}</li>
                            <li class="stxt">|</li>
                            <li class="stxt"><img src="./assets/img/duration.svg" alt="" /> 
                                {{ bxNewsDetailData?.content | readWordTime}} Mins Read</li>
                        </ul>
                        <ul class="cmtright">
                            <li>
                                <a [routerLink]="" onclick="commentsec()" class="comment-action"><i
                                        class="fa fa-comment" aria-hidden="true"></i> Comments</a>
                            </li>
                            <li>
                                <share-popup-button [theme]="'modern-dark'"
                                [include]="['facebook','twitter','linkedin','whatsapp']"
                                [show]="5"
                                [showText]="true"
                                [url]="currentUrl"
                                [title]="bxNewsDetailData?.title"
                                [description]="bxNewsDetailData?.shortDesc"
                                [image]="bxNewsDetailData?.image"
                                [icon]="faShareAlt"
                                [closeButton]="'null'"
                                [showIcon]="true"
                                ></share-popup-button>
                            
                            </li>
                        </ul>
                    </div>


                    <!-- <div class="shrt-desc">
                        {{bxNewsDetailData?.shortDesc}}
                    </div> -->

                    <div class="contentblk" [innerHTML]="bxNewsDetailData?.content">
                    </div>

                    <div class="tag-block">
                        <ul class="tag-list">
                            <li *ngFor="let tag of bxNewsDetailData?.tags">
                                <a [routerLink]="['/article/'+tag.tag_slug]">{{tag?.tag_name}}</a>
                            </li>
                        </ul>
                    </div>


                    <div id="newscontainer" class="business-detail-sec sec-slide-effect" >
                        <div id="commentpost" class="post-comment">
                            <div class="row custom_error">
                                <div class="msg-pnl_sales" *ngIf="submitMessage">
                                    <div *ngFor="let message of submitMessage | keyspipe : true">
                                        {{message}}
                                    </div>
                                </div>
                            </div>
                            <div class="ttl">Please add your Comment</div>
                            <div class="row comment-box">

                            <form #commentForm="ngForm" name="commentForm"
                                (ngSubmit)="saveComment(commentForm.value)"
                                class="form-horizontal1 form-sec" role="form">
                                <div class="col-xs-12 col-sm-12 col-md-12">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input type="text" name="comment_name" #comment_name="ngModel"
                                                        class="form-control" placeholder="Enter Your Name"
                                                        data-error="Please enter your name" required ngModel>
                                                    <span class="glyphicon form-control-feedback"
                                                        aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-6">
                                            <div class="form-group has-feedback">
                                                <div class="input-group">
                                                    <input type="email" name="comment_email"
                                                        #comment_email="ngModel" class="form-control"
                                                        placeholder="Enter Your Email ID"
                                                        data-error="Please enter your email ID" required ngModel>
                                                    <span class="glyphicon form-control-feedback"
                                                        aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-12">
                                            <div class="form-group has-feedback">
                                                <div class="input-group">
                                                    <textarea name="comment_detail" #comment_detail="ngModel"
                                                        class="form-control" rows="3" placeholder="" ngModel
                                                        data-error="Please fill out this field."
                                                        required></textarea>
                                                    <span class="glyphicon form-control-feedback"
                                                        aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-12 col-md-12 txt-center">
                                    <button type="submit" class="btn btn-default btn-blue">Post
                                        Comment
                                    </button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>

                <div id="newsthankscontainer" class="business-detail-sec sec-slide-effect" style="display:none;">
                    <div id="commentpost" class="post-comment">
                        <div class="ttl">Thanks for your comment</div>
                    </div>
                </div>



                    <!-- <div class="lbk">
                        <div class="fleft"> <a routerLink="['/']"> Previous<i class="icofont-rounded-left"></i>
                            </a> </div>
                        <div class="fright"> <a routerLink="['/']">Next <i class="icofont-rounded-right"></i> </a>
                        </div>
                    </div> -->


                    <!-- <div class="section-title">
                        <h2>BEx Bureau<span></span></h2>
                        <div class="viewall">
                            <a routerLink="['/news']"> View All</a>
                        </div>
                    </div> -->

                </div>


                <div class="col-xs-12 col-md-3 rightsec">

                    <div class="rigblk">
                        <div class="section-title">
                            <h2>Recommended for you<span></span></h2>
                        </div>
                        <ul class="rlist">
                            <li *ngFor="let similardata of recomendateNews; let i=index; let last = last" (click)="onclickFooterPage()">
                                <div class="imgleft">
                                    <a [routerLink]="['/news/'+similardata.url+'/'+similardata.news_id]">

                                        <img class="img-responsive" src="{{similardata?.image}}" />
                                    </a>
                                </div>
                                <div class="imgtxt">
                                    <div class="rhead">
                                        <a [routerLink]="['/news/'+similardata.url+'/'+similardata.news_id]">
                                            {{similardata?.shortDesc| stripTags | limitTo:30}}
                                        </a></div>
                                    <span class="datshow">{{similardata?.time}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>


                    <div class="rigblk">
                        <div class="section-title">
                            <h2>Latest News & Articles<span></span></h2>
                        </div>
                        <ul class="rlist">
                            <li *ngFor="let similardata of latestNews; let i=index; let last = last" (click)="onclickFooterPage()">
                                <div class="imgleft">
                                    <a [routerLink]="['/news/'+similardata.url+'/'+similardata.news_id]">
                                        <img class="img-responsive" src="{{similardata?.image}}" />
                                    </a>
                                </div>
                                <div class="imgtxt">
                                    <div class="rhead">
                                        <a [routerLink]="['/news/'+similardata.url+'/'+similardata.news_id]">
                                            {{similardata?.shortDesc| stripTags | limitTo:30}}
                                        </a></div>
                                    <span class="datshow">{{similardata?.time}}</span>
                                </div>
                            </li>

                        </ul>
                    </div>

                    <!-- <div class="section-title">
              <h2>Other Categories<span></span></h2>
            </div>
            <ul class="catlistnew">
              <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
              <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
              <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
              <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
              <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
              <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
            </ul> -->
                    <!-- <div class="riads"><img src="./assets/img/ads.png" alt=""></div> -->
                </div>

            </div>
        </div>

    </div>

</main>