import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { APP_CONFIG } from '../config/app-config.constants';
import { IAppConfig } from '../config/app-config.interface';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class HomeService {

    /*private footerBussinessList = new BehaviorSubject<any>(false);
    currentMessage = this.footerBussinessList.asObservable();
    changefooterBussinessList(message: any) {
        this.footerBussinessList.next(message)
    }*/

    replymySubjectBusinessFooter = new ReplaySubject<any>();
    replymySubjectInvestorFooter = new ReplaySubject<any>();
    replymySubjectStartupFooter = new ReplaySubject<any>();
    
    constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppConfig) {
    }


    getBxnewshome(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/bxnewshome')
    }

    getBxarticlehome(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/bxarticlehome')
    }

    getarticleDetails(article_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/bxarticle' + '/' + article_id)
            .toPromise()
            .catch(this.handleError);
    }

    bxarticlesimilar(article_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/bxarticlesimilar' + '/' + article_id)
            .toPromise()
            .catch(this.handleError);
    }

    getBxindustryreport(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/bxindustryreport')
    }

    getSeller(stateShortName, cityName): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/featureSellers', {
            stateShortName: stateShortName,
            cityName: cityName
        })
    }

    getMentor(): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/getFeaturedMentor', {})
    }

    getStartup(): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/getFeaturedStartup', {})
    }

    getInvestor(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/featuredInvestors');
    }

    quickRegistration(endpoint, data): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/' + endpoint, data)
    }

    getfiltersInvestor(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/filtersInvestor');
    }

    getfiltersBusiness(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/searchFiltersSeller');
    }

    getfiltersStartup(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/searchFiltersStartup');
    }

    getfiltersMentor(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/getsearchFiltersMentor')
    }


    getInvestorlisting(event: any, investorState: any, investorCity: any, catind: any, subcatind: any, investorType: any, itemsPerPage: any, maxInvestment: any, minInvestment: any, sortby: any): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/getInvestorList', {
            currentPage: event,
            state: investorState,
            city: investorCity,
            industrymain: catind,
            industrysub: subcatind,
            investorType: investorType,
            itemsPerPage: itemsPerPage,
            minInvestment: minInvestment,
            maxInvestment: maxInvestment,
            sortby: sortby
        })
    }

    getBusinesslistingDemo(event: any, BusinessState: any, BusinessCity: any, catind: any, subcatind: any,
        BusinessType: any, sortby: any, itemsPerPage: any, maxInvestment: any, minInvestment: any,
        annualsalesmin: any, annualsalesmax: any): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/getBusinessListDemo', {
            currentPage: event,
            state: BusinessState,
            city: BusinessCity,
            industrymain: catind,
            industrysub: subcatind,
            BusinessType: BusinessType,
            sortby: sortby,
            itemsPerPage: itemsPerPage,
            minInvestment: minInvestment,
            maxInvestment: maxInvestment,
            annualsalesmin: annualsalesmin,
            annualsalesmax: annualsalesmax
        })
    }

    getStartuplisting(event: any, BusinessState: any, BusinessCity: any, catind: any,
        subcatind: any, BusinessType: any, sortby: any, itemsPerPage: any, maxInvestment: any,
        minInvestment: any, annualsalesmin: any, annualsalesmax: any): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/getStartupList', {
            currentPage: event,
            state: BusinessState,
            city: BusinessCity,
            industrymain: catind,
            industrysub: subcatind,
            BusinessType: BusinessType,
            sortby: sortby,
            itemsPerPage: itemsPerPage,
            minInvestment: minInvestment,
            maxInvestment: maxInvestment,
            annualsalesmin: annualsalesmin,
            annualsalesmax: annualsalesmax
        })
    }

    getMentorlisting(event: any, BusinessState: any, BusinessCity: any, catind: any, subcatind: any,
        BusinessType: any, sortby: any, itemsPerPage: any, maxInvestment: any, minInvestment: any,
        annualsalesmin: any, annualsalesmax: any): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/getMentorList', {
            currentPage: event,
            state: BusinessState,
            city: BusinessCity,
            industrymain: catind,
            industrysub: subcatind,
            BusinessType: BusinessType,
            sortby: sortby,
            itemsPerPage: itemsPerPage,
            minInvestment: minInvestment,
            maxInvestment: maxInvestment,
            annualsalesmin: annualsalesmin,
            annualsalesmax: annualsalesmax
        })
    }

    getTotalActiveBussiness(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/activebussiness')
    }

    getTotalActiveInvestors(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/activeInvestors')
    }

    getTotalActiveStartups(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/activeStartups')
    }

    getTotalActiveMentors(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/activeMentors')
    }


    getArticlelisting(event: any, itemsPerPage: any, time: any, read: any, commented: any, orderBy: any, tagSlug: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getBxarticleListing', {
            currentPage: event,
            itemsPerPage: itemsPerPage,
            read: read,
            comment: commented,
            orderBy: orderBy,
            tagSlug: tagSlug
        })
            .toPromise()
            .catch(this.handleError);
    }

    subscribeMailer(data): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/newsLetterSubscribe', data)
    }


    searchFooterBusinessSeller(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/businessIndustrySeller')
    }

    searchFooterInvestorSeller(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/investorIndustrySeller')
    }

    startupIndustrySeller(): Observable<any> {
        return this.http.get(this.config.apiEndpoint + '/startupIndustrySeller')
    }

    getHomesearch(keywords: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/searchbusiness', { keywords: keywords })
            .toPromise()
            .catch(this.handleError);
    }


    getnewsDetails(news_Id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/bxnews' + '/' + news_Id)
            .toPromise()
            .catch(this.handleError);
    }

    getNewslisting(event: any, itemsPerPage: any, time: any, read: any, commented: any, orderBy: any, tagSlug: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/bxnewslisting', {
            currentPage: event,
            itemsPerPage: itemsPerPage,
            read: read,
            comment: commented,
            orderBy: orderBy,
            tagSlug: tagSlug
        })
            .toPromise()
            .catch(this.handleError);
    }

    bxnewssimilar(news_Id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/bxnewssimilar' + '/' + news_Id)
            .toPromise()
            .catch(this.handleError);
    }


    getBexShortslisting(event: any, itemsPerPage: any, time: any, read: any, commented: any, orderBy: any, tagSlug: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/bxshortslisting', {
            currentPage: event,
            itemsPerPage: itemsPerPage,
            read: read,
            comment: commented,
            orderBy: orderBy,
            tagSlug: tagSlug
        })
            .toPromise()
            .catch(this.handleError);
    }




    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

}
