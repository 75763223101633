import { isPlatformBrowser, Location } from '@angular/common';
import { ConstantPool } from '@angular/compiler';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BASE_URL } from '../../../../config/app-config.constants';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';
import {faTimesCircle as faTimesCircle,faShareAlt as faShareAlt} from '@fortawesome/free-solid-svg-icons/'



declare var $:any;

@Component({
  selector: 'app-news-listing',
  templateUrl: './news-listing.component.html',
  styleUrls: ['./news-listing.component.css']
})
export class NewsListingComponent implements OnInit {
    faShareAlt = faShareAlt;
    faTimesCircle = faTimesCircle;
  public title = 'Business News';
  public currentPage = 1;
  itemsPerPage = 12;
  public orderBy = 'created_at';
  public commented = '';
  public time = '';
  public totalItems = 0;
  public bxNewsListing = [];
  public read;
  homeslider=[];
  public bxnewslistingRecent = [];
  public bxbannertop;
  public bxbannerleft;
  public bxbannerrightside;
  public bxbannercenter;
  public bxbannerbottom;
  public bxbannertopmob;
  public bannerpage = 14;
  public tagSlug = '';
  public tag = '';
  obj = {Title: 'test', Url: 'test12'};
  pagination = false;
  @ViewChild(ListingPaginationComponent)
  private listingPagination: ListingPaginationComponent;

  constructor(private linkService: LinkService,
              private meta: Meta, private titleService: Title,
              private contactService: ContactService,
              private homeService: HomeService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private route: ActivatedRoute,
              private router: Router,
              private historyLocation: Location) {

      linkService.removeCanonicalLink();

      this.route.paramMap.subscribe((params: Params) => {
          this.tagSlug = params.get('newsTagSlug');
          const param1 = +this.route.snapshot.queryParams['page'];
          if (param1 !== undefined && param1 > 0) {
              this.currentPage = param1;
          }
          this.getNewsListing();
      });
  }

  getBanners(): void {
      this.contactService.getBanners(this.bannerpage)
          .then((result) => {
              this.bxbannerbottom     = result.bottom;
              this.bxbannercenter     = result.middle;
              this.bxbannerleft       = result.left;
              this.bxbannerrightside  = result.right;
              this.bxbannertop        = result.top;
              this.bxbannertopmob = result.topMobile;
          });
  }

  getNewsListing() {
      if (this.pagination) {
          if(this.currentPage > 1) {
              history.pushState(this.obj, this.obj.Title, this.listingPagination.getPathString() + '?page=' + this.currentPage);
          }else{
              history.pushState(this.obj, this.obj.Title, this.listingPagination.getPathString());
          }
      }
      this.homeService.getNewslisting(this.currentPage, this.itemsPerPage, this.time, this.read, this.commented, this.orderBy,this.tagSlug)
          .then(
              result => {
                  this.bxNewsListing = result.bxnewslistingdata;
                  //console.log( this.bxNewsListing );

                 // this.bxnewslistingRecent = result.bxnewslistingdata.sort((a, b) => a.news_id - b.news_id).slice(0,4)

                  //this.homeslider = this.bxNewsListing;
                  //this.homeslider = this.homeslider.sort(() => Math.random() - 0.5)

                  if(this.tagSlug !== '' && this.bxNewsListing.length === 0) {
                      this.router.navigate(['/news']);
                  }
                  this.totalItems = result.newsCount;
                  this.setTitleMeta();
                  this.currentPage = (this.bxNewsListing.length === 0) ? 0 : this.currentPage;
              }
          );
  }

  shortByDate(orderBy) {
      this.pagination = true;
      this.currentPage = 1;
      this.orderBy = orderBy;
      this.getNewsListing();
      $('#recent').addClass('active');
      $('#read').removeClass('active');
      $('#comment').removeClass('active');
      $('#title').removeClass('active');

  }

  shortByRead(read) {
      this.pagination = true;
      this.currentPage = 1;
      this.orderBy = read;
      this.getNewsListing();
      $('#read').addClass('active');
      $('#recent').removeClass('active');
      $('#comment').removeClass('active');
      $('#title').removeClass('active');

  }

  shortByComment(commented) {
      this.pagination = true;
      this.currentPage = 1;
      this.orderBy = commented;
      this.getNewsListing();
      $('#comment').addClass('active');
      $('#recent').removeClass('active');
      $('#read').removeClass('active');
      $('#title').removeClass('active');

  }

  shortByAlpha(alpha) {
      this.pagination = true;
      this.currentPage = 1;
      this.orderBy = alpha;
      this.getNewsListing();
      $('#title').addClass('active');
      $('#read').removeClass('active');
      $('#comment').removeClass('active');
      $('#recent').removeClass('active');

  }

  goToPage(n: number): void {
      this.pagination = true;
      this.currentPage = n;
      this.getNewsListing();

  }

  onNext(): void {
      this.pagination = true;
      this.currentPage++;
      this.getNewsListing();

  }

  onPrev(): void {
      this.pagination = true;
      this.currentPage--;
      this.getNewsListing();
  }

  newsList(): void {
      if (isPlatformBrowser(this.platformId)) {
          setTimeout(function () {
              /** Title Ellipsis **/
                    // appConfig.ellipsizeTextBox('ttl-ellipsis', 45);
                    // appConfig.ellipsizeTextBox('sum-ellipsis', 32);
                    // appConfig.ellipsizeTextBox('sec-ellipsis', 48);
              /** Page Scroll Animation **/
              appConfig.pagescrollanimation();
          }, 500);
      }
  }

  ngOnInit() {
      //this.getBanners();
  }


  private setTitleMeta() {
      let title = 'Latest Business News and Information | Today\'s Business News - BusinessEx';
      if(this.tagSlug !== null) {
          this.tag = appConfig.revertSlug(this.tagSlug);
          title = 'Latest '+ this.tag +' News and '+ this.tag +' Information | Today\'s Business News - BusinessEx';
      }
      this.titleService.setTitle(title);

      this.meta.updateTag({ name: 'Keywords', content: 'latest ' + this.tag + ' usiness news,business news today,today\'s business news, business news and information'});
      
      this.meta.updateTag({ name: 'Description', content: 'Find latest ' + this.tag + ' business news,business news today,today\'s business news, business news and information'});

      /*this.meta.updateTag({ name: 'fb:pages', content: '84775983858482' });
      this.meta.updateTag({ name: 'fb:app_id', content: '59517604401875' });
      this.meta.updateTag({ name: 'original-source', content: BASE_URL + this.historyLocation.path() });
      this.meta.updateTag({ property: 'site_name', content: 'BusinessEx' });

      this.meta.updateTag({ property: 'og:type', content: 'news' });
      this.meta.updateTag({ property: 'og:url', content: BASE_URL + this.historyLocation.path() });
      this.meta.updateTag({ property: 'og:image:width', content: '770' });
      this.meta.updateTag({ property: 'og:image:height', content: '434' });
  
      this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
      this.meta.updateTag({ name: 'twitter:site', content: '@BusinesEx' });
      this.meta.updateTag({ name: 'twitter:domain', content: 'businessex.com' });
      this.meta.updateTag({ name: 'twitter:creator', content: '@businesex' });
      */

      this.linkService.removeCanonicalLink();
      const totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 0;
      let pNumber = 0;
      if (this.currentPage > 1 && this.currentPage <= totalPages) {
          pNumber = this.currentPage - 1;
          this.linkService.addTag({
              rel: 'prev',
              href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
          });
      }
      this.linkService.addTag({
          rel: 'canonical',
          href: BASE_URL + this.historyLocation.path()
      });
      if (this.currentPage >= 1 && this.currentPage < totalPages) {
          pNumber = this.currentPage + 1;
          this.linkService.addTag({
              rel: 'next',
              href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
          });
      }
  }

  
}
