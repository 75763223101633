import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthenticationService } from 'projects/frontend/src/app/services/authentication.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { PROFILE_ARRAY, PROFILE_TYPE } from '../../../../config/app-config.constants';
import { MyProfileService } from 'projects/frontend/src/app/services/my-profile.service';
import { ChatService } from 'projects/frontend/src/app/services/chat.service';
import { Router } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { appConfig } from '../../../frontend/app.config';

declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  LoginDeatail
  jwtHelper = new JwtHelperService();
  public users;
  token: any;
  decode;
  profileObjects;
  userID;
  notifycreatedate;
  requestlist;
  totalrequest;
  listingNotification;
  listingUpdateNotification;
  lenNotification;
  updatedate;
  reg_source;
  activeMenu;
  totalclickthreemonth;
  vistorlist;
  totalclick;
  dateclick = [];
  profileName;
  lineChartYData;
  lineChartXData;
  clicks = [];
  useraccount;
  public contactHistory;
  public ProfileSelected;
  public Profilepercentage;
  public bxinboxnotification: any = [];
  public unReadNotificationcount;
  public bxInvestorType;
  public userchatlist;
  public connection;
  public makeConnection = true;
  public profiles = PROFILE_TYPE;

  constructor(private myProfileService: MyProfileService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public authService: AuthenticationService,
    private chatService: ChatService,
    private historyLocation: Location,
    public router: Router) {
  }

  ngOnInit(): void {
    /*this.token = localStorage.getItem('currentUser');
    this.LoginDeatail = this.jwtHelper.decodeToken(this.token);
    const expirationDate = this.jwtHelper.getTokenExpirationDate(this.token);
    const isExpired = this.jwtHelper.isTokenExpired(this.token);*/

    this.check();

  }


  typeCheck() {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
      if (localStorage.ProfileType !== undefined) {
        const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
        this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
      } else {
        this.ProfileSelected = this.profileObjects[0];
      }
    }
  }

  getVisitorSeller(): void {
    this.myProfileService.getVisitorSeller(this.ProfileSelected.profile_type, this.ProfileSelected.profile_str)
      .then((result) => {
        this.totalclickthreemonth = result.threemoncount;
        this.vistorlist = result.vistor;
        this.vistorlist.forEach(function (eachObj) {
          this.clicks.push(eachObj.total);
          this.dateclick.push(eachObj.created_at);
          this.totalclick += eachObj.total;
        }.bind(this));

        const data = {
          'xData': this.dateclick,
          'yData': [{
            'name': this.ProfileSelected.profile_name,
            'data': this.clicks
          }]
        };

        this.lineChartYData = data.yData;
        this.lineChartXData = data.xData;

      });
  }



  getUserChatlist(): void {
    this.myProfileService.getuserChatlist(this.userID).then((result) => {
      this.userchatlist = result;
    });
  }

  updateBxinboxNotification(): void {
    this.myProfileService.getupdateBxinboxNotification(this.userID)
      .then((result) => {
        if (result !== null && result.hasOwnProperty('message') && result['message'] === 200) {
          this.getRequestList();
          this.getBxInboxNotification();
        }
      });

  }

  getBxInboxNotification(): void {
    this.myProfileService.getBxInboxNotification(this.userID)
      .then((result) => {
        this.bxinboxnotification = result.messages;
        this.unReadNotificationcount = result.unReadNotificationcount;
        this.connectToChatServer();
      });
  }


  getRequestList(): void {
    this.myProfileService.getRequestlist(this.userID)
      .then((result) => {
        if (result.massge === 'null') {
          this.totalrequest = 0;
        } else {
          this.requestlist = result;
          this.totalrequest = this.requestlist.length;
        }
      });
  }


  updateViewedStatus(): void {
    if (this.totalrequest > 0) {
      this.myProfileService.updateProposalViewedStatus(this.userID);
    }
  }

  getListingNotification(): void {
    this.myProfileService.getListingNotification(this.userID, this.notifycreatedate)
      .then((result) => {
       // console.log(result);
        if (result.response === 'null') {
          this.listingNotification = 'null';
        } else {
          this.listingNotification = result;
          this.lenNotification = this.listingNotification.length;

        }
      });
  }

  getProfilePercentage(): void {
    this.myProfileService.getProfilepercentage(this.ProfileSelected.profile_type, this.ProfileSelected.profile_str)
      .then((result) => {
        this.Profilepercentage = result;
      });
  }


  check(): void {
    if (isPlatformBrowser(this.platformId)) {

      if (localStorage.getItem('currentUser') !== null) {

        this.token = localStorage.getItem('currentUser');
        this.decode = this.jwtHelper.decodeToken(this.token);
        this.userID = this.decode.sub;
        this.myProfileService.getNewToken(this.userID).then((response) => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(response['token']));
          this.token = localStorage.getItem('currentUser');
          this.decode = this.jwtHelper.decodeToken(this.token);
          this.userID = this.decode.sub;
          this.profileObjects = this.decode.userProfile;
          if (this.profileObjects.length > 0 && this.profileObjects[0].profile_id > 0) {
            // forcefully logout the user to get the reflection of new changes (free membership expired)
            if (this.profileObjects[0]['isFreeCreditExpired'] === undefined) {
              this.isLogout();
              return;
            }
          }
          this.notifycreatedate = this.decode.notify_date;
          this.reg_source = this.decode.reg_source;
          this.useraccount = this.decode;
          this.typeCheck();
          this.getVisitorSeller();
          this.getBxInboxNotification();
          this.getRequestList();
          this.getListingNotification();
          this.getProfilePercentage();
          // sending non-verified mobile user to myaccount (for all)
          if (this.decode.mobileVerified === 0) {
            this.router.navigate(['/dashboard/myaccount']);
          }
        });
      }
      if (localStorage.getItem('updatedate') !== null) {
        this.updatedate = 'Update';
      }
    }
  }

  private connectToChatServer() {
    // make connection if user accept/send at least one proposal
    if (this.makeConnection && this.bxinboxnotification.length > 0) {
      this.makeConnection = false;
      // reeving the message send by chat server
      this.connection = this.chatService.getMessages(this.userID).subscribe(message => {
        // this is for one to one communication
        // show the chat server notification when user access some other page
        if (this.historyLocation.path().split('?')[0] !== '/dashboard/myinteraction'
          && this.userID && message['to_id'] === this.userID) {
          this.bxinboxnotification.push(message);
          this.unReadNotificationcount++;
        }
      });
    }
  }


  handleRequest(proposals, requestStatus): void {
      this.myProfileService.handleRequest(proposals, requestStatus)
          .then((result) => {
              if (result.massge === 'Accept Reguest successfully' || result.massge === 'Reject Reguest successfully') {
                  this.getRequestList();
                  this.getBxInboxNotification();
                  this.getUserChatlist();
                  //$('.dropdown-menu.bxproposal').css('display', 'block');
                 
              }
          });
  }

  isLogout(): void {
    this.token = null;
    this.authService.logout()
    localStorage.clear();
    if (isPlatformBrowser(this.platformId)){
      this.router.navigate(['/']);
      window.location.reload();
    }
  }


  onclickFooterPage() {
    appConfig.scrollToError(true);
  }

}
