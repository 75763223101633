import {PipeTransform, Pipe} from '@angular/core';

@Pipe({name: 'stripTags'})
export class StripTagsPipe implements PipeTransform {

  transform(text: string, ...allowedTags: any[]): string {
    return allowedTags.length > 0
      ? text.replace(new RegExp(`<(?!\/?(${allowedTags.join('|')})\s*\/?)[^>]+>`, 'g'), '')
            .replace(/(&nbsp;|amp;)+/g, '')
            .replace(/(&ndash;|&mdash;)+/g, '–')
      : text.replace(/<(?:.|\s)*?>/g, '').replace(/(&nbsp;|amp;)+/g, '')
            .replace(/(&ndash;|&mdash;)+/g, '–')
            .replace(/&rdquo;/g, '\"' )
            .replace(/&ldquo;/g, '\"' )
            .replace(/(&rsquo;)/g, "'")
        ;
  }
}
