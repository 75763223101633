import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APP_CONFIG } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';

declare var $:any

@Component({
  selector: 'app-business-plan',
  templateUrl: './business-plan.component.html',
  styleUrls: ['./business-plan.component.css']
})
export class BusinessPlanComponent implements OnInit {

  businessValuation: FormGroup;
  submitted: boolean = false;
  submitMessage: any;
  jwtHelper = new JwtHelperService();
  users: any;
  token;
  decode;
  userID;
  public isViewChecked: boolean;
  public trackid: any;
  public utm_source: any;
  public utm_campaign: any;
  responsevalue;

  constructor(
    linkService: LinkService,
    private formBuilder: FormBuilder,
    meta: Meta, title: Title,
    private contactService: ContactService,
    private router: Router,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private config: IAppConfig) {

    this.isViewChecked = false;
    linkService.removeCanonicalLink();
    linkService.addTag({rel: 'canonical', href: 'https://www.businessex.com/service/business-plan'});
    title.setTitle('Business Plan for Startups, Investors, Lenders, Buyers and Team Members');
    this.checkLoginToken();
    this.route.queryParams.subscribe(params => {
        this.utm_source = params['utm_source'];
        this.utm_campaign = params['utm_campaign'];
    });


  }

  initForm() {
    this.businessValuation = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      mobile: ['', Validators.required],
      company: ['', Validators.required],
      payment_mode: ['', Validators.required],
    })
  }

  get bForm() {
    return this.businessValuation.controls;
  }

  checkLoginToken(): void {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
      this.token = localStorage.getItem('currentUser');
      this.decode = this.jwtHelper.decodeToken(this.token);
      this.userID = this.decode.sub;
    } else {
      this.userID = '';
    }
  }

  ngOnInit(): void {
    this.initForm()

    if (isPlatformBrowser(this.platformId) && this.utm_source !== '' && this.utm_source !== undefined) {
      localStorage.setItem('trackid', this.utm_source);
      localStorage.setItem('utm_source', this.utm_source);
      localStorage.setItem('utm_campaign', this.utm_campaign);
    }
    appConfig.accordianBizEquityFaq();

  }

  businessValuationSubmit() {
    this.submitted = true;
    if (this.businessValuation.invalid) {
      appConfig.scrollToError(true);
      return
    }

    let data = {
      'user_id': this.userID,
      'service_type': 2,
      'event_city': null,
      'event_date': null,
      'event_timing': null,
      'designation': null,
      'event_topic': null,
      'trackid': localStorage.getItem('trackid'),
      'utm_source': localStorage.getItem('utm_source'),
      'utm_campaign': localStorage.getItem('utm_campaign'),
      'name': this.businessValuation.controls.name.value,
      'email': this.businessValuation.controls.email.value,
      'mobile': this.businessValuation.controls.mobile.value,
      'company': this.businessValuation.controls.company.value,
      'payment_mode': this.businessValuation.controls.payment_mode.value,

    }

    this.http.post(this.config.apiEndpoint + '/initiateServicePayment', data).subscribe(
      dataResponse => {
        this.responsevalue = dataResponse;
        if (data.payment_mode == undefined || data.payment_mode == '') {
          appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
            key: this.responsevalue.merchant_key,
            hash: this.responsevalue.hash,
            txnid: this.responsevalue.txnId,
            surl: this.responsevalue.surl,
            furl: this.responsevalue.furl,
            curl: this.responsevalue.curl,
            amount: this.responsevalue.amount,
            firstname: this.responsevalue.firstname,
            email: this.responsevalue.email,
            phone: this.responsevalue.phone,
            productinfo: this.responsevalue.productinfo,
            city: this.responsevalue.city,
            country: this.responsevalue.country
          }, true);

        } else if (data.payment_mode == 'Paytm') {
          let paytmData = this.responsevalue.paytmList;
          appConfig.submitPayFORM('https://securegw.paytm.in/theia/processTransaction', 'POST', paytmData);

        } else {
          appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
            access_code: this.responsevalue.access_code,
            encRequest: this.responsevalue.encRequest,
          });
        }

      },
      error => {
        this.submitMessage = error.error;
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        this.businessValuation.reset();
        appConfig.scrollToError();
      });


  }

}
