
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 

                    <profile-detail></profile-detail>

                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="row">
                            <div class="col-md-12">

                                <div class="bdr">
                                    <div class="cat-list ">
                                        <div class="cat-sec-ttl">
                                            My Profile 
                                            <!--<a href="#" class="dwn-response">Download Response</a>-->
                                        </div>
                                    </div>

                                    <div class="margin-20">
                                        <div class="row cat-detail">
                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                <div class="page-ttl">{{ (sellerProfileData?.title !== '') ?
                                                    sellerProfileData?.title : ("N/A") }}
                                                </div>
                                                <div class="shrt-desc tp-border">{{ (sellerProfileData?.description
                                                    !== '') ? sellerProfileData?.description : ("N/A") }}
                                                </div>

                                                <div class="col-xs-12 col-sm-12 col-md-12">

                                                    <div class="business-detail">
                                                        <div class="business-key-sec">

                                                            <div class="business-key col-1">ASKING
                                                                <span class="brk-word">PRICE</span>
                                                                <span class="keynote"
                                                                      *ngIf="sellerProfileData?.askingPrice !== 0">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {{ sellerProfileData?.askingPrice }}
                        </span>
                                                                <span class="keynote"
                                                                      *ngIf="sellerProfileData?.askingPrice == 0">
                            {{ "N/A" }}
                        </span>
                                                            </div>
                                                            <div class="business-key col-2">ANNUAL
                                                                <span class="brk-word">SALES/TURNOVER</span>
                                                                <span class="keynote"
                                                                      *ngIf="sellerProfileData?.annualsale !== 0">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {{ sellerProfileData?.annualsale | priceShort }}
                        </span>
                                                                <span class="keynote"
                                                                      *ngIf="sellerProfileData?.annualsale == 0">
                            {{ "N/A" }}
                        </span>
                                                            </div>

                                                            <div class="business-key col-1 rm-bdr">GROSS
                                                                <span class="brk-word">INCOME</span>
                                                                <span class="keynote"
                                                                      *ngIf="sellerProfileData?.grossprofit !== 0">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {{ sellerProfileData?.grossprofit | priceShort }}
                        </span>
                                                                <span class="keynote"
                                                                      *ngIf="sellerProfileData?.grossprofit == 0">
                            {{ "N/A" }}
                        </span>
                                                            </div>

                                                        </div>

                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect">
                                                                <div class="img-gallery">
                                                                    <div id="carousel-example-generic" class="carousel slide"
                                                                         data-ride="carousel">
                                                                        <!-- Indicators -->
                                                                        <ol class="carousel-indicators">
                                                                            <li data-target="#carousel-example-generic"
                                                                                *ngFor="let image of businessImages;let i=index;"
                                                                                [attr.data-slide-to]="i" [ngClass]="{'active': i==0}"></li>
                                                                        </ol>
                                                                        <!-- Wrapper for slides -->
                                                                        <div class="carousel-inner" role="listbox">
                                                                            <div *ngFor="let image of businessImages;let j=index;"
                                                                                 class="item" [ngClass]="{'active': j==0}">
                                                                                <img [src]="image.imageUrl " alt="{{image.title}}">
                                                                                <div class="carousel-caption">{{image.title}}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="primiumtag"
                                                                         *ngIf="sellerProfileData?.membership_paid==1">

                                                                        <img *ngIf="sellerProfileData?.membership_plan==1" src="assets/images/premium.png">
                                                                        <img *ngIf="sellerProfileData?.membership_plan==2" src="assets/images/gold.png">
                                                                        <img *ngIf="sellerProfileData?.membership_plan==3" src="assets/images/platinum.png">

                                                                        <span *ngIf="sellerProfileData?.membership_plan==1">Premium</span>
                                                                        <span style="color:#000" *ngIf="sellerProfileData?.membership_plan==2">Gold</span>
                                                                        <span style="color:#000" *ngIf="sellerProfileData?.membership_plan==3">Platinum</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="row">
                                                                    <div class="col-xs-12 col-sm-7 col-md-7 sec-slide-effect">
                                                                        <div class="key-area">
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Website
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.business_website
                                                                                    !== null) ?
                                                                                    sellerProfileData?.business_website
                                                                                    : ("N/A") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Established From
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.estb_year !==
                                                                                    0) ? sellerProfileData?.estb_year
                                                                                    : ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Employees
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.emp_count !==
                                                                                    0) ? sellerProfileData?.emp_count
                                                                                    : ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Entity type <span>:</span>
                                                                                </div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.entity_type
                                                                                    !== 0) ?
                                                                                    sellerProfileData?.entity_type :
                                                                                    ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Business Sector
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.industry !==
                                                                                    '') ? sellerProfileData?.industry
                                                                                    : ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xs-12 col-sm-5 col-md-5 sec-slide-effect">
                                                                        <div class="key-area key-sec">
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Business Type
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.business_type
                                                                                    !== 0) ?
                                                                                    sellerProfileData?.business_type
                                                                                    : ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">EBITDA
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.ebitda !== 0)
                                                                                    ? sellerProfileData?.ebitda :
                                                                                    ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">EBITDA Margin
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.ebitdamargin
                                                                                    !== 0) ?
                                                                                    sellerProfileData?.ebitda_margin
                                                                                    : ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Inventory value
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.inventory_value
                                                                                    !== null) ?
                                                                                    sellerProfileData?.inventory_value
                                                                                    : ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="key-detail">
                                                                                <div class="key-word">Rentals
                                                                                    <span>:</span></div>
                                                                                <div class="key-val">{{
                                                                                    (sellerProfileData?.rentals !==
                                                                                    null) ?
                                                                                    sellerProfileData?.rentals :
                                                                                    ("Undisclosed") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="business-pitch sec-slide-effect">
                                                                    One line Business Pitch
                                                                    <div>{{ (sellerProfileData?.business_pitch !==
                                                                        '') ? sellerProfileData?.business_pitch :
                                                                        ("N/A") }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="business-detail-sec sec-slide-effect" *ngIf="businessDocuments.length >0">
                                                        Business Documents
                                                        <div class="download-documents">
                                                            <ul>
                                                                <li *ngFor="let document of businessDocuments;let j=index;">
                                                                    <div id="business-doc{{j}}" class="doc-name ellipsis"
                                                                         onmouseover="scrollText(this.id);"
                                                                         onmouseout="returnScroll(this.id);">{{appConfig.getDocumentName(document)}}
                                                                    </div>
                                                                    <div><a href="{{document}}"
                                                                            target="_blank"><i class="fa fa-file-pdf-o"
                                                                                               aria-hidden="true"></i> Download</a></div>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </div>
                                                    <div class="business-detail-sec sec-slide-effect">
                                                        Business Requirement
                                                        <!-- For Web Section start here -->
                                                        <div class="tabulardata hide-ph">
                                                            <div class="tbrow tabTitl">
                                                                <div class="lkfor">Looking For</div>
                                                                <div class="lkforamnt">Amount</div>
                                                                <div class="lkforrjn">Reason</div>
                                                            </div>
                                                            <div class="tbrow"
                                                                 *ngIf="sellerProfileData?.seeking_buyers == '1'">
                                                                <div class="lkfor">Sale</div>
                                                                <div class="lkforamnt">
                                                                    {{sellerProfileData?.saleAmount}}
                                                                </div>
                                                                <div class="lkforrjn">
                                                                    {{sellerProfileData?.buyer_sell_reason}}
                                                                </div>
                                                            </div>
                                                            <div class="tbrow"
                                                                 *ngIf="sellerProfileData?.seeking_investors == 1">
                                                                <div class="lkfor">Investment</div>
                                                                <div class="lkforamnt"><span>{{sellerProfileData?.investmentsize}} at {{sellerProfileData?.inv_stake}} % stake</span>
                                                                </div>
                                                                <div class="lkforrjn"><span>{{sellerProfileData?.inv_reason}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="tbrow"
                                                                 *ngIf="sellerProfileData?.seeking_loan != 0">
                                                                <div class="lkfor">Loans</div>
                                                                <div class="lkforamnt"><span>{{sellerProfileData?.loanAmount}} at {{sellerProfileData?.loan_interest_rate}} % interest rate</span>
                                                                </div>
                                                                <div class="lkforrjn"><span>{{sellerProfileData?.loan_reason}}</span>
                                                                </div>
                                                                <div class="othr-detail"
                                                                     *ngIf="sellerProfileData?.loan_collateral_details !== null && sellerProfileData?.loan_collateral_details != ''">
                                                                    <span>Collateral Details: {{sellerProfileData?.loan_collateral_details}}</span>
                                                                </div>
                                                                <div class="othr-detail"
                                                                     *ngIf="sellerProfileData?.loan_repayment_period !== null && sellerProfileData?.loan_repayment_period !== 0">
                                                                    <span>Loan Repayment Period: {{sellerProfileData?.loan_repayment_period}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tabulardata hide-ph"
                                                             *ngIf="sellerProfileData?.seeking_mentors == 1 || sellerProfileData?.seeking_accelerators == 1">
                                                            <div class="tbrow tabTitl">
                                                                <div class="lkfor">Looking For</div>
                                                                <div class="lkforamnt">Field of Support</div>
                                                                <div class="lkforrjn">Support Req.</div>
                                                            </div>
                                                            <div class="tbrow"
                                                                 *ngIf="sellerProfileData?.seeking_mentors == 1">
                                                                <div class="lkfor">Mentorship</div>
                                                                <div class="lkforamnt">
                                                <span class="comma" *ngFor="let mentorSupport of sellerMentorSupport">    
                                                    {{mentorSupport.mentor_category_name}}
                                                </span>
                                                                </div>
                                                                <div class="lkforrjn">
                                                                    {{sellerProfileData?.mentor_req_details}}
                                                                </div>
                                                            </div>
                                                            <div class="tbrow"
                                                                 *ngIf="sellerProfileData?.seeking_accelerators == 1">
                                                                <div class="lkfor">Incubator Support
                                                                    <div class="smhints">(for
                                                                        {{sellerProfileData?.accel_time_period}})
                                                                    </div>
                                                                </div>
                                                                <div class="lkforamnt">
                                                <span class="comma" *ngFor="let accelSupport of sellerAccelSupport">    
                                                    {{accelSupport.mentor_category_name}}
                                                </span>
                                                                </div>
                                                                <div class="lkforrjn">
                                                                    {{sellerProfileData?.accel_req_details}}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <!-- For Web Section end here -->

                                                        <!-- For Ph Section start here -->
                                                        <div class="ph-lkfr web-hide">
                                                            <ul>
                                                                <li *ngIf="sellerProfileData?.seeking_buyers == '1'">
                                                                    <div class="title">Looking For Sale</div>
                                                                    <div class="sec">
                                                                        <div class="lst">
                                                                            Amount
                                                                            <div>{{sellerProfileData?.saleAmount}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="lst">
                                                                            Reason
                                                                            <div>
                                                                                {{sellerProfileData?.buyer_sell_reason}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li *ngIf="sellerProfileData?.seeking_investors == 1">
                                                                    <div class="title">Looking For Investment</div>
                                                                    <div class="sec">
                                                                        <div class="lst">
                                                                            Amount
                                                                            <div><span>{{sellerProfileData?.investmentsize}} at {{sellerProfileData?.inv_stake}} % stake</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="lst">
                                                                            Reason
                                                                            <div><span>{{sellerProfileData?.inv_reason}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li *ngIf="sellerProfileData?.seeking_loan != 0">
                                                                    <div class="title">Looking For Loan</div>
                                                                    <div class="sec">
                                                                        <div class="lst">
                                                                            Amount
                                                                            <div><span>{{sellerProfileData?.loanAmount}} at {{sellerProfileData?.loan_interest_rate}} % interest rate</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="lst">
                                                                            Reason
                                                                            <div><span>{{sellerProfileData?.loan_reason}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="othr-detail">Collateral Details:
                                                                            {{sellerProfileData?.loan_collateral_details}}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li *ngIf="sellerProfileData?.seeking_mentors == 1">
                                                                    <div class="title">Looking For Mentorship</div>
                                                                    <div class="sec">
                                                                        <div class="lst">
                                                                            Field of Support
                                                                            <div>
                                                            <span class="comma"
                                                                  *ngFor="let mentorSupport of sellerMentorSupport">
                                                                {{mentorSupport.mentor_category_name}}
                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="lst">
                                                                            Support Req.
                                                                            <div>
                                                                                {{sellerProfileData?.mentor_req_details}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li *ngIf="sellerProfileData?.seeking_accelerators == 1">
                                                                    <div class="title">
                                                                        Looking For Incubator Support
                                                                        <div class="sub-ttl">(for
                                                                            {{sellerProfileData?.accel_time_period}})
                                                                        </div>
                                                                    </div>
                                                                    <div class="sec">
                                                                        <div class="lst">
                                                                            Field of Support
                                                                            <div>
                                                            <span class="comma"
                                                                  *ngFor="let accelSupport of sellerAccelSupport">
                                                                {{accelSupport.mentor_category_name}}
                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="lst">
                                                                            Support Req.
                                                                            <div>
                                                                                {{sellerProfileData?.accel_req_details}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <!-- For Ph Section end here -->

                                                    </div>
                                                    <div class="business-detail-sec sec-slide-effect">
                                                        Director / CEO information
                                                        <div class="com-member-list">
                                                            <ul>
                                                                <li>
                                                                    {{ (sellerProfileData?.director_name !== '') ?
                                                                    sellerProfileData?.director_name : ("N/A") }},
                                                                    {{ (sellerProfileData?.director_designation !==
                                                                    '') ? sellerProfileData?.director_designation :
                                                                    ("N/A") }}
                                                                    <span class="pipe">|</span>
                                                                    {{ (sellerProfileData?.director_email !== '') ?
                                                                    sellerProfileData?.director_email : ("N/A") }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="business-detail-sec sec-slide-effect">
                                                        Management Team information
                                                        <div class="com-member-list">
                                                            <ul *ngIf="sellerMgtTeamDetails?.length != 0">
                                                                <li *ngFor="let sellerMgt of sellerMgtTeamDetails">
                                                                    {{ (sellerMgt.mgmt_name !== '') ?
                                                                    sellerMgt.mgmt_name : ("N/A") }},
                                                                    {{ (sellerMgt.mgmt_designation !== '') ?
                                                                    sellerMgt.mgmt_designation : ("N/A") }}
                                                                    <span class="pipe">|</span>
                                                                    {{ (sellerMgt.mgmt_email !== '') ?
                                                                    sellerMgt.mgmt_email : ("N/A") }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="business-detail-sec sec-slide-effect">
                                                        Business Overview
                                                        <div class="overview">
                                                            {{sellerProfileData?.company_summary}}
                                                        </div>
                                                    </div>
                                                    <div class="business-detail-sec sec-slide-effect">
                                                        Facilities Overview
                                                        <div class="overview">
                                                            {{sellerProfileData?.facilities_desc}}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
     
      