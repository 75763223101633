import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable , PLATFORM_ID} from "@angular/core";
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
    providedIn:'root'
})

export class AuthInterceptor implements HttpInterceptor{

    constructor(@Inject(PLATFORM_ID) private platformId:Object,private router:Router){

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE0OTkyNTE3MTQsImV4cCI6MTUxMzc2NjkxNH0.Mt5l5IzJI7m5NXr240Yx_fJWQb_a0c7zFvAtW_wKgZ8';
        if (isPlatformBrowser(this.platformId)) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser && currentUser.token) {
                token = currentUser.token;
            }
        }

        //clone the request  to add the new headert

        const authReq = req.clone({headers:req.headers.set('Authorization','Bearer'+token)});
        

        return next.handle(authReq)
        .pipe(
            map(event => {
                if (event instanceof HttpResponse && (event.status) === 204) {
                    console.info('HttpResponse::event =', event);
                    this.router.navigate(['/page-not-found'], {skipLocationChange: true});
                }  
                return event;
            },(error: any) => {
                    if (error instanceof HttpErrorResponse && req.method === 'GET') {
                        
                        if (error.status === 404) {
                            this.router.navigate(['/page-not-found'], {skipLocationChange: true});
                        }
                    }
                }
            )
          )        
    }

}