<section id="intro" class="clearfix">
  <div class="container form">

    <div class="selright">

      <div class="row custom_error" style="margin-top:20px;">
        <div class="col-xs-12">
            <div class="msg-pnl_sales" *ngIf="submitMessage">
                <div *ngFor="let message of submitMessage | keyspipe : true">
                    {{message}}
                </div>
            </div>
        </div>
    </div>

      <h3>Value your Business today!</h3>

      <form [formGroup]="businessValuation" (ngSubmit)="businessValuationSubmit()">

        <div class="form-group">
          <input type="text" class="form-control" placeholder="Your Name" formControlName="name"
          [ngClass]="{'invalid-border': submitted && bForm.name.errors}">
        </div>

        <div class="form-group">
          <input type="email" class="form-control" placeholder="Your Email" formControlName="email"
          [ngClass]="{'invalid-border': submitted && bForm.email.errors}">
        </div>
        
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Your Mobile" formControlName="mobile"
          [ngClass]="{'invalid-border': submitted && bForm.mobile.errors}">
        </div>

        <div class="form-group">
          <input type="text" class="form-control"  placeholder="Your Company" formControlName="company" [ngClass]="{'invalid-border': submitted && bForm.company.errors}">
        </div>

        <div class="form-group has-feedback">
          <select formControlName="payment_mode" class="form-control rmv-bdr"  placeholder="Select Payment Mode" [ngClass]="{'invalid-border': submitted && bForm.payment_mode.errors}">
              <option value="" selected="selected">Select Payment Mode</option>
              <option value="OPTCRDC">Credit Card</option>
              <option value="OPTDBCRD">Debit Card</option>
              <option value="OPTNBK">Net Banking</option>
              <option value="Paytm">Paytm</option>
          </select>
      </div>
    
        <input type="submit" value="Submit" class="sbbtn">

      </form>
    </div>
    

    <div class="intro-info">
      <h2>BusinessEx has Partnered with BizEquity <br>
        <div  class="txtfrm">The world’s only patented and the largest provider of business valuations, having
          valued over 33 Million+ Private Businesses globally.</div>
      </h2>

    </div>

  </div>
</section>
<section id="about" class=" sec-slide-effect">
  <div class="container">

    <header class="section-header">
      <h3>What’s your Business Worth?</h3>
      <p></p>
    </header>

    <div class="row about-container">

      <div class="col-xs-12 col-md-6">


        <div class="icon-box wow fadeInUp">
          <div class="icon"><i class="fa fa-briefcase"></i></div>

          <p class="description">If you are like <strong>98% of business owners</strong>, the value of your largest
            asset is a mystery. You wouldn’t start to plan your retirement 30 days before you retire, so why wait to
            find out what your business is worth, right before you sell.</p>
        </div>

        <div class="icon-box wow fadeInUp" data-wow-delay="0.2s">
          <div class="icon"><i class="fa fa-cloud"></i></div>

          <p class="description">Our <strong>cloud-based valuation service</strong> harnesses the power of big data to
            deliver accurate valuations in a fraction of the time and expense of a traditional valuation.</p>
        </div>

        <div class="icon-box wow fadeInUp" data-wow-delay="0.4s">
          <div class="icon"><i class="fa fa-users"></i></div>

          <p class="description">Our service system compares your business to <strong>17 million </strong>others in over
            <strong>30 key areas</strong>. We provide insights that save you time and money while making it easier to
            plan for the future of your business and your life. </p>
        </div>

      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 background order-lg-2 order-1 wow fadeInUp imgblk">
        <img src="./assets/images/side_img.jpg">
      </div>
    </div>

  </div>
</section>


<section id="services" class="section-bg sec-slide-effect">
  <div class="container">

    <header class="section-header">
      <h3>When do you need a Business Valuation?</h3>
      <p></p>
    </header>

    <div class="row setwi">

      <div class="col-xs-12 col-sm-6 col-md-5 col-md-offset-1 wow bounceInUp" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/selling.png" alt="" /></div>
          <h4 class="title">Selling Your Business</h4>

        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-5 wow bounceInUp" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/buy.png" alt="" /></div>
          <h4 class="title">Buying a Business</h4>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-5 col-md-offset-1 wow bounceInUp" data-wow-delay="0.1s"
        data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/fund.png" alt="" /></div>
          <h4 class="title">Seeking Funding</h4>

        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-5 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/plan.png" alt="" /></div>
          <h4 class="title">Planning Your Retirement</h4>

        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-5 col-md-offset-1 wow bounceInUp" data-wow-delay="0.2s"
        data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/tax.png" alt="" /></div>
          <h4 class="title">Tax Purposes</h4>

        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-5 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/checks.png" alt="" /></div>
          <h4 class="title">Litigation Checks</h4>

        </div>

      </div>

    </div>



    <div class="row setwi">

      <div class="col-xs-12 col-sm-6 col-md-5 col-md-offset-1 wow bounceInUp" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/company-2.png" alt="" /></div>
          <h4 class="title">Liquidation of Your Company</h4>
 
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-5 wow bounceInUp" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/family.png" alt="" /></div>
          <h4 class="title">Protecting Your Business & your Family</h4>

        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-5 col-md-offset-1 wow bounceInUp" data-wow-delay="0.1s"
        data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/gain.png" alt="" /></div>
          <h4 class="title">To gain Better Understanding of Your Business</h4>

        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-5 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
        <div class="box">
          <div class="icon"><img src="./assets/images/future.png" alt="" /></div>
          <h4 class="title">Planning a Transition Strategy for the Future of Your Business</h4>

        </div>
      </div>



    </div>

  </div>
</section>

<section id="faqs" class="sec-slide-effect">
  <header class="section-header">
    <h3>Frequently Asked Questions</h3>

  </header>
  <div class="container">

    <div class="row about-container">

      <button class="accordion accordian-arrow">What is Business Valuation?</button>

      <div class="panel faq-ans">
        <p>BusinessEx defines Business Valuation as a process of obtaining a fair economic value of a business. </p>
      </div>

      <button class="accordion accordian-arrow">How long will it take to prepare a Business Valuation Report by
        BusinessEx?</button>

      <div class="panel faq-ans">
        <p>It takes about 20 business days to prepare a complete Business Valuation Report from the date we receive all
          the important documents and information, along with the requisite fees.</p>
      </div>

      <button class="accordion accordian-arrow">What is the cost of a basic Business Valuation Report?</button>
      <div class="panel faq-ans">
        <p>Traditionally, the typical cost of a basic Business Valuation Report is Rs 50,000 in India.</p>
      </div>

      <button class="accordion accordian-arrow">How much does BusinessEx charge for their Business Valuation
        Report?</button>
      <div class="panel faq-ans">
        <p>BusinessEx charges Rs 20,000 inclusive of service tax to prepare a basic Business Valuation Report.</p>
      </div>

      <button class="accordion accordian-arrow">Will I need to pay this amount for any type of Business Valuation
        Report?</button>

      <div class="panel faq-ans">
        <p>The charges of the Business Valuation Report may vary based on your requirement. These charges will be
          adjusted against the minimum amount of Rs 20,000 charged by BusinessEx. Please call our representative today
          to know more.</p>
      </div>

      <button class="accordion accordian-arrow">What will happen when I make the payment at BusinessEx?</button>

      <div class="panel faq-ans">
        <p>Once you make the payment, a representative from our Team will contact you within 2 working days to explain
          the next steps. A meeting will then be scheduled to give you more information about the process and the
          documents required by us to deliver the Business Valuation Report.</p>
      </div>




    </div>
  </div>
</section>
<section id="contact">
  <div class="container">

    <div class="section-header">
      <h3>Contact Us</h3>
    </div>

    <div class="row wow fadeInUp">

      <div class="col-lg-6">
        <div class="map mb-4 mb-lg-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.637887213309!2d77.28094571440371!3d28.490449997249474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1f7c135a39d%3A0x2e7cf3031d63171a!2sBusinessEx%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1568023334445!5m2!1sen!2sin"
            width="100%" height="312" frameborder="0" style="border:0;" allowfullscreen=""></iframe>

        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-12 info">
            <i class="ion-ios-location-outline"></i>
            <p>BusinessEx Solutions Private Limited, <br> Unit No. 601, 6th Floor Pinnacle Tower Behind The Atrium, <br>
              Suraj Kund Rd, <br> Faridabad, Haryana 121009, India</p>
          </div>
          <div class="col-md-12 info">
            <i class="ion-ios-email-outline"></i>
            <p>support@businessex.com</p>
          </div>
          <div class="col-md-12 info">
            <i class="ion-ios-telephone-outline"></i>
            <p>+91 8929353325</p>
          </div>
        </div>


      </div>

    </div>

  </div>
</section>