import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {IAppConfig} from '../../../../config/app-config.interface';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'business-location-details',
    templateUrl: './business-location-details.component.html'
})
export class BusinessLocationDetailsComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    profile_str;
    public BusinessAdvDetailsdata;
    public BusinessTeamDetailsdata;
    public businessProfileSuccessError;
    public businessProfileFailError;
    public userProfileType;
    public ofc_city;
    public ofc_state;
    public ofc_country;
    public location_details: Object;
    private isViewChecked = false;

    getAddress(place: Object) {
        this.location_details = place['formatted_address'];
        var location = place['geometry']['location'];
        this.ofc_city = place['address_components'][0].long_name;
        this.ofc_state = place['address_components'][2].short_name;
        this.ofc_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Business Location Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    businessMyAccountSubmit(data) {
        if ($('form[name="businessForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.business_profile_str = this.profile_str;
        data.ofc_city = this.ofc_city;
        data.ofc_state = this.ofc_state;
        data.ofc_country = this.ofc_country;
        this.http.post(this.config.apiEndpoint + '/updatesellerlocation/' + this.profile_str, data).subscribe(
            data => {
                //console.log("Location Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.businessProfileSuccessError = "Location Details Updated Successfully";
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                this.businessProfileFailError = "Please Enter Correct Details";
            });
    }

    getsellerLocationDetails(profile_str: any): void {
        this.myprofileService.getsellerLocationDetails(this.profile_str)
            .then((result) => {
                this.BusinessTeamDetailsdata = result;
            });
    }

    getUserRegisterProfileType(): void {
        this.myprofileService.getUserRegisterProfileType(this.userID)
            .then((result) => {
                this.userProfileType = result;
            });
    }

    getsellerAdvDetails(profile_str: any): void {
        this.myprofileService.getsellerAdvDetails(this.profile_str)
            .then((result) => {
                this.BusinessAdvDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getsellerLocationDetails(this.profile_str);
        this.getsellerAdvDetails(this.profile_str);
        this.getUserRegisterProfileType();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="businessForm"]').validator();
        }
    }
}
