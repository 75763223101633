<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">
<!-- Profile Detail Left Panel start here -->
<profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
<div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
<div class="bdr">
<div class="cat-list">
<div class="cat-sec-ttl">
    UPGRADE PLAN
</div>
</div>

<div class="margin-20">

<div class="row form-sec" style="padding-bottom: 20px;" *ngIf="planDetails.length > 0">
    <!--Business registered under broker profile-->
    <h4 style="text-align: center" class="tlss">Membership Details</h4>
    <div class="planInfo">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width: 215px;vertical-align: middle;">Profile - Plan</th>
                    <!--<th>Plan</th>-->
                    <th style="vertical-align: middle;">Amount</th>
                    <th>Payment Date</th>
                    <th>Activation|Expiry Date</th>
                    <!--<th>Expiry Date</th>-->
                    <th>Interaction | Insta Credits (Total/Used) </th>
                    <!--<th>Insta Credits (Total/Used) </th>-->
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let planDetail of planDetails">
                    <td data-visual-label="Profile - Plan">
                        {{profiles[planDetail?.profileType]}} - {{planDetail?.plan}}
                    </td>
                    <!--<td data-visual-label="Plan">{{planDetail?.plan}}</td>-->
                    <td data-visual-label="Amount">{{planDetail?.amount}}</td>
                    <td data-visual-label="Payment Date">{{planDetail?.paymentDate}}
                    </td>
                    <td data-visual-label="Activation|Expiry Date">
                        {{planDetail?.planActivationDate}} |
                        {{planDetail?.planExpiryDate}}</td>
                    <!--<td data-visual-label="Expiry Date"></td>-->
<td data-visual-label="Interaction | Insta Credits (availableInteractionCredit/usedInteraCredit/availableInstaCredit/usedInstaCredit)">
<span title="availableInteractionCredit/usedInteraCredit/availableInstaCredit/usedInstaCredit"  style="float: left;margin-left: 25px">
{{planDetail?.availableInteractionCredit}} /
{{planDetail?.usedInteraCredit}}
<br>
{{planDetail?.availableInstaCredit}} /
{{planDetail?.usedInstaCredit}}
</span>

                    </td>
                    <!--<td data-visual-label="Insta Credits (Total/Used)">{{planDetail?.availableInstaCredit}} / {{planDetail?.usedInstaCredit}}</td>-->
                </tr>

            </tbody>
        </table>
    </div>
</div>

<div class="row form-sec" style="padding-bottom: 20px;" *ngIf="addOnDetails.length > 0">
    <!--Business registered under broker profile-->
    <h4 style="text-align: center">Add On Details</h4>
    <div class="planInfo">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="vertical-align: middle;">Profile - Plan</th>
                    <!--<th>Plan</th>-->
                    <th style="vertical-align: middle;">Amount</th>
                    <th>Payment Date</th>
                    <th>Activation|Expiry Date</th>
                    <!--<th>Expiry Date</th>-->
                    <th>Interaction | Insta Credits (Total/Used) </th>
                    <!--<th>Insta Credits (Total/Used) </th>-->
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let planDetail of addOnDetails">
                    <td data-visual-label="Profile - Plan">
                        {{profiles[planDetail?.profileType]}}- {{planDetail?.plan}}</td>
                    <!--<td data-visual-label="Plan">{{planDetail?.plan}}</td>-->
                    <td data-visual-label="Amount">{{planDetail?.amount}}</td>
                    <td data-visual-label="Payment Date">{{planDetail?.paymentDate}}
                    </td>
                    <td data-visual-label="Activation|Expiry Date">
                        {{planDetail?.planActivationDate}} |
                        {{planDetail?.planExpiryDate}}</td>
                    <!--<td data-visual-label="Expiry Date">{{planDetail?.planExpiryDate}}</td>-->
                    <td data-visual-label="Interaction | Insta Credits (Total/Used)">
                        <span style="float: left;margin-left: 25px">
                            {{planDetail?.availableInteractionCredit}} /
                            {{planDetail?.usedInteraCredit}}</span>
                        {{planDetail?.availableInstaCredit}} /
                        {{planDetail?.usedInstaCredit}}</td>
                    <!--<td data-visual-label="Insta Credits (Total/Used)">{{planDetail?.availableInstaCredit}} / {{planDetail?.usedInstaCredit}}</td>-->
                </tr>

            </tbody>
        </table>
    </div>
</div>

<div class="row form-sec">
    <div class="col-xs-12 col-sm-12 col-md-12 addondisablecustom">
        <div class="addondisable" *ngIf="ProfileSelected?.membership_type == 3">
            <div class="overlayblk">
                <div class="overlayblkinner">
                    You already have platinum membership which includes all of the
                    benefits.
                </div>
            </div>
        </div>
        <form name="upgradeSellerForm" #upgradeSellerForm="ngForm"
            (ngSubmit)="saveUpgradeAccount(upgradeSellerForm.value, 'upgradeSellerForm')"
            class="form-horizontal" role="form">
            <div class="sec-slide-effect">
                <div class="row form-group bdr-btm">
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: none">
                        <div class="paymentsec inves">
                            <div class="paymentplan">
                                <div class="radio">
                                    <!--<label><input type="radio" name="membership_plan" #membership_plan="ngModel" ngModel value="0" checked="checked" data-error="Please fill out this field." required onchange="onSelectObj(this)">Basic Plan</label>-->
                                    Current Plan
                                </div>
                            </div>
                            <div class="price free"><span class="amnt">Free of
                                    Cost</span>
                                <div class="sub-desc">No Time Limit</div>
                            </div>
                            <div class="features">
                                <ul>
                                    <li>Profile Listing</li>
                                    <li>Send 5 Business Proposals</li>
                                    <li>Reveal 5 Instant Responses</li>
                                    <li>Connect with your invitations</li>
                                    <li>Get Profile view info</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="mainPayment">
                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 1 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="paymentsec inves"
                                [ngClass]="{'active':ProfileSelected?.membership_type == 1}">
                                <div class="paymentplan"
                                    [style.pointer-events]="(ProfileSelected?.membership_type >= 1) ? 'none': ''">
                                    <div class="radio">
                                        <label><input type="radio"
                                                name="membership_plan"
                                                #membership_plan="ngModel" ngModel
                                                value="1" (change)="removeCoupon()"
                                                data-error="Please Select atleast one"
                                                onchange="onSelectObj(this)"
                                                required>Premium</label>
                                        {{(ProfileSelected?.membership_type ==1)?'(Current Plan)':''}}
                                    </div>
                                </div>
                                <!-- <div class="price"
                                    style="font-size:20px; padding-bottom:0px;">
                                    <span>Rs.</span> <span class="amnt"
                                        style="text-decoration: line-through; color: #666666;">9,999</span>
                                </div> -->
                                <div class="price" style="padding-top:0px;">
                                    <span>Rs.</span>
                                    <span class="amnt"
                                        style="color:#f58220;">5,999</span>
                                    <span style="font-size: small;">(+ 18% GST)</span>
                                    <div class="sub-desc">3 Months</div>
                                </div>
                                <div class="features">
                                    <div class="add-plus">Basic Plan Plus</div>
                                    <ul>
                                        <li>Speedy Profile Activation.</li>
                                        <li>Send 50 Business Proposals.</li>
                                        <li>Receive Unlimited Investment Proposals.</li>
                                        <li>Continuous Email Support.</li>
                                        <li>10 Website Visitor Queries.</li>
                                        <li>Profile Views.</li>
       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="mainPayment">
                            <div class="abr"
                                *ngIf="ProfileSelected?.membership_type > 2 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="paymentsec inves"
                                [ngClass]="{'active':ProfileSelected?.membership_type == 2 || ProfileSelected?.membership_type == 0}">
                                <div class="paymentplan"
                                    [style.pointer-events]="(ProfileSelected?.membership_type >= 2) ? 'none': ''">
                                    <div class="radio">
                                        <label><input type="radio"
                                                name="membership_plan"
                                                #membership_plan="ngModel" ngModel
                                                value="2" (change)="removeCoupon()"
                                                data-error="Please Select atleast one"
                                                onchange="onSelectObj(this)"
                                                required>Gold
                                            <strong
                                                *ngIf="ProfileSelected?.membership_type ==2">(Current
                                                Plan)</strong>
                                            <span
                                                *ngIf="ProfileSelected?.membership_type < 2"
                                                class="recom"
                                                style="font-size: 12px; color: #2377d3;">(Recommend)</span></label>
                                    </div>
                                </div>
                                <!-- <div class="price"
                                    style="font-size:20px; padding-bottom:0px;">
                                    <span>Rs.</span> <span class="amnt"
                                        style="text-decoration: line-through; color: #666666;">15,999</span>
                                </div> -->
                                <div class="price" style="padding-top:0px;">
                                    <span>Rs.</span>
                                    <span class="amnt"
                                        style="color:#f58220;">11,999</span>
                                    <span style="font-size: small;">(+ 18% GST)</span>
                                    <div class="sub-desc">12 Months</div>
                                </div>
                                <div class="features">
                                    <div class="add-plus">Basic Plan Plus</div>
                                    <ul>
                                        <li>Speedy Profile Activation </li>
                                        <li>100 Business Proposals</li>
                                        <li>Receive Unlimited Investment Proposals</li>
                                        <li>Continuous Email Support</li>
                                        <li>20 Website Visitor Queries</li>
                                        <li>Profile Views</li>
                                        <li>Profile Promotion</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="paymentsec inves"
                            [ngClass]="{'active':ProfileSelected?.membership_type == 3}">
                            <div class="paymentplan"
                                [style.pointer-events]="(ProfileSelected?.membership_type === 3) ? 'none': ''">
                                <div class="radio">
                                    <label><input type="radio" name="membership_plan"
                                            #membership_plan="ngModel" ngModel value="3"
                                            (change)="removeCoupon()"
                                            data-error="Please Select atleast one"
                                            onchange="onSelectObj(this)"
                                            required>Platinum</label>
                                    {{(ProfileSelected?.membership_type ==3)?'(Current Plan)':''}}
                                </div>
                            </div>
                            <!-- <div class="price"
                                style="font-size:20px; padding-bottom:0px;">
                                <span>Rs.</span> <span class="amnt"
                                    style="text-decoration: line-through; color: #666666;">24,999</span>
                            </div> -->
                            <div class="price" style="padding-top:0px;"><span>Rs.</span>
                                <span class="amnt" style="color:#f58220;">99,999</span>
                                <span style="font-size: small;">(+Brokerage and GST)</span>
                                <div class="sub-desc">12 Months</div>
                            </div>
                            <div class="features">
                                <div class="add-plus">Basic Plan Plus</div>
                                <ul>
                                    <li><b>Everything in Gold Plan+</b></li>
                                    <li>Key Account Manager</li>
                                    <li>Top Bussinesses/Startups.</li>
                                    <li>Acceleration Marketing</li>
                                    <li>Businesses Valution</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Coupon Section-->
                <div class="stmar">
                    <div class="row form-group">
                        <label class="col-xs-12 col-sm-2 col-md-3 control-label">Promo
                            Code :</label>
                        <div class="col-xs-12 col-sm-4 col-md-5"
                            style="position:relative;">
                            <span class="tag"
                                *ngIf="couponResponse?.status == 'success'">
                                {{upgradeSellerForm.value.coupon_code}}
                                <i class="fa fa-times" aria-hidden="true"
                                    (click)="removeCoupon()"></i></span>
                            <input type="text" name="coupon_code" #coupon_code="ngModel"
                                class="form-control"
                                placeholder="{{(couponResponse?.status !== 'success') ? 'Enter Promo Code': ''}}"
                                ngModel>
                            <input type="button"
                                *ngIf="couponResponse?.status !== 'success'"
                                (click)="submitCoupon(upgradeSellerForm.value)"
                                value="Apply" class="prombtn" />
                            <div class="comman"
                                [ngClass]="{ 'successshow': couponResponse?.status == 'success', 'errorshow': couponResponse?.status == 'error' } "
                                *ngIf="couponResponse?.message">
                                {{couponResponse?.message}}</div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-4 amtpro"
                            *ngIf="couponResponse?.status == 'success'">Checkout Amount:
                            <strong><i class="fa fa-inr" aria-hidden="true"></i>
                                {{couponResponse?.final_price}}</strong></div>
                    </div>
                </div>
                <!--Coupon Section-->
                <!-- <div class="row">
<div class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect txt-cen">
<div class="row">
<div class="col-xs-12 col-sm-12 col-md-2"><strong>Payment Mode</strong></div>
<div class="col-xs-12 col-sm-12 col-md-8"> 
<ul class="bx-payment-mode">
<li><input class="payOption" type="radio" name="payment_mode" value="OPTCRDC">&nbsp;Credit Card</li>
<li><input class="payOption" type="radio" name="payment_mode" value="OPTDBCRD">&nbsp;Debit Card</li>
<li><input class="payOption" type="radio" name="payment_mode" value="OPTNBK">&nbsp;Net Banking </li>
<li><input class="payOption" type="radio" name="payment_mode" value="Paytm">&nbsp;Paytm</li>
</ul>
</div>
</div>

</div>
</div> -->

                <div class="col-xs-12 col-sm-12 col-md-12 row paymentOptions">
                    <div class="col-xs-12 col-sm-12 col-md-3"><strong>Payment Mode
                            *:</strong>
                        <div *ngIf="isSubmitted && payment_mode.errors?.required"
                            class="require">Choose payment mode</div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-9">
                        <ul class="bx-payment-mode">
                            <li><input required data-error="Please fill out this field."
                                    #payment_mode="ngModel" ngModel class="payOption"
                                    type="radio" name="payment_mode"
                                    value="OPTCRDC">&nbsp;Credit Card</li>
                            <li><input required data-error="Please fill out this field."
                                    #payment_mode="ngModel" ngModel class="payOption"
                                    type="radio" name="payment_mode"
                                    value="OPTDBCRD">&nbsp;Debit Card</li>
                            <li><input required data-error="Please fill out this field."
                                    #payment_mode="ngModel" ngModel class="payOption"
                                    type="radio" name="payment_mode"
                                    value="OPTNBK">&nbsp;Net Banking </li>
                            <li><input required data-error="Please fill out this field."
                                    #payment_mode="ngModel" ngModel class="payOption"
                                    type="radio" name="payment_mode"
                                    value="Paytm">&nbsp;Paytm</li>
                        </ul>
                    </div>

                </div>


            </div>
            <!--<div class="sec-slide-effect ttl-amt">
Total Amount :<span><i class="fa fa-inr" aria-hidden="true"></i> 3,499</span>
</div>-->
            <div class="sec-slide-effect txt-cen">
                <div class="submitfrm">
                    <button type="submit" class="btn btn-default btn-blue rm-pad1">
                        SUBMIT
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<add-on [isAddOnPage]="false"></add-on>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<!-- End Start Body Part -->