import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insight-content',
  templateUrl: './insight-content.component.html',
  styleUrls: ['./insight-content.component.css']
})
export class InsightContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
