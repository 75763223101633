import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {MyProfileService} from '../../../../services/my-profile.service';
import {APP_CONFIG, PROFILE_ARRAY} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {Meta, Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'contact-history',
    templateUrl: './contact-history.component.html'
})
export class ContactHistoryComponent implements OnInit {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    ProfileSelected;
    contactHistory;
    contactHistoryLength;
    messageLoading;

    constructor(private meta: Meta,
                private title: Title,
                private myprofileService: MyProfileService,
                private http: HttpClient,
                @Inject(APP_CONFIG)
                private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Proposal Sent - BusinessEx');
    }

    typecheck() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
        }
    }

    getContactHistory(): void {
        this.messageLoading = true;
        this.myprofileService.getContactHistory(this.userID, this.ProfileSelected.profile_type)
            .then((result) => {
                this.messageLoading = false;
                if (result) {
                    this.contactHistory = result;
                    this.contactHistoryLength = result.length;
                }
            });
    }

    ngOnInit() {
        this.typecheck();
        this.getContactHistory();
    }

}
