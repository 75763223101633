
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
<!-- Profile Detail Left Panel start here -->
<profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
<div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
  <div class="bdr">
      <div class="cat-list">
          <div class="cat-sec-ttl">
              MANAGE FINANCIAL DETAILS
          </div>

          <!-- search keywords start -->
          <div class="colorstrip">
              <ul>
                  <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                  <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                  <li><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                  <li class="selected"><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                  <li><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                  <li><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                  <li><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                  <li><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                  <li><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
              </ul>
          </div>
          <!-- search keywords end -->

      </div>
      <div class="margin-20">
          <div class="row form-sec">
              <div class="col-xs-12 col-sm-12 col-md-12">
                  <form id="startupForm" name="startupForm" #startupForm="ngForm"
                        (ngSubmit)="startupForm.form.valid && startupMyAccountSubmit(startupForm.value)"
                        class="form-horizontal" role="form">

                      <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                           style="margin-bottom:20px; text-align:center;">
                          {{startupProfileSuccessError}}
                      </div>
                      <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                           style="margin-bottom:20px; text-align:center;">
                          {{startupProfileFailError}}
                      </div>

                      <div class="sec-slide-effect">

                          <div class="lookingfr dflt">
                              <div class="row">
                                  <div class="col-xs-12">
                                      <div class="frm-sec-ttl">Financial details</div>
                                  </div>
                              </div>

                              <div class="input-sec">
                                  <div class="row txt-center">
                                      <div class="col-xs-12 col-sm-4  col-md-4">
                                          <div class="">
                                              <div class="">
                                                  <label class="col-xs-12 col-sm-12 col-md-12 control-label">Annual
                                                      Sales<span class="star">*</span></label>
                                                  <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                                                      <div class="input-group">
                                                          <input type="text" pattern="[0-9]+"
                                                                 data-minlength="5" maxlength="12"
                                                                 class="form-control"
                                                                 name="annual_sales"
                                                                 [ngModel]="startupFinancialdata?.annual_sales"
                                                                 placeholder=""
                                                                 data-error="Please enter annual sales."
                                                                 required>
                                                          <span class="glyphicon form-control-feedback"
                                                                aria-hidden="true"></span>
                                                      </div>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-xs-12 col-sm-4 col-md-4">
                                          <div class="">
                                              <div class="">
                                                  <label class="col-xs-12 col-sm-12 col-md-12 control-label">Inventory
                                                      Value<span class="star">*</span></label>
                                                  <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                                                      <div class="input-group">
                                                          <input type="text" pattern="[0-9]+"
                                                                 data-minlength="5" maxlength="12"
                                                                 name="inventory_value"
                                                                 [ngModel]="startupFinancialdata?.inventory_value"
                                                                 class="form-control"
                                                                 placeholder=""
                                                                 data-error="Please enter inventory value."
                                                                 required>
                                                          <span class="glyphicon form-control-feedback"
                                                                aria-hidden="true"></span>
                                                      </div>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-xs-12 col-sm-4 col-md-4">
                                          <div class="">
                                              <div class="">
                                                  <label class="col-xs-12 col-sm-12 col-md-12 control-label">Gross
                                                      Income<span class="star">*</span></label>
                                                  <div class="col-xs-12 col-sm-12 col-md-12">
                                                      <div class="input-group">
                                                          <input pattern="[0-9]+" data-minlength="5"
                                                                 maxlength="12"
                                                                 class="form-control"
                                                                 name="gross_profit"
                                                                 [ngModel]="startupFinancialdata?.gross_profit"
                                                                 placeholder="" type="text"
                                                                 data-error="Please enter gross income."
                                                                 required>
                                                          <span class="glyphicon form-control-feedback"
                                                                aria-hidden="true"></span>
                                                      </div>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row txt-center">
                                      <div class="col-xs-12 col-sm-4 col-md-4">
                                          <div class="">
                                              <div class="">
                                                  <label class="col-xs-12 col-sm-12 col-md-12 control-label">EBITDA<span
                                                          class="star">*</span></label>
                                                  <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                                                      <div class="input-group">
                                                          <input type="text" pattern="[0-9]+"
                                                                 data-minlength="5" maxlength="12"
                                                                 class="form-control"
                                                                 name="ebitda"
                                                                 [ngModel]="startupFinancialdata?.ebitda"
                                                                 placeholder=""
                                                                 data-error="Please enter ebitda."
                                                                 required>
                                                          <span class="glyphicon form-control-feedback"
                                                                aria-hidden="true"></span>
                                                      </div>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-xs-12 col-sm-4 col-md-4">
                                          <div class="">
                                              <div class="">
                                                  <label class="col-xs-12 col-sm-12 col-md-12 control-label">EBITDA
                                                      Margin<span class="star">*</span></label>
                                                  <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                                                      <div class="input-group">
                                                          <input type="text" pattern="[0-9]+"
                                                                 data-minlength="5" maxlength="12"
                                                                 name="ebitda_margin"
                                                                 [ngModel]="startupFinancialdata?.ebitda_margin"
                                                                 class="form-control"
                                                                 placeholder=""
                                                                 data-error="Please enter ebitda margin."
                                                                 required>
                                                          <span class="glyphicon form-control-feedback"
                                                                aria-hidden="true"></span>
                                                      </div>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-xs-12 col-sm-4 col-md-4">
                                          <div class="">
                                              <div class="">
                                                  <label class="col-xs-12 col-sm-12 col-md-12 control-label">Rentals<span
                                                          class="star">*</span></label>
                                                  <div class="col-xs-12 col-sm-12 col-md-12 pad-bottom">
                                                      <div class="input-group">
                                                          <input pattern="[0-9]+" data-minlength="5"
                                                                 maxlength="12"
                                                                 class="form-control"
                                                                 name="rentals"
                                                                 [ngModel]="startupFinancialdata?.rentals"
                                                                 placeholder="" type="text"
                                                                 data-error="Please enter rentals."
                                                                 required>
                                                          <span class="glyphicon form-control-feedback"
                                                                aria-hidden="true"></span>
                                                      </div>
                                                      <div class="help-block with-errors"></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                          <div class="row form-group setmmy">
                              <label class="col-xs-12 col-sm-6 col-md-4 control-label">Fund raising information<span>:</span></label>
                              <div class="col-xs-12 col-sm-6 col-md-7">
                                  <div class="clonebox box_container1" id="count_clone1">
                                      <div class="row entry pofa" *ngFor="let fund of startupFinancialdata?.fundDetails; let i=index;" [id]="'clone_' + i">
                                          <div class="col-xs-10 col-sm-12 col-md-12">
                                              <div class="row">
                                                  <div class="col-xs-12 col-sm-6 col-md-6 pad-bottom " style="margin-right: 0px; margin-left: 0px;">
                                                     
                                                          <div class="input-group tl-tip">
                                                             <!--  <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Select the number of years of industry experience "><i class="fa fa-info" aria-hidden="true"></i></div> -->
                                                              <select [disabled]="fund?.fund_stage !=''" class="form-control" name="fund_stage">
                                                                  <option value="" selected>Round of funding </option>
                                                                  <option id="rase_0" value="1" [selected]="1===fund?.fund_stage" >Seed Funding</option>
                                                                  <option id="rase_1" value="2" [selected]="2===fund?.fund_stage" >Series A</option>
                                                                  <option id="rase_2" value="3" [selected]="3===fund?.fund_stage" >Series B</option>
                                                                  <option id="rase_3" value="4" [selected]="4===fund?.fund_stage" >Series C</option>
                                                              </select>
                                                          </div>
                                                      
                                                  </div>
                                                  <div class="col-xs-12 col-sm-6 col-md-6" style="margin-right: 0px; margin-left: 0px;">
                                                   
                                                          <div class="input-group">
                                                              <input type="text" name="fund_amount" value="{{fund.fund_amount}}" class="form-control" placeholder="Investment amount">
                                                              <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                          </div>
                                                  
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-pad-left posrt">
                                              <a *ngIf="i===0" id="add_0" [routerLink]="" class="addbox clone_add tppadding1" onclick="cloneDiv1('#count_clone1')"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                              <a [id]="'rem_'+ i" style="display:none;" id="rem_0" [routerLink]="" onClick="removeFormdiv1(this.id);" class="removebox tppadding1" [style.display]="( i>0)? 'table':'none'"><i class="fa fa-times" aria-hidden="true"></i></a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="sec-slide-effect txt-cen">
                          <div class="submitfrm">
                              <button type="submit" class="btn btn-default btn-blue">SUBMIT
                              </button>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
<!-- End Start Body Part -->
