import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'startup-other',
    templateUrl: './startup-others.component.html'
})
export class StartupOtherComponent implements OnInit, AfterViewChecked {

    profile_str;
    public startupOtherdata;
    public startupProfileSuccessError;
    public startupProfileFailError;
    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Startup Other Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    companystage = [
        {id: 1, name: "Idea & Concept Stage"},
        {id: 2, name: "Development Stage"},
        {id: 3, name: "Scaling Up Stage"},
        {id: 4, name: "Full Business Established"}
    ];

    startupMyAccountSubmit(data) {
        if ($('form[name="startupForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.startup_profile_str = this.profile_str;
        this.http.post(this.config.apiEndpoint + '/updatestartupother/' + this.profile_str, data).subscribe(
            data => {
                console.log("Startup Other Details Updated Successfully");
                $("#contactUsForm .form-control").val("");

                this.startupProfileSuccessError = "Startup Other Details Updated Successfully";
                $("html, body").animate({scrollTop: 0}, "slow");
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                console.log("Please Enter Correct Details");
                console.log(error);
                this.startupProfileFailError = "Please Enter Correct Details";
            });
    }

    gestartupOther(profile_str: any): void {
        this.myprofileService.gestartupOther(this.profile_str)
            .then((result) => {
                this.startupOtherdata = result;
            });
    }

    ngOnInit() {
        this.gestartupOther(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="startupForm"]').validator();
        }
    }

}
