import { HttpClient } from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Observable, pipe } from 'rxjs';
import {APP_CONFIG} from '../config/app-config.constants'
import {IAppConfig} from '../config/app-config.interface'
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn:'root'
})

export class AuthenticationService{
    constructor(private http:HttpClient,
        @Inject(APP_CONFIG) private config:IAppConfig,
        @Inject(PLATFORM_ID) private platformId:Object){
        }

    login(username:string,password:string):Observable<any>{
        return this.http.post(this.config.apiEndpoint+'/auth/login',{email:username,password:password}).pipe(map((respose)=>{
            if(respose && respose.hasOwnProperty('token')){
                localStorage.setItem('currentUser',JSON.stringify(respose['token']));
            }
            return respose;
        }))
        
    }

    signup(regmail:string,regpassword:string,subscribe:number):Observable<any>{
        return this.http.post(this.config.apiEndpoint+'/auth/signup',{
            email:regmail,
            password:regpassword,
            subscribe:subscribe
        }).pipe(map((response)=>{
            if(response && response.hasOwnProperty('token')){
                localStorage.setItem('currentUser', JSON.stringify(response['token']));
            }
            return response;
        }))
    }

    forgot(username:string):Observable<any>{
        return this.http.post(this.config.apiEndpoint+'/password/email',{email:username})
    }

    logout(){
        localStorage.removeItem('currentUser');
    }

    public isAuthenticated(){
        
        if(isPlatformBrowser(this.platformId)){
            const currentUser = JSON.parse(localStorage.getItem('currentUser'))
            if(currentUser){
                return true
            }
        }
        return false;
    }
}


