
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                             
<!-- Profile Detail Left Panel start here -->
    <profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
<div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
<div class="bdr">   
<div class="cat-list">
<div class="cat-sec-ttl">
    <div *ngIf="userProfileType?.reg_profile == 'Other' || userProfileType?.reg_profile == null || userProfileType?.reg_profile == ''">
        MANAGE ADVERTISEMENT INFORMATION      
    </div>    
    <div *ngIf="userProfileType?.reg_profile == 'Broker'">
        <div><a routerLink="/dashboard/brokerbusinesslisting">Business Listing</a></div>
        {{BusinessAdvDetailsdata.advmt_headline}}                               
    </div>    
</div>

    <!-- search keywords start -->
    <div class="colorstrip">
        <ul>
            <li><a routerLink="/dashboard/businessConfidential/{{profile_str}}">Confidential Info</a></li>
            <li class="selected"><a routerLink="/dashboard/businessAdvDetails/{{profile_str}}">Advert Details</a></li>
            <li><a routerLink="/dashboard/businessInformation/{{profile_str}}">Business Info</a></li>
            <li><a routerLink="/dashboard/businessFinanceDetails/{{profile_str}}">Financial Details</a></li>
            <li><a routerLink="/dashboard/businessTeamDetails/{{profile_str}}">Team Details</a></li>
            <li><a routerLink="/dashboard/businessLocationDetails/{{profile_str}}">Headquarters</a></li>
            <li><a routerLink="/dashboard/businessRequirement/{{profile_str}}">Requirements</a></li>
            <li><a routerLink="/dashboard/businessAttachment/{{profile_str}}">Attachments</a></li>
        </ul>
    </div>
    <!-- search keywords end -->
    
</div>         
<div class="margin-20">
<div class="row form-sec">
<div class="col-xs-12 col-sm-12 col-md-12">
<form #businessForm="ngForm" (ngSubmit)="businessForm.form.valid && businessMyAccountSubmit(businessForm.value)" class="form-horizontal"  name="businessForm" role="form">
<div class="sec-slide-effect">
    
    <div class="msg-pnl_sales" *ngIf="businessProfileSuccessError" style="margin-bottom:20px; text-align:center;">{{businessProfileSuccessError}}</div> 
    <div class="msg-pnl_sales" *ngIf="businessProfileFailError" style="margin-bottom:20px; text-align:center;">{{businessProfileFailError}}</div> 
    
    <!-- <div class="row">
        <div class="col-xs-12"><div class="frm-sec-ttl">Advertisement Details</div></div>                                          
    </div> -->
    <div class="row form-group has-feedback">
        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Advertisement Headline<span class="star">*</span> <span>:</span></label>
        <div class="col-xs-12 col-sm-6 col-md-7">
            <div class="input-group">
                <input type="text" class="form-control" name="advmt_headline"  [ngModel]="BusinessAdvDetailsdata?.advmt_headline" placeholder="Enter Advertisement Headline" data-error="Please enter advertisement headline." required>
                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
            </div>
            <div class="help-block with-errors"></div>
        </div>
    </div>
    <div class="row form-group">
        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Introduction <span>:</span></label>
        <div class="col-xs-12 col-sm-6 col-md-7">
            <div class="input-group">
                    <textarea  class="form-control" name="seller_intro"  [ngModel]="BusinessAdvDetailsdata?.seller_intro" rows="3"  placeholder=""></textarea>                                                    
            </div>                                                
        </div>
    </div>
</div>                                       
<div class="sec-slide-effect txt-cen">                                  
    <div class="submitfrm">
        <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
    </div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>            
</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->

