
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MENTOR PROFILE
                                </div>
                            </div>
                            <div class="margin-20 cat-detail investor">
                                <div class="row form-sec">

                                    <div class="col-xs-12 col-sm-12 col-md-12 scroll-ph sec-slide-effect">
                                        <div class="page-ttl"><h1>{{mentorProfileData?.mentor_adv_headline}}</h1></div>
                                        <div class="shrt-desc tp-border">
                                            {{mentorProfileData?.mentor_intro}}
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col-xs-12 col-sm-4">
                                            <div class="investor-profile">
                                                <img class="img-responsive"
                                                     *ngIf="mentorProfileData?.mentor_profile_pic != ''"
                                                     src={{mentorProfileData?.mentor_profile_pic}}/>
                                                <img class="img-responsive"
                                                     *ngIf="mentorProfileData?.mentor_profile_pic == '' || mentorProfileData?.mentor_profile_pic == null"
                                                     src="assets/images/userprofilepic.gif"/>
                                            </div>
                                        </div>

                                        <div class="col-xs-12 col-sm-8 col-md-8">

                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                <div class="investor-name">{{mentorProfileData?.mentor_name}}
                                                    <span class="pipe">|</span>
                                                    <span class="location">
                            <i class="fa fa-map-marker" aria-hidden="true"></i> {{mentorProfileData?.mentor_city}}, {{mentorProfileData?.mentor_state}}, {{mentorProfileData?.mentor_country}}
                        </span>
                                                </div>
                                                <div class="overview">{{mentorProfileData?.mentor_designation}}</div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                <div class="investor-contacts">
                                                    <div class="contact-ph">MOBILE <span>{{mentorProfileData?.mentor_mobile}}</span>
                                                    </div>
                                                    <div class="contact-email">EMAIL <span>{{mentorProfileData?.mentor_email}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Company / College Name:
                                                <div class="overview inline">
                                                    {{ (mentorProfileData?.mentor_company !== '') ?
                                                    mentorProfileData?.mentor_company : ("N/A") }}
                                                    <span class="pipe">|</span>
                                                    <span class="location"><i class="fa fa-map-marker"
                                                                              aria-hidden="true"></i>
                            {{mentorProfileData?.mentor_city}}, {{mentorProfileData?.mentor_state}}, {{mentorProfileData?.mentor_country}}
                        </span>
                                                </div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Designation / Title:
                                                <div class="overview inline">
                                                    {{ (mentorProfileData?.mentor_designation !== '') ?
                                                    mentorProfileData?.mentor_designation : ("N/A") }}
                                                </div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Professional Summary:
                                                <div class="overview">
                                                    {{ (mentorProfileData?.mentor_profile_summary !== '') ?
                                                    mentorProfileData?.mentor_profile_summary : ("N/A") }}
                                                </div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect">
                                                Professional Experience
                                                <div class="tabulardata">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>Sector</th>
                                                            <th>Years</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr *ngFor="let experience of mentorProfExperience">
                                                            <td data-th="Sector">{{experience.category}}</td>
                                                            <td data-th="Years">{{experience.year}} Years</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect"
                                                 *ngIf="mentorProfileData?.subjectExpertise != ''">
                                                Subject Expertise
                                                <div class="overview">
                                                    {{mentorProfileData?.subjectExpertise}}
                                                </div>
                                            </div>

                                            <div class="business-detail-sec sec-slide-effect already-visible come-in"
                                                 *ngIf="mentorProfileData?.sectorPreference != ''">
                                                Sector Preference
                                                <div class="overview">
                                                    {{mentorProfileData?.sectorPreference}}
                                                </div>
                                            </div>


                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
