import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';
import {ContactService} from '../../../../services/contact.service';
import {appConfig} from '../../../frontend/app.config';
import {MapsAPILoader} from "@agm/core";

declare var $: any;

@Component({
    selector: 'lender-preference',
    templateUrl: './lender-preference.component.html'
})

export class LenderPreferenceComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    profile_str;
    public lenderPreferencedata;
    public lenderProfileSuccessError;
    public lenderProfileFailError;
    public bxLenderType;

    private isViewChecked = false;
    private industryCategoryData;

    constructor(meta: Meta, title: Title, private http: HttpClient,
                private myprofileService: MyProfileService, route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private contactService: ContactService,
                private mapsAPILoader: MapsAPILoader) {
        title.setTitle('Lender Preference Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    lenderMyAccountSubmit(data) {

        if ($('form[name="lenderForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        if ($('#ind_pref').val().length === 0) {
            $('#ind_pref').parent().css('border', '1px solid red');
            return;
        }
        $('#ind_pref').parent().css('border', 'none');

        data.industry_pref1 = appConfig.getFormattedValues('#ind_pref', this.industryCategoryData);
        data.location_pref1 = $("#lenderlocationPreference1").val();
        data.lender_profile_str = this.profile_str;

        this.http.post(this.config.apiEndpoint + '/updatelenderPreference/' + this.profile_str, data).subscribe(
            data => {
                //console.log("Lender Preference Details Updated Successfully");
                $("#contactUsForm .form-control").val("");

                this.lenderProfileSuccessError = data;

                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);

            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.lenderProfileFailError = "Please Enter Correct Details";
            });
    }

    getcheckBxLenderType(): void {
        this.myprofileService.getcheckBxLenderType(this.userID, this.profile_str)
            .then((result) => {
                this.bxLenderType = result;
            });
    }

    getlenderPreference(): void {
        this.myprofileService.getlenderPreference(this.profile_str)
            .then((result) => {
                this.lenderPreferencedata = result;
                if (isPlatformBrowser(this.platformId)) {
                    $(".LocationPrefrencetagit").tagit(appConfig.mapTagItConfiguration(this.mapsAPILoader));
                    this.lenderPreferencedata.location_pref.forEach(item => {
                        $('.LocationPrefrencetagit').tagit("createTag", item.location_name);
                    });
                    $('#ind_pref').tagit({allowSpaces: true});
                    this.lenderPreferencedata.ind_pref.forEach(item => {
                        $('#ind_pref').tagit("createTag", item.name);
                    });
                }
            });
    }

    ngOnInit() {
        this.getlenderPreference();
        this.getcheckBxLenderType();
        this.getIndustrySector();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="lenderForm"]').validator();
        }
    }

    getIndustrySector(): void {
        this.contactService.getIndustrySector()
            .then((result) => {
                this.industryCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.industryCategoryData);
                    setTimeout(function () {
                        $('#ind_pref').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }

}
