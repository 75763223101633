import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APP_CONFIG, BANNER_PAGE_LOCATION, BASE_URL, MENTOR_CONTACT_PROFILE_TYPE, OVERALL_INSTA_QUERY_LIMIT, OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE, PROFILE_REGISTRATION_URL, PROFILE_TYPE, SINGLE_DAY_INSTA_QUERY_LIMIT, SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';

declare var $: any;

@Component({
  selector: 'app-mentor-contact',
  templateUrl: './mentor-contact.component.html',
  styleUrls: ['./mentor-contact.component.css']
})
export class MentorContactComponent implements OnInit {

  jwtHelper = new JwtHelperService();
    token;
    decode;
    userID;
    Profile;
    ProfileCheck;
    category;
    profile_str;
    instaContactError;
    instaProposalError;
    profileActiveStatus = '';
    profilePendingStatus = '';
    pendingProfileStr;
    sellerUpgProfUniqStr;
    sellerUpgProfName;
    public mentorProfileData;
    public mentorProfExperience;
    public SearchHistorydata;
    public SavedSearchdata;
    public bxContactCount;
    public bxProposalResult;
    public profileCreditBalance;
    public bxBookmarkCount;
    public bxBookmarkStatus = 0;
    public mentorDataList;
    public isViewChecked = false;
    public bxbannerrightside;
    title = 'Contact Mentor';
    profileMembership = 0;
    mentorProfileExistCheck = '';
    contact_profile_type = MENTOR_CONTACT_PROFILE_TYPE;
    public appConfig = appConfig;
    isFreeCreditExpired = false;
    public singleDayCount;
    public totalCount;
    contactLimitExceedMessage = SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE;
    singleDayLimit = SINGLE_DAY_INSTA_QUERY_LIMIT;
    overallLimit = OVERALL_INSTA_QUERY_LIMIT;
    creditBalance = [];
    moderatorContactStatus = 0; // status 4 means: profile users are not interested to make payment but allowing them to send insta query

    loading = false;

    constructor(private linkService: LinkService,
                private meta: Meta, private titleService: Title,
                public http: HttpClient, private contactService: ContactService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private route: ActivatedRoute,
                private router: Router,
                @Inject(APP_CONFIG) private config: IAppConfig) {
    }

    checkLoginToken(): void {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.userID = this.decode.sub;
            this.Profile = this.decode.userProfile;
            this.ProfileCheck = this.decode.userProfile[0].profile_id;
            this.saveSearchHistory();
            this.checkBxContactBusiness();
            this.checkBxProposalCount();
            this.checkBxBookmark();
            this.getProfileData();
        } else {
            this.Profile = [{
                'profile_id': 0,
                'profile_type': '',
                'profile_status': '',
                'profile_name': '',
                'profile_str': '',
                'payment_status': 0
            }];
            this.ProfileCheck = this.Profile[0].profile_id;
            this.userID = 0;
            this.bxContactCount = 0;
            if (isPlatformBrowser(this.platformId)) {
                $('#login').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                $('#login .close').css('display', 'none');
            }
        }
    }

    toggle(wantBookMark): any {
        this.bxBookmarkStatus = wantBookMark;
        this.contactService.saveSavedSearches(this.userID, PROFILE_TYPE.Mentor, this.profile_str, wantBookMark)
            .then((result) => {
                this.SavedSearchdata = result;
            });
    }

    getProfileData(): any {
        this.Profile.forEach((key) => {
            if (key.profile_type === PROFILE_TYPE.Mentor && key.profile_str === this.profile_str) {
                this.mentorProfileExistCheck = 'UserProfile';
            }
            if (key.profile_type === PROFILE_TYPE.Business || key.profile_type === PROFILE_TYPE.Startup) {
                if (key.profile_status === 1) {
                    this.profileActiveStatus = key.profile_name;
                    this.sellerUpgProfName = key.profile_name;
                    this.sellerUpgProfUniqStr = key.profile_str;
                    this.profileMembership = key.membership_type;
                } else {
                    this.profilePendingStatus = key.profile_name;
                    this.pendingProfileStr = key.profile_str;
                    this.moderatorContactStatus = key.moderatorContactStatus;
                }
            }
        });
    }

    contactProposalFormSubmit(data, receiverId, profileStr) {
        const formSelector = $('form[name="contactProposalForm"]');
        if (formSelector.validator('validate').has('.has-error').length || this.profileCreditBalance <= 0) {
            return;
        }
        data.sender = this.userID;
        data.receiver = receiverId;
        data.profileStr = profileStr;
        data.sender_profile_type = data.ProfileSelected;
        data.receiver_profile_type = PROFILE_TYPE.Mentor;
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/contactProposal', data).subscribe(
            (response) => {
                // storing the new token in local storage. No need to logout and login for loading new profiles of user
                /*localStorage.removeItem('currentUser');
                localStorage.setItem('currentUser', JSON.stringify(response['token']));
                this.token = localStorage.getItem('currentUser');
                this.decode = this.jwtHelper.decodeToken(this.token);
                this.userID = this.decode.sub;
                this.Profile = this.decode.userProfile;*/

                $('#mentorproposalForm .form-control').val('');
                $('#mentorproposalForm').css('display', 'none');
                $('#thankyouproposal').css('display', 'block');
            },
            error => {
                this.instaProposalError = error.error;
                appConfig.scrollToError(false);
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    contactProfileTypeSubmit(data) {
        if ($('form[name="contactProfileTypeForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        this.router.navigate([PROFILE_REGISTRATION_URL[data.profile_type]]);
    }

    contactFormMentorSubmit(data, profileId, profileStr) {
        const formSelector = $('form[name="contactFormMentor"]');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        data.user_id = this.userID;
        data.profile_id = profileId;
        data.profile_str = profileStr;
        data.subscribe = (data.subscribe === false) ? 0 : 1;
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/contactthementor', data).subscribe(
            dataResponse => {
                $('#contactForm .form-control').val('');
                $('#contactform').css('display', 'none');
                $('#contactthankyou').css('display', 'block');
            },
            error => {
                this.instaContactError = error.error;
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    getMentorSimiliar(userId): void {
        this.contactService.getMentorSimiliar(userId)
            .then((result) => {
                this.mentorDataList = result.mentorSimilarData;
            });
    }

    saveSearchHistory(): void {
        this.contactService.saveSearchHistory(this.userID, PROFILE_TYPE.Mentor, this.profile_str)
            .then((result) => {
                this.SearchHistorydata = result;
            });
    }

    checkBxContactBusiness(): void {
        this.contactService.checkBxContactMentor(this.userID, this.profile_str)
            .then((result) => {
                this.bxContactCount = result.count;
                this.singleDayCount = result.singleDayCount;
                this.totalCount = result.totalCount;
                if (this.totalCount >= OVERALL_INSTA_QUERY_LIMIT) {
                    this.contactLimitExceedMessage = OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE;
                }
            });
    }

    checkBxProposal(): void {
        this.contactService.checkBxProposal(this.userID, PROFILE_TYPE.Mentor, this.profile_str, this.mentorProfileData.user_id)
            .then((result) => {
                this.bxProposalResult = result;
            });
    }

    checkBxProposalCount(): void {
        this.contactService
            .checkBxProposalCount(this.userID, [PROFILE_TYPE.Investor, PROFILE_TYPE.Lender, PROFILE_TYPE.Mentor, PROFILE_TYPE.Incubation])
            .then((result) => {
                this.creditBalance = result.creditBalance;
            });
    }

    checkBxBookmark(): void {
        this.contactService.checkBxBookmark(this.userID, PROFILE_TYPE.Mentor, this.profile_str)
            .then((result) => {
                this.bxBookmarkCount = result.count;
                this.bxBookmarkStatus = result.checked;
            });
    }

    getBanners(): void {
        this.contactService.getBanners(BANNER_PAGE_LOCATION.MentorContact)
            .then((result) => {
                this.bxbannerrightside = result.right;
            });
    }

    ngOnInit() {
        this.loading = true;
        this.route.paramMap.subscribe((params: Params) => {
            this.getBanners();
            this.profile_str = params.get('mentorUniqueId');
            this.category = params.get('category');
            this.checkLoginToken();
            this.contactService.getMentorContactDetails(this.profile_str, this.userID)
                .then((result) => {
                    if (result) {
                        this.mentorProfileData = result.mentorData;
                        //console.log(this.mentorProfileData)
                        this.mentorProfExperience = result.profExperience;
                        if (this.mentorProfileData.slug !== this.category ||
                            this.mentorProfileData.mentor_profile_str !== this.profile_str) {
                            this.router
                                .navigate(['/mentor/' + this.mentorProfileData.slug + '/' + this.mentorProfileData.mentor_profile_str]);
                        }
                        this.checkBxProposal();
                        this.setMetaTitle();
                        this.getMentorSimiliar(this.mentorProfileData.user_id);
                    }
                    this.loading = false;
                }, (error) => {
                    if (error.status === 404) {
                        this.router.navigate(['/mentorlisting']);
                    }
                    this.loading = false;
                });
        });
    }

    private setMetaTitle() {
        this.titleService.setTitle(this.mentorProfileData.mentor_adv_headline + '- BusinessEx');
        this.meta.updateTag({name: 'Keywords', content: 'Mentor - BusinessEx'});

        this.meta.updateTag({
        property: 'og:title',
        content:this.mentorProfileData.mentor_adv_headline + '- BusinessEx'
        });

        this.meta.updateTag({
        name: 'twitter:title',
        content:this.mentorProfileData.mentor_adv_headline + '- BusinessEx'
        });

        this.meta.updateTag({property: 'og:url', content: BASE_URL +this.router.url});
        this.meta.updateTag({name: 'twitter:url', content: BASE_URL + this.router.url});

          
        this.meta.updateTag(
            {
                name: 'Description',
                content: 'BusinessEx provides mentor with a wide range of businesses and ' +
                'startups looking to sell or expand their businesses from all across the ' +
                'country in 13 different industries'
            }
        );

        this.meta.updateTag({ name: 'twitter:description', content: 'BusinessEx provides mentor with a wide range of businesses and ' +
        'startups looking to sell or expand their businesses from all across the ' +
        'country in 13 different industries'});
        
        this.meta.updateTag({ property: 'og:description', content: 'BusinessEx provides mentor with a wide range of businesses and ' +
        'startups looking to sell or expand their businesses from all across the ' +
        'country in 13 different industries'});


        this.linkService.removeCanonicalLink();
        this.linkService.addTag({
            rel: 'canonical',
            href: BASE_URL + '/mentor/' + this.category + '/' + this.profile_str
        });
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="contactFormMentor"]').validator();
            $('form[name="contactProposalForm"]').validator();
            $('form[name="contactProfileTypeForm"]').validator();
        }
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            $('#login').modal('hide');
        }
    }

    onChangeObj($event) {
        if (this.Profile === undefined) {
            return;
        }
        const selectedProfile = this.Profile.filter(item => (item.profile_type == $event))[0];
        localStorage.setItem('ProfileType', selectedProfile.profile_name); // need to show the appropriate payment plan
        this.isFreeCreditExpired = (parseInt(selectedProfile.isFreeCreditExpired, 10) === 1);
        const selectedProfileCredits = this.creditBalance.filter(item => (item.profileType == $event))[0];
        this.profileCreditBalance = (selectedProfileCredits !== undefined) ? selectedProfileCredits.interactionCreditBalance : 0;
    }
    
}
