
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE ATTACHMENT DETAILS
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                                        <li><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                                        <li><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                                        <li><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                                        <li><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                                        <li class="selected"><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="startupForm" #startupForm="ngForm"
                                              (ngSubmit)="startupMyAccountSubmit(startupForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business Photo<span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group image-preview">
                                                            <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                                                            <span class="input-group-btn">
                                                                <button type="button" (click)="removeUploadEvent('file',$event)" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                                                        <span class="glyphicon glyphicon-remove"></span> Clear
                                                                    </button>
                                                                <!-- image-preview-input -->
                                                                <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                                                    <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                                    <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                                    <input type="file" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="input-group image-preview">
                                                            <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                                                            <span class="input-group-btn">
                                                                <button type="button" (click)="removeUploadEvent('file',$event)" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                                                        <span class="glyphicon glyphicon-remove"></span> Clear
                                                                    </button>
                                                                <!-- image-preview-input -->
                                                                <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                                                    <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                                    <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                                    <input type="file" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="input-group image-preview">
                                                            <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                                                            <span class="input-group-btn">
                                                                <button type="button" (click)="removeUploadEvent('file',$event)" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                                                        <span class="glyphicon glyphicon-remove"></span> Clear
                                                                    </button>
                                                                <!-- image-preview-input -->
                                                                <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                                                    <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                                    <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                                    <input type="file" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="input-group image-preview">
                                                            <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                                                            <span class="input-group-btn">
                                                                <button type="button" (click)="removeUploadEvent('file',$event)" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                                                        <span class="glyphicon glyphicon-remove"></span> Clear
                                                                    </button>
                                                                <!-- image-preview-input -->
                                                                <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                                                    <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                                    <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                                    <input type="file" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="input-group image-preview">
                                                            <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                                                            <span class="input-group-btn">
                                                                <button type="button" (click)="removeUploadEvent('file',$event)" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                                                        <span class="glyphicon glyphicon-remove"></span> Clear
                                                                    </button>
                                                                <!-- image-preview-input -->
                                                                <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                                                    <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                                    <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                                    <input type="file" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Business Documents<span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group doc-preview">
                                                            <input type="text" class="form-control doc-preview-filename"placeholder="Accepted formats - Word Document, Excel & PDF" disabled="disabled">
                                                            <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default rm-img doc-preview-clear" (click)="removeUploadEvent('doc',$event)" style="display:none;">
                                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                                        </button>
                                                                <!-- image-preview-input -->
                                                    <div class="btn btn-default btn-blue btn-file rm-img doc-preview-input">
                                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                        <span class="doc-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                        <input type="file" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" (change)="docChangeEvent($event)" name="input-file-preview"/> <!-- rename it -->
                                                    </div>
                                                </span>
                                                        </div>
                                                        <div class="input-group doc-preview">
                                                            <input type="text" class="form-control doc-preview-filename"placeholder="Accepted formats - Word Document, Excel & PDF" disabled="disabled">
                                                            <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default rm-img doc-preview-clear" (click)="removeUploadEvent('doc',$event)" style="display:none;">
                                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                                        </button>
                                                                <!-- image-preview-input -->
                                                    <div class="btn btn-default btn-blue btn-file rm-img doc-preview-input">
                                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                        <span class="doc-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                        <input type="file" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" (change)="docChangeEvent($event)" name="input-file-preview"/> <!-- rename it -->
                                                    </div>
                                                </span>
                                                        </div>
                                                        <div class="input-group doc-preview">
                                                            <input type="text" class="form-control doc-preview-filename"placeholder="Accepted formats - Word Document, Excel & PDF" disabled="disabled">
                                                            <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default rm-img doc-preview-clear" (click)="removeUploadEvent('doc',$event)"  style="display:none;">
                                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                                        </button>
                                                                <!-- image-preview-input -->
                                                    <div class="btn btn-default btn-blue btn-file rm-img doc-preview-input">
                                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                        <span class="doc-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                        <input type="file" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" (change)="docChangeEvent($event)" name="input-file-preview"/> <!-- rename it -->
                                                    </div>
                                                </span>
                                                        </div>
                                                        <div class="input-group doc-preview">
                                                            <input type="text" class="form-control doc-preview-filename"placeholder="Accepted formats - Word Document, Excel & PDF" disabled="disabled">
                                                            <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default rm-img doc-preview-clear" (click)="removeUploadEvent('doc',$event)" style="display:none;">
                                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                                        </button>
                                                                <!-- image-preview-input -->
                                                    <div class="btn btn-default btn-blue btn-file rm-img doc-preview-input">
                                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                        <span class="doc-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                        <input type="file" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" (change)="docChangeEvent($event)" name="input-file-preview"/> <!-- rename it -->
                                                    </div>
                                                </span>
                                                        </div>
                                                        <div class="input-group doc-preview">
                                                            <input type="text" class="form-control doc-preview-filename"placeholder="Accepted formats - Word Document, Excel & PDF" disabled="disabled">
                                                            <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default rm-img doc-preview-clear" (click)="removeUploadEvent('doc',$event)"  style="display:none;">
                                                            <span class="glyphicon glyphicon-remove"></span> Clear
                                                        </button>
                                                                <!-- image-preview-input -->
                                                    <div class="btn btn-default btn-blue btn-file rm-img doc-preview-input">
                                                        <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                                        <span class="doc-preview-input-title" style="margin-left: 5px;">Browse</span>
                                                        <input type="file" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" (change)="docChangeEvent($event)" name="input-file-preview"/> <!-- rename it -->
                                                    </div>
                                                </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->