import { isPlatformBrowser, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APP_CONFIG, BASE_URL } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { AlertService } from 'projects/frontend/src/app/services/alert.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { LocalstorageService } from 'projects/frontend/src/app/services/localstorage.service';
import { appConfig } from '../../app.config';

declare var $: any;

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})


export class PricingComponent implements OnInit {

  public datapost: Datapost;
  jwtHelper = new JwtHelperService();
  users: any;
  token;
  decode;
  profileType;
  profileObjects;
  userID;
  profileUrl;
  ProfileSelected;
  unique_str;
  responsevalue;
  submitMessage;
  profilename;
  emailID;
  trackid: any;
  private isViewChecked = false;
  public utm_source: any;
  public utm_campaign: any;
  public profileExist = 0;
  public couponResponse = { status: '', message: '', coupon_id: '' };
  public showBasicPlan: boolean = true;
  isSubmitted = false;
  pricingForm: FormGroup;
  pricingFormUgrade:FormGroup;
  submitted: boolean = false;
  priclistinnerActive:string = 'gold';
  changeMentorAndStartupPlan:any = 0;
  membership_plan = 2;
  formSubmitted:boolean;




  create_profile_type = [
    { id: 1, name: 'Business', profileUrl: 'salesPlanSellerProfile' },
    { id: 7, name: 'Startup', profileUrl: 'salesPlanStartupProfile' },
    { id: 2, name: 'Investor', profileUrl: 'salesPlanInvestorProfile' },
    { id: 4, name: 'Mentor', profileUrl: 'salesPlanMentorProfile' },

  ];


  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private linkService: LinkService,
    private location: Location,
    private meta: Meta,
    private title: Title,
    private router: Router,
    private http: HttpClient,
    private cutomeStorage: LocalstorageService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private config: IAppConfig) {

    this.profilename = 'Business';
    linkService.removeCanonicalLink();
    linkService.addTag({ rel: 'canonical', href: 'https://www.businessex.com/pricing' });
    title.setTitle('BusinessEx Pricing Plan');
    meta.addTags([
      { name: 'Keywords', content: 'businessex, pricing, plan' },
      { name: 'Description', content: 'businessex Pricing Plan' }
    ]);

    /*meta.updateTag({ property: 'og:title', content:'BusinessEx Pricing Plan'});
    meta.updateTag({ property: 'og:url', content: BASE_URL + this.router.url });
    meta.updateTag({ property: 'og:description', content: 'businessex Pricing Plan' });
    meta.updateTag({ name: 'twitter:url', content: BASE_URL + this.router.url });
    meta.updateTag({ name: 'twitter:description', content: 'businessex Pricing Plan' });
    meta.updateTag({ property: 'twitter:title', content: 'BusinessEx Pricing Plan'});
  */


    this.datapost = new Datapost();

  }

  initPricingForm() {

    this.pricingForm = this.formBuilder.group({
      profile_type: [1, Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      membership_plan: [2, Validators.required],
      company: ['', Validators.required],
      mobile: ['', Validators.required],
      payment_mode: ['', Validators.required],
      coupon_code:[],

    })
  }

  pricingFormUpgrade() {

    this.pricingFormUgrade = this.formBuilder.group({
      profile_type: ['', Validators.required],
      membership_plan: ['2', Validators.required],
      payment_mode: ['', Validators.required],
      coupon_code:[],

    })
  }


  

  get pForm() { return this.pricingForm.controls; }
  get pFormUpgrade() { return this.pricingFormUgrade.controls; }

  ngOnInit(): void {

    this.initPricingForm();
    this.pricingFormUpgrade();
    this.checkLoginToken();

    this.route.data.subscribe((v) => {
      this.showBasicPlan = v.showBasicPlan

    });

    this.route.queryParamMap.subscribe(params => {

      this.priclistinnerActive = params.get('membership')!=null?params.get('membership'):'gold'
    });

    this.route.paramMap.subscribe((params: Params) => {
        this.trackid = params.get('trackid');
    });

    if (this.trackid === undefined || this.trackid === '') {
      this.trackid = localStorage.getItem('trackid');
    }

  }

  checkLoginToken(): void {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
      this.token = localStorage.getItem('currentUser');
      this.decode = this.jwtHelper.decodeToken(this.token);
      this.profileObjects = this.decode.userProfile;
      //console.log(this.profileObjects);
      this.userID = this.decode.sub;
      this.emailID = this.decode.email;
      this.profileExist = (this.profileObjects[0].profile_id !== 0) ? 1 : 2;;
    } else {
      this.profileObjects = [{
        'profile_id': 0,
        'profile_type': '',
        'profile_status': '',
        'profile_name': '',
        'profile_str': '',
        'payment_status': 0
      }];
      this.userID = '';
      this.profileExist = 2;

      if (isPlatformBrowser(this.platformId) && (this.trackid !== 'startup-offer')) {
        $('#login').modal({
          backdrop: 'static',
          keyboard: false
        });
      }
    }
  }

  onChagePlanActive(event,membership_plan) {
    this.membership_plan = membership_plan;
    $('.priclistinner').removeClass('active');
    $(event.target).parent().parent().parent().addClass('active');
  }



  mentorAndInvesot(profilrId){
    if(profilrId == 4 || profilrId==2){
        this.changeMentorAndStartupPlan = 1;
    }else if(profilrId==7){
        this.changeMentorAndStartupPlan = 2;
    }else{
       this.changeMentorAndStartupPlan = 0;
    }
    
    this.removeCoupon();
    const selectedProfile = this.profileObjects.filter(item => (item.profile_type == profilrId))[0];
    this.ProfileSelected = selectedProfile;

    if (selectedProfile !== undefined) {
      this.profilename = selectedProfile.profile_name;
      this.unique_str = selectedProfile.profile_str;
      this.profileType = selectedProfile.profile_type;
    }


  }

  onChangeObj($event) {
    if (this.profileObjects === undefined) {
      return;
    }
    $('input[name="membership_plan"]').attr('checked', false);
    this.removeCoupon();
    const selectedProfile = this.profileObjects.filter(item => (item.profile_type == $event))[0];
    this.ProfileSelected = selectedProfile;
    if (selectedProfile !== undefined) {
      this.profilename = selectedProfile.profile_name;
      this.unique_str = selectedProfile.profile_str;
      this.profileType = selectedProfile.profile_type;
    }
  }


  submitCoupon(coupon_code) {

    let membership_plan = this.membership_plan;//this.pricingForm.controls.membership_plan.value
    let profile_type = this.pricingForm.controls.profile_type.value
    //let coupon_code = this.pricingForm.controls.coupon_code.value

    if (coupon_code == '' || coupon_code ==null) {
      this.couponResponse.status = 'error';
      this.couponResponse.message = 'Please enter the coupon code';
      return;
    }

    $('.prombtn').prop('disabled', true);
    this.couponResponse.message = '';

   this.http.post(this.config.apiEndpoint + '/apply-coupon',
      { user_id: this.userID, coupon_code: coupon_code, membership: membership_plan, profile_type: profile_type })
      .subscribe(
        (response: any) => {
          this.couponResponse = response;
         $('.prombtn').prop('disabled', false);
        },
        error => {
          this.couponResponse = error.error;
          $('.prombtn').prop('disabled', false);
        });
    

  }


  submitCouponUpgrage(coupon_code) {

    let membership_plan = this.membership_plan;//this.pricingFormUgrade.controls.membership_plan.value
    let profile_type = this.pricingFormUgrade.controls.profile_type.value

    if (coupon_code == '' || coupon_code ==null) {
      this.couponResponse.status = 'error';
      this.couponResponse.message = 'Please enter the coupon code';
      return;
    }

    $('.prombtn').prop('disabled', true);
    this.couponResponse.message = '';

   this.http.post(this.config.apiEndpoint + '/apply-coupon',
      { user_id: this.userID, coupon_code: coupon_code, membership: membership_plan, profile_type: profile_type })
      .subscribe(
        (response: any) => {
          this.couponResponse = response;
         $('.prombtn').prop('disabled', false);
        },
        error => {
          this.couponResponse = error.error;
          $('.prombtn').prop('disabled', false);
        });
    

  }

  removeCoupon() {
    this.couponResponse.message = '';
    this.couponResponse.status = '';
    this.couponResponse.coupon_id = '';
  }

  priceFormSubmit() {

    this.submitted = true;

    if (this.pricingForm.invalid) {
      appConfig.scrollToError(true);
      return;
    }

    this.formSubmitted =  true;

    const selectedProfile = this.create_profile_type.filter(item => (item.id === this.pricingForm.controls.profile_type.value))[0];

    this.profileUrl = selectedProfile.profileUrl;

    this.datapost.user_id = this.userID;
    this.datapost.profile_type = (this.trackid === 'startup-offer') ? 7 : +this.pricingForm.controls.profile_type.value;
    this.datapost.name = this.pricingForm.controls.name.value;
    this.datapost.email = this.pricingForm.controls.email.value;
    this.datapost.mobile = this.pricingForm.controls.mobile.value;
    this.datapost.company_name = this.pricingForm.controls.company.value;
    this.datapost.trackid = this.trackid;
    this.datapost.membership_plan = this.membership_plan;//this.pricingForm.controls.membership_plan.value;
    this.datapost.utm_source = this.cutomeStorage.getItem('utm_source');
    this.datapost.utm_campaign = this.cutomeStorage.getItem('utm_campaign');


    this.http.post(this.config.apiEndpoint + '/' + this.profileUrl, this.datapost).subscribe(
      response => {
        this.responsevalue = response['userDetails'];
        this.unique_str = this.responsevalue.unique_str;
        this.callUpgradeAccount(this.responsevalue);
        this.formSubmitted =  false;
      },
      error => {
        this.submitMessage = error.error;
        this.alertService.error(error.error);
        appConfig.scrollToError(true);
        this.formSubmitted =  false;
      });

  }

  callUpgradeAccount(responsevalue) {

    responsevalue.payment_mode = this.pricingForm.controls.payment_mode.value;

    this.http.post(this.config.apiEndpoint + '/upgradenew/' + this.unique_str, {
      user_id: responsevalue.user_id,
      membership_plan: responsevalue.membership_plan,
      profile_type: responsevalue.profile_type,
      profile_id: responsevalue.profile_id,
      trackid: this.trackid,
      coupon_id: this.couponResponse.coupon_id,
      payment_mode: responsevalue.payment_mode,
    }).subscribe(
      response => {
        this.responsevalue = response;
        if (responsevalue.payment_mode == undefined || responsevalue.payment_mode == '') {
          appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
            key: this.responsevalue.merchant_key,
            hash: this.responsevalue.hash,
            txnid: this.responsevalue.txnId,
            surl: this.responsevalue.surl,
            furl: this.responsevalue.furl,
            curl: this.responsevalue.curl,
            amount: this.responsevalue.amount,
            firstname: this.responsevalue.firstname,
            email: this.responsevalue.email,
            phone: this.responsevalue.phone,
            productinfo: this.responsevalue.productinfo,
            city: this.responsevalue.city,
            country: this.responsevalue.country
          }, true);

        } else if (responsevalue.payment_mode == 'Paytm') {
          let paytmData = this.responsevalue.paytmList;
          appConfig.submitPayFORM('https://securegw.paytm.in/theia/processTransaction', 'POST', paytmData);

        } else {
          appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
            access_code: this.responsevalue.access_code,
            encRequest: this.responsevalue.encRequest,
          });
        }
      },
      error => {
        //console.log('registration failed');
        this.alertService.error('registration failed');
      });
  }

  saveUpgradeAccount() {
    this.submitted = true;

    if (this.pricingFormUgrade.invalid) {
      appConfig.scrollToError(true);
      return;
    }

    this.formSubmitted =  true;

    let data={
    'user_id':this.userID,
    'profile_type':this.pricingFormUgrade.controls.profile_type.value,
    'profile_id':this.ProfileSelected.profile_id,
    'coupon_id' :this.couponResponse.coupon_id,
    'payment_mode':this.pricingFormUgrade.controls.payment_mode.value,
    'membership_plan':this.membership_plan//this.pricingFormUgrade.controls.membership_plan.value
    }


    this.http.post(this.config.apiEndpoint + '/upgrade/' + this.unique_str, data).subscribe(
      response => {
        console.log('registration successfull');
        this.responsevalue = response;

        if (data.payment_mode == undefined || data.payment_mode == '') {
          appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
            key: this.responsevalue.merchant_key,
            hash: this.responsevalue.hash,
            txnid: this.responsevalue.txnId,
            surl: this.responsevalue.surl,
            furl: this.responsevalue.furl,
            curl: this.responsevalue.curl,
            amount: this.responsevalue.amount,
            firstname: this.responsevalue.firstname,
            email: this.responsevalue.email,
            phone: this.responsevalue.phone,
            productinfo: this.responsevalue.productinfo,
            city: this.responsevalue.city,
            country: this.responsevalue.country
          }, true);

        } else if (data.payment_mode == 'Paytm') {
          let paytmData = this.responsevalue.paytmList;
          appConfig.submitPayFORM('https://securegw.paytm.in/theia/processTransaction', 'POST', paytmData);

        } else {
          appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
            access_code: this.responsevalue.access_code,
            encRequest: this.responsevalue.encRequest,
          });
        }

        this.formSubmitted =  false;

      },
      error => {
        console.log('registration failed');
        this.formSubmitted =  false;
        //formSelector.find('button[type="submit"]').button('reset');
      });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      $('#login').modal('hide');
    }
  }



}


export class Datapost {
  user_id: any;
  profile_type: any;
  name: any;
  email: any;
  mobile: any;
  company_name: any;
  trackid: any;
  membership_plan: any;
  utm_source: any;
  utm_campaign: any;
}

export class DataUpgradepost {
  user_id: any;
  profile_type: any;
  trackid: any;
  membership_plan: any;
}

