
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE OTHER DETAILS
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                                        <li><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                                        <li><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                                        <li class="selected"><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                                        <li><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                                        <li><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="startupForm" #startupForm="ngForm"
                                              (ngSubmit)="startupMyAccountSubmit(startupForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">Other Details</div>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Select your
                                                        Company stage<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="company_stage"
                                                                    [ngModel]="startupOtherdata?.other.company_stage"
                                                                    data-error="Please select stage" required>
                                                                <option *ngFor="let cmpStage of companystage"
                                                                        [value]="cmpStage.id"
                                                                        [selected]="cmpStage.id == 'startupOtherdata?.other.company_stage'">
                                                                    {{cmpStage.name}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Describe
                                                        the customer problem your start-up will solve<span class="star">*</span>
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="customer_problem"
                                                                      [ngModel]="startupOtherdata?.other.customer_problem"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Enter Details"
                                                                      data-error="Please enter details"
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Describe
                                                        the product or service that you will sell<span
                                                                class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="product_service"
                                                                      [ngModel]="startupOtherdata?.other.product_service"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Enter Details"
                                                                      data-error="Please enter details"
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Describe
                                                        your target customer segment<span class="star">*</span>
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="customer_segment"
                                                                      [ngModel]="startupOtherdata?.other.customer_segment"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Enter Details"
                                                                      data-error="Please enter details"
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Describe
                                                        your target market<span class="star">*</span>
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="target_market"
                                                                      [ngModel]="startupOtherdata?.other.target_market"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Enter Target Market"
                                                                      data-error="Please enter details"
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Competitors<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="competitors"
                                                                   [ngModel]="startupOtherdata?.other.competitors"
                                                                   class="form-control" placeholder="Enter Competitors"
                                                                   data-error="Please enter competitors" required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Competitive
                                                        Advantage<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="competitive_advantage"
                                                                   [ngModel]="startupOtherdata?.other.competitive_advantage"
                                                                   class="form-control"
                                                                   placeholder="Enter Competitive Advantage"
                                                                   data-error="Please enter competitive advantage"
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Sales &
                                                        Marketing strategy<span class="star">*</span>
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="sales_marketing"
                                                                   [ngModel]="startupOtherdata?.other.sales_marketing"
                                                                   class="form-control"
                                                                   placeholder="Enter Sales & Marketing Strategy"
                                                                   data-error="Please enter sales and market strategy"
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Describe
                                                        about the Business / Company Summary<span class="star">*</span>
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="company_summary"
                                                                      [ngModel]="startupOtherdata?.other.company_summary"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Describe about the Business / Company Summary"
                                                                      data-error="Please enter details"
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
