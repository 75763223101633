import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {IAppConfig} from '../../../../config/app-config.interface';
import {APP_CONFIG, REPAYMENT_PERIOD, SUPPORT_FIELD} from '../../../../config/app-config.constants';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from "@angular/common";
import {ContactService} from "../../../../services/contact.service";
import {appConfig} from "../../../frontend/app.config";


declare var $: any;

@Component({
    selector: 'business-requirement',
    templateUrl: './business-requirement.component.html'
})
export class BusinessRequirementComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;

    profile_str;
    public BusinessAdvDetailsdata;
    public BusinessRequirementData;
    public businessProfileSuccessError;
    public businessProfileFailError;
    public userProfileType;
    private isViewChecked = false;

    public isLookingFor = false;
    repayment_period = REPAYMENT_PERIOD;
    support_period = SUPPORT_FIELD;

    public mentorCategoryData;

    constructor(meta: Meta, title: Title, private http: HttpClient,
                private myprofileService: MyProfileService, route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private contactService: ContactService) {
        title.setTitle('Business Requirement Details - BusinessEx');


        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    businessMyAccountSubmit(data) {
        if ($('form[name="businessForm"]').validator('validate').has('.has-error').length || this.isLookingFor) {
            return;
        }
        if ($('input[name="seeking_mentors"]').is(':checked') && $('#mentor_industry').val().length ===0) {
            $('#mentor_industry').parent().css('border','1px solid red');
            return;
        }
        $('#mentor_industry').parent().css('border','none');
        if ($('input[name="seeking_accelerators"]').is(':checked') && $('#accel_support_industry').val().length ===0) {
            $('#accel_support_industry').parent().css('border','1px solid red');
            return;
        }
        $('#accel_support_industry').parent().css('border','none');
        data.mentor_industry = appConfig.getFormattedValues('#mentor_industry', this.mentorCategoryData);
        data.accel_support_industry = appConfig.getFormattedValues('#accel_support_industry', this.mentorCategoryData);
        data.business_profile_str = this.profile_str;
        this.http.post(this.config.apiEndpoint + '/updatesellerrequirement/' + this.profile_str, data).subscribe(
            data => {
                //console.log('Business Requirement Details Updated Successfully');
                this.businessProfileSuccessError = 'Business Requirement Details Updated Successfully';
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
                window.scrollTo(0, 0);
            },
            error => {
                //console.log('Please Enter Correct Details');
                //console.log(error);
                this.businessProfileFailError = 'Please Enter Correct Details';
                window.scrollTo(0, 0);
            });
    }

    getbusinessRequirement(profile_str: any): void {
        this.myprofileService.getbusinessRequirement(this.profile_str)
            .then((result) => {
                this.BusinessRequirementData = result;
                if (isPlatformBrowser(this.platformId)) {
                    setTimeout(() => {
                        if (this.BusinessRequirementData.requirement.seeking_investors) {
                            $('.invester').slideToggle().find('input, textarea, select').attr('required', 'required');
                        }
                        if (this.BusinessRequirementData.requirement.seeking_loan) {
                            $('.loansseeking').slideToggle().find('input, textarea, select').attr('required', 'required');
                        }
                        if (this.BusinessRequirementData.requirement.seeking_accelerators) {
                            $('.incubatorsaccelerator').slideToggle().find('input, textarea, select').not('.multitag input').attr('required', 'required');
                        }
                        if (this.BusinessRequirementData.requirement.seeking_buyers) {
                            $('.businesssale').slideToggle().find('input, textarea, select').attr('required', 'required');
                        }
                        if (this.BusinessRequirementData.requirement.seeking_mentors) {
                            $('.mentorsearch').slideToggle().find('input, textarea, select').not('.multitag input').attr('required', 'required');
                        }
                        /*creating default tags*/
                        this.BusinessRequirementData.accel_support_industry.forEach(item => {
                            $('#accel_support_industry').tagit("createTag", item.name);
                        });
                        this.BusinessRequirementData.mentor_industry.forEach(item => {
                            $('#mentor_industry').tagit("createTag", item.name);
                        });
                    }, 1000)
                }
            });
    }

    getUserRegisterProfileType(): void {
        this.myprofileService.getUserRegisterProfileType(this.userID)
            .then((result) => {
                this.userProfileType = result;
            });
    }

    getsellerAdvDetails(profile_str: any): void {
        this.myprofileService.getsellerAdvDetails(this.profile_str)
            .then((result) => {
                this.BusinessAdvDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getMentorSector();
        this.getbusinessRequirement(this.profile_str);
        this.getsellerAdvDetails(this.profile_str);
        this.getUserRegisterProfileType();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="businessForm"]').validator('update');
        }
    }

    isChecked(inputName, toggleClass) {
        this.isLookingFor = ($('.other-detail input[type="checkbox"]:checked').length <= 0);
        if ($('input[name="' + inputName + '"]').is(':checked')) {
            $('.' + toggleClass).find('input, textarea, select').not('.multitag input').attr('required', 'required');
        } else {
            $('.' + toggleClass).find('input, textarea, select').removeAttr('required');
        }
        $('form[name="businessForm"]').validator('update');
    }

    getMentorSector(): void {
        this.contactService.getMentorSector()
            .then((result) => {
                this.mentorCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.mentorCategoryData);
                    setTimeout(function () {
                        $('#mentor_industry, #accel_support_industry').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }
}
