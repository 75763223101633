<div *ngIf="loading" class="loading">
    <div style="display:table-cell; vertical-align: middle;">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>
</div>


<div class="container bex-main">
    <div class="row">
        <div class="col-md-12">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item" *ngIf="catind.length === 1"><a [routerLink]="['/startupslisting']">Start-ups</a></li>
                <li class="breadcrumb-item" *ngIf="catind.length === 0 || catind.length > 1">Start-ups</li>
                <li class="breadcrumb-item active" *ngIf="subcatind.length === 1"><a [routerLink]="['/startupslisting/'+getIndustryName(catind[0])?.industrySlug+'-startups-in-India/'+catind[0]+'/s0/st0']">{{getIndustryName(catind[0])?.industry}}</a></li>
                <li class="breadcrumb-item active" *ngIf="catind.length === 1 && subcatind.length > 1">{{getIndustryName(catind[0])?.industry}}</li>
                <li class="breadcrumb-item active" *ngIf="subcatind.length === 1">{{getIndustryName(catind[0], subcatind[0])?.subindustry}}</li>

            </ol>
        </div>
        <!-- <div class="col-md-12 pt-b15">
            <div class="bex-search-section">
                <span class="mhides">
                    <i class="fa fa-bolt" aria-hidden="true"></i> Trending Searches:
                </span>
                <span>
                    <ul class="bex-trending-search-tab">
                        <li><a href="#">Hospitality</a></li>
                        <li><a href="#">Hotels</a></li>
                        <li><a href="#">Management</a></li>
                        <li><a href="#">Education</a></li>
                        <li><a href="#">Pre-School</a></li>
                        <li><a href="#">Restaurants</a></li>
                        <li><a href="#">Food Parlor</a></li>
                    </ul>
                </span>
            </div>
        </div> -->
    </div>


    <div class="row catfull">
        <div class="filter" id="showftr">Apply Filter</div>

        <div class="col-md-3 catsh">
            <div class="catleft">
                <div id="closeftr" class="closebtn"><i class="fa fa-times fa-2x" aria-hidden="true"></i></div>
                <div class="mainleftdiv">
                    <div class="subhead">Filters</div>

                    <!-- looking for-->
                    <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Startups Looking for</a> <span
                                class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                            <ul class="sub-menu">
                                <li *ngFor="let tag of filters.tags; let i=index;">
                                    <label>
                                        <input 
                                        class="sub-gen" 
                                        id="{{tag.name}}"
                                        type="radio"
                                        name="radio-tag" 
                                        (change)='parentChange(tag)' 
                                        value="{{tag.name}}" 
                                        [(ngModel)]="BusinessType" />

                                        {{tag.label}}

                                    </label>

                                </li>

                            </ul>
                        </div>
                    </div>

                    <!-- investment size-->
                    <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Investment Size</a> <span class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                           <div class="leftfrmblk">
                              <form>
                                 <div class="priblk">

                                    <div id="html5"></div>
          
                                    <!-- <nouislider #sliderPrice name="sliderPrice" [config]="someKeyboardConfig" [(ngModel)]="sliderRange" (end)="priceFilter($event)"></nouislider> -->
      
                                    <div class="fbl">
                                       <div class="prlic1" id="prlic1">
                                          <i class="fas fa-rupee-sign"></i> {{minInvestmentValue}} </div>
                                        <div class="prlic2" id="prlic2"><i class="fas fa-rupee-sign"></i>
                                          {{maxInvestmentValue}}
                                       </div>
                                    </div>
                                 </div>      
                              </form>
                           </div>
                        </div>
                     </div>

                    <!-- Location -->
                    <div class="accordion_container">

                        <div class="accordion_head"><span class="plusminus minus"></span> <a
                            [routerLink]="">Location</a>
                        </div>
                        <div class="accordion_body" style="display: block;">


                            <div class="accordianHead" (click)="appConfig.locationtypeaccordianCheck($event)"
                                *ngFor="let statelist of filterLocation12 | unique: 'ofc_state'; let i = index">

                                <div class="accordion_headmain">
                                    <span class="rightdown downval"></span>

                                    <input class="brand-filter" type="checkbox" attr.id="{{statelist.state}}"
                                        (click)="includeCity(statelist.ofc_state, statelist.state,'', statelist.cityId, statelist.ofc_state)"
                                        attr.class="state{{statelist.state}}"
                                        [(ngModel)]="filterLocationstate[statelist.ofc_state]">

                                    <a class="clicked" attr.id="state{{statelist.state}}"
                                        href="startupslisting/businesses-for-sale-in-{{statelist.stateSlug}}/c0/s0/{{statelist.state}}"
                                        (click)="$event.preventDefault()">
                                        {{statelist.ofc_state | limitTo: 20}}
                                    </a>

                                </div>

                                <div class="accordion_bodymain" style="display: none;">
                                    <div class="more">
                                        <ul class="sub-menu">
                                            <li
                                                *ngFor="let citylist of filterLocation12 | filterBy:['ofc_state']:statelist.ofc_state  | unique:'ofc_city'; let j=index">
                                                <input attr.id="{{statelist.state}}"
                                                    (click)="includeCity(statelist.ofc_state ,statelist.state, citylist.ofc_city, citylist.cityId, citylist.ofc_city)"
                                                    value="" type="checkbox" class="sub-gen"
                                                    attr.class="city{{statelist.state}}{{j}}"
                                                    [(ngModel)]="filterLocation[citylist.ofc_city]">
                                                <a class="clicked" attr.id="city{{statelist.state}}{{j}}"
                                                    href="startupslisting/businesses-for-sale-in-{{citylist.citySlug}}/c0/s0/{{citylist.state}}/{{citylist.cityId}}"
                                                    (click)="$event.preventDefault()"
                                                    >
                                                    {{citylist.ofc_city | limitTo: 20}}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <!-- <div class="morcity"><a href="#">25 More</a></div> -->

                        </div>
                    </div>

                    <!-- industries -->

                    <div class="accordion_container">
                        <div class="accordion_head">
                            <a [routerLink]="">Industries</a>
                            <span class="plusminus add"></span></div>

                        <div class="accordion_body" style="display: block;">
                            <div class="accordianHead" (click)="appConfig.locationtypeaccordianCheck($event)"
                                *ngFor="let catindustry of industrySeller | sortBy : 'industry' | unique:'industry'; let i = index">

                                <div class="accordion_headmain">
                                    <span class="rightdown downval"></span>

                                    <input class="brand-filter" attr.id="{{catindustry.industryid}}" value=""
                                        type="checkbox"
                                        (click)="includeindustry(catindustry.industryid, '', catindustry.industry,'','')"
                                        attr.class="class{{catindustry.industryid}}"
                                        [(ngModel)]="filterIndustrymain[catindustry.industryid]">

                                    <a class="clicked" attr.id="class{{catindustry.industryid}}"
                                        href="startupslisting/{{catindustry.industrySlug}}-businesses-for-sale/{{catindustry.industryid}}/s0/st0" 
                                        (click)="$event.preventDefault()">
                                        {{catindustry.industry | limitTo: 20}}
                                    </a>

                                </div>

                                <div class="accordion_bodymain" style="display: none;">
                                    <div class="more">
                                        <ul class="sub-menu">
                                            <li
                                                *ngFor="let subCategory of industrySeller | sortBy : 'subindustry' | filterBy:['industry' ]: catindustry.industry | unique:'subindustry'">


                                                <input class="sub-gen" attr.id="{{catindustry.industryid}}"
                                                    attr.class="class{{subCategory.subIndustryid}}" value=""
                                                    type="checkbox"
                                                    (click)="includeindustry(catindustry.industryid, subCategory.subIndustryid, subCategory.subindustry, catindustry.industry, subCategory.subIndustryid)"
                                                    [(ngModel)]="filterIndustry[subCategory.subIndustryid]">
                                                <a class="clicked" attr.id="class{{subCategory.subIndustryid}}"
                                                    href="startupslisting/{{subCategory.subIndustrySlug}}-businesses-for-sale/{{catindustry.industryid}}/{{subCategory.subIndustryid}}/st0"
                                                    (click)="$event.preventDefault()">
                                                    {{subCategory.subindustry | limitTo: 20}}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <!-- <div class="morcity"><a href="#">25 More</a></div> -->
                        </div>

                    </div>

                    
                    <!--- annual sales -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Annual Sales</a> <span class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                            <div class="frmright">

                                <select class="form-control">
                                    <option value="50 - 100 Crores">50 - 100 Crores</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

                    <!--- extablishement year -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Establishment year</a> <span
                                class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                            <div class="frmright">

                                <select class="form-control">
                                    <option value="2001 - 2010">2001 - 2010</option>
                                    <option value="2001 - 2010">2011 - 2020</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

                    <!--- Entity Types -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Entity Type</a> <span class="plusminus add"></span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                            More Filters
                        </div>
                    </div> -->

                    <!--- Employee count -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Employee Count</a> <span class="plusminus add"></span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                            More Filters
                        </div>
                    </div> -->

                    <!--- Business Type -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Business Type</a> <span class="plusminus add"></span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                            More Filters
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-9 col-md-9 mdy">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-9 setheading">
                    <h1 class="headblk">{{hititle}}</h1>
                </div>
                <div class="col-12 col-sm-6 col-md-3 float-right setmob">
                    <div class="form-group" *ngIf="slider && slider?.sort.length > 0">
                        <select class="selectpicker slt-box form-control" (ngModelChange)="onChangeObj($event)"
                            name="ProfileSelected" #ProfileSelected="ngModel" ngModel>
                            <option value="" disabled selected hidden>Sort By {{slider?.label}}</option>
                            <option [ngValue]="option?.value" *ngFor="let option of slider?.sort">
                                {{option?.label}}</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row">
                <!-- <div class="col-md-12">
                    <p [innerHTML]="subheadline"></p>
                </div> -->

                <div class="col-md-12 shrt-desc sec-hide" id="shrinkMe">
                    <p [innerHTML]="subheadline"></p>
                    <a id="more" onclick="shrink(this.id)" class="pos" [routerLink]="">...More</a>
                    <a onclick="shrink(this.id)" id="less" class="pos" [routerLink]="">...Less</a>
                </div>
                
            </div>

            <div class="row setvto">
                <ul class="listop startuplist">
                    <li class="sec-slide-effect" *ngFor="let business of Business; let last = last" [isLast]="last" (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">

                        <div class="ribbonblk">

                            <container-element [ngSwitch]="business.membership_plan">
                                <div class="ribbonblkinner" *ngSwitchCase="1">Premium</div>
                                <div class="ribbonblkinner" *ngSwitchCase="2">Gold</div>
                                <div class="ribbonblkinner" *ngSwitchCase="3">Platinum</div>
                                <!-- <div class="ribbonblkinner" *ngSwitchDefault></div> -->
                            </container-element>

                        </div>

                        <div class="fullban">
                            <a [routerLink]="['/startup/'+business.startupurl+'/'+business.profileStr]">
                                <span *ngIf="business.thumbimage == null">
                                    <img class="img-responsive" src="assets/images/category/small/{{business.catImage}}"
                                        alt="{{business.thumbimageName!=null?business.thumbimageName:business.title}}"
                                        title="{{business.title}}" />
                                </span>
                                <span *ngIf="business.thumbimage != null && business.thumbimage != ''">
                                    <img class="img-responsive" src="{{business.thumbimage}}"
                                        alt="{{business.thumbimageName!=null?business.thumbimageName:business.title}}"
                                        title="{{business.title}}" />
                                </span>
                            </a>
                        </div>

                        <div class="fullb cattxt">
                            <span>{{business.industry}} / {{business.subindustry}}</span>
                           {{business.title | limitTo: 50}}
                        </div>

                    <!--     <div class="fullb contxt">
                            {{business.description | limitTo: 35}}
                        </div>
 -->
                    <!--     <div class="sdd">
                            <div class="sddinner" title="{{business.mobVerifyStatus == 0?'Not Verified Mobile':'Verified Mobile'}}">
                                <img src="./assets/img/phone.svg"> <span>Phone</span></div>
                            <div class="sddinner">
                                <img src="./assets/img/email.svg"> <span>Email</span></div>
                        </div> -->
<!-- 
                        <div class="fullb citytxt">
                            {{business.city}}{{ (business.city !='') ?  (","):("")}} {{business.state}}
                        </div> -->
                        
                        <!-- <div class="tagv">
                            <div class="tagvinner">Hospitality</div>
                            <div class="tagvinner">Hotels</div>
                            <div class="tagvinner">Management</div>
                        </div> -->

                        <div class="backv">
                            <div class="inblk sebe">
                                {{business?.priceLabel}} <span><i class="fas fa-rupee-sign"></i>{{(business.askingPrice != "NA") ? business.askingPrice  : ("Undisclosed")  }}</span>
                            </div>

                            <div class="inblk">
                                Annual sale <span><i class="fas fa-rupee-sign"></i>{{appConfig.PriceSlideFormate(business.annualsale)}}</span>
                            </div>
                            
                            <div class="inblk">
                                Establishment year <span>{{business.estb_year}}</span>
                            </div>

                            <div class="inblk">
                                Employee count <span>
                                     {{business.emp_count}} </span>
                            </div>

                            <div class="inblk">
                                Entity type <span>{{business.entity_type}}</span>
                            </div>

                            <div class="inblk">
                                Business type <span>{{business.business_type}}</span>
                            </div>
                                <div class="inblk">

                                <i class="fa fa-map-marker" aria-hidden="true"></i> {{business.city}}, {{business.state}} 
                                <span> 
                                    <a [routerLink]="" class="bookmar" *ngIf="userID">
                                        <i *ngIf="bxBookmarkStatus === 0 " (click)="toggle(1)"
                                            class="fa fa-bookmark-o fa-6" aria-hidden="true"></i>
                                        <i *ngIf="bxBookmarkStatus === 1 " (click)="toggle(0)"
                                            class="fa fa-bookmark" aria-hidden="true"></i>
                                    </a>
                                </span>

                            </div>

                        </div>
                        
                        <div class="inbtn"><a [routerLink]="['/startup/'+business.startupurl+'/'+business.profileStr]" class="contact-btn">Contact Startup </a></div>
                    </li>
                </ul>
            </div>

            <div class="row pt30 pb-30">
                <div class="col-md-12">
                    <listing-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()"
                        [pagesToShow]="5" [page]="currentPage" [perPage]="itemsPerPage" [count]="totalItems">
                    </listing-pagination>
                </div>
            </div>
        </div>

    </div>


</div>
