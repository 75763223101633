
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                                
                     <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                     <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">   
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE OTHER INFORMATION                                          
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/brokerConfidential/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/brokerBusiness/{{profile_str}}">Business Details</a></li>
                                        <li><a routerLink="/dashboard/brokerLocation/{{profile_str}}">Location Info</a></li>
                                        <li class="selected"><a routerLink="/dashboard/brokerOther/{{profile_str}}">Other Info</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>         
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                    <form name="brokerForm" #brokerForm="ngForm" (ngSubmit)="brokerForm.form.valid && brokerMyAccountSubmit(brokerForm.value)" class="form-horizontal" role="form">
                                        <div class="sec-slide-effect">

                                            <div class="msg-pnl_sales" *ngIf="brokerProfileSuccessError" style="margin-top:10px; text-align:center;">{{brokerProfileSuccessError}}</div> 
                                            <div class="msg-pnl_sales" *ngIf="brokerProfileFailError" style="margin-top:10px; text-align:center;">{{brokerProfileFailError}}</div> 
                                            
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-12">
                                                    <div class="frm-sec-ttl">Other Information</div>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Professional Summary<span class="star">*</span><span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">                                          
                                                        <textarea  class="form-control" name="prof_summary"  [ngModel]="BrokerOtherdata?.uniqueid.prof_summary" rows="3" minlength="15" maxlength="150" placeholder="Enter Professional Summary" data-error="Please enter professional summary." required></textarea>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="form-group has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Year Of Experience<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <select class="form-control" name="prof_exp_year" [ngModel]="BrokerOtherdata?.uniqueid.prof_exp_year" data-error="Please select Year Of Experience." required>                                        
                                                            <option *ngFor="let empCount of professional_exp_year" [value]="empCount.id" [selected]="empCount.id == 'BrokerOtherdata.uniqueid.prof_exp_year'">{{empCount.id}}</option>
                                                        </select>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div> 
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>                                             

                                        </div>                                        
                                        <div class="sec-slide-effect txt-cen">                                  
                                            <div class="submitfrm">
                                                <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                                            </div>
                                        </div>
                                    </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>                                
            </div>
        </div>
    </div>                    
</div>
<!-- End Start Body Part -->