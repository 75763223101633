import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'investor-confidential',
    templateUrl: './investor-confidential.component.html'
})
export class InvestorConfidentialComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    profile_str;
    public investorConfidentialdata;
    public investorProfileSuccessError;
    public investorProfileFailError;
    public bxInvestorType;
    public investorAdvertisementdata;
    public inv_city;
    public inv_state;
    public inv_country;
    public inv_placeid;
    private isViewChecked = false;
    public inv_location: Object;

    getAddress(place: Object) {
        this.inv_location = place['formatted_address'];
        var location = place['geometry']['location'];
        this.inv_placeid = place['place_id'];
        this.inv_city = place['address_components'][0].long_name;
        this.inv_state = place['address_components'][2].short_name;
        this.inv_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Investor Confidential Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    investorMyAccountSubmit(data) {
        if ($('form[name="investorForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.inv_profile_str = this.profile_str;
        data.inv_placeid = this.inv_placeid;
        data.inv_city = this.inv_city;
        data.inv_state = this.inv_state;
        data.inv_country = this.inv_country;
        this.http.post(this.config.apiEndpoint + '/updateinvestorConfidential/' + this.profile_str, data).subscribe(
            data => {
                //console.log("Confidential Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.investorProfileSuccessError = "Confidential Details Updated Successfully";
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.investorProfileFailError = "Please Enter Correct Details";
            });
    }

    getinvestorConfidential(profile_str: any): void {
        this.myprofileService.getinvestorConfidential(this.profile_str)
            .then((result) => {
                this.investorConfidentialdata = result;
            });
    }

    getinvestorAdvDetails(profile_str: any): void {
        this.myprofileService.getinvestorAdvDetails(this.profile_str)
            .then((result) => {
                this.investorAdvertisementdata = result;
            });
    }

    getcheckBxInvestorType(userID: any, profile_str: any): void {
        this.myprofileService.getcheckBxInvestorType(this.userID, this.profile_str)
            .then((result) => {
                this.bxInvestorType = result;
            });
    }

    ngOnInit() {
        this.getinvestorConfidential(this.profile_str);
        this.getinvestorAdvDetails(this.profile_str);
        this.getcheckBxInvestorType(this.userID, this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="investorForm"]').validator();
        }
    }

}
