<div *ngIf="loading" class="loading">
  <div style="display: table-cell; vertical-align: middle">
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>
</div>

<!-- ======= Intro Section ======= -->
<section id="hero" class="section-t8 d-flex align-items-center">
  <div class="container" data-aos="zoom-out" data-aos-delay="100">
    <div class="row">
      <div
        class="bex-hero-section section-t4"
        [ngClass]="{
          'col-xl-8': !authService.isAuthenticated(),
          'col-xl-12': authService.isAuthenticated()
        }"
      >
        <h1>
          World’s
          <span class="bex-hero-section-hed">Fastest Growing</span> Intelligent
          Deal Platform
        </h1>
        <!-- <h2><strong>1500+</strong> Businesses, <strong>1400+</strong> Startups, <strong>1800+</strong>
               Investors, <strong>200+</strong> Mentors and <strong>50+</strong>
               Incubators are registered in our community so far!
            </h2> -->
        <h2><strong>LEADING BUSINESS EXCHANGE</strong></h2>
        <h3>Why Wait? Create Your Profile Now!</h3>
        <!-- <a href="#about" class="btn-get-started scrollto">Get Started</a>  -->
        <div class="row">
          <div
            class="col-xl-6 offset-xs-12 offset-md-12 offset-lg-12 offset-xl-3 align-self-center bex-form-control"
          >
            <form>
              <div class="input-group input-group-hero-main mb-3">
                <select
                  id="inputStates"
                  name="profiledata"
                  class="form-control"
                  #profiledata
                >
                  <option selected>Select a profile</option>
                  <option value="1">Business</option>
                  <option value="2">Investor</option>
                  <option value="4">Mentor</option>
                  <option value="7">Startup</option>
                </select>
                <div class="input-group-append">
                  <button
                    class="btn bex-btn-primary"
                    type="submit"
                    id="button-addon2"
                    (click)="profileSubmit(profiledata.value)"
                  >
                    CREATE PROFILE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-4" *ngIf="!authService.isAuthenticated()">
        <app-quick-registration></app-quick-registration>
      </div>
    </div>
  </div>
</section>
<!-- End Hero -->

<!-- Main Section Start -->
<main id="main">
  <!-- ======= Why Business-Ex Section ======= -->
  <section class="section-business-ex sectiont2 topsec nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">Why BusinessEx?</h2>
              <h5>BusinessEx- Exit, Exchange, Excel</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="setnew">
        <ul class="setul">
          <li>
            <div class="imgblknew">
              <img src="./assets/img/single-platformnew.svg" alt="" />
            </div>
            <h4>Single Platform For Entire Ecosystem</h4>
            <p class="shotxt">
              An online interactive platform connecting Businesses, Startups,
              Investos, Mentors, Lenders, Incubators and Brokers, across
              industries and geographies.
            </p>
          </li>

          <li>
            <div class="imgblknew">
              <img src="./assets/img/help-companies-scale-upnew.svg" alt="" />
            </div>
            <h4>Help Companies Scale Up</h4>
            <p class="shotxt">
              BusinessEx offers a platform for high-growth potential companies
              to promote their investment opportunities to investors or to gain
              expertise from renowned mentors, in a secure environment
            </p>
          </li>

          <li>
            <div class="imgblknew">
              <img src="./assets/img/a-connected-networknew.svg" alt="" />
            </div>
            <h4>A Connected Network</h4>
            <p class="shotxt">
              Provides an opportunity to connect to a broader network to share
              deals and grow your connections, while keeping your important
              details confidential.
            </p>
          </li>

          <li>
            <div class="imgblknew">
              <img
                src="./assets/img/fully-customizable-platformnew.svg"
                alt=""
              />
            </div>
            <h4>Put Your Mark On It</h4>
            <p class="shotxt">
              Our platform is fully customizable. You decide the information you
              want to share. Automatically receive recommendations based on your
              profile and preferences.
            </p>
          </li>

          <li>
            <div class="imgblknew">
              <img src="./assets/img/authentic-communitynew.svg" alt="" />
            </div>
            <h4>Authentic Community</h4>
            <p class="shotxt">
              Meet and interact with genuine and interested customers registered
              with BusinessEx, and deepen relationships
            </p>
          </li>

          <li>
            <div class="imgblknew">
              <img src="./assets/img/portfolio-managementnew.svg" alt="" />
            </div>
            <h4>Portfolio Management Made Easy</h4>
            <p class="shotxt">
              Keep track of all your conversations and proposals in one place.
              Track user preferences (location, industry, investment) and
              receive curated opportunities.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <!-- Featured Business For Sale Section -->
  <section class="section-business-ex sectiont2 sec1 nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">Business For Sale Opportunities</h2>

              <h5>
                BusinessEx offers {{ totalBussiness }} businesses in
                {{ totalBussinessIndustries }} industries as on
                {{ currentDate | date }}
              </h5>

              <a [routerLink]="['businesslisting']" class="bex-view-all-section"
                >View All</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="bex-featured-business-for-sale-carousel owl-carousel owl-arrow"
      >
        <div
          class="carousel-item-a"
          *ngFor="
            let sell of seller | slice : 0 : 12;
            let i = index;
            let last = last
          "
          [isLast]="last"
          (lastDone)="
            featuredLisitingBusiness('bex-featured-business-for-sale-carousel')
          "
        >
          <div class="business-ex-box">
            <div class="bex-bussiness-section card">
              <div class="bex-features-section-main">
                <div id="ribbon-container">
                  <container-element [ngSwitch]="sell.membership_plan">
                    <a [routerLink]="" *ngSwitchCase="1">Premium</a>
                    <a [routerLink]="" *ngSwitchCase="2">Gold</a>
                    <a [routerLink]="" *ngSwitchCase="3">Platinum</a>
                    <!-- <div class="ribbonblkinner" *ngSwitchDefault></div> -->
                  </container-element>
                </div>

                <div class="bex-selection justify-content-end">
                  <img
                    src="assets/img/share.svg"
                    alt="share-icon-main"
                    class="share-icon-main"
                    loading="lazy"
                  />
                  <div
                    class="share-social-content displaynone"
                    tabindex="0"
                  ></div>
                </div>

                <span *ngIf="sell.thumbimage == null">
                  <img
                    class="img-responsive"
                    src="assets/images/category/small/{{ sell.catImage }}"
                    loading="lazy"
                    alt="{{ sell.title }}"
                  />
                </span>

                <span *ngIf="sell.thumbimage != null && sell.thumbimage != ''">
                  <img
                    class="img-responsive"
                    src="{{ sell.thumbimage }}"
                    alt="{{ sell.title }}"
                    loading="lazy"
                  />
                </span>
              </div>

              <div class="bex-bussiness-section-info">
                <p class="homestxt">
                  {{ sell.industry }} / {{ sell.ParentIndustry }}
                </p>
                <h6 class="homeshead">
                  <a
                    [routerLink]="[
                      '/business/' + sell.sellerurl + '/' + sell.profileStr
                    ]"
                    >{{ sell.title | limitTo : 60 }}</a
                  >
                </h6>
              </div>

              <div class="homeinblk">
                {{ sell?.priceLabel }}

                <span *ngIf="sell.priceLabel != 'Seeking Mentor'">
                  <i
                    class="fa fa-inr"
                    aria-hidden="true"
                    *ngIf="sell.price != 'NA'"
                  ></i>
                  {{ sell.price != "NA" ? sell.price : "Undisclosed" }}
                </span>
              </div>

              <div class="bex-bussiness-section-info-main">
                <div
                  class="bex-main-info-card-timing"
                  title="{{
                    sell.mobVerifyStatus == 0
                      ? 'Not Verified Mobile'
                      : 'Verified Mobile'
                  }}"
                >
                  <span
                    class="bex-main-info-card-timing-img"
                    [ngClass]="{ active: sell.mobVerifyStatus == 1 }"
                    ><i class="fa fa-phone" aria-hidden="true"></i> Phone
                  </span>

                  <span class="bex-main-info-card-timing-img"
                    ><i class="fa fa-envelope" aria-hidden="true"></i>
                    Email
                  </span>

                  <span class="bex-main-info-card-timing-img">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    {{ sell.location | split : "," : 0 }}
                  </span>
                </div>
              </div>

              <div class="bex-primary-btn">
                <a
                  [routerLink]="[
                    '/business/' + sell.sellerurl + '/' + sell.profileStr
                  ]"
                  class="contact-btn-home"
                  >Contact Business</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Upcoming event start-->
  <section class="section-business-ex sectiont2 sec3 nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">Upcoming Events</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        id="bex-upcoming-past-events-carousel"
        class="owl-carousel owl-arrow"
      >
        <div class="carousel-item-a">
          <div class="business-ex-box">
            <div class="card bex-card-padding-tblr">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <a
                    href="https://www.franchiseindia.com/expo/mumbai"
                    target="_blank"
                  >
                    <img
                      src="https://franchiseindia.s3.ap-south-1.amazonaws.com/tbo/07311730010509.webp"
                      alt="FranchiseIndia_2024"
                  /></a>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="bex-main-info-card">
                    <p>
                      <a
                        href="https://www.franchiseindia.com/expo/mumbai"
                        target="_blank"
                        >Franchise India 2024 Mumbai</a
                      >
                    </p>
                  </div>
                  <div class="bex-main-info-card-timing">
                    <span class="bex-main-info-card-timing-img ng-tns-c42-0"
                      ><i
                        _ngcontent-serverapp-c42=""
                        aria-hidden="true"
                        class="fa fa-calendar ng-tns-c42-0"
                      ></i>
                      29-30 NOV 2024
                    </span>

                    <span class="bex-main-info-card-timing-img"
                      ><i class="fa fa-map-marker" aria-hidden="true"></i>JIO
                      WORLD CONVENTION CENTRE, MUMBAI</span
                    >
                  </div>
                  <div class="bex-main-info-summury-main">
                    <p class="bex-main-info-summury">
                      Join over 500 Franchisors and 50,000+ verified buyers on
                      the 29th and 30th Nov 2024 at JIO WORLD CONVENTION CENTRE,
                      MUMBAI. 2 day massive expo with unlimited franchise
                      opportunities from various sectors and industries.
                    </p>
                  </div>

                  <div class="bex-business-main-btn">
                    <a
                      href="https://www.franchiseindia.com/expo/mumbai"
                      target="_blank"
                      >REGISTER NOW</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item-a">
          <div class="business-ex-box">
            <div class="card bex-card-padding-tblr">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <a
                    href="https://www.entrepreneurindia.com/live"
                    target="_blank"
                    ><img
                      src="https://franchiseindia.s3.ap-south-1.amazonaws.com/tbo/07311989365846.webp"
                      alt="IReC 2024"
                  /></a>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="bex-main-info-card">
                    <p>
                      <a href="https://www.irec.asia/mumbai/" target="_blank"
                        >IReC 2024</a
                      >
                    </p>
                  </div>
                  <div class="bex-main-info-card-timing">
                    <span class="bex-main-info-card-timing-img ng-tns-c42-0"
                      ><i
                        _ngcontent-serverapp-c42=""
                        aria-hidden="true"
                        class="fa fa-calendar ng-tns-c42-0"
                      ></i>
                      29-30 NOV 2024
                    </span>

                    <span class="bex-main-info-card-timing-img"
                      ><i class="fa fa-map-marker" aria-hidden="true"></i> JIO
                      WORLD CONVENTION CENTRE, MUMBAI</span
                    >
                  </div>
                  <div class="bex-main-info-summury-main">
                    <p class="bex-main-info-summury">
                      India’s largest Retail & Licensing extravaganza. Get ready
                      for the ultimate industry convergence to unlock the Future
                      of Retail & Licensing
                    </p>
                  </div>

                  <div class="bex-business-main-btn">
                    <a href="https://www.irec.asia/mumbai" target="_blank"
                      >REGISTER NOW</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Upcoming evnt end  -->
  <!-- ======= Featured Investors Section ======= -->
  <section class="section-business-ex sectiont2 sec4 nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">Featured Investors</h2>
              <h5>
                BusinessEx offers {{ totalActiveInvestors }} investors with
                investment preferences in
                {{ totalInvestorIndustries }} industries as on
                {{ currentDate | date }}
              </h5>
              <a [routerLink]="['investorlisting']" class="bex-view-all-section"
                >View All</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bex-featured-investors-carousel owl-carousel owl-arrow">
        <div
          class="carousel-item-a"
          *ngFor="
            let investor of investor | slice : 0 : 15;
            let i = index;
            let last = last
          "
          [isLast]="last"
          (lastDone)="featuredLisiting('bex-featured-investors-carousel')"
        >
          <div class="business-ex-box">
            <div class="bex-bussiness-section card">
              <div class="bex-features-section-main">
                <div id="ribbon-container">
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="investor.membership_plan == 1"
                    >Premium</a
                  >
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="investor.membership_plan == 2"
                    >Gold</a
                  >
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="investor.membership_plan == 3"
                    >Platinum</a
                  >
                </div>

                <div class="bex-mentor-section-main">
                  <div class="row">
                    <div class="col-sm-8 col-md-8">
                      <div class="bex-mentor-section-info">
                        <h6>
                          {{
                            investor.membership_paid == 1
                              ? investor.investorName
                              : investor.investorName
                          }}
                        </h6>
                        <p>
                          {{ investor.designation }},{{ investor.companyName }}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-4">
                      <img
                        src="{{ investor.investorProfPic }}"
                        alt="{{ investor.investorName }}"
                        class="rounded-circle bex-mentor-img"
                        loading="lazy"
                      />
                    </div>
                    <div class="col-sm-12 col-md-12">
                      <div class="bex-bussiness-section-info-main">
                        <div class="bex-main-info-card-timing">
                          <span class="bex-main-info-card-timing-img"
                            ><i class="fa fa-phone" aria-hidden="true"></i>
                            Phone
                          </span>
                          <span class="bex-main-info-card-timing-img"
                            ><i class="fa fa-envelope" aria-hidden="true"></i>
                            Email
                          </span>
                          <span class="bex-main-info-card-timing-img"
                            ><i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ investor.investorCity | limitTo : 10 }}</span
                          >
                        </div>
                        <div class="bex-summary">Summary</div>
                        <div class="bex-summary-info">
                          {{ investor.investorSummary | limitTo : 65 }}
                        </div>
                        <!--    <p>
                                  
                                    <i class="fa fa-map-marker"
                                 aria-hidden="true"></i> <a href="#"
                                          *ngIf="investor.investorSate != '' && investor.investorSate != 'NULL'">{{investor.investorSate}}</a>,
                                       <a href="#">India</a>
                                    </p> -->
                        <!-- <ul class="bex-service-tags">
                                    <li>Hospitality</li>
                                    <li>Hospitality</li>
                                    <li>Hospitality</li>
                                </ul> -->
                      </div>

                      <div class="bex-primary-btn">
                        <a
                          [routerLink]="[
                            '/investor/' +
                              investor.investorurl +
                              '/' +
                              investor.investorStr
                          ]"
                          class="contact-btn"
                          >SEND PROPOSAL</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Featured Business For Sale Section -->
  <section class="section-business-ex sectiont2 sec3 nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">Top Franchise Opportunities</h2>

              <a
                href="https://www.franchiseindia.com/"
                class="bex-view-all-section"
                target="_blank"
                >View All</a
              >
            </div>
          </div>
        </div>
      </div>
      <div id="topfranchiseopportunities" class="owl-carousel owl-arrow">
     
        <div class="carousel-item-a">
          <div class="business-ex-box">
            <div
              class="modified-col col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <div class="overlay-card"></div>
              <div class="card card-m card-p-10">
                <div class="brand-image-section">
                  <div class="brand-main-section">
                    <a
                      href="https://www.franchiseindia.com/brands/kathi-junction-foods.6140"
                      target="_blank"
                    >
                      <img
                        src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/franchisor/kathi-junction-foods_1.jpg"
                        class=""
                        alt=""
                    /></a>
                  </div>
                </div>
                <div class="card-body-section">
                  <p>Quick Service Restaurants</p>
                  <h2>
                    <a
                      href="https://www.franchiseindia.com/brands/kathi-junction-foods.6140"
                      target="_blank"
                      >Kathi Junction</a
                    >
                  </h2>
                  <div class="d-flex">
                    <div class="card-info">Investment Range</div>
                    <div class="card-info-amt">₹5 Lakh - 10 Lakh</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Space Required</div>
                    <div class="card-info-amt">
                      100 - 1500 Sq.ft
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Location</div>
                    <div class="card-info-amt"></div>
                  </div>
                  <div class="plc">
                    Delhi, Haryana, Himachal Pradesh, + 16 More
                  </div>
                  <div class="link-section">
                    <a
                      href="https://www.franchiseindia.com/brands/kathi-junction-foods.6140"
                      target="_blank"
                      >Know More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item-a">
          <div class="business-ex-box">
            <div
              class="modified-col col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <div class="overlay-card"></div>
              <div class="card card-m card-p-10">
                <div class="brand-image-section">
                  <div class="brand-main-section">
                    <a
                      href="https://www.franchiseindia.com/brands/coolbean-studios.85029"
                      target="_blank"
                      ><img
                        src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/franchisor/coolbean-studios_1.jpg"
                        class=""
                        alt=""
                    /></a>
                  </div>
                </div>
                <div class="card-body-section">
                  <p>Education</p>
                  <h2>
                    <a
                      href="https://www.franchiseindia.com/brands/coolbean-studios.85029"
                      target="_blank"
                      >Coolbean Studios</a
                    >
                  </h2>
                  <div class="d-flex">
                    <div class="card-info">Investment Range</div>
                    <div class="card-info-amt">5 Lakh - 10 Lakh</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Space Required</div>
                    <div class="card-info-amt"> 100 - 1500 Sq.ft</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Location </div>
                    <div class="card-info-amt"></div>
                  </div>
                  <div class="plc">Haryana, Rajasthan, Chhattisgarh, + 16 More<br /><br /></div>
                  <div class="link-section">
                    <a
                      href="https://www.franchiseindia.com/brands/coolbean-studios.85029"
                      target="_blank"
                      >Know More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item-a">
          <div class="business-ex-box">
            <div
              class="modified-col col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <div class="overlay-card"></div>
              <div class="card card-m card-p-10">
                <div class="brand-image-section">
                  <div class="brand-main-section">
                    <a
                      href="https://www.franchiseindia.com/brands/dr-smith-orthopedic-mattress.93283"
                      target="_blank"
                      ><img
                        src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/franchisor/dr-smith-orthopedic-mattress_1.gif"
                        class="img-responsive"
                        alt=""
                    /></a>
                  </div>
                </div>
                <div class="card-body-section">
                  <p>Furniture</p>
                  <h2>
                    <a
                      href="https://www.franchiseindia.com/brands/dr-smith-orthopedic-mattress.93283"
                      target="_blank"
                      >Dr. Smith Orthopedic Mattress</a
                    >
                  </h2>
                  <div class="d-flex">
                    <div class="card-info">Investment Range</div>
                    <div class="card-info-amt">20 Lakh - 30 Lakh</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Space Required</div>
                    <div class="card-info-amt">1000 - 2000 Sq.ft</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Location</div>
                    <div class="card-info-amt"></div>
                  </div>
                  <div class="plc">
                    Delhi, Haryana, Himachal Pradesh, Jammu and Kashmir, Punjab, +11 More
                  </div>
                  <div class="link-section">
                    <a
                      href="https://www.franchiseindia.com/brands/dr-smith-orthopedic-mattress.93283"
                      target="_blank"
                      >Know More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item-a">
          <div class="business-ex-box">
            <div
              class="modified-col col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <div class="overlay-card"></div>
              <div class="card card-m card-p-10">
                <div class="brand-image-section">
                  <div class="brand-main-section">
                    <a
                      href="https://www.franchiseindia.com/brands/thirstea.100385"
                      target="_blank"
                      ><img
                        src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/franchisor/thirstea_1.png"
                        class=""
                        alt=""
                    /></a>
                  </div>
                </div>
                <div class="card-body-section">
                  <p>Tea And Coffee Chain </p>
                  <h2>
                    <a
                      href="https://www.franchiseindia.com/brands/thirstea.100385"
                      target="_blank"
                      >ThirsTea USA</a
                    >
                  </h2>
                  <div class="d-flex">
                    <div class="card-info">Investment Range</div>
                    <div class="card-info-amt">10 Lakh - 20 Lakh</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Space Required</div>
                    <div class="card-info-amt">600 - 1200 Sq.ft</div>
                  </div>
                  <div class="d-flex">
                    <div class="card-info">Location</div>
                    <div class="card-info-amt"></div>
                  </div>
                  <div class="plc">
                    Delhi, Haryana, Himachal Pradesh, Jammu & Kashmir +15 More
                  </div>
                  <div class="link-section">
                    <a
                      href="https://www.franchiseindia.com/brands/thirstea.100385"
                      target="_blank"
                      >Know More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End business-ex Section -->

  <!-- ======= FeaFeatured tured Start-Ups Section ======= -->
  <section class="section-business-ex sectiont2 sec1 nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">High Growth Potential Startups</h2>
              <h5>
                BusinessEx offers {{ totalAtiveStartup }} startups in
                {{ totalStartupIndustries }} industries as on
                {{ currentDate | date }}
              </h5>
              <a [routerLink]="['startupslisting']" class="bex-view-all-section"
                >View All</a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="bex-Featured-Start-Ups-carousel owl-carousel owl-arrow bex-card-margin"
      >
        <div
          class="carousel-item-a"
          *ngFor="
            let start of startup | slice : 0 : 15;
            let i = index;
            let last = last
          "
          [isLast]="last"
          (lastDone)="featuredLisiting('bex-Featured-Start-Ups-carousel')"
        >
          <div class="business-ex-box">
            <div class="bex-bussiness-section card">
              <div class="bex-features-section-main">
                <div id="ribbon-container">
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="start.membership_plan == 1"
                    >Premium</a
                  >
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="start.membership_plan == 2"
                    >Gold</a
                  >
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="start.membership_plan == 3"
                    >Platinum</a
                  >
                </div>

                <div class="bex-selection justify-content-end">
                  <img
                    src="./assets/img/Shortlist.svg"
                    alt=""
                    class="share-icon-main"
                    loading="lazy"
                  />
                  <img
                    src="./assets/img/share.svg"
                    alt=""
                    class="share-icon-main"
                    id="share-icon-main"
                    loading="lazy"
                  />
                  <div
                    id="share-social-content"
                    class="share-social-content displaynone"
                    tabindex="0"
                  ></div>
                </div>

                <!-- <img src="./assets/img/business-sale.jpg" alt="" /> -->

                <span *ngIf="start.thumbimage == null">
                  <img
                    class="img-responsive"
                    src="assets/images/category/small/{{ start.catImage }}"
                    alt="{{ start.title }}"
                    loading="lazy"
                  />
                </span>

                <span
                  *ngIf="start.thumbimage != null && start.thumbimage != ''"
                >
                  <img
                    class="img-responsive"
                    src="{{ start.thumbimage }}"
                    alt="{{ start.title }}"
                    loading="lazy"
                  />
                </span>
              </div>

              <div class="bex-bussiness-section-info">
                <p class="homestxt">
                  {{ start.industry }} / {{ start.ParentIndustry }}
                </p>
                <h6 class="homeshead">{{ start.title | limitTo : 60 }}</h6>
              </div>
              <div class="homeinblk">
                {{ start?.priceLabel }}
                <span><i class="fas fa-rupee-sign"></i>{{ start.price }}</span>
              </div>

              <div class="bex-bussiness-section-info-main">
                <div class="bex-main-info-card-timing">
                  <span class="bex-main-info-card-timing-img"
                    ><i class="fa fa-phone" aria-hidden="true"></i>
                    Phone
                  </span>
                  <span class="bex-main-info-card-timing-img"
                    ><i class="fa fa-envelope" aria-hidden="true"></i>
                    Email
                  </span>
                  <span class="bex-main-info-card-timing-img"
                    ><i class="fa fa-map-marker" aria-hidden="true"></i>
                    {{ start.location | split : "," : 0 }}</span
                  >
                </div>
                <!-- <p><a href="#">Gurgaon</a>, <a href="#">Haryana</a></p>
                        <ul class="bex-service-tags">
                            <li>Hospitality</li>
                            <li>Hospitality</li>
                            <li>Hospitality</li>
                        </ul> -->
              </div>
              <div class="bex-primary-btn">
                <a
                  [routerLink]="[
                    '/startup/' + start.startupurl + '/' + start.profileStr
                  ]"
                  class="contact-btn-home"
                  >Enquire Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ======= Featured Mentor  Section ======= -->
  <section class="section-business-ex sectiont2 sec4 nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">World Class Mentors</h2>
              <h5>
                BusinessEx offers {{ totalActiveMentor }} mentors as on
                {{ currentDate | date }}
              </h5>
              <a [routerLink]="['mentorlisting']" class="bex-view-all-section"
                >View All</a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="bex-Featured-mentors-carousel owl-carousel owl-arrow bex-card-margin"
      >
        <div
          class="carousel-item-a"
          *ngFor="
            let mentor of mentor | slice : 0 : 15;
            let i = index;
            let last = last
          "
          [isLast]="last"
          (lastDone)="featuredLisiting('bex-Featured-mentors-carousel')"
        >
          <div class="business-ex-box">
            <div class="bex-bussiness-section card">
              <div class="bex-features-section-main">
                <div id="ribbon-container">
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="mentor.membership_plan == 1"
                    >Premium</a
                  >
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="mentor.membership_plan == 2"
                    >Gold</a
                  >
                  <a
                    [routerLink]=""
                    id="ribbon"
                    *ngIf="mentor.membership_plan == 3"
                    >Platinum</a
                  >
                </div>
                <div class="bex-mentor-section-main">
                  <div class="row">
                    <div class="col-sm-8 col-md-8">
                      <div class="bex-mentor-section-info">
                        <h6 class="mhomeshead">
                          {{ mentor.mentor_name }}
                          <!-- {{mentor.mentorDesignation | limitTo : 10}} -->
                        </h6>
                        <p class="mhomestxt">
                          {{ mentor.mentorCompany | limitTo : 20 }}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-4">
                      <img
                        src="{{ mentor.profilePic }}"
                        alt="{{ mentor.mentor_name }}"
                        class="rounded-circle bex-mentor-img"
                        loading="lazy"
                      />
                    </div>
                    <div class="col-sm-12 col-md-12">
                      <div class="bex-bussiness-section-info-main">
                        <div class="bex-main-info-card-timing">
                          <span class="bex-main-info-card-timing-img"
                            ><i class="fa fa-phone" aria-hidden="true"></i>
                            Phone
                          </span>
                          <span class="bex-main-info-card-timing-img"
                            ><i class="fa fa-envelope" aria-hidden="true"></i>
                            Email
                          </span>
                          <span class="bex-main-info-card-timing-img"
                            ><i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ mentor.mentorCity | limitTo : 5 }}</span
                          >
                        </div>
                        <div class="bex-summary">Summary</div>
                        <div class="bex-summary-info">
                          {{ mentor.mentorSummary | limitTo : 55 }}
                        </div>
                        <p>
                          <!--     <a href="#"
                                          *ngIf="mentor.mentorCity != '' && mentor.mentorCity != 'NULL' && mentor.mentorProfileStr !='uDQPqy'">
                                          <i class="fa fa-map-marker" aria-hidden="true"></i> {{mentor.mentorCity}}
                                       </a>,
                                       <a href="#"> {{mentor.mentorCountry}}</a> -->
                        </p>
                        <!-- <ul class="bex-service-tags">
                                      <li>Hospitality</li>
                                      <li>Hospitality</li>
                                      <li>Hospitality</li>
                                   </ul> -->
                      </div>
                      <div class="bex-primary-btn">
                        <a
                          [routerLink]="[
                            '/mentor/' +
                              mentor.mentorurl +
                              '/' +
                              mentor.mentorProfileStr
                          ]"
                          >SEND PROPOSAL</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Featured Business For Sale Section --
       <section class="section-business-ex sectiont2 sec3 nav-arrow-a">
            <div class="container">
               <div class="row">
                  <div class="col-md-12">
                     <div class=" d-flex justify-content-between">
                        <div class="title-box">
                           <h2 class="bex-title-a">Top Dealership Opportunities</h2>
                          
                           <a href="https://dealer.franchiseindia.com/" class="bex-view-all-section" target="_blank">View All</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="bex-dealership-carousel" class="owl-carousel owl-arrow">
                  <div class="carousel-item-a">
                     <div class="business-ex-box">
                        <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/speed-catchers-india-pvt-ltd-5663" target="_blank"><img
                                          src="https://img.franchiseindia.com/brands/logo/337379985.jpg"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/herbal-ayurvedic-homeopathic-natural-care-products" target="_blank">PCD Herbal, Ayurvedic, Homeopathic </a>
                                    </p>
                                    <h2><a href="https://dealer.franchiseindia.com/manufacturer/speed-catchers-india-pvt-ltd-5663" target="_blank">PlayBunny</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          3 Lac - 4 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          50 - 100 Sq. ft
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                         
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Delhi, Jammu & Kashmir, Chandigarh +12 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/speed-catchers-india-pvt-ltd-5663" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>
                  <!--  --
                  <div class="carousel-item-a">
                     <div class="business-ex-box">
                        <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/sampar-paris-5882" target="_blank"><img
                                          src="https://img.franchiseindia.com/brands/logo/1222944798.jpg"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/builder-construction-hardware" target="_blank">Sampar Paris</a>
                                    </p>
                                    <h2><a href="https://dealer.franchiseindia.com/manufacturer/sampar-paris-5882" target="_blank">Sampar Paris</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          15 Lac - 20 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          Not Available
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                         
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Andhra Pradesh, Delhi, Haryana, Gujrat... +7 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/sampar-paris-5882" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>

                  <!--  --
                  <div class="carousel-item-a">
                     <div class="business-ex-box">
                       <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                        <a href="https://dealer.franchiseindia.com/manufacturer/durabuild-3299" target="_blank"><img
                                          src="https://img.franchiseindia.com/brands/logo/durabuild_1.png"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/builder-construction-hardware" target="_blank">Builder & Construction Hardware</a>
                                    </p>
                                    <h2> <a href="https://dealer.franchiseindia.com/manufacturer/durabuild-3299" target="_blank">Durabuild</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          5 Lac - 10 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          300 - 1000 Sq. ft
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                         
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Delhi, Haryana, Himachal Pradesh, Jammu & Kashmir +31 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/durabuild-3299" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>

                  <!--  --
                  <div class="carousel-item-a">
                     <div class="business-ex-box">
                       <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/food-solution-india-limited-5956" target="_blank"><img
                                          src="https://img.franchiseindia.com/brands/logo/885639014.jpg"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/ready-to-eat-instant-food-mixes" target="_blank">Ready to Eat & Instant Food Mixes</a>
                                    </p>
                                    <h2><a href="https://dealer.franchiseindia.com/manufacturer/food-solution-india-limited-5956" target="_blank">Food Solutions(India) Ltd.</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          2 Lac - 6 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          40 - 100 Sq. ft
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                         
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Delhi, Haryana, Himachal Pradesh, Jammu & Kashmir +31 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/food-solution-india-limited-5956" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>
                 <!--  --
                  <div class="carousel-item-a">
                     <div class="business-ex-box">
                      <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                        <a href="https://dealer.franchiseindia.com/manufacturer/confiance-beverages-pvt-ltd-5607" target="_blank"><img
                                          src="https://img.franchiseindia.com/brands/logo/51271965.jpg"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/beverages" target="_blank">Beverages</a>
                                    </p>
                                    <h2> <a href="https://dealer.franchiseindia.com/manufacturer/confiance-beverages-pvt-ltd-5607" target="_blank">Confiance beverages Pvt Ltd</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          1 Lac - 3 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          250 - 2000 Sq. ft
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                          
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Maharashtra, Delhi, Chandigarh, Karnataka, Tamil Nadu +6 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/confiance-beverages-pvt-ltd-5607" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>

                   <div class="carousel-item-a">
                     <div class="business-ex-box">
                      <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                        <a href="https://dealer.franchiseindia.com/manufacturer/drone-power-private-limited-5541" target="_blank"><img
                                          src="https://img.franchiseindia.com/brands/logo/drone-power-private-limited_1.jpg"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/batteries-chargers-storage-devices" target="_blank">Batteries, Chargers & Storage Devices</a>
                                    </p>
                                    <h2> <a href="https://dealer.franchiseindia.com/manufacturer/drone-power-private-limited-5541" target="_blank">Drone Power Private Limited</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          2 Lac - 5 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          300 - 500 Sq. ft
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                         
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Delhi, Gujarat, Haryana, Himachal Pradesh +26 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/drone-power-private-limited-5541" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>

                   <div class="carousel-item-a">
                     <div class="business-ex-box">
                      <div class="modified-col col-xs-12 col-sm-12
                              col-md-12
                              col-lg-12
                              col-xl-12">
                              <div class="overlay-card"></div>
                              <div class="card card-m card-p-10">
                                 <div class="brand-image-section">
                                    <div class="brand-main-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/czarlabs-pvt-lt-3359" target="_blank"> <img
                                          src="https://img.franchiseindia.com/brands/logo/1916360066.jpg"
                                          class="" alt=""></a>
                                    </div>
                                 </div>
                                 <div class="card-body-section">
                                    <p>
                                       <a href="https://dealer.franchiseindia.com/dir/chemical-reagents-catalysts" target="_blank">Chemical Reagents & Catalysts</a>
                                    </p>
                                    <h2> <a href="https://dealer.franchiseindia.com/manufacturer/czarlabs-pvt-lt-3359" target="_blank">Czarlabs pvt ltd</a></h2>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Investment Range  
                                       </div>
                                       <div class="card-info-amt">
                                          2 Lac - 6 Lac
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Space Required
                                       </div>
                                       <div class="card-info-amt">
                                          100 - 500 Sq. ft

                                       </div>
                                    </div>
                                    <div class="d-flex">
                                       <div class="card-info">
                                          Location
                                       </div>
                                       <div class="card-info-amt">
                                         
                                       </div>
                                    </div>
                                    <div class="plc">
                                       Delhi, Haryana, Himachal Pradesh, Jammu & Kashmir +31 More
                                    </div>
                                    <div class="link-section">
                                       <a href="https://dealer.franchiseindia.com/manufacturer/czarlabs-pvt-lt-3359" target="_blank">Know More</a>
                                    </div>
                                   
                                 </div>
                              </div>
                           </div>
                     </div>
                  </div>


               </div>
            </div>
        
         </section>
         <!-- End business-ex Section -->

  <!-- ======= All Popular Business Opportunities Section ======= -->
  <section class="abackbg">
    <div class="container">
      <div class="title-box">
        <h2 class="bex-title-a">All Popular Business Opportunities</h2>
      </div>
    </div>

    <div class="container">
      <div class="boxb">
        <h2 class="viewtst">View Opportunities By Industry</h2>

        <ul class="listlinks">
          <li
            *ngFor="
              let imagedata of imagePathName | shuffle | slice : 0 : 9;
              let i = index
            "
          >
            <a
              [routerLink]="[
                'businesslisting/' +
                  imagedata.title_slug +
                  '-businesses-for-sale/' +
                  imagedata.cat_id +
                  '/' +
                  imagedata.sub_cat_id +
                  '/st0'
              ]"
            >
              {{ imagedata.title }}
            </a>
          </li>

          <li></li>
        </ul>
      </div>

      <div class="boxb">
        <h2 class="viewtst">View Opportunities By Location</h2>

        <ul class="listlinks">
          <li>
            <a [routerLink]="['/businesslisting']"
              >All India <span>(1500+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-delhi/c0/s0/DL'
              ]"
              >Delhi <span>(150+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-andaman-and-nicobar-islands/c0/s0/AN'
              ]"
              >Andaman and Nicobar <span>(2+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-rajasthan/c0/s0/RJ'
              ]"
              >Rajasthan <span>(20+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-maharashtra/c0/s0/MH'
              ]"
              >Maharashtra <span>(200+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-kerala/c0/s0/KL'
              ]"
              >Kerala <span>(15+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-punjab/c0/s0/PB'
              ]"
              >Punjab <span>(30+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-jharkhand/c0/s0/JH'
              ]"
              >Jharkhand <span>(3+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-telangana/c0/s0/TS'
              ]"
              >Telangana <span>(90+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-uttar-pradesh/c0/s0/UP'
              ]"
              >Uttar Pradesh <span>(80+)</span></a
            >
          </li>
          <li>
            <a
              routerLink="['/businesslisting/businesses-for-sale-in-karnataka/c0/s0/KA']"
              >Karnataka <span>(190+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-haryana/c0/s0/HR'
              ]"
              >Haryana <span>(80+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-chandigarh/c0/s0/CH'
              ]"
              >Chandigarh <span>(10+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-west-bengal/c0/s0/WB'
              ]"
              >West Bengal <span>(20+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-andhra-pradesh/c0/s0/AP'
              ]"
              >Andhra Pradesh <span>(20+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-tamil-nadu/c0/s0/TN'
              ]"
              >Tamil Nadu <span>(100+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-goa/c0/s0/GA'
              ]"
              >Goa <span>(3+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-chhattisgarh/c0/s0/CG'
              ]"
              >Chhattisgarh <span>(3+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-daman-and-diu/c0/s0/DD'
              ]"
              >Daman and Diu <span>(2+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-jammu-and-kashmir/c0/s0/JK'
              ]"
              >Jammu and Kashmir <span>(3+)</span></a
            >
          </li>
          <li>
            <a
              routerLink="['/businesslisting/businesses-for-sale-in-gujarat/c0/s0/GJ']"
              >Gujarat <span>(40+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-bihar/c0/s0/BR'
              ]"
              >Bihar <span>(3+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-madhya-pradesh/c0/s0/MP'
              ]"
              >Madhya Pradesh <span>(10+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-orissa/c0/s0/OR'
              ]"
              >Odisha <span>(1+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-assam/c0/s0/AS'
              ]"
              >Assam <span>(4+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-uttarakhand/c0/s0/UK'
              ]"
              >Uttarakhand <span>(5+)</span></a
            >
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/businesses-for-sale-in-himachal-pradesh/c0/s0/HP'
              ]"
              >Himachal Pradesh <span>(2+)</span></a
            >
          </li>
        </ul>
      </div>

      <div class="boxb">
        <h2 class="viewtst">View Opportunities By Investment</h2>

        <ul class="listlinks">
          <li>
            <!-- <a [routerLink]="['/businesslisting/business-opportunities/business-under-50k']">
                     Under 50K
                  </a> -->

            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-50k-2lakh'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"></i>50K -
              <i class="fa fa-inr" aria-hidden="true"></i>2Lakh
            </a>
          </li>

          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-2lakh-5lakh'
              ]"
              ><i class="fa fa-inr" aria-hidden="true"></i>2Lakh -
              <i class="fa fa-inr" aria-hidden="true"></i>5Lakh
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-5lakh-10lakh'
              ]"
              ><i class="fa fa-inr" aria-hidden="true"></i>5Lakh -
              <i class="fa fa-inr" aria-hidden="true"></i>10Lakh
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-10lakh-20lakh'
              ]"
              ><i class="fa fa-inr" aria-hidden="true"></i>10Lakh -
              <i class="fa fa-inr" aria-hidden="true"></i>20Lakh
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-20lakh-30lakh'
              ]"
              ><i class="fa fa-inr" aria-hidden="true"></i>20Lakh -
              <i class="fa fa-inr" aria-hidden="true"></i>30Lakh
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-30lakh-50lakh'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"> </i>30Lakh -
              <i class="fa fa-inr" aria-hidden="true"></i>50Lakh
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-50lakh-1cr'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"></i>50Lakh -
              <i class="fa fa-inr" aria-hidden="true"></i>1Cr
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-1cr-2cr'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"></i>1Cr -
              <i class="fa fa-inr" aria-hidden="true"></i>2Cr
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-2cr-5cr'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"></i>2Cr -
              <i class="fa fa-inr" aria-hidden="true"></i>5Cr
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-5cr-10cr'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"></i>5Cr -
              <i class="fa fa-inr" aria-hidden="true"></i>10Cr
            </a>
          </li>
          <li>
            <a
              [routerLink]="[
                '/businesslisting/business-opportunities/business-under-10cr-20cr'
              ]"
            >
              <i class="fa fa-inr" aria-hidden="true"></i>10Cr -
              <i class="fa fa-inr" aria-hidden="true"></i>20Cr
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <!--  business-ex Inside-section Section -->
  <section id="bx-insight" class="bx-insight section-bg">
    <div class="container" data-aos="fade-up">
      <div class="row" data-aos="fade-up" data-aos-delay="100">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <div class="title-box">
              <h2 class="bex-title-a">Bx Insights</h2>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <!-- <li class="nav-item">
                     <a class="nav-link active" id="News-tab" data-toggle="tab" href="#News" role="tab"
                        aria-controls="News" aria-selected="true">News</a>
                  </li> -->
            <!-- <li class="nav-item">
                     <a class="nav-link" id="Articles-tab" data-toggle="tab" href="#Articles" role="tab"
                        aria-controls="Articles" aria-selected="false">Articles</a>
                  </li> -->
          </ul>

          <div class="tab-content" id="myTabContent">
            <!-- <div class="tab-pane fade show active" id="News" role="tabpanel" aria-labelledby="News-tab">

                     <div class="row">
                        <div class="col-md-12">
                           <a [routerLink]="['/news']" class="pull-right">View All</a>
                        </div>
                     </div>

                     <div class="row">

                        <div class="col-md-3" *ngFor="let newsdata of bxNewsHome | slice:0:4; let i=index">
                           <div class="card bex-card-padding-tblr">
                              <div class="card-box-b card-shadow news-box">
                                 <div class="img-box-b">
                                    <a [routerLink]="['news/'+newsdata.url+'/'+newsdata.news_id]">
                                       <img src="{{newsdata.image}}" alt="{{newsdata.title}}" loading="lazy" class="img-b img-fluid">
                                    </a>
                                 </div>

                              </div>
                              
                              <div class="bex-card-info-main">
                                 <p class="bex-author-timeline">{{newsdata.time}}</p>
                                 <p>
                                    <a [routerLink]="['news/'+newsdata.url+'/'+newsdata.news_id]">{{newsdata.title}}</a>
                                 </p>
                                 <p class="bex-author-timeline"> By {{newsdata.author}}</p>
                              </div>

                           </div>
                        </div>

                     </div>

                  </div> -->

            <div
              class="tab-pane fade show active"
              id="Articles"
              role="tabpanel"
              aria-labelledby="Articles-tab"
            >
              <div class="row">
                <div class="col-md-12">
                  <a [routerLink]="['/article']" class="pull-right">View All</a>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-md-3"
                  *ngFor="
                    let articledata of bxArticleHome | slice : 0 : 4;
                    let i = index
                  "
                >
                  <div class="card bex-card-padding-tblr">
                    <div class="card-box-b card-shadow news-box">
                      <div class="img-box-b">
                        <a
                          [routerLink]="[
                            'article/' +
                              articledata.url +
                              '/' +
                              articledata.article_id
                          ]"
                        >
                          <img
                            src="{{ articledata.image }}"
                            alt="{{ articledata.title }}"
                            class="img-b img-fluid"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="bex-card-info-main">
                      <p class="bex-author-timeline">{{ articledata.time }}</p>
                      <p>
                        <a
                          [routerLink]="[
                            'article/' +
                              articledata.url +
                              '/' +
                              articledata.article_id
                          ]"
                        >
                          {{ articledata.title }}
                        </a>
                      </p>
                      <p class="bex-author-timeline">
                        By {{ articledata.author }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ======= Bx Services Section ======= -->

  <section class="abackbgwhite">
    <div class="container">
      <div class="title-box">
        <h2 class="bex-title-a">Bx Services</h2>
      </div>
    </div>

    <div class="container">
      <ul class="serviceslist">
        <li>
          <div class="imgbl">
            <a [routerLink]="['service/business-valuation']"
              ><img src="assets/img/hvalue.jpg" loading="lazy" alt="hvalue"
            /></a>
          </div>
          <div class="headcl">
            <a [routerLink]="['service/business-valuation']"
              >Business Valuation</a
            >
          </div>
          <div class="vtxt">
            BusinessEx defines Business Valuation as a process of obtaining a
            fair economic value of a business. This will benefit in figuring out
            sale value, pitching to investors and developing business strategies
          </div>
          <a [routerLink]="['service/business-valuation']" class="brngre"
            >+ Explore More</a
          >
        </li>

        <li>
          <div class="imgbl">
            <a [routerLink]="['service/business-plan']"
              ><img src="assets/img/hplan.jpg" loading="lazy" alt="hplan"
            /></a>
          </div>
          <div class="headcl">
            <a [routerLink]="['service/business-plan']">Business Plan</a>
          </div>
          <div class="vtxt">
            Business Plan is a document setting out a business's future
            objectives and strategies for achieving them. It lays out a written
            roadmap for the firm from a marketing, financial and operational
            standpoint.
          </div>
          <a [routerLink]="['service/business-plan']" class="brngre"
            >+ Explore More</a
          >
        </li>

        <li>
          <div class="imgbl">
            <a [routerLink]="['service/due-diligence']"
              ><img
                src="assets/img/hdiligence.jpg"
                loading="lazy"
                alt="hdiligence"
            /></a>
          </div>
          <div class="headcl">
            <a [routerLink]="['service/due-diligence']">Due Diligence</a>
          </div>
          <div class="vtxt">
            Due diligence refers to an investigation of the business to confirm
            all facts, or an authentication of the information provided before
            signing a contract.
          </div>
          <a [routerLink]="['service/due-diligence']" class="brngre"
            >+ Explore More</a
          >
        </li>
      </ul>
    </div>
  </section>

  <!-- ======= Membership Plans Section ======= -->
  <section class="section-business-ex sectiont2 section-b2 prics nav-arrow-a">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="justify-content-between">
            <div class="title-box">
              <h1 class="bex-title-a">Membership Plans</h1>
              <h5>Choose The Right One For You</h5>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bex-card-margin justify-content-center bex-all-popular-bussiness-main"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="card text-center">
              <div class="bex-card-margin bex-plans-main setmarg">
                <h4>Premium</h4>
                <!--     <p>
                           <s> &#8377; 9,999</s>
                        </p>
                        <h1>
                           &#8377; 6,999
                        </h1> -->
                <p>3 months</p>
              </div>
              <div class="bex-plans-card-bottom">
                <p class="bex-most-popular-text">
                  <b>Reach out to 50 Businesses/Startups/Investors/Mentors</b
                  ><br />
                  <b
                    >Accept Unlimited Investment Proposals from registered
                    users</b
                  ><br />
                  Unlock 10 Proposals from website visitors
                </p>
              </div>
              <div class="bex-plans-btn">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-md"
                  (click)="pricingPage('premium')"
                >
                  <i class="fa fa-plus"></i>
                  EXPLORE MORE
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card text-center">
              <div class="bex-most-popular">
                <span>Most Popular</span>
              </div>
              <div
                class="bex-card-margin bex-plans-main bex-premium-price-main"
              >
                <h4>Gold</h4>
                <p>12 months</p>
              </div>
              <div class="bex-plans-card-bottom">
                <p class="bex-most-popular-text">
                  <b>Reach out to 100 Businesses/Startups/Investors/Mentors</b
                  ><br />
                  <b
                    >Accept Unlimited Investment Proposals from registered
                    users</b
                  ><br />
                  Unlock 20 Proposals from website visitors
                </p>
              </div>
              <div class="bex-plans-btn">
                <button
                  type="button"
                  class="btn btn-light btn-md"
                  (click)="pricingPage('gold')"
                >
                  <i class="fa fa-plus"></i> EXPLORE MORE
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card text-center">
              <div class="bex-card-margin bex-plans-main setmarg">
                <h4>Platinum</h4>
                <p>12 months</p>
              </div>
              <div class="bex-plans-card-bottom">
                <p class="bex-most-popular-text">
                  <b
                    >Reach-out to Unlimited
                    Businesses/Startups/Investors/Mentors</b
                  ><br />
                  <b
                    >Accept Unlimited Investment Proposals from registered
                    users</b
                  ><br />
                  Unlock Unlimited Proposals from website visitors
                </p>
              </div>
              <div class="bex-plans-btn">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-md"
                  (click)="pricingPage('platinum')"
                >
                  <i class="fa fa-plus"></i>
                  EXPLORE MORE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ======= Did You Find Anything Interesting Section ======= -->
  <section class="section-business-ex sectiont2 section-b2 nav-arrow-a b-white">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="justify-content-between">
            <div class="title-box">
              <h1 class="bex-title-a">Did You Find Anything Interesting ?</h1>
              <h5>Create Your Profile Now!</h5>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bex-card-margin justify-content-center bex-all-popular-bussiness-main"
      >
        <div class="row">
          <div
            class="col-xl-6 offset-xs-12 offset-md-12 offset-lg-12 offset-xl-3 align-self-center"
          >
            <form>
              <div class="input-group">
                <select
                  class="custom-select"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  #profiledata
                >
                  <option selected>Select a profile</option>
                  <option value="1">Business</option>
                  <option value="2">Investor</option>
                  <option value="4">Mentor</option>
                  <option value="7">Startup</option>
                </select>
                <div class="input-group-append">
                  <button
                    class="btn btn-success-main"
                    type="button"
                    (click)="profileSubmit(profiledata.value)"
                  >
                    CREATE PROFILE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="feebackbg">
    <div class="container">
      <div class="title-box">
        <h2 class="bex-title-a">What Our Clients say</h2>
      </div>
    </div>

    <div class="container">
      <div class="owl-carousel owl-theme" id="clientssay">
        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "All the transactions went on smoothly from your end. You and your team were excellent in follow ups and you created a very fair valuation report for my business and I am satisfied with the price of deal and as promised you found me a buyer within 4-6 months. Best Wishes!"
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Rahul Malik</strong>
                  <span class="dane"
                    ><strong>Mumbai</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>
        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Buying a subway from BusinessEx is a very good decision that we made, I am happy with this venture."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Dhanjeet Manchanda</strong>
                  <span class="dane"
                    ><strong>Delhi</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>
        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "I really appreciate the hassle-free deal that I made with BusinessEx in buying a running franchise. It was indeed expert's job on every step. Till end I got a feeling that I am dealing with experts."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Abhinav Lamba</strong>
                  <span class="dane"
                    ><strong>Delhi</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>
        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Fair Pricing, clean documentation and utmost secrecy sums up all for BusinessEx dealings. Thank You!"
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Aman</strong>
                  <span class="dane"
                    ><strong>Delhi, NCR</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>
        <!-- //Pankaj -->
        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "I have been associated with Business Ex and it has been a great
                time. Their response to customer queries for selling business is
                very prompt. I would like to thank them for their continued
                support and recommend their services to other industry."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Anjula Seth</strong>
                  <span class="dane"
                    ><strong>Vice President, Multinational Training Company</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Thanks for your service and support that you offered us in
                buying a successfully running Pre-school. This marks a key role
                of BusinessEx in offering excellent support to their customers.
                Hope BusinessEx will continue well with their immediate
                services.
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>
                  Neelima Kapoor</strong> <span class="dane"><strong>Director, Kidzee</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Doing great job, keep going! All the best! Special thanks to
                BusinessEx team for connecting me with the COO of IAN."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Amit Kumar Giri</strong><span class="dane"><strong>CEO, LujoBox</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "I was not sure if the platform would work for me but I decided
                to give it a try anyway. I didn’t keep my hopes too high but
                started sending out proposals to a few relevant investors. To my
                surprise, they began to accept my proposals and I started
                getting interests from website visitors as well. I had thorough
                discussions with a few selected ones and was able to finally
                close a deal in a little more than two months."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                 <strong>Yash Shah</strong><span class="dane"><strong>Businessman, Surat</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Great platform for networking and forming connections. Got
                acquainted to many high profile investors through this
                platform."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Naresh Kumar</strong>
                  <span class="dane"><strong>Founder & CEO, BrokersADDA</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "The BusinessEx team is very co-operative. They have personally
                helped me in reaching out to investors for my Edtech startup."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Manish Negi</strong> <span class="dane"><strong>CEO, EasyStudy</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "I was recommended this business by one of my friend. I am
                trying to look for some investors for my online clothing
                business. I am impressed that company charged me nominal fee and
                activated my profile within 2 days. Even, they respond to my
                queries instantly. That’s appreciable."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Ghanshyam Mundra</strong><span class="dane"><strong>Works at BHEL</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "One of the leader company to associate with."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Benimadhav Mohta</strong><span class="dane"
                    ><strong>Worked at Shiv-Vani Oil & Gas Exploration Services
                    Ltd</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "This is market friendly and business-centric concept. Keep
                growing!"
              </div>
              <div class="clkblkimg">
                <div class="cname">
                  <strong>Santosh Singh Dagur</strong><span class="dane"
                    ><strong>Digital Marketing Expert</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "I had tried a few platforms for business valuation and
                brokerage but was not able to get any positive results from any.
                My colleague told me about BusinessEx and I was initially
                resistant as I did not think it would work, owing to my past
                experience. Believe me when I say the quality of investors
                listed on this website is great- most of them were very active,
                responsive and resourceful. Got to crack a deal in a short time-
                highly recommend from my personal experience."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Digu Patel</strong><span class="dane"><strong>Kiran Gems Private Limited</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Great Company and Team is very supportive. “Nice, detailed
                information presented."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Ravi Mathur</strong><span class="dane"><strong>CTO, Insurance Samadhan</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Highly credible platform for meeting serious business owners."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Poorvashree Chandak</strong><span class="dane"
                    ><strong>Individual Investor in Nagpur</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "Good quality of startups listed on platform- connected with
                many."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>Vikram</strong><span class="dane"><strong>Alpsho Ventures</strong></span>
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>

        <div class="item">
          <!--  Start here -->
          <div class="clinefee">
            <div class="overlry"></div>
            <div class="pad10">
              <div class="ctxt">
                "The company has come up with new concept and has really helped
                to sell my business. It took 1 to 2 months to find the right
                buyer and even negotiation went smooth. Will recommend this to
                everyone."
              </div>
              <div class="clkblkimg">
                <div class="cname">
                <strong>ER Kisna Thakur</strong><span class="dane"
                    ><strong>Software Engineer, GNIIT</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--  End here -->
        </div>
      </div>
    </div>
  </section>
</main>

