
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE HEADQUARTERS / LOCATION INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                                        <li><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                                        <li class="selected"><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                                        <li><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                                        <li><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                                        <li><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                                        <li><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="startupForm" #startupForm="ngForm"
                                              (ngSubmit)="startupForm.form.valid && startupMyAccountSubmit(startupForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileFailError}}
                                            </div>


                                            <div class="sec-slide-effect" style="position: relative; z-index: 2;">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">Headquarters / Location</div>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Address<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea class="form-control" name="ofc_address"
                                                                      [ngModel]="startupLocationDetailsdata?.ofc_address"
                                                                      rows="3" minlength="15" maxlength="150"
                                                                      placeholder="Enter Office Address" required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">City<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="ofc_location"
                                                                   [ngModel]="startupLocationDetailsdata?.ofc_location"
                                                                   (setAddress)="getAddress($event)"
                                                                   class="form-control"
                                                                   placeholder="Enter Your Location"
                                                                   data-error="Please select location" googleplace
                                                                   required class="form-control">
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">PIN
                                                        Code<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="ofc_pincode"
                                                                   [ngModel]="startupLocationDetailsdata?.ofc_pincode"
                                                                   class="form-control" placeholder="Enter Pin Code"
                                                                   data-error="Please enter pincode" required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
