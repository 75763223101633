import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import {APP_CONFIG} from '../config/app-config.constants';
import {IAppConfig} from '../config/app-config.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ContactService {

    constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppConfig) {
    }

    getIndustrySector(): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/industryCategory')
            .toPromise()
            .catch(this.handleError);
    }

    getMentorSector(): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/mentorCategory')
            .toPromise()
            .catch(this.handleError);
    }

    getsellerContactDetails(profile_str: any, userId = ''): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerprofile/' + profile_str + '?userId=' + userId, {
            responseType: 'text'
        })
            .toPromise()
            .catch(this.handleError);
    }

    getStartupContactDetails(profile_str: any, userId = ''): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getStartupData' + '/' + profile_str + '?userId=' + userId)
            .toPromise()
            .catch(this.handleError);
    }

    getInvestorContactDetails(profile_str: any, userId = ''): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/investorprofilenew' + '/' + profile_str + '?userId=' + userId, {
            responseType: 'text'
        })
            .toPromise()
            .catch(this.handleError);
    }

    getMentorContactDetails(profile_str: any, userId = ''): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getMentorData' + '/' + profile_str+ '?userId=' + userId)
            .toPromise()
            .catch(this.handleError);
    }

    getLenderContactDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getLenderPageData' + '/' + profile_str)
            .toPromise()
            .catch(this.handleError);
    }

    getIncubatorContactDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getIncubatorData' + '/' + profile_str)
            .toPromise()
            .catch(this.handleError);
    }

    getFeatureSellers(): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/featureSellers')
            .toPromise()
            .catch(this.handleError);
    }

    getSimiliarSellers(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/similiarSellers' + '/' + profile_str)
            .toPromise()
            .catch(this.handleError);
    }

    getStartupSimilar(startup_id: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getStartupSimilar', {startup_id: startup_id})
            .toPromise()
            .catch(this.handleError);
    }

    getInvestorSimiliar(): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/featuredInvestors')
            .toPromise()
            .catch(this.handleError);
    }

    getMentorSimiliar(userId): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getMentorSimilarData/' + userId)
            .toPromise()
            .catch(this.handleError);
    }

    getLenderSimiliar(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getLenderSimilarData' + '/' + profile_str)
            .toPromise()
            .catch(this.handleError);
    }

    getIncubatorSimiliar(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getIncubatorSimilarData' + '/' + profile_str)
            .toPromise()
            .catch(this.handleError);
    }

    saveSearchHistory(user_id: any, profile_type: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/searchHistory', {
            user_id: user_id,
            profile_type: profile_type,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    saveSavedSearches(user_id: any, profile_type: any, profile_str: any, profile_chk: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/savedSearches', {
            user_id: user_id,
            profile_type: profile_type,
            profile_str: profile_str,
            profile_chk: profile_chk
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxContactBusiness(user_id: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxContactBusiness', {
            user_id: user_id,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxContactStartup(user_id: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxContactStartup', {
            user_id: user_id,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxContactInvestor(user_id: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxContactInvestor', {
            user_id: user_id,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxContactMentor(user_id: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxContactMentor', {
            user_id: user_id,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxContactLender(user_id: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxContactLender', {
            user_id: user_id,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxContactIncubator(user_id: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxContactIncubator', {
            user_id: user_id,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxProposal(sender: any, sender_profile_type: any, profile_str: any, profile_user_id: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxProposal', {
            sender: sender,
            sender_profile_type: sender_profile_type,
            profile_str: profile_str,
            profile_user_id: profile_user_id
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxProposalCount(user_id: any, receiver_profile_type: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxProposalCount', {
            user_id: user_id,
            receiver_profile_type: receiver_profile_type
        })
            .toPromise()
            .catch(this.handleError);
    }

    checkBxBookmark(user_id: any, profile_type: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxBookmark', {
            user_id: user_id,
            profile_type: profile_type,
            profile_str: profile_str
        })
            .toPromise()
            .catch(this.handleError);
    }

    getBanners(bannerpage: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getbanners' + '/' + bannerpage)
            .toPromise()
            .catch(this.handleError);
    }

    saveVerifyUser(token_id: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/verifyUser', {token_id: token_id})
            .toPromise()
            .catch(this.handleError);
    }

    updateProfileViewCount(profileString: any, profileType: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/updateProfileViewCount', {
            profileString: profileString,
            profileType: profileType
        })
            .toPromise()
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }


    businessProfileCreate(data): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/sellernew',data)
    }

    starrtupProfileCreate(data): Observable<any> {
        return this.http.post(this.config.apiEndpoint + '/startupnew',data)
    }
    

}

