import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {ContactService} from '../../../../services/contact.service';
import {APP_CONFIG, BUSINESS_TYPE, EMPLOYEE_COUNT, ENTITY_TYPE} from "../../../../config/app-config.constants";
import {IAppConfig} from "../../../../config/app-config.interface";
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'startup-company-details',
    templateUrl: './startup-company-details.component.html'
})
export class StartupCompanyDetailsComponent implements OnInit, AfterViewChecked {

    profile_str;
    public StartupCompanyDetailsdata;
    public industryCategoryData;
    public mentorCategoryData;
    public startupProfileSuccessError;
    public startupProfileFailError;
    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private contactService: ContactService,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Startup Company Information - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    empployee_count = EMPLOYEE_COUNT;
    entitytype = ENTITY_TYPE;
    businesstype = BUSINESS_TYPE;

    estblish_year = [];
    i;

    getEstYear(): void {
        for (this.i = 2017; this.i > 1899; this.i--) {
            this.estblish_year.push(this.i.toString());
        }
    }

    getIndustrySector(): void {
        this.contactService.getIndustrySector()
            .then((result) => {
                this.industryCategoryData = result;
            });
    }

    getMentorSector(): void {
        this.contactService.getMentorSector()
            .then((result) => {
                this.mentorCategoryData = result;
            });
    }

    startupMyAccountSubmit(data) {
        data.startup_profile_str = this.profile_str;
        this.http.post(this.config.apiEndpoint + '/updatestartupdetails/' + this.profile_str, data).subscribe(
            data => {
                console.log("Company Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.startupProfileSuccessError = "Company Details Updated Successfully";
                $("html, body").animate({scrollTop: 0}, "slow");
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                console.log("Please Enter Correct Details");
                console.log(error);
                this.startupProfileFailError = "Please Enter Correct Details";
            });
    }

    getstartupCompanyDetails(profile_str: any): void {
        this.myprofileService.getstartupCompanyDetails(this.profile_str)
            .then((result) => {
                this.StartupCompanyDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getstartupCompanyDetails(this.profile_str);
        this.getEstYear();
        this.getIndustrySector();
        this.getMentorSector();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="startupForm"]').validator();
        }
    }

}
