
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">

                                <div class="cat-sec-ttl">
                                    MANAGE ADVERTISEMENT INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/investorConfidentials/{{profile_str}}">Confidential Informaion</a></li>
                                        <li class="selected"><a routerLink="/dashboard/investorAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a *ngIf="bxInvestorType?.type==1" routerLink="/dashboard/investorFirmType/{{profile_str}}">Business Information</a></li>
                                        <li><a *ngIf="bxInvestorType?.type==2" routerLink="/dashboard/investorIndividualType/{{profile_str}}">Profile Information</a></li>
                                        <li><a routerLink="/dashboard/investorMultiPref/{{profile_str}}">Preferences</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="investorForm" #investorForm="ngForm"
                                              (ngSubmit)="investorForm.form.valid && investorMyAccountSubmit(investorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="investorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{investorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="investorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{investorProfileFailError}}
                                            </div>


                                            <div class="sec-slide-effect">
                                                <!-- <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Advertisement Details</div></div>
                                                </div>  -->
                                                <div class="row form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Advertisement
                                                        Headline<span class="star">*</span> 
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group tl-tip">
                                                            <div class="input-group-addon grey-tooltip"
                                                                 data-toggle="tooltip" data-placement="top"
                                                                 title="Give a tagline to advertise your profile to your customers (5-8 words)">
                                                             <!--    <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                            <input type="text" name="inv_headline"
                                                                   [ngModel]="investorAdvertisementdata?.inv_headline"
                                                                   class="form-control"
                                                                   placeholder="Enter Advertisement Headline"
                                                                   data-error="Please fill out this field." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Introduction
                                                        <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group tl-tip">
                                                            <div class="input-group-addon grey-tooltip"
                                                                 data-toggle="tooltip" data-placement="top"
                                                                 title="Give a short description for yourself (10-12 words)">
                                                               <!--  <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                            <textarea name="inv_intro"
                                                                      [ngModel]="investorAdvertisementdata?.inv_intro"
                                                                      class="form-control" rows="3"
                                                                      placeholder=""></textarea>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
