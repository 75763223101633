import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'payment-cancelled',
    templateUrl: './payment-cancelled.component.html'
})
export class PaymentCancelledComponent implements OnInit {


    constructor(public router: Router,
                private route: ActivatedRoute) {
    }

    title = 'Membership Payment Declined';
    message = 'Cancelled !!! Your payment has been declined due to some reasons !!!';

    ngOnInit() {
        this.route.paramMap.subscribe((params: Params) => {
            // direct page access not allowed
            if (document.referrer === '') {
               this.router.navigate(['/']);
            }
        });
    }
}
