import {AfterViewChecked, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {MyProfileService} from '../../../../services/my-profile.service';
import {APP_CONFIG, PROFILE_ARRAY} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {appConfig} from '../../../frontend/app.config';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;
declare var jQuery: any;

@Component({
    selector: 'my-account',
    templateUrl: './my-account.component.html'
})
export class MyAccountComponent implements OnInit, AfterViewChecked, OnDestroy {
    jwtHelper = new JwtHelperService();
    users: any;
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    public ProfileSelected;
    public recommendInvestors;
    public instaResponser;
    public requestlist;
    public totalrequest;
    public sellerSavedSearch = [];
    public sellerSearchHistory = [];
    public sellerSearchHistoryleng;
    public sellerNewListing = [];
    public investorRecommend;
    public sellerRecommend;
    public startupRecommend;
    public sellerSavedSearchleng;

    public mobVerifiedCount;
    public mobVerifiedStatus;
    public mobileError;
    public mobileCodeError;

    public userProfileType;
    public userProfile;
    public profileStatus;
    public isViewChecked = false;
    searchlimit = 5;
    requestlimit = 5;
    savedlimit = 5;
    appConfig = appConfig;
    public userRegisterProfileType = '';

    constructor(private myprofileService: MyProfileService, private http: HttpClient,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
    }

    getUserProfile() {
        const token = localStorage.getItem('currentUser');
        if (token && token.split('.').length === 3) {
            this.users = this.jwtHelper.decodeToken(token);
            return this.users;
        }
    }

    setActive = function (menuItem: any) {
        localStorage.setItem('activeMenu', menuItem);
    };

    getLatestListing(): void {
        this.myprofileService.getLatestListing()
            .then((result) => {
                this.sellerNewListing = result.latestdata;
            });
    }

    getrecommendSellers(): void {
        this.myprofileService.getrecommendSellers(this.userID, this.ProfileSelected.profile_type)
            .then((result) => {
                this.sellerRecommend = result;
                if (result.startup !== '') {
                    this.startupRecommend = result.startup;
                }
            });
    }

    getrecommendInvestors(): void {
        this.myprofileService.getrecommendInvestors(this.userID, this.ProfileSelected.profile_type)
            .then((result) => {
                this.investorRecommend = result;
            });
    }

    getProfilepercentage(): void {
        this.myprofileService.getProfilepercentage(this.ProfileSelected.profile_type, this.userID)
            .then((result) => {
                this.recommendInvestors = result;
            });
    }

    getSearchHistory(): void {
        this.myprofileService.getSearchHistory(this.userID)
            .then((result) => {
                this.sellerSearchHistory = result;
                this.sellerSearchHistoryleng = this.sellerSearchHistory.length;
            });
    }

    getUserRegisterProfileType(): void {
        this.myprofileService.getUserRegisterProfileType(this.userID)
            .then((result) => {
                this.userProfileType = result;
            });
    }

    getSavedSearches(): void {
        this.myprofileService.getSavedSearches(this.userID)
            .then((result) => {
                this.sellerSavedSearch = result;
                this.sellerSavedSearchleng = this.sellerSavedSearch.length;
            });
    }

    getcheckBxMobVerify(): void {
        this.myprofileService.getcheckBxMobVerify(this.userID)
            .then((result) => {
                this.mobVerifiedCount = result.count;
                this.mobVerifiedStatus = result.verified;
                if (this.mobVerifiedCount === 0 && this.mobVerifiedStatus === 0) {
                    $('#verified-ph').modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                }
            });
    }

    saveMobileVerifyDetail(data) {
        data.user_id = this.userID;
        // Post values here
        this.http.post(this.config.apiEndpoint + '/smsotp', data).subscribe(
            dataResponse => {
                //console.log('mobile registration successfull');
                $('#mobilenoverify').css('display', 'none');
                $('#mobilecodeverify').css('display', 'block');
            },
            error => {
                //console.log('registration failed');
                //console.log(error);
                this.mobileError = error.error;
            });
    }

    saveMobileOTPVerifyDetail(data) {
        data.user_id = this.userID;
        // Post values here
        this.http.post(this.config.apiEndpoint + '/verifysms', data).subscribe(
            dataResponse => {
                //console.log('mobile otp verified successfull');
                $('#mobilenoverify').css('display', 'none');
                $('#mobilecodeverify').css('display', 'none');
                $('#thanksverification').css('display', 'block');
            },
            error => {
                //console.log('registration failed');
                //console.log(error);
                // this.mobileError = '';
                this.mobileCodeError = error.error;
            });
    }

    getViewListingNotification(): void {
        this.myprofileService.getViewListingNotification(this.userID, this.notifycreatedate)
            .then((result) => {
                this.instaResponser = result;
            });
    }

    getRequestlist(): void {
        this.myprofileService.getRequestlist(this.userID)
            .then((result) => {
                this.requestlist = result;
                if (result.massge === 'null') {
                    this.totalrequest = 0;
                } else {
                    this.requestlist = result;
                    this.totalrequest = this.requestlist.length;
                }
            });
    }

    getBxinboxNotification(): void {
        this.myprofileService.getBxInboxNotification(this.userID)
            .then((result) => {
                this.instaResponser = result;
            });
    }

    getBxinboxunReadNotification(): void {
        this.myprofileService.getBxInboxUnReadNotification(this.userID)
            .then((result) => {
                this.instaResponser = result;
            });
    }

    sellerNewListingLoadMore(sellerNewListing) {
        if (this.requestlimit < sellerNewListing.length) {
            this.requestlimit = this.requestlimit + 5;
        }
    }

    sellerSavedSearchLoadMore(sellerSavedSearch) {
        if (this.savedlimit < sellerSavedSearch.length) {
            this.savedlimit = this.savedlimit + 3;
        }
    }

    sellerSearchHistoryLoadMore(sellerSearchHistory) {
        if (this.searchlimit < sellerSearchHistory.length) {
            this.searchlimit = this.searchlimit + 3;
        }
    }

    onChangeObj(profiletype) {
        localStorage.setItem('ProfileType', profiletype.profile_name);
        this.ProfileSelected = profiletype;
    }

    ngOnInit() {
        this.typecheck();
        this.getUserProfile();
        this.getLatestListing();
        this.getProfilepercentage();
        this.getSearchHistory();
        this.getUserRegisterProfileType();
        this.getcheckBxMobVerify();
        this.getViewListingNotification();
        this.getRequestlist();
        this.getBxinboxNotification();
        this.getBxinboxunReadNotification();
        this.getSavedSearches();
        this.getUserProfiles();

        function toggleIcon(e) {
            $(e.target)
                .prev('.new-dash .panel-heading')
                .find('.fa')
                .toggleClass('fa-plus-square-o fa-minus-square-o');
        }

        $('.new-dash .panel-group').on('hidden.bs.collapse', toggleIcon).on('shown.bs.collapse', toggleIcon);

        $('.panel-title').on('click', function (e) {
            $('.profile-ttl-name').html($('.profile-info .name').text());
        });

    }

    getUserProfiles(): void {
        this.myprofileService.getUserProfiles(this.userID)
            .then((result) => {
                this.userProfile = result.profiles;
                this.profileStatus = result.profileStatus;
            });
    }

    lastProfile = function (): void {
        $('.profile-comp').percentcircle({
            animate: true,
            diameter: 100,
            guage: 2,
            coverBg: '#fff',
            bgColor: '#efefef',
            fillColor: '#5c93c8',
            percentSize: '15px',
            percentWeight: 'normal'
        });
    };

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false) {
            this.isViewChecked = true;
            $('form[name="mobileVerifyForm"]').validator();
            $('form[name="mobileCodeVerifyForm"]').validator();
        }
    }

    typecheck() {
        if (localStorage.ProfileType !== undefined) {
            this.userRegisterProfileType = localStorage.getItem('userRegisterProfileType');
            const _profile = PROFILE_ARRAY.filter(item => (item.name === localStorage.ProfileType))[0];
            this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type === _profile.id))[0];
        } else {
            this.ProfileSelected = this.profileObjects[0];
        }
    }

    /*Receiving profile change and sending to the right section using input decorator */
    profileChangeHandler(data) {
        this.ProfileSelected = data;
    }

    ngOnDestroy(): void {
        // TODO hiding mobile verfication modal on destroy but black screen is coming
        if (isPlatformBrowser(this.platformId)) {
            $('#verified-ph').modal('hide');
        }
    }
}
