
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE INCUBATOR / ACCELERATOR INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/incubatorConfidentials/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/incubatorAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li class="selected"><a routerLink="/dashboard/incubatorDetails/{{profile_str}}">Incubator Details</a></li>
                                        <li><a routerLink="/dashboard/incubatorAttachment/{{profile_str}}">Attachments</a></li>
                                        <li><a routerLink="/dashboard/incubatorPreference/{{profile_str}}">Preferences </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="incubatorForm" #incubatorForm="ngForm"
                                              (ngSubmit)="incubatorForm.form.valid && incubatorMyAccountSubmit(incubatorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="incubatorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{incubatorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="incubatorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{incubatorProfileFailError}}
                                            </div>


                                            <div class="sec-slide-effect">
                                                <!-- <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">For Incubator / Accelerator</div>
                                                    </div>
                                                </div> -->

                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="incubator_company"
                                                                   [ngModel]="incubatorDetailsdata?.incubator_company"
                                                                   class="form-control"
                                                                   placeholder="Enter your Company Name"
                                                                   data-error="Please fill out this field." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Designation<span
                                                            class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="incubator_designation"
                                                                   [ngModel]="incubatorDetailsdata?.incubator_designation"
                                                                   class="form-control"
                                                                   placeholder="Enter your Designation"
                                                                   data-error="Please fill out this field."
                                                                   data-error="Please fill out this field." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Professional
                                                        Summary<span class="star">*</span><span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <textarea name="incubator_profile_summary"
                                                                      [ngModel]="incubatorDetailsdata?.incubator_profile_summary"
                                                                      class="form-control" rows="3" minlength="15"
                                                                      maxlength="150" placeholder="Enter Your Name"
                                                                      data-error="Please fill out this field."
                                                                      required></textarea>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Establishment
                                                        Year<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="estb_year"
                                                                    [ngModel]="incubatorDetailsdata?.estb_year"
                                                                    data-error="Please select establish year." required>
                                                                <option *ngFor="let empYear of estblish_year"
                                                                        [value]="empYear"
                                                                        [selected]="empYear == 'incubatorDetailsdata.estb_year'">
                                                                    {{empYear}}
                                                                </option>
                                                            </select>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company
                                                        Location<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="company_location"
                                                                   [ngModel]="incubatorDetailsdata?.company_location"
                                                                   class="form-control" placeholder="Enter Location"
                                                                   (setAddress)="getAddress($event)"
                                                                   data-error="Please select location" googleplace
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">PIN
                                                        Code<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="company_pincode"
                                                                   [ngModel]="incubatorDetailsdata?.company_pincode"
                                                                   class="form-control"
                                                                   placeholder="Enter Your PIN code"
                                                                   data-error="Please fill out this field." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company
                                                        Website<span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="company_website"
                                                                   [ngModel]="incubatorDetailsdata?.company_website"
                                                                   class="form-control" placeholder="Enter URL">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->



