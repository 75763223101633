
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                                
                     <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    
                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-7 col-md-6 mdy-width-49">
                        <loader [loading]="messageLoading"></loader>
                    <div class="bdr">
                    <div class="cat-list ">
                    <div class="cat-sec-ttl">
                    Proposal Sent for {{ProfileSelected?.profile_name}} Profile
                    </div>
                    
                    <!-- Investor Listing for Proposal Sent start here -->
                    <div *ngIf="contactHistoryLength">        
                    <ul *ngIf="ProfileSelected?.profile_type == '1' || ProfileSelected?.profile_type == '6' || ProfileSelected?.profile_type == '7'">
                        <li *ngFor="let invHistory of contactHistory">
                        <div class="row investorlist">
                            <div class="col-xs-12 col-sm-7 col-md-7 borlef"> 
                                <div class="profile-pic">
                                    <a routerLink="{{invHistory.profileurl}}"><img src="{{invHistory.profilePic}}" class="img-responsive"/></a>
                                </div>
                                <div class="profile-content">
                                    <div class="iname">{{invHistory.title}}</div>
                                    <div class="desi">
                                        {{ invHistory.designation }},
                                        {{ invHistory.company }},
                                        {{ invHistory.country }}
                                    </div>
                                    <div class="summary"><span>Summary:</span> 
                                        {{ (invHistory.summary !=='') ? invHistory.summary : ("N/A") }}                                               
                                    </div>
                                    <strong>Proposal Status:</strong> {{(invHistory.viewedStatus == 1) ? 'Viewed' : 'Not Viewed'}}
                                </div> 
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4 marlef">    
                                <div class="locate"><i class="fa fa-map-marker" aria-hidden="true"></i> 
                                    {{ (invHistory.city !=='') ? invHistory.city : ("N/A") }}
                                </div>
                                <div class="othera" *ngIf="invHistory.profileType ==2">
                                    <span>Inv. Pref.:</span>
                                    {{ (invHistory.investmentPref !=='') ? invHistory.investmentPref : ("N/A") }}
                                </div>
                                <div class="othera" *ngIf="invHistory.profileType ==4">
                                    <span>Sec Pref.:</span>
                                    {{ (invHistory.sectorPreference !=='') ? invHistory.sectorPreference : ("N/A") }}
                                </div>
                                <div class="othera" *ngIf="invHistory.profileType ==4">
                                    <span>Sub Expertise.:</span>
                                    {{ (invHistory.subjectExpertise !=='') ? (invHistory.subjectExpertise | limitTo: 50) : "N/A" }}
                                </div>
                                <div class="othera" *ngIf="invHistory.profileType ==2"><span>Investment Size:</span>
                                    <span>
                                        <i class="fa fa-inr" aria-hidden="true"></i>
                                        {{ (invHistory.minInvestment !=='') ? invHistory.minInvestment : ("N/A") }}
                                        - 
                                        {{ (invHistory.maxInvestment !=='') ? invHistory.maxInvestment : ("N/A") }}
                                    </span>
                                </div>
                                <div class="contact-btn">
                                    <a routerLink="{{invHistory.profileurl}}" >Send Proposal</a>
                                </div>   
                            </div>
                        </div>
                        </li>    
                    </ul>
                    </div> 
                    <!-- Investor Listing for Proposal Sent end here -->
                   
                    <!-- Business Listing for Proposal Sent start here -->
                    <div *ngIf="contactHistoryLength">   
                    <ul *ngIf="ProfileSelected?.profile_type == '2' || ProfileSelected?.profile_type == '3'  || ProfileSelected?.profile_type == '4'  || ProfileSelected?.profile_type == '5'">
                        <li *ngFor="let sellerdata of contactHistory">
                        <div class="row">                
                            <div class="col-xs-12 col-sm-5 col-md-5">
                                <a routerLink="{{sellerdata.profileurl}}">
                                    <span *ngIf="sellerdata.thumbimage == null"><img class="img-responsive" src="assets/images/category/small/{{sellerdata.catImage}}" alt="{{sellerdata.title}}" title="{{sellerdata.title}}" /></span>
                                    <span *ngIf="sellerdata.thumbimage != null && sellerdata.thumbimage != ''"><img class="img-responsive" src="{{sellerdata.thumbimage}}" alt="{{sellerdata.title}}" title="{{sellerdata.title}}" /></span>
                                </a>
                                <br>
                                <strong>Proposal Status:</strong> {{(sellerdata.viewedStatus == 1) ? 'Viewed' : 'Not Viewed'}}
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-7">                                                        
                                <div class="cat">
                                    {{sellerdata.industry}}
                                </div>
                                <div class="cat-ttl"><a routerLink="{{sellerdata.profileurl}}">{{sellerdata.title}}</a></div>
                                <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{sellerdata.location}}</div>
                                <div class="investment-price">
                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                    {{ (sellerdata.price !== '') ? sellerdata.price : ("N/A") }} 
                                    <div>{{sellerdata?.priceLabel}}</div>
                                </div>

                                <div class="contact-btn">
                                    <a routerLink="{{sellerdata.profileurl}}">Contact business</a>
                                </div>
                            </div>
                        </div>
                        </li>                
                    </ul>
                    </div> 

                    <div class="nodata" *ngIf="contactHistoryLength==0">
                        No contact history found !
                    </div>
                    <!-- Business Listing for Proposal Sent end here -->
                    <!-- <a href="#" class="load-more">LOAD MORE</a> -->

                    </div>
                    </div>
                    </div>
                    <!-- Investor profile pic -->

                    <!-- My Profile Right Section Start Here -->
                        <right-section></right-section>
                    <!-- My Profile Right Section End Here -->
                    
                    

                </div>                                
            </div>
        </div>
    </div>                    
</div>
<!-- End Start Body Part -->

