import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import {APP_CONFIG} from '../config/app-config.constants';
import {IAppConfig} from '../config/app-config.interface';

@Injectable({
    providedIn: 'root'
})

export class MyProfileService {

    sellerNewListing = {};

    constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppConfig) {
    }

    getViewConfirmInstaUpdate(contact_id: any, profileTypeId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/viewInstaStatusUpdate', {
            contact_id: contact_id,
            profile_type_id: profileTypeId
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getUserDetails(user_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getUserDetails' + '/' + user_id)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getPlanDetails(user_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getUserProfilePlans' + '/' + user_id)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getAddOnConfig(): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getAddOnConfig')
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getNewToken(user_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getNewToken' + '/' + user_id)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getInstaRevealCount(user_id: any, profileTypeId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkInstaRevealCount', {
            user_id: user_id,
            profile_type_id: profileTypeId
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getContactHistory(user_id: any, profileTypeId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getContactHistory', {
            user_id: user_id,
            profile_type_id: profileTypeId
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getLatestListing(): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getLatestListing')
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getSavedSearches(user_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getSavedSearches' + '/' + user_id)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);

    }

    getSearchHistory(user_id: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/getSearchHistory' + '/' + user_id)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getrecommendInvestors(userId, profileType): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/recommendInvestors?userId=' + userId + '&profileType=' + profileType)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getrecommendSellers(userId, profileType): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/recommendSellers?userId=' + userId + '&profileType=' + profileType)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getInstaResponse(userId: any, profileTypeId: any, ProfileMembershipType: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getInstaResponse', {
            user_id: userId,
            profile_type_id: profileTypeId,
            membership_type: ProfileMembershipType
        }).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getVisitorSeller(profileTypeId: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getVisitor', {
            profile_type: profileTypeId,
            profile_str: profile_str
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    updateProposalViewedStatus(userId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/updateProposalViewedStatus', {
            userId: userId
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getProfileView(profileTypeId: any, profile_str: any, userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getProfileView', {
            profile_type: profileTypeId,
            profile_str: profile_str,
            user_id: userID
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getProfilepercentage(profileTypeId: any, profile_str: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getProfilepercentage', {
            profile_type: profileTypeId,
            profile_str: profile_str
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getcheckBxMobVerify(userId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkBxMobVerify', {
            user_id: userId
        }).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getcheckInstaRevealCount(userID: any, profileTypeId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/checkInstaRevealCount', {
            user_id: userID, profile_type_id: profileTypeId
        }).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getViewListingNotification(userID: any, notify_create_date: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getViewListingNotification', {
            user_id: userID, notify_create_date: notify_create_date
        }).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getRequestlist(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getRequestlist', {user_id: userID}).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getBxInboxNotification(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getBxInboxNotification', {user_id: userID}).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getBxInboxUnReadNotification(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getBxInboxUnReadNotification', {user_id: userID}).toPromise().then(response => response)
            .catch(this.handleError);

    }

    getcheckBxInvestorType(userID: any, profile_str: any): Promise<any> {

        return this.http.post(this.config.apiEndpoint + '/checkBxInvestorType', {
            user_id: userID,
            inv_profile_str: profile_str
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getcheckBxLenderType(userID: any, profile_str: any): Promise<any> {

        return this.http.post(this.config.apiEndpoint + '/checkBxLenderType', {
            user_id: userID,
            lender_profile_str: profile_str
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getsellerConfidential(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerConfidential' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getsellerprofile(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerprofile' + '/' + profile_str, {
            responseType: 'text'
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }


    getsellerAdvDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerAdvertisement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getsellerInformation(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerInformation' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getsellerFinancial(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerFinancial' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getsellerTeamDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerOwner' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getsellerLocationDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerLocation' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getbusinessRequirement(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerRequirement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }


    getbusinessAttachment(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/sellerAttachment' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getinvestorConfidential(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/investorConfidential' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getinvestorAdvDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/investorAdvertisement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getinvestorIndividualType(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/investorIndividual' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getinvestorFirmType(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/investorFirm' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getinvestorMultiPref(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/investorPreference' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

// startup //

    getstartupConfidential(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupConfidentials' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getstartupAdvDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupAdvertisement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getstartupCompanyDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupCompanyDetails' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getstartupFinancial(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupFinancial' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getstartupTeamDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupteam' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getstartupLocationDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupLocation' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getstartupRequirement(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupRequirement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }


    gestartupAttachment(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupAttachment' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }


    gestartupOther(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/startupOther' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }


// mentor service  //
    getmentorConfidential(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/mentorConfidential' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getmentorAdvDetailsUrl(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/mentorAdvertisement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getmentorDetailsUrl(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/mentorDetail' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getmentorPreference(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/mentorPreference' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }


// start lander //
    getlenderConfidentials(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/lenderConfidential' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getlenderAdvertisement(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/lenderAdvertisement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getprivateLender(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/lenderPrivate' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getnbfcLender(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/lenderNBFC' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getlenderPreference(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/lenderPreference' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    // Incubator Sector Start Here

    getincubatorConfidentials(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/incubatorConfidential' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getincubatorAdvertisement(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/incubatorAdvertisement' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getincubatorDetails(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/incubatorDetails' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getincubatorPreference(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/incubatorPreference' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getincubatorAttachment(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/incubatorAttachment' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getListingNotification(userID: any, notify_create_date: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getListingNotification', {
            user_id: userID, notify_date: notify_create_date
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getUserInfo(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/me', {user_id: userID}).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getuserChatlist(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getuserChatlist', {user_id: userID}).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getMsg(request_id: any) {
        return this.http.post(this.config.apiEndpoint + '/getMsgs', {
            request_id: request_id
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getupdateUserNotification(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/updateUserNotification', {user_id: userID}).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getupdateBxinboxNotification(userID: any, contactedContacts = []): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/updateBxinboxNotification', {
            user_id: userID,
            contactedContacts: contactedContacts
        })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    handleRequest(proposals: any, requestStatus): Promise<any> {
        const url = (requestStatus === 1) ? 'acceptRequest' : 'rejectReguest';
        return this.http.post(this.config.apiEndpoint + '/' + url, {
            receiverID: proposals.receiver,
            senderID: proposals.sender,
            msg: proposals.msg,
            request_id: proposals.request_id
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }

    getbrokerConfidential(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/brokerConfidentials' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getbrokerBusinessInfo(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/brokerBusinessInfo' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getbrokerLocation(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/brokerLocation' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getbrokerOtherInfo(profile_str: any): Promise<any> {
        return this.http.get(this.config.apiEndpoint + '/brokerOtherInfo' + '/' + profile_str)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getBrokerListingDetails(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getBrokerListing', {user_id: userID})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getUserRegisterProfileType(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getUserRegisterProfileType', {user_id: userID})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }

    getUserProfileExist(userID: any, profileTypeId: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getUserProfileExist', {
            user_id: userID, profile_type_id: profileTypeId
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    getUserProfiles(userID: any): Promise<any> {
        return this.http.post(this.config.apiEndpoint + '/getUserProfiles', {
            user_id: userID
        }).toPromise().then(response => response)
            .catch(this.handleError);
    }
}

