
<div class="container bex-main">
      <div class="row">
            <div class="col-md-12 col-xs-12" >
                    <div class="headblk">
                        <h4>{{message}}</h4>

                        <h5>Please click on the link below to access the profile</h5>
                    </div>
                    <div class="contact-btn" style="text-align: center;">
                        <a class="btn btn-outline-bse ng-tns-c82-0"
                           routerLink="/dashboard/myaccount">My profile</a>
                    </div>
                
            </div>
        </div>

<!-- Start Group companies -->
<div class="row grp-comp">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="brand-ttl">Our <span>Group Companies</span></div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal" data-target="#francorp_modal"><img
                            src="assets/images/francorp.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal" data-target="#franchiseIndia_modal"><img
                            src="assets/images/franchise_india.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal" data-target="#franGlobal_modal"><img
                            src="assets/images/franGlobal.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal" data-target="#bradeford_modal"><img
                            src="assets/images/bradeford.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal"
                       data-target="#franchiseIndia_entrepreneur_modal"><img
                            src="assets/images/franchiseIndia_entrepreneur.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal" data-target="#indian_franchise_modal"><img
                            src="assets/images/indian_franchise.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <div class="brand-container">
                    <a [routerLink]="" data-toggle="modal" data-target="#entrepreneur_modal"><img
                            src="assets/images/entrepreneur.png" alt=""/>
                        <div class="detail-view">
                            <div class="view-sec"><i class="fa fa-eye" aria-hidden="true"></i></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- End Group companies -->
<!-- Francorp Modal -->
<div class="about modal fade lg-panel" id="francorp_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img src="assets/images/francorp.png"
                                                                                alt=""/></a></div>
                        <div class="brand-desc">
                            <a [routerLink]="">Francorp</a> is a Global Franchise Consulting Company
                            established in the year 1976. It has since been the unsurpassed leader in its industry
                            globally. The company provides end to end Franchise solutions to over 2,000 enterprises
                            worldwide. Market Opportunity Assessment, Review and Recommendations Advisory, Franchisee
                            Auditing, Conceptualizing and Building up Idea, Business Plan Preparation, India Entry
                            strategy are a few among the services they offer.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FranchiseIndia Modal -->
<div class="about modal fade lg-panel" id="franchiseIndia_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img src="assets/images/franchise_india.png"
                                                                                alt=""/></a></div>
                        <div class="brand-desc">
                            <a title="Franchise India Brands" href="http://www.franchiseindia.in/" target="_blank">Franchise
                                India Brands Limited</a> (FIBL)is the worldâ€™s biggest brokerage network claiming
                            nobody covers India better. We offer Business Advisory, Property Advisory as well as
                            Financial Advisory to an investor base of 300,000 across more than 231 cities in India
                            alone. In the last 15 years, FIBL has helped thousands of investors in selecting the right
                            Franchise and, in turn, assisted more than 500+ International and National Brands for
                            franchise expansions.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fran Global Modal -->
<div class="about modal fade lg-panel" id="franGlobal_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img src="assets/images/franGlobal.png"
                                                                                alt=""/></a></div>
                        <div class="brand-desc">
                            <a title="FranGlobal" href="http://www.franglobal.com/" target="_blank">FranGlobal</a> an
                            international business arm of Franchise India assists any organization expand their
                            footprints in multiple geographies across the globe. They take care of the market entry are
                            growth enablers being dedicated strategy and implementation advisors.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Brade Ford Modal -->
<div class="about modal fade lg-panel" id="bradeford_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img src="assets/images/bradeford.png"
                                                                                alt=""/></a></div>
                        <div class="brand-desc">
                            <a title="Brandford License India" href="http://www.bradfordlicenseindia.com/"
                               target="_blank">Bradford License India</a> Indiaâ€™s first complete licensing solution
                            provider are leaders in Brand Licensing. It was formed with an objective to increase
                            awareness and support the growth of licensing in the country. Their network consists of
                            manufacturers and retailers that span all industries in all the tiers of distribution within
                            India. With an efficient backend support system to handle brand requirements and deliveries
                            they run 9 fully operational offices in all the major cities of India.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Franchise India Entrepreneur Modal -->
<div class="about modal fade lg-panel" id="franchiseIndia_entrepreneur_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img
                                src="assets/images/franchiseIndia_entrepreneur.png" alt=""/></a></div>
                        <div class="brand-desc">
                            <a title="Franchise India" href="http://www.franchiseindia.com/" target="_blank">Franchise
                                India</a> is Asia's largest integrated franchise solution company since 1999, is the
                            absolute authority on Franchising, Licensing, Retailing and Real Estate. Franchise India was
                            also the first company to introduce and develop the franchise market consulting in India and
                            has been completely focused on Franchise Ecosystem for the past 16 years and will continue
                            to do so. The company has serviced over 1100 brands across 5 continents covering various
                            sectors.
                            <p>We at Franchise India offer Business Advisory, Property Advisory as well as Financial
                                Advisory to an investor base of 300,000 across more than 231 cities in India alone. In
                                the last 15 years, FIBL has helped thousands of investors in selecting the right
                                Franchise and, in turn, assisted more than 500+ International and National Brands for
                                franchise expansions. Knowledge, Opportunity, Network and Success are the hallmark of
                                our operations.</p>
                            <p>We have consulted several major brands over these years like HCL, MGF, Quality Walls,
                                Tata, Gitanjali, HSBC, Levis, JK Tyres, Lakme, D'damas, Adidas, Euro Kidz, The Apollo
                                Clinic, Chhabra 555, Kidzee, Motilal Oswal, Rosebys, Next, Welhome and more, through
                                media, advisory and exhibitions.</p>
                            <p>Franchise India has six broad divisions- Franchise India Holdings Limited (FIHL),
                                Franchise India Brands Limited (FIBL), Francorp, Bradford License India, India Franchise
                                Association (IFA) and Entrepreneur India.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Indian Franchise Modal -->
<div class="about modal fade lg-panel" id="indian_franchise_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img src="assets/images/indian_franchise.png"
                                                                                alt=""/></a></div>
                        <div class="brand-desc">
                            <a title="Indian Franchise Association" href="http://www.franchiseindia.org/"
                               target="_blank">Indian Franchise Association (IFA)</a>, Indiaâ€™s premier, non-political,
                            not-for-profit body represents the Indian Franchising Sector. Their endeavor to promote,
                            promulgate and popularize the concept of franchising as a mode for doing business across the
                            industry verticals along with nurturing the entrepreneurial skill of every Indian.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Entrepreneur Modal -->
<div class="about modal fade lg-panel" id="entrepreneur_modal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mdl-close-btn">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="logo-pnl"><a [routerLink]=""><img src="assets/images/entrepreneur.png"
                                                                                alt=""/></a></div>
                        <div class="brand-desc">
                            <a title="Entrepreneur Advisor" href="http://www.entrepreneurindia.com/"
                               target="_blank"><strong>Entrepreneur India Advisors Limited </strong></a>is a unit
                            established with an aim to help perspective entrepreneurs to turn their ideas into
                            successful businesses. The practice is supported by integrated, cross functional,
                            international team of experienced professionals specializing in providing value added
                            multi-disciplinary services to companies.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Google Conversion Code Start Here -->
<script type="text/javascript">
    /* <![CDATA[ */
    var google_conversion_id = 848383829;
    var google_conversion_language = "en";
    var google_conversion_format = "3";
    var google_conversion_color = "ffffff";
    var google_conversion_label = "OyloCPSfmHIQ1Z7FlAM";
    var google_remarketing_only = false;
    /* ]]> */
</script>
<script type="text/javascript"
        src="//www.googleadservices.com/pagead/conversion.js">
</script>
<noscript>
    <div style="display:inline;">
        <img height="1" width="1" style="border-style:none;" alt=""
             src="//www.googleadservices.com/pagead/conversion/848383829/?label=OyloCPSfmHIQ1Z7FlAM&amp;guid=ON&amp;script=0"/>
    </div>
</noscript>
<!-- Google Conversion Code End Here -->

<!-- Linkedin Conversion Code Started Here -->
<script type="text/javascript">
    _linkedin_partner_id = "260427";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
</script>
<script type="text/javascript">
    (function () {
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
    })();
</script>
<noscript>
    <img height="1" width="1" style="display:none;" alt=""
         src="https://dc.ads.linkedin.com/collect/?pid=260427&fmt=gif"/>
</noscript>
<!-- Linkedin Conversion Code End Here -->

<!-- Facebook Pixel Code Start Here -->
<script>
    !function (f, b, e, v, n, t, s) {
        if (f.fbq)return;
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '865253970178641'); // Insert your pixel ID here.
    fbq('track', 'PageView');
    fbq('track', 'Lead');
    fbq('track', 'CompleteRegistration');
</script>
<noscript><img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=865253970178641&ev=PageView&noscript=1"/></noscript>
<!-- End Facebook Pixel Code End Here -->
 