import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastDirective } from '../../directives/last.directive';
import {LimitTo} from '../../pipes/limitto.pipe';
import {UniquePipe} from '../../pipes/unique.pipe';
import {SortPipe} from '../../pipes/sortby';
import {FilterByPipe} from '../../pipes/filterby.pipe';
import { AlertComponent } from './alert/alert.component';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {AgmCoreModule} from '@agm/core';
import { GooglePlaceDirective } from '../../directives/google-place.directive';
import { GoogleDfpComponent } from './components/google-ad/google-dfp.component';
import { GoggleAdComponent } from './components/google-ad/google-add.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchComponent } from './components/search/search.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from './components/payment-cancelled/payment-cancelled.component';
import { CommonThanksComponent } from './components/common-thanks/common-thanks.component';
import { KeysPipe } from '../../pipes/keys.pipe';
import { PriceShortPipe } from '../../pipes/price-short.pipe';
import { NonBrokerProfileTypeFilter } from '../../pipes/non-broker-profile-type-filter.pipe';
import { AddOnComponent } from './components/add-on/add-on.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ImageLazyLoadDirective } from '../../directives/image-lazy-load.directive';
import { ChatService } from '../../services/chat.service';
import { StripTagsPipe } from '../../pipes/strip-tags';
import { SplitPipe } from '../../pipes/split.pipes';
import {ReadWordTime} from '../../pipes/read-word-time.pipe'
//import {GooglePlaceDirective} from '../../directives/google-place.directive';

@NgModule({
  declarations: [
    AlertComponent,
    GooglePlaceDirective,
    GoogleDfpComponent,
    GoggleAdComponent,
    PaginationComponent,
    SearchComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    PaymentCancelledComponent,
    CommonThanksComponent,
    LastDirective,
    LimitTo,
    SortPipe,
    KeysPipe,
    PriceShortPipe,
    NonBrokerProfileTypeFilter,
    AddOnComponent,
    LoaderComponent,
    ImageLazyLoadDirective,
    UniquePipe,
    FilterByPipe,
    StripTagsPipe,
    SplitPipe,
    ReadWordTime


  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyCGuZEYyMyQ0-b3CX9OYVQ1tOj9IBZxSb4',
        libraries: ['places']
    })
  ],
  exports:[
    AlertComponent,
    GooglePlaceDirective,
    GoogleDfpComponent,
    GoggleAdComponent,
    PaginationComponent,
    SearchComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    PaymentCancelledComponent,
    CommonThanksComponent,
    LastDirective,
    LimitTo,
    SortPipe,
    KeysPipe,
    PriceShortPipe,
    NonBrokerProfileTypeFilter,
    AddOnComponent,
    LoaderComponent,
    ImageLazyLoadDirective,
    UniquePipe,
    FilterByPipe,
    StripTagsPipe,
    SplitPipe,
    ReadWordTime

  ],
  providers: [ChatService],
})
export class SharedModule { }
