import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appConfig } from '../../../frontend/app.config';
import { BehaviorSubject } from 'rxjs';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { APP_CONFIG } from '../../../../config/app-config.constants';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MyProfileService } from 'projects/frontend/src/app/services/my-profile.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  industrySeller: any;
  industryStarttup: any;
  industryInvestor: any;
  subscribeForm: FormGroup
  submittedSubscribe: any;
  subscribeMessage: boolean;
  dataResponse: any;
  userID;
  public userProfileSuccessError;
  public userProfileFailError;
  useraccount
  jwtHelper = new JwtHelperService();
  token: any;
  users: any;
  decode;
  profileType;
  profileObjects;
  checkBrowser: boolean = false;
  public isCookieCheckedForEvent: boolean = false;



  static changeTab(flag): void {
    if (flag === 'businesslisting') {
      $('a[data-target="#bIndustry"]').trigger('click');
    } else if (flag === 'startupslisting') {
      $('#bIndustry').removeClass('active');
      $('a[data-target="#sIndustry"]').trigger('click');
    } else if (flag === 'investorlisting') {
      $('#bIndustry').removeClass('active');
      $('a[data-target="#iIndustry"]').trigger('click');
    } else {
      $('a[data-target="#bIndustry"]').trigger('click');
    }
  }


  constructor(
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    @Inject(APP_CONFIG) private config: IAppConfig,
    private http: HttpClient,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private myProfileService: MyProfileService
  ) {

    this.checkBrowser = isPlatformBrowser(this.platformId);

    this.homeService.searchFooterBusinessSeller()
      .subscribe(
        result => {
          this.homeService.replymySubjectBusinessFooter.next(result.industrySeller);
        })

    this.homeService.startupIndustrySeller()
      .subscribe(
        result => {
          this.homeService.replymySubjectStartupFooter.next(result.industrySeller);
        })

    this.homeService.searchFooterInvestorSeller()
      .subscribe(
        result => {
          this.homeService.replymySubjectInvestorFooter.next(result.industryPrefInvestor);
        })

  }


  get sform() { return this.subscribeForm.controls; }

  ngOnInit(): void {

    this.isCookieCheckedForEvent = localStorage.getItem('isCookieCheckedForEvent') == 'true' ? true : false;

    this.subscribeForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      contact: ['', Validators.required],
      city: ['', [Validators.required]],
    })

    this.getUserDetails();
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationEnd)) {
        FooterComponent.changeTab(evt.url.split('/')[1]);
      }
    });



  }


  getUserDetails(): void {
    if (isPlatformBrowser(this.platformId)) {
      const token1 = localStorage.getItem('currentUser');
      if (token1) {
        this.decode = this.jwtHelper.decodeToken(token1);
        this.userID = this.decode.sub;
        if (this.userID !== null && this.userID !== undefined) {
          this.myProfileService.getUserDetails(this.userID)
            .then((result) => {
              this.useraccount = result;
            });
        }
      }
    }

  }


  ngAfterViewInit() {

    this.homeService.replymySubjectBusinessFooter.subscribe((data) => {
      this.industrySeller = data;
    });

    this.homeService.replymySubjectStartupFooter.subscribe((data) => {
      this.industryStarttup = data;
    });

    this.homeService.replymySubjectInvestorFooter.subscribe((data) => {
      this.industryInvestor = data;
    });

    if (isPlatformBrowser(this.platformId)) {

      $(".close-event-warning").on("click", function () {
        localStorage.setItem('isCookieCheckedForEvent', 'true');
        this.isCookieCheckedForEvent = true;
      })

    }


    /*this.homeService.searchFooterBusinessSeller()
    .subscribe(
        result => {
            this.industrySeller = result.industrySeller
            this.homeService.changefooterBussinessList(this.industrySeller);
            this.homeService.replymySubject.next(this.industrySeller);
            
    })
    
    this.homeService.startupIndustrySeller()
      .subscribe(
        result => {
          this.industryStarttup = result.industrySeller;
        })

    this.homeService.searchFooterInvestorSeller()
      .subscribe(
        result => {
          this.industryInvestor = result.industryPrefInvestor;
        })
        */
  }

  subscribeSubmit() {

    this.submittedSubscribe = true;

    if (this.subscribeForm.invalid) {
      return;
    }

    let data = {
      name: this.subscribeForm.controls.name.value,
      email: this.subscribeForm.controls.email.value,
      contact: this.subscribeForm.controls.contact.value,
      city: this.subscribeForm.controls.city.value,
    }

    this.homeService.subscribeMailer(data).subscribe(Response => {
      this.subscribeMessage = true;
      this.dataResponse = Response.message != undefined ? Response.message : Response;
      this.subscribeForm.reset();
      // setTimeout(function () {
      //     $('.msg-pnl_sucess').delay(0).slideToggle('slow');
      //     $('.msg-pnl_sucess').delay(5000).slideToggle('slow');
      // }, 50);

    }, error => {
      console.log(error);
    }
    )

  }

  onclickFooterPage() {
    appConfig.scrollToError(true);
  }


  saveUserAccountDetail(data) {
    if ($('form[name="contactForm"]').validator('validate').has('.has-error').length) {
      return;
    }
    data.user_id = this.userID;
    this.http.post(this.config.apiEndpoint + '/updateUserDetails', data).subscribe(
      response => {
        console.log('registration successfull');

        this.userProfileSuccessError = 'Profile Details Updated Successfully';
        $('.msg-pnl_sales').delay(0).slideToggle('slow');
        setTimeout(function () {
          $('.msg-pnl_sales').delay(3000).slideToggle('slow');
        }, 6000);

        window.location.reload();

      },
      error => {
        console.log('registration failed');
        console.log(error);
        this.userProfileFailError = 'Please Enter Correct Details';
      });
  }



}
