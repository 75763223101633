import {Component, PLATFORM_ID, Inject} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ContactService} from '../../../../services/contact.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {JwtHelperService} from '@auth0/angular-jwt';

declare var $: any;

@Component({
    selector: 'mentor-profile',
    templateUrl: './mentor-profile.component.html'
})
export class MyMentorProfileComponent {

    profile_str;
    jwtHelper = new JwtHelperService();
    users = {};
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileType = this.decode.userProfile;
    profileObjects = this.profileType;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    public mentorProfileData;
    public mentorProfExperience;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private contactService: ContactService,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Mentor Profile Details - BusinessEx');
        meta.addTags([
            {name: 'Author', content: 'businessex.com'},
            {name: 'Keywords', content: 'businessex Seo Keywords'},
            {name: 'Description', content: 'businessex description'}
        ]);
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
        this.getMentorContactDetails(this.profile_str);
    }

    getMentorContactDetails(profile_str: any): void {
        this.contactService.getMentorContactDetails(this.profile_str)
            .then((result) => {
                this.mentorProfileData = result.mentorData;
                this.mentorProfExperience = result.profExperience;
            });
    }

}
