import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient} from "@angular/common/http";
import {ContactService} from "../../../../services/contact.service";
import {MyProfileService} from '../../../../services/my-profile.service';
import {APP_CONFIG} from "../../../../config/app-config.constants";
import {ActivatedRoute} from '@angular/router';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from "@angular/common";
import {appConfig} from "../../../frontend/app.config";
import {MapsAPILoader} from "@agm/core";

declare var $: any;

@Component({
    selector: 'investor-multi-pref',
    templateUrl: './investor-multi-pref.component.html'
})
export class InvestorMultiPrefComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;

    profile_str;
    public investorProfileSuccessError;
    public investorProfileFailError;
    public bxInvestorType;
    data;

    public InvestorMultiPrefComponentdata;

    private isViewChecked = false;
    public industryCategoryData;

    constructor(meta: Meta, title: Title, private http: HttpClient,
                private myprofileService: MyProfileService,
                route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private contactService: ContactService,
                private mapsAPILoader: MapsAPILoader) {
        title.setTitle('Investor Preference Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    investorMyAccountSubmit(data) {
        data.industry_pref1 = appConfig.getFormattedValues('#industry_pref', this.industryCategoryData);
        data.location_pref1 = $(".LocationPrefrencetagit").val();
        data.inv_profile_str = this.profile_str;
        if ($('#industry_pref').val().length === 0) {
            $('#industry_pref').parent().css('border', '1px solid red');
            return;
        }
        $('#industry_pref').parent().css('border', 'none');
        this.http.post(this.config.apiEndpoint + '/updateinvestorPreference/' + this.profile_str, data).subscribe(
            data => {
                $("#contactUsForm .form-control").val("");
                this.investorProfileSuccessError = "Preference Details Updated Successfully";
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
                window.scrollTo(0, 0);

            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.investorProfileFailError = "Please Enter Correct Details";
                window.scrollTo(0, 0);
            });
    }

    getinvestorMultiPref(): void {
        this.myprofileService.getinvestorMultiPref(this.profile_str)
            .then((result) => {
                this.InvestorMultiPrefComponentdata = result;
                if (isPlatformBrowser(this.platformId)) {
                    $(".LocationPrefrencetagit").tagit(appConfig.mapTagItConfiguration(this.mapsAPILoader));
                    this.InvestorMultiPrefComponentdata.location_pref.forEach(item => {
                        $('.LocationPrefrencetagit').tagit("createTag", item.location_name);
                    });
                    $('#industry_pref').tagit({allowSpaces: true});
                    this.InvestorMultiPrefComponentdata.industry_pref.forEach(item => {
                        $('#industry_pref').tagit("createTag", item.name);
                    });
                }
            });
    }

    getcheckBxInvestorType(): void {
        this.myprofileService.getcheckBxInvestorType(this.userID, this.profile_str)
            .then((result) => {
                this.bxInvestorType = result;
            });
    }

    ngOnInit() {
        this.getIndustrySector();
        this.getcheckBxInvestorType();
        this.getinvestorMultiPref();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="investorForm"]').validator();
        }
    }

    getIndustrySector(): void {
        this.contactService.getIndustrySector()
            .then((result) => {
                this.industryCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.industryCategoryData);
                    setTimeout(() => {
                        $('#industry_pref').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }

}
