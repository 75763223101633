    <div class="card bex-card-m-main">
        <div class="bex-card-main">
           <div class="bex-form-section-main">
              <h5>Quick Registration</h5>
              <app-alert></app-alert>
           </div>
           <div class="msg-pnl_sales" *ngIf="submitMessage">{{(!!submitMessage && submitMessage.constructor === Array) ? submitMessage[0]: submitMessage}}</div>
        </div>
        <div>
           <div class="bex-form-section">
              <form name="salesSellerForm" [formGroup]="quickRegistration" (ngSubmit)="qRFormSubmit()">
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <span class="input-group-text" id="basic-addon1" [ngClass]="{'invalid-background' : submitted && qRform.business_name.errors}" ><img src="./assets/img/doc-file.png" /></span>
 
                    </div>
                    <select id="inputState" class="form-control"  formControlName="business_name" [ngClass]="{'invalid-border' : submitted && qRform.business_name.errors}">
                    <option hidden="" selected="" value="">Select a profile</option>
                    <option value="1">Business | Looking To Sell</option>
                    <option value="7">Startup | Looking For Funds</option>
                    <option value="2">Investor | Looking To Invest/Buy</option>
                    <option value="4">Mentor | Looking To Guide/Coach </option>
                    <!-- <option value="5">Incubation</option> -->
                    
                    </select>
 
                    <!-- <div *ngIf="submitted && qRform.business_name.errors" class="invalid-feedback">
                        <div *ngIf="qRform.business_name.errors.required">Please Select Your Profile</div>
                    </div> -->
 
                 </div>
                
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <span class="input-group-text" [ngClass]="{'invalid-background' : submitted && qRform.s_name.errors}" id="basic-addon1"><img src="./assets/img/person.png" /></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Enter Your Name" aria-label="Username" aria-describedby="basic-addon1" formControlName="s_name" [ngClass]="{'invalid-border' : submitted && qRform.s_name.errors}">
 
                       <!-- <div *ngIf="submitted && qRform.s_name.errors" class="invalid-feedback">
                        <div *ngIf="qRform.s_name.errors.required">Please enter your name</div>
                    </div> -->
 
                 </div>
 
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <span class="input-group-text" [ngClass]="{'invalid-background' : submitted && qRform.s_mobile.errors}" id="basic-addon1"><img src="./assets/img/telephone.png" /></span>
                    </div>
                    <input type="tel" class="form-control" placeholder="Enter Your Mobile No." aria-label="Mobile No"
                       aria-describedby="basic-addon1" formControlName="s_mobile" pattern="[56789][0-9]{9}" minlength="10" maxlength="10"
                       onkeypress="return isNumber(event)"
                       [ngClass]="{'invalid-border' : submitted && qRform.s_mobile.errors}"
                    >
 
                    <!-- <div *ngIf="submitted && qRform.s_mobile.errors" class="invalid-feedback">
                        <div *ngIf="qRform.s_mobile.errors.required">Please enter your 10 digit mobile number</div>
                    </div> -->
 
 
                 </div>
                 <div class="input-group mb-3">
 
                    <div class="input-group-prepend">
                       <span class="input-group-text" [ngClass]="{'invalid-background' : submitted && qRform.s_email.errors}" id="basic-addon1"><img src="./assets/img/mail.png" /></span>
                    </div>
                    <input type="email" class="form-control" placeholder="Enter Your Email ID" aria-label="Email"
                       aria-describedby="basic-addon1" formControlName="s_email" [ngClass]="{'invalid-border' : submitted && qRform.s_email.errors}">
 
                    <!-- <div *ngIf="submitted && qRform.s_email.errors" class="invalid-feedback">
                        <div *ngIf="qRform.s_email.errors.required">Please enter your email ID</div>
                    </div> -->
 
                 </div>
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <span class="input-group-text" id="basic-addon1" [ngClass]="{'invalid-background' : submitted && qRform.s_company.errors}"><img src="./assets/img/company.png" /></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Enter Company Name" aria-label="Company" formControlName="s_company"
                       aria-describedby="basic-addon1" [ngClass]="{'invalid-border' : submitted && qRform.s_company.errors}">
 
                    <!-- <div *ngIf="submitted && qRform.s_company.errors" class="invalid-feedback">
                        <div *ngIf="qRform.s_company.errors.required">Please enter your company name</div>
                    </div> -->
                 </div>
                 <div class="bex-form-top-btn">
                    <button type="submit" class="btn btn-outline-secondary btn-outline-secondary-main">
                       <span style="color:#fe7806;" *ngIf="formSubmitted">Loading....</span>
                       <span *ngIf="!formSubmitted">Submit</span>
                     </button>
                 </div>
              </form>
           </div>
        </div>
     </div>
 