import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

declare var googletag: any;

@Component({
    selector: 'google-dfp',
    template: `
        <div class="adContainer" style="text-align: center;">
            <div id={{bannerId}}></div></div>`
})
export class GoogleDfpComponent implements AfterViewInit {
    @Input() dfpSlot: string;

    @Input() bannerId: string;

    @Input() bWidth: number;

    @Input() bHeight: number;

    constructor() {
    }

    /**
     * Called when the component is loading
     */

    ngAfterViewInit() {
        if (googletag !== undefined) {
            this.defineAds(googletag);
            let tag: any = this.bannerId;
            this.displayAdCustom(tag);
        }
    }

    /**
     * Displays an ad
     * @param tag The complete tag/bannerId of the ad wanted to be displayed
     */
    displayAdCustom(tag: any): void {
        document.getElementById(tag).setAttribute('id', tag);
        googletag.cmd.push(function (result: any) {
            googletag.display(tag);
        });
    }

    /**
     * Define Slots used for the ads to display
     * @param googletag The googletag declared by google's script included in index.html
     */
    defineAds(googletag: any): void {
        var googletag = googletag || {};
        googletag.cmd = googletag.cmd || [];
        const gptAdSlots = [];
        const me = this;
        googletag.cmd.push(function () {
            if (me.bannerId !== null || me.bannerId !== undefined) {
                gptAdSlots.push(googletag.defineSlot(me.dfpSlot, [[me.bWidth, me.bHeight]], me.bannerId).addService(googletag.pubads()));
            }
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
        });
    }
}
