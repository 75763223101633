import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG, INDUSTRY_SECTOR} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'mentor-details',
    templateUrl: './mentor-details.component.html'
})
export class MentorDetailsComponent implements OnInit, AfterViewChecked {

    profile_str;
    public mentorDetailsdata;
    public mentorProfileSuccessError;
    public mentorProfileFailError;
    private isViewChecked = false;
    files;
    industry_sector = INDUSTRY_SECTOR;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Mentor Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    mentorMyAccountSubmit(data) {
        const formData: any = new FormData();
        if (this.files != undefined) {
            formData.append('mentor_profile_pic', this.files[0], this.files[0].name);
        }
        if (data.mentor_profile_pic_old != '') {
            formData.append('mentor_profile_pic_old', data.mentor_profile_pic_old);
            //console.log(data.mentor_profile_pic_old);
        }
        formData.append('mentor_profile_str', this.profile_str);
        formData.append('mentor_occupation', data.mentor_occupation);
        formData.append('mentor_company', data.mentor_company);
        formData.append('mentor_designation', data.mentor_designation);
        formData.append('mentor_profile_summary', data.mentor_profile_summary);
        formData.append('mentor_linkedin', data.mentor_linkedin);

        this.http.post(this.config.apiEndpoint + '/updatementordetailsdata/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Mentor Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.mentorProfileSuccessError = "Mentor Details Updated Successfully";
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.mentorProfileFailError = "Please Enter Correct Details";
            });
    }

    fileChangeEvent(fileInput: any) {
        this.files = fileInput.target.files;
    }

    getmentorDetailsUrl(profile_str: any): void {
        this.myprofileService.getmentorDetailsUrl(this.profile_str)
            .then((result) => {
                this.mentorDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getmentorDetailsUrl(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="mentorForm"]').validator();
        }
    }

}
