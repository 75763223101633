import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute} from '@angular/router';
import {ContactService} from '../../../../services/contact.service';
import {BUSINESS_IMAGES} from '../../../../static-data/business-images';
import {appConfig} from "../../../frontend/app.config";

@Component({
    selector: 'startup-profile',
    templateUrl: './startup-profile.component.html'
})
export class MyStartupProfileComponent implements OnInit {

    profile_str;
    jwtHelper = new JwtHelperService();
    users = {};
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileType = this.decode.userProfile;
    profileObjects = this.profileType;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    public startupmgtTeamDetail;
    public startupfundRaisingDetail;
    startupProfileData;
    startupImages = [];
    startupDocuments = [];
    public appConfig = appConfig;

    constructor(private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                private contactService: ContactService,
                @Inject(PLATFORM_ID) private platformId: Object) {
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    getStartupContactDetails(profile_str: any): void {
        this.contactService.getStartupContactDetails(this.profile_str)
            .then((result) => {
                this.startupProfileData = result.startupData;
                this.startupmgtTeamDetail = this.startupProfileData.mgtTeamDetail;
                this.startupfundRaisingDetail = this.startupProfileData.fundRaisingDetail;
                this.startupDocuments = result.startupDocuments;
                this.startupImages = result.startupImages;
                if (Object.keys(result.startupImages).length === 0) {
                    this.startupImages = this.imageFunction(this.startupProfileData.parentCatId);
                }

            });
    }

    imageFunction(parentCatId: any): any {
        const images = BUSINESS_IMAGES.filter(
            item => item.parentId === parentCatId)[0];
        return images.imageSet;
    }

    ngOnInit() {
        this.getStartupContactDetails(this.profile_str);
    }

}
