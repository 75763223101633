
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    LENDER PROFILE
                                </div>
                            </div>
                            <div class="margin-20 cat-detail investor">
                                <div class="row form-sec">

                                    <div class="col-xs-12 col-sm-12 col-md-12 scroll-ph sec-slide-effect">
                                        <div class="page-ttl"><h1>{{lenderProfileData?.lender_adv_headline}}</h1></div>
                                        <div class="shrt-desc tp-border">
                                            {{lenderProfileData?.lender_intro}}
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="lenderProfileData?.lender_type == '2'">
                                        <div class="col-xs-12 col-sm-4">

                                            <div class="investor-profile">

                                                <img class="img-responsive"
                                                     *ngIf="lenderProfileData?.profile_pic_path != ''"
                                                     src={{lenderProfileData?.profile_pic_path}}/>
                                                <img class="img-responsive"
                                                     *ngIf="lenderProfileData?.profile_pic_path == '' || lenderProfileData?.profile_pic_path == null"
                                                     src="assets/images/userprofilepic.gif"/>

                                            </div>

                                        </div>

                                        <div class="col-xs-12 col-sm-8 col-md-8">
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                <div class="investor-name">{{lenderProfileData?.lender_name}}
                                                    <span class="pipe">|</span>
                                                    <span class="location">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i> {{lenderProfileData?.lender_location}}
                                </span>
                                                </div>
                                                <div class="overview">{{lenderProfileData?.lender_occupation}}</div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                <div class="investor-contacts">
                                                    <div class="contact-ph">MOBILE <span>{{lenderProfileData?.lender_mobile}}</span>
                                                    </div>
                                                    <div class="contact-email">EMAIL <span>{{lenderProfileData?.lender_email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Place of residence:
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.lender_city !== '') ?
                                                    lenderProfileData?.lender_city : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Occupation:
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.lender_occupation !== '') ?
                                                    lenderProfileData?.lender_occupation : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Lending capacity :
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.lending_capacity !== '') ?
                                                    lenderProfileData?.lending_capacity : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                Lending interest rate :
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.lending_interest_rate !== '') ?
                                                    lenderProfileData?.lending_interest_rate : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect come-in">
                                                Location Preference
                                                <div class="overview">
                                                    {{ (lenderProfileData?.locations !== '') ?
                                                    lenderProfileData?.locations : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect come-in">
                                                Sector Preference
                                                <div class="overview">
                                                    {{ (lenderProfileData?.industries !== '') ?
                                                    lenderProfileData?.industries : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect come-in">
                                                Professional summary
                                                <div class="overview">
                                                    {{ (lenderProfileData?.prof_summary !== '') ?
                                                    lenderProfileData?.prof_summary : ("N/A") }},
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row" *ngIf="lenderProfileData?.lender_type == '3'">
                                        <div class="col-xs-12 col-sm-4">

                                            <div class="investor-profile">

                                                <img class="img-responsive"
                                                     *ngIf="lenderProfileData?.profile_pic_path != ''"
                                                     src={{lenderProfileData?.profile_pic_path}}/>
                                                <img class="img-responsive"
                                                     *ngIf="lenderProfileData?.profile_pic_path == '' || lenderProfileData?.profile_pic_path == null"
                                                     src="assets/images/userprofilepic.gif"/>

                                            </div>
                                            <a [routerLink]="" class="company-logo"
                                               *ngIf="lenderProfileData?.nbfc_corporate_profile_path != '' && lenderProfileData?.nbfc_corporate_profile_path != null">
                                                <img *ngIf="lenderProfileData?.nbfc_corporate_profile_path != '' && lenderProfileData?.nbfc_corporate_profile_path != null"
                                                     src={{lenderProfileData?.nbfc_corporate_profile_path}}/>
                                            </a>

                                        </div>

                                        <div class="col-xs-12 col-sm-8 col-md-8">
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                <div class="investor-name">{{lenderProfileData?.lender_name}}
                                                    <span class="pipe">|</span>
                                                    <span class="location">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i> {{lenderProfileData?.lender_location}}
                                </span>
                                                </div>
                                                <div class="overview">{{lenderProfileData?.lender_occupation}},
                                                    {{lenderProfileData?.nbfc_comp_name}} ,
                                                    {{lenderProfileData?.nbfc_country}}
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect already-visible come-in">
                                                <div class="investor-contacts">
                                                    <div class="contact-ph">MOBILE <span>{{lenderProfileData?.lender_mobile}}</span>
                                                    </div>
                                                    <div class="contact-email">EMAIL <span>{{lenderProfileData?.lender_email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Designation:
                                                <div class="overview inline">
                                                    {{lenderProfileData?.nbfc_contact_designation}}<span
                                                        class="pipe">|</span>
                                                    <span class="location"><i class="fa fa-map-marker"
                                                                              aria-hidden="true"></i>
                            {{ (lenderProfileData?.nbfc_location !== '') ? lenderProfileData?.nbfc_location : ("N/A") }}, 
                        </span>
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Company Name:
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.nbfc_comp_name !== '') ?
                                                    lenderProfileData?.nbfc_comp_name : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                NBFC Type:
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.nbfc_type !== '') ?
                                                    lenderProfileData?.nbfc_type : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Company website:
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.nbfc_website !== '') ?
                                                    lenderProfileData?.nbfc_website : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                About the company
                                                <div class="overview">
                                                    {{ (lenderProfileData?.nbfc_about !== '') ?
                                                    lenderProfileData?.nbfc_about : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Lending capacity :
                                                <div class="overview inline">
                                                    {{ (lenderProfileData?.lending_capacity !== '') ?
                                                    lenderProfileData?.lending_capacity : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Location Preference
                                                <div class="overview">
                                                    {{ (lenderProfileData?.locations !== '') ?
                                                    lenderProfileData?.locations : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Sector Preference
                                                <div class="overview">
                                                    {{ (lenderProfileData?.industries !== '') ?
                                                    lenderProfileData?.industries : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Professional summary
                                                <div class="overview">
                                                    {{ (lenderProfileData?.prof_summary !== '') ?
                                                    lenderProfileData?.prof_summary : ("N/A") }},
                                                </div>
                                            </div>
                                            <div class="business-detail-sec sec-slide-effect">
                                                Registered with RBI:
                                                <div class="overview inline">
                                                    <span *ngIf="lenderProfileData?.rbi_registered == '0'">No</span>
                                                    <span *ngIf="lenderProfileData?.rbi_registered == '1'">YES</span>
                                                    <span *ngIf="lenderProfileData?.rbi_registered == '1'">({{lenderProfileData?.rbi_registered_no}})</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
