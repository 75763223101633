import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {IAppConfig} from '../../../../config/app-config.interface';
import {APP_CONFIG, EMPLOYEE_COUNT} from '../../../../config/app-config.constants';
import {isPlatformBrowser} from '@angular/common';


declare var $: any;

@Component({
    selector: 'broker-business-information',
    templateUrl: './broker-business-information.component.html'
})
export class BrokerBusinessInformationComponent implements OnInit, AfterViewChecked {
    profile_str;
    public BrokerBusinessdata;
    public brokerProfileSuccessError;
    public brokerProfileFailError;
    public company_city = '';
    public company_state = '';
    public company_country = '';
    logos;
    private isViewChecked = false;
    estblish_year = [];
    i;

    getAddress(place: Object) {
        this.company_city = place['address_components'][0].long_name;
        this.company_state = place['address_components'][2].short_name;
        this.company_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Broker Profile Business Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    empployee_count = EMPLOYEE_COUNT;

    getEstYear(): void {
        for (this.i = 2017; this.i > 1899; this.i--) {
            this.estblish_year.push(this.i.toString());
        }
    }

    logoChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.logos = fileInput.target.files;
            //console.log(this.logos);
        }
    }

    brokerMyAccountSubmit(data) {
        if ($('form[name="brokerForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        const formData: any = new FormData();
        if (this.logos != undefined) {
            formData.append('broker_company_logo', this.logos[0], this.logos[0].name);
            //console.log(this.logos[0]);
        }
        if (data.signature_old != '') {
            formData.append('broker_company_logo_old', data.broker_company_logo_old);
            //console.log(data.broker_company_logo_old);
        }
        formData.append('broker_profile_str', this.profile_str);
        formData.append('broker_company', data.broker_company);
        formData.append('estb_year', data.estb_year);
        formData.append('emp_count', data.emp_count);
        formData.append('company_website', data.company_website);
        formData.append('company_city', this.company_city);
        formData.append('company_state', this.company_state);
        formData.append('company_country', this.company_country);

        this.http.post(this.config.apiEndpoint + '/updateBrokerBusiness/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Business Details Updated Successfully");
                $("#contactUsForm .form-control").val("");

                this.brokerProfileSuccessError = "Broker Business Details Updated Successfully";
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.brokerProfileFailError = "Please Enter Correct Details";

            });
    }

    getbrokerConfidential(profile_str: any): void {
        this.myprofileService.getbrokerBusinessInfo(this.profile_str)
            .then((result) => {
                this.BrokerBusinessdata = result;
            });
    }

    ngOnInit() {
        this.getbrokerConfidential(this.profile_str);
        this.getEstYear();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="brokerForm"]').validator();
        }
    }

}
