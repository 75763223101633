<div class="myAccount">
    <div class="container">
        <div class="">
            <!-- Filter Panel end -->
            <div class="">
                <!-- Page Title -->
                <div class="row">
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="pos-rel">
                            <div class="height-calc">
                                <div class="width-calc">
                                    <div class="bx-mail ph-fxd">
                                        <div class="mail-bdy">
                                            <div class="mail-profile">
                                                <div class="ph-arrow">
                                                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                                </div>
                                                <img *ngIf="!selectedUser" src="assets/images/profile-dflt.jpg"
                                                    alt="profile pic" width="48" />
                                                <a *ngIf="selectedUser" routerLink="/{{selectedUser?.profileLink}}">
                                                    <img src="{{ (selectedUser?.profilepic) ? selectedUser?.profilepic: 'assets/images/profile-dflt.jpg'}}"
                                                        alt="profile pic" width="48" />
                                                </a>
                                                <div class="detail-profile">
                                                    <div class="profile">
                                                        <span *ngIf="!selectedUser">BX Mail</span>
                                                        <a *ngIf="selectedUser"
                                                            routerLink="/{{selectedUser?.listingLink}}">
                                                            {{ (selectedUser?.profileName) ? selectedUser?.profileName :
                                                        'BX Mail'}}
                                                        </a>
                                                    </div>
                                                    <div class="name">
                                                        <span *ngIf="!selectedUser">Select User to Chat</span>
                                                        <a *ngIf="selectedUser"
                                                            routerLink="/{{selectedUser?.profileLink}}">
                                                            {{((selectedUser?.name) ? selectedUser?.name : 'User')}}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul id="msg-bdy-list" *ngIf="msgs.length > 0">
                                                <li class="msg-bdy-list" *ngFor="let msg_data of msgs"
                                                    [ngClass]="{ left:uid==msg_data.to_id , right:uid!=msg_data.to_id }">
                                                    <div class="sec-right">
                                                        <div class="profile" *ngIf="uid==msg_data.to_id">
                                                            <img *ngIf="selectedUser?.profilepic ==''"
                                                                src="assets/images/profile-dflt.jpg" alt="profile pic"
                                                                width="48" />
                                                            <img *ngIf="selectedUser?.profilepic !==''"
                                                                src="{{selectedUser?.profilepic}}" alt="profile pic"
                                                                width="48" />
                                                        </div>
                                                        <div class="msg">
                                                            <div class="sender-nm">{{msg_data.msg}}</div>


                                                            <div class="attach-file" *ngIf="msg_data.files.length > 0">
                                                                <ul>

                                                                    <li
                                                                        *ngFor="let attach of  msg_data.files; let i =index;">
                                                                        <div class="file-name">{{attach?.file_name}}
                                                                        </div>
                                                                        <!-- <div class="file-size" ng-init="filesize = ( msg_data.attachsize..split(',') | explode )">
                                                                            {{ filesize[i]  }}
                                                                        </div> -->
                                                                        <a href="{{attach?.file_path}}" target="_self"
                                                                            download="{{attach?.file_name}}"
                                                                            class="down-file">
                                                                            <i class="fa fa-cloud-download"
                                                                                aria-hidden="true">

                                                                            </i>
                                                                        </a>

                                                                    </li>
                                                                </ul>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="time">{{msg_data.timestamp*1000 | date}}</div>
                                                </li>

                                            </ul>
                                            <div class="mail-compose" *ngIf="selectedUser">
                                                <form name="bxFormmsg" #f="ngForm" (ngSubmit)="send_msg(f);"
                                                    role="form">
                                                    <div class="form-group has-feedback">
                                                        <div class="input-area">
                                                            <textarea id="msg_modal" name="sendtext" rows='1'
                                                                #sendtext="ngModel" class="form-control"
                                                                placeholder="Type a message..."
                                                                data-error="Please enter message." required ngModel
                                                                (keypress)="isSubmit($event)"></textarea>
                                                        </div>
                                                    </div>
                <div class="action">
                    <div class="submit-msg">
                        <button type="submit" class="btn btn-default btn-blue"
                            data-dismiss="modal">
                            Send
                        </button>
                    </div>
                    <div class="attachment">

                        <img src="assets/images/attachment.jpg" />

                        <!-- <input type="file" id="bx_inbox_attach" name="bx_inbox_attach" file-model="bx_inbox_attach" class="filestyle">

                        <input type="file" class="file-upload" file-upload multiple/> -->

                        <input id="cin"  name="cin" type="file"
                            (change)="fileChangeEvent($event)"
                            placeholder="Upload a file..." multiple />
                    </div>
                </div>
                <div class="attachment-file" style="display: block;">
                    <ul>
                        <li *ngFor="let file of files;let i =index;">
                            <div class="file-name">{{file.name}}</div>
                            <div class="file-size">
                                {{file.size }}
                            </div>
                            <div class="rm-file">
                                <a [routerLink]=""
                                    (click)="removeUploadEvent(file.name)"><i
                                        class="fa fa-times"
                                        aria-hidden="true"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>

                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="mail-list">
                                        <loader [loading]="messageLoading"></loader>
                                        <div class="sec-ttl">My Contacts</div>
                                        <div class="searchbar">
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Search for...">
                                                <!--<span class="input-group-btn">
                                                    <button class="btn btn-default" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>
                                                </span>-->
                                            </div><!-- /input-group -->
                                        </div>
                                        <div class="msg-list">
                                            <ul>
                                                <li *ngFor="let singleuser of userChatList | sortBy : 'msgId' : 'DESC';"
                                                    id="{{singleuser?.request_id}}"
                                                    [ngClass]="{ 'unread': singleuser.readstatus==1 }">
                                                    <div class="mail-profile" (click)="highLightUser(singleuser)">
                                                        <img *ngIf="singleuser?.profilepic ==''"
                                                            src="assets/images/profile-dflt.jpg" alt="profile pic"
                                                            width="48" />
                                                        <img *ngIf="singleuser?.profilepic !=''"
                                                            src="{{singleuser.profilepic}}" alt="profile pic"
                                                            width="48" />
                                                        <div class="detail-profile">
                                                            <div class="profile">{{singleuser?.profileName}}<span
                                                                    class="pull-right">{{singleuser?.timestamp*1000 | date}}</span>
                                                            </div>
                                                            <div class="name">{{singleuser?.name}}
                                                                <div class="status"
                                                                    [ngClass]="{'online': singleuser.isUserOnline ===1}">
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                            <div class="comp-info">
                                                                <div *ngIf="singleuser?.companyname !=''">
                                                                    {{singleuser?.companyname}},
                                                                </div>
                                                                <div *ngIf="singleuser?.designation !=''">
                                                                    {{singleuser?.designation}} ,
                                                                </div>
                                                                <div *ngIf="singleuser?.location !=''">
                                                                    {{singleuser?.location}}
                                                                </div>
                                                            </div>
                                                            <div id="msg{{singleuser?.request_id}}"
                                                                class="shrt-mail content" style="width:70%;">
                                                                {{singleuser?.msgtext}}</div>
                                                            <div style="font-size: 13px;"
                                                                *ngIf="profileObjects[0].profile_type == profiles.Broker">
                                                                <strong style="font-weight: bold">Business Contacted
                                                                    :</strong> {{singleuser?.sellerCompany}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->