<!-- Start Body Part -->
<div class="row myAccount">
  <div class="container">
    <div class="row">
<div *ngFor="let message of messages">
  {{message.timestamp| date: 'M/d/yy'}} {{message.timestamp| date: 'h:mm:ss'}}: {{message.msg}}
</div>

<input #chatInput (keyup.enter)="sendMessage()" [(ngModel)]="message" />
<button (click)="sendMessage()">Send</button>
    </div>
  </div>
</div>
