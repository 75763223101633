import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { appConfig } from '../../app.config';
import { STATE } from '../../../../static-data/state';
import { LinkService } from '../../../../services/link.service';
import { BASE_URL, FILTER_RANGE } from '../../../../config/app-config.constants';
import { Meta, Title } from '@angular/platform-browser';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';
import * as noui from 'nouislider';


declare var $: any;
var moveSlider = false;


@Component({
    selector: 'app-investor-listing',
    templateUrl: './investor-listing.component.html',
    styleUrls: [
        './investor-listing.component.css'
]
})
export class InvestorListingComponent implements OnInit {

    priceRange;
    filterLocation12;
    parentChildCategoryId;
    industrySeller;
    filterinvestorType: any = [];
    investorType = [];
    loading = false;
    pagination = false;
    currentPage = 1;
    cityArray: any = []
    catind: any = [];
    subcatind: any = [];
    investor = [];
    itemsPerPage = 12;
    minInvestment = 0;
    maxInvestment = 1000000000;
    annualsalesmin2 = 10000;
    minInvestmentValue;
    maxInvestmentValue;
    sortby;
    totalItems = 0;
    industryloc = [];
    filterIndustrymain: any = [];
    filterIndustry: any = [];
    industry = [];
    industryid = [];
    state = [];
    stateArray: any = [];
    city = [];
    filterLocationstate: any = [];
    filterLocation: any = [];
    subheadline;
    hititle;
    public noResult = false;

    public investorOccupation = [];
    public filterInvestor = [];
    public filterInvestorArray = [];
    public appConfig = appConfig;
    public isViewChecked = false;
    public seo;
    testDataValue:any = [];
    checkCurrectCity = false;
    getindustrysubCat ='';
    getindustrymainCat ='';
    getindustrysubSlug='';

    @ViewChild('slider', {read: ElementRef}) slider: ElementRef;
    public sliderNo: noui.noUiSlider;

    sliderRange; //= FILTER_RANGE.length - 1;

    someKeyboardConfig: any = {
        connect: true,
        start: [0, this.maxInvestment],
        step: 1,
        tooltips: [false,false],
        // range: {
        //   min: this.minInvestment,
        //   max: this.maxInvestment
        // },

        range: {
            'min': 0,
            '2%': 200*1.5*this.annualsalesmin2,
            '4%':200*3*this.annualsalesmin2,
            '8%':200*4.5*this.annualsalesmin2,
            '10%':200*6*this.annualsalesmin2,
            '12%':200*7.5*this.annualsalesmin2,
            '14%':200*9*this.annualsalesmin2,
            '16%': 200*10.5*this.annualsalesmin2,
            '18%': 200*12*this.annualsalesmin2,
            '20%': 200*13.5*this.annualsalesmin2,
            '22%': 200*15*this.annualsalesmin2,
            '24%': 200*16.5*this.annualsalesmin2,
            '26%': 200*18*this.annualsalesmin2,
            '28%': 200*19.5*this.annualsalesmin2,
            '32%': 200*21*this.annualsalesmin2,
            '34%': 200*22.5*this.annualsalesmin2,
            '36%': 200*24*this.annualsalesmin2,
            '38%': 200*25*this.annualsalesmin2,
            '40%': 200*26.5*this.annualsalesmin2,
            '42%': 200*28*this.annualsalesmin2,
            '46%': 200*29.5*this.annualsalesmin2,
            '48%': 200*31*this.annualsalesmin2,
            '50%': 200*32.5*this.annualsalesmin2,
            '52%': 200*34*this.annualsalesmin2,
            '54%': 200*35.5*this.annualsalesmin2,
            '56%': 200*37*this.annualsalesmin2,
            '58%': 200*38.5*this.annualsalesmin2,
            '60%': 200*40*this.annualsalesmin2,
            '62%': 200*41.5*this.annualsalesmin2,
            '64%': 200*43*this.annualsalesmin2,
            '66%': 200*44.5*this.annualsalesmin2,
            '68%': 200*46*this.annualsalesmin2,
            '70%': 200*47.5*this.annualsalesmin2,
            '72%': 200*49*this.annualsalesmin2,
            '74%': 200*50.5*this.annualsalesmin2,
            '76%': 200*52*this.annualsalesmin2,
            '78%': 200*53.5*this.annualsalesmin2,
            '80%': 200*55*this.annualsalesmin2,
            '82%': 200*56.5*this.annualsalesmin2,
            '84%': 200*58*this.annualsalesmin2,
            '86%': 200*59.5*this.annualsalesmin2,
            '88%': 200*61*this.annualsalesmin2,
            '90%': 200*62.5*this.annualsalesmin2,
            '92%': 200*64*this.annualsalesmin2,
            '94%': 200*65.5*this.annualsalesmin2,
            '96%': 200*67*this.annualsalesmin2,
            '98%': 200*68.5*this.annualsalesmin2,
            'max': this.maxInvestment
          },
        behaviour: 'drag',
      };

    options: Array<Object> = [
        {
            label: 'Recently Listed',
            value: 'created_at'
        }, {
            label: 'Investment (Highest first)',
            value: 'desc'
        }, {
            label: 'Investment (Lowest first)',
            value: 'asc'
        }];
        
    obj = { Title: 'test', Url: 'test12' };
    @ViewChild(ListingPaginationComponent)
    private listingPagination: ListingPaginationComponent;



    constructor(
        private homeService: HomeService,
        private route: ActivatedRoute,
        private meta: Meta, private title: Title,
        private linkService: LinkService,
        private historyLocation: Location,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document, private elementRef: ElementRef
    ) {
        this.filterinvestorType['Individual Investor'] = false;
        this.minInvestmentValue = appConfig.PriceSlideFormate(this.minInvestment);
        this.maxInvestmentValue = appConfig.PriceSlideFormate(this.maxInvestment);
    }



 

    ngOnInit(): void {

        this.route.paramMap.subscribe((params: Params) => {

            this.reInitializeVariables();

            const param1 = +this.route.snapshot.queryParams['page'];

            if (param1 != undefined && param1 > 0) {
                this.currentPage = param1
            }

            this.loading = true;

            this.homeService.getfiltersInvestor().subscribe(result => {

                this.priceRange = result.minMaxInvestment;
                this.filterLocation12 = result.Location;
                this.industrySeller = result.industryPrefInvestor;
                this.parentChildCategoryId = result.parentChildCategoryId;
                this.investorOccupation = result.investorOccupation;

                if (params.get('businesstitle')) {
                    if (params.get('selecat') !== 'c0') {
                        this.catind = params.get('selecat').split('-').map(function (item) {
                            return parseInt(item, 10);
                        });
                    }
                    if (params.get('selesubcat') !== 's0') {
                        this.subcatind = params.get('selesubcat').split('-').map(function (item) {
                            return parseInt(item, 10);
                        });
                    }
                    if (params.get('state') !== 'st0') {
                        this.state = params.get('state').split('-');
                    }
                    let paramsCity = [];
                    if (params.get('city') !== null && params.get('city') !== '' && params.get('city') !== 'ct0') {
                        paramsCity = params.get('city').split('-').map(function (item) {
                            return parseInt(item, 10);
                        });
                    }

                    const newState = [];
                    if (this.state) {
                        Object.keys(STATE).forEach(function (key) {
                            newState[STATE[key]] = key;
                        });
                        for (let i = 0; i < this.state.length; ++i) {
                            this.filterLocationstate[newState[this.state[i]]] = true;
                            for (let ml = 0; ml < this.filterLocation12.length; ++ml) {
                                const locationFilter = this.filterLocation12[ml];
                                if (locationFilter.inv_state === newState[this.state[i]] &&
                                    (paramsCity.length === 0 || paramsCity.indexOf(locationFilter.cityId) > -1)) {
                                    this.pushCityState(locationFilter);
                                    this.checkCurrectCity = true;
                                }else if(locationFilter.inv_state === newState[this.state[i]]){
                                    this.testDataValue.push(locationFilter)
                                }
                            }
                        }


                        if(paramsCity.length==1 && this.state.length==1 && this.checkCurrectCity ==false && this.testDataValue.length>0){
                            this.router.navigate(['/investorlisting/investment-opportunities-in-'+this.testDataValue[0].stateSlug+'/c0/s0/'+this.state[0]]);
                        }

                    }

                    if (this.catind.length === 1 && params.get('selesubcat') === 's0') {
                        this.subcatind = this.parentChildCategoryId[this.catind[0]].split('-').map(function (item) {
                            return parseInt(item, 10);
                        });
                    }
                    for (let j = 0; j < this.subcatind.length; ++j) {
                        this.filterIndustry[this.subcatind[j]] = true;
                        this.getindustrysub(this.subcatind[j]);
                    }
                    for (let k = 0; k < this.catind.length; ++k) {
                        this.filterIndustrymain[this.catind[k]] = true;
                        this.getindustrymain(this.catind[k]);
                    }


                    if(this.catind.length==1 && this.subcatind.length==1 && this.getindustrysubCat != this.getindustrymainCat){
                        this.router.navigate(['/investorlisting/'+this.getindustrysubSlug+'-investment-opportunities/'+this.catind+'/s0/st0']);
                    }

                    this.callInvestorService();
                }
                if (params.get('businesstitle') === null) {
                    this.callInvestorService();
                }
            })
        })


        var html5Slider = document.getElementById('html5');

        this.sliderNo = noui.create(html5Slider, {
            start: [0, this.maxInvestment],
            step: 0.5,
            connect: true,
            tooltips: [false, false],
            range: {
              'min': 0,
              '2%': 200*1.5*this.annualsalesmin2,
              '4%':200*3*this.annualsalesmin2,
              '8%':200*4.5*this.annualsalesmin2,
              '10%':200*6*this.annualsalesmin2,
              '12%':200*7.5*this.annualsalesmin2,
              '14%':200*9*this.annualsalesmin2,
              '16%': 200*10.5*this.annualsalesmin2,
              '18%': 200*12*this.annualsalesmin2,
              '20%': 200*13.5*this.annualsalesmin2,
              '22%': 200*15*this.annualsalesmin2,
              '24%': 200*16.5*this.annualsalesmin2,
              '26%': 200*18*this.annualsalesmin2,
              '28%': 200*19.5*this.annualsalesmin2,
              '32%': 200*21*this.annualsalesmin2,
              '34%': 200*22.5*this.annualsalesmin2,
              '36%': 200*24*this.annualsalesmin2,
              '38%': 200*25*this.annualsalesmin2,
              '40%': 200*26.5*this.annualsalesmin2,
              '42%': 200*28*this.annualsalesmin2,
              '46%': 200*29.5*this.annualsalesmin2,
              '48%': 200*31*this.annualsalesmin2,
              '50%': 200*32.5*this.annualsalesmin2,
              '52%': 200*34*this.annualsalesmin2,
              '54%': 200*35.5*this.annualsalesmin2,
              '56%': 200*37*this.annualsalesmin2,
              '58%': 200*38.5*this.annualsalesmin2,
              '60%': 200*40*this.annualsalesmin2,
              '62%': 200*41.5*this.annualsalesmin2,
              '64%': 200*43*this.annualsalesmin2,
              '66%': 200*44.5*this.annualsalesmin2,
              '68%': 200*46*this.annualsalesmin2,
              '70%': 200*47.5*this.annualsalesmin2,
              '72%': 200*49*this.annualsalesmin2,
              '74%': 200*50.5*this.annualsalesmin2,
              '76%': 200*52*this.annualsalesmin2,
              '78%': 200*53.5*this.annualsalesmin2,
              '80%': 200*55*this.annualsalesmin2,
              '82%': 200*56.5*this.annualsalesmin2,
              '84%': 200*58*this.annualsalesmin2,
              '86%': 200*59.5*this.annualsalesmin2,
              '88%': 200*61*this.annualsalesmin2,
              '90%': 200*62.5*this.annualsalesmin2,
              '92%': 200*64*this.annualsalesmin2,
              '94%': 200*65.5*this.annualsalesmin2,
              '96%': 200*67*this.annualsalesmin2,
              '98%': 200*68.5*this.annualsalesmin2,
              'max': this.maxInvestment
            },
            behaviour: 'drag',
          });

          this.sliderNo.on('change',this.btnPriceFilter.bind(this))
    }


    btnPriceFilter(){
        let pAraay = this.sliderNo.get();
        if(pAraay.length){
            this.priceFilter(pAraay)
        }
   }

    ngAfterViewInit(){

        if (isPlatformBrowser(this.platformId)) {

            setTimeout(function () {

                /*var html5Slider = document.getElementById('html5');
                noUiSlider.create(html5Slider, {
                  start: [100, 800],
                  connect: true,
                  range: {
                    'min': 100,
                    'max': 1000
                  }
                });*/
                
                $(".accordion_head").click(function () {
                    if ($(this).next(".accordion_body").is(':visible')) {
                        $(this).next(".accordion_body").slideUp(300);
                        $(this).children(".plusminus").addClass('add');
                        $(this).children(".plusminus").removeClass('minus');
                    } else {
                        $(this).next(".accordion_body").slideDown(300);
                        $(this).children(".plusminus").addClass('minus');
                        $(this).children(".plusminus").removeClass('add');
                    }
                });

                /*$(".accordion_headmain").click(function () {

                    if ($(this).next(".accordion_bodymain").is(':visible')) {
                        $(this).next(".accordion_bodymain").slideUp(300);
                        $(this).children(".rightdown").addClass('rightval');
                        $(this).children(".rightdown").removeClass('downval');
                    } else {
                        $(this).next(".accordion_bodymain").slideDown(300);
                        $(this).children(".rightdown").addClass('downval');
                        $(this).children(".rightdown").removeClass('rightval');
                    }
                });*/

                $("#showftr").click(function () {
                    $(".catleft").show(400);
                });

                $("#closeftr").click(function () {
                    $(".catleft").hide(400);
                });
        },50)

        }
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            // $('#more').css('display', 'block');
            this.isViewChecked = true;
        }
    }


    private reInitializeVariables() {
        this.filterIndustrymain = [];
        this.filterIndustry = [];
        this.catind = [];
        this.industry = [];
        this.subcatind = [];
        this.stateArray = [];
        this.filterLocationstate = [];
        this.filterLocation = [];
        this.cityArray = [];
        this.state = [];
        this.city = [];
    }


    private getSubCatIndexes() {
        const newCat = this.parentChildCategoryId[this.catind[0]].split('-').map(function (item) {
            return parseInt(item, 10);
        });
        return (newCat.length === this.subcatind.length) ? 's0' : this.subcatind.join('-');
    }

    private getIndustryOrSubIndustry() {
        if (this.catind.length === 1) {
            const industry = this.industrySeller.filter(item =>
                (item.industryid === this.catind[0] && item.subIndustryid === this.subcatind[0]))[0];
            if (this.subcatind.length === 1) {
                const newCat = this.parentChildCategoryId[this.catind[0]].split('-').map(function (item) {
                    return parseInt(item, 10);
                });
                return (newCat.length === this.subcatind.length) ? industry.industry : industry.subindustry;
            }
            return industry.industry;
        }
        return '';
    }

    private callInvestorService() {

        this.loading = true;
        this.homeService.getInvestorlisting(this.currentPage, this.state, this.cityArray, this.catind,
            this.subcatind, this.investorType, this.itemsPerPage, this.maxInvestment,
            this.minInvestment, this.sortby).subscribe(response => {
                
                this.investor = response.investorList;
                //console.log(this.investor);
                this.totalItems = response.investorCount;
                this.seo = response.seo;
                this.setTitleMeta();
                this.noResult = this.investor.length === 0;
                this.loading = false;
                this.currentPage = (this.investor.length === 0) ? 0 : this.currentPage;


            }, error => {
                this.loading = false;
                //console.log(error);
            }
            );
    }

    private setTitleMeta() {
        this.subheadline = this.seo.description;
        this.hititle = this.seo.title;
        this.title.setTitle(this.seo.title);

        this.meta.updateTag({
        property: 'og:title',
        content:this.seo.title + ' - BusinessEx'
        });


        this.meta.updateTag({
        name: 'twitter:title',
        content: this.seo.title + ' - BusinessEx'
        });

        this.meta.updateTag({property: 'og:url', content: BASE_URL + this.historyLocation.path()});
        this.meta.updateTag({name: 'twitter:url', content: BASE_URL + this.historyLocation.path()});

        this.meta.updateTag({
            name: 'Description',
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
                .replace(/(\r\n\t|\n|\r\t)/gm, '')
                .replace('&lsquo;', '').replace('&rsquo;', '')
                .substring(0, 400)
        });

        this.meta.updateTag({ property: 'og:description', 
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
            .replace(/(\r\n\t|\n|\r\t)/gm, '')
            .replace('&lsquo;', '').replace('&rsquo;', '')
            .substring(0, 400)
        });
        this.meta.updateTag({ name: 'twitter:description', 
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
                .replace(/(\r\n\t|\n|\r\t)/gm, '')
                .replace('&lsquo;', '').replace('&rsquo;', '')
                .substring(0, 400)
        });

        this.meta.updateTag({ name: 'Keywords', content: this.seo.keyword });
        this.linkService.removeCanonicalLink();
        //this.linkService.removeAmpHtmlCanonicalLink();
        const totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 0;
        let pNumber = 0;
        if (this.currentPage > 1 && this.currentPage <= totalPages) {
            pNumber = this.currentPage - 1;
            this.linkService.addTag({
                rel: 'prev',
                href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
            });
        }
        this.linkService.addTag({
            rel: 'canonical',
            href: BASE_URL + this.historyLocation.path()
        });

        // this.linkService.addTag({
        //     rel: 'amphtml',
        //     href: BASE_URL + '/amp' + this.historyLocation.path()
        // });

        if (this.currentPage >= 1 && this.currentPage < totalPages) {
            pNumber = this.currentPage + 1;
            this.linkService.addTag({
                rel: 'next',
                href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
            });
        }
    }


    includeCityNew(State, shortsate, City, CityId, industrymainloc) {
        this.currentPage = 1;
        this.pagination = false;
        if ($('input:checkbox[id^=' + shortsate + ']:checked').length === 1) {
            if (this.filterLocationstate[State]) {
                if ($.inArray(State, this.stateArray) > -1) {
                    this.stateArray.splice(this.stateArray.indexOf(State), 1);
                    this.state.splice(this.state.indexOf(shortsate), 1);
                    this.filterLocationstate[State] = false;
                }
                this.filterLocation12.filter(item => (item.state === shortsate)).forEach(newItem => {
                    if (this.cityArray.indexOf(newItem.inv_city) > -1) {
                        this.cityArray.splice(this.cityArray.indexOf(newItem.inv_city), 1);
                        this.city.splice(this.city.indexOf(newItem.CityId), 1);
                        this.filterLocation[newItem.inv_city] = false;
                    }
                });
                this.getInvestorlisting();
                return;
            }
        }
        if (State !== '') {
            if ($.inArray(State, this.stateArray) > -1 && City === '') {
                if (this.filterLocationstate[State]) {
                    $('input:checkbox[id^=' + shortsate + ']').removeAttr('checked');
                    if (this.industryloc.indexOf(State) > -1) {
                        this.industryloc.splice(this.industryloc.indexOf(State), 1);
                    }
                    if (this.stateArray.indexOf(State) > -1) {
                        this.stateArray.splice(this.stateArray.indexOf(State), 1);
                    }
                    if (this.state.indexOf(shortsate) > -1) {
                        this.state.splice(this.state.indexOf(shortsate), 1);
                    }
                    for (let index = 0; index < this.filterLocation12.length; ++index) {
                        const locationFilter1 = this.filterLocation12[index];
                        if (locationFilter1.inv_state === State) {
                            this.filterLocation[locationFilter1.inv_city] = false;
                            if (this.cityArray.indexOf(locationFilter1.inv_city) > -1) {
                                this.cityArray.splice(this.cityArray.indexOf(locationFilter1.inv_city), 1);
                            }
                            if (this.city.indexOf(parseInt(locationFilter1.cityId, 10)) > -1) {
                                this.city.splice(this.city.indexOf(parseInt(locationFilter1.cityId, 10)), 1);
                            }
                        }
                    }
                }

            } else {
                for (let ml = 0; ml < this.filterLocation12.length; ++ml) {
                    const locationFilter = this.filterLocation12[ml];
                    if (locationFilter.inv_state === industrymainloc) {
                        this.pushCityState(locationFilter);
                    } else if (locationFilter.inv_city === City) {
                        this.industryloc.push(locationFilter.inv_state);
                    }
                }
            }
            if ($.inArray(City, this.cityArray) > -1 && City !== '') {
                if (this.cityArray.indexOf(City) > -1) {
                    this.cityArray.splice(this.cityArray.indexOf(City), 1);
                    this.city.splice(this.city.indexOf(CityId), 1);
                }
            } else if (City !== '') {
                if ($.inArray(City, this.cityArray) === -1) {
                    this.cityArray.push(City);
                }
                if ($.inArray(CityId, this.city) === -1) {
                    this.city.push(CityId);
                }
                if ($.inArray(State, this.stateArray) === -1) {
                    this.stateArray.push(State);
                }
                this.filterLocationstate[State] = true;
            }
            this.stateArray.forEach(newstate => {
                this.state.push(STATE[newstate]);
            });
            this.state = appConfig.unique(this.state);
            this.getInvestorlisting();
        }
    }

    includeindustry(catind, subcatind, industry1, industrymain, subid) {
        this.currentPage = 1;
        this.pagination = false;

        const n = $('input:checkbox[id^=' + catind + ']:checked').length;

        if (n === 1) {
            if (this.filterIndustrymain[catind]) {
                if ($.inArray(catind, this.catind) > -1) {
                    this.catind.splice(this.catind.indexOf(catind), 1);
                    $('input:checkbox[id^=' + catind + ']').removeAttr('checked');
                    this.filterIndustrymain[catind] = false;
                }
                if ($.inArray(industrymain, this.industry) > -1) {
                    this.industry.splice(this.industry.indexOf(industrymain), 1);
                }
                if(subid == ''){ //if there is only single sub category
                    subid = this.subcatind[0];
                }
                if ($.inArray(parseInt(subid, 10), this.subcatind) > -1) {
                    this.subcatind.splice(this.subcatind.indexOf(parseInt(subid, 10)), 1);
                    this.filterIndustry[parseInt(subid, 10)] = false;
                }
                this.getInvestorlisting();
                return;
            }
        }

        if (industry1 !== '') {
            if ($.inArray(industry1, this.industry) > -1) {
                this.industry.splice(this.industry.indexOf(industry1), 1);
            } else {
                this.industry.push(industry1);
            }
        }

        if (catind !== '') {
            if ($.inArray(catind, this.catind) > -1 && subcatind === '') {
                if (this.filterIndustrymain[catind]) {
                    $('input:checkbox[id^=' + catind + ']').removeAttr('checked');
                    this.catind.splice(this.catind.indexOf(catind), 1);
                    for (let index = 0; index < this.industrySeller.length; ++index) {
                        const subIndustryid = this.industrySeller[index];
                        if (subIndustryid.industry === industry1) {
                            this.filterIndustry[subIndustryid.subIndustryid] = false;
                            if (this.subcatind.indexOf(subIndustryid.subIndustryid) > -1) {
                                this.subcatind.splice(this.subcatind.indexOf(subIndustryid.subIndustryid), 1);
                            }
                            if (this.industry.indexOf(subIndustryid.subindustry) > -1) {
                                this.industry.splice(this.industry.indexOf(subIndustryid.subindustry), 1);
                            }
                        }
                    }
                }
            } else {
                for (let index = 0; index < this.industrySeller.length; ++index) {
                    const subIndustryid = this.industrySeller[index];
                    if (subIndustryid.industry === industry1) {
                        this.catind.push(catind);
                        this.filterIndustry[subIndustryid.subIndustryid] = true;
                        this.industryid.push(subIndustryid.subIndustryid);
                        this.industry.push(subIndustryid.subindustry);
                        this.subcatind.push(subIndustryid.subIndustryid);
                    } else if (subIndustryid.industryid === catind) {
                        this.industry.push(subIndustryid.industry);
                    }
                }
            }
            if ($.inArray(subcatind, this.subcatind) > -1 && subcatind !== '') {
                this.subcatind.splice(this.subcatind.indexOf(subcatind), 1);
                this.filterIndustry[subcatind] = false;
                this.industry.splice(this.industry.indexOf(industry1), 1);
            } else if (subcatind !== '') {
                this.subcatind.push(subcatind);
                this.filterIndustry[subcatind] = true;
                this.catind.push(catind);
                this.filterIndustrymain[catind] = true;
            }
            this.subcatind = appConfig.unique(this.subcatind);
            this.industry = appConfig.unique(this.industry);
            this.catind = appConfig.unique(this.catind);
            this.getInvestorlisting();
        }
    }

    getIndustryName(industryId, subIndustryId = 0): any {
        if (this.industrySeller === undefined || this.industrySeller === '' || industryId === '') {
            return '';
        }
        let myIndustry = this.industrySeller.filter(item => (item.industryid === industryId));
        if (subIndustryId !== 0) {
            myIndustry = this.industrySeller.filter(item => (item.industryid === industryId && item.subIndustryid === subIndustryId));
        }
        return myIndustry[0];
    }

    getInvestorlisting() {
        function Stringrep(items) {
            return items.replace(/\s/g, '-').toLowerCase();
        }

        const city = (this.stateArray.length === 1) ?
            (this.city.length === 1) ? '-in-' + appConfig.getSlug(this.cityArray[0]) : '-in-'
                + this.stateArray.map(Stringrep).join('-') : '';

        if (this.catind.length === 0 && this.subcatind.length === 0 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting');
            this.industry = [];
        }

        if (this.catind.length === 0 && this.subcatind.length > 0 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting');
            this.industry = [];
        }

        if (this.catind.length === 1 && this.subcatind.length > 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-investment-opportunities/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/st0');
        }

        if (this.catind.length === 1 && this.subcatind.length === 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-investment-opportunities/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/st0');

        }

        if (this.catind.length === 1 && this.subcatind.length === 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-investment-opportunities' + city + '/'
                + this.catind.join('-') + '/' + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length === 0 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.industry[0]) + '-investment-opportunities' + city + '/' + this.catind.join('-')
                + '/' + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length === 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-investment-opportunities' + city + '/' + this.catind.join('-')
                + '/' + this.state.join('-'));
        }
        if (this.catind.length === 0 && this.subcatind.length === 0 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/investment-opportunities' + city + '/c0/s0/'
                + this.state.join('-'));
        }
        if (this.catind.length === 0 && this.subcatind.length === 0 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/investment-opportunities/c0/s0/' + this.state.join('-'));
        }
        if (this.catind.length === 1 && this.subcatind.length > 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-investment-opportunities/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/'
                + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length > 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-investment-opportunities' + city + '/' + this.catind.join('-')
                + '/' + this.getSubCatIndexes() + '/' + this.state.join('-'));
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/investment-opportunities/' + this.catind.join('-')
                + '/' + this.subcatind.join('-') + '/st0');
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/investment-opportunities' + city + '/' +
                this.catind.join('-') + '/' + this.subcatind.join('-') + '/' + this.state.join('-'));
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'investorlisting/investment-opportunities/' + this.catind.join('-')
                + '/' + this.subcatind.join('-') + '/' + this.state.join('-'));
        }
        if (this.stateArray.length >= 1) {
            const newCat = this.filterLocation12.filter(item => (item.state === this.state[0]));
            if ((this.stateArray.length === 1 && newCat.length !== this.city.length) || this.stateArray.length > 1) {
                history.pushState(this.obj, this.obj.Title, this.historyLocation.path() + '/' + this.city.join('-'));
            }
        }

        if (this.pagination) {
            history.pushState(this.obj, this.obj.Title, this.historyLocation.path() + '?page=' + this.currentPage);
        }
        this.callInvestorService();
    }

    investorChange(investorId, investorType) {

        if (investorType !== '') {

            if ($.inArray(investorId, this.filterInvestor) > -1) {
                this.filterInvestor.splice(this.filterInvestor.indexOf(investorId), 1);
            } else {
                this.filterInvestor.push(investorId);
            }

            if ($.inArray(investorType, this.filterInvestorArray) > -1) {
                this.filterInvestorArray.splice(this.filterInvestorArray.indexOf(investorType), 1);
            } else {
                this.filterInvestorArray.push(investorType);
            }

            this.investorType = appConfig.unique(this.filterInvestor);
            this.getInvestorlisting();

        }
    }

    goToPage(n: number): void {
        this.currentPage = n;
        this.getInvestorlisting();
    }

    onNext(): void {
        this.pagination = true;
        this.currentPage++;
        this.getInvestorlisting();
    }

    onPrev(): void {
        this.pagination = true;
        this.currentPage--;
        this.getInvestorlisting();
    }

    pushCityState(locationFilter: any) {
        this.filterLocation[locationFilter.inv_city] = true;
        if (this.industryloc.indexOf(locationFilter.inv_state) === -1) {
            this.industryloc.push(locationFilter.inv_state);
        }
        if (this.stateArray.indexOf(locationFilter.inv_state) === -1) {
            this.stateArray.push(locationFilter.inv_state);
        }
        if (this.cityArray.indexOf(locationFilter.inv_city) === -1) {
            this.cityArray.push(locationFilter.inv_city);
        }
        if (this.city.indexOf(locationFilter.cityId) === -1) {
            this.city.push(locationFilter.cityId);
        }
    }

    getindustrysub(industry1) {
        for (let index = 0; index < this.industrySeller.length; ++index) {
            const subIndustryid = this.industrySeller[index];
            if (subIndustryid.subIndustryid === industry1) {
                if (this.industry.indexOf(subIndustryid.industry) === -1) {
                    this.industry.push(subIndustryid.industry);
                    this.getindustrysubCat = subIndustryid.industry;  
                }
            }
        }
    }

    getindustrymain(industry1) {
        for (let index = 0; index < this.industrySeller.length; ++index) {
            const subIndustryid = this.industrySeller[index];
            if (subIndustryid.industryid === industry1) {
                this.getindustrymainCat = subIndustryid.industry
                this.getindustrysubSlug = subIndustryid.industrySlug;
                if (this.industry.indexOf(subIndustryid.industry) === -1) {
                    this.industry.push(subIndustryid.industry);
                }
            }
        }

    }

    onChangeObj(sortby) {
        this.sortby = sortby;
        this.getInvestorlisting();
    }


    priceFilter(event) {
        this.minInvestmentValue = appConfig.PriceSlideFormate(event[0]);
        this.maxInvestmentValue = appConfig.PriceSlideFormate(event[1]);

        this.minInvestment = event[0];
        this.maxInvestment = event[1];
        this.getInvestorlisting();
    }

    

}
