<!-- <div class="pagination" *ngIf="count > 0">
	<div class="numbers">
		<button class="link" (click)="onPrev()" [disabled]="page === 1 || loading" [ngClass]="{ 'disabled': page === 1 || loading }">&lt;</button>
            <a 
                    *ngFor="let pageNum of getPages()"
					(click)="onPage(pageNum)"
					[ngClass]="{'active': pageNum === page, 'disabled': loading}"
                    class="clicked" href='{{getPathString()}}?page={{pageNum}}'
            >{{ pageNum }}</a>
            
			<button class="link disabled">...</button>
			<span class="page-totals">{{ totalPages() }}</span>
		<button class="link" (click)="onNext(true)" [disabled]="lastPage() || loading" [ngClass]="{ 'disabled': lastPage() || loading }">&gt;</button>
	</div>
</div> -->


<nav class="pagination-a" *ngIf="count > 0">
	
	<ul class="pagination justify-content-center">

		<li class="page-item" class="page-item" (click)="onPrev()" [disabled]="page === 1 || loading" [ngClass]="{ 'disabled': page === 1 || loading }">
			<a class="page-link" 
			[routerLink]=""
			 tabindex="-1">
			   <span class="ion-ios-arrow-back"></span>
			</a>
		 </li>

	   <li class="page-item" *ngFor="let pageNum of getPages()" [ngClass]="{'active': pageNum === page, 'disabled': loading}">
			<a 
				class="page-link" 
				(click)="onPage(pageNum)" 
				[routerLink]="getPathString()"
				[queryParams]="{page:pageNum}"

			 >
			 {{ pageNum }}</a>
		</li>

	   <li class="page-item disabled"><span class="page-link">..{{ totalPages() }}</span></li>
	   <li class="page-item next" (click)="onNext(true)" [disabled]="lastPage() || loading" [ngClass]="{ 'disabled': lastPage() || loading }">
			<a class="page-link" 
			[routerLink]="getPathString()"
			[queryParams]="{page:totalPages()}"
			>
			<span class="ion-ios-arrow-forward"></span>
			</a>
		</li>

	</ul>
 </nav>