<main id="main" class="minheigh">
  <div class="container bex-main">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12">
        <ul class="brunnar">
          <li><a href="#" onclick="return false;">Home</a></li>
          <li>/</li>
          <li>Contact Us</li>
        </ul>
      </div>
    </div>

    <div class="page-ttl">
      <h1>Contact Us</h1>
    </div>

    <div class="row backbg">
      <div class="col-12 col-sm-12 col-md-12">
        BusinessEx.com is a networking platform helps you find solutions for
        your business problems with proper connections. For more information get
        connected with us by filling up required information given below.
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <h2 class="stati2chead">Reach Us</h2>
        <div class="inncblk">
          <div class="fst">
            <div class="t1">Telephone</div>
            <div class="t2">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <a href="tel:+91 8586891020">+91 8586891020</a>
              (Monday - Friday 10am to 6pm, IST)
            </div>
          </div>
          <div class="fst">
            <div class="t1">Email</div>
            <div class="t2">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <a href="mailto:support@businessex.com">
                &nbsp; support@businessex.com</a
              >
            </div>
          </div>
          <!-- <div class="fst">
            <div class="t1">Fax</div>
            <div class="t2">011- 40665563</div>
          </div> -->
          <div class="fst">
            <div class="t1">Postal Mail</div>
            <div class="t2">
              <i class="fa fa-map-marker" aria-hidden="true"></i> Franchise
              India Holdings Limited, 4th-5th Floor, Charmwood Plaza, Surajkund
              Road, Faridabad - 121009, Haryana
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 contbg">
        <h2 class="stati2chead marsetb">Send Us Your Questions and Feedback</h2>
        <!-- <form name="contactUsForm" #contactUsForm="ngForm"
                (ngSubmit)="contactUsForm.form.valid && contactUsFormSubmit(contactUsForm.value)"
                class="form-horizontal" role="form">


                    <div class="row marsettop">
                        <div class="col-xs-12">
                            <div class="msg-pnl_sales custom_error" *ngIf="ContactError"
                            style=" text-align: center; clear: both; margin: 0px 15px 20px;">
                                <div *ngFor="let message of ContactError | keyspipe : true">
                                {{message}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marsettop">
                        <label class="col-12 col-sm-6 col-md-4 frmtxt mandatory">Your Name</label>
                        <div class="d-none d-md-block col-md-1">:</div>
                        <div class="col-12 col-sm-6 col-md-7">
                            <div class="input-group">
                                <input type="text" name="contact_name" #contact_name="ngModel"
                                       ngModel class="form-control" placeholder="Enter Your Name"
                                       data-error="Please fill out this field." required>
                                <span class="glyphicon form-control-feedback"
                                      aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>
                            </div>
                    </div>

                    <div class="row marsettop">
                        <label class="col-12 col-sm-6 col-md-4 frmtxt mandatory">Email</label>
                        <div class="d-none d-md-block col-md-1">:</div>
                        <div class="col-12 col-sm-6 col-md-7">
                            <div class="input-group">
                                                    <input type="email"
                                                           pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                                           name="contact_email" #contact_email="ngModel" ngModel
                                                           class="form-control" placeholder="Enter Your Email ID"
                                                           data-error="Please fill out this field." required>
                                                    <span class="glyphicon form-control-feedback"
                                                          aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                            
                        </div>
                    </div>

                    <div class="row marsettop">
                        <label class="col-12 col-sm-6 col-md-4 frmtxt mandatory">Mobile</label>
                        <div class="d-none d-md-block col-md-1">:</div>
                        <div class="col-12 col-sm-6 col-md-7">
                            <div class="input-group">
                                <input type="text" name="contact_mobile" #contact_mobile="ngModel"
                                       ngModel pattern="[56789][0-9]{9}" class="form-control"
                                       placeholder="Enter Your Mobile Number"
                                       data-error="Please enter valid mobile number" required
                                       onkeypress="return isNumber(event)">
                                <span class="glyphicon form-control-feedback"
                                      aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="row marsettop">
                        <label class="col-12 col-sm-6 col-md-4 frmtxt mandatory">Comment</label>
                        <div class="d-none d-md-block col-md-1">:</div>
                        <div class="col-12 col-sm-6 col-md-7"> 

                            <div class="input-group">
                                <textarea name="contact_comment" #contact_comment="ngModel" ngModel
                                          class="form-control" rows="3" minlength="15"
                                          maxlength="150" placeholder="Enter Your Name"
                                          data-error="Please fill out this field."
                                          required></textarea>
                                <span class="glyphicon form-control-feedback"
                                      aria-hidden="true"></span>
                            </div>
                            <div class="help-block with-errors"></div>

                        </div>
                    </div>

                    <div class="row marsettop">
                        <label class="col-12 col-sm-6 col-md-4 frmtxt"></label>
                        <div class="d-none d-md-block col-md-1"></div>
                        <div class="col-12 col-sm-6 col-md-7">
                            <input type='checkbox' name="subscribe" #subscribe="ngModel"
                            ngModel> Subscribe for latest news
                        </div>
                    </div>

                    <div class="row setborder">
                        <input type="submit" value="Submit" class="frmbtn" />
                        <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                    </div>

                    <div class="termstxt">By Clicking Submit you are Accepting <a routerLink="/terms">Terms & Conditions</a></div>
                </form> -->

        <form
          name="contactUsForm"
          #contactUsForm="ngForm"
          (ngSubmit)="
            contactUsForm.form.valid && contactUsFormSubmit(contactUsForm.value)
          "
          class="form-horizontal"
          role="form"
        >
          <div class="sec-slide-effect">
            <div class="row">
              <div
                class="msg-pnl_sales custom_error"
                *ngIf="ContactError"
                style="text-align: center; clear: both; margin: 0px 15px 20px"
              >
                <div *ngFor="let message of ContactError | keyspipe: true">
                  {{ message }}
                </div>
              </div>
            </div>
            <div class="form-group has-feedback row">
              <label class="col-xs-12 col-sm-6 col-md-4 control-label"
                >Your Name<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    name="contact_name"
                    #contact_name="ngModel"
                    ngModel
                    class="form-control"
                    placeholder="Enter Your Name"
                    data-error="Please fill out this field."
                    required
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="form-group has-feedback row">
              <div class="col-xs-12 col-sm-6 col-md-4 control-label">
                Email Address<span class="star">*</span> <span>:</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    name="contact_email"
                    #contact_email="ngModel"
                    ngModel
                    class="form-control"
                    placeholder="Enter Your Email ID"
                    data-error="Please fill out this field."
                    required
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="form-group has-feedback row">
              <label class="col-xs-12 col-sm-6 col-md-4 control-label"
                >Mobile Number<span class="star">*</span> <span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <input
                    type="text"
                    name="contact_mobile"
                    #contact_mobile="ngModel"
                    ngModel
                    pattern="[56789][0-9]{9}"
                    class="form-control"
                    placeholder="Enter Your Mobile Number"
                    data-error="Please enter valid mobile number"
                    required
                    onkeypress="return isNumber(event)"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="form-group has-feedback row">
              <label class="col-xs-12 col-sm-6 col-md-4 control-label"
                >Comments<span class="star">*</span><span>:</span></label
              >
              <div class="col-xs-12 col-sm-6 col-md-7">
                <div class="input-group">
                  <textarea
                    name="contact_comment"
                    #contact_comment="ngModel"
                    ngModel
                    class="form-control"
                    rows="3"
                    minlength="15"
                    maxlength="150"
                    placeholder="Enter Your Message"
                    data-error="Please fill out this field."
                    required
                  ></textarea>
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                </div>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="row marsettop">
              <label class="col-12 col-sm-6 col-md-4 frmtxt"></label>
              <div
                class="col-xs-12 col-sm-6 col-sm-offset-6 col-md-17 col-md-offset-4"
              >
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="subscribe"
                      #subscribe="ngModel"
                      ngModel
                    />
                    Subscribe for latest news
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row setborder">
            <button type="submit" class="frmbtn">SUBMIT</button>
          </div>

          <div class="termstxt">
            By Clicking Submit you are Accepting
            <a routerLink="/terms">Terms & Conditions</a>
          </div>
        </form>
      </div>
      <!-- Tab system -->
      <!-- <div class="tabcontact">
                <div class="col-12 col-sm-12 col-md-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#SalesLeads">Sales Leads</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#RegionalSales">Regional Sales</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#PartnerBranch">Partner Branch</a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content">
                    <div class="tab-pane container active" id="SalesLeads">
                        <ul class="contblk incon">
                            <li>
                                <h3>Bhavya Preet Kaur</h3>
                                <div class="ftc">
                                    <span class="f1">Phone:</span>
                                    <span class="f2"> 8929600710</span>
                                </div>
                            </li>

                            <li>
                                <h3>Rajeev Kaushik</h3>
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> 9910922749</span></div>
                            </li>


                            <li>
                                <h3>Amrish Kalra</h3>
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> 9876633669</span></div>
                            </li>

                            <li>
                                <h3>Saurabh Varshney</h3>
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> 8810512407</span></div>
                            </li>
                        </ul> 
                    </div>

                    <div class="tab-pane container" id="RegionalSales">
                         <ul class="contblk"> 


 <li>
                                <h3>Delhi NCR 1st Office</h3>
                                <div class="hname">Ms. Bhavya Preet Kaur</div>
                               Franchise India Holdings Limited, 4th-5th Floor, Charmwood Plaza, Surajkund Road,
                                    Faridabad, Haryana - 121009
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> +91 129 4228858</span>
                                </div>
                                <div class="ftc"><span class="f1">Mobile:</span><span class="f2">+91 8929600710</span>
                                </div>
                                <div class="ftc"><span class="f1">Email:</span><span class="f2">
                                    <a href="mailto:bhavya.kaur@businessex.com">bhavya.kaur@businessex.com</a><br>
                                            <a href="mailto:sales@businessex.com">sales@businessex.com</a></span></div>
                            </li>







                            <li>
                                <h3>Delhi NCR 2nd Office</h3>
                                <div class="hname">Mr. Rajeev Kaushik</div>
                                Franchise India Brands Limited, F-89/11,Okhla Phase 1, New Delhi -
                                    110020,
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> +91 11 40665555</span>
                                </div>
                                <div class="ftc"><span class="f1">Mobile:</span><span class="f2">+91 9910922749</span>
                                </div>
                                <div class="ftc"><span class="f1">Email:</span><span class="f2"><a
                                            href="mailto:feedback@franchiseindia.in">feedback@franchiseindia.in</a><br>
                                            <a href="mailto:sales@businessex.com">sales@businessex.com</a></span></div>
                            </li>

                            <li>
                                <h3>Mumbai Office</h3>
                                <div class="hname">Mr. Maninder Pal Singh</div>
                            Unit No.16-B, Ground Floor, Technopolis Knowledge Park, Mahakali Caves Road,
                                    Andheri (East) Mumbai-400 093
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2">+91 22-40908555</span>
                                </div>
                                <div class="ftc"><span class="f1">Mobile:</span><span class="f2">+91 9022064443</span>
                                </div>
                                <div class="ftc"><span class="f1">Email:</span><span class="f2"><a href="mailto:maninderpal.singh@franchiseindia.in">maninderpal.singh@franchiseindia.in</a><br>
                                <a href="mailto:sales@businessex.com">sales@businessex.com</a></span>
                                </div>
                            </li>

                            <li>
                                <h3>Pune Office</h3>
                                <div class="hname">Mr. Rupam Ganguly</div>
                                Office No:- 407, 4th floor, Tower 2, Franchise India pvt Ltd, World Trade Centre,
                                    Kharodi, Pune - 411014
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> +91 20 - 40177400</span>
                                </div>
                                <div class="ftc"><span class="f1">Mobile:</span><span class="f2">+91 9021177217</span>
                                </div>
                                <div class="ftc"><span class="f1">Email:</span><span class="f2"><a
                                            href="mailto:rupam.ganguly@franchiseindia.in">rupam.ganguly@franchiseindia.in</a><br>
                                            <a href="mailto:sales@businessex.com">sales@businessex.com</a></span></div>
                            </li>


                            <li>
                                <h3>Bangalore Office</h3>
                                <div class="hname">Ms. Jabeena H A</div>
                                No-2, Second Floor, Church Street,
                                    Below Blossoms Book House, Opposite to Starbucks, Bangalore-560001
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> +91 80-49035522</span>
                                </div>
                                <div class="ftc"><span class="f1">Mobile:</span><span class="f2">+91 9342018427</span>
                                </div>
                                <div class="ftc"><span class="f1">Email:</span><span class="f2"><a
                                            href="mailto:blradmin@franchiseindia.in">blradmin@franchiseindia.in</a><br>
                                            <a href="mailto:sales@businessex.com">sales@businessex.com</a></span></div>
                            </li>


                            <li>
                                <h3>Chennai Office</h3>
                                <div class="hname">Mr. Ravishankar Swaminathan</div>
                                Unit 704, 7th Floor, Capital Towers, 180, Kodambakkam High Road, Nungambakkam, Chennai - 600 034
                                <div class="ftc"><span class="f1">Phone:</span><span class="f2"> +91 44 - 42053731</span>
                                </div>
                                <div class="ftc"><span class="f1">Mobile:</span><span class="f2">+91 9025199460</span>
                                </div>
                                <div class="ftc"><span class="f1">Email:</span><span class="f2"><a
                                            href="mailto:ravi.swaminathan@franchiseindia.in">ravi.swaminathan@franchiseindia.in</a><br>
                                            <a href="mailto:sales@businessex.com">sales@businessex.com</a></span></div>
                            </li>

                        </ul>

                    </div>


                    <div class="tab-pane container" id="PartnerBranch">
                        <ul class="contblk">
                        <li>
                            <h3>Franchise India Nepal Office</h3>
                            <div class="hname">Mr. Diwesh Bothra </div>
                            284,Nagpokhri Sadak, Near Nagpokhri Next to Ginger, Kathmandu-44600, Nepal.
                           
                        
                            <div class="ftc">
                                <span class="f1">Email:</span>
                                <span class="f2">
                                    <a  href="mailto:feedback@franchiseindia.in">feedback@franchiseindi>a.in</a>
                                </span>
                            </div>
                        </li>
                        
                        <li>
                            <h3>Lucknow Office</h3>
                            <div class="hname">Mr. Anurag Pandey</div>
                          715, Parasvanath Planet Plaza,
                                    Vibhuti Khand, Gomti Nagar, Lucknow - 226010.
                          
                            <div class="ftc"><span class="f1">Email:</span><span class="f2"><a
                                        href="mailto:feedback@franchiseindia.in">feedback@franchiseindia.in</a><br>
                                      </span></div>
                        </li>
                        
                        
                        
                        </ul> 
                        
                        </div>

                </div>
            </div> -->
      <!-- Tab System end  -->
    </div>
  </div>
</main>
