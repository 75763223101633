import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';
declare var $: any;

@Component({
    selector: 'incubator-attachment',
    templateUrl: './incubator-attachment.component.html'
})
export class IncubatorAttachmentComponent implements OnInit, AfterViewChecked {

    profile_str;
    public IncubatorAttachmentdata;
    public incubatorProfileSuccessError;
    public incubatorProfileFailError;
    files;
    logos;

    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private myprofileService: MyProfileService,
                private http: HttpClient,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {

        title.setTitle('Incubator Attachment Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    incubatorMyAccountSubmit(data) {
        if ($('form[name="incubatorForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.incubator_profile_str = this.profile_str;
        const formData: any = new FormData();
        if (this.files != undefined) {
            formData.append('incubator_company_logo', this.files[0], this.files[0].name);
            //console.log(this.files[0]);
        }
        if (this.logos != undefined) {
            formData.append('signature', this.logos[0], this.logos[0].name);
            //console.log(this.logos[0]);
        }
        if (data.incubator_company_logo_old != '') {
            formData.append('incubator_company_logo_old', data.incubator_company_logo_old);
            //console.log(data.incubator_company_logo_old);
        }
        if (data.signature_old != '') {
            formData.append('signature_old', data.signature_old);
            //console.log(data.signature_old);
        }
        formData.append('incubator_profile_str', this.profile_str);
        this.http.post(this.config.apiEndpoint + '/updateincubatorattachment/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Attachment Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.incubatorProfileSuccessError = data;
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                this.incubatorProfileFailError = "Please Enter Correct Details";
            });
    }

    getincubatorAttachment(profile_str: any): void {
        this.myprofileService.getincubatorAttachment(this.profile_str)
            .then((result) => {
                this.IncubatorAttachmentdata = result;
            });
    }

    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.files = fileInput.target.files;
        }
    }

    logoChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.logos = fileInput.target.files;
        }
    }

    ngOnInit() {
        this.getincubatorAttachment(this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="incubatorForm"]').validator();
        }
    }

}
