import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ContactService} from '../../../../services/contact.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG, PROFESSIONAL_EXPERIENCE} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';
import {appConfig} from '../../../frontend/app.config';
import {MapsAPILoader} from "@agm/core";

declare var $: any;

@Component({
    selector: 'incubator-preference',
    templateUrl: './incubator-preference.component.html'
})

export class IncubatorPreferenceComponent implements OnInit, AfterViewChecked {

    profile_str;
    public incubatorPreferencedata;
    public industryCategoryData;
    public incubatorProfileSuccessError;
    public incubatorProfileFailError;
    private isViewChecked = false;
    public mentorCategoryData;
    tags = [];
    professional_exp_year = PROFESSIONAL_EXPERIENCE;

    constructor(private meta: Meta,
                private title: Title,
                private contactService: ContactService,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private mapsAPILoader: MapsAPILoader) {
        title.setTitle('Incubator Preference Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    getIndustrySector(): void {
        this.contactService.getIndustrySector()
            .then((result) => {
                this.industryCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.industryCategoryData);
                    setTimeout(function () {
                        $('#incubator_sector_preference').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }

    incubatorMyAccountSubmit(data) {
        const formSelector = $('form[name="incubatorForm"]');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        if ($('#incubator_subject_expertise').val().length === 0) {
            $('#incubator_subject_expertise').parent().css('border', '1px solid red');
            return;
        }
        $('#incubator_subject_expertise').parent().css('border', 'none');
        if ($('#incubator_sector_preference').val().length === 0) {
            $('#incubator_sector_preference').parent().css('border', '1px solid red');
            return;
        }
        $('#incubator_sector_preference').parent().css('border', 'none');

        data.incubator_subject_expertise = appConfig.getFormattedValues('#incubator_subject_expertise', this.mentorCategoryData);
        data.incubator_sector_preference = appConfig.getFormattedValues('#incubator_sector_preference', this.industryCategoryData);
        data.location_preference = $("#incubatorLocationPreference").val();
        data.incubator_profile_str = this.profile_str;
        data.professional_experience = appConfig.prepareTeamJson('#parent0 select', 2);
        this.http.post(this.config.apiEndpoint + '/updateincubatorPreference/' + this.profile_str, data).subscribe(
            data => {
                this.incubatorProfileSuccessError = data;
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
            },
            error => {
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
                this.incubatorProfileFailError = "Please Enter Correct Details";
            });
    }

    getincubatorPreference(): void {
        this.myprofileService.getincubatorPreference(this.profile_str)
            .then((result) => {
                this.incubatorPreferencedata = result;
                $('#incubator_subject_expertise, #incubator_sector_preference').tagit({allowSpaces: true});
                if (isPlatformBrowser(this.platformId)) {
                    $(".LocationPrefrencetagit").tagit(appConfig.mapTagItConfiguration(this.mapsAPILoader));
                    this.incubatorPreferencedata.location_pref.forEach(item => {
                        $('#incubatorLocationPreference').tagit("createTag", item.location_name);
                    });
                    this.incubatorPreferencedata.incubator_subject_expertise.forEach(item => {
                        $('#incubator_subject_expertise').tagit("createTag", item.name);
                    });
                    this.incubatorPreferencedata.incubator_sector_preference.forEach(item => {
                        $('#incubator_sector_preference').tagit("createTag", item.name);
                    });
                }
            });
    }

    ngOnInit() {
        this.getincubatorPreference();
        this.getIndustrySector();
        this.getMentorSector();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="incubatorForm"]').validator();
        }
    }

    getMentorSector(): void {
        this.contactService.getMentorSector()
            .then((result) => {
                this.mentorCategoryData = result;
                if (isPlatformBrowser(this.platformId)) {
                    var aTags = appConfig.getAvailabileTags(this.mentorCategoryData);
                    setTimeout(function () {
                        $('#incubator_subject_expertise').tagit(appConfig.tagItConfiguration(aTags));
                    }, 1000)
                }
            });
    }
}
