import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard-component';
import {DemoComponent} from './components/demo.component';
import {MyAccountComponent} from './components/my-account/my-account.component';
import {BrokerConfidentialComponent} from './components/broker-confidential/broker-confidential.component';
import {BrokerBusinessListingComponent} from './components/broker-listing/broker-listing.component';
import {BrokerLocationComponent} from './components/broker-location/broker-location.component';
import {BrokerBusinessInformationComponent} from './components/broker-business-information/broker-business-information.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {ProfileViewChartComponent} from './components/profile-view-chart/profile-view-chart.component';
import {MyBusinessComponent} from './components/business/business.component';
import {BusinessAttachmentComponent} from './components/business-attachment/business-attachment.component';
import {BusinessConfidentialComponent} from './components/business-confidential/business-confidential.component';
import {BusinessFinancialComponent} from './components/business-finanical/business-financial.component';
import {BusinessInformationComponent} from './components/business-information/business-information.component';
import {BusinessLocationDetailsComponent} from './components/business-location-details/business-location-details.component';
import {BxproposalComponent} from './components/business-proposal/business-proposal.component';
import {BusinessRequirementComponent} from './components/business-requirement/business-requirement.component';
import {BusinessTeamDetailsComponent} from './components/business-team-details/business-team-details.component';
import {BxMailComponent} from './components/bx-mail/bx-mail.component';
import {ContactHistoryComponent} from './components/contact-history/contact-history.component';
import {IncubatorAdvertisementComponent} from './components/incubator-advertisement/incubator-advertisement.component';
import {IncubatorAttachmentComponent} from './components/incubator-attachment/incubator-attachment.component';
import {IncubatorConfidentialsComponent} from './components/incubator-confidentials/incubator-confidentials.component';
import {IncubatorDetailsComponent} from './components/incubator-details/incubator-details.component';
import {IncubatorPreferenceComponent} from './components/incubator-preference/incubator-preference.component';
import {InstaResponseComponent} from './components/instaresponse/instaresponse.component';
import {InvestorAdvertisementComponent} from './components/investor-advertisement/investor-advertisement.component';
import {InvestorConfidentialComponent} from './components/investor-confidential/investor-confidential.component';
import {InvestorFirmTypeComponent} from './components/investor-firmtype/investor-firmtype.component';
import {InvestorIndividualTypeComponent} from './components/investor-individual-type/investor-individual-type.component';
import {MyInvestorProfileComponent} from './components/investor-profile/investor-profile.component';
import {LenderAdvertisementComponent} from './components/lender-advertisement/lender-advertisement.component';
import {LenderConfidentialsComponent} from './components/lender-confidentials/lender-confidentials.component';
import {LenderPreferenceComponent} from './components/lender-prefrence/lender-preference.component';
import {MyLenderProfileComponent} from './components/lender-profile/lender-profile.component';
import {MentorAdvDetailsComponent} from './components/mentor-advdetails/mentor-advdetails.component';
import {MentorConfidentialComponent} from './components/mentor-confidential/mentor-confidential.component';
import {MentorDetailsComponent} from './components/mentor-details/mentor-details.component';
import {MentorPreferenceComponent} from './components/mentor-preference/mentor-preference.component';
import {MyMentorProfileComponent} from './components/mentor-profile/mentor-profile.component';
import {NbfcLenderComponent} from './components/nbfc-lender/nbfc-lender.component';
import {PrivateLenderComponent} from './components/private-lender/private-lender.component';
import {StartupAttachmentComponent} from './components/startup-attachment/startup-attachment.component';
import {StartupCompanyDetailsComponent} from './components/startup-company-details/startup-company-details.component';
import {StartupConfidentialComponent} from './components/startup-confidential/startup-confidential.component';
import {StartupFinancialComponent} from './components/startup-financial/startup-financial.component';
import {StartupLocationDetailsComponent} from './components/startup-location-details/startup-location-details.component';
import {StartupOtherComponent} from './components/startup-others/startup-others.component';
import {MyStartupProfileComponent} from './components/startup-profile/startup-profile.component';
import {StartupRequirementComponent} from './components/startup-requirement/startup-requirement.component';
import {StartupTeamDetailsComponent} from './components/startup-team-details/startup-team-details.component';
import {UpgradeInvestorComponent} from './components/upgrade-investor/upgrade-investor.component';
import {UpgradeBusinessComponent} from './components/upgrade-seller/upgrade-seller.component';
import {BrokerOtherInfoComponent} from './components/broker-other-info/broker-other-info.component';
import {InvestorMultiPrefComponent} from './components/investor-multi-pref/investor-multi-pref.component';
import {StartupAdvDetailsComponent} from './components/startup-adv-details/startup-adv-details.component';
import {BusinessAdvDetailsComponent} from './components/business-adv-details/business-adv-details.component';
import {ChatComponent} from './components/chat/chat.component';
import {ActivateAccountComponent} from './components/activate-account/activate-account.component';
import {UpgradeStartupComponent} from './components/upgrade-startup/upgrade-startup.component'

const dashboardRoutes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: 'myaccount',
                component: MyAccountComponent
            },
            {
                path: 'changePassword',
                component: ChangePasswordComponent
            },
            {
                path: 'profileview',
                component: ProfileViewChartComponent
            },
            {
                path: 'demo',
                component: DemoComponent
            },
            {
                path: 'brokerBusiness/:sellerUniqueId',
                component: BrokerBusinessInformationComponent
            },
            {
                path: 'brokerConfidential/:sellerUniqueId',
                component: BrokerConfidentialComponent
            },
            {
                path: 'brokerbusinesslisting',
                component: BrokerBusinessListingComponent
            },
            {
                path: 'brokerLocation/:sellerUniqueId',
                component: BrokerLocationComponent
            },
            {
                path: 'brokerOther/:sellerUniqueId',
                component: BrokerOtherInfoComponent
            },
            {
                path: 'mybusiness/:sellerUniqueId',
                component: MyBusinessComponent
            },
            {
                path: 'businessAdvDetails/:sellerUniqueId',
                component: BusinessAdvDetailsComponent
            },
            {
                path: 'businessAttachment/:sellerUniqueId',
                component: BusinessAttachmentComponent
            },
            {
                path: 'businessConfidential/:sellerUniqueId',
                component: BusinessConfidentialComponent
            },
            {
                path: 'businessFinanceDetails/:sellerUniqueId',
                component: BusinessFinancialComponent
            },
            {
                path: 'businessInformation/:sellerUniqueId',
                component: BusinessInformationComponent
            },
            {
                path: 'businessLocationDetails/:sellerUniqueId',
                component: BusinessLocationDetailsComponent
            },
            {
                path: 'bxproposal',
                component: BxproposalComponent
            },
            {
                path: 'businessRequirement/:sellerUniqueId',
                component: BusinessRequirementComponent
            },
            {
                path: 'businessTeamDetails/:sellerUniqueId',
                component: BusinessTeamDetailsComponent
            },
            {
                path: 'myinteraction',
                component: BxMailComponent
            },
            {
                path: 'contactHistory',
                component: ContactHistoryComponent
            },
            {
                path: 'incubatorAdvertisement/:sellerUniqueId',
                component: IncubatorAdvertisementComponent
            },
            {
                path: 'incubatorAttachment/:sellerUniqueId',
                component: IncubatorAttachmentComponent
            },
            {
                path: 'incubatorConfidentials/:sellerUniqueId',
                component: IncubatorConfidentialsComponent
            },
            {
                path: 'incubatorDetails/:sellerUniqueId',
                component: IncubatorDetailsComponent
            },
            {
                path: 'incubatorPreference/:sellerUniqueId',
                component: IncubatorPreferenceComponent
            },
            {
                path: 'instaResponse',
                component: InstaResponseComponent
            },
            {
                path: 'investorAdvertisement/:sellerUniqueId',
                component: InvestorAdvertisementComponent
            },
            {
                path: 'investorConfidentials/:sellerUniqueId',
                component: InvestorConfidentialComponent
            },
            {
                path: 'investorFirmType/:sellerUniqueId',
                component: InvestorFirmTypeComponent
            },
            {
                path: 'investorIndividualType/:sellerUniqueId',
                component: InvestorIndividualTypeComponent

            },
            {
                path: 'investorMultiPref/:sellerUniqueId',
                component: InvestorMultiPrefComponent
            },
            {
                path: 'myinvestor/:sellerUniqueId',
                component: MyInvestorProfileComponent
            },
            {
                path: 'lenderAdvertisement/:sellerUniqueId',
                component: LenderAdvertisementComponent
            },
            {
                path: 'lenderConfidentials/:sellerUniqueId',
                component: LenderConfidentialsComponent
            },
            {
                path: 'lenderPreference/:sellerUniqueId',
                component: LenderPreferenceComponent
            },
            {
                path: 'mylender/:sellerUniqueId',
                component: MyLenderProfileComponent
            },
            {
                path: 'mentorAdvDetails/:sellerUniqueId',
                component: MentorAdvDetailsComponent
            },
            {
                path: 'mentorConfidentials/:sellerUniqueId',
                component: MentorConfidentialComponent
            },
            {
                path: 'mentorDetails/:sellerUniqueId',
                component: MentorDetailsComponent
            },
            {
                path: 'mentorPreference/:sellerUniqueId',
                component: MentorPreferenceComponent

            },
            {
                path: 'mymentor/:sellerUniqueId',
                component: MyMentorProfileComponent
            },
            {
                path: 'nbfcLender/:sellerUniqueId',
                component: NbfcLenderComponent
            },
            {
                path: 'privateLender/:sellerUniqueId',
                component: PrivateLenderComponent
            },
            {
                path: 'startupAdvertisement/:sellerUniqueId',
                component: StartupAdvDetailsComponent
            },
            {
                path: 'startupAttachment/:sellerUniqueId',
                component: StartupAttachmentComponent
            },
            {
                path: 'startupCompanyDetails/:sellerUniqueId',
                component: StartupCompanyDetailsComponent
            },
            {
                path: 'startupConfidentials/:sellerUniqueId',
                component: StartupConfidentialComponent
            },
            {
                path: 'startupFinancial/:sellerUniqueId',
                component: StartupFinancialComponent
            },
            {
                path: 'startupLocation/:sellerUniqueId',
                component: StartupLocationDetailsComponent
            },
            {
                path: 'startupOther/:sellerUniqueId',
                component: StartupOtherComponent
            },
            {
                path: 'mystartup/:sellerUniqueId',
                component: MyStartupProfileComponent
            },
            {
                path: 'startupRequirement/:sellerUniqueId',
                component: StartupRequirementComponent
            },
            {
                path: 'startupTeamDetails/:sellerUniqueId',
                component: StartupTeamDetailsComponent
            },
            {
                path: 'upgradeInv/:userUniqueId',
                component: UpgradeInvestorComponent
            },
            {
                path: 'upgrade/:userUniqueId',
                component: UpgradeBusinessComponent
            },

            {
                path: 'upgradeStarup/:userUniqueId',
                component: UpgradeStartupComponent
            },

            {
                path: 'chat',
                component: ChatComponent
            },
            {
                path: 'activateAccount',
                component: ActivateAccountComponent
            },
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(dashboardRoutes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {
}

export const dashboardRoutedComponents = [
    MyAccountComponent,
    ChangePasswordComponent,
    DemoComponent,
    ProfileViewChartComponent,
    DemoComponent,
    BrokerBusinessInformationComponent,
    BrokerConfidentialComponent,
    BrokerBusinessListingComponent,
    BrokerOtherInfoComponent,
    BrokerLocationComponent,
    MyBusinessComponent,
    BusinessAdvDetailsComponent,
    BusinessAttachmentComponent,
    BusinessConfidentialComponent,
    BusinessFinancialComponent,
    BusinessInformationComponent,
    BusinessLocationDetailsComponent,
    BxproposalComponent,
    BusinessRequirementComponent,
    BusinessTeamDetailsComponent,
    BxMailComponent,
    ContactHistoryComponent,
    IncubatorAdvertisementComponent,
    IncubatorAttachmentComponent,
    IncubatorConfidentialsComponent,
    IncubatorDetailsComponent,
    IncubatorPreferenceComponent,
    InstaResponseComponent,
    InvestorAdvertisementComponent,
    InvestorConfidentialComponent,
    InvestorFirmTypeComponent,
    InvestorIndividualTypeComponent,
    InvestorMultiPrefComponent,
    MyInvestorProfileComponent,
    LenderAdvertisementComponent,
    LenderConfidentialsComponent,
    LenderPreferenceComponent,
    MyLenderProfileComponent,
    MentorConfidentialComponent,
    MentorDetailsComponent,
    MentorPreferenceComponent,
    MyMentorProfileComponent,
    MentorAdvDetailsComponent,
    NbfcLenderComponent,
    PrivateLenderComponent,
    StartupAdvDetailsComponent,
    StartupAttachmentComponent,
    StartupCompanyDetailsComponent,
    StartupConfidentialComponent,
    StartupLocationDetailsComponent,
    StartupOtherComponent,
    MyStartupProfileComponent,
    StartupRequirementComponent,
    StartupTeamDetailsComponent,
    StartupFinancialComponent,
    UpgradeInvestorComponent,
    UpgradeBusinessComponent,
    ChatComponent,
    ActivateAccountComponent,
    UpgradeStartupComponent

];

