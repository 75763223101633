
/*
import {APP_CONFIG} from './app-config.constants';
import {IAppConfig} from './app-config.interface';
import {Inject} from '@angular/core';

export class MyCustomConfig {
    constructor(@Inject(APP_CONFIG) private config: IAppConfig) {
    }

    providers: {
        google: { clientId: this.config.googleClientId, url: this.config.apiEndpoint + '/auth/google' },
        facebook: { clientId: this.config.facebookClientId, url: this.config.apiEndpoint + '/auth/facebook' },
        linkedin: { clientId: this.config.linkedinClientId, url: this.config.apiEndpoint + '/auth/linkedin' }
    };
}*/

// import {IPartialConfigOptions} from 'ng2-ui-auth/config.service';
// export const MyAuthConfig: IPartialConfigOptions = {
//     providers: {
//         google: {
//             clientId: '770175068252-fm5j7e7j68tn8ch49s0rjopn5q4g4hpj.apps.googleusercontent.com',
//             url: 'https://bxapi.businessex.com/bexapi' + '/auth/google'
//         },
//         facebook: {clientId: '595176044018751', url: 'https://bxapi.businessex.com/bexapi' + '/auth/facebook'},
//         linkedin: {
//             clientId: '81ztq4ujjp861k',
//             url: 'https://bxapi.businessex.com/bexapi' + '/auth/linkedin',
//             scope: ['r_emailaddress', 'r_liteprofile']
//         }
//     }
// };


import { Ng2UiAuthModule} from 'ng2-ui-auth';

export const GOOGLE_CLIENT_ID = '770175068252-fm5j7e7j68tn8ch49s0rjopn5q4g4hpj.apps.googleusercontent.com';
export const FACBOOK_CLIENT_ID = '595176044018751';
export const LINKEDIN_CLIENT_ID = '81ztq4ujjp861k';

export const MyAuthConfig = {
    //defaultHeaders = { 'Content-Type': 'application/json' };
    providers : {
        google: {
            clientId: GOOGLE_CLIENT_ID,
            url: 'https://bxapi.businessex.com/bexapi' + '/auth/google'
        },
        facebook: {
            clientId: FACBOOK_CLIENT_ID,
            url: 'https://bxapi.businessex.com/bexapi' + '/auth/facebook'
        },
        linkedin: {
            clientId: LINKEDIN_CLIENT_ID,
            url: 'https://bxapi.businessex.com/bexapi' + '/auth/linkedin',
            scope: ['r_emailaddress', 'r_liteprofile']
        }

    }
}