import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';
import {appConfig} from '../../../frontend/app.config';
import { JwtHelperService } from '@auth0/angular-jwt';
import {APP_CONFIG, PROFILE_ARRAY, PROFILE_NAME_FROM_TYPE} from '../../../../config/app-config.constants';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
    selector: 'activate-account',
    templateUrl: './activate-account.component.html',
    styles: [
        `
        .bx-payment-mode li { 
            list-style:none
        }
        .bx-payment-mode li{
            float:left;
            padding:0 10px;
        }
        .paymentOptions{
            padding: 10px 0;
        }
        .paymentOptions .require{
            color: #a94442;
        }
        `
    ]
})

export class ActivateAccountComponent implements OnInit {

    responsevalue;
    response;
    submitMessage;
    showProfileActivationPaymentForm = false;
    isSubmitted = false;
    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    ProfileSelected;
    unique_str;
    unique_profile;
    public couponResponse = {status: '', message: '', coupon_id: ''};

    

    ngOnInit(): void {


        
        if (isPlatformBrowser(this.platformId)) {


            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }


            if (localStorage.getItem('newUserId') !== null && localStorage.getItem('newProfileId') !== null) {
                this.http.get(this.config.apiEndpoint + '/checkActivationPayment/' + localStorage.getItem('newUserId')
                    + '/' + localStorage.getItem('newProfileType'))
                    .subscribe(response => {
                        this.response = response;
                        if (this.response.status == 1) { // payment already done or profile not exist
                            this.submitMessage = this.response.message;
                            appConfig.scrollToError(true);
                        } else {
                            this.showProfileActivationPaymentForm = false;
                        }
                    });
            }
        }
    }

    constructor(private http: HttpClient, @Inject(APP_CONFIG)
                private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                route: ActivatedRoute,) {

                    route.paramMap.subscribe(
                        params => this.unique_str = params.get('userUniqueId')
                    );
                    route.paramMap.subscribe(
                        params => this.unique_profile = params.get('profile')
                    );
    }

    saveProfileActivationPayment(data) {
        const formSelector = $('form[name="profileActivationForm"]');
        this.isSubmitted = true;
        const payment_mode = $("input[name='payment_mode']:checked").val();
        if (formSelector.validator('validate').has('.has-error').length || payment_mode==undefined) {
            return;
        }

        data.user_id = localStorage.getItem('newUserId');
        data.profile_type = localStorage.getItem('newProfileType');
        data.profile_id = localStorage.getItem('newProfileId');
        data.payment_mode = $("input[name='payment_mode']:checked").val();
        data.membership_plan = 0; // for activation, it is 0
        data.payment_mode = $("input[name='payment_mode']:checked").val();
        formSelector.find('button[type="submit"]').button('loading');
/*
        // Post values here
        this.http.post(this.config.apiEndpoint + '/upgradenew/' + data.profile_id, data).subscribe(
            response => {
                console.log('registration successfull');
                this.responsevalue = response;
                if (typeof this.responsevalue === 'object' && this.responsevalue !== null) {
                    appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
                        key: this.responsevalue.merchant_key,
                        hash: this.responsevalue.hash,
                        txnid: this.responsevalue.txnId,
                        surl: this.responsevalue.surl,
                        furl: this.responsevalue.furl,
                        curl: this.responsevalue.curl,
                        amount: this.responsevalue.amount,
                        firstname: this.responsevalue.firstname,
                        email: this.responsevalue.email,
                        phone: this.responsevalue.phone,
                        productinfo: this.responsevalue.productinfo,
                        city: this.responsevalue.city,
                        country: this.responsevalue.country
                    }, true);

                }
            },
            error => {
                console.log('Profile Activation Payment failed');
                formSelector.find('button[type="submit"]').button('reset');
            });
			*/
			
			// Post values here
        this.http.post(this.config.apiEndpoint + '/upgradenew/' + data.profile_id, data).subscribe(
            response => {

                this.responsevalue = response;  

                if(data.payment_mode==undefined || data.payment_mode==''){
                    appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
                        key: this.responsevalue.merchant_key,
                        hash: this.responsevalue.hash,
                        txnid: this.responsevalue.txnId,
                        surl: this.responsevalue.surl,
                        furl: this.responsevalue.furl,
                        curl: this.responsevalue.curl,
                        amount: this.responsevalue.amount,
                        firstname: this.responsevalue.firstname,
                        email: this.responsevalue.email,
                        phone: this.responsevalue.phone,
                        productinfo: this.responsevalue.productinfo,
                        city: this.responsevalue.city,
                        country: this.responsevalue.country
                    });

                }else if(data.payment_mode =='Paytm'){
                  let paytmData = this.responsevalue.paytmList;
                   appConfig.submitPayFORM('https://securegw.paytm.in/theia/processTransaction', 'POST',paytmData);

                }else{
                    appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
                        access_code: this.responsevalue.access_code,
                        encRequest: this.responsevalue.encRequest, 
                    });
                }

                /*if (typeof this.responsevalue === 'object' && this.responsevalue !== null) {

                    appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
                        access_code: this.responsevalue.access_code,
                        encRequest: this.responsevalue.encRequest, 
                    }, true); 


                }*/  
            },
            error => {
                //console.log('Profile Activation Payment failed');
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    saveUpgradeAccount(data, formName) {
        const formSelector = $('form[name="' + formName + '"]');
        this.isSubmitted = true;
        const payment_mode = $("input[name='payment_mode']:checked").val();
        
        if (formName === 'upgradeSellerForm' && (formSelector.validator('validate').has('.has-error').length || !data.membership_plan || payment_mode==undefined)) {
            return;
        }
        data.user_id = this.userID;
        data.profile_type = this.ProfileSelected.profile_type;
        data.profile_id = this.ProfileSelected.profile_id;
        data.membership_plan = (data.membership_plan) ? data.membership_plan : 500; // addon plan
		data.payment_mode = $("input[name='payment_mode']:checked").val();
        formSelector.find('button[type="submit"]').button('loading');
		
        // Post values here
        this.http.post(this.config.apiEndpoint + '/upgrade/' + this.unique_str, data).subscribe(
            response => {


                this.responsevalue = response;
                
                if(data.payment_mode==undefined || data.payment_mode==''){
                    
                    appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
                        key: this.responsevalue.merchant_key,
                        hash: this.responsevalue.hash,
                        txnid: this.responsevalue.txnId,
                        surl: this.responsevalue.surl,
                        furl: this.responsevalue.furl,
                        curl: this.responsevalue.curl,
                        amount: this.responsevalue.amount,
                        firstname: this.responsevalue.firstname,
                        email: this.responsevalue.email,
                        phone: this.responsevalue.phone,
                        productinfo: this.responsevalue.productinfo,
                        city: this.responsevalue.city,
                        country: this.responsevalue.country
                    });

                }else if(data.payment_mode =='Paytm'){
                  let paytmData = this.responsevalue.paytmList;
                   appConfig.submitPayFORM('https://securegw.paytm.in/theia/processTransaction', 'POST',paytmData);

                }else{
                    appConfig.submitPayFORM('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction', 'POST', {
                        access_code: this.responsevalue.access_code,
                        encRequest: this.responsevalue.encRequest, 
                    });
                }
                
            },
            error => {
                //console.log('registration failed');
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    submitCoupon(data) {
        const formSelector = $('form[name="upgradeSellerForm"]');
        if (data.coupon_code === '' || $('input[name="membership_plan"]:checked').length === 0) {
            this.couponResponse.status = 'error';
            this.couponResponse.message = (data.coupon_code === '') ? 'Please enter the coupon code' : 'Please choose a membership';
            return;
        }
        formSelector.find('.prombtn').button('loading');
        this.couponResponse.message = '';
        this.http.post(this.config.apiEndpoint + '/apply-coupon',
            {
                user_id: this.userID,
                coupon_code: data.coupon_code,
                membership: data.membership_plan,
                profile_type: +this.ProfileSelected.profile_type
            })
            .subscribe(
                (response: any) => {
                    this.couponResponse = response;
                    formSelector.find('.prombtn').button('loading');
                },
                error => {
                    this.couponResponse = error.error;
                    formSelector.find('.prombtn').button('reset');
                });
    }

    removeCoupon() {
        this.couponResponse.message = '';
        this.couponResponse.status = '';
        this.couponResponse.coupon_id = '';
    }


}
