<!-- Search Bar Section Start Here -->
<!-- <search></search> -->
<!-- Search Bar Section End Here -->
<!-- Start Body Part -->
<!--for frontend-->
<div *ngIf="isAddOnPage" class="row aboutus myAccount ph-price without-search">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">
<!-- Investor profile pic -->
<!-- Company intraction detaild info -->
<div class="col-xs-12 col-sm-12 col-md-12">
<div class="">
<div class="cat-list">
<div class="page-ttl">
<h1>Add-On Plans</h1>
</div>
</div>
<div class="row custom_error" style="margin-top:20px;">
<div class="col-xs-12">
<div class="msg-pnl_sales" *ngIf="submitMessage">
{{submitMessage}}
</div>
</div>
</div>

<div class="margin-20" style="margin:20px 0px;">

<div class="row form-sec">
<div class="col-xs-12 col-sm-12 col-md-12">
<div class="sec-slide-effect">

<form #addOnForm="ngForm" (ngSubmit)="saveAddOn(addOnForm.value);"
style="padding-top: 20px;" class="form-horizontal" name="addOnForm">
<div class="row">
<div
    class="col-xs-12 col-sm-12 col-md-10 col-md-offset-2 addoncustom">
    <div class="form-group">
        <div class="addondisable"
            *ngIf="profileObjects[0]?.profile_id == 0 "></div>
        <label
            class="col-xs-12 col-sm-3 col-md-2 control-label"><strong>Upgrade
                Profile</strong> <span>:</span></label>
        <div class="col-xs-12 col-sm-9 col-md-9">

            <select class="form-control" name="profile_type"
                #profile_type="ngModel"
                (ngModelChange)="onChangeObj($event)" ngModel
                data-error="Please fill out this field." required>
                <option value="" disabled selected hidden>Select
                    Profile</option>
                <option
                    *ngFor="let profType of profileObjects | nonBrokerProfileTypeFilter"
                    [value]="profType.profile_str"
                    [selected]="profType.profile_name == ''">
                    {{profType.profile_name}}</option>
            </select>
        </div>
    </div>
</div>
</div>
<div class="row modfynew">
<div class="col-xs-12 col-sm-12 col-md-9 col-md-offset-2">
    <div class="sec-slide-effect">
        <div class="form-group">
            <div class="sec-slide-effect add-on-packages">
                <div class="addondisable"
                    *ngIf="ProfileSelected?.profile_status ==3
    || ProfileSelected?.membership_type == 3 || profileObjects[0]?.profile_id == 0 ">
                    <div class="overlayblk">
                        <div class="overlayblkinner"
                            *ngIf="profileObjects[0]?.profile_id == 0">
                            In order to avail our add-on services,
                            please Create a Profile by
                            <a
                                [routerLink]="['/registration/quick-registration']">Clicking
                                Here</a>
                        </div>
                        <div class="overlayblkinner"
                            *ngIf="ProfileSelected?.profile_status ==3">
                            Kindly get your profile activated by
                            <a
                                [routerLink]="['/dashboard/activateAccount']">Clicking
                                Here</a>
                        </div>
                        <div class="overlayblkinner"
                            *ngIf="ProfileSelected?.membership_type == 3">
                            Add-ons will be available after your
                            current membership has expired.
                        </div>
                    </div>
                </div>
                <div class="addon-ttl">Add-on Services</div>
                <span class="error-msg"><span
                        class="alert">{{addOnFormError}}</span></span>
                <div class="addon-list">
                    <ul>
                        <li>
                            <div class="addondisable"
                                *ngIf="addOn1 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="addOn-select-area">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            name="profile_view"
                                            value="999"
                                            id="addonone"
                                            data-error="Please Select atleast one"
                                            class="role"
                                            (click)="isChecked()"
                                            #profile_view="ngModel"
                                            ngModel>
                                    </label>
                                </div>
                            </div>
                            <div class="addOn-name">
                                PROFILE VIEW
                                <div class="desc">Get to know the
                                    complete information about your
                                    Profile Visitors</div>
                            </div>
                            <div class="addOn-Year">
                                <div class="selectbx">
                                    <select class="form-control"
                                        [(ngModel)]="value501"
                                        name="profile_view_duration"
                                        #profile_view_duration="ngModel"
                                        (change)="changeAddOnAmount($event.target.value, 501)"
                                        ngModel>
                                        <option value="1">1 Month
                                        </option>
                                        <option value="3">3 Month
                                        </option>
                                        <option value="6">6 Month
                                        </option>
                                    </select>
                                </div>
                                <div class="price"><i
                                        class="fa fa-inr"
                                        aria-hidden="true"></i>{{profileViewAmount}}
                                    <span
                                        style="font-size: x-small;">(+
                                        18% GST)</span></div>
                            </div>
                        </li>
                        <li>
                            <div class="addondisable"
                                *ngIf="addOn2 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="addOn-select-area">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            name="top_recommendation"
                                            value="1999"
                                            id="addontwo"
                                            data-error="Please Select atleast one"
                                            class="role"
                                            (click)="isChecked()"
                                            #top_recommendation="ngModel"
                                            ngModel>
                                    </label>
                                </div>
                            </div>
                            <div class="addOn-name">TOP
                                RECOMMENDATIONS
                                <div class="desc">Stand on top of
                                    the Dashboard as “Top
                                    Recommended”</div>
                            </div>
                            <div class="addOn-Year">
                                <div class="selectbx">
                                    <select class="form-control"
                                        [(ngModel)]="value502"
                                        name="top_recommendation_duration"
                                        #top_recommendation_duration="ngModel"
                                        (change)="changeAddOnAmount($event.target.value, 502)"
                                        ngModel>
                                        <option value="1">1 Month
                                        </option>
                                        <option value="3">3 Month
                                        </option>
                                        <option value="6">6 Month
                                        </option>
                                    </select>
                                </div>
                                <div class="price"><i
                                        class="fa fa-inr"
                                        aria-hidden="true"></i>
                                    {{topRecommendationAmount}}
                                    <span
                                        style="font-size: x-small;">(+
                                        18% GST)</span></div>
                            </div>
                        </li>
                        <li>
                            <div class="addondisable"
                                *ngIf="selectedProfilePlan?.interactionCreditBalance > 0 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="addOn-select-area">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            name="interaction_credits"
                                            value="500"
                                            id="addonthree"
                                            data-error="Please Select atleast one"
                                            class="role"
                                            (click)="isChecked()"
                                            #interaction_credits="ngModel"
                                            ngModel>
                                    </label>
                                </div>
                            </div>
                            <div class="addOn-name">INTERACTION
                                CREDITS
                                <div class="desc">Add more Credits
                                    to send Proposals to Registered
                                    Users</div>
                            </div>
                            <div class="addOn-Year">

                                <div class="selectbx">
                                    <select class="form-control"
                                        [(ngModel)]="value503"
                                        name="interaction_credits_number"
                                        #interaction_credits_number="ngModel"
                                        (change)="changeAddOnAmount($event.target.value, 503)"
                                        ngModel>
                                        <option value="5">5 Credits
                                        </option>
                                        <option value="10">10
                                            Credits</option>
                                        <option value="15">15
                                            Credits</option>
                                    </select>
                                </div>
                                <div class="price"><i
                                        class="fa fa-inr"
                                        aria-hidden="true"></i>{{interactionAmount}}/-
                                    One Time
                                    <span
                                        style="font-size: x-small;">(+
                                        18% GST)</span></div>
                            </div>
                        </li>
                        <li>
                            <div class="addondisable"
                                *ngIf="selectedProfilePlan?.instaCreditBalance > 0 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="addOn-select-area">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            name="instant_response"
                                            value="500"
                                            id="addonfour"
                                            data-error="Please Select atleast one"
                                            class="role"
                                            (click)="isChecked()"
                                            #instant_response="ngModel"
                                            ngModel>
                                    </label>
                                </div>
                            </div>
                            <div class="addOn-name">INSTANT RESPONSE
                                <div class="desc">Add more Credits
                                    to unlock Proposals from Website
                                    Visitors</div>
                            </div>
                            <div class="addOn-Year">

                                <div class="selectbx">
                                    <select class="form-control"
                                        [(ngModel)]="value504"
                                        name="instant_response_number"
                                        #instant_response_number="ngModel"
                                        (change)="changeAddOnAmount($event.target.value, 504)"
                                        ngModel>
                                        <option value="5">5 Credits
                                        </option>
                                        <option value="10">10
                                            Credits</option>
                                        <option value="15">15
                                            Credits</option>
                                    </select>
                                </div>
                                <div class="price"><i
                                        class="fa fa-inr"
                                        aria-hidden="true"></i>{{instaResponseAmount}}/-
                                    One Time
                                    <span
                                        style="font-size: x-small;">(+
                                        18% GST)</span></div>
                            </div>
                        </li>
                        <li>
                            <div class="addondisable"
                                *ngIf="addOn5 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="addOn-select-area">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            name="accelerated_marketing"
                                            value="500"
                                            id="addonfive"
                                            data-error="Please Select atleast one"
                                            class="role"
                                            (click)="isChecked()"
                                            #accelerated_marketing="ngModel"
                                            ngModel>
                                    </label>
                                </div>
                            </div>
                            <div class="addOn-name">Accelerated
                                Marketing
                                <div class="desc">Upto 10,000
                                    marketing emails sent to
                                    registered members of BusinessEx
                                </div>
                            </div>
                            <div class="addOn-Year">

                                <div class="selectbx">
                                    <select class="form-control"
                                        [(ngModel)]="value505"
                                        name="accelerated_marketing_duration"
                                        #accelerated_marketing_duration="ngModel"
                                        (change)="changeAddOnAmount($event.target.value, 505)"
                                        ngModel>
                                        <option value="1">1 Month
                                        </option>
                                    </select>
                                </div>
                                <div class="price"><i
                                        class="fa fa-inr"
                                        aria-hidden="true"></i>{{acceleratedMarketingAmount}}/-
                                    One Time
                                    <span
                                        style="font-size: x-small;">(+
                                        18% GST)</span></div>
                            </div>
                        </li>
                        <li>
                            <div class="addondisable"
                                *ngIf="addOn6 && ProfileSelected?.membership_type != 3">
                            </div>
                            <div class="addOn-select-area">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            name="top_buyer"
                                            value="500"
                                            id="addonsix"
                                            data-error="Please Select atleast one"
                                            class="role"
                                            (click)="isChecked()"
                                            #top_buyer="ngModel"
                                            ngModel>
                                    </label>
                                </div>
                            </div>
                            <div class="addOn-name">
                                {{_profile?.need}}
                                <div class="desc">
                                    {{_profile?.proposal}}</div>
                            </div>
                            <div class="addOn-Year">

                                <div class="selectbx">
                                    <select class="form-control"
                                        [(ngModel)]="value506"
                                        name="no_of_proposal"
                                        #no_of_proposal="ngModel"
                                        (change)="changeAddOnAmount($event.target.value, 506)"
                                        ngModel>
                                        <option value="20">20
                                            Proposals</option>
                                        <option value="50">50
                                            Proposals</option>
                                    </select>
                                </div>
                                <div class="price"><i
                                        class="fa fa-inr"
                                        aria-hidden="true"></i>{{topBuyerSellerAmount}}/-
                                    One Time
                                    <span
                                        style="font-size: x-small;">(+
                                        18% GST)</span></div>
                            </div>
                        </li>
                    </ul>
                </div>


                <div
                    class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect txt-cen">
                    <div class="top-bdr">
                        <div class="terms">By Clicking Upgrade you
                            are Accepting
                            <a routerLink="/terms">Terms &amp;
                                Conditions</a>
                        </div>
                        <div class="submitfrm">
                            <button type="submit"
                                class="btn btn-default btn-blue rm-pad1">UPGRADE</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

<!--for dashboard-->
<div *ngIf="!isAddOnPage" class="row form-sec">
<div class="">
<div class="sec-slide-effect">
<form #addOnForm="ngForm" (ngSubmit)="saveAddOn(addOnForm.value);" class="form-horizontal" name="addOnForm">
<div class="row">
<div class="col-xs-12 col-sm-12 col-md-12">
<div class="sec-slide-effect">
<div class="form-group">
<div class="sec-slide-effect add-on-packages">
<div class="addondisable" *ngIf="ProfileSelected?.membership_type == 3 ">
<div class="overlayblk">
<div class="overlayblkinner" *ngIf="ProfileSelected?.membership_type == 3">
Add-ons will be available after your current membership has expired.
</div>
</div>
</div>

<div class="addon-ttl">Add-on Services</div>
<span class="error-msg"><span class="alert">{{addOnFormError}}</span></span>
<div class="addon-list">
<ul>
<li>
<div class="addondisable"
*ngIf="addOn1 && ProfileSelected?.membership_type != 3"></div>
<div class="addOn-select-area">
<div class="checkbox">
    <label>
        <input type="checkbox" name="profile_view" value="999"
            id="addonone" data-error="Please Select atleast one"
            class="role" (click)="isChecked()"
            #profile_view="ngModel" ngModel>
    </label>
</div>
</div>
<div class="addOn-name">
PROFILE VIEW
<div class="desc">Get to know the complete information about your
    Profile Visitors</div>
</div>
<div class="addOn-Year">
<div class="selectbx">
    <select class="form-control" [(ngModel)]="value501"
        name="profile_view_duration"
        #profile_view_duration="ngModel"
        (change)="changeAddOnAmount($event.target.value, 501)"
        ngModel>
        <option value="1">1 Month</option>
        <option value="3">3 Month</option>
        <option value="6">6 Month</option>
    </select>
</div>
<div class="price"><i class="fa fa-inr"
        aria-hidden="true"></i>{{profileViewAmount}} <span
        style="font-size: x-small;">(+ 18% GST)</span></div>
</div>
</li>
<li>
<div class="addondisable"
*ngIf="addOn2 && ProfileSelected?.membership_type != 3"></div>
<div class="addOn-select-area">
<div class="checkbox">
    <label>
        <input type="checkbox" name="top_recommendation"
            value="1999" id="addontwo"
            data-error="Please Select atleast one" class="role"
            (click)="isChecked()" #top_recommendation="ngModel"
            ngModel>
    </label>
</div>
</div>
<div class="addOn-name">TOP RECOMMENDATIONS
<div class="desc">Stand on top of the Dashboard as “Top Recommended”
</div>
</div>
<div class="addOn-Year">
<div class="selectbx">
    <select class="form-control" [(ngModel)]="value502"
        name="top_recommendation_duration"
        #top_recommendation_duration="ngModel"
        (change)="changeAddOnAmount($event.target.value, 502)"
        ngModel>
        <option value="1">1 Month</option>
        <option value="3">3 Month</option>
        <option value="6">6 Month</option>
    </select>
</div>
<div class="price"><i class="fa fa-inr" aria-hidden="true"></i>
    {{topRecommendationAmount}} <span style="font-size: x-small;">(+
        18% GST)</span></div>
</div>
</li>
<li>
<div class="addondisable"
*ngIf="selectedProfilePlan?.interactionCreditBalance > 0 && ProfileSelected?.membership_type != 3">
</div>
<div class="addOn-select-area">
<div class="checkbox">
    <label>
        <input type="checkbox" name="interaction_credits"
            value="500" id="addonthree"
            data-error="Please Select atleast one" class="role"
            (click)="isChecked()" #interaction_credits="ngModel"
            ngModel>
    </label>
</div>
</div>
<div class="addOn-name">INTERACTION CREDITS
<div class="desc">Add more Credits to send Proposals to Registered
    Users</div>
</div>
<div class="addOn-Year">

<div class="selectbx">
    <select class="form-control" [(ngModel)]="value503"
        name="interaction_credits_number"
        #interaction_credits_number="ngModel"
        (change)="changeAddOnAmount($event.target.value, 503)"
        ngModel>
        <option value="5">5 Credits</option>
        <option value="10">10 Credits</option>
        <option value="15">15 Credits</option>
    </select>
</div>
<div class="price"><i class="fa fa-inr"
        aria-hidden="true"></i>{{interactionAmount}}/- One Time
    <span style="font-size: x-small;">(+ 18% GST)</span></div>
</div>
</li>
<li>
<div class="addondisable"
*ngIf="selectedProfilePlan?.instaCreditBalance > 0 && ProfileSelected?.membership_type != 3">
</div>
<div class="addOn-select-area">
<div class="checkbox">
    <label>
        <input type="checkbox" name="instant_response" value="500"
            id="addonfour" data-error="Please Select atleast one"
            class="role" (click)="isChecked()"
            #instant_response="ngModel" ngModel>
    </label>
</div>
</div>
<div class="addOn-name">INSTANT RESPONSE
<div class="desc">Add more Credits to unlock Proposals from Website
    Visitors</div>
</div>
<div class="addOn-Year">

<div class="selectbx">
    <select class="form-control" [(ngModel)]="value504"
        name="instant_response_number"
        #instant_response_number="ngModel"
        (change)="changeAddOnAmount($event.target.value, 504)"
        ngModel>
        <option value="5">5 Credits</option>
        <option value="10">10 Credits</option>
        <option value="15">15 Credits</option>
    </select>
</div>
<div class="price"><i class="fa fa-inr"
        aria-hidden="true"></i>{{instaResponseAmount}}/- One Time
    <span style="font-size: x-small;">(+ 18% GST)</span></div>
</div>
</li>

<li>
<div class="addondisable"
*ngIf="addOn5 && ProfileSelected?.membership_type != 3"></div>
<div class="addOn-select-area">
<div class="checkbox">
    <label>
        <input type="checkbox" name="accelerated_marketing"
            value="500" id="addonfive"
            data-error="Please Select atleast one" class="role"
            (click)="isChecked()" #accelerated_marketing="ngModel"
            ngModel>
    </label>
</div>
</div>
<div class="addOn-name">Accelerated Marketing
<div class="desc">Upto 10,000 marketing emails sent to registered
    members of BusinessEx</div>
</div>
<div class="addOn-Year">

<div class="selectbx">
    <select class="form-control" [(ngModel)]="value505"
        name="accelerated_marketing_duration"
        #accelerated_marketing_duration="ngModel"
        (change)="changeAddOnAmount($event.target.value, 505)"
        ngModel>
        <option value="1">1 Month</option>
    </select>
</div>
<div class="price"><i class="fa fa-inr"
        aria-hidden="true"></i>{{acceleratedMarketingAmount}}/- One
    Time
    <span style="font-size: x-small;">(+ 18% GST)</span></div>
</div>
</li>
<li>
<div class="addondisable"
*ngIf="addOn6 && ProfileSelected?.membership_type != 3"></div>
<div class="addOn-select-area">
<div class="checkbox">
    <label>
        <input type="checkbox" name="top_buyer" value="500"
            id="addonsix" data-error="Please Select atleast one"
            class="role" (click)="isChecked()" #top_buyer="ngModel"
            ngModel>
    </label>
</div>
</div>
<div class="addOn-name">{{_profile?.need}}
<div class="desc">{{_profile?.proposal}}</div>
</div>
<div class="addOn-Year">

<div class="selectbx">
    <select class="form-control" [(ngModel)]="value506"
        name="no_of_proposal" #no_of_proposal="ngModel"
        (change)="changeAddOnAmount($event.target.value, 506)"
        ngModel>
        <option value="20">20 Proposals</option>
        <option value="50">50 Proposals</option>
    </select>
</div>
<div class="price"><i class="fa fa-inr"
        aria-hidden="true"></i>{{topBuyerSellerAmount}}/- One Time
    <span style="font-size: x-small;">(+ 18% GST)</span></div>
</div>
</li>
</ul>
</div>
<div class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect txt-cen">
<div class="top-bdr">
<div class="submitfrm">
<button type="submit"
class="btn btn-default btn-blue rm-pad1">UPGRADE</button>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
<!-- End Start Body Part -->