<div *ngIf="loading" class="loading">
    <div style="display:table-cell; vertical-align: middle;">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>
</div>


<div class="container bex-main">
    <div class="row">
        <div class="col-md-12">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item" *ngIf="filterMentor.length === 1"><a [routerLink]="['/mentorlisting']">Mentor</a></li>
                <li class="breadcrumb-item" *ngIf="filterMentor.length === 0 || filterMentor.length > 1">Mentor</li>
                <li class="breadcrumb-item active" *ngIf="filterMentor.length === 1">{{getMentorType(filterMentor[0])?.mentorOccupation}}</li>
            </ol>
        </div>
        <!-- <div class="col-md-12 pt-b15">
            <div class="bex-search-section">
                <span class="mhides">
                    <i class="fa fa-bolt" aria-hidden="true"></i> Trending Searches:
                </span>
                <span>
                    <ul class="bex-trending-search-tab">
                        <li><a href="#">Hospitality</a></li>
                        <li><a href="#">Hotels</a></li>
                        <li><a href="#">Management</a></li>
                        <li><a href="#">Education</a></li>
                        <li><a href="#">Pre-School</a></li>
                        <li><a href="#">Restaurants</a></li>
                        <li><a href="#">Food Parlor</a></li>
                    </ul>
                </span>
            </div>
        </div> -->
    </div>


    <div class="row catfull">
        <div class="filter" id="showftr">Apply Filter</div>

        <div class="col-md-3 catsh">
            <div class="catleft">
                <div id="closeftr" class="closebtn"><i class="fa fa-times fa-2x" aria-hidden="true"></i></div>
                <div class="mainleftdiv">
                    <div class="subhead">Filters</div>

                    <!-- looking for-->
                    <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Mentor Types</a> <span class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                            <ul class="sub-menu">
                                <li
                                    *ngFor="let categorylist of mentorOccupation | unique:'mentorOccupation'; let i = index ">
                                    <label>

                                        <input class="sub-gen" type="checkbox"
                                            attr.id="{{categorylist.mentorOccupationid}}"
                                            attr.class="mentor{{categorylist.mentorOccupationid}}"
                                            (click)="mentorChange(categorylist.mentorOccupationid , categorylist.mentorOccupation)"
                                            [(ngModel)]="filterMentorselect[categorylist.mentorOccupation]">
                                        <a class="clicked" attr.id="mentor{{categorylist.mentorOccupationid}}"
                                            href="mentorlisting/{{categorylist.slug}}-mentor-in-india/{{categorylist.mentorOccupationid}}/s0/st0"
                                            (click)="$event.preventDefault()">
                                            {{categorylist.mentorOccupation}}
                                        </a>



                                    </label>

                                </li>

                            </ul>
                        </div>
                    </div>

                    <!-- investment size-->
                    <!-- Location -->
                    <div class="accordion_container">

                        <div class="accordion_head"><span class="plusminus minus"></span> <a
                            [routerLink]="">Location</a>
                        </div>
                        <div class="accordion_body">
                            <div class="accordianHead" (click)="appConfig.locationtypeaccordianCheck($event)"
                                *ngFor="let statelist of filterLocation12 | unique: 'mentor_state'; let i = index">

                                <div class="accordion_headmain">
                                    <span class="rightdown downval"></span>

                                    <input class="brand-filter" type="checkbox" attr.id="{{statelist.state}}"
                                        attr.class="state{{statelist.state}}"
                                        (click)="includeCity(statelist.mentor_state, statelist.state,'', statelist.cityId, statelist.mentor_state)"
                                        [(ngModel)]="filterLocationstate[statelist.mentor_state]">
                                    <a class="clicked" attr.id="state{{statelist.state}}"
                                        href="mentorlisting/mentor-in-{{statelist.stateSlug}}/c0/s0/{{statelist.state}}"
                                        (click)="$event.preventDefault()">
                                        {{statelist.mentor_state}}
                                    </a>



                                </div>

                                <div class="accordion_bodymain" style="display: none;">
                                    <div class="more">
                                        <ul class="sub-menu">
                                            <li
                                                *ngFor="let citylist of filterLocation12 | filterBy:['mentor_state']:statelist.mentor_state  | unique:'mentor_city'; let j=index">

                                                <input class="sub-gen" type="checkbox" attr.id="{{statelist.state}}"
                                                    attr.class="city{{statelist.state}}{{j}}"
                                                    (click)="includeCity(statelist.mentor_state ,statelist.state, citylist.mentor_city, citylist.cityId, citylist.mentor_city)"
                                                    [(ngModel)]="filterLocation[citylist.mentor_city]">
                                                <a class="clicked" attr.id="city{{statelist.state}}{{j}}"
                                                    href="mentorlisting/mentor-in-{{citylist.citySlug}}/c0/s0/{{citylist.state}}/{{citylist.cityId}}"
                                                    (click)="$event.preventDefault()">
                                                    {{citylist.mentor_city}}
                                                </a>



                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <!-- <div class="morcity"><a href="#">25 More</a></div> -->

                        </div>
                    </div>


                    <!--- annual sales -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Annual Sales</a> <span class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                            <div class="frmright">

                                <select class="form-control">
                                    <option value="50 - 100 Crores">50 - 100 Crores</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

                    <!--- extablishement year -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Establishment year</a> <span
                                class="plusminus minus"></span>
                        </div>
                        <div class="accordion_body" style="display: block;">
                            <div class="frmright">

                                <select class="form-control">
                                    <option value="2001 - 2010">2001 - 2010</option>
                                    <option value="2001 - 2010">2011 - 2020</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

                    <!--- Entity Types -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Entity Type</a> <span class="plusminus add"></span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                            More Filters
                        </div>
                    </div> -->

                    <!--- Employee count -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Employee Count</a> <span class="plusminus add"></span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                            More Filters
                        </div>
                    </div> -->

                    <!--- Business Type -->
                    <!-- <div class="accordion_container">
                        <div class="accordion_head"> <a href="#">Business Type</a> <span class="plusminus add"></span>
                        </div>
                        <div class="accordion_body" style="display: none;">
                            More Filters
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-9 col-md-9 mdy">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-9 setheading">
                    <h1 class="headblk">{{hititle}}</h1>
                </div>
                <div class="col-12 col-sm-6 col-md-3 float-right setmob">
                    <select class="selectpicker slt-box form-control" (ngModelChange)="onChangeObj($event)"
                        name="ProfileSelected" #ProfileSelected="ngModel" ngModel>
                        <option value="" disabled selected hidden>Sort By</option>
                        <option [ngValue]="option.value" *ngFor="let option of options">{{option.label}}</option>
                    </select>
                </div>
            </div>


            <div class="row">
                <!-- <div class="col-md-12">
                    <p [innerHTML]="subheadline"></p>
                </div> -->

                <div class="col-md-12 shrt-desc sec-hide" id="shrinkMe">
                    <p [innerHTML]="subheadline"></p>
                    <a id="more" onclick="shrink(this.id)" class="pos" [routerLink]="">...More</a>
                    <a onclick="shrink(this.id)" id="less" class="pos" [routerLink]="">...Less</a>
                </div>
            </div>

            <div class="row setvto">
                <ul class="listop otherlist">
                    <li class="sec-slide-effect" *ngFor="let mentorList of Business; let last = last" [isLast]="last"
                        (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">

                        <div class="ribbonblk">
                            <container-element [ngSwitch]="mentorList.membership_plan">
                                <div class="ribbonblkinner" *ngSwitchCase="1">Premium</div>
                                <div class="ribbonblkinner" *ngSwitchCase="2">Gold</div>
                                <div class="ribbonblkinner" *ngSwitchCase="3">Platinum</div>
                                <!-- <div class="ribbonblkinner" *ngSwitchDefault></div> -->
                            </container-element>
                            
                        </div>
                        <div class="fullb settmar">
                            <div class="fbleft ">
                                <div class="cname">
                                    <div class="cnameinner">
                                         <span> 
                                             {{mentorList.mentorName | limitTo : 10}}                                      
                                        </span>
                                        
                                        <!-- <span
                                            *ngIf="mentorList.mentorDesignation">{{mentorList.mentorDesignation | limitTo: 20}}
                                        </span> -->
                                    <div class="inet" *ngIf="mentorList.mentorOccupation != '' && mentorList.mentorOccupation != 'NULL'">
                                        
                                    <span>{{mentorList.mentorOccupation | limitTo: 20}}</span>
                                    </div>
                                    </div>
                                 
                                </div>
                            </div>
                            <div class="fbright">
                                <div class="compper">
                                    <img src="{{ mentorList.profilePic }}" alt="{{ mentorList?.profilePicName }}">
                                </div>
                            </div>
                        </div>
                    <!--     <div class="fullb contxt">
                            <span *ngIf="mentorList.mentorCompany">{{mentorList.mentorCompany}}</span>
                        </div> -->
                        <div class="sdd">
                            <div class="sddinner tooltipLP">
                                <div class="top {{mentorList.mobVerifyStatus == 0?'':'active'}}">
                                    {{mentorList.mobVerifyStatus == 1?'Verified Mobile':'Not Verified Mobile'}}

                                </div>
                                <i class="fa fa-phone-square" aria-hidden="true"></i></div>
                           

 <div class="sddinner">
                               <i class="fa fa-envelope" aria-hidden="true"></i>
                     </div>


                         <div class="sddinner ">
                               <a href="#" class="bookmar"><i class="fa fa-bookmark" aria-hidden="true"></i> </a>
                     </div>
          


                            <div class="sddinner setwva"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                {{mentorList.mentorCity}}</div>
                        </div>
                        <div class="fullb summtxt">
                            <span>Summary</span>
                            {{mentorList.mentorSummary | limitTo: 80}}
                        </div>
                        <!-- <div class="fullb citytxt">
                            Pondicherry, Tamil Nadu
                        </div> -->
                        <!-- <div class="tagv">
                            <div class="tagvinner">Hospitality</div>
                            <div class="tagvinner">Hotels</div>
                            <div class="tagvinner">Management</div>
                        </div> -->
                        <div class="backv">
                            <div class="inblk">
                                Experience <span>{{ mentorList.mentorExp}} years</span>
                            </div>
                            <div class="inblk tooltipLPshow">
                                Expertise <span
                                    *ngIf="mentorList.subExpStr != ''">{{mentorList.subExpStr | limitTo: 25}}  {{mentorList.subExpStr !==''?'+':''}}</span>
                                     <div class="top">
                                        {{mentorList.subExpStr}}
                           </div>
                            </div>
                  
                            <div class="inblk tooltipLPshow">
                                Sectors<span>{{ (mentorList.mentorSector !== '') ? (mentorList.mentorSector | limitTo: 25) : ("N/A") }} {{mentorList.mentorSector !==''?'+':''}}</span>
                                 <div class="top">
                                    {{ (mentorList.mentorSector !== '') ? (mentorList.mentorSector) : ("N/A") }}
                           </div>
                            </div>
                        </div>
                        <div class="inbtn"><a
                                [routerLink]="['/mentor/'+mentorList.mentorurl+'/'+mentorList.mentorProfileStr]">Send
                                Proposal</a></div>
                    </li>
                </ul>
            </div>

            <div class="row pt30 pb-30">
                <div class="col-md-12">
                    <listing-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()"
                        [pagesToShow]="5" [page]="currentPage" [perPage]="itemsPerPage" [count]="totalItems">
                    </listing-pagination>
                </div>
            </div>
        </div>

    </div>


</div>