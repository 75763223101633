
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE CONFIDENTIAL INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li class="selected"><a routerLink="/dashboard/incubatorConfidentials/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/incubatorAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/incubatorDetails/{{profile_str}}">Incubator Details</a></li>
                                        <li><a routerLink="/dashboard/incubatorAttachment/{{profile_str}}">Attachments</a></li>
                                        <li><a routerLink="/dashboard/incubatorPreference/{{profile_str}}">Preferences </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="incubatorForm" #incubatorForm="ngForm"
                                              (ngSubmit)="incubatorForm.form.valid && incubatorMyAccountSubmit(incubatorForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="incubatorProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{incubatorProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="incubatorProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{incubatorProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl">Confidential Information</div>
                                                    </div>
                                                </div>
                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Your
                                                        Name<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   name="incubator_name"
                                                                   [ngModel]="incubatorConfidentialsdata?.incubator_name"
                                                                   placeholder="Enter Your Name"
                                                                   data-error="Please fill out this field." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Mobile
                                                        Number<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" pattern="[56789][0-9]{9}"
                                                                   name="incubator_mobile"
                                                                   [ngModel]="incubatorConfidentialsdata?.incubator_mobile"
                                                                   class="form-control"
                                                                   placeholder="Enter Your Mobile Number"
                                                                   data-error="Please enter valid mobile number"
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Email
                                                        ID<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="email" name="incubator_email"
                                                                   [ngModel]="incubatorConfidentialsdata?.incubator_email"
                                                                   class="form-control"
                                                                   placeholder="Enter Your Email ID"
                                                                   data-error="Please fill out this field." required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group has-feedback">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="incubator_location"
                                                                   [ngModel]="incubatorConfidentialsdata?.incubator_location"
                                                                   class="form-control" placeholder="Enter Location"
                                                                   (setAddress)="getAddress($event)"
                                                                   data-error="Please select location" googleplace
                                                                   required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->