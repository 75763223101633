import {Component, Input} from '@angular/core';


declare var $: any;

@Component({
    selector: 'loader',
    template: '<div class="mailListDisable mdyw" *ngIf="loading">' +
    '                            <div class="img-wait">' +
    '                                <img src="assets/images/bx_loader.gif">' +
    '                            </div>' +
    '                        </div>',
})
export class LoaderComponent {

    @Input() loading;
}
