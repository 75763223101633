import {Inject, Injectable} from '@angular/core';
//import {Observable} from 'rxjs/Observable';
import * as io from 'socket.io-client';
import {ChatMessage} from "../models/chat-message";
import {APP_CONFIG} from "../config/app-config.constants";
import {IAppConfig} from "../config/app-config.interface";
import { Observable } from 'rxjs';

@Injectable()
export class ChatService {
    private socket;

    constructor(@Inject(APP_CONFIG) private config: IAppConfig) {

    }

    public sendMessage(message) {
        this.socket.emit('add-message', message);
    }

    public getMessages(userId = 0) {
        let observable = new Observable(observer => {
            this.socket = io(this.config.chatServerPath, {reconnectionAttempts: 2, secure: true, query: {uid: userId}});

            this.socket.on('message', (message: ChatMessage) => {
                observer.next(message);
            });
            // send some data as soon as we are connected
            this.socket.on('connect', () => {
                this.socket.emit('send-user-id', userId);
            });
            return () => {
                this.socket.disconnect();
            };
        });
        return observable;
    }

    public getOnlineUsers() {
        let observable = new Observable(observer => {
            this.socket.on('available-user', function (onlineUsers: any) {
                observer.next(onlineUsers);
            });
            return () => {
                this.socket.disconnect();
            };
        });
        return observable;
    }

    public isServerOn(): boolean {
        return this.socket.connected;
    }

}