
import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
// \\import {} from '@types/googlemaps';
declare var google: any;



@Directive({
    selector: '[googleplace]',
})
export class GooglePlaceDirective implements OnInit {
    @Output() setAddress: EventEmitter<any> = new EventEmitter();

    autocomplete: any;

    constructor(private el: ElementRef,
                private mapsAPILoader: MapsAPILoader) {
    }

    ngOnInit() {
        // load Places Autocomplete
        //console.log('Autocomplete google place')
        this.mapsAPILoader.load().then(() => {
            this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
                types: [],  // address,establishment,geocode.
                componentRestrictions: {country: 'in'},
            });

            google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
                this.setAddress.emit(this.autocomplete.getPlace());
            });
        });
    }
}
