import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'business-attachment',
    templateUrl: './business-attachment.component.html'
})
export class BusinessAttachmentComponent implements OnInit {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    files = [];
    docs = [];

    profile_str;
    public BusinessAdvDetailsdata;
    public BusinessAttachmentdata;
    public businessProfileSuccessError;
    public businessProfileFailError;
    public userProfileType;
    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Business Attachment - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    businessMyAccountSubmit(data) {
        if ($('form[name="businessForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        const formData: any = new FormData();
        for (const file of this.files) {
            formData.append('seller_prof_pic[]', file, file.name);
        }
        for (const doc of this.docs) {
            formData.append('seller_doc_path[]', doc, doc.name);
        }
        formData.append('business_profile_str', this.profile_str);
        this.http.post(this.config.apiEndpoint + '/updatesellerattachment/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Attachment Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.businessProfileSuccessError = data;
                //this.getbusinessAttachment(this.profile_str);
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.businessProfileFailError = error.error;
            });
    }

    removeUploadEvent(type, object) {
        const fileName = object.srcElement.parentElement.getAttribute('file');
        if (type === 'doc') {
            this.docs = this.docs.filter(item => item.name !== fileName);
            //console.log(this.docs);
        }
        if (type === 'file') {
            this.files = this.files.filter(item => item.name !== fileName);
            //console.log(this.files);
        }
    }

    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            if (this.files.findIndex(item => item.name === fileInput.target.files[0].name) === -1) {
                this.files.push(fileInput.target.files[0]);
                fileInput.srcElement.parentElement.setAttribute('flag', '1');
            } else {
                fileInput.srcElement.parentElement.setAttribute('flag', '0');
            }
            //console.log(this.files);
        }
    }

    docChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            if (this.docs.findIndex(item => item.name === fileInput.target.files[0].name) === -1) {
                this.docs.push(fileInput.target.files[0]);
                fileInput.srcElement.parentElement.setAttribute('flag', '1');
            } else {
                fileInput.srcElement.parentElement.setAttribute('flag', '0');
            }
            console.log(this.docs);
        }
    }

    getbusinessAttachment(profile_str: any): void {
        this.myprofileService.getbusinessAttachment(this.profile_str)
            .then((result) => {
                this.BusinessAttachmentdata = result;
            });
    }

    getsellerAdvDetails(profile_str: any): void {
        this.myprofileService.getsellerAdvDetails(this.profile_str)
            .then((result) => {
                this.BusinessAdvDetailsdata = result;
            });
    }

    getUserRegisterProfileType(): void {
        this.myprofileService.getUserRegisterProfileType(this.userID)
            .then((result) => {
                this.userProfileType = result;
            });
    }

    ngOnInit() {
        this.getbusinessAttachment(this.profile_str);
        this.getsellerAdvDetails(this.profile_str);
        this.getUserRegisterProfileType();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="businessForm"]').validator();
        }
    }

}
