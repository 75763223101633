import {InjectionToken} from '@angular/core';
import {IAppConfig} from './app-config.interface';
import {environment} from '../../environments/environment';


export const AppConfig: IAppConfig = {
    apiEndpoint: environment.apiEndpoint,
    googleClientId: environment.googleClientId,
    facebookClientId: environment.facebookClientId,
    linkedinClientId: environment.linkedinClientId,
    chatServerPath: environment.chatServerPath
};

export let APP_CONFIG = new InjectionToken<IAppConfig>('app.config');


export const Regex = {
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const PROFILE_REGISTRATION_URL = {
    Business: '/registration/create-business-profile',
    Investor: '/registration/create-investor-profile',
    Lender: '/registration/create-lender-profile',
    Mentor: '/registration/create-mentor-profile',
    Incubation: '/registration/create-incubator-profile',
    Startup: '/registration/create-startup-profile'
};

export const PROFILE_TYPE = {
    Business: 1,
    Investor: 2,
    Lender: 3,
    Mentor: 4,
    Incubation: 5,
    Broker: 6,
    Startup: 7,
};

export const PROFILE_NAME_FROM_TYPE = {
    1: 'Business',
    2: 'Investor',
    3: 'Lender',
    4: 'Mentor',
    5: 'Incubation',
    6: 'Broker',
    7: 'Startup',
};

export const PROFILE_NAME = {
    Business: 'Business',
    Investor: 'Investor',
    Lender: 'Lender',
    Mentor: 'Mentor',
    Incubation: 'Incubation',
    Broker: 'Broker',
    Startup: 'Startup',
};


export const PROFILE_ARRAY = [
    {id: PROFILE_TYPE.Business, name: PROFILE_NAME.Business, instaCount: {0: 5, 1: 10, 2: 20}, need: 'TOP BUYERS/INVESTORS',
        proposal: 'BusinessEx refines and chooses Top Buyers/Investors for your Business and sends them your Business Proposal'},
    {id: PROFILE_TYPE.Investor, name: PROFILE_NAME.Investor, instaCount: {0: 5, 1: 10, 2: 20}, need: 'TOP BUSINESS/STARTUPS',
    proposal: 'BusinessEx refines and chooses Top Business/Startups for your requirement and sends them your Investment Proposal'},
    {id: PROFILE_TYPE.Lender, name: PROFILE_NAME.Lender, instaCount: {0: 5, 1: 10, 2: 20}},
    {id: PROFILE_TYPE.Mentor, name: PROFILE_NAME.Mentor, instaCount: {0: 5, 1: 10, 2: 20}, need: 'TOP BUSINESS/STARTUPS',
    proposal: 'BusinessEx refines and chooses Top Business/Startups for your requirement and sends them your Mentorship Proposal'},
    {id: PROFILE_TYPE.Incubation, name: PROFILE_NAME.Incubation, instaCount: {0: 5, 1: 10, 2: 20}},
    {id: PROFILE_TYPE.Broker, name: PROFILE_NAME.Broker, instaCount: {}},
    {id: PROFILE_TYPE.Startup, name: PROFILE_NAME.Startup, instaCount: {0: 5, 1: 10, 2: 20}, need: 'TOP INVESTORS',
    proposal: 'BusinessEx refines and chooses Top Investors for your Business and sends them your Business Proposal'}
];

export const BASE_URL = 'https://www.businessex.com'; // no ending slash
export const FOOTER_META_DESCRIPTION = 'BusinessEx offers a platform where MAIN_PROFILE can ' +
    'connect with the prospective CONNECTING_PROFILE in India. The information given below will ' +
    'help you understand on how to use our services. If you are interested then create ' +
    'your MAIN_PROFILE Profile now.';

export const BUSINESS_CONTACT_PROFILE_TYPE = [
    {id: PROFILE_TYPE.Investor, name: PROFILE_NAME.Investor},
    {id: PROFILE_TYPE.Lender, name: PROFILE_NAME.Lender},
    {id: PROFILE_TYPE.Mentor, name: PROFILE_NAME.Mentor},
    {id: PROFILE_TYPE.Incubation, name: PROFILE_NAME.Incubation}
];

export const MENTOR_CONTACT_PROFILE_TYPE = [
    {id: PROFILE_TYPE.Business, name: PROFILE_NAME.Business},
    {id: PROFILE_TYPE.Startup, name: PROFILE_NAME.Startup}
];

export const PURCHASE_TIME = [
    {id: 1, name: '3 Month'},
    {id: 2, name: '6 Month'},
    {id: 3, name: '9 Month'},
    {id: 4, name: '1 Year'},
    {id: 5, name: '2 Year'}
];

export const BANNER_PAGE_LOCATION = {
    BusinessContact: 8,
    StartupContact: 9,
    InvestorContact: 10,
    MentorContact: 11,
    Home: 1
};

export const PROFESSIONAL_EXPERIENCE = [
    {id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8},
    {id: 9}, {id: 10}, {id: 11}, {id: 12}, {id: 13}, {id: 14}, {id: 15},
    {id: 16}, {id: 17}, {id: 18}, {id: 19}, {id: 20}
];

export const ENTITY_TYPE = [
    {id: 1, name: 'Proprietorship'},
    {id: 2, name: 'Partnership'},
    {id: 3, name: 'Limited Liability Company'},
    {id: 4, name: 'Private Limited Company'},
    {id: 5, name: 'Public Limited Company'}
];

export const BUSINESS_TYPE = [
    {id: 1, name: 'B2B'},
    {id: 2, name: 'B2C'},
    {id: 3, name: 'C2C'},
    {id: 4, name: 'C2B'},
    {id: 4, name: 'C2B'},
    {id: 4, name: 'B2B and B2C'}
];

export const EMPLOYEE_COUNT = [
    {id: 1, name: 'less than 10'},
    {id: 2, name: '10-50'},
    {id: 3, name: '50-100'},
    {id: 4, name: '100-500'},
    {id: 5, name: '500-1000'},
    {id: 6, name: 'more than 1000'}
];

export const REPAYMENT_PERIOD = [
    {id: 1, name: '3 Month'},
    {id: 2, name: '6 Month'},
    {id: 3, name: '9 Month'},
    {id: 4, name: '1 Year'},
    {id: 5, name: '2 Year'},
    {id: 6, name: 'more than 2 Year'}
];

export const LOAN_INTEREST = [
    {id: 1, name: '0-5'},
    {id: 2, name: '5-10'},
    {id: 3, name: '10-25'},
    {id: 4, name: '25-35'},
    {id: 5, name: '40-50'},
    {id: 6, name: 'more than 50'}
];

export const SUPPORT_FIELD = [
    {id: 1, name: '3 Month'},
    {id: 2, name: '6 Month'},
    {id: 3, name: '9 Month'},
    {id: 4, name: '1 Year'},
    {id: 5, name: '2 Year'}
];

export const UNSUBSCRIBE_REASON = [
    'Your emails are too frequent',
    'I don\'t remember signing up for this',
    'I no longer want to receive these emails',
    'The emails are spam and should be reported '
];

export const NEWSLETTER_STATUS = {
    CONFIRM_SUSBCRIPTION: 1,
    UNSUBSCRIBE_NEWSLETTER: 2,
};

export const DESIGNATION = [{id: 1, name: 'Director'}, {id: 2, name: 'CEO'}, {id: 3, name: 'Owner'}];

export const INDUSTRY_SECTOR = [{id: 1, name: 'Corporate Professional'}, {id: 2, name: 'Educational Professional'}];

export const FIRM_TYPE = [{id: 1, name: 'Corporate Investment Firm'}, {id: 2, name: 'Private Equity Firm'},
    {id: 3, name: 'Venture Capital Firm'}];

export const NBFC_TYPE = [
    {id: 1, name: 'Asset Finance Company (AFC)'},
    {id: 2, name: 'Investment Company (IC)'},
    {id: 3, name: 'Loan Company (LC)'},
    {id: 4, name: 'Infrastructure Finance Company (IFC)'},
    {id: 5, name: 'Systemically Important Core Investment Company (CIC-ND-SI)'},
    {id: 6, name: 'Infrastructure Debt Fund: Non- Banking Financial Company (IDF-NBFC)'},
    {id: 7, name: 'Non-Banking Financial Company - Micro Finance Institution (NBFC-MFI)'},
    {id: 8, name: 'Non-Banking Financial Company – Factors (NBFC-Factors)'},
    {id: 9, name: 'Mortgage Guarantee Companies (MGC)'},
    {id: 10, name: 'NBFC- Non-Operative Financial Holding Company (NOFHC)'}
];

export const SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE = 'You have used all your insta credits for today.' +
    ' You can only contact any 2 profiles per day.';

export const OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE = 'You have exhausted all your insta credits.';

export const SINGLE_DAY_INSTA_QUERY_LIMIT = 2;
export const OVERALL_INSTA_QUERY_LIMIT = 14;

export const FILTER_RANGE = [50000, 75000, 100000, 200000, 300000, 500000, 1000000, 1500000, 2500000, 3500000, 4500000, 6000000,
    8000000, 10000000, 15000000, 20000000, 50000000, 100000000, 200000000, 300000000, 500000000, 700000000,
    850000000, 1000000000];
