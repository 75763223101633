import {PROFILE_NAME} from '../../config/app-config.constants';

declare var $: any;
declare var jQuery: any;
declare var google: any;

var mySlider;

var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 150 - Math.random() * 100;

    if (this.isDeleting) {
        delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};
export const appConfig = {
    /** Text Ellipsis **/
    ellipsizeTextBox: function (classname, divheight) {
        var el = document.getElementsByClassName(classname);
        [].slice.call(el).forEach(function (clname) {
            var wordArray = clname.innerHTML.split(' ');
            var offsetheight = divheight;
            while (clname.scrollHeight > offsetheight) {
                wordArray.pop();
                clname.innerHTML = wordArray.join(' ') + '...';
                //console.log(clname.innerHTML);
            }
        });
    },
    /********** Page Scroll Animation ******************/
    pagescrollanimation: function () {
        (function ($) {
            $.fn.visible = function (partial) {
                var $t = $(this),
                    $w = $(window),
                    viewTop = $w.scrollTop(),
                    viewBottom = viewTop + $w.height(),
                    _top = $t.offset().top,
                    _bottom = _top + $t.height(),
                    compareTop = partial === true ? _bottom : _top,
                    compareBottom = partial === true ? _top : _bottom;
                return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
            };
        })(jQuery);
        var win = $(window);
        var allMods = $('.sec-slide-effect');
        allMods.each(function (i, el) {
            var el = $(el);
            if (el.visible(true)) {
                el.addClass('already-visible');
            }
        });
        win.scroll(function (event) {
            allMods.each(function (i, el) {
                var el = $(el);
                if (el.visible(true)) {
                    el.addClass('come-in');
                }
            });
        });
    },

    getFormattedValues: function (fieldId, data, isSingle = false) {
        var keys = [];
        if (isSingle) {
            let selecteditem = data.filter(item => (item.name === $(fieldId).val()))[0];
            return (selecteditem !== undefined) ? selecteditem.id : '';
        }
        $(fieldId).val().split(',').forEach(mainItem => {
            let selecteditem = data.filter(item => (item.name === mainItem))[0];
            if (selecteditem !== undefined) {
                keys.push(selecteditem.id + '_' + selecteditem.name + '_' + selecteditem.pid);
            }
        });
        return keys;
    },
    getAvailabileTags: function (data) {
        var aTags = [];
        data.forEach(item => {
            aTags.push(item.name);
        });
        return aTags;
    },
    tagItConfiguration: function (aTags, tagLimit = 5) {
        return {
            tagLimit: tagLimit,
            availableTags: aTags,
            allowSpaces: true,
            showAutocompleteOnFocus: false,
            beforeTagAdded: function (event, ui) {
                if (aTags.indexOf(ui.tagLabel) < 0) {
                    return false;
                }
            }
        };
    },
    locationtypeaccordian: function (event) {
        const currentAttrValue = event.target.id;
        if ($('#' + currentAttrValue).hasClass('active')) {
            this.close_accordion_locationttype();
        } else {
            this.close_accordion_locationttype();
            //  Add active class to section title
            $('#' + currentAttrValue).addClass('active');
            //  Open up the hidden content panel
            $('#' + currentAttrValue + '_1').slideDown(300).addClass('open');
        }
    },
    close_accordion_locationttype: function () {
        $('.location-pnl .accordian-arrow').removeClass('active');
        $('.location-pnl .citylist').slideUp(300).removeClass('open');
    },
    locationtypeaccordianCheck: function (event) {

        if ($(event.target).parent().next(".accordion_bodymain").is(':visible')) {
            $(event.target).parent().next(".accordion_bodymain").slideUp(300);
            $(event.target).parent().children(".rightdown").addClass('rightval');
            $(event.target).parent().children(".rightdown").removeClass('downval');
        } else {
            $(event.target).parent().next(".accordion_bodymain").slideDown(300);
            $(event.target).parent().children(".rightdown").addClass('downval');
            $(event.target).parent().children(".rightdown").removeClass('rightval');
        }
        
    },
    investmenttypeaccordian: function (event) {
        const currentAttrValue = event.target.id;
        if ($('#' + currentAttrValue).hasClass('active')) {
            this.close_accordion_investmenttype();
        } else {
            this.close_accordion_investmenttype();
            //  Add active class to section title
            $('#' + currentAttrValue).addClass('active');
            //  Open up the hidden content panel
            $('#' + currentAttrValue + '_1').slideDown(300).addClass('open');
        }
    },
    close_accordion_investmenttype: function () {
        $('.industries-pnl .accordian-arrow').removeClass('active');
        $('.industries-pnl .citylist').slideUp(300).removeClass('open');
    },
    unique: function (arr) {
        const hash = {};
        const subcatind = [];
        for (let i = 0, l = arr.length; i < l; ++i) {
            if (!hash.hasOwnProperty(arr[i])) {
                hash[arr[i]] = true;
                subcatind.push(arr[i]);
            }
        }
        return subcatind;
    },
    pageScrollAndShortTitle(isBrowser = false) {
        if (isBrowser) {
            setTimeout(() => {
                /** Title Ellipsis **/
                this.ellipsizeTextBox('ttl-ellipsis', 45);
                this.ellipsizeTextBox('sum-ellipsis', 32);
                this.ellipsizeTextBox('sec-ellipsis', 48);
                /** Page Scroll Animation **/
                this.pagescrollanimation();
            }, 500);
        }
    },
    mapTagItConfiguration: function (mapsAPILoader) {
        return {
            allowSpaces: true,
            autocomplete: {
                delay: 0,
                minLength: 2,
                source: (request, response) => {
                    mapsAPILoader.load().then(() => {
                        new google.maps.places.AutocompleteService()
                            .getPlacePredictions({
                                input: request.term,
                                componentRestrictions: {country: 'IN'}
                            }, (predictions, status) => {
                                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                                    return;
                                }
                                response($.map(predictions, (item) => {
                                    return {
                                        label: item.description,
                                        value: item.description
                                    };
                                }));
                            });
                    });
                }
            }
        };
    },
    similarList: function (className, widthClass, isBrowser = false, controls = true) {
        if (isBrowser) {
            setTimeout(() => {
                if (mySlider !== undefined) {
                    if (typeof mySlider.destroySlider === 'function') {
                        mySlider.destroySlider();
                    }
                }
                mySlider = $('.' + className).bxSlider({
                    minSlides: 1,
                    maxSlides: 5,
                    slideWidth: $('.' + widthClass).outerWidth(),
                    slideMargin: 0,
                    controls: controls
                });
                this.ellipsizeTextBox('ttl-ellipsis', 45);
                this.ellipsizeTextBox('sum-ellipsis', 32);
                this.ellipsizeTextBox('sec-ellipsis', 48);
            }, 500);
        }
    },
    getSlug: function (string) {
        if(string){
        return string
            .toString().toLowerCase()
            .replace(/[\s\W-]+/g, '-')     // Replace spaces, non-word characters and dashes with a single dash (-)
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');             // Trim - from end of text
        }
    },
    scrollToError: function (scrollToTop = true) {
        if (scrollToTop) {
            $('html, body').animate({scrollTop: 0}, 'slow');
        }
        setTimeout(function () {
            $('.msg-pnl_sales').delay(0).slideDown('slow');
        }, 50);
    },
    getDocumentName: function (path: string) {
        const documentString = path.split(/[\s/]+/);
        return documentString[path.length - 1];
    },
    getPendingProfileUrl: function (pendingProfile: string, pendingProfileStr: string) {
        if (pendingProfile === PROFILE_NAME.Investor) {
            return '/dashboard/investorConfidentials/' + pendingProfileStr;
        } else if (pendingProfile === PROFILE_NAME.Mentor) {
            return '/dashboard/mentorConfidentials/' + pendingProfileStr;
        } else if (pendingProfile === PROFILE_NAME.Lender) {
            return '/dashboard/lenderConfidentials/' + pendingProfileStr;
        } else if (pendingProfile === PROFILE_NAME.Incubation) {
            return '/dashboard/incubatorConfidentials/' + pendingProfileStr;
        } else if (pendingProfile === PROFILE_NAME.Startup) {
            return '/dashboard/startupConfidentials/' + pendingProfileStr;
        } else if (pendingProfile === PROFILE_NAME.Business) {
            return '/dashboard/businessConfidential/' + pendingProfileStr;
        }
        return '';
    },

    prepareTeamJson: function (selector, noOfElements = 3) {
        let teams = [];
        let counter = 1;
        let member = {};
        $(selector).each(function () {
            member[$(this).attr('name')] = $(this).val();
            if (counter % noOfElements === 0) {
                teams.push(member);
                member = {};
            }
            counter++;
        });
        return teams;
    },
    
    revertSlug: function (string) {
        return string
            .split('-')
            .join(' ')
            .replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
    },
    setNewProfileDetails: function (newUserId, newProfileType, newProfileId) {
        localStorage.setItem('newUserId', newUserId);
        localStorage.setItem('newProfileType', newProfileType);
        localStorage.setItem('newProfileId', newProfileId);
    },
    submitPayFORM: function (path, method, params, clearLocalStorage = false): void {
        const form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        // Move the submit function to another variable
        // so that it doesn't get overwritten.
        form._submit_function_ = form.submit;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        if (clearLocalStorage) {
            localStorage.removeItem('newProfileId');
            localStorage.removeItem('newUserId');
            localStorage.removeItem('newProfileType');
        }
        form._submit_function_();
    },
    sliding: function (event, ui, myData, id1, id2) {
        let minFormat;
        let maxFormat;
        const ui0 = myData[ui.values[0]];
        const ui1 = myData[ui.values[1]];
        if (ui0 >= 10000000) {
            minFormat = (ui0 / 10000000).toFixed(2) + ' cr';
        } else if (ui0 >= 100000) {
            minFormat = (ui0 / 100000).toFixed(2) + ' lakh';
        } else if (ui0 >= 1000) {
            minFormat = (ui0 / 1000).toFixed(2) + ' k';
        } else if (ui0 === 0) {
            minFormat = 0 + '';
        }
        if (ui1 >= 10000000) {
            maxFormat = (ui1 / 10000000).toFixed(2) + ' cr';
        } else if (ui1 >= 100000) {
            maxFormat = (ui1 / 100000).toFixed(2) + ' lakh';
        } else if (ui1 >= 1000) {
            maxFormat = (ui1 / 1000).toFixed(2) + ' k';
        }

        $('#' + id1).html('<i class="fa fa-inr" aria-hidden="true"></i> ' + minFormat);
        $('#' + id2).html('<i class="fa fa-inr" aria-hidden="true"></i> ' + maxFormat);
    },
    PriceSlideFormate: function (value) {
        let formatedvalue;
        const ui0 = value;
        if (ui0 >= 10000000) {
            formatedvalue = (ui0 / 10000000).toFixed(2) + ' cr';
        } else if (ui0 >= 100000) {
            formatedvalue = (ui0 / 100000).toFixed(2) + ' lakh';
        } else if (ui0 >= 1000) {
            formatedvalue = (ui0 / 1000).toFixed(2) + ' k';
        } else if (ui0 === 0) {
            formatedvalue = 0 + '';
        }
        return formatedvalue

    },
    homeSlider: function () {
        setTimeout(function () {
            $('.flexslider').flexslider({
                animation: 'fade',
                controlsContainer: '.flexslider'
            });
            var elements = document.getElementsByClassName('typewrite');
            for (var i = 0; i < elements.length; i++) {
                var toRotate = elements[i].getAttribute('data-type');
                var period = elements[i].getAttribute('data-period');
                if (toRotate) {
                    new TxtType(elements[i], JSON.parse(toRotate), period);
                }
            }
            // INJECT CSS
            var css = document.createElement('style');
            css.type = 'text/css';
            css.innerHTML = '.typewrite > .wrap { border-right: 0.08em solid #fff;}';
            document.body.appendChild(css);
        }, 50);
    },
    accordianBizEquityFaq: function () {
        var acc = document.getElementsByClassName('accordion');
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener('click', function () {
                this.classList.toggle('active');
                var panel = this.nextElementSibling;
                if (panel.style.display === 'block') {
                    panel.style.display = 'none';
                } else {
                    panel.style.display = 'block';
                }
            });
        }
    }
};
