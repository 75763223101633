import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {APP_CONFIG, FIRM_TYPE} from '../../../../config/app-config.constants';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'investor-firmtype',
    templateUrl: './investor-firmtype.component.html'
})
export class InvestorFirmTypeComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    logos;
    profile_str;
    public investorFirmTypedata;
    public investorProfileSuccessError;
    public investorProfileFailError;
    public bxInvestorType;
    public investorAdvertisementdata;
    public company_placeid;
    public company_city;
    public company_state;
    public company_country;
    private isViewChecked = false;
    public company_location: Object;
    public isLookingFor = false;

    getAddress(place: Object) {
        this.company_location = place['formatted_address'];
        var location = place['geometry']['location'];
        this.company_placeid = place['place_id'];
        this.company_city = place['address_components'][0].long_name;
        this.company_state = place['address_components'][2].short_name;
        this.company_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Investor Firmtype Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    firm_types = FIRM_TYPE;

    investorMyAccountSubmit(data) {
        if ($('form[name="investorForm"]').validator('validate').has('.has-error').length || this.isLookingFor) {
            return;
        }
        data.inv_profile_str = this.profile_str;
        data.company_placeid = this.company_placeid;
        data.company_city = this.company_city;
        data.company_state = this.company_state;
        data.company_country = this.company_country;
        data.invest_pref = (data.invest_pref == false) ? 0 : 1;
        data.full_acquisition = (data.full_acquisition == false) ? 0 : 1;
        const formData: any = new FormData();
        if (this.logos != undefined) {
            formData.append('company_logo_path', this.logos[0], this.logos[0].name);
            //console.log(this.logos[0]);
        }
        if (data.company_logo_path_old != '') {
            formData.append('company_logo_path_old', data.company_logo_path_old);
            //console.log(data.company_logo_path_old);
        }
        formData.append('inv_profile_str', this.profile_str);
        formData.append('firm_type', data.firm_type);
        formData.append('invest_pref', data.invest_pref);
        formData.append('invest_size_min', data.invest_size_min);
        formData.append('invest_size_max', data.invest_size_max);
        formData.append('invest_stake', data.invest_stake);
        formData.append('full_acquisition', data.full_acquisition);
        formData.append('purchase_capacity_min', data.purchase_capacity_min);
        formData.append('purchase_capacity_max', data.purchase_capacity_max);
        formData.append('inv_abt_urself', data.inv_abt_urself);
        formData.append('linkedin_profile', data.linkedin_profile);
        formData.append('company_name', data.company_name);
        formData.append('company_designation', data.company_designation);
        formData.append('company_placeid', this.company_placeid);
        formData.append('company_city', this.company_city);
        formData.append('company_state', this.company_state);
        formData.append('company_country', this.company_country);
        formData.append('company_pincode', data.company_pincode);
        formData.append('company_website', data.company_website);
        formData.append('company_summary', data.company_summary);

        this.http.post(this.config.apiEndpoint + '/updateinvestorFirm/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Firm Type Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.investorProfileSuccessError = data;
                $("html, body").animate({scrollTop: 0}, "slow");
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.investorProfileFailError = "Please Enter Correct Details";
            });
    }

    logoChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.logos = fileInput.target.files;
        }
    }

    getinvestorFirmType(profile_str: any): void {
        this.myprofileService.getinvestorFirmType(this.profile_str)
            .then((result) => {
                this.investorFirmTypedata = result;
                if (this.investorFirmTypedata.invest_pref) {
                    $(".investment").slideToggle().find('input, textarea, select').attr('required', 'required');
                }
                if (this.investorFirmTypedata.full_acquisition) {
                    $(".acquisition").slideToggle().find('input, textarea, select').attr('required', 'required');
                }
            });
    }

    getinvestorAdvDetails(profile_str: any): void {
        this.myprofileService.getinvestorAdvDetails(this.profile_str)
            .then((result) => {
                this.investorAdvertisementdata = result;
            });
    }

    getcheckBxInvestorType(userID: any, profile_str: any): void {
        this.myprofileService.getcheckBxInvestorType(this.userID, this.profile_str)
            .then((result) => {
                this.bxInvestorType = result;
            });
    }

    ngOnInit() {
        this.getinvestorFirmType(this.profile_str);
        this.getinvestorAdvDetails(this.profile_str);
        this.getcheckBxInvestorType(this.userID, this.profile_str);
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="investorForm"]').validator();
        }
    }

    isChecked(inputName, toggleClass) {
        this.isLookingFor = ($('.other-detail input[type="checkbox"]:checked').length <= 0);
        if ($('input[name="' + inputName + '"]').is(':checked')) {
            $('.' + toggleClass).slideToggle().find('input, textarea, select').attr('required', 'required');
        } else {
            $('.' + toggleClass).slideToggle().find('input, textarea, select').removeAttr('required');
        }
        $('form[name="investorForm"]').validator('update');
    }

}
