import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {MyProfileService} from '../../../../services/my-profile.service';
import {PROFILE_ARRAY} from "../../../../config/app-config.constants";
import {isPlatformBrowser} from "@angular/common";

declare var $: any;

@Component({
    selector: 'broker-listing',
    templateUrl: './broker-listing.component.html'
})
export class BrokerBusinessListingComponent implements OnInit {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    ProfileSelected;
    public brokerBusiness;
    public brokerBusinessLength;

    constructor(meta: Meta, title: Title, private myprofileService: MyProfileService,
                private http: HttpClient,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Broker Business Listing - BusinessEx');
    }

    getBrokerBusiness(): void {
        this.myprofileService.getBrokerListingDetails(this.userID)
            .then((result) => {
                if (result) {
                    this.brokerBusiness = result.brokerdata;
                    this.brokerBusinessLength = this.brokerBusiness.length;
                }
            });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
        }
        this.getBrokerBusiness();
    }

}
