import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APP_CONFIG, BANNER_PAGE_LOCATION, BASE_URL, BUSINESS_CONTACT_PROFILE_TYPE, OVERALL_INSTA_QUERY_LIMIT, OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE, PROFILE_ARRAY, PROFILE_REGISTRATION_URL, PROFILE_TYPE, PURCHASE_TIME, SINGLE_DAY_INSTA_QUERY_LIMIT, SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { BUSINESS_IMAGES } from '../../../../static-data/business-images';
import { appConfig } from '../../app.config';

  declare var $: any;

@Component({
  selector: 'app-startup-contact',
  templateUrl: './startup-contact.component.html',
  styleUrls: ['./startup-contact.component.css']
})
export class StartupContactComponent implements OnInit {

  jwtHelper = new JwtHelperService();
    token;
    decode;
    userID;
    Profile;
    ProfileCheck;
    profile_str;
    startupProfileData;
    startupImages;
    startupDocuments;
    category;
    instaContactError;
    instaProposalError;
    public startupDataList;
    public startupmgtTeamDetail = [];
    public startupfundRaisingDetail;
    public SearchHistorydata;
    public SavedSearchdata;
    public bxContactCount;
    public bxProposalResult;
    public profileCreditBalance = 1; // by default user can send proposal
    public bxBookmarkCount;
    public bxBookmarkStatus;
    public isViewChecked = false;
    public bxbannertop;
    public bxbannerrightside;
    title = 'Contact Startup';
    profileMembership = 0;
    startupProfileExistCheck = '';
    profileActiveStatus = '';
    profilePendingStatus = '';
    pendingProfileStr;
    invUpgProfUniqStr;
    loading = false;

    contact_profile_type = BUSINESS_CONTACT_PROFILE_TYPE;
    quick_profile_type = PROFILE_ARRAY;
    purchase_time = PURCHASE_TIME;
    public appConfig = appConfig;
    seoImageUrl = '';
    isFreeCreditExpired = false;
    public singleDayCount;
    public totalCount;
    contactLimitExceedMessage = SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE;
    singleDayLimit = SINGLE_DAY_INSTA_QUERY_LIMIT;
    overallLimit = OVERALL_INSTA_QUERY_LIMIT;
    creditBalance = [];
    moderatorContactStatus = 0; // status 4 means: profile users are not interested to make payment but allowing them to send insta query

    constructor(private linkService: LinkService,
                private meta: Meta, private titleService: Title,
                public http: HttpClient, private contactService: ContactService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private route: ActivatedRoute,
                private router: Router,
                @Inject(APP_CONFIG) private config: IAppConfig) {
    }

    checkLoginToken(): void {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.userID = this.decode.sub;
            this.Profile = this.decode.userProfile;
            this.ProfileCheck = this.decode.userProfile[0].profile_id;
            this.saveSearchHistory();
            this.checkBxContactBusiness();
            this.checkBxProposalCount();
            this.checkBxBookmark();
            this.getProfileData();
        } else {
            this.Profile = [{
                'profile_id': 0,
                'profile_type': '',
                'profile_status': '',
                'profile_name': '',
                'profile_str': '',
                'payment_status': 0
            }];
            this.ProfileCheck = this.Profile[0].profile_id;
            this.userID = 0;
            this.bxContactCount = 0;
            if (isPlatformBrowser(this.platformId)) {
                $('#login').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                $('#login .close').css('display', 'none');
            }
        }
    }

    toggle(wantBookMark): any {
        this.bxBookmarkStatus = wantBookMark;
        this.contactService.saveSavedSearches(this.userID, PROFILE_TYPE.Startup, this.profile_str, wantBookMark)
            .then((result) => {
                this.SavedSearchdata = result;
            });
    }

    getProfileData(): any {
        this.Profile.forEach((key) => {
            if (key.profile_type === PROFILE_TYPE.Startup && key.profile_str === this.profile_str) {
                this.startupProfileExistCheck = 'UserProfile';
            }
            if (key.profile_type === PROFILE_TYPE.Investor || key.profile_type === PROFILE_TYPE.Lender
                || key.profile_type === PROFILE_TYPE.Mentor || key.profile_type === PROFILE_TYPE.Incubation) {
                if (key.profile_status === 1) {
                    this.profileActiveStatus = key.profile_name;
                    this.invUpgProfUniqStr = key.profile_str;
                    this.profileMembership = key.membership_type;
                } else {
                    this.profilePendingStatus = key.profile_name;
                    this.pendingProfileStr = key.profile_str;
                    this.moderatorContactStatus = key.moderatorContactStatus;
                }
            }
        });
    }

    contactProposalFormSubmit(data, receiverId, profileStr) {
        const formSelector = $('form[name="contactProposalForm"]');
        if (formSelector.validator('validate').has('.has-error').length || this.profileCreditBalance <= 0) {
            return;
        }
        data.sender = this.userID;
        data.receiver = receiverId;
        data.profileStr = profileStr;
        data.sender_profile_type = data.ProfileSelected;
        data.receiver_profile_type = PROFILE_TYPE.Startup;
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/contactProposal', data).subscribe(
            dataResponse => {
                $('#startupproposalForm .form-control').val('');
                $('#startupproposalForm').css('display', 'none');
                $('#thankyouproposal').css('display', 'block');
            },
            error => {
                this.instaProposalError = error.error;
                appConfig.scrollToError(false);
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    contactProfileTypeSubmit(data) {
        if ($('form[name="contactProfileTypeForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        this.router.navigate([PROFILE_REGISTRATION_URL[data.profile_type]]);
    }

    contactFormStartupSubmit(data, profileId, profileStr) {
        const formSelector = $('form[name="contactFormStartup"]');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        data.user_id = this.userID;
        data.profile_id = profileId;
        data.profile_str = profileStr;
        formSelector.find('button[type="submit"]').button('loading');
        data.subscribe = (data.subscribe === false) ? 0 : 1;
        this.http.post(this.config.apiEndpoint + '/contactthestartup', data).subscribe(
            (response) => {
                // storing the new token in local storage. No need to logout and login for loading new profiles of user
                /*localStorage.removeItem('currentUser');
                localStorage.setItem('currentUser', JSON.stringify(response['token']));
                this.token = localStorage.getItem('currentUser');
                this.decode = this.jwtHelper.decodeToken(this.token);
                this.userID = this.decode.sub;
                this.Profile = this.decode.userProfile;*/

                $('#contactForm .form-control').val('');
                $('#contactform').css('display', 'none');
                $('#contactthankyou').css('display', 'block');
            },
            error => {
                this.instaContactError = error.error;
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    imageFunction(parentCatId: any): any {
        parentCatId = (parentCatId === null || parentCatId === '') ? 5 : parentCatId;
        const images = BUSINESS_IMAGES.filter(
            item => item.parentId === parentCatId)[0];
        return images.imageSet;
    }

    getStartupSimilar(): void {
        this.contactService.getStartupSimilar(this.profile_str)
            .then((result) => {
                this.startupDataList = result.startupSimilarData;
            });
    }

    saveSearchHistory(): void {
        this.contactService.saveSearchHistory(this.userID, PROFILE_TYPE.Startup, this.profile_str)
            .then((result) => {
                this.SearchHistorydata = result;
            });
    }

    checkBxContactBusiness(): void {
        this.contactService.checkBxContactStartup(this.userID, this.profile_str)
            .then((result) => {
                this.bxContactCount = result.count;
                this.singleDayCount = result.singleDayCount;
                this.totalCount = result.totalCount;
                if (this.totalCount >= OVERALL_INSTA_QUERY_LIMIT) {
                    this.contactLimitExceedMessage = OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE;
                }
            });
    }

    checkBxProposal(): void {
        this.contactService.checkBxProposal(this.userID, PROFILE_TYPE.Startup, this.profile_str, this.startupProfileData.userId)
            .then((result) => {
                this.bxProposalResult = result;
            });
    }

    checkBxProposalCount(): void {
        this.contactService.checkBxProposalCount(this.userID, [PROFILE_TYPE.Business, PROFILE_TYPE.Startup])
            .then((result) => {
                this.creditBalance = result.creditBalance;
            });
    }

    checkBxBookmark(): void {
        this.contactService.checkBxBookmark(this.userID, PROFILE_TYPE.Startup, this.profile_str)
            .then((result) => {
                this.bxBookmarkCount = result.count;
                this.bxBookmarkStatus = result.checked;
            });
    }

    getBanners(): void {
        this.contactService.getBanners(BANNER_PAGE_LOCATION.StartupContact)
            .then((result) => {
                this.bxbannerrightside = result.right;
                this.bxbannertop = result.top;
            });
    }

    ngOnInit() {
        this.loading = true;
        this.route.paramMap.subscribe((params: Params) => {
            this.getBanners();
            this.category = params.get('category');
            this.profile_str = params.get('sellerUniqueId');

            // updating startup profile view count in case of  mailer campagain
            this.route.queryParams.subscribe(params1 => {
                if (params1['mc'] !== undefined && atob(params1['mc']) === this.profile_str) {
                    this.contactService.updateProfileViewCount(this.profile_str, PROFILE_TYPE.Startup)
                        .then((result) => {
                        });
                }
            });

            this.checkLoginToken();
            this.contactService.getStartupContactDetails(this.profile_str, this.userID)
                .then((result) => {
                    if (result) {
                        this.startupProfileData = result.startupData;
                        this.startupmgtTeamDetail = result.mgtTeamDetail;
                        this.startupfundRaisingDetail = result.fundRaisingDetail;
                        this.startupDocuments = result.startupDocuments;
                        this.startupImages = result.startupImages;
                        //console.log(this.startupImages);
                        this.startupfundRaisingDetail = this.startupProfileData.fundRaisingDetail;
                        if (this.startupProfileData.slug !== this.category ||
                            this.startupProfileData.businessUniqStr !== this.profile_str) {
                            this.router
                                .navigate(['/startup/' + this.startupProfileData.slug + '/' + this.startupProfileData.businessUniqStr]);
                        }
                        this.category = this.startupProfileData.subindustry;
                        if (Object.keys(result.startupImages).length === 0) {
                            this.startupImages = this.imageFunction(this.startupProfileData.parentCatId);
                            this.seoImageUrl = BASE_URL + '/' + this.startupImages[0].imageUrl;
                        } else {
                            this.seoImageUrl = this.startupImages[0].imageUrl;
                        }
                        this.checkBxProposal();
                        this.setTitleMeta();
                        this.getStartupSimilar();
                    }
                    this.loading = false;
                }, (error) => {
                    if (error.status === 404) {
                        this.router.navigate(['/startupslisting']);
                    }
                    this.loading = false;
                });
        });
    }

    private setTitleMeta() {

        /*const description = 'It\'s important to be at the right place, at the right time! ' +
            'You have a ' + this.category + ' business, we have the ' +
            'ammunition to make you a starlet brand.';*/

            
        const description = this.startupProfileData.description.substring(0, 400)

        this.titleService.setTitle(this.startupProfileData.title + '- BusinessEx');
        this.meta.updateTag({
            name: 'Keywords',
            content: 'Startup ' + this.category + ''
        });
        this.meta.updateTag(
            {
                name: 'Description',
                content: description
            },
        );

        /*this.meta.updateTag({property: 'site_name', content: 'BusinessEx'});
        this.meta.updateTag({property: 'og:title', content: this.startupProfileData.title});
        // this.meta.updateTag({property: 'og:type', content: 'article'});
        this.meta.updateTag({property: 'og:url', content: BASE_URL + this.router.url});
        this.meta.updateTag({property: 'og:image', content: this.seoImageUrl});
        this.meta.updateTag({property: 'og:image:secure_url', content: this.seoImageUrl});
        this.meta.updateTag({property: 'og:description', content: description});
        this.meta.updateTag({property: 'og:image:width', content: '770'});
        this.meta.updateTag({property: 'og:image:height', content: '434'});

        this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.meta.updateTag({property: 'twitter:title', content: this.startupProfileData.title});
        this.meta.updateTag({name: 'twitter:image', content: this.seoImageUrl});
        this.meta.updateTag({name: 'twitter:description', content: description});
        this.meta.updateTag({name: 'twitter:url', content: BASE_URL + this.router.url});
        this.meta.updateTag({name: 'twitter:image:src', content: this.seoImageUrl});
        this.meta.updateTag({name: 'twitter:site', content: '@BusinesEx'});
        this.meta.updateTag({name: 'twitter:domain', content: 'businessex.com'});
        this.meta.updateTag({name: 'twitter:creator', content: '@businesex'});*/

        this.linkService.removeCanonicalLink();
        this.linkService.addTag({
            rel: 'canonical',
            href: BASE_URL + this.router.url
        });

    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="contactFormStartup"]').validator();
            $('form[name="contactProposalForm"]').validator();
            $('form[name="contactProfileTypeForm"]').validator();
        }
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            $('#login').modal('hide');
        }
    }

    onChangeObj($event) {
        if (this.Profile === undefined) {
            return;
        }
        const selectedProfile = this.Profile.filter(item => (item.profile_type == $event))[0];
        localStorage.setItem('ProfileType', selectedProfile.profile_name); // need to show the appropriate payment plan
        const selectedProfileCredits = this.creditBalance.filter(item => (item.profileType == $event))[0];
        this.isFreeCreditExpired = (parseInt(selectedProfile.isFreeCreditExpired, 10) === 1);
        this.profileCreditBalance = (selectedProfileCredits !== undefined) ? selectedProfileCredits.interactionCreditBalance : 0;
    }


}
