import {AfterViewChecked, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MyProfileService} from '../../../../services/my-profile.service';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, PROFILE_TYPE} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {Meta, Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {NgForm} from '@angular/forms';
import {ChatService} from '../../../../services/chat.service';
import {ActivatedRoute, Router} from '@angular/router';

declare var $: any;

@Component({
    selector: 'bx-mail',
    templateUrl: './bx-mail.component.html'
})
export class BxMailComponent implements OnInit, AfterViewChecked, OnDestroy {
    jwtHelper = new JwtHelperService();
    public selectedUser;
    public msgtext;
    public msgs = [];
    public msf;
    public userChatList;
    public to_id;
    sendtext;
    files = [];
    public isViewChecked = false;
    token;
    decode;
    profileType;
    profileObjects;
    uid;
    public connection;
    senderProfile;
    contactedContacts = [];
    public profiles = PROFILE_TYPE;
    messageLoading: boolean;

    constructor(meta: Meta, title: Title, private myprofileService: MyProfileService,
                private http: HttpClient, @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object,
                private chatService: ChatService,
                private route: ActivatedRoute,
                private router: Router,
                private myProfileService: MyProfileService) {
        this.files = [];
        title.setTitle('BX Interaction - BusinessEx');

    }

    getUserChatList(): void {
        this.myprofileService.getuserChatlist(this.uid)
            .then((result) => {
                this.userChatList = result;
                if (this.route.snapshot.queryParams['rid']) {
                    setTimeout(() => {
                        const requestId = +this.route.snapshot.queryParams['rid'];
                        const divSelector = $('#' + requestId + ' div');
                        // if request_id is invalid, send to dashboard
                        if (divSelector.length === 0 || !Number.isInteger(requestId)) {
                            this.router.navigate(['/dashboard/myaccount']);
                        }
                        divSelector.trigger('click');
                    }, 50);
                }
            });

    }

    /* Function To show selected user from chat list*/
    highLightUser(selectedUser) {
        this.messageLoading = true;
        this.msgs = [];
        $('.msg-list li').removeClass('active');
        // $(".tab").addClass("active"); // instead of this do the below
        $('#' + selectedUser.request_id).addClass('active');

        this.selectedUser = selectedUser;
        this.senderProfile = this.decode.userProfile.filter(item => (item.profile_type === this.selectedUser.userProfileType))[0];
        this.msgtext = selectedUser.msgtext;
        this.contactedContacts.push(selectedUser.request_id);
        this.myprofileService.getMsg(selectedUser.request_id)
            .then((result) => {
                if (result) {
                    this.messageLoading = false;
                    this.msgs = result;
                    if (this.msgs.length > 0) {
                        this.scrollToChat();
                    }
                }
            });
        if ($(window).width() < 768) {
            $('.ph-fxd').css('right', '0');
            $('body').css('overflow', 'hidden');
        }
        $('#' + selectedUser.request_id).removeClass('blink_me unread');
    }

    send_msg(form: NgForm): void {
        const formSelector = $('form[name="bxFormmsg"]');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        const formData: any = new FormData();
        for (let i = 0; i < this.files.length; i++) {
            formData.append('file[' + i + ']', this.files[i], this.files[i].name);
        }
        formData.append('msg', form.value.sendtext);
        formData.append('from_id', this.uid);
        formData.append('to_id', this.selectedUser.id);
        formData.append('request_id', this.selectedUser.request_id);
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/saveMsgs', formData).subscribe(
            response => {
                this.msf = response;
                const _deliveredMessage = {
                    msg: this.msf.msg,
                    from_id: this.uid,
                    to_id: this.selectedUser.id,
                    files: this.msf.files,
                    timestamp: this.msf.timestamp,
                    request_id: this.selectedUser.request_id,
                    name: this.decode.name,
                    profileName: this.senderProfile.profile_name,
                    profilepic: this.decode.profile_pic,
                    profilelink: this.senderProfile.profile_link,
                    msgId: this.msf.msgId,
                };
                // broadcasting the message to chat server if connected
                if (this.chatService.isServerOn()) {
                    this.chatService.sendMessage(_deliveredMessage);
                } else {
                    this.msgs.push(_deliveredMessage);
                }

                // sending mail if receiver is offline
                if (this.selectedUser.isUserOnline === 0) {
                    this.http.post(this.config.apiEndpoint + '/sendMailToOfflineUser', formData)
                        .subscribe(responseNew => { }
                    );
                }

                this.scrollToChat();
                this.sendtext = '';
                this.files = [];
                form.reset();
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    fileChangeEvent(fileInput: any) {
        for (let key in fileInput.target.files) {
            if (typeof fileInput.target.files[key] === 'object') {
                this.files.push(fileInput.target.files[key]);
            }
        }
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.profileType = this.decode.userProfile;
            this.profileObjects = this.profileType;
            this.uid = this.decode.sub;
            this.getUserChatList();
            // receiving the message send by chat server
            this.connection = this.chatService.getMessages(this.uid).subscribe(message => {
                // this is for one to one communication
                if (this.selectedUser && message['request_id'] === this.selectedUser.request_id) {
                    this.msgs.push(message);
                    this.scrollToChat();
                } else {
                    // 1. user not select any contact to chat and other user sent him a message
                    // 2. the selected user is not one who send the message
                    // in both the condition highlighting the sender in the top of list
                    const restContact = this.userChatList.filter(item => (item.request_id !== message['request_id']));
                    const senderContact = this.userChatList.filter(item => (item.request_id === message['request_id']))[0];
                    if (senderContact !== undefined && senderContact['request_id'] === message['request_id']) {
                        senderContact.msgtext = message['msg'];
                        senderContact.readstatus = 1;
                        senderContact.msgId = message['msgId'];
                        senderContact.timestamp = message['timestamp'];
                        restContact.push(senderContact);
                        this.userChatList = restContact;
                        $('#' + message['request_id']).addClass('blink_me');
                        setTimeout(() => {
                            $('.msg-list').scrollTop($('.msg-list ul li')[0]);
                        }, 50);
                    }
                }
            });
            this.updateOnlineUsers();
            const winWidth = $(window).width();
            if (winWidth < 768) {
                $('.ph-fxd').css('right', '-' + winWidth + 'px');
                $('.ph-arrow').click(function () {
                    $('.ph-fxd').css('right', '-' + winWidth + 'px');
                    $('body').css('overflow', 'visible');
                });
            }
            $('.attachment-file a').click(function (event) {
                event.preventDefault();
                $(this).fadeOut(300, function () {
                    $(this).parents('li').remove();
                });
            });
            $('.bx-hide').css('display', 'none');
        }
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            $('.width-calc').css('width', $('.pos-rel').width());
            $('.faq-container').css('width', $('.faq-container').outerWidth());
            this.isViewChecked = true;
            $('form[name="bxFormmsg"]').validator();
        }
    }

    private scrollToChat() {
        setTimeout(() => {
            const _selector = $('#msg-bdy-list');
            _selector.scrollTop(_selector[0].scrollHeight);
        }, 50);
    }

    removeUploadEvent(fileName) {
        this.files = this.files.filter(item => (item.name !== fileName));
    }

    ngOnDestroy() {
        // changing message readStatus of those contact which is selected by the user
        this.myProfileService.getupdateBxinboxNotification(this.uid, this.contactedContacts).then(() => {
        });
        // this.connection.unsubscribe();
    }

    isSubmit = function (e) {
        // submit form when user hit enter key
        if (e.which === 13 && !e.shiftKey) {
            $('form[name="bxFormmsg"] button[type="submit"]').trigger('click');
            e.preventDefault();
            return false;
        }
    };

    private updateOnlineUsers() {
        this.chatService.getOnlineUsers().subscribe(_onlineUsers => {
            const onlineUsers = _onlineUsers['users'];
            onlineUsers.splice(onlineUsers.indexOf(this.uid.toString()), 1);
            setTimeout(() => {
                if (this.userChatList !== undefined) {
                    const otherContact = this.userChatList;
                    otherContact.forEach(item => {
                        if (onlineUsers.indexOf(item['id'].toString()) > -1) {
                            item.isUserOnline = 1;
                        } else {
                            item.isUserOnline = 0;
                        }
                    });
                    this.userChatList = otherContact;
                }
            }, 50);
        });
    }

}


