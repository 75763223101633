import {Component, Inject} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ContactService} from '../../../../services/contact.service';
import {ActivatedRoute} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from "../../../../config/app-config.constants";
import {IAppConfig} from "../../../../config/app-config.interface";
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from "@angular/common/http";


declare var $: any;

@Component({
    selector: 'investor-profile',
    templateUrl: './investor-profile.component.html'
})
export class MyInvestorProfileComponent {

    profile_str;
    jwtHelper = new JwtHelperService();
    users = {};
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileType = this.decode.userProfile;
    profileObjects = this.profileType;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    investorContactData;
    public investorProfileData;
    public investorLocPreference;
    public investorIndPreference;

    constructor(meta: Meta, title: Title, private http: HttpClient, private contactService: ContactService, private myprofileService: MyProfileService, route: ActivatedRoute, @Inject(APP_CONFIG) private config: IAppConfig) {
        title.setTitle('Investor Profile Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );

        this.getInvestorContactDetails(this.profile_str);

    }

    getInvestorContactDetails(profile_str: any): void {
        this.contactService.getInvestorContactDetails(this.profile_str)
            .then((result) => {
                this.investorContactData = this.jwtHelper.decodeToken(result);
                this.investorProfileData = this.investorContactData.investordata;
                this.investorLocPreference = this.investorContactData.locpref;
                this.investorIndPreference = this.investorContactData.industryPref;
            });
    }

}
