
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE PRIVATE LENDER INFORMATION
                                </div>



                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/lenderConfidentials/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/lenderAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li class="selected" *ngIf="bxLenderType?.type==2"><a routerLink="/dashboard/privateLender/{{profile_str}}">Private Lender Info</a></li>
                                        <li *ngIf="bxLenderType?.type==3"><a routerLink="/dashboard/nbfcLender/{{profile_str}}">NBFC Personnel Info </a></li>
                                        <li><a routerLink="/dashboard/lenderPreference/{{profile_str}}">Preferences</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="lenderForm" #lenderForm="ngForm"
                                              (ngSubmit)="lenderForm.form.valid && lenderMyAccountSubmit(lenderForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="lenderProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{lenderProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="lenderProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{lenderProfileFailError}}
                                            </div>

                                            <!--For Private Lender Start here -->
                                            <div class="sec-slide-effect">
                                                <!-- <div class="row frm-sec-ttl">
                                                    <div class="col-xs-12 col-sm-6 col-md-4">For Private Lender</div>
                                                </div> -->
                                                <div class="input-sec">

                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Occupation<span
                                                                class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" name="lender_occupation"
                                                                       [ngModel]="privateLenderdata?.lender_occupation"
                                                                       class="form-control"
                                                                       placeholder="Enter your Occupation"
                                                                       data-error="Please enter occupation." required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Lending
                                                            Capacity </label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" pattern="[0-9]+" minlength="5"
                                                                       maxlength="12" name="lending_capacity"
                                                                       [ngModel]="privateLenderdata?.lending_capacity"
                                                                       class="form-control" placeholder="Enter %"
                                                                       data-error="Please enter lending capacity."
                                                                       required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Lending
                                                            Interest Rate </label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" pattern="[0-9]+" minlength="1"
                                                                       maxlength="2" name="lending_interest_rate"
                                                                       [ngModel]="privateLenderdata?.lending_interest_rate"
                                                                       class="form-control" placeholder="Enter %"
                                                                       data-error="Please enter interest rate."
                                                                       required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <!--<div class="form-group">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Private Lender Location Preference </label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <ul id="courseLocation1"></ul>
                                                            <ul id="result-list"></ul>
                                                        </div>
                                                    </div>-->
                                                    <!-- <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Sector Preference<span class="star">*</span> </label>
                                                        <span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group tl-tip">
                                                                <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Enter your text"><i class="fa fa-info" aria-hidden="true"></i></div>
                                                    -->
                                                    <!-- <p-autoComplete name="my_ind_pref" #my_ind_pref="ngModel" ngModel [suggestions]="filteredIndustriesMultiple" (completeMethod)="filterIndustryMultiple($event)" styleClass="wid100"
                                                [minLength]="1" placeholder="Add Sector Preference" field="name" [multiple]="true">
                                                    </p-autoComplete> -->
                                                    <!--   <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                   </div>
                                                   <div class="help-block with-errors"></div>
                                               </div>
                                           </div> -->

                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Professional
                                                            Summary </label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <textarea class="form-control" name="prof_summary"
                                                                          [ngModel]="privateLenderdata?.prof_summary"
                                                                          rows="3"
                                                                          placeholder="Write Professional Summary"
                                                                          data-error="Please enter professional summary."
                                                                          required></textarea>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Profile
                                                            Picture</label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <img *ngIf="privateLenderdata?.profile_pic_path != '' && privateLenderdata?.profile_pic_path != null" src="{{privateLenderdata?.profile_pic_path}}" height="150" width="150">
                                                            <div class="input-group image-preview">
                                                                <input type="text"
                                                                       class="form-control image-preview-filename"
                                                                       placeholder="Accepted formats - png, jpeg, gif"
                                                                       disabled="disabled">
                                                                <input type="hidden" name="profile_pic_path_old" [ngModel]="privateLenderdata?.profile_pic_path">
                                                                <span class="input-group-btn">
                                        <button type="button" class="btn btn-default rm-img image-preview-clear"
                                                style="display:none;">
                                                <span class="glyphicon glyphicon-remove"></span> Clear
                                            </button>
                                                                    <!-- image-preview-input -->
                                        <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                            <span class="glyphicon glyphicon-folder-open"
                                                  style="margin-top: 5px;"></span>
                                            <span class="image-preview-input-title"
                                                  style="margin-left: 5px;">Browse</span>
                                            <input type="file" accept="image/png, image/jpeg, image/gif"
                                                   (change)="fileChangeEvent($event)" name="input-img-preview"/>
                                            <!-- rename it -->
                                        </div>
                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <!--For Private Lender Start here -->

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->

