import {AfterContentInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MyProfileService} from '../../../../services/my-profile.service';
import {Meta, Title} from '@angular/platform-browser';
import {chart} from 'highcharts';
import {isPlatformBrowser} from '@angular/common';
import {PROFILE_ARRAY} from '../../../../config/app-config.constants';
import {NonBrokerProfileTypeFilter} from '../../../../pipes/non-broker-profile-type-filter.pipe';

declare var $: any;

@Component({
    templateUrl: './profile-view-chart.component.html',
    providers: [NonBrokerProfileTypeFilter]
})
export class ProfileViewChartComponent implements OnInit, AfterContentInit {
    jwtHelper = new JwtHelperService();
    token: any;
    decode;
    profileType;
    profileObjects;
    userID;
    notifycreatedate;
    reg_source;
    activeMenu;
    totalclickthreemonth;
    vistorlist;
    totalclick;
    dateclick: any = [];
    profileName;
    lineChartYData;
    lineChartXData;
    clicks: any = [];
    public contactHistory;
    public ProfileSelected;
    public bxInvestorType;
    public bxProfileView;
    public bxProfileViewlength;
    addOnProfileView = 0;
    @ViewChild('chartTarget') chartTarget: ElementRef;
    chart: Highcharts.ChartObject;
    searchlimit = 5;
    public userRegisterProfileType;

    constructor(meta: Meta, title: Title, private myprofileService: MyProfileService,
                @Inject(PLATFORM_ID) private platformId: Object, private nonBrokerProfileTypeFilter: NonBrokerProfileTypeFilter) {
        title.setTitle('Profile View - BusinessEx');
    }

    check(): void {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.profileType = this.decode.userProfile;
            this.profileObjects = this.profileType;
            this.userID = this.decode.sub;
            this.notifycreatedate = this.decode.notify_date;
            this.typecheck();
            // this.getVisitorSeller(this.profileTypeId, this.profile_str);
            this.getProfileView(this.ProfileSelected.profile_type, this.ProfileSelected.profile_str);
        }
    }

    typecheck() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.userRegisterProfileType = localStorage.getItem('userRegisterProfileType');
            // in case of broker logged in, excluding broker profile
            this.profileObjects = this.nonBrokerProfileTypeFilter.transform(this.profileObjects);
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
            if (this.ProfileSelected.profile_type === 2) {
                this.getcheckBxInvestorType();
            }
        }
    }

    getVisitorSeller(profileType, profileString): void {
        this.myprofileService.getVisitorSeller(profileType, profileString)
            .then((result) => {
                this.totalclickthreemonth = result.threemoncount;
                this.vistorlist = result.vistor;
                this.vistorlist.forEach((eachObj) => {
                    this.clicks.push(eachObj.total);
                    this.dateclick.push(eachObj.dt);
                    this.totalclick += eachObj.total;
                });

                const data = {
                    'xData': this.dateclick,
                    'yData': [{
                        'name': this.profileName,
                        'data': this.clicks
                    }]
                };

                this.lineChartYData = data.yData;
                this.lineChartXData = data.xData;

                const options: Highcharts.Options = {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: this.dateclick
                    },
                    yAxis: {
                        title: {
                            text: 'Hit'
                        }
                    },
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: [{
                        name: '',
                        data: this.clicks
                    }]
                };

                this.chart = chart(this.chartTarget.nativeElement, options);
            });
    }

    getcheckBxInvestorType(): void {
        this.myprofileService.getcheckBxInvestorType(this.userID, this.ProfileSelected.profile_str)
            .then((result) => {
                this.bxInvestorType = result;
            });
    }

    getProfileView(profileType, profileString): void {
        this.myprofileService.getProfileView(profileType, profileString, this.userID)
            .then((result) => {
                this.bxProfileView = result.profileViews;
                this.addOnProfileView = result.addOnProfileView;
                this.bxProfileViewlength = this.bxProfileView.length;
            });
    }


    bxProfileViewLoadMore(bxProfileView) {
        this.bxProfileView = bxProfileView;
        const increamented = this.searchlimit + 3;
        if (increamented > this.bxProfileView.length) {
            this.searchlimit = this.bxProfileView.length;
            $('#bxProfileView').hide();
        } else {
            this.searchlimit = increamented;
        }
    }

    ngOnInit() {
        this.check();
    }

    ngAfterContentInit(): void {
        this.getVisitorSeller(this.ProfileSelected.profile_type, this.ProfileSelected.profile_str);
    }

    onChangeObj(profiletype) {
        // its only for broker. he can select any business, registered under him.
        this.getVisitorSeller(profiletype.profile_type, profiletype.profile_str);
        this.getProfileView(profiletype.profile_type, profiletype.profile_str);
    }
}
