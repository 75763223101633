export const STATE = {
    'Andhra Pradesh': 'AP',
    'Himachal Pradesh': 'HP',
    'Assam': 'AS',
    'Bihar': 'BR',
    'Chhattisgarh': 'CG',
    'Goa': 'GA',
    'Gujarat': 'GJ',
    'Haryana': 'HR',
    'Jammu and Kashmir': 'JK',
    'Jharkhand': 'JH',
    'Karnataka': 'KA',
    'Kerala': 'KL',
    'Madhya Pradesh': 'MP',
    'Maharashtra': 'MH',
    'Manipur': 'MN',
    'Meghalaya': 'ML',
    'Mizoram': 'MZ',
    'Nagaland': 'NL',
    'Orissa': 'OR',
    'Punjab': 'PB',
    'Rajasthan': 'RJ',
    'Sikkim': 'SK',
    'Tamil Nadu': 'TN',
    'Telangana': 'TS',
    'Tripura': 'TR',
    'Uttarakhand': 'UK',
    'West Bengal': 'WB',
    'Andaman and Nicobar': 'AN',
    'Andaman and Nicobar Island': 'AN',
    'Andaman and Nicobar Islands': 'AN',
    'Chandigarh': 'CH',
    'Dadra and Nagar Haveli': 'DH',
    'Daman and Diu': 'DD',
    'Delhi': 'DL',
    'Lakshadweep': 'LD',
    'Pondicherry': 'PY',
    'Uttar Pradesh': 'UP',
}
;
