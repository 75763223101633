import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APP_CONFIG, BASE_URL, BUSINESS_CONTACT_PROFILE_TYPE, OVERALL_INSTA_QUERY_LIMIT, OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE, PROFILE_REGISTRATION_URL, PROFILE_TYPE, PURCHASE_TIME, SINGLE_DAY_INSTA_QUERY_LIMIT, SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { BUSINESS_IMAGES } from '../../../../static-data/business-images';
import { appConfig } from '../../app.config';

declare var $: any;

@Component({
  selector: 'app-business-contact',
  templateUrl: './business-contact.component.html',
  styleUrls: ['./business-contact.component.css']
})
export class BusinessContactComponent implements OnInit {
  

  jwtHelper = new JwtHelperService();
  token;
  decode;
  profileObjects;
  userID;
  Profile;
  ProfileCheck;
  sellerContactData: any;
  seller_unique_str;
  profile_str;
  category;
  instaContactError;
  instaProposalError;
  public businessImages;
  public businessDocuments;
  public isViewChecked = false;
  profileActiveStatus = '';
  profilePendingStatus = '';
  pendingProfileStr;
  invUpgProfUniqStr;
  public sellerProfileData;
  public sellerMentorSupport;
  public sellerAccelSupport;
  public sellerMgtTeamDetails = [];
  public SearchHistorydata;
  public SavedSearchdata;
  public bxContactCount;
  public bxProposalResult;
  public profileCreditBalance = 1;
  public bxBookmarkCount;
  public bxBookmarkStatus = 0;
  public sellerSimiliarData;
  public bxbannertop;
  public bxbannerrightside;
  title = 'Contact Seller';
  profileMembership = 0;
  sellerProfileExistCheck = '';
  profileUserId;
  moderatorContactStatus = 0; // status 4 means: profile users are not interested to make payment but allowing them to send insta query
  loading = false;

  // quick_profile_type = PROFILE_ARRAY;
  contact_profile_type = BUSINESS_CONTACT_PROFILE_TYPE;
  purchase_time = PURCHASE_TIME;
  public appConfig = appConfig;
  seoImageUrl = '';
  isFreeCreditExpired = false;
  public singleDayCount;
  public totalCount;
  contactLimitExceedMessage = SINGLE_DAY_INSTA_QUERY_LIMIT_EXCEED_MESSAGE;
  singleDayLimit = SINGLE_DAY_INSTA_QUERY_LIMIT;
  overallLimit = OVERALL_INSTA_QUERY_LIMIT;
  creditBalance = [];

  constructor(private linkService: LinkService,
    private meta: Meta, private titleService: Title,
    public http: HttpClient,
    private contactService: ContactService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(APP_CONFIG) private config: IAppConfig) {
  }


  checkLoginToken(): void {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
      this.token = localStorage.getItem('currentUser');
      this.decode = this.jwtHelper.decodeToken(this.token);
      this.userID = this.decode.sub;
      this.Profile = this.decode.userProfile;
      this.ProfileCheck = this.decode.userProfile[0].profile_id;
      this.saveSearchHistory();
      this.checkBxContactBusiness();
      this.checkBxProposalCount();
      this.checkBxBookmark();
      this.getProfileData();
    } else {
      this.Profile = [{
        'profile_id': 0,
        'profile_type': '',
        'profile_status': '',
        'profile_name': '',
        'profile_str': '',
        'payment_status': 0
      }];
      this.ProfileCheck = this.Profile[0].profile_id;
      this.userID = 0;
      this.bxContactCount = 0;
      if (isPlatformBrowser(this.platformId)) {
        $('#login').modal({
          backdrop: 'static',
          keyboard: false
        });
        $('#login .close').css('display', 'none');
      }
    }
  }

  contactProposalFormSubmit(data, receiverId, profileStr) {
    const formSelector = $('form[name="contactProposalForm"]');
    if (formSelector.validator('validate').has('.has-error').length || this.profileCreditBalance <= 0) {
      return;
    }
    data.sender = this.userID;
    data.receiver = receiverId;
    data.profileStr = profileStr;
    data.sender_profile_type = data.ProfileSelected;
    data.receiver_profile_type = PROFILE_TYPE.Business;
    formSelector.find('button[type="submit"]').button('loading');
    this.http.post(this.config.apiEndpoint + '/contactProposal', data).subscribe(
      () => {
        $('#sellerproposalForm .form-control').val('');
        $('#sellerproposalForm').css('display', 'none');
        $('#thankyouproposal').css('display', 'block');
      },
      error => {
        this.instaProposalError = error.error;
        appConfig.scrollToError(false);
        formSelector.find('button[type="submit"]').button('reset');
      });
  }

  contactProfileTypeSubmit(data) {
    if ($('form[name="contactProfileTypeForm"]').validator('validate').has('.has-error').length) {
      return;
    }
    this.router.navigate([PROFILE_REGISTRATION_URL[data.profile_type]]);
  }

  contactFormBusinessSubmit(data, profileId, profileStr) {
    const formSelector = $('form[name="contactFormBusiness"]');
    if (formSelector.validator('validate').has('.has-error').length) {
      return;
    }
    data.user_id = this.userID;
    data.profile_id = profileId;
    data.profile_str = profileStr;
    formSelector.find('button[type="submit"]').button('loading');
    data.subscribe = (data.subscribe === false) ? 0 : 1;
    this.http.post(this.config.apiEndpoint + '/contactthebusiness', data).subscribe(
      (response) => {
        // storing the new token in local storage. No need to logout and login for loading new profiles of user
        // localStorage.removeItem('currentUser');
        // localStorage.setItem('currentUser', JSON.stringify(response['token']));
        // this.token = localStorage.getItem('currentUser');
        // this.decode = this.jwtHelper.decodeToken(this.token);
        // this.userID = this.decode.sub;
        // this.Profile = this.decode.userProfile;

        $('#contactForm .form-control').val('');
        $('#contactform').css('display', 'none');
        $('#contactthankyou').css('display', 'block');
      },
      error => {
        this.instaContactError = error.error;
        appConfig.scrollToError();
        formSelector.find('button[type="submit"]').button('reset');
      });
  }

  getProfileData(): any {
    this.Profile.forEach((key) => {
      if (key.profile_type === PROFILE_TYPE.Business && key.profile_str === this.profile_str) {
        this.sellerProfileExistCheck = 'UserProfile';
      }
      if (key.profile_type === PROFILE_TYPE.Investor || key.profile_type === PROFILE_TYPE.Lender
        || key.profile_type === PROFILE_TYPE.Mentor || key.profile_type === PROFILE_TYPE.Incubation) {
        if (key.profile_status === 1) {
          this.profileActiveStatus = key.profile_name;
          this.invUpgProfUniqStr = key.profile_str;
          this.profileMembership = key.membership_type;
        } else {
          this.profilePendingStatus = key.profile_name;
          this.pendingProfileStr = key.profile_str;
          this.moderatorContactStatus = key.moderatorContactStatus;
        }
      }
    });
  }

  checkBxBookmark(): void {
    this.contactService.checkBxBookmark(this.userID, PROFILE_TYPE.Business, this.seller_unique_str)
      .then((result) => {
        this.bxBookmarkCount = result.count;
        this.bxBookmarkStatus = result.checked;
      });
  }

  checkBxProposalCount(): void {
    this.contactService.checkBxProposalCount(this.userID, [PROFILE_TYPE.Business, PROFILE_TYPE.Startup])
      .then((result) => {
        this.creditBalance = result.creditBalance;
      });
  }

  checkBxContactBusiness(): void {
    this.contactService.checkBxContactBusiness(this.userID, this.seller_unique_str)
      .then((result) => {
        this.bxContactCount = result.count;
        this.singleDayCount = result.singleDayCount;
        this.totalCount = result.totalCount;
        if (this.totalCount >= OVERALL_INSTA_QUERY_LIMIT) {
          this.contactLimitExceedMessage = OVERALL_INSTA_QUERY_LIMIT_EXCEED_MESSAGE;
        }
      });
  }

  saveSearchHistory(): void {
    this.contactService.saveSearchHistory(this.userID, PROFILE_TYPE.Business, this.seller_unique_str)
      .then((result) => {
        this.SearchHistorydata = result;
      });
  }

  imageFunction(parentCatId: any): any {
    if(parentCatId ==null){
      return 
    }
    const images = BUSINESS_IMAGES.filter(item => item.parentId === parentCatId)[0];
    return images.imageSet;
  }


  checkBxProposal(): void {
    this.contactService.checkBxProposal(this.userID, PROFILE_TYPE.Business, this.seller_unique_str, this.profileUserId)
      .then((result) => {
        this.bxProposalResult = result;
      });
  }


  getSimiliarSellers(): void {
    this.contactService.getSimiliarSellers(this.seller_unique_str)
      .then((result) => {
        this.sellerSimiliarData = result.similiarsellerdatalist;
      });
  }

  ngOnInit() {

    this.loading = true;
    this.route.paramMap.subscribe((params: Params) => {

      //this.getBanners();
      this.seller_unique_str = params.get('sellerUniqueId');
      // updating business profile view count in case of  mailer campagain
      this.route.queryParams.subscribe(params1 => {
        if (params1['mc'] !== undefined && atob(params1['mc']) === this.seller_unique_str) {
          this.contactService.updateProfileViewCount(this.seller_unique_str, PROFILE_TYPE.Business)
            .then((result) => {
            });
        }
      });

      this.category = params.get('category');
      this.checkLoginToken();
      this.contactService.getsellerContactDetails(this.seller_unique_str, this.userID)
        .then((result) => {
          if (result) {
            this.sellerContactData = this.jwtHelper.decodeToken(result);
            this.sellerProfileData = this.sellerContactData.sellerData;

           //console.log(this.sellerProfileData);
            
            this.sellerMentorSupport = this.sellerContactData.mentorSupport;
            this.sellerAccelSupport = this.sellerContactData.accelSupport;
            this.sellerMgtTeamDetails = this.sellerContactData.mgtTeamDetail;
            this.businessDocuments = this.sellerContactData.businessDocuments;
            this.businessImages = this.sellerContactData.businessImage;

            if (this.sellerProfileData.slug !== this.category || this.sellerProfileData.profileStr !== this.seller_unique_str) {
              this.router.navigate(['/business/' + this.sellerProfileData.slug + '/' + this.sellerProfileData.profileStr]);
            }
            this.category = this.sellerProfileData.subindustry;
            this.profileUserId = this.sellerProfileData.userId;
            if (Object.keys(this.sellerContactData.businessImage).length === 0) {
              this.businessImages = this.imageFunction(this.sellerProfileData.parentCatId);
              //console.log('businessImages',this.businessImages)
              this.seoImageUrl =  this.businessImages ? BASE_URL + '/' + this.businessImages[0].imageUrl:'';
            } else {
              this.seoImageUrl =  this.businessImages ? this.businessImages[0].imageUrl:'';
            }
            this.checkBxProposal();
            this.setMetaTitle();
            this.getSimiliarSellers();
          }
          this.loading = false;
        }, (error) => {
          if (error.status === 404) {
            this.router.navigate(['/businesslisting']);
          }
          this.loading = false;
        });
    });
  }


  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      $('#login').modal('hide');
    }
  }


  private setMetaTitle() {
    /*const description_old = 'Every ' + this.category +
        ' business needs the right channel to flourish. ' +
        'At Businessex.com, we offer you the perfect platform to ' +
        'sky rocket your business instantly.It’s important to be' +
        'at the right place, at the right time! You have the concept ' +
        'and we have the ammunition to make you a starlet brand.';*/

    const description = this.sellerProfileData.description.substring(0, 400)

    this.titleService.setTitle(this.sellerProfileData.title + '- BusinessEx');
    this.meta.updateTag({ name: 'Keywords', content: '' + this.category + '' });
    this.meta.updateTag(
      {
        name: 'Description',
        content: description
      }
    );

    /*this.meta.updateTag({ property: 'site_name', content: 'BusinessEx' });
    this.meta.updateTag({ property: 'og:title', content: this.sellerProfileData.title + '- BusinessEx'});
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:url', content: BASE_URL + this.router.url });
    this.meta.updateTag({ property: 'og:image', content: this.seoImageUrl });
    this.meta.updateTag({ property: 'og:image:secure_url', content: this.seoImageUrl });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image:width', content: '770' });
    this.meta.updateTag({ property: 'og:image:height', content: '434' });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ property: 'twitter:title', content: this.sellerProfileData.title + '- BusinessEx'});
    this.meta.updateTag({ name: 'twitter:image', content: this.seoImageUrl });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:url', content: BASE_URL + this.router.url });
    this.meta.updateTag({ name: 'twitter:image:src', content: this.seoImageUrl });
    this.meta.updateTag({ name: 'twitter:site', content: '@BusinesEx' });
    this.meta.updateTag({ name: 'twitter:domain', content: 'businessex.com' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@businesex' });*/

    this.linkService.removeCanonicalLink();
    this.linkService.addTag({
      rel: 'canonical',
      href: BASE_URL + this.router.url
    });
  }


  onChangeObj($event) {
    if (this.Profile === undefined) {
        return;
    }
    const selectedProfile = this.Profile.filter(item => (item.profile_type == $event))[0];
    localStorage.setItem('ProfileType', selectedProfile.profile_name); // need to show the appropriate payment plan
    const selectedProfileCredits = this.creditBalance.filter(item => (item.profileType == $event))[0];
    this.isFreeCreditExpired = (parseInt(selectedProfile.isFreeCreditExpired, 10) === 1);
    this.profileCreditBalance = (selectedProfileCredits !== undefined) ? selectedProfileCredits.interactionCreditBalance : 0;
}


}
