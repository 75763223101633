export const BUSINESS_IMAGES = [
    {
        parentId: 1,
        imageSet: [
            {
                id: 1,
                imageUrl: 'assets/images/category/big/automobile.jpg',
                title: 'Automobile',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 1,
                imageUrl: 'assets/images/category/big/automobile1.jpg',
                title: 'Automobile',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 1,
                imageUrl: 'assets/images/category/big/automobile2.jpg',
                title: 'Automobile',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 2,
        imageSet: [
            {
                id: 2,
                imageUrl: 'assets/images/category/big/fmcg.jpg',
                title: 'FMCG',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 2,
                imageUrl: 'assets/images/category/big/fmcg1.jpg',
                title: 'FMCG',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 2,
                imageUrl: 'assets/images/category/big/fmcg2.jpg',
                title: 'FMCG',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 3,
        imageSet: [
            {
                id: 3,
                imageUrl: 'assets/images/category/big/education.jpg',
                title: 'Education',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 3,
                imageUrl: 'assets/images/category/big/education1.jpg',
                title: 'Education',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 3,
                imageUrl: 'assets/images/category/big/education2.jpg',
                title: 'Education',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 4,
        imageSet: [
            {
                id: 4,
                imageUrl: 'assets/images/category/big/beauty.jpg',
                title: 'Beauty, Health & Wellness',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 4,
                imageUrl: 'assets/images/category/big/beauty1.jpg',
                title: 'Beauty, Health & Wellness',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 4,
                imageUrl: 'assets/images/category/big/beauty2.jpg',
                title: 'Beauty, Health & Wellness',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 5,
        imageSet: [
            {
                id: 5,
                imageUrl: 'assets/images/category/big/business.jpg',
                title: 'Business Services',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 5,
                imageUrl: 'assets/images/category/big/business1.jpg',
                title: 'Business Services',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 5,
                imageUrl: 'assets/images/category/big/business2.jpg',
                title: 'Business Services',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 6,
        imageSet: [
            {
                id: 6,
                imageUrl: 'assets/images/category/big/food.jpg',
                title: 'Food & Beverage',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 6,
                imageUrl: 'assets/images/category/big/food1.jpg',
                title: 'Food & Beverage',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 6,
                imageUrl: 'assets/images/category/big/food2.jpg',
                title: 'Food & Beverage',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },

        ]
    },
    {
        parentId: 7,
        imageSet: [
            {
                id: 7,
                imageUrl: 'assets/images/category/big/fashion.jpg',
                title: 'Fashion',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 7,
                imageUrl: 'assets/images/category/big/fashion1.jpg',
                title: 'Fashion',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 7,
                imageUrl: 'assets/images/category/big/fashion2.jpg',
                title: 'Fashion',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 8,
        imageSet: [
            {
                id: 8,
                imageUrl: 'assets/images/category/big/building.jpg',
                title: 'Building construction & Home products',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 8,
                imageUrl: 'assets/images/category/big/building1.jpg',
                title: 'Building construction & Home products',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 8,
                imageUrl: 'assets/images/category/big/building2.jpg',
                title: 'Building construction & Home products',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 9,
        imageSet: [
            {
                id: 9,
                imageUrl: 'assets/images/category/big/travel.jpg',
                title: 'Travel & Tourism',
                cat_slug: 'Travel & Tourism',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 9,
                imageUrl: 'assets/images/category/big/travel1.jpg',
                title: 'Travel & Tourism',
                cat_slug: 'Travel & Tourism',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 9,
                imageUrl: 'assets/images/category/big/travel2.jpg',
                title: 'Travel & Tourism',
                cat_slug: 'Travel & Tourism',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 10,
        imageSet: [
            {
                id: 10,
                imageUrl: 'assets/images/category/big/entertainment.jpg',
                title: 'Leisure & Entertainment',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 10,
                imageUrl: 'assets/images/category/big/entertainment1.jpg',
                title: 'Leisure & Entertainment',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 10,
                imageUrl: 'assets/images/category/big/entertainment2.jpg',
                title: 'Leisure & Entertainment',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 11,
        imageSet: [

            {
                id: 11,
                imageUrl: 'assets/images/category/big/finance.jpg',
                title: 'Finance',
                cat_slug: 'Finance',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 11,
                imageUrl: 'assets/images/category/big/finance1.jpg',
                title: 'Finance',
                cat_slug: 'Finance',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 11,
                imageUrl: 'assets/images/category/big/finance2.jpg',
                title: 'Finance',
                cat_slug: 'Finance',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },

        ]
    },
    {
        parentId: 12,
        imageSet: [
            {
                id: 12,
                imageUrl: 'assets/images/category/big/energy.jpg',
                title: 'Energy & Environment',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 12,
                imageUrl: 'assets/images/category/big/energy1.jpg',
                title: 'Energy & Environment',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 12,
                imageUrl: 'assets/images/category/big/energy2.jpg',
                title: 'Energy & Environment',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 13,
        imageSet: [
            {
                id: 13,
                imageUrl: 'assets/images/category/big/manufacturing.jpg',
                title: 'Industrial machinery & Manufacturing',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 13,
                imageUrl: 'assets/images/category/big/manufacturing1.jpg',
                title: 'Industrial machinery & Manufacturing',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 13,
                imageUrl: 'assets/images/category/big/manufacturing2.jpg',
                title: 'Industrial machinery & Manufacturing',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    },
    {
        parentId: 14,
        imageSet: [
            {
                id: 14,
                imageUrl: 'assets/images/category/big/retail.jpg',
                title: 'Retail',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 14,
                imageUrl: 'assets/images/category/big/retail1.jpg',
                title: 'Retail',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
            {
                id: 14,
                imageUrl: 'assets/images/category/big/retail2.jpg',
                title: 'Retail',
                cat_slug: 'automobile',
                listingPageImage: 'AngularJS',
                detailPageImage: 'AngularJS'
            },
        ]
    }
];
