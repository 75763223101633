import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {MyProfileService} from '../../../../services/my-profile.service';
import {Meta, Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {PROFILE_ARRAY, PROFILE_TYPE} from '../../../../config/app-config.constants';

declare var $: any;

@Component({
    selector: 'instaresponse',
    templateUrl: './instaresponse.component.html',
    styles: [
        '.tabulardata.insta-width td, .tabulardata.insta-width th { min-width: 158px !important; }'
    ]
})
export class InstaResponseComponent implements OnInit {

    jwtHelper = new JwtHelperService();
    users: any;
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    ProfileSelected;
    public instaRevealCount;
    public confirmInstaUpdate;
    public instaResponse;
    public instaTotalCount;
    instaResponseLength;
    contactSelId;
    public profiles = PROFILE_TYPE;
    messageLoading: boolean;

    constructor(private meta: Meta,
                private title: Title,
                private myprofileService: MyProfileService,
                private http: HttpClient,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Insta Response - BusinessEx');
    }

    ViewConfirmInstaUpdate(contactId): void {
        if (this.instaRevealCount >= this.instaTotalCount) {
            return; // already consumed insta credit
        }
        this.myprofileService.getViewConfirmInstaUpdate(contactId, this.ProfileSelected.profile_type)
            .then((result) => {
                this.confirmInstaUpdate = result;
                if (this.confirmInstaUpdate) {
                    this.contactSelId = contactId;
                    this.getInstaRevealCount();
                    this.getInstaResponser();
                } else {
                    this.contactSelId = 0;
                }
            });
    }

    getInstaRevealCount(): void {
        this.myprofileService.getInstaRevealCount(this.userID, this.ProfileSelected.profile_type)
            .then((result) => {
                this.instaRevealCount = result.count;
                this.instaTotalCount = result.total;
            });
    }

    getInstaResponser(): void {
        this.messageLoading = true;
        this.myprofileService.getInstaResponse(this.userID, this.ProfileSelected.profile_type, this.ProfileSelected.membership_type)
            .then((result) => {
                if (result) {
                    this.instaResponse = result;
                    this.instaResponseLength = this.instaResponse.length;
                    this.messageLoading = false;
                }
            });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
            const instaProfile = PROFILE_ARRAY.filter(item => (item.id == this.ProfileSelected.profile_type))[0];
            this.getInstaRevealCount();
            this.getInstaResponser();
        }
    }

}
