
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 

    <profile-detail></profile-detail>

<!-- Company intraction detaild info -->
<div class="col-xs-12 col-sm-7 col-md-6 mdy-width-49">
    <loader [loading]="messageLoading"></loader>
<div class="bdr">
<div class="cat-list ">
<div class="cat-sec-ttl">
Business Ex Proposal
</div>
    
<!-- Business Listing for Proposal Sent start here -->
<div *ngIf="totalrequest>0">
<ul class="bp-list">
    <li *ngFor="let proposals of requestlist | limitTo:requestlist;">
        <div class="img-sec">
            <a style="padding: 0px;" routerLink="{{proposals.link}}">
                
                <img *ngIf="proposals.profileName == 'Business'" src="assets/images/business_img_48.jpg"/>
                <img *ngIf="proposals.profileName == 'Investor'" src="assets/images/profile-dflt.gif"/>
                
                <!--<img ng-if="proposals.profilepic == '' && proposals.profileName == 'Business'" src="assets/images/business_img_48.jpg"/>
                <img ng-if="proposals.profilepic != '' && proposals.profileName == 'Business'" ng-src="{{proposals.profilepic}}"/>
                <img ng-if="proposals.profilepic == '' && proposals.profileName == 'Investor'" src="assets/images/profile-dflt.gif"/>
                <img ng-if="proposals.profilepic != '' && proposals.profileName == 'Investor'" ng-src="{{proposals.profilepic}}"/>-->
            </a>
        </div>        
        <div class="bdy-sec">
            <div class="profile-type">
            <div class="pull-left">New {{proposals.profileName}} <span class="pipe">|</span> 
            <i class="fa fa-map-marker" aria-hidden="true"></i> {{proposals.city}} 
            <span class="pipe">|</span> {{proposals.category}}
            </div> 
            <!--<div class="pull-right">4 minuttes ago</div>-->
            </div>
            <div class="name"><a routerLink="{{proposals.link}}">{{proposals.headline}}</a></div>
            <div class="business-req">Business Req.: <span>{{proposals.requirement}}</span></div>
            <div ng-if="proposals.profileName =='Investor'" class="business-req">Investor Profile: <span>{{proposals.category}}</span></div>
            <div class="business-req">Investment Req.: <span>{{proposals.price}}</span></div>
            <div class="business-req"> Message: <span>{{proposals.msg}}</span></div>
            <div class="action-btn">
                <a [routerLink]="" class="slide-blue-btn" (click)="handleRequest(proposals, 1)">ACCEPT</a>
                <a [routerLink]="" class="slide-blue-btn" (click)="handleRequest(proposals, 2)">REJECT</a>
            </div>
            <div class="businessContacted" *ngIf="profileObjects[0].profile_type == profiles.Broker">
                <strong>Business Contacted :</strong> {{proposals?.sellerCompany}}
            </div>
        </div>                                
    </li>      
</ul>
   <a [routerLink]="" id="requestList" (click)="requestListingLoadMore(requestlist)" class="load-more">LOAD MORE</a>
</div>

<div class="nodata" *ngIf="totalrequest==0">  
    No Bx Proposal data found !
</div>
<!-- Business Listing for Proposal Sent end here -->

</div>
</div>
</div>
<!-- Investor profile pic -->

<!-- My Profile Right Section Start Here -->
<!-- My Profile Right Section Start Here -->
    <right-section></right-section>
<!-- My Profile Right Section End Here -->
<!-- My Profile Right Section End Here -->

</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->
     
