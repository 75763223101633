<section id="heroinner">
  <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

    <!-- <ol class="carousel-indicators" id="hero-carousel-indicators">
      <ol class="carousel-indicators" id="hero-carousel-indicators">
        <li data-target="#heroCarousel" data-slide-to="0" class=""></li>
        <li data-target="#heroCarousel" data-slide-to="1" class=""></li>
        <li data-target="#heroCarousel" data-slide-to="2" class="active"></li>
        <li data-target="#heroCarousel" data-slide-to="3" class=""></li>
      </ol>
    </ol> -->


    <div class="carousel-inner" role="listbox">

      <!-- Slide 1 -->
      <div class="carousel-item featured-post-banner active"
      style="background-image: url({{articledata.image}})" *ngFor="let articledata of bxNewsListing | slice:0:1 let j=index; let lastItem = last">
      <div class="item-overlay"></div>
      <div class="carousel-container">
        <div class="container">
          <!-- <span class="animate__animated animate__fadeInDown">
            Business Ideas
          </span> -->
          <h2 class="animate__animated animate__fadeInDown">{{articledata.title | stripTags | limitTo:100}}</h2>
          <p class="animate__animated animate__fadeInUp">
            {{articledata.shortDesc | stripTags | limitTo:100 }}</p>
          <a  [routerLink]="['/article/'+articledata.url+'/'+articledata.news_id]" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
        </div>
      </div>
    </div>
      <!-- Slide 2 -->
      <!-- <div class="carousel-item featured-post-banner" style="background-image: url(assets/img/slide/slide-3.jpg)">
        <div class="item-overlay"></div>
        <div class="carousel-container">
          <div class="container">
            <span class="animate__animated animate__fadeInDown">
              Business Ideas
            </span>
            <h2 class="animate__animated animate__fadeInDown">Leveraging The Power Of <br />Blockchain :</h2>
            <p class="animate__animated animate__fadeInUp">4 Indian Entrepreneurs Who Embraced <br />Technology In
              Their Business Models.</p>
            <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
          </div>
        </div>
      </div> -->
      <!-- Slide 3 -->
      <!-- <div class="carousel-item featured-post-banner" style="background-image: url(assets/img/slide/slide-2.jpg)">
        <div class="item-overlay"></div>
        <div class="carousel-container">
          <div class="container">
            <span class="animate__animated animate__fadeInDown">
              Business Ideas
            </span>
            <h2 class="animate__animated animate__fadeInDown">Leveraging The Power Of <br />Blockchain :</h2>
            <p class="animate__animated animate__fadeInUp">4 Indian Entrepreneurs Who Embraced <br />Technology In
              Their Business Models.</p>
            <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
          </div>
        </div>
      </div> -->
      <!-- Slide 4 -->
      <!-- <div class="carousel-item featured-post-banner" style="background-image: url(assets/img/slide/slide-3.jpg)">
        <div class="item-overlay"></div>
        <div class="carousel-container">
          <div class="container">
            <span class="animate__animated animate__fadeInDown">
              Business Ideas
            </span>
            <h2 class="animate__animated animate__fadeInDown">Leveraging The Power Of <br />Blockchain :</h2>
            <p class="animate__animated animate__fadeInUp">4 Indian Entrepreneurs Who Embraced <br />Technology In
              Their Business Models.</p>
            <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
          </div>
        </div>
      </div> -->


    </div>

<!-- 
    <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
      <span class="sr-only">Previous</span>
    </a>

    <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
      <span class="sr-only">Next</span>
    </a> -->

  </div>
</section>
<main id="main">
  <!-- ======= Trending Articles Section ======= -->
  <div class="inneblk">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-9">
          <div class="section-title">
            <div class="page-ttl">
              <h2>{{this.tag}} {{title}}</h2>
            </div>
            <div class="shrt-desc sec-hide">
              Discover all the relevant information you need for start-ups, businesses, investments, mentorship,
              brokerage, lending, & incubation right here on BusinessEx.com article division. You need any information
              or want share your thoughts there is a comment section right below the article.
            </div>
            <div class="news-sort-list">
              <ul>
                <li>SORT BY :</li>
                <li><a class="active" id="recent" [routerLink]="" (click)="shortByDate('created_at')">Most Recent</a></li>
                <li><a [routerLink]="" id="read" (click)="shortByRead('news_views')">Most Read</a></li>
                <li><a [routerLink]="" id="comment" (click)="shortByComment('news_comments')">Most Commented</a></li>
                <li><a [routerLink]="" id="title" (click)="shortByAlpha('news_title')">Alphabetical</a></li>
              </ul>
            </div>
            <!-- <div class="ttl-article">
              <h2>Recent {{tag}} Articles</h2>
               <span>{{bxarticleListing.length}}</span> article out of <span>{{bxarticleListing.length}}</span>
            </div> -->
          </div>

          <ul class="bxartlist">

            <li  *ngFor="let newsdata of bxNewsListing;  let i=index; let last = last" [isLast]="last" (lastDone)="newsList()">
              <div class="inimg">
                <img src="{{newsdata.image}}">
              </div>
              <div class="summry-section">
                <p>
                  <a [routerLink]="['/news/'+newsdata.url+'/'+newsdata.news_id]">
                    {{newsdata.title | stripTags | limitTo:30}}
                  </a>
                </p>
                <h2>
                  <a [routerLink]="['/news/'+newsdata.url+'/'+newsdata.news_id]">
                    {{ newsdata.content | stripTags| limitTo:50 }}
                  </a>
                </h2>

                <span class="time">{{newsdata.time}}</span>
              </div>
              <div class="border-tb1 mt-2">
                <div class="pt-2 pb-2 d-flex">
                  <div class="text-left duration-section">
                    <img src="./assets/img/duration.svg" alt="" /><span>
                      {{ newsdata?.content| readWordTime}} Min Read</span>
                  </div>
                  <div class="text-right share-section">

                    <share-popup-button [theme]="'modern-dark'"
                    [include]="['facebook','twitter','linkedin','whatsapp']"
                    [show]="5"
                    [showText]="true"
                    [url]="'/news/'+newsdata.url+'/'+newsdata.news_id"
                    [title]="newsdata.title"
                    [description]="newsdata.shortDesc "
                    [image]="newsdata.image"
                    [icon]="faShareAlt"
                    [closeButton]="'null'"
                    [showIcon]="true"
                    ></share-popup-button>


                  </div>
                </div>
              </div>
            </li>

          </ul>

          <div class="row" *ngIf="bxNewsListing.length < 1">
            <div class="col-xs-12 col-sm-6 col-md-3 sec-slide-effect">
              <div style="margin-top: 30px;">No Records Found</div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12">
            <listing-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="5"
              [page]="currentPage" [perPage]="itemsPerPage" [count]="totalItems">
            </listing-pagination>
          </div>


        </div>
        <div class="col-xs-12 col-md-3 rightsec">
          <div class="section-title">
            <h2>Latest News & Articles<span></span></h2>
          </div>
          <ul class="rlist">
            <li *ngFor="let newsdata of bxNewsListing | slice:0:4 let j=index; let lastItem = last" [isLast]="lastItem">
              <div class="imgleft"><img src="{{newsdata.image}}">
              </div>
              <div class="imgtxt">
                <div class="rhead">
                  <a [routerLink]="['/news/'+newsdata.url+'/'+newsdata.news_id]">
                    {{newsdata.title | stripTags | limitTo:20}}
                  </a>
                </div>
                <span class="datshow">{{newsdata.time}}</span>
              </div>
            </li>


          </ul>

          <!-- <div class="section-title">
            <h2>Other Categories<span></span></h2>
          </div>
          <ul class="catlistleft">
            <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
            <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
            <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
            <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
            <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
            <li><a href="#">Business Ideas</a> <i class="icofont-rounded-right"></i></li>
          </ul> -->
          <!-- <div class="riads"><img src="./assets/img/ads.png" alt=""></div> -->
        </div>
      </div>
    </div>

  </div>





</main>
<script type="text/javascript">
  // Intro carousel
  var heroCarousel = $("#heroCarousel");
  var heroCarouselIndicators = $("#hero-carousel-indicators");
  heroCarousel.find(".carousel-inner").children(".carousel-item").each(function (index) {
    (index === 0) ?
      heroCarouselIndicators.append("<li data-target='#heroCarousel' data-slide-to='" + index + "' class='active'></li>") :
      heroCarouselIndicators.append("<li data-target='#heroCarousel' data-slide-to='" + index + "'></li>");
  });

  heroCarousel.on('slid.bs.carousel', function (e) {
    $(this).find('h2').addClass('animate__animated animate__fadeInDown');
    $(this).find('p, .btn-get-started').addClass('animate__animated animate__fadeInUp');
  });
</script>