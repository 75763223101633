import {AfterViewChecked, Component, Inject, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import { APP_CONFIG, BASE_URL } from '../../../.././config/app-config.constants';
import { IAppConfig } from '../../../.././config/app-config.interface';
import { LinkService } from '../../../.././services/link.service';
import {isPlatformBrowser} from '@angular/common';
import { appConfig } from '../../../.././modules/frontend/app.config';


declare var $: any;

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements AfterViewChecked {
    ContactError;
    private isViewChecked = false;

    constructor(private linkService: LinkService,
                private meta: Meta, private titleService: Title, private http: HttpClient, route: ActivatedRoute,
                @Inject(PLATFORM_ID) private platformId: Object,
                @Inject(APP_CONFIG) private config: IAppConfig) {
                this.setMetaTitle();
    }

    contactUsFormSubmit(data) {
        const formSelector = $('form[name="contactUsForm"]');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        data.subscribe = (data.subscribe === false) ? 0 : 1;
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/contactUs', data).subscribe(
            data => {
                this.ContactError = [['Thank You ! Your message has been submitted successfully. ' +
                    'Our business team will contact you regarding your query.']];
                setTimeout(function () {
                    $('#connect-with-us .form-control').val('');
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(10000).slideToggle('slow');
                    return false;
                }, 100);
                formSelector.find('button[type="submit"]').button('reset');
            },
            error => {
                appConfig.scrollToError(false);
                this.ContactError = error.error;
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    private setMetaTitle() {
        this.titleService.setTitle('Contact Us - BusinessEx');
        this.meta.updateTag({name: 'Keywords', content: 'businessex, Business exchange, Mentorship, New Business, Startups'});
        this.meta.updateTag({name: 'Description', content: 'BusinessEx.com is a networking platform that assists you to find the perfect solutions for your business with proper connections. For more information contact us by filling the required information given below.'});
        this.linkService.removeCanonicalLink();
        this.linkService.addTag({rel: 'canonical', href: 'https://www.businessex.com/contact-us/'});
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="contactUsForm"]').validator();
        }
    }

}
