
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE NBFC LENDER INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/lenderConfidentials/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/lenderAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li *ngIf="bxLenderType?.type==2"><a routerLink="/dashboard/privateLender/{{profile_str}}">Private Lender Info</a></li>
                                        <li class="selected" *ngIf="bxLenderType?.type==3"><a routerLink="/dashboard/nbfcLender/{{profile_str}}">NBFC Personnel Info </a></li>
                                        <li><a routerLink="/dashboard/lenderPreference/{{profile_str}}">Preferences</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="lenderForm" #lenderForm="ngForm" (ngSubmit)="lenderMyAccountSubmit(lenderForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="lenderProfileSuccessError"
                                                 style="margin-top:10px; text-align:center;">
                                                {{lenderProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="lenderProfileFailError"
                                                 style="margin-top:10px; text-align:center;">{{lenderProfileFailError}}
                                            </div>

                                            <!--For NBFC Start here -->
                                            <div class="sec-slide-effect">
                                                <!-- <div class="row frm-sec-ttl">
                                                    <div class="col-xs-12 col-sm-6 col-md-4">For NBFC</div>
                                                </div> -->
                                                <div class="input-sec">
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Contact
                                                            Person Name <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" name="nbfc_contact_name"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_contact_name"
                                                                       class="form-control" placeholder="Enter Name"
                                                                       data-error="Please enter name" required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Person
                                                            Designation <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" name="nbfc_contact_designation"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_contact_designation"
                                                                       class="form-control"
                                                                       placeholder="Enter Designation"
                                                                       data-error="Please enter designation" required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company
                                                            Name <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" name="nbfc_comp_name"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_comp_name"
                                                                       class="form-control"
                                                                       placeholder="Enter Company Name"
                                                                       data-error="Please enter company name" required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">NBFC
                                                            Type <span class="star">*</span></label>
                                                        <span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <select class="form-control" name="nbfc_type"
                                                                        [ngModel]="nbfcLenderdata?.nbfc_type"
                                                                        data-error="Please select nbfc type." required>
                                                                    <option *ngFor="let industry of nbfc_types"
                                                                            [value]="industry.id"
                                                                            [selected]="industry.id == 'nbfcLenderdata.nbfc_type'">
                                                                        {{industry.name}}
                                                                    </option>
                                                                </select>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company
                                                            Branch <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" name="nbfc_branch"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_branch"
                                                                       class="form-control"
                                                                       placeholder="Enter Company Branch"
                                                                       data-error="Please enter company branch"
                                                                       required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location
                                                            <span class="star">*</span></label>
                                                        <span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" minlength="3" name="nbfc_city"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_location"
                                                                       class="form-control"
                                                                       placeholder="Enter Your Location"
                                                                       (setAddress)="getAddress($event)"
                                                                       data-error="Please select location" googleplace
                                                                       required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">PIN
                                                            code <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" pattern="[0-9]+" minlength="6"
                                                                       maxlength="6" name="nbfc_pincode"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_pincode"
                                                                       class="form-control" placeholder="Enter Pincode"
                                                                       data-error="Please enter pincode" required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company
                                                            Website <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" name="nbfc_website"
                                                                       [ngModel]="nbfcLenderdata?.nbfc_website"
                                                                       http-prefix class="form-control"
                                                                       placeholder="Enter Company Website"
                                                                       data-error="Please enter company website"
                                                                       required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">About
                                                            The Company <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <textarea class="form-control" name="nbfc_about"
                                                                          [ngModel]="nbfcLenderdata?.nbfc_about"
                                                                          rows="3" placeholder="Enter Company Summary"
                                                                          data-error="Please enter summary"
                                                                          required></textarea>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Profile
                                                            Pictures</label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <img *ngIf="nbfcLenderdata?.profile_pic_path != '' && nbfcLenderdata?.profile_pic_path != null" src="{{nbfcLenderdata?.profile_pic_path}}" height="150" width="150">
                                                            <div class="input-group cer-preview">
                                                                <input type="text"
                                                                       class="form-control cer-preview-filename"
                                                                       placeholder="Accepted formats - png, jpeg, gif"
                                                                       disabled="disabled">
                                                                <input type="hidden" name="profile_pic_path_old" [ngModel]="nbfcLenderdata?.profile_pic_path">
                                                                <span class="input-group-btn">
                                            <button type="button" class="btn btn-default rm-img cer-preview-clear"
                                                    style="display:none; height:40px;">
                                                    <span class="glyphicon glyphicon-remove"></span> Clear
                                                </button>
                                            <!-- image-preview-input -->
                                            <div class="btn btn-default btn-blue btn-file rm-img cer-preview-input">
                                                <span class="glyphicon glyphicon-folder-open"
                                                      style="margin-top: 5px;"></span>
                                                <span class="cer-preview-input-title"
                                                      style="margin-left: 5px;">Browse</span>
                                                <input type="file" id="inputImage" name="profile_pic_path"
                                                       file-model="profile_pic_path" (change)="fileChangeEvent($event)"
                                                       accept="image/png, image/jpeg, image/gif"/> <!-- rename it -->
                                            </div>
                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Attach
                                                            Company Logo</label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <img *ngIf="nbfcLenderdata?.nbfc_corporate_profile_path != '' && nbfcLenderdata?.nbfc_corporate_profile_path != null" src="{{nbfcLenderdata?.nbfc_corporate_profile_path}}" height="150" width="150">
                                                            <div class="input-group logo-preview">
                                                                <input type="text"
                                                                       class="form-control logo-preview-filename"
                                                                       placeholder="Accepted formats - png, jpeg, gif"
                                                                       disabled="disabled">
                                                                <input type="hidden" name="nbfc_corporate_profile_path_old" [ngModel]="nbfcLenderdata?.nbfc_corporate_profile_path">
                                                                <span class="input-group-btn">
                                            <button type="button" class="btn btn-default rm-img logo-preview-clear"
                                                    style="display:none; height:40px;">
                                                    <span class="glyphicon glyphicon-remove"></span> Clear
                                                </button>
                                                                    <!-- image-preview-input -->
                                            <div class="btn btn-default btn-blue btn-file rm-img logo-preview-input">
                                                <span class="glyphicon glyphicon-folder-open"
                                                      style="margin-top: 5px;"></span>
                                                <span class="logo-preview-input-title"
                                                      style="margin-left: 5px;">Browse</span>
                                                <input type="file" id="inputImage" name="nbfc_corporate_profile_path"
                                                       file-model="nbfc_corporate_profile_path"
                                                       (change)="logoChangeEvent($event)"
                                                       accept="image/png, image/jpeg, image/gif"/> <!-- rename it -->
                                            </div>
                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Lending
                                                            Capacity <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <input type="text" pattern="[0-9]+" minlength="5"
                                                                       maxlength="12" name="lending_capacity"
                                                                       [ngModel]="nbfcLenderdata?.lending_capacity"
                                                                       data-ng-pattern="/^[0-9]{1,10}$/"
                                                                       data-ng-change="string" data-ng-minlength="10"
                                                                       data-ng-maxlength="10" class="form-control"
                                                                       placeholder="Enter Lending Capacity"
                                                                       data-error="Please enter lending capacity"
                                                                       required>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <!--<div class="form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location Preference </label><span class="colmark">:</span>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <ul id="courseLocation"></ul>
                                                        <ul id="result-list"></ul>
                                                    </div>
                                                    </div>

                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Sector Preference<span class="star">*</span></label>
                                                        <span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group tl-tip">
                                                                <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Enter your text"><i class="fa fa-info" aria-hidden="true"></i></div>
                                                                 <p-autoComplete name="nbfc_ind_pref" #nbfc_ind_pref="ngModel" ngModel [suggestions]="filteredIndustriesMultiple" (completeMethod)="filterIndustryMultiple($event)" styleClass="wid100"
                                                            [minLength]="1" placeholder="Add Sector Preference" field="name" [multiple]="true">
                                                                </p-autoComplete>
                                                                <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>-->

                                                    <div class="form-group has-feedback">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Professional
                                                            Summary <span class="star">*</span> </label><span
                                                            class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <div class="input-group">
                                                                <textarea class="form-control" name="prof_summary"
                                                                          [ngModel]="nbfcLenderdata?.prof_summary"
                                                                          rows="3"
                                                                          placeholder="Write Professional Summary"
                                                                          data-error="Please enter professional summary"
                                                                          required></textarea>
                                                                <span class="glyphicon form-control-feedback"
                                                                      aria-hidden="true"></span>
                                                            </div>
                                                            <div class="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Registered
                                                            with RBI </label><span class="colmark">:</span>
                                                        <div class="col-xs-12 col-sm-6 col-md-7">
                                                            <label class="radio-inline">
                                                                <input type="radio" name="rbi_registered"
                                                                       [ngModel]="nbfcLenderdata?.rbi_registered"
                                                                       id="inlineRadio1" value="1"> Yes
                                                            </label>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="rbi_registered"
                                                                       [ngModel]="nbfcLenderdata?.rbi_registered"
                                                                       id="inlineRadio2" value="0" ng-checked="true"> No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group registered_rbi">
                                                        <div class="col-xs-12 col-sm-6 col-sm-offset-6 col-md-5 col-md-offset-4">
                                                            <input type="text" name="rbi_registered_no"
                                                                   [ngModel]="nbfcLenderdata?.rbi_registered_no"
                                                                   class="form-control"
                                                                   placeholder="Enter Registration number">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--For NBFC End here -->

                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
