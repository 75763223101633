import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'projects/frontend/src/app/services/authentication.service';
import { AlertService } from 'projects/frontend/src/app/services/alert.service';
import { APP_CONFIG } from '../../../../config/app-config.constants';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';//
import {AuthService} from 'ng2-ui-auth';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotForm: FormGroup;
  registerForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  errormsg: any;
  loginTab: boolean;
  socialLoading = '';
  hideClose = true;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(APP_CONFIG) private config: IAppConfig

  ) { 
     
  }

  initLoginForm() {



    this.loginForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
      }
    );

    this.registerForm = this.formBuilder.group(
      {
        remail: ['', [Validators.required, Validators.email]],
        rpassword: ['', Validators.required],
        subscribe: [''],
        cpassword: ['', Validators.required],
      },
      {
        validator: this.MustMatch('rpassword', 'cpassword'),
      }
    );

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })

  }

  /*initRegisterForm() {
    this.registerForm = this.formBuilder.group(
      {
        remail: ['', [Validators.required, Validators.email]],
        rpassword: ['', Validators.required],
        subscribe: [''],
        cpassword: ['', Validators.required],
      },
      {
        validator: this.MustMatch('rpassword', 'cpassword'),
      }
    );
  }*/

  MustMatch(controlName: string, matchingControlName: string) {
    return (registerForm: FormGroup) => {
      const control = registerForm.controls[controlName];
      const matchingControl = registerForm.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }


  get lForm() { return this.loginForm.controls; }
  get rForm() { return this.registerForm.controls; }
  get fForm() { return this.forgotForm.controls; }

  ngOnInit(): void {

    this.router.events.subscribe((url:any) => {
         
          if(url.url !=undefined){

            url.url.split("/").forEach(element => {
                if(element ==='registration'){
                  this.hideClose = false;
                }
            })
          }
    })

    this.initLoginForm();
  }

  submitRegister() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.signup(
      this.registerForm.controls.remail.value,
      this.registerForm.controls.rpassword.value,
      this.registerForm.controls.subscribe.value)
      .subscribe(
        data => {
          $('#Register').modal('hide');
          $('#register .thank-you').css('display', 'block');
          this.loading = false;
          this.registerForm.reset()
        },
        error => {
          this.alertService.error('The email has already been taken');
          this.loading = false;
        });


  }

  lFormSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value)
      .subscribe(
        data => {
          $('#login').modal('hide');
          window.location.reload();
        },
        error => {
          this.errormsg = error.error.message;
          this.alertService.error(this.errormsg);
          this.loading = false;
        });
  }

  fFormSubmit() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }


    this.authenticationService.forgot(this.forgotForm.controls.email.value)
      .subscribe(
        data => {
          
          $('#Register').modal('hide');
          $('#LoginBlock .thank-you').css('display', 'block');
          $('#LoginBlock .forgotblk').css('display', 'hide');

          this.loading = false;
          this.forgotForm.reset()
        },
        error => {
          this.errormsg = error.error.message;
          this.alertService.error(this.errormsg);
          this.loading = false;
        });

  }

  openTab(classActive) {
    $('.toggleBlock').hide();
    $('.forgotblk').hide();
    $('.thank-you').hide();
    $('#register').hide();
    if (classActive === 'regidterblk') {
      $('.loginText').show()
    }
    $('.' + classActive).show();
  }


  loginWithGoogle() {
    this.socialLoading = 'goggle';
    this.auth.authenticate('google')
      .subscribe(
        data => {
          const user = data;
          //console.log(JSON.stringify(user.token));
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.token));
          }
          $('#login').modal('hide');
          window.location.reload();
        },
        error => {
          // this.alertService.error(error);
          this.socialLoading = '';
        });
  }

  loginWithFacebook() {
    this.socialLoading = 'facebook';
    this.auth.authenticate('facebook')
      .subscribe(
        data => {
          const user = data;
          // console.log(JSON.stringify(user.token));
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.token));
          }
          $('#login').modal('hide');
          window.location.reload();
        },
        error => {
          // this.alertService.error(error);
          this.socialLoading = '';
        });

  }

  loginWithLinkedin() {
    this.socialLoading = 'linkedin';
    this.auth.authenticate('linkedin')
      .subscribe(
        data => {
          const user = data;
          // console.log(JSON.stringify(user.token));
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.token));
          }
          $('#login').modal('hide');
          window.location.reload();
        },
        error => {
          // this.alertService.error(error);
          this.socialLoading = '';
        });

  }



}


