import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';
import { BASE_URL, FILTER_RANGE, PROFILE_TYPE } from '../../../../config/app-config.constants';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { STATE } from '../../../../static-data/state';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as noui from 'nouislider';

declare var $: any;
var moveSlider = false;

@Component({
    selector: 'app-business-listing',
    templateUrl: './business-listing.component.html',
    styleUrls: ['./business-listing.component.css']
})
export class BusinessListingComponent implements OnInit {
    jwtHelper = new JwtHelperService();
    token;
    decode;
    profileObjects;
    userID;
    Profile;
    ProfileCheck;
    seller_unique_str

    seller = {};
    mentor = {};
    startup = {};
    Business = [];
    Business_check =false;
    subheadline;
    categoryhead;
    indusrttitle;
    industry = [];
    industryloc = [];
    industryid = [];
    priceRange;
    filterLocation12;
    industrySeller;
    parentChildCategoryId;

    currentPage = 1;
    itemsPerPage = 12;
    state: any = [];
    stateArray: any = [];
    cityArray: any = [];
    city: any = [];
    catind: any = [];
    subcatind: any = [];
    loading = false;
    total = 0;
    pagination = false;
    limit = 20;
    hititle;
    BusinessType = '';
    totalItems = 0;
    minInvestment = 0;
    maxInvestment = 1000000000;
    annualsalesmin = 0;
    annualsalesmin2 = 10000;
    annualsalesmax:any = 0;
    minInvestmentValue;
    maxInvestmentValue;
    annualsalesmaxValue;
    annualsalesminValue;
    filterIndustry: any = [];
    filterLocation: any = [];
    filterLocationstate: any = [];
    filterIndustrymain: any = [];
    filters: any;
    ProfileSelected: any;
    businesslookingfor = '';
    value;
    sortby;
    minFormat = '50 k';
    maxFormat = '100 cr';
    p: any;
    i: any;
    m: any;
    public noResult = false;
    public rangeFlag = false;
    /*End start  function && object for Mentorship search*/
    public seo;
    public isViewChecked: boolean;

    public bxbannertop = '';
    public bxbannerleft = '';
    public bxbannerrightside = '';
    public bxbannercenter = '';
    public bxbannerbottom = '';
    public bannerpage = 4;
    public bxbannertopmob;
    public flag = false;
    obj = { Title: 'test', Url: 'test12' };
    @ViewChild(ListingPaginationComponent)
    private listingPagination: ListingPaginationComponent;
    public appConfig = appConfig;
    public slider_value;
    public bxBookmarkCount;
    public bxBookmarkStatus = 0;
    public SavedSearchdata;
    testDataValue:any = [];
    checkCurrectCity = false;
    getindustrysubCat ='';
    getindustrymainCat ='';
    getindustrysubSlug='';

    @ViewChild('sliderPrice', { read: ElementRef }) sliderPrice: ElementRef;
    //@ViewChild('contentComponent', {static: false}) contentElement: ElementRef;
    public sliderNo: noui.noUiSlider;

    sliderRange;
    someKeyboardConfig: any = {
        connect: true,
        start: [0, this.maxInvestment],
        step: 0.5,
        tooltips: [false, false],
        range: {
          'min': 0,
          '2%': 200*1.5*this.annualsalesmin2,
          '4%':200*3*this.annualsalesmin2,
          '8%':200*4.5*this.annualsalesmin2,
          '10%':200*6*this.annualsalesmin2,
          '12%':200*7.5*this.annualsalesmin2,
          '14%':200*9*this.annualsalesmin2,
          '16%': 200*10.5*this.annualsalesmin2,
          '18%': 200*12*this.annualsalesmin2,
          '20%': 200*13.5*this.annualsalesmin2,
          '22%': 200*15*this.annualsalesmin2,
          '24%': 200*16.5*this.annualsalesmin2,
          '26%': 200*18*this.annualsalesmin2,
          '28%': 200*19.5*this.annualsalesmin2,
          '32%': 200*21*this.annualsalesmin2,
          '34%': 200*22.5*this.annualsalesmin2,
          '36%': 200*24*this.annualsalesmin2,
          '38%': 200*25*this.annualsalesmin2,
          '40%': 200*26.5*this.annualsalesmin2,
          '42%': 200*28*this.annualsalesmin2,
          '46%': 200*29.5*this.annualsalesmin2,
          '48%': 200*31*this.annualsalesmin2,
          '50%': 200*32.5*this.annualsalesmin2,
          '52%': 200*34*this.annualsalesmin2,
          '54%': 200*35.5*this.annualsalesmin2,
          '56%': 200*37*this.annualsalesmin2,
          '58%': 200*38.5*this.annualsalesmin2,
          '60%': 200*40*this.annualsalesmin2,
          '62%': 200*41.5*this.annualsalesmin2,
          '64%': 200*43*this.annualsalesmin2,
          '66%': 200*44.5*this.annualsalesmin2,
          '68%': 200*46*this.annualsalesmin2,
          '70%': 200*47.5*this.annualsalesmin2,
          '72%': 200*49*this.annualsalesmin2,
          '74%': 200*50.5*this.annualsalesmin2,
          '76%': 200*52*this.annualsalesmin2,
          '78%': 200*53.5*this.annualsalesmin2,
          '80%': 200*55*this.annualsalesmin2,
          '82%': 200*56.5*this.annualsalesmin2,
          '84%': 200*58*this.annualsalesmin2,
          '86%': 200*59.5*this.annualsalesmin2,
          '88%': 200*61*this.annualsalesmin2,
          '90%': 200*62.5*this.annualsalesmin2,
          '92%': 200*64*this.annualsalesmin2,
          '94%': 200*65.5*this.annualsalesmin2,
          '96%': 200*67*this.annualsalesmin2,
          '98%': 200*68.5*this.annualsalesmin2,
          'max': this.maxInvestment
        },
        behaviour: 'drag',
    };

    constructor(private linkService: LinkService, private meta: Meta, private title: Title,
        private homeService: HomeService, private route: ActivatedRoute,
        private router: Router,
        private contactService: ContactService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private historyLocation: Location) {

        this.minInvestmentValue = appConfig.PriceSlideFormate(this.minInvestment);
        this.maxInvestmentValue = appConfig.PriceSlideFormate(this.maxInvestment);

        this.annualsalesminValue = appConfig.PriceSlideFormate(this.annualsalesmin);
        this.annualsalesmaxValue = appConfig.PriceSlideFormate(1000000000);



        this.isViewChecked = false;
        this.route.paramMap.subscribe((params: Params) => {

            this.loading = true;

            this.reInitializeVariables();

            const catId = params.get('selecat');

            const param1 = +this.route.snapshot.queryParams['page'];

            if (param1 !== undefined && param1 > 0) {
                this.currentPage = param1;
            }

            this.homeService.getfiltersBusiness()
                .subscribe(
                    result => {
                        this.priceRange = result.minMaxInvestment;
                        this.filterLocation12 = result.Location;
                        this.industrySeller = result.industrySeller;
                        this.parentChildCategoryId = result.parentChildCategoryId;
                        if (params.get('business-opportunities') === null && catId === null && params.get('businesstitle') === null && params.get('businessType') === null) {
                            this.callSellerService();
                        }

                        if (params.get('businessType')) {
                            if (params.get('businessType').search('sale') > -1) {
                                this.BusinessType = 'sale';
                            } else if (params.get('businessType').search('investment') > -1) {
                                this.BusinessType = 'investment';
                            } else if (params.get('businessType').search('seeking-loan') > -1) {
                                this.BusinessType = 'seeking-loan';
                            }
                            this.callSellerService();
                        }

                        if (params.get('businesstitle')) {

                            if (params.get('businesstitle').search('sale') > -1) {
                                this.BusinessType = 'sale';
                            } else if (params.get('businesstitle').search('investment') > -1) {
                                this.BusinessType = 'investment';
                            } else if (params.get('businesstitle').search('seeking-loan') > -1) {
                                this.BusinessType = 'seeking-loan';
                            }
                            if (params.get('selecat') !== 'c0') {
                                this.catind = params.get('selecat').split('-').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            }

                            if (params.get('selesubcat') !== 's0') {
                                this.subcatind = params.get('selesubcat').split('-').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            }

                            this.loading = true;

                            if (params.get('state') !== 'st0') {
                                this.state = params.get('state').split('-');
                            }

                            

                            let paramsCity = [];

                            if (params.get('city') !== null && params.get('city') !== '' && params.get('city') !== 'ct0') {
                                paramsCity = params.get('city').split('-').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            }

                            const newState = [];
                            if (this.state) {
                                Object.keys(STATE).forEach(function (key) {
                                    newState[STATE[key]] = key;
                                });

                                for (let i = 0; i < this.state.length; ++i) {
                                    this.filterLocationstate[newState[this.state[i]]] = true;

                                    for (let ml = 0; ml < this.filterLocation12.length; ++ml) {
                                        const locationFilter = this.filterLocation12[ml];

                                        if (locationFilter.ofc_state === newState[this.state[i]] &&
                                            (paramsCity.length === 0 || paramsCity.indexOf(locationFilter.cityId) > -1)) {

                                            this.pushCityState(locationFilter);
                                            this.checkCurrectCity = true;

                                        }else if(locationFilter.ofc_state === newState[this.state[i]]){
                                            this.testDataValue.push(locationFilter)
                                        }
                                    }

                                   
                                }

                                if(paramsCity.length==1 && this.state.length==1 && this.checkCurrectCity ==false && this.testDataValue.length>0){
                                    this.router.navigate(['/businesslisting/businesses-'+this.testDataValue[0].stateSlug+'/c0/s0/'+this.state[0]]);
                                }

                            }

                            

                            if (this.catind.length === 1 && params.get('selesubcat') === 's0') {
                                this.subcatind = this.parentChildCategoryId[this.catind[0]].split('-').map(function (item) {
                                    return parseInt(item, 10);
                                });
                            }

                            for (let j = 0; j < this.subcatind.length; ++j) {
                                this.filterIndustry[this.subcatind[j]] = true;
                                this.getindustrysub(this.subcatind[j]);
                            }
                            for (let k = 0; k < this.catind.length; ++k) {
                                this.filterIndustrymain[this.catind[k]] = true;
                                this.getindustrymain(this.catind[k]);
                            }

                            if(this.catind.length==1 && this.subcatind.length==1 && this.getindustrysubCat != this.getindustrymainCat){
                                this.router.navigate(['/businesslisting/'+this.getindustrysubSlug+'-businesses/'+this.catind+'/s0/st0']);
                            }


                            this.callSellerService();
                        }


                        if (params.get('business-opportunities') === 'business-opportunities') {
                            this.rangeFlag = true;
                            this.BusinessType = 'sale';
                            const range = params.get('businessprice');
                            if (range === 'business-under-50k') {
                                this.minInvestment = 0;
                                this.maxInvestment = 50000;
                            } else if (range === 'business-under-50k-2lakh') {
                                this.minInvestment = 50000;
                                this.maxInvestment = 200000;
                            } else if (range === 'business-under-2lakh-5lakh') {
                                this.minInvestment = 200000;
                                this.maxInvestment = 500000;
                            } else if (range === 'business-under-5lakh-10lakh') {
                                this.minInvestment = 500000;
                                this.maxInvestment = 1000000;
                            } else if (range === 'business-under-10lakh-20lakh') {
                                this.minInvestment = 1000000;
                                this.maxInvestment = 2000000;
                            } else if (range === 'business-under-20lakh-30lakh') {
                                this.minInvestment = 2000000;
                                this.maxInvestment = 3000000;
                            } else if (range === 'business-under-30lakh-50lakh') {
                                this.minInvestment = 3000000;
                                this.maxInvestment = 5000000;
                            } else if (range === 'business-under-50lakh-1cr') {
                                this.minInvestment = 5000000;
                                this.maxInvestment = 10000000;
                            } else if (range === 'business-under-1cr-2cr') {
                                this.minInvestment = 10000000;
                                this.maxInvestment = 20000000;
                            } else if (range === 'business-under-2cr-5cr') {
                                this.minInvestment = 20000000;
                                this.maxInvestment = 50000000;
                            } else if (range === 'business-under-5cr-10cr') {
                                this.minInvestment = 50000000;
                                this.maxInvestment = 100000000;
                            } else if (range === 'business-under-10cr-20cr') {
                                this.minInvestment = 100000000;
                                this.maxInvestment = 200000000;
                            } else if (range === 'business-under-20cr-50cr') {

                                this.minInvestment = 200000000;
                                this.maxInvestment = 500000000;
                            } else if (range === 'business-under-50cr-100cr') {
                                this.minInvestment = 500000000;
                                this.maxInvestment = 10000000000;
                            } else if (range === 'business-under-100cr-or-above') {
                                this.minInvestment = 10000000000;
                                this.maxInvestment = 100000000000;
                            }
                            if (this.minInvestment >= 10000000) {
                                this.minFormat = (this.minInvestment / 10000000).toFixed(0) + ' cr';
                            } else if (this.minInvestment >= 100000) {
                                this.minFormat = (this.minInvestment / 100000).toFixed(0) + ' lakh';
                            } else if (this.minInvestment >= 1000) {
                                this.minFormat = (this.minInvestment / 1000).toFixed(0) + ' k';
                            } else if (this.minInvestment === 0) {
                                this.minFormat = 0 + '';
                            }
                            if (this.maxInvestment >= 10000000) {
                                this.maxFormat = (this.maxInvestment / 10000000).toFixed(0) + ' cr';
                            } else if (this.maxInvestment >= 100000) {
                                this.maxFormat = (this.maxInvestment / 100000).toFixed(0) + ' lakh';
                            } else if (this.maxInvestment >= 1000) {
                                this.maxFormat = (this.maxInvestment / 1000).toFixed(0) + ' k';
                            }
                            this.callSellerService();
                        }
                        this.slider_value = this.filters.tags.filter(item => (item.name === this.BusinessType))[0];
                    }
                );
        });


        this.filters = {
            query: '',
            type: '',
            tags: [
                {
                    label: 'All',
                    name: '',
                    text: '',
                    sort: [/*{
                    label: 'Selling Price (Highest first)',
                    value: 'desc'
                }, {
                    label: 'Selling Price (Lowest first)',
                    value: 'asc'
                },{
                    label: 'Recently Listed',
                    value: 'created_at'
                }*/]
                },
                {
                    label: 'Sale',
                    name: 'sale',
                    text: 'SALE AMOUNT SIZE',
                    sort: [{
                        label: 'Recently Listed',
                        value: 'created_at'
                    }, {
                        label: 'Selling Price (Highest first)',
                        value: 'desc'
                    }, {
                        label: 'Selling Price (Lowest first)',
                        value: 'asc'
                    },]
                },
                {
                    label: 'Investor',
                    name: 'investment',
                    text: 'INVESTMENT SIZE',
                    sort: [{
                        label: 'Recently Listed',
                        value: 'created_at'
                    }, {
                        label: 'Investment Required (Highest first)',
                        value: 'desc'
                    }, {
                        label: 'Investment Required (Lowest first)',
                        value: 'asc'
                    },]
                },
                {
                    label: 'Loan',
                    name: 'seeking-loan',
                    text: 'LENDING CAPACITY',
                    sort: [{
                        label: 'Recently Listed',
                        value: 'created_at'
                    }, {
                        label: 'Loan Required (Highest first)',
                        value: 'desc'
                    }, {
                        label: 'Loan Required (Lowest first)',
                        value: 'asc'
                    },]
                }/*,
            {
                label: 'Incubators',
                name: 'Incubators',
                text: 'INVESTMENT SIZE',
                selected: false,
                sort: [{
                    label: 'Recently Listed',
                    value: 'created_at'
                },{
                    label: 'Investment Required (Highest first)',
                    value: 'desc'
                }, {
                    label: 'Investment Required (Lowest first)',
                    value: 'asc'
                },]
            }*/
            ]
        };

    }


    checkLoginToken(): void {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.userID = this.decode.sub;
            this.Profile = this.decode.userProfile;
            this.ProfileCheck = this.decode.userProfile[0].profile_id;
        } else {
            this.Profile = [{
                'profile_id': 0,
                'profile_type': '',
                'profile_status': '',
                'profile_name': '',
                'profile_str': '',
                'payment_status': 0
            }];
            this.ProfileCheck = this.Profile[0].profile_id;
            this.userID = 0;
        }
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: Params) => {
            this.seller_unique_str = params.get('sellerUniqueId');
            this.checkLoginToken();
        })

        var html5Slider = document.getElementById('html5');

        this.sliderNo = noui.create(html5Slider, {
            start: [0, this.maxInvestment],
            step: 0.5,
            connect: true,
            tooltips: [false, false],
            range: {
              'min': 0,
              '2%': 200*1.5*this.annualsalesmin2,
              '4%':200*3*this.annualsalesmin2,
              '8%':200*4.5*this.annualsalesmin2,
              '10%':200*6*this.annualsalesmin2,
              '12%':200*7.5*this.annualsalesmin2,
              '14%':200*9*this.annualsalesmin2,
              '16%': 200*10.5*this.annualsalesmin2,
              '18%': 200*12*this.annualsalesmin2,
              '20%': 200*13.5*this.annualsalesmin2,
              '22%': 200*15*this.annualsalesmin2,
              '24%': 200*16.5*this.annualsalesmin2,
              '26%': 200*18*this.annualsalesmin2,
              '28%': 200*19.5*this.annualsalesmin2,
              '32%': 200*21*this.annualsalesmin2,
              '34%': 200*22.5*this.annualsalesmin2,
              '36%': 200*24*this.annualsalesmin2,
              '38%': 200*25*this.annualsalesmin2,
              '40%': 200*26.5*this.annualsalesmin2,
              '42%': 200*28*this.annualsalesmin2,
              '46%': 200*29.5*this.annualsalesmin2,
              '48%': 200*31*this.annualsalesmin2,
              '50%': 200*32.5*this.annualsalesmin2,
              '52%': 200*34*this.annualsalesmin2,
              '54%': 200*35.5*this.annualsalesmin2,
              '56%': 200*37*this.annualsalesmin2,
              '58%': 200*38.5*this.annualsalesmin2,
              '60%': 200*40*this.annualsalesmin2,
              '62%': 200*41.5*this.annualsalesmin2,
              '64%': 200*43*this.annualsalesmin2,
              '66%': 200*44.5*this.annualsalesmin2,
              '68%': 200*46*this.annualsalesmin2,
              '70%': 200*47.5*this.annualsalesmin2,
              '72%': 200*49*this.annualsalesmin2,
              '74%': 200*50.5*this.annualsalesmin2,
              '76%': 200*52*this.annualsalesmin2,
              '78%': 200*53.5*this.annualsalesmin2,
              '80%': 200*55*this.annualsalesmin2,
              '82%': 200*56.5*this.annualsalesmin2,
              '84%': 200*58*this.annualsalesmin2,
              '86%': 200*59.5*this.annualsalesmin2,
              '88%': 200*61*this.annualsalesmin2,
              '90%': 200*62.5*this.annualsalesmin2,
              '92%': 200*64*this.annualsalesmin2,
              '94%': 200*65.5*this.annualsalesmin2,
              '96%': 200*67*this.annualsalesmin2,
              '98%': 200*68.5*this.annualsalesmin2,
              'max': this.maxInvestment
            },
            behaviour: 'drag',
          });

          this.sliderNo.on('change',this.btnPriceFilter.bind(this))


    }

    btnPriceFilter(){
        let pAraay = this.sliderNo.get();
        if(pAraay.length){
            this.priceFilter(pAraay)
        }
   }

    


    private reInitializeVariables() {

        this.filterIndustrymain = [];
        this.filterIndustry = [];
        this.catind = [];
        this.industry = [];
        this.subcatind = [];
        this.stateArray = [];
        this.filterLocationstate = [];
        this.filterLocation = [];
        this.cityArray = [];
        this.state = [];
        this.city = [];
    }

    private callSellerService() {
        this.homeService.getBusinesslistingDemo(this.currentPage, this.state, this.cityArray, this.catind, this.subcatind,this.BusinessType, this.sortby, this.itemsPerPage, this.maxInvestment, this.minInvestment,
            this.annualsalesmin, this.annualsalesmax)
            
            .subscribe(result => {
                //console.log(result)
                this.Business = result.businesslist;   
                console.log(this.Business)             
                this.totalItems = result.businessCount;
                this.categoryhead = result.categoryheadline;
                this.seo = result.seo;
                this.noResult = this.Business.length === 0;
                this.loading = false;
                this.setTitleMeta();
                this.currentPage = (this.Business.length === 0) ? 0 : this.currentPage;
            }
            );

            this.Business_check = true
    }

    private setTitleMeta() {
        this.subheadline = this.seo.description;
        this.hititle = this.seo.title;
        this.title.setTitle(this.seo.title + ' - BusinessEx');

        this.meta.updateTag({
        property: 'og:title',
        content: this.seo.title + ' - BusinessEx'
        });


        this.meta.updateTag({
        name: 'twitter:title',
        content: this.seo.title + ' - BusinessEx'
        });

        this.meta.updateTag({property: 'og:url', content: BASE_URL + this.historyLocation.path()});
        this.meta.updateTag({name: 'twitter:url', content: BASE_URL + this.historyLocation.path()});

        this.meta.updateTag({
            name: 'Description',
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
                .replace(/(\r\n\t|\n|\r\t)/gm, '')
                .replace('&lsquo;', '').replace('&rsquo;', '')
                .substring(0, 400)
        });

        this.meta.updateTag({ property: 'og:description', 
            content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
            .replace(/(\r\n\t|\n|\r\t)/gm, '')
            .replace('&lsquo;', '').replace('&rsquo;', '')
            .substring(0, 400)
        });
        this.meta.updateTag({ name: 'twitter:description', 
             content: this.seo.meta_description.replace(/<[^>]+>/gm, '')
                .replace(/(\r\n\t|\n|\r\t)/gm, '')
                .replace('&lsquo;', '').replace('&rsquo;', '')
                .substring(0, 400)
        });
        

        this.meta.updateTag({ name: 'Keywords', content: this.seo.keyword });

        this.linkService.removeCanonicalLink();
        //this.linkService.removeAmpHtmlCanonicalLink();
        const totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 0;
        let pNumber = 0;
        if (this.currentPage > 1 && this.currentPage <= totalPages) {
            pNumber = this.currentPage - 1;
            this.linkService.addTag({
                rel: 'prev',
                href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
            });
        }

        this.linkService.addTag({
            rel: 'canonical',
            href: BASE_URL + this.historyLocation.path()
        });

        // this.linkService.addTag({
        //     rel: 'amphtml',
        //     href: BASE_URL + '/amp' + this.historyLocation.path()
        // });

        if (this.currentPage >= 1 && this.currentPage < totalPages) {
            pNumber = this.currentPage + 1;
            this.linkService.addTag({
                rel: 'next',
                href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
            });
        }
        if (isPlatformBrowser(this.platformId)) {
            if (this.seo.description.length > 1500) {
                $('#more').css('display', 'block');
            } else {
                $('#more').css('display', 'none');
            }
        }
    }

    private getIndustryOrSubIndustry() {
        if (this.catind.length === 1) {
            const industry = this.industrySeller
                .filter(item => (item.industryid === this.catind[0] && item.subIndustryid === this.subcatind[0]))[0];
            if (this.subcatind.length === 1) {
                const newCat = this.parentChildCategoryId[this.catind[0]].split('-').map(function (item) {
                    return parseInt(item, 10);
                });
                return (newCat.length === this.subcatind.length) ? industry.industry : industry.subindustry;
            }
            return (industry !== undefined) ? industry.industry : '';
        }
        return '';
    }

    private getSubCatIndexes() {
        const newCat = this.parentChildCategoryId[this.catind[0]].split('-').map(function (item) {
            return parseInt(item, 10);
        });
        return (newCat.length === this.subcatind.length) ? 's0' : this.subcatind.join('-');
    }

    parentChange(value) {

        this.slider_value = value;
        this.minInvestment = 0;
        this.annualsalesmin = 0;
        this.maxInvestment = 1000000000;
        this.annualsalesmax = value.label =='All' ? '0' :  '1000000000';
        this.minFormat = '50 k';
        this.maxFormat = '100 cr';
        /*$('#saleamount-range').each(function () {
            var options = $(this).slider_value('option');
            $(this).slider_value('values', [options.min, options.max]);
        });
        $('#annualsales-range').each(function () {
            var options = $(this).slider_value('option');
            $(this).slider_value('values', [options.min, options.max]);
        });*/
        this.currentPage = 1;
        this.BusinessType = value.name;
        this.getBusinesslisting();
        setTimeout(() => {
            $('.selectpicker').val('');
        }, 10);
    }


    pushCityState(locationFilter: any) {

        this.filterLocation[locationFilter.ofc_city] = true;

        if (this.industryloc.indexOf(locationFilter.ofc_state) === -1) {
            this.industryloc.push(locationFilter.ofc_state);
        }
        if (this.stateArray.indexOf(locationFilter.ofc_state) === -1) {
            this.stateArray.push(locationFilter.ofc_state);
        }
        if (this.cityArray.indexOf(locationFilter.ofc_city) === -1) {
            this.cityArray.push(locationFilter.ofc_city);
        }
        if (this.city.indexOf(locationFilter.cityId) === -1) {
            this.city.push(locationFilter.cityId);
        }
    }


    includeindustry(catind, subcatind, industry1, industrymain, subid) {
        this.currentPage = 1;
        this.pagination = false;
        const n = $('input:checkbox[id^=' + catind + ']:checked').length;
        this.isViewChecked = false;
        if (n === 1) {
            if (this.filterIndustrymain[catind]) {
                if ($.inArray(catind, this.catind) > -1) {
                    this.catind.splice(this.catind.indexOf(catind), 1);
                    $('input:checkbox[id^=' + catind + ']').removeAttr('checked');
                    this.filterIndustrymain[catind] = false;
                }
                if ($.inArray(industrymain, this.industry) > -1) {
                    this.industry.splice(this.industry.indexOf(industrymain), 1);
                }
                if (subid == '') { //if there is only single sub category
                    subid = this.subcatind[0];
                }
                if ($.inArray(parseInt(subid, 10), this.subcatind) > -1) {
                    this.subcatind.splice(this.subcatind.indexOf(parseInt(subid, 10)), 1);
                    this.filterIndustry[parseInt(subid, 10)] = false;
                }
                this.getBusinesslisting();
                return;
            }
        }

        if (industry1 !== '') {
            if ($.inArray(industry1, this.industry) > -1) {
                this.industry.splice(this.industry.indexOf(industry1), 1);
            } else {
                this.industry.push(industry1);
            }
        }

        if (catind !== '') {
            if ($.inArray(catind, this.catind) > -1 && subcatind === '') {
                if (this.filterIndustrymain[catind]) {
                    $('input:checkbox[id^=' + catind + ']').removeAttr('checked');
                    this.catind.splice(this.catind.indexOf(catind), 1);
                    for (let index = 0; index < this.industrySeller.length; ++index) {
                        const subIndustryid1 = this.industrySeller[index];
                        if (subIndustryid1.industry === industry1) {
                            this.filterIndustry[subIndustryid1.subIndustryid] = false;
                            if (this.subcatind.indexOf(subIndustryid1.subIndustryid) > -1) {
                                this.subcatind.splice(this.subcatind.indexOf(subIndustryid1.subIndustryid), 1);
                            }
                            if (this.industry.indexOf(subIndustryid1.subindustry) > -1) {
                                this.industry.splice(this.industry.indexOf(subIndustryid1.subindustry), 1);
                            }
                        }
                    }
                }

            } else {
                for (let index = 0; index < this.industrySeller.length; ++index) {
                    const subIndustryid = this.industrySeller[index];
                    if (subIndustryid.industry === industry1) {
                        this.catind.push(catind);
                        this.filterIndustry[subIndustryid.subIndustryid] = true;
                        this.industryid.push(subIndustryid.subIndustryid);
                        this.industry.push(subIndustryid.subindustry);
                        this.subcatind.push(subIndustryid.subIndustryid);
                    } else if (subIndustryid.industryid === catind) {
                        this.industry.push(subIndustryid.industry);
                    }
                }
            }

            if ($.inArray(subcatind, this.subcatind) > -1 && subcatind !== '') {
                this.subcatind.splice(this.subcatind.indexOf(subcatind), 1);
                this.filterIndustry[subcatind] = false;
                this.industry.splice(this.industry.indexOf(industry1), 1);
            } else if (subcatind !== '') {
                this.subcatind.push(subcatind);
                this.filterIndustry[subcatind] = true;
                this.catind.push(catind);
                this.filterIndustrymain[catind] = true;
            }
            this.subcatind = appConfig.unique(this.subcatind);
            this.industry = appConfig.unique(this.industry);
            this.catind = appConfig.unique(this.catind);
            this.getBusinesslisting();

        }
    }



    getindustrysub(industry1) {
        for (let index = 0; index < this.industrySeller.length; ++index) {
            const subIndustryid = this.industrySeller[index];
            if (subIndustryid.subIndustryid === industry1) {
                this.industry.push(subIndustryid.industry);
                this.getindustrysubCat = subIndustryid.industry;   
            }
        }
    }

    getindustrymain(industry1) {
        for (let index = 0; index < this.industrySeller.length; ++index) {
            const subIndustryid = this.industrySeller[index];
            if (subIndustryid.industryid === industry1) {
                this.industry.push(subIndustryid.industry);
                this.getindustrymainCat = subIndustryid.industry
                this.getindustrysubSlug = subIndustryid.industrySlug;
            }
        }
    }

    getBusinesslisting() {
        let urlBType = '';
        if (this.BusinessType === 'sale') {
            urlBType = '-for-sale';
        } else if (this.BusinessType === 'investment') {
            urlBType = '-for-investment';
        } else if (this.BusinessType === 'seeking-loan') {
            urlBType = '-seeking-loan';
        }
        this.loading = true;

        function Stringrep(items) {
            return items.replace(/\s/g, '-').toLowerCase();
        }

        const city = (this.stateArray.length === 1) ?
            (this.city.length === 1) ? '-in-' + appConfig.getSlug(this.cityArray[0]) : '-in-'
                + this.stateArray.map(Stringrep).join('-') : '';

        if (this.catind.length === 0 && this.subcatind.length === 0 && this.stateArray.length === 0) {
            const bType = (this.BusinessType) ? '/' + this.BusinessType : '';
            history.pushState(this.obj, this.obj.Title, 'businesslisting' + bType);
            this.industry = [];
        }
        if (this.catind.length === 0 && this.subcatind.length === 0 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/businesses' + urlBType + city + '/c0/s0/' + this.state.join('-'));
        }
        if (this.catind.length === 0 && this.subcatind.length === 0 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/businesses' + urlBType + '/c0/s0/' + this.state.join('-'));
        }
        if (this.catind.length === 1 && this.subcatind.length > 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-businesses' + urlBType + '/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/st0');
        }

        if (this.catind.length === 1 && this.subcatind.length === 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-businesses' + urlBType + '/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/st0');
        }

        if (this.catind.length === 1 && this.subcatind.length === 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry()) + '-businesses' + urlBType + city + '/'
                + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/' + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length === 0 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.industry[0]) + '-businesses' + urlBType + city
                + '/' + this.catind.join('-') + '/' + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length === 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry())
                + '-businesses' + urlBType + '/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/' + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length > 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry())
                + '-businesses' + urlBType + '/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/' + this.state.join('-'));
        }

        if (this.catind.length === 1 && this.subcatind.length > 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/' + appConfig.getSlug(this.getIndustryOrSubIndustry())
                + '-businesses' + urlBType + city + '/' + this.catind.join('-') + '/' + this.getSubCatIndexes() + '/' + this.state.join('-'));
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length === 0) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/businesses' + urlBType + '/' + this.catind.join('-') + '/'
                + this.subcatind.join('-') + '/st0');
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length === 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/businesses' + urlBType + city + '/' +
                this.catind.join('-') + '/' + this.subcatind.join('-') + '/'
                + this.state.join('-'));
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/businesses' + urlBType + '/' + this.catind.join('-')
                + '/' + this.subcatind.join('-') + '/' + this.state.join('-'));
        }

        if (this.catind.length > 1 && this.subcatind.length > 1 && this.stateArray.length > 1) {
            history.pushState(this.obj, this.obj.Title, 'businesslisting/businesses' + urlBType + '/' + this.catind.join('-')
                + '/' + this.subcatind.join('-') + '/' + this.state.join('-'));
        }
        if (this.stateArray.length >= 1) {
            const newCat = this.filterLocation12.filter(item => (item.state === this.state[0]));
            if ((this.stateArray.length === 1 && newCat.length !== this.city.length) || this.stateArray.length > 1) {
                history.pushState(this.obj, this.obj.Title, this.historyLocation.path() + '/' + this.city.join('-'));
            }
        }
        if (this.pagination) {
            history.pushState(this.obj, this.obj.Title, this.historyLocation.path() + '?page=' + this.currentPage);
        }
        this.callSellerService();
    }

    goToPage(n: number): void {
        this.currentPage = n;
        this.pagination = true;
        this.getBusinesslisting();
    }

    onNext(): void {
        this.currentPage++;
        this.pagination = true;
        this.getBusinesslisting();
    }

    onPrev(): void {
        this.currentPage--;
        this.pagination = true;
        this.getBusinesslisting();
    }

    onChangeObj(sortby) {
        this.sortby = sortby;
        this.getBusinesslisting();
    }

    priceFilter(event) {

        this.annualsalesminValue = appConfig.PriceSlideFormate(event[0]);
        this.annualsalesmaxValue = appConfig.PriceSlideFormate(event[1]);

        this.annualsalesmin = event[0];
        this.annualsalesmax = event[1];
        this.getBusinesslisting();
    }

    includeCity(State, shortsate, City, CityId, industrymainloc) {

        CityId = parseInt(CityId, 10);
        this.currentPage = 1;
        this.pagination = false;
        if ($('input:checkbox[id^=' + shortsate + ']:checked').length === 1) {
            if (this.filterLocationstate[State]) {
                if ($.inArray(State, this.stateArray) > -1) {
                    this.stateArray.splice(this.stateArray.indexOf(State), 1);
                    this.state.splice(this.state.indexOf(shortsate), 1);
                    this.filterLocationstate[State] = false;
                }
                this.filterLocation12.filter(item => (item.state === shortsate)).forEach(newItem => {
                    if (this.cityArray.indexOf(newItem.ofc_city) > -1) {
                        this.cityArray.splice(this.cityArray.indexOf(newItem.ofc_city), 1);
                        this.city.splice(this.city.indexOf(newItem.CityId), 1);
                        this.filterLocation[newItem.ofc_city] = false;
                    }
                });
                this.getBusinesslisting();
                return;
            }
        }
        if (State !== '') {
            if ($.inArray(State, this.stateArray) > -1 && City === '') {
                if (this.filterLocationstate[State]) {
                    $('input:checkbox[id^=' + shortsate + ']').removeAttr('checked');
                    if (this.industryloc.indexOf(State) > -1) {
                        this.industryloc.splice(this.industryloc.indexOf(State), 1);
                    }
                    if (this.stateArray.indexOf(State) > -1) {
                        this.stateArray.splice(this.stateArray.indexOf(State), 1);
                    }
                    if (this.state.indexOf(shortsate) > -1) {
                        this.state.splice(this.state.indexOf(shortsate), 1);
                    }
                    for (let index = 0; index < this.filterLocation12.length; ++index) {
                        const locationFilter1 = this.filterLocation12[index];
                        if (locationFilter1.ofc_state === State) {
                            this.filterLocation[locationFilter1.ofc_city] = false;
                            if (this.cityArray.indexOf(locationFilter1.ofc_city) > -1) {
                                this.cityArray.splice(this.cityArray.indexOf(locationFilter1.ofc_city), 1);
                            }
                            if (this.city.indexOf(parseInt(locationFilter1.cityId, 10)) > -1) {
                                this.city.splice(this.city.indexOf(parseInt(locationFilter1.cityId, 10)), 1);
                            }
                        }
                    }
                }

            } else {
                for (let ml = 0; ml < this.filterLocation12.length; ++ml) {
                    const locationFilter = this.filterLocation12[ml];
                    if (locationFilter.ofc_state === industrymainloc) {
                        this.pushCityState(locationFilter);
                    } else if (locationFilter.ofc_city === City) {
                        this.industryloc.push(locationFilter.ofc_state);
                    }
                }
            }
            if ($.inArray(City, this.cityArray) > -1 && City !== '') {
                if (this.cityArray.indexOf(City) > -1) {
                    this.cityArray.splice(this.cityArray.indexOf(City), 1);
                    this.city.splice(this.city.indexOf(CityId), 1);
                }
            } else if (City !== '') {
                if ($.inArray(City, this.cityArray) === -1) {
                    this.cityArray.push(City);
                }
                if ($.inArray(CityId, this.city) === -1) {
                    this.city.push(CityId);
                }
                if ($.inArray(State, this.stateArray) === -1) {
                    this.stateArray.push(State);
                }
                this.filterLocationstate[State] = true;
            }
            this.stateArray.forEach(newstate => {
                this.state.push(STATE[newstate]);
            });
            this.state = appConfig.unique(this.state);
            this.getBusinesslisting();
        }
    }


    getIndustryName(industryId, subIndustryId = 0): any {
        if (this.industrySeller === undefined || this.industrySeller === '' || industryId === '') {
            return '';
        }
        let myIndustry = this.industrySeller.filter(item => (item.industryid === industryId));
        if (subIndustryId !== 0) {
            myIndustry = this.industrySeller.filter(item => (item.industryid === industryId && item.subIndustryid === subIndustryId));
        }
        return myIndustry[0];
    }

    toggle(wantBookMark): any {
        this.bxBookmarkStatus = wantBookMark;
        this.contactService.saveSavedSearches(this.userID, PROFILE_TYPE.Business, this.seller_unique_str, wantBookMark)
            .then((result) => {
                this.SavedSearchdata = result;
            });
    }


    ngAfterViewInit() {

        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {

                $(".accordion_head").click(function () {
                    if ($(this).next(".accordion_body").is(':visible')) {
                        $(this).next(".accordion_body").slideUp(300);
                        $(this).children(".plusminus").addClass('add');
                        $(this).children(".plusminus").removeClass('minus');
                    } else {
                        $(this).next(".accordion_body").slideDown(300);
                        $(this).children(".plusminus").addClass('minus');
                        $(this).children(".plusminus").removeClass('add');
                    }
                });

                /*$(".accordion_headmain").click(function () {

                    if ($(this).next(".accordion_bodymain").is(':visible')) {
                        $(this).next(".accordion_bodymain").slideUp(300);
                        $(this).children(".rightdown").addClass('rightval');
                        $(this).children(".rightdown").removeClass('downval');
                    } else {
                        $(this).next(".accordion_bodymain").slideDown(300);
                        $(this).children(".rightdown").addClass('downval');
                        $(this).children(".rightdown").removeClass('rightval');
                    }
                });*/

                $("#showftr").click(function () {
                    $(".catleft").show(400);
                });

                $("#closeftr").click(function () {
                    $(".catleft").hide(400);
                });
            }, 50)

        }
    }



}
