import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { APP_CONFIG, BASE_URL } from '../../../../config/app-config.constants';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';
import { isPlatformBrowser, Location } from '@angular/common';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from 'projects/frontend/src/app/config/app-config.interface';
import { result } from 'lodash';
import {faTimesCircle as faTimesCircle,faShareAlt as faShareAlt} from '@fortawesome/free-solid-svg-icons/'


declare var $: any;

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})


export class ArticleComponent implements OnInit {
  faShareAlt = faShareAlt;
  faTimesCircle = faTimesCircle;
  currentUrl=window.location.href;
  public article_id;
  public articletitle;
  public bxArticleDetailData;
  public bxarticlesimilardata;
  public bxlength;
  public bxbannertop = '';
  public bxbannerleft = '';
  public bxbannerrightside = '';
  public bxbannercenter = '';
  public bxbannerbottom = '';
  public bannerpage = 13;
  public isViewChecked: boolean;
  public appConfig = appConfig;
  submitMessage;
  recomendateArticel:any;
  latestArticle:any;
  ArticelByAuthor:any;
  loading = true;


  constructor(private linkService: LinkService, private meta: Meta, private titleService: Title,
    private title: Title, private contactService: ContactService,
    private http: HttpClient, private homeService: HomeService,
    private route: ActivatedRoute,
    private router : Router,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_CONFIG) private config: IAppConfig,
    private historyLocation: Location) {
    this.isViewChecked = false;
  }


  saveComment(data) {
    const formSelector = $('form[name="commentForm"]');
    if (formSelector.validator('validate').has('.has-error').length) {
      return;
    }
    data.article_id = this.article_id;
    formSelector.find('button[type="submit"]').button('loading');
    this.http.post(this.config.apiEndpoint + '/articleComment', data).subscribe(
      response => {
        $('#newscontainer .form-control').val('');
        $('#newscontainer').css('display', 'none');
        $('#newsthankscontainer').css('display', 'block');
      },
      error => {
        appConfig.scrollToError(false);
        this.submitMessage = error.error;
        formSelector.find('button[type="submit"]').button('reset');
      });
  }

  ngOnInit() {
    
    this.route.paramMap.subscribe((params: Params) => {

      this.contactService.getBanners(this.bannerpage)
        .then((result) => {
          this.bxbannerbottom = result.bottom;
          this.bxbannercenter = result.middle;
          this.bxbannerleft = result.left;
          this.bxbannerrightside = result.right;
          this.bxbannertop = result.top;
          this.loading = false;
        });
        this.article_id = params.get('articleId');
        this.articletitle = params.get('articletitle');
        this.homeService.getarticleDetails(this.article_id)
        
        .then((result) => {
          this.bxArticleDetailData = result.bxarticledetaildata;
          //console.log(this.bxArticleDetailData);

          if(this.bxArticleDetailData.url !== this.articletitle){
                   this.router.navigate(['/article']);
          }
          this.setMetaTitle();
          return this.bxArticleDetailData;
          
        }).then((resultArticel)=>{

           this.homeService.bxarticlesimilar(this.article_id)
            .then((result) => {
              
              this.bxarticlesimilardata = result.bxarticlesimilardata;
              
              this.recomendateArticel = result.bxarticlesimilardata.sort((a,b)=>a.views-b.views).slice(0,3);
              
              this.latestArticle = result.bxarticlesimilardata.sort((a,b)=>a.article_id-b.article_id).slice(0,3);
              
              this.ArticelByAuthor = result.bxarticlesimilardata.filter((item)=>item.author=resultArticel.author).slice(0,12);

              this.bxlength = this.bxarticlesimilardata.length;
              
            });

        })

    });

    /** Page Scroll Animation **/
    if (isPlatformBrowser(this.platformId)) {
      //appConfig.pagescrollanimation();
    }

  }

  private setMetaTitle() {
    this.titleService.setTitle(this.bxArticleDetailData.seo_title + '- BusinessEx');
    this.meta.updateTag({ name: 'Keywords', content: this.bxArticleDetailData.seo_keywords });
    this.meta.updateTag({ name: 'Description', content: this.bxArticleDetailData.shortDesc });


   /* this.meta.updateTag({ name: 'fb:pages', content: '84775983858482' });
    this.meta.updateTag({ name: 'fb:app_id', content: '59517604401875' });
    this.meta.updateTag({ itemprop: 'name', content: this.bxArticleDetailData.seo_title });
    this.meta.updateTag({ itemprop: 'description', content: this.bxArticleDetailData.shortDesc });
    this.meta.updateTag({ itemprop: 'image', content: this.bxArticleDetailData.images[0] });
    this.meta.updateTag({ name: 'original-source', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ property: 'article:section', itemprop: 'articleSection', content: '' });
    this.meta.updateTag({ property: 'article:tag', content: this.bxArticleDetailData.seo_keywords });
    this.meta.updateTag({
      property: 'article:published_time',
      itemprop: 'datePublished',
      content: this.bxArticleDetailData.time
    });
    this.meta.updateTag({
      property: 'article:modified_time',
      itemprop: 'dateModified',
      content: this.bxArticleDetailData.time
    });

    this.meta.updateTag({ property: 'site_name', content: 'BusinessEx' });
    this.meta.updateTag({ property: 'og:title', content: this.bxArticleDetailData.seo_title + '- BusinessEx' });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:url', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ property: 'og:image', content: this.bxArticleDetailData.images[0] });
    this.meta.updateTag({ property: 'og:image:secure_url', content: this.bxArticleDetailData.images[0] });
    this.meta.updateTag({ property: 'og:description', content: this.bxArticleDetailData.shortDesc });
    this.meta.updateTag({ property: 'og:image:width', content: '770' });
    this.meta.updateTag({ property: 'og:image:height', content: '434' });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ property: 'twitter:title', content:this.bxArticleDetailData.seo_title + '- BusinessEx' });
    this.meta.updateTag({ name: 'twitter:image', content: this.bxArticleDetailData.images[0] });
    this.meta.updateTag({ name: 'twitter:description', content: this.bxArticleDetailData.shortDesc });
    this.meta.updateTag({ name: 'twitter:url', content: BASE_URL + this.historyLocation.path() });
    this.meta.updateTag({ name: 'twitter:image:src', content: this.bxArticleDetailData.images[0] });
    this.meta.updateTag({ name: 'twitter:site', content: '@BusinesEx' });
    this.meta.updateTag({ name: 'twitter:domain', content: 'businessex.com' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@businesex' });

    */

    this.linkService.removeCanonicalLink();
    this.linkService.addTag({
      rel: 'canonical',
      href: BASE_URL + this.historyLocation.path()
    });

    /*this.linkService.addTag({
      rel: 'amphtml',
      href: BASE_URL + '/amp' + this.historyLocation.path()
    });*/
    
  }

  onclickFooterPage() {
    appConfig.scrollToError(true);
  }




}
