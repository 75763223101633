
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE DIRECTOR / CEO / OWNER INFORMATION DETAILS
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/startupConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/startupAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/startupCompanyDetails/{{profile_str}}">Business Information</a></li>
                                        <li><a routerLink="/dashboard/startupFinancial/{{profile_str}}">Financial Details</a></li>
                                        <li><a routerLink="/dashboard/startupLocation/{{profile_str}}">Headquarters </a></li>
                                        <li class="selected"><a routerLink="/dashboard/startupTeamDetails/{{profile_str}}">Team Details </a></li>
                                        <li><a routerLink="/dashboard/startupOther/{{profile_str}}">Business Plan </a></li>
                                        <li><a routerLink="/dashboard/startupRequirement/{{profile_str}}"> Requirement </a></li>
                                        <li><a routerLink="/dashboard/startupAttachment/{{profile_str}}"> Attachments </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                        <form name="startupForm" #startupForm="ngForm"
                                              (ngSubmit)="startupForm.form.valid && startupMyAccountSubmit(startupForm.value)"
                                              class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="startupProfileSuccessError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileSuccessError}}
                                            </div>
                                            <div class="msg-pnl_sales" *ngIf="startupProfileFailError"
                                                 style="margin-bottom:20px; text-align:center;">
                                                {{startupProfileFailError}}
                                            </div>

                                            <div class="sec-slide-effect" style="position: relative; z-index:1;">
                                                <!-- <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Director / CEO information</div></div>
                                                </div> -->
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Name<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="director_name"
                                                                   [ngModel]="startupTeamDetailsdata?.teamInfo.director_name"
                                                                   class="form-control" placeholder="Enter Your Name"
                                                                   data-error="Please enter name" required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Email
                                                        ID<span class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <input type="text" name="director_email"
                                                                   [ngModel]="startupTeamDetailsdata?.teamInfo.director_email"
                                                                   class="form-control" placeholder="Enter Your Email"
                                                                   data-error="Please enter email Id" required>
                                                            <span class="glyphicon form-control-feedback"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Designation<span
                                                            class="star">*</span> <span>:</span></label>
                                                    <div class="col-xs-12 col-sm-6 col-md-7">
                                                        <div class="input-group">
                                                            <select class="form-control" name="director_designation"
                                                                    [ngModel]="startupTeamDetailsdata?.teamInfo.director_designation"
                                                                    data-error="Please select designation." required>
                                                                <option *ngFor="let designation of designationinf"
                                                                        [value]="designation.id"
                                                                        [selected]="designation.id == 'startupTeamDetailsdata.teamInfo.director_designation'">
                                                                    {{designation.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="frm-sec-ttl mytc">Management Team Information</div>
                                                    </div>
                                                </div>
                                                <div class="form-group txt-center">
                                                    <div class="sec-hide">
                                                       
                                                                <div class="row">
                                                                 
                                                                            <label class="col-xs-12 col-sm-12 col-md-4 control-label">Name<span
                                                                                    class="star">*</span></label>
                                                                   
                                                                   
                                                                            <label class="col-xs-12 col-sm-12 col-md-4 control-label">Designation<span
                                                                                    class="star">*</span></label>
                                                                        
                                                                  
                                                                            <label class="col-xs-12 col-sm-12 col-md-4 control-label">Email
                                                                                ID<span class="star">*</span></label>
                                                                       
                                                              
                                                        </div>
                                                    </div>

                    <!-- Clone Box -->
                    <div class="clonebox box_container" id="team_detail">
                        <div class="entry"
                             *ngFor="let mgtInf of startupTeamDetailsdata?.mgtTeamInf; let i=index;" [id]="'clone' + i">

                            <div class="">
                                <div class="row">
                            
                                            <div class="form-group col-xs-12 col-sm-12 col-md-4 has-feedback">
                                                <div class="input-group">
                                                    <input type="text" name="name"
                                                           value="{{mgtInf.name}}"
                                                           class="form-control"
                                                           placeholder="Enter Name"
                                                           data-error="Please fill out this field."
                                                           required>
                                                    <span class="glyphicon form-control-feedback"
                                                          aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        
                              
                                            <div class="form-group col-xs-12 col-sm-12 col-md-4 has-feedback">
                                                <div class="input-group">
                                                    <input type="text"
                                                           name="designation"
                                                           value="{{mgtInf.designation}}"
                                                           class="form-control"
                                                           placeholder="Enter Designation"
                                                           data-error="Please fill out this field."
                                                           required>
                                                    <span class="glyphicon form-control-feedback"
                                                          aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        
                                    
                                            <div class="form-group col-xs-12 col-sm-12 col-md-4 has-feedback">
                                                <div class="input-group">
                                                    <input type="text" name="email"
                                                           value="{{mgtInf.email}}"
                                                           class="form-control"
                                                           placeholder="Enter Email ID"
                                                           data-error="Please fill out this field."
                                                           required>
                                                    <span class="glyphicon form-control-feedback"
                                                          aria-hidden="true"></span>
                                                </div>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                      
                                </div>
                            </div>

                            <div class="myicona">
                                <a *ngIf="i===0" id="add0" [routerLink]=""
                                   class="addbox cloneadd" onclick="cloneDiv('#team_detail')">
                                    <i class="fa fa-plus" aria-hidden="true"></i></a>
                                <a [id]="'rem'+ i" [routerLink]=""
                                   onClick="removeFormdiv(this.id);" class="removebox" [style.display]="( i>0)? 'table':'none'">
                                    <i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>

                        </div>
                    </div>
                    <!-- /Clone Box -->

                                                </div>
                                            </div>
                                            <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->
