
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                                 
                     <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    
                    
                    <!-- Company intraction detaild info -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                    <div class="row">                                        
                    <div class="col-md-12">
                        <div class="bdr">
                            <div class="cat-list">
                            <div class="cat-sec-ttl">
                               BROKER BUSINESS LISTING                                            
                            </div>
                            <!-- Business List -->
                            
                            <ul>
                                <li *ngFor="let brokerdata of brokerBusiness">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4 col-md-3">                                                        
                                            <a routerLink="/business/{{brokerdata.sellerurl}}/{{brokerdata.profileStr}}">

                                                <span *ngIf="brokerdata.thumbimage == null"><img class="img-responsive" src="assets/images/category/small/{{brokerdata.parentCatId}}.jpg" alt="Featured Business"/></span>
                                                <span *ngIf="brokerdata.thumbimage != null && brokerdata.thumbimage != ''"><img class="img-responsive" src="{{brokerdata.thumbimage}}" alt="Featured Business"/></span>
                                                <div class="primiumtag" *ngIf="brokerdata.membership_paid == 1">

                                                    <img *ngIf="brokerdata.membership_plan==1" src="assets/images/premium.png">
                                                    <img *ngIf="brokerdata.membership_plan==2" src="assets/images/gold.png">
                                                    <img *ngIf="brokerdata.membership_plan==3" src="assets/images/platinum.png">

                                                    <span *ngIf="brokerdata.membership_plan==1">Premium</span>
                                                    <span style="color:#000" *ngIf="brokerdata.membership_plan==2">Gold</span>
                                                    <span style="color:#000" *ngIf="brokerdata.membership_plan==3">Platinum</span>
                                                </div>                                             
                                                <!-- <img src="assets/images/business_img.jpg" class="img-responsive"/> -->
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-8 col-md-9">                                                        
                                            <div class="cat"> {{brokerdata.industry}} </div>
                                            <div class="cat-ttl"><a routerLink="/business/{{brokerdata.sellerurl}}/{{brokerdata.profileStr}}">{{brokerdata.title}}</a></div>
                                            <div class="location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{brokerdata.location}}</div>
                                            <div class="investment-price mdy-flt">
                                                {{ (brokerdata.price !== '') ? brokerdata.price : ("N/A") }} 
                                                <div>Asking Price</div>
                                            </div>     
                                            <div class="pull-right edit-business">
                                                <div class="dropdown">
                                                    <a class="b-edit" data-target="#" [routerLink]="" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                        EDIT
                                                        <span class="caret"></span>
                                                    </a>
                                                    <ul class="dropdown-menu" aria-labelledby="dLabel">
                                                        <li><a routerLink="/dashboard/businessConfidential/{{brokerdata.profileStr}}">Confidential Info</a></li>
                                                        <li><a routerLink="/dashboard/businessAdvDetails/{{brokerdata.profileStr}}">Advert Details</a></li>
                                                        <li><a routerLink="/dashboard/businessInformation/{{brokerdata.profileStr}}">Business Info</a></li>
                                                        <li><a routerLink="/dashboard/businessFinanceDetails/{{brokerdata.profileStr}}">Financial Details</a></li>
                                                        <li><a routerLink="/dashboard/businessTeamDetails/{{brokerdata.profileStr}}">Team Details</a></li>
                                                        <li><a routerLink="/dashboard/businessLocationDetails/{{brokerdata.profileStr}}">Headquarters</a></li>
                                                        <li><a routerLink="/dashboard/businessRequirement/{{brokerdata.profileStr}}">Requirements</a></li>
                                                        <li><a routerLink="/dashboard/businessAttachment/{{brokerdata.profileStr}}">Attachments</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </li>        
                            </ul>
                            <!-- /Business List -->
                            <!-- <a href="#" class="load-more">LOAD MORE</a> -->
                            </div>

                            <div class="instant-list nodata" *ngIf="!brokerBusinessLength">
                                No Business Listing Found !
                            </div>

                        </div>

                    </div>
                    </div>                                                                                                
                    </div>
                    
                </div>                                
            </div>
        </div>
    </div>                    
</div>
<!-- End Start Body Part -->

