
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row"> 
                    <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE PREFERENCE INFORMATION
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li><a routerLink="/dashboard/mentorConfidentials/{{profile_str}}">Confidential Information</a></li>
                                        <li><a routerLink="/dashboard/mentorAdvDetails/{{profile_str}}">Advertisement Details</a></li>
                                        <li><a routerLink="/dashboard/mentorDetails/{{profile_str}}">Profile Information</a></li>
                                        <li class="selected"><a routerLink="/dashboard/mentorPreference/{{profile_str}}">Preferences </a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                         <form name="mentorForm" #mentorForm="ngForm" (ngSubmit)="mentorForm.form.valid && mentorMyAccountSubmit(mentorForm.value)" class="form-horizontal" role="form">

                                            <div class="msg-pnl_sales" *ngIf="mentorProfileSuccessError" style="margin-top:10px; text-align:center;">{{mentorProfileSuccessError}}</div>
                                            <div class="msg-pnl_sales" *ngIf="mentorProfileFailError" style="margin-top:10px; text-align:center;">{{mentorProfileFailError}}</div>

                                            <div class="sec-slide-effect">
                                                <div class="row">
                                                    <div class="col-xs-12"><div class="frm-sec-ttl">Preference Details</div></div>
                                                </div>

                                            </div>

                                             <div class="row form-group has-feedback">
                                                 <label class="col-xs-12 col-sm-6 col-md-4 control-label">Subject Expertise
                                                     <span class="star">*</span> <span>:</span></label>
                                                 <div class="col-xs-12 col-sm-6 col-md-7">
                                                     <div class="input-group multitag">
                                                         <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Enter your text"><!-- <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                         <!--<div style="position:relative;">-->
                                                             <input class="form-control"
                                                                    id="mentor_subject_expertise"
                                                                    name="mentor_subject_expertise"
                                                                    #support_field="ngModel" ngModel style="display: none">
                                                         <!--</div>-->
                                                     </div>
                                                     <div class="help-block with-errors"></div>
                                                 </div>
                                             </div>


                                             <div class="row form-group has-feedback">
                                                 <label class="col-xs-12 col-sm-6 col-md-4 control-label">Sector Preference
                                                     <span class="star">*</span> <span>:</span></label>
                                                 <div class="col-xs-12 col-sm-6 col-md-7">
                                                     <div class="input-group multitag">
                                                         <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Enter your text"><!-- <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                         <!--<div style="position:relative;">-->
                                                             <input class="form-control" id="mentor_sector_preference"
                                                                    name="mentor_sector_preference"
                                                                    #mentor_sector_preference ="ngModel" ngModel style="display: none">
                                                         <!--</div>-->
                                                     </div>
                                                     <div class="help-block with-errors"></div>
                                                 </div>
                                             </div>
                                             <div class="row">
                                                 <label class="col-xs-12 col-sm-6 col-md-4 control-label" style="padding-left: 0px;">Professional Experience<span class="star">*</span> <span>:</span></label>
                                                 <div class="col-xs-12 col-sm-6 col-md-7">
                                                     <div class="row clonebox box_container" id="parent0">
                                                         <div class="entry"  *ngFor="let pExperience of MentorPreferencedata?.profExperience; let i=index;" [id]="'clone' + i">
                                                             <div class="col-xs-10 col-sm-11 col-md-11">
                                                                 <div class="row">
                                                                     <div class="col-xs-12 col-sm-6 col-md-6 pad-bottom has-feedback">
                                                                         <div class="input-group tl-tip">
                                                                             <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Select the number of years of industry experience "><!-- <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                                             <select class="form-control" name="prof_exp_year"  data-error="Please select number of years." required>
                                                                                 <option *ngFor="let profExp of professional_exp_year" [value]="profExp.id" [selected]="profExp.id===pExperience?.prof_exp_year" >{{profExp.id}}</option>
                                                                             </select>
                                                                             <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                                         </div>
                                                                         <div class="help-block with-errors"></div>
                                                                     </div>
                                                                     <div class="col-xs-12 col-sm-6 col-md-6 has-feedback">
                                                                         <div class="input-group tl-tip">
                                                                             <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Add the sectors of industry experience"><!-- <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                                                             <select class="form-control" name="mentor_sector_expertise" title="Enter your Sectors of Expertise" data-error="Please select Sectors of Expertise." required>
                                                                                 <option *ngFor="let industry of industryCategoryData;" [value]="industry.id" [selected]="industry.id===pExperience?.mentor_sector_expertise">{{industry.name}}</option>
                                                                             </select>
                                                                             <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                                         </div>
                                                                         <div class="help-block with-errors"></div>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                             <div class="col-xs-2 col-sm-1 col-md-1 col-pad-left">
                                                                 <a *ngIf="i===0" id="add0" [routerLink]="" onclick="cloneDiv('#parent0')" class="addbox cloneadd tppadding"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                                                 <a [id]="'rem'+ i" style="display:none;" id="rem0" [routerLink]="" onClick="removeFormdiv(this.id);" class="removebox tppadding" [style.display]="( i>0)? 'table':'none'"><i class="fa fa-times" aria-hidden="true"></i></a>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div class="sec-slide-effect txt-cen">
                                                <div class="submitfrm">
                                                    <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Start Body Part -->


<!-- {{ MentorPreferencedata | json}} -->