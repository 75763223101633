
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                               
    <!-- Profile Detail Left Panel start here -->
    <profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
    <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
    <div class="bdr">   
        <div class="cat-list">
            <div class="cat-sec-ttl">
                MANAGE CONFIDENTIAL INFORMATION                                          
            </div>

            <!-- search keywords start -->
            <div class="colorstrip">
                <ul>
                    <li class="selected"><a routerLink="/dashboard/lenderConfidentials/{{profile_str}}">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/lenderAdvertisement/{{profile_str}}">Advertisement Details</a></li>
                    <li *ngIf="bxLenderType?.type==2"><a routerLink="/dashboard/privateLender/{{profile_str}}">Private Lender Info</a></li>
                    <li *ngIf="bxLenderType?.type==3"><a routerLink="/dashboard/nbfcLender/{{profile_str}}">NBFC Personnel Info </a></li>
                    <li><a routerLink="/dashboard/lenderPreference/{{profile_str}}">Preferences</a></li>
                </ul>
            </div>
            <!-- search keywords end -->

        </div>         
        <div class="margin-20">
            <div class="row form-sec">
                <div class="col-xs-12 col-sm-12 col-md-12">
                <form name="lenderForm" #lenderForm="ngForm" (ngSubmit)="lenderForm.form.valid && lenderMyAccountSubmit(lenderForm.value)" class="form-horizontal" role="form">
                    
                    <div class="msg-pnl_sales" *ngIf="lenderProfileSuccessError" style="margin-bottom:20px; text-align:center;">{{lenderProfileSuccessError}}</div> 
                    <div class="msg-pnl_sales" *ngIf="lenderProfileFailError" style="margin-bottom:20px; text-align:center;">{{lenderProfileFailError}}</div> 
                    
                    <div class="sec-slide-effect">
                        <!-- <div class="row">
                            <div class="col-xs-12"><div class="frm-sec-ttl">Confidential Information</div></div>                                          
                        </div> -->
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Your Name<span class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" name="lender_name" [ngModel]="lenderConfidentialsdata?.lender_name" class="form-control" placeholder="Enter Name" data-error="Please enter name." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>                            
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Mobile Number<span class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" name="lender_mobile" [ngModel]="lenderConfidentialsdata?.lender_mobile" pattern="[56789][0-9]{9}" class="form-control" placeholder="Enter Mobile Number" data-error="Please enter valid mobile number" required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Email ID<span class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="email" name="lender_email" [ngModel]="lenderConfidentialsdata?.lender_email" class="form-control" placeholder="Enter Email ID" data-error="Please enter email id." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location<span class="star">*</span></label>
                            <span class="colmark">:</span>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">                                        
                                    <input type="text" name="lender_location" [ngModel]="lenderConfidentialsdata?.lender_location" class="form-control" placeholder="Enter Location" (setAddress) = "getAddress($event)" data-error="Please select location" googleplace required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>                                        
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>
                    <div class="sec-slide-effect txt-cen">                                  
                        <div class="submitfrm">
                            <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>            
</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->
