import { isPlatformBrowser,Location } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BASE_URL } from '../../../../config/app-config.constants';
import { ContactService } from 'projects/frontend/src/app/services/contact.service';
import { HomeService } from 'projects/frontend/src/app/services/home.service';
import { LinkService } from 'projects/frontend/src/app/services/link.service';
import { appConfig } from '../../app.config';
import { ListingPaginationComponent } from '../listing-pagination/listing-pagination.component';

@Component({
  selector: 'app-bex-shorts',
  templateUrl: './bex-shorts.component.html',
  styleUrls: ['./bex-shorts.component.css']
})
export class BexShortsComponent implements OnInit {

  public bxbannertopmob;
  public bxbannertop;
  public title = 'Bex Shorts';
  public bxNewsListing = [];
  public currentPage = 1;
  itemsPerPage = 10;
  public time = '';
  public commented = '';
  public orderBy = 'created_at';
  public tagSlug = '';
  public tag = '';
  public read;
  public totalItems = 0;
  pagination = false;
  obj = { Title: 'test', Url: 'test12' };
  loading:boolean= true;

  @ViewChild(ListingPaginationComponent)
  private listingPagination: ListingPaginationComponent


  constructor(private linkService: LinkService,
    private meta: Meta, private titleService: Title,
    private contactService: ContactService,
    private homeService: HomeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    private historyLocation: Location) {

    this.route.paramMap.subscribe((params: Params) => {
      // this.tagSlug = params.get('newsTagSlug');
      // const param1 = +this.route.snapshot.queryParams['page'];
      // if (param1 !== undefined && param1 > 0) {
      //     this.currentPage = param1;
      //   }
      this.getNewsListing();
    });

  }

  ngOnInit() {
  }


  getNewsListing() {
    if (this.pagination) {
      if (this.currentPage > 1) {
        history.pushState(this.obj, this.obj.Title, this.listingPagination.getPathString() + '?page=' + this.currentPage);
      } else {
        history.pushState(this.obj, this.obj.Title, this.listingPagination.getPathString());
      }
    }
    this.homeService.getBexShortslisting(this.currentPage, this.itemsPerPage, this.time, this.read, this.commented, this.orderBy, this.tagSlug)
      .then(
        result => {
          this.bxNewsListing = result.bxnewslistingdata;
          //console.log(this.bxNewsListing);

          if (this.tagSlug !== '' && this.bxNewsListing.length === 0) {
            this.router.navigate(['/news']);
          }
          this.totalItems = result.newsCount;
          this.setTitleMeta()
          this.currentPage = (this.bxNewsListing.length === 0) ? 0 : this.currentPage;
          this.loading = false;
      }, (error) => {
        this.loading = false;
      });
  }



  private setTitleMeta() {
    let title = 'Latest Business News and Information | Today\'s Business News - BusinessEx';
    if (this.tagSlug !== null) {
      this.tag = appConfig.revertSlug(this.tagSlug);
      title = 'Latest ' + this.tag + ' News and ' + this.tag + ' Information | Today\'s Business News - BusinessEx';
    }
    this.titleService.setTitle(title);

   /* this.meta.addTags([
      {
        name: 'news_keywords',
        content: 'latest ' + this.tag + ' business news,business news today,today\'s business news, business news and information'
      },
      {
        name: 'Description',
        content: 'Find latest ' + this.tag + ' business news and ' + this.tag + ' information in relevance with Investment, Funding, M&A, Buying & Selling of Business only on BusinessEx.'
      }
    ]);*/

    this.meta.updateTag({ name: 'Keywords', 
        content:'latest ' + this.tag + ' business news,business news today,today\'s business news, business news and information'
    });
    
    this.meta.updateTag({ name: 'Description', 
       content: 'Find latest ' + this.tag + ' business news and ' + this.tag + ' information in relevance with Investment, Funding, M&A, Buying & Selling of Business only on BusinessEx.'
    });



    this.linkService.removeCanonicalLink();
    const totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 0;
    let pNumber = 0;
    if (this.currentPage > 1 && this.currentPage <= totalPages) {
      pNumber = this.currentPage - 1;
      this.linkService.addTag({
        rel: 'prev',
        href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
      });
    }
    this.linkService.addTag({
      rel: 'canonical',
      href: BASE_URL + this.historyLocation.path()
    });
    if (this.currentPage >= 1 && this.currentPage < totalPages) {
      pNumber = this.currentPage + 1;
      this.linkService.addTag({
        rel: 'next',
        href: BASE_URL + this.listingPagination.getPathString() + '?page=' + pNumber
      });
    }
  }


  goToPage(n: number): void {
    this.pagination = true;
    this.currentPage = n;
    this.getNewsListing();

  }

  onNext(): void {
    this.pagination = true;
    this.currentPage++;
    this.getNewsListing();

  }

  onPrev(): void {
    this.pagination = true;
    this.currentPage--;
    this.getNewsListing();
  }


  newsList(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(function () {
        /** Title Ellipsis **/
        appConfig.ellipsizeTextBox('ttl-ellipsis', 45);
        appConfig.ellipsizeTextBox('sum-ellipsis', 32);
        appConfig.ellipsizeTextBox('sec-ellipsis', 48);
        /** Page Scroll Animation **/
        appConfig.pagescrollanimation();
      }, 500);
    }
  }

}
