import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {IAppConfig} from '../../../../config/app-config.interface';
import {APP_CONFIG, DESIGNATION} from '../../../../config/app-config.constants';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';
import {NgForm} from "@angular/forms";
import {appConfig} from "../../../frontend/app.config";

declare var $: any;

@Component({
    selector: 'business-team-details',
    templateUrl: './business-team-details.component.html'
})
export class BusinessTeamDetailsComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    profile_str;
    public BusinessAdvDetailsdata;
    public BusinessTeamDetailsdata;
    public businessProfileSuccessError;
    public businessProfileFailError;
    public userProfileType;
    private isViewChecked = false;
    designationinf = DESIGNATION;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Business Team Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }


    // obj = [];
    // mgtTeamInf;
    // this.BusinessTeamDetailsdata.mgtTeamInf = [{"id":"mgt1"}];
    // addNewMgtData(){
    //     if (this.BusinessTeamDetailsdata.mgtTeamInf.length < 5) {
    //         var newItemNo = this.BusinessTeamDetailsdata.mgtTeamInf.length + 1;
    //         this.BusinessTeamDetailsdata.mgtTeamInf.push({'id': 'mgt' + newItemNo});
    //     }
    // }

    businessMyAccountSubmit(form: NgForm) {
        const formSelector = $('form[name="businessForm"]');
        formSelector.validator('update');
        if (formSelector.validator('validate').has('.has-error').length) {
            return;
        }
        form.value.business_profile_str = this.profile_str;
        form.value.mgt_team_inf = appConfig.prepareTeamJson('#team_detail input');
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/updatesellerteam/' + this.profile_str, form.value).subscribe(
            data => {
                this.businessProfileSuccessError = "Business Team Details Updated Successfully";
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
            },
            error => {
                appConfig.scrollToError();
                formSelector.find('button[type="submit"]').button('reset');
                this.businessProfileFailError = "Please Enter Correct Details";
            });
    }

    getsellerTeamDetails(profile_str: any): void {
        this.myprofileService.getsellerTeamDetails(this.profile_str)
            .then((result) => {
                this.BusinessTeamDetailsdata = result;
            });
    }

    getsellerAdvDetails(profile_str: any): void {
        this.myprofileService.getsellerAdvDetails(this.profile_str)
            .then((result) => {
                this.BusinessAdvDetailsdata = result;
            });
    }

    getUserRegisterProfileType(): void {
        this.myprofileService.getUserRegisterProfileType(this.userID)
            .then((result) => {
                this.userProfileType = result;
            });
    }

    ngOnInit() {
        this.getsellerTeamDetails(this.profile_str);
        this.getsellerAdvDetails(this.profile_str);
        this.getUserRegisterProfileType();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="businessForm"]').validator();
        }
    }
}
