<div *ngIf="loading" class="loading">
    <div style="display:table-cell; vertical-align: middle;">
       <div class="sk-circle">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
       </div>
    </div>
 </div>

<main id="main">
    <div class="container bex-main">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <ul class="brunnar">
                    <li><a routerLink="/">Home</a></li>
                    <li>/</li>
                    <li><a routerLink="/investorlisting">investor</a></li>
                    <li>/</li>
                    <li>Individual Investor</li>
                    <li>/</li>
                    <li></li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12" *ngIf="investorProfileData?.inv_headline !== '' ">
                <h1>{{investorProfileData?.inv_headline}}</h1>
            </div>

            <div class="col-12 col-sm-12 col-md-12" *ngIf="investorProfileData?.inv_intro">
                {{investorProfileData?.inv_intro}}
            </div>

            <div class="col-12 col-sm-12 col-md-12"
                *ngIf="investorProfileData?.inv_headline == '' || investorProfileData?.inv_headline == 'NULL'">
                <h1>Individual Investor from {{ (investorProfileData?.inv_city !=='') ?
                    investorProfileData?.inv_city : ("India") }} wanting to investment between {{
                    (investorProfileData?.invest_size_min != "") ?
                    (investorProfileData?.invest_size_min | priceShort) : ("N/A") }} and {{
                    (investorProfileData?.invest_size_max != "") ?
                    (investorProfileData?.invest_size_max | priceShort) : ("N/A") }}</h1>
            </div>

        </div>

        <div class="row landpage">
            <div class="col-12 col-sm-12 col-md-9">

                <div class="fulldetailpage iber">

                    <div class="tab-content ">
                        <div class="">

                            <div class="planblst">
                                <!-- Nav tabs -->
                                <div class="ribbonblk finalpage"
                                    *ngIf="investorProfileData?.membership_paid==1 && investorProfileData?.membership_plan==1">
                                    <div class="ribbonblkinner">Premium</div>
                                </div>
                                <div class="ribbonblk finalpage"
                                    *ngIf="investorProfileData?.membership_paid==1 && investorProfileData?.membership_plan==2">
                                    <div class="ribbonblkinner">Gold</div>
                                </div>
                                <div class="ribbonblk finalpage"
                                    *ngIf="investorProfileData?.membership_paid==1 && investorProfileData?.membership_plan==3">
                                    <div class="ribbonblkinner">Platinum</div>
                                </div>
                            </div>
                            <!-- for company person  -->
                            <div class="boxsect">
                                <div class="boxsecthead">Personal Information</div>
                                <div class="fullblks">

                                    <div class="f1blk">
                                        <div class="showtxtc"><span class="sh1">Name </span>
                                        <span class="sh2">{{ investorProfileData?.inv_name }}</span>
                                            <!-- <span class="sh2" *ngIf="bxProposalResult?.status == 2">
                                                {{ investorProfileData?.inv_name }}</span>
                                            <span class="sh2" *ngIf="bxProposalResult?.status < 2"> <i
                                                    class="fa fa-lock" aria-hidden="true"></i>Available after
                                                Interaction</span> -->
                                        </div>

                                        <div class="showtxtc"><span class="sh1">Mobile </span>
                                            <span class="sh2" *ngIf="bxProposalResult?.status == 2">
                                                {{investorProfileData?.inv_mobile }}
                                            </span>
                                            <span class="sh2" *ngIf="bxProposalResult?.status < 2"> <i
                                                    class="fa fa-lock" aria-hidden="true"></i>Available after
                                                Interaction</span>
                                        </div>
                                        <div class="showtxtc"><span class="sh1">Email id </span> <span class="sh2"
                                                *ngIf="bxProposalResult?.status == 2">
                                                {{ investorProfileData?.inv_email }}</span>
                                            <span class="sh2" *ngIf="bxProposalResult?.status < 2"> <i
                                                    class="fa fa-lock" aria-hidden="true"></i>Available after
                                                Interaction</span>
                                        </div>
                                        <div class="showtxtc"><span class="sh1">Designation </span> 
                                             <span class="sh2">
                                                <!-- *ngIf="bxProposalResult?.status == 2" -->
                                                {{ investorProfileData?.company_designation }},
                                                {{ investorProfileData?.company_name }}
                                            </span>

                                            <!-- <span class="sh2" *ngIf="bxProposalResult?.status < 2">
                                                <i class="fa fa-lock" aria-hidden="true"></i>Available after Interaction
                                            </span> -->

                                        </div>
                                        <div class="showtxtc"><span class="sh1">Location </span> 
                                            <span class="sh2" *ngIf="bxProposalResult?.status == 2">
                                                {{ investorProfileData?.inv_city }},
                                                {{ investorProfileData?.inv_state }}
                                            </span>

                                            <span class="sh2" *ngIf="bxProposalResult?.status < 2"> <i
                                                    class="fa fa-lock" aria-hidden="true"></i>
                                                    Available after
                                                Interaction</span>
                                        </div>
                                    </div>


                                    <div class="f2blk">
                                        <!-- <div class="comppro" *ngIf="bxProposalResult?.status == 2">
                                            <img src="{{ investorProfileData?.inv_profile_pic_path }}">
                                        </div>

                                        <div class="comppro" *ngIf="bxProposalResult?.status < 2">
                                            <img src="assets/img/mentor.png">
                                        </div> -->

                                        <div class="comppro">
                                            <img src="{{investorProfileData?.inv_profile_pic_path }}">
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <!-- for Investor Information  -->
                            <div class="boxsect">
                                <div class="boxsecthead">Investor Information</div>
                                <div class="fullshblk">
                                    <h3 class="subchead"><span class="sds1"> Investor Type</span> <span
                                            class="sds2">Individual or Investment Firm </span></h3>
                                    <div class="halkblknew">
                                        <div class="sminhead">Individual</div>
                                        <div class="indetailmodfy "><span class="ds1"> Investor Company </span><span
                                                class="ds2">{{ (investorProfileData?.company_name !== '') ?
                                                investorProfileData?.company_name : ("N/A") }}</span></div>
                                        <div class="indetailmodfy"><span class="ds1">Investor Designation </span><span
                                                class="ds2"> {{ (investorProfileData?.company_designation !== '') ?
                                                investorProfileData?.company_designation : ("N/A") }}</span></div>
                                        <div class="indetailmodfy"><span class="ds1">Professional Summary </span><span
                                                class="ds2">
                                                {{ (investorProfileData?.inv_abt_urself !== '') ?
                                (investorProfileData?.inv_abt_urself |stripTags): ("N/A") }}
                                            </span></div>
                                    </div>


                                </div>

                            </div>
                            <!--  Investor Information  -->

                            <!-- for Preferences Information  -->

                            <div class="boxsect">
                                <div class="boxsecthead">Preferences</div>

                                <div class="fullshblk">
                                    <div class="perset">
                                        <h3 class="subchead">1. Investment Preference (Investment/Acquisition)</h3>

                                        <div class="halkblk">
                                            <div class="sminhead">For Investment:</div>
                                            <div class="indetailmodfy boxs"
                                                *ngIf="investorProfileData?.invest_pref == '1'"><span
                                                    class="ds1">Investment Size-
                                                </span><span class="ds2">
                                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                                    {{ (investorProfileData?.invest_size_min != "") ?
                                (investorProfileData?.invest_size_min | priceShort) : ("N/A") }}
                                                    - <i class="fa fa-inr" aria-hidden="true"></i>
                                                    {{ (investorProfileData?.invest_size_max != "") ?
                                (investorProfileData?.invest_size_max | priceShort) : ("N/A") }}

                                                </span></div>
                                            <div class="indetailmodfy boxs">
                                                <span class="ds1">Investment Stake Preference:</span>
                                                <span class="ds2">{{investorProfileData?.invest_stake}} % </span>
                                            </div>

                                        </div>


                                        <div class="halkblk" *ngIf="investorProfileData?.full_acquisition == '1'">
                                            <div class="sminhead">Full Acquisition</div>
                                            <div class="indetailmodfy boxs"><span class="ds1">Purchasing
                                                    Capacity:</span>
                                                <span class="ds2">
                                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                                    {{(investorProfileData?.purchase_capacity_min | priceShort)}}
                                                    - <i class="fa fa-inr" aria-hidden="true"></i>
                                                    {{(investorProfileData?.purchase_capacity_max | priceShort)}}
                                                </span>
                                            </div>

                                        </div>


                                    </div>



                                </div>
                                <div class="fullshblk">
                                    <div class="perset">
                                        <h3 class="subchead">2. Location Preference</h3>
                                        <ul class="prefetxt mdfy">
                                            <li *ngFor="let locationPreference of investorLocPreference"><i
                                                    class="fa fa-check"
                                                    aria-hidden="true"></i>{{locationPreference.location_name}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="fullshblk">
                                    <div class="perset">
                                        <h3 class="subchead">3. Sector Preference</h3>
                                        <ul class="prefetxt">
                                            <li *ngFor="let industryPreference of investorIndPreference"><i
                                                    class="fa fa-angle-double-right"
                                                    aria-hidden="true"></i>{{industryPreference.category_name}}</li>
                                        </ul>
                                    </div>


                                </div>

                            </div>
                            <!-- Preferences Information  -->

                        </div>


                    </div>

                </div>

            </div>
            <div class="col-12 col-sm-12 col-md-3">
                <!-- If User have no profile, creating profile for sending insta query-->
                <div id="contactform" class="contact-seller bm-mark form-sec sec-slide-effect"
                    *ngIf="(ProfileCheck == 0 || moderatorContactStatus == 4) && bxContactCount == 0 && singleDayCount < singleDayLimit && investorProfileExistCheck == ''">
                    <div class="contact-ttl">{{title}}</div>

                    <div class="msg-pnl_sales" *ngIf="instaContactError" style="text-align:center;">
                        <div *ngFor="let message of instaContactError | keyspipe : true">
                            {{message}}
                        </div>
                    </div>

                    <form name="contactFormInvestor" #contactFormInvestor="ngForm" name="contactFormInvestor"
                        (ngSubmit)="contactFormInvestorSubmit(contactFormInvestor.value, investorProfileData?.user_id, investorProfileData?.inv_profile_str)"
                        class="form-horizontal contact-seler-form" role="form">
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Full Name<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" name="contact_name" #contact_name="ngModel" ngModel
                                        class="form-control" placeholder="Enter Name" data-error="Please enter name."
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Designation<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" name="contact_designation" #contact_designation="ngModel" ngModel
                                        class="form-control" id="inputPassword3" placeholder="Enter Designation"
                                        data-error="Please enter designation." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Mobile<span class="star">*</span>
                                <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" pattern="[56789][0-9]{9}" minlength="10" maxlength="10"
                                        onkeypress="return isNumber(event)" name="contact_mobile"
                                        #contact_mobile="ngModel" ngModel class="form-control"
                                        placeholder="Enter Mobile" data-error="Please enter valid mobile number"
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Email<span class="star">*</span>
                                <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        name="contact_email" #contact_email="ngModel" ngModel class="form-control"
                                        placeholder="Enter Email" data-error="Please enter email Id." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Company<span class="star">*</span>
                                <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" name="contact_company" #contact_company="ngModel" ngModel
                                        class="form-control" placeholder="Enter Company"
                                        data-error="Please enter company." required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Investment<span
                                    class="star">*</span> <span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <input type="text" pattern="[0-9]+" minlength="5" maxlength="12"
                                        onkeypress="return isNumber(event)" name="contact_investment"
                                        #contact_investment="ngModel" ngModel class="form-control"
                                        placeholder="Your Contribution" data-error="Please enter contribution."
                                        required>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>

                        <div class="form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-5 control-label">Profile Type <span
                                    class="star">*</span><span>:</span></label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="input-group">
                                    <select class="form-control" name="profile_type" #profile_type="ngModel"
                                        data-error="Please select profile type" required ngModel>
                                        <option value="" disabled selected hidden>Select Profile Type</option>
                                        <option *ngFor="let profileType of contact_profile_type"
                                            [value]="profileType.id">{{profileType.name}}
                                        </option>
                                    </select>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>

                        <div class="form-group has-feedback">
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="input-group">
                                    <textarea class="form-control" onkeypress="return lettersOnly(event)"
                                        name="contact_comment" #contact_comment="ngModel" ngModel rows="3"
                                        placeholder="Enter your Comment" data-error="Please enter comment."
                                        required></textarea>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-xs-12 col-sm-12">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" name="subscribe" #subscribe="ngModel" checked="checked"
                                            ngModel> Subscribe for Daily Updates
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group rm-margin">
                            <div class="col-xs-12 col-sm-12 btn-center">
                                <button type="submit" class="contact-btn mrgn-top">Contact Investor</button>
                            </div>
                        </div>
                    </form>
                    <div class="bookmark-landing">
                        <a [routerLink]="">
                            <i *ngIf="bxBookmarkStatus === 0 " (click)="toggle(1)" class="fa fa-bookmark-o fa-6"
                                aria-hidden="true"></i>
                            <i *ngIf="bxBookmarkStatus === 1 " (click)="toggle(0)" class="fa fa-bookmark"
                                aria-hidden="true"></i>
                            Bookmark
                        </a>
                    </div>
                </div>
                <!-- If User have no profile, creating required profile for sending insta query end here-->

                <div *ngIf="investorProfileExistCheck == ''">

                    <!-- Single/total day contact profile limit exceeded -->
                    <div *ngIf="(ProfileCheck == 0 || moderatorContactStatus == 4) && bxContactCount != 1 &&
(singleDayCount >= singleDayLimit || totalCount >= overallLimit)" class="contact-seller  form-sec sec-slide-effect">
                        <div class="contact-ttl">Contact Investor</div>
                        <div class="added-info btnpad">
                            <span *ngIf="ProfileCheck == 0">
                                {{contactLimitExceedMessage}}
                                Please <a [routerLink]="['/registration/quick-registration']">create a profile</a>
                                to send more queries.
                            </span>
                            <span *ngIf="moderatorContactStatus == 4">
                                {{contactLimitExceedMessage}}
                                Get Your profile <a [routerLink]="['/myaccount/dashboard']">activated</a> to send more
                                queries.
                            </span>
                        </div>
                    </div>
                    <!-- Single day contact profile limit exceeded -->

                    <!-- Already contacted message after contact investor form -->
                    <div *ngIf="(ProfileCheck == 0 || moderatorContactStatus == 4) && bxContactCount == 1"
                        id="pendingContactedProposal" class="contact-seller  form-sec sec-slide-effect">
                        <div class="contact-ttl">Contact Investor</div>
                        <div class="added-info btnpad">You have already contacted this Profile.</div>
                    </div>
                    <!-- Already contacted message after contact investor form -->

                    <!-- Thank you message after sending insta query Start Here -->
                    <div class="contact-seller form-sec sec-slide-effect" id="contactthankyou" style="display: none;"
                        *ngIf="userID !== 0 && (ProfileCheck == 0 || moderatorContactStatus == 4)">
                        <div class="thank-info">
                            We have successfully shared your details with the Investor. You will receive a
                            notification when they reply.
                        </div>
                    </div>
                    <!-- Thank you message after sending insta query End Here -->

                    <!-- If Profile is pending start here -->
                    <div class="contact-seller  form-sec sec-slide-effect inbtn"
                        *ngIf="profilePendingStatus != '' && profileActiveStatus == '' && moderatorContactStatus !=4 ">
                        <div class="added-info btnpad">Complete your profile to contact</div>
                        
                            <a [routerLink]="[appConfig.getPendingProfileUrl(profilePendingStatus, pendingProfileStr)]"
                                class="contact-btn">Click here</a>
                        
                    </div>
                    <!-- If Profile is pending end here -->

                    <!-- If User have at least one profile but required profile for sending proposal not exist -->
                    <div class="contact-seller  form-sec sec-slide-effect"
                        *ngIf="ProfileCheck != 0 && investorProfileExistCheck == '' && profileActiveStatus == '' && profilePendingStatus == ''">
                        <div class="added-info btnpad">To contact this investor, Please create a
                            Business/Start-up profile
                        </div>
                        <div class="listed-by">
                            <div class="create-prf">Please create a profile for better Business Opportunity.
                            </div>
                            <form #contactProfileTypeForm="ngForm" name="contactProfileTypeForm"
                                (ngSubmit)="contactProfileTypeSubmit(contactProfileTypeForm.value)"
                                class="form-horizontal contact-seler-form" role="form">
                                <div class="form-group row">
                                    <label class="col-xs-12 col-sm-5 col-md-5 control-label">Profile Type
                                        <span>:</span>
                                    </label>
                                    <div class="col-xs-12 col-md-7 col-sm-7">
                                        <select class="form-control" name="profile_type" #profile_type="ngModel" ngModel
                                            data-error="Please select profile." required>
                                            <option *ngFor="let profType of contact_profile_type"
                                                [value]="profType.name" [selected]="profType.name == '1'">
                                                {{profType.name}}
                                            </option>
                                        </select>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="form-group mgn-tp">
                                    <div class="col-xs-12 col-sm-12 btn-center">
                                        <button type="submit" class="contact-btn mrgn-top">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- If User have at least one profile but required profile for sending proposal not exist -->


                </div>

                <!-- Business Proposal Section Start Here For Basic Plan Investor -->
                <div id="investorproposalForm" class="contact-seller bm-mark  form-sec sec-slide-effect"
                    *ngIf="profileActiveStatus != '' && investorProfileExistCheck === '' && userID !== 0 && ProfileCheck != 0 && bxProposalResult?.count == 0">
                    <div class="contact-ttl">Contact Investor</div>
                    <div class="msg-pnl_sales" *ngIf="instaProposalError" style="text-align:center;">
                        <!-- <div *ngFor="let message of instaProposalError | keyspipe : true"> -->
                            {{instaProposalError}}
                        <!-- </div> -->
                    </div>
                    <form name="contactProposalForm" #contactProposalForm="ngForm"
                        (ngSubmit)="contactProposalFormSubmit(contactProposalForm.value, investorProfileData?.user_id, investorProfileData?.inv_profile_str)"
                        class="form-horizontal contact-seler-form" role="form">


                        <div class="form-group has-feedback">
                            <div class="col-xs-12 col-sm-12">
                                <div class="input-group">
                                    <select class="form-control" name="ProfileSelected" #ProfileSelected="ngModel"
                                        ngModel (ngModelChange)="onChangeObj($event)"
                                        data-error="Please select profile." required>
                                        <option value="" disabled selected hidden>Select Profile</option>
                                        <option *ngFor="let Profiledata of profileObjects | investorProfileTypeFilter"
                                            [value]="Profiledata.profile_type">
                                            {{Profiledata.profile_name}}
                                        </option>
                                    </select>
                                    <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                </div>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="mainPayment">
                            <div class="abr" *ngIf="profileCreditBalance <= 0">
                                Your don't have any credit. Kindly Upgrade your profile.
                            </div>
                            <div class="form-group has-feedback">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="input-group">
                                        <textarea class="form-control" onkeypress="return lettersOnly(event)" rows="3"
                                            name="msg" #msg="ngModel" ngModel placeholder="Enter your Comment"
                                            data-error="Please enter comment." required></textarea>
                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                    </div>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-xs-12 col-sm-12 btn-center">
                                    <button class="contact-btn" type="submit">Contact Investor</button>
                                </div>
                            </div>
                        </div>
                        <!--don't have any credit, showing upgrade message start here-->
                        <div class="form-group added-info" *ngIf="profileCreditBalance <= 0">
                            <div class="col-xs-12 col-sm-12 btn-center inbtn">
                                <a [routerLink]="['/dashboard/upgrade/'+sellerUpgProfUniqStr]" class="contact-btn">Upgrade
                                    Plan</a>
                            </div>
                        </div>
                        <!--don't have any credit, showing upgrade message end here-->

                        <div class="bookmark-landing">
                            <a [routerLink]="">
                                <i *ngIf="bxBookmarkStatus === 0 " (click)="toggle(1)" class="fa fa-bookmark-o fa-6"
                                    aria-hidden="true"></i>
                                <i *ngIf="bxBookmarkStatus === 1 " (click)="toggle(0)" class="fa fa-bookmark"
                                    aria-hidden="true"></i>
                                Bookmark
                            </a>
                        </div>
                    </form>
                </div>
                <!-- Business Proposal Section End Here For Basic Plan Investor -->

                <div *ngIf="investorProfileExistCheck === ''">

                    <!-- Membership plan upgrade section start here -->
                    <!--<div class="contact-seller  form-sec sec-slide-effect"
     *ngIf="userID !== 0 && bxProposalResult?.status ==0 && profileActiveStatus !='' &&  ProfileCheck != 0 && ((profileMembership == 0 && profileCreditBalance > 4)
     || (profileMembership == 1 && profileCreditBalance > 49) || (profileMembership == 2 && profileCreditBalance > 99)
     || (profileMembership == 4 && profileCreditBalance > 49))">
    <div class="contact-ttl">Contact Investor</div>
    <div class="added-info btnpad">You don't have any credit limits So please Upgrade your
        plan.
    </div>
    <div><a routerLink="/dashboard/upgrade/{{sellerUpgProfUniqStr}}" class="contact-btn">Upgrade
        Plan</a></div>
</div>-->
                    <!-- Membership plan upgrade section end here -->

                    <!-- Thank you message after investor proposal -->
                    <div id="thankyouproposal" class="contact-seller  form-sec sec-slide-effect" style="display:none;">
                        <div class="contact-ttl">Contact Investor</div>
                        <div class="added-info btnpad">
                            We have successfully shared your details with the Investor. You will receive a
                            notification when they reply.
                        </div>
                    </div>
                    <!-- Thank you message after investor proposal -->

                    <!-- Already contacted message after business proposal -->
                    <div *ngIf="bxProposalResult?.count == '1' && bxProposalResult?.status == '1'"
                        class="contact-seller  form-sec sec-slide-effect">
                        <div class="contact-ttl">Contact Investor</div>
                        <div class="added-info btnpad">You have already contacted this Profile.</div>
                    </div>
                    <!-- Already contacted message after business proposal -->

                    <!-- After business proposal accepted start here -->
                    <div *ngIf="bxProposalResult?.count == '1' && bxProposalResult?.status == '2'"
                        id="activeContactedProposal" class="contact-seller  form-sec sec-slide-effect inbtn">
                        <a [routerLink]="['/dashboard/myinteraction']" class="contact-btn">GO TO BX INBOX</a>
                        <br>
                        <div class="added-info">Your proposal has been accepted, Click the above link to
                            continue the Interaction
                        </div>
                    </div>
                    <!-- After business proposal accepted end here -->

                    <!-- business proposal interaction already start here -->
                    <div *ngIf="bxProposalResult?.count == '1' && bxProposalResult?.status == '3'"
                        class="contact-seller  form-sec sec-slide-effect inbtn">
                        
                            <a [routerLink]="['/dashboard/myinteraction']" *ngIf="bxProposalResult?._status == 2"
                                class="contact-btn">
                                GO TO BX INBOX
                            </a>
                            
                            <a [routerLink]="['/dashboard/bxproposal']" *ngIf="bxProposalResult?._status == 1"
                                class="contact-btn">
                                GO TO BX PROPOSAL
                            </a>
                        
                        <div class="added-info btnpad">
                            {{ (bxProposalResult?._status == 1) ?
                        'You have not yet responded to this proposal. Please accept or reject it by clicking above.' :
                        'Interaction has already started with your profile.' }}
                        </div>
                    </div>
                    <!-- business proposal interaction already end here -->

                </div>

                <!-- User visit own profile start here -->
                <div *ngIf="investorProfileExistCheck == 'UserProfile'">
                    <div class="contact-seller  form-sec sec-slide-effect inbtn">
                        <a [routerLink]="['/dashboard/myaccount']" class="contact-btn">GO TO DASHBOARD</a><br>
                        <div class="added-info btnpad">This is your Investor Profile. Click above link to update
                            profile
                        </div>
                    </div>
                </div>
                <!-- User visit own profile end here -->



            </div>
        </div>
    </div>
</main>