
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                               
<!-- Profile Detail Left Panel start here -->
    <profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
<div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
<div class="bdr">   
<div class="cat-list">
<div class="cat-sec-ttl">CHANGE PASSWORD</div>
<div class="msg-pnl msg-change-pwd" style="top:0px;">your password has been reset successfully, Please login again.</div>
</div>         
<div class="margin-20">
<div class="row form-sec">
<div class="col-xs-12 col-sm-12 col-md-12">

<form class="form-horizontal" id="reset-frm" name="forgot" #forgot="ngForm" style="display: block;" novalidate (ngSubmit)="forgot.form.valid && changePasswordSubmit(forgot.value)">                                            


<div class="sec-slide-effect">
    <!-- <div class="row">
        <div class="col-xs-12"><div class="frm-sec-ttl">Change Password</div></div>                                          
    </div>                                       -->
   
    <div class="row form-group chng-pwd">
        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Old Password<span class="star">*</span> <span>:</span></label>     
        <div class="col-xs-12 col-sm-6 col-md-7">
        <input type="password" class="form-control" name="oldPassword" [ngModel]="regmodel.oldPassword" 
        required  #oldPassword="ngModel" placeholder="Old Password">
        <small [hidden]="oldPassword.valid || (oldPassword.pristine && !forgot.submitted)" class="text-danger">
            Old Password is required 
        </small>
        </div>
    </div>
                    
    <div class="row form-group chng-pwd">
        <label class="col-xs-12 col-sm-6 col-md-4 control-label">New Password<span class="star">*</span> <span>:</span></label>     
        <div class="col-xs-12 col-sm-6 col-md-7">
        <input type="password" class="form-control" name="password" [ngModel]="regmodel.password" 
        required validateEqual="confirmPassword" reverse="true" #password="ngModel" placeholder="Password">
        <small [hidden]="password.valid || (password.pristine && !forgot.submitted)" class="text-danger">
            Password is required
        </small>
        </div>
    </div>

    <div class="row form-group chng-pwd">
        <label class="col-xs-12 col-sm-6 col-md-4 control-label">Confirm Password<span class="star">*</span> <span>:</span></label>     
        <div class="col-xs-12 col-sm-6 col-md-7">
        <input type="password" class="form-control" name="confirmPassword" [ngModel]="regmodel.confirmPassword" 
        required validateEqual="password" reverse="false" #confirmPassword="ngModel" placeholder="Confirm Password">
        <small [hidden]="confirmPassword.valid || (confirmPassword.pristine && !forgot.submitted)" class="text-danger">
            Password mismatch
        </small>
        </div>
    </div>

    
</div>                                       
<div class="sec-slide-effect txt-cen">                                  
    <div class="submitfrm">
        <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
    </div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>            
</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->

