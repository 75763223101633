import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {APP_CONFIG} from "../../../../config/app-config.constants";
import {IAppConfig} from '../../../../config/app-config.interface';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'investor-individual-type',
    templateUrl: './investor-individual-type.component.html'
})
export class InvestorIndividualTypeComponent implements OnInit, AfterViewChecked {

    jwtHelper = new JwtHelperService();
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    userID = this.decode.sub;
    files;
    profile_str;
    public investorIndividualTypedata;
    public investorProfileSuccessError;
    public investorProfileFailError;
    public bxInvestorType;
    public investorAdvertisementdata;
    private isViewChecked = false;

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Individual Investor Details - BusinessEx');

        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    investorMyAccountSubmit(data) {
        if ($('form[name="investorForm"]').validator('validate').has('.has-error').length) {
            return;
        }
        data.inv_profile_str = this.profile_str;
        data.invest_pref = (data.invest_pref === false) ? 0 : 1;
        data.full_acquisition = (data.full_acquisition === false) ? 0 : 1;
        const formData: any = new FormData();
        if (this.files != undefined) {
            formData.append('inv_profile_pic_path', this.files[0], this.files[0].name);
            //console.log(this.files[0]);
        }
        if (data.inv_profile_pic_path_old != '') {
            formData.append('inv_profile_pic_path_old', data.inv_profile_pic_path_old);
            //console.log(data.inv_profile_pic_path_old);
        }
        formData.append('company_name', data.company_name);
        formData.append('company_designation', data.company_designation);
        formData.append('invest_pref', data.invest_pref);
        formData.append('full_acquisition', data.full_acquisition);
        formData.append('invest_size_min', data.invest_size_min);
        formData.append('invest_size_max', data.invest_size_max);
        formData.append('invest_stake', data.invest_stake);
        formData.append('purchase_capacity_min', data.purchase_capacity_min);
        formData.append('purchase_capacity_max', data.purchase_capacity_max);
        formData.append('inv_abt_urself', data.inv_abt_urself);
        formData.append('linkedin_profile', data.linkedin_profile);
        this.http.post(this.config.apiEndpoint + '/updateinvestorindividualdetails/' + this.profile_str, formData).subscribe(
            data => {
                //console.log("Individual Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.investorProfileSuccessError = data;
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                //console.log(error);
                this.investorProfileFailError = "Please Enter Correct Details";
            });
    }

    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.files = fileInput.target.files;
        }
    }

    getinvestorIndividualType(profile_str: any): void {
        this.myprofileService.getinvestorIndividualType(this.profile_str)
            .then((result) => {
                this.investorIndividualTypedata = result;
            });
    }

    getinvestorAdvDetails(profile_str: any): void {
        this.myprofileService.getinvestorAdvDetails(this.profile_str)
            .then((result) => {
                this.investorAdvertisementdata = result;
            });
    }

    getcheckBxInvestorType(userID: any, profile_str: any): void {
        this.myprofileService.getcheckBxInvestorType(this.userID, this.profile_str)
            .then((result) => {
                this.bxInvestorType = result;
            });
    }

    ngOnInit() {
        this.getinvestorIndividualType(this.profile_str);
        this.getinvestorAdvDetails(this.profile_str);
        this.getcheckBxInvestorType(this.userID, this.profile_str);
        if (isPlatformBrowser(this.platformId)) {
            $('.other-detail input[type="checkbox"]').click(function () {
                if ($(this).attr("name") == "invest_pref") {
                    $(".investment").slideToggle();
                }
                if ($(this).attr("name") == "full_acquisition") {
                    $(".acquisition").slideToggle();
                }
            });
        }
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="investorForm"]').validator();
        }
    }

}
