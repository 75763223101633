<!-- login -->
<div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginLabel" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <button type="button" class="close" data-dismiss="modal" aria-label="Close" *ngIf="hideClose">
            <span aria-hidden="true">&times;</span>
         </button>

         <div class="modal-body">
            <div class="popimgleft">
               <div class="textblk">
                  <div class="innertextblk">
                     <div class="loginblk toggleBlock">
                        <div class="h2bg">Welcome Back!</div>
                        <p class="txt">To keep connected with us,
                           please login with your personal info</p>
                     </div>

                     <div class="forgotblk toggleBlock">
                        <div class="h2bg">FORGOT PASSWORD</div>
                        <p class="txt">Enter your email address associated with your BusinessEx account and we will send
                           you a link to reset your password.</p>
                     </div>

                     <div class="regidterblk toggleBlock" style="display:none;">
                        <div class="h2bg">Why should I register?</div>
                        <p class="txt">BusinessEx is a platform for everyone in the Business community to move ahead on
                           their expansion path. Register today to unveil the Business opportunity of your dreams! Your
                           basic information will only be used to create Login profile and will never be used for any
                           other purposes.</p>
                     </div>

                  </div>
               </div>
            </div>
            <div class="poptxtright">
               <!-- Nav tabs -->
               <ul class="nav nav-tabs">
                  <li class="nav-item">
                     <a class="nav-link active" data-toggle="tab" href="#LoginBlock" (click)="openTab('loginblk')"><span
                           class="loginblk loginText">Login</span> <span class="forgotblk">Forgot</span></a>
                  </li>

                  <li class="nav-item">
                     <a class="nav-link" data-toggle="tab" href="#Register"
                        (click)="openTab('regidterblk')">Register</a>
                  </li>
               </ul>

               <!-- Tab strat her  -->
               <div class="tab-content">

                  <div class="tab-pane container active" id="LoginBlock">

                     <div class="loginblk">
                        <div class="soc">

                           <div class="innsoc">
                              <a [routerLink]="" (click)="loginWithGoogle()" class="active"><img
                                    src="./assets/img/google.svg">
                                 <!-- <span>{{(socialLoading=='goggle')? 'Please Wait...': 'Login with Google'}}</span> -->
                              </a>
                           </div>

                           <div class="innsoc">
                              <a [routerLink]="" (click)="loginWithFacebook()"><img src="./assets/img/fb.svg">
                                 <!-- <span>{{(socialLoading=='facebook')? 'Please Wait...': 'Login with Facebook'}}</span> -->
                              </a>
                           </div>
                           <div class="innsoc">
                              <a [routerLink]="" (click)="loginWithLinkedin()"><img
                                    src="./assets/img/linkedins.svg">
                                 <!-- <span>{{(socialLoading=='linkedin')? 'Please Wait...': 'Login with Linkedin'}}</span> --></a>
                           </div>
                        </div>
                        <div class="emaishow">Or use your email account</div>

                        <div class="error-msg alert-danger">
                           <app-alert></app-alert>
                        </div>

                        <form [formGroup]="loginForm" (ngSubmit)="lFormSubmit()">
                           <div class="frmblk">
                              <div class="input-group mb-4">
                                 <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><img
                                          src="./assets/img/email-iconnew.svg"></span>
                                 </div>
                                 <input type="email" class="form-control" placeholder="Enter Your Email ID"
                                    aria-label="Username" aria-describedby="basic-addon1" formControlName="email">

                                 <div *ngIf="submitted && lForm.email.errors" class="invalid-feedback">
                                    <div *ngIf="lForm.email.errors.required">Please Enter your email id</div>
                                 </div>
                              </div>

                              <div class="input-group mb-4">
                                 <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><img
                                          src="./assets/img/lock-icon.svg"></span>
                                 </div>
                                 <input type="password" class="form-control" placeholder="Enter Your Password"
                                    aria-label="Username" aria-describedby="basic-addon1" formControlName="password">

                                 <div *ngIf="submitted && lForm.password.errors" class="invalid-feedback">
                                    <div *ngIf="lForm.password.errors.required">Please Enter your Password</div>
                                 </div>

                              </div>
                              <div class="ffull">
                                 <div class="pleft">
                                    <input type="checkbox"> Keep me Logged In
                                 </div>
                                 <div class="pright">
                                    <a [routerLink]="" onclick="forgot_panel()">Forgot Password?</a>
                                 </div>
                              </div>
                              <input type="submit" value="Login" class="popbtn">
                           </div>
                        </form>
                     </div>

                     <div class="forgotblk">

                        <form [formGroup]="forgotForm" (ngSubmit)="fFormSubmit()">
                           <div class="frmblk">
                              <div class="input-group mb-4">
                                 <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><img
                                          src="./assets/img/email-iconnew.svg"></span>
                                 </div>
                                 <input type="email" class="form-control" placeholder="Enter Your Email ID"
                                    aria-label="Username" aria-describedby="basic-addon1" formControlName="email">

                                 <div *ngIf="submitted && fForm.email.errors" class="invalid-feedback">
                                    <div *ngIf="fForm.email.errors.required">Please Enter your email id</div>
                                 </div>
                              </div>

                              <div class="ffull">
                                 <div class="pleft">
                                    <input type="submit" value="Submit" class="popbtn">
                                 </div>
                                 <div class="pright">
                                    <a [routerLink]="" onclick="login_panel()">Login</a>
                                 </div>
                              </div>

                           </div>
                        </form>

                     </div>

                     <div class="thank-you" style="display:none;">
                        <div class="desc">
                           Thank you for submitting your E-mail information with <a routerLink="/">BusinessEX.com</a>.
                           Please check your mail to reset your Password.
                        </div>
                     </div>

                  </div>

                  <div class="tab-pane container fade" id="Register">

                     <div class="soc">
                        <div class="innsoc">
                           <a [routerLink]="" (click)="loginWithGoogle()" class="active"><img
                                 src="./assets/img/google.svg">
                              <!-- <span>{{(socialLoading=='goggle')? 'Please Wait...': 'Login with Google'}}</span> -->
                           </a>
                        </div>

                        <div class="innsoc">
                           <a [routerLink]="" (click)="loginWithFacebook()"><img src="./assets/img/fb.svg">
                              <!-- <span>{{(socialLoading=='facebook')? 'Please Wait...': 'Login with Facebook'}}</span> -->
                           </a>
                        </div>
                        <div class="innsoc">
                           <a [routerLink]="" (click)="loginWithLinkedin()"><img
                                 src="./assets/img/linkedins.svg">
                              <!-- <span>{{(socialLoading=='linkedin')? 'Please Wait...': 'Login with Linkedin'}}</span> --></a>
                        </div>
                     </div>

                     <div class="emaishow">Or use your email account</div>
                     <div class="error-msg alert-danger">
                        <app-alert></app-alert>
                     </div>

                     <div class="thank-you" style="display:none;">
                        <div class="ttl">Thank you for Signing Up!</div>
                        <div class="desc">
                           Thank you for registering with <a routerLink="/">BusinessEx.com</a>.
                           Please check your mail to confirm your Login credentials.
                        </div>
                     </div>

                     <form [formGroup]="registerForm" (ngSubmit)="submitRegister()">
                        <div class="frmblk">
                           <div class="input-group mb-4">
                              <div class="input-group-prepend">
                                 <span class="input-group-text" id="basic-addon1">
                                    <img src="./assets/img/email-iconnew.svg">
                                 </span>
                              </div>

                              <input formControlName="remail" type="email" class="form-control"
                                 placeholder="Enter Your Email ID" aria-label="Username"
                                 aria-describedby="basic-addon1">

                              <div *ngIf="submitted && rForm.remail.errors" class="invalid-feedback">
                                 <div *ngIf="rForm.remail.errors.required">Please Enter your email id</div>
                              </div>



                           </div>

                           <div class="input-group mb-4">
                              <div class="input-group-prepend">
                                 <span class="input-group-text" id="basic-addon1">
                                    <img src="./assets/img/lock-icon.svg"></span>
                              </div>
                              <input formControlName="rpassword" type="password" class="form-control"
                                 placeholder="Enter Your Password" aria-label="Username"
                                 aria-describedby="basic-addon1">

                              <div *ngIf="submitted && rForm.rpassword.errors" class="invalid-feedback">
                                 <div *ngIf="rForm.rpassword.errors.required">Please Enter your Password</div>
                              </div>

                           </div>

                           <div class="input-group mb-4">
                              <div class="input-group-prepend">
                                 <span class="input-group-text" id="basic-addon1"><img
                                       src="./assets/img/lock-icon.svg"></span>
                              </div>
                              <input formControlName="cpassword" type="password" class="form-control"
                                 placeholder="Enter Confirm Password" aria-label="Username"
                                 aria-describedby="basic-addon1">

                              <div *ngIf="submitted && rForm.cpassword.errors" class="invalid-feedback">
                                 <div *ngIf="rForm.cpassword.errors.required">Confirm Password is required</div>
                                 <div *ngIf="rForm.cpassword.errors.mustMatch">Passwords do not match</div>
                              </div>

                           </div>

                           <div class="ffull">

                              <div class="pleft">
                                 <input type="checkbox" formControlName="subscribe"> Subscribe for Daily Updates</div>

                           </div>
                           <input type="submit" value="Submit" class="popbtn">
                        </div>
                     </form>

                  </div>
               </div>
               <!-- tab end her  -->
            </div>
         </div>
      </div>
   </div>
</div>