
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                               
                     <!-- Profile Detail Left Panel start here -->
                    <profile-detail></profile-detail>
                    <!-- Profile Detail Left Panel end here -->
                     <div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
                        <div class="bdr">   
                            <div class="cat-list">
                                <div class="cat-sec-ttl">
                                    MANAGE CONFIDENTIAL INFORMATION                                          
                                </div>

                                <!-- search keywords start -->
                                <div class="colorstrip">
                                    <ul>
                                        <li class="selected"><a routerLink="/dashboard/brokerConfidential/{{profile_str}}">Confidential Info</a></li>
                                        <li><a routerLink="/dashboard/brokerBusiness/{{profile_str}}">Business Details</a></li>
                                        <li><a routerLink="/dashboard/brokerLocation/{{profile_str}}">Location Info</a></li>
                                        <li><a routerLink="/dashboard/brokerOther/{{profile_str}}">Other Info</a></li>
                                    </ul>
                                </div>
                                <!-- search keywords end -->

                            </div>         
                            <div class="margin-20">
                                <div class="row form-sec">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                    <form name="brokerForm" #brokerForm="ngForm" (ngSubmit)="brokerForm.form.valid && brokerMyAccountSubmit(brokerForm.value)" class="form-horizontal" role="form">
                                        <div class="sec-slide-effect">

                                            <div class="msg-pnl_sales" *ngIf="brokerProfileSuccessError" style="margin-top:10px; text-align:center;">{{brokerProfileSuccessError}}</div> 
                                            <div class="msg-pnl_sales" *ngIf="brokerProfileFailError" style="margin-top:10px; text-align:center;">{{brokerProfileFailError}}</div> 
                                            
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-12">
                                                    <div class="frm-sec-ttl">Confidential Information</div>
                                                </div>
                                            </div>
                                            <div class="row has-feedback">
                                                <label class="col-xs-12 col-sm-6 col-md-4 control-label">Your Name<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" placeholder="Enter Your Name" name="broker_name"  [ngModel]="BrokerConfidentialdata?.broker_name" data-error="Please enter name." required>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div class="row has-feedback">
                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Mobile Number<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <input type="text" pattern="[56789][0-9]{9}" minlength="10" maxlength="10" class="form-control" name="broker_mobile"  [ngModel]="BrokerConfidentialdata?.broker_mobile"  placeholder="Enter Your Mobile Number" data-error="Please enter valid mobile number" required>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div class="row has-feedback">
                                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Email ID<span class="star">*</span> <span>:</span></label>
                                                <div class="col-xs-12 col-sm-6 col-md-7">
                                                    <div class="input-group">
                                                        <input type="email" class="form-control" minlength="10" maxlength="55" name="broker_email"  [ngModel]="BrokerConfidentialdata?.broker_email" placeholder="Enter Your Email ID" data-error="Please enter email id." required>
                                                        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                                    </div>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div class="sec-slide-effect txt-cen">                                  
                                            <div class="submitfrm">
                                                <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                                            </div>
                                        </div>
                                    </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>                                
            </div>
        </div>
    </div>                    
</div>
<!-- End Start Body Part -->