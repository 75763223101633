
<div class="col-xs-12 col-sm-4 col-md-3 mdy-width-27">

    <!--Google DFP Right Side Banner Start Here -->
    <div *ngIf="bxbannerrightside !== ''" class="desc-banner" style="margin-bottom: 30px;">
        <google-dfp [bannerId]="bxbannerrightside?.dfpid"  [dfpSlot]="bxbannerrightside?.dfpslot" [bWidth]="bxbannerrightside?.width" [bHeight]="bxbannerrightside?.height"></google-dfp>
        <!--<google-dfp id="div-gpt-ad-1498540898563-5" [bannerId]="'div-gpt-ad-1498540898563-5'"  [dfpSlot]="'/1057625/RightSide-300X250-1'" [bWidth]="300" [bHeight]="250"></google-dfp>-->
    </div>
    <!--Google DFP Right Side Banner End Here -->

    <div class="bdr" *ngIf="ProfileSelected?.profile_type == '1' || ProfileSelected?.profile_type == '7' || ProfileSelected?.profile_type == '6'">
        <div class="cat-list recommendations cat-listing investor">
            <div class="cat-sec-ttl">TOP 5 RECOMMENDATIONS</div>
            <ul class="investor">
                <li *ngFor="let recommend of investorRecommend">
                    <div class="row business-list-container">
                        <div class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect">
                            <div class="top-sec-list {{recommend.investorPlan}}">
                                <div class="profile-sec">
                                    <div class="profile-ttl">
                                        <div>
                                            <!--   {{ (recommend.designation !== '') ? recommend.designation : ("N/A") }}
                                              {{ (recommend.companyName !== '') ? recommend.companyName : ("N/A") }} -->
                                        </div>
                                        <div>
                                            <span *ngIf="recommend.investorType != ''">{{recommend.investorType}}</span>
                                        </div>
                                        <div class="location">
                                            {{ (recommend.country !== 'NULL') ? recommend.country : ("India") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="other-info">
                                    <div>Profile Type <span>Investor</span></div>
                                    <div>Investment Size
                                        <span>
                                            <i class="fa fa-inr" aria-hidden="true"></i>
                                            {{ (recommend.minInvestment !== '') ? recommend.minInvestment : ("N/A") }}
                                             -  <i class="fa fa-inr" aria-hidden="true"></i>
                                            {{ (recommend.maxInvestment !== '') ? recommend.maxInvestment : ("N/A") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="addinfonew" *ngIf="recommend.isSponsored">Sponsored</div>
                                <a routerLink="/investor/{{recommend.investorurl}}/{{recommend.investorStr}}" class="contact-btn">SEND PROPOSAL</a>
                            </div>
                            <div class="investor-tag">
                                <img *ngIf="recommend.membership_plan==1" src="assets/images/premium_inv.png">
                                <img *ngIf="recommend.membership_plan==2" src="assets/images/gold_inv.png">
                                <img *ngIf="recommend.membership_plan==3" src="assets/images/platinum_inv.png">
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li *ngFor="let lender of lenderRecommend">
                <div class="row business-list-container">
                    <div class="col-xs-12 col-sm-12 col-md-12 sec-slide-effect">
                        <div class="top-sec-list">
                            <div class="profile-sec">
                                <div class="profile-ttl">
                                    <div>
                                        {{ (lender.designation !== '') ? lender.designation : ("N/A") }}
                                        {{ (lender.company !== '') ? lender.company : ("N/A") }}
                                    </div>
                                    <div class="location">
                                        {{ (lender.country !== '') ? lender.country : ("N/A") }}
                                    </div>
                                </div>
                            </div>
                            <div class="other-info">
                                <div>Lender Type <span>{{ (lender.lenderType !== '') ? lender.lenderType : ("N/A") }}</span></div>
                                <div>Lending Capacity
                                    <span>
                                        <i class="fa fa-inr" aria-hidden="true"></i>
                                        {{ (lender.lendingCapacity !== '') ? lender.lendingCapacity : ("N/A") }}
                                    </span>
                                </div>
                            </div>
                            <a routerLink="lender/{{lender.lender_profile_str}}" class="contact-btn">SEND PROPOSAL</a>
                        </div>
                    </div>
                </div>
                </li> -->
            </ul>
        </div>
    </div>

    <div class="bdr" *ngIf="ProfileSelected?.profile_type == '2' || ProfileSelected?.profile_type == '3' || ProfileSelected?.profile_type == '4' || ProfileSelected?.profile_type == '5' || ProfileSelected?.profile_type == ''">

        <div class="cat-list recommendations">
            <div class="cat-sec-ttl">TOP 5 RECOMMENDATIONS </div>

            <ul>
                <li *ngFor="let seller of sellerRecommend | slice:0:5; let i=index; let last = last" [isLast]="last" (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <a routerLink="/business/{{seller.sellerurl}}/{{seller.profileStr}}">
                                <span *ngIf="seller.thumbimage == null"><img class="img-responsive" src="assets/images/category/small/{{seller.parentCatId}}.jpg" alt="Featured Business"/></span>
                                <span *ngIf="seller.thumbimage != null && seller.thumbimage != ''"><img class="img-responsive" src="{{seller.thumbimage}}" alt="Featured Business"/></span>
                                <div class="primiumtag" *ngIf="seller.membership_paid == 1">

                                    <img *ngIf="seller.membership_plan==1" src="assets/images/premium.png">
                                    <img *ngIf="seller.membership_plan==2" src="assets/images/gold.png">
                                    <img *ngIf="seller.membership_plan==3" src="assets/images/platinum.png">

                                    <span *ngIf="seller.membership_plan==1">Premium</span>
                                    <span style="color:#000" *ngIf="seller.membership_plan==2">Gold</span>
                                    <span style="color:#000" *ngIf="seller.membership_plan==3">Platinum</span>
                                </div>
                                <div class="addinfo" *ngIf="seller.isSponsored">Sponsored</div>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <div class="cat-ttl ttl-ellipsis">
                                <a routerLink="/business/{{seller.sellerurl}}/{{seller.profileStr}}">
                                    {{ (seller.title !== '') ? (seller.title) : ("N/A") }}
                                </a>
                            </div>
                            <div class="investment-price">{{ (seller.price !== '') ? seller.price : ("N/A") }}<div>{{seller?.priceLabel}}</div></div>
                        </div>
                    </div>
                </li>
                <li *ngFor="let startup of startupRecommend | slice:0:3; let i=index; let last = last" [isLast]="last" (lastDone)="appConfig.pageScrollAndShortTitle(isViewChecked)">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <a routerLink="/startup/{{startup.startupurl}}/{{startup.profileStr}}">

                                <span *ngIf="startup.thumbimage == null"><img class="img-responsive" src="assets/images/category/small/{{startup.parentCatId}}.jpg" alt="Featured Business"/></span>
                                <span *ngIf="startup.thumbimage != null && startup.thumbimage != ''"><img class="img-responsive" src="{{startup.thumbimage}}" alt="Featured Business"/></span>
                                <div class="primiumtag" *ngIf="startup.membership_paid == 1">

                                    <img *ngIf="startup.membership_plan==1" src="assets/images/premium.png">
                                    <img *ngIf="startup.membership_plan==2" src="assets/images/gold.png">
                                    <img *ngIf="startup.membership_plan==3" src="assets/images/platinum.png">

                                    <span *ngIf="startup.membership_plan==1">Premium</span>
                                    <span style="color:#000" *ngIf="startup.membership_plan==2">Gold</span>
                                    <span style="color:#000" *ngIf="startup.membership_plan==3">Platinum</span>
                                </div>
                                <div class="addinfo" *ngIf="startup.isSponsored">Sponsored</div>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <div class="cat-ttl ttl-ellipsis">
                                <a routerLink="/startup/{{startup.startupurl}}/{{startup.profileStr}}">
                                    {{ (startup.title !== '') ? (startup.title) : ("N/A") }}
                                </a>
                            </div>
                            <div class="investment-price"> {{ (startup.price !== '') ? startup.price : ("N/A") }} <div>{{startup?.priceLabel}}</div></div>
                        </div>
                    </div>
                </li>
            </ul>

        </div>

    </div>

</div>
