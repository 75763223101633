import {AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MyProfileService} from '../../../../services/my-profile.service';
import {APP_CONFIG, PROFILE_ARRAY, PROFILE_TYPE} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {appConfig} from '../../../frontend/app.config';

declare var $: any;

@Component({
    selector: 'profile-detail',
    templateUrl: './profile-detail.component.html'
})
export class ProfileDetailComponent implements OnInit, AfterViewChecked {
    jwtHelper = new JwtHelperService();
    public users;
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileObjects = this.decode.userProfile;
    userID = this.decode.sub;
    public ProfileSelected;
    public Profilepercentage;
    public bxInvestorType;
    public totalclickthreemonth;
    public vistorlist;
    public userProfileType;
    public bxLenderType;
    unique_profile;
    useraccount;
    files;
    public profiles = PROFILE_TYPE;
    @Output() ProfileChange = new EventEmitter();
    @Input() profileViewCount = 0;
    activeMenu;
    membershipCount = this.decode.membershipCount;

    constructor(private http: HttpClient, private myprofileService: MyProfileService,
                route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig, private router: Router) {
        route.paramMap.subscribe(
            params => this.unique_profile = params.get('profile')
        );

        //console.log(this.profileObjects);

    }

    fileChangeEvent(fileInput: any) {
        this.files = fileInput.target.files;
        const formData: any = new FormData();
        if (this.files != undefined) {
            formData.append('profile_pic', this.files[0], this.files[0].name);
        }
        formData.append('user_id', this.userID);

        //Post values here
        this.http.post(this.config.apiEndpoint + '/userprofilepicupload', formData).subscribe(
            data => {
                //console.log('profile pic uploaded successfull');
                this.getUserDetails();
            },
            error => {
                //console.log('profile pic uploaded failed');
            });

    }


    getcheckBxInvestorType(): void {
        this.myprofileService.getcheckBxInvestorType(this.userID, this.ProfileSelected.profile_str)
            .then((result) => {
                this.bxInvestorType = result;
            });
    }

    getcheckBxLenderType(): void {
        this.myprofileService.getcheckBxLenderType(this.userID, this.ProfileSelected.profile_str)
            .then((result) => {
                this.bxLenderType = result;
            });
    }

    getUserDetails(): void {
        if (this.userID !== null && this.userID !== undefined) {
            this.myprofileService.getUserDetails(this.userID)
                .then((result) => {
                    this.useraccount = result;
                });
        }
    }

    getUserRegisterProfileType(): void {
        this.myprofileService.getUserRegisterProfileType(this.userID)
            .then((result) => {
                this.userProfileType = result;
                // user registration type in local storage
                localStorage.setItem('userRegisterProfileType', result.reg_profile);
            });
    }


    getUserProfile() {
        const token = localStorage.getItem('currentUser');
        if (token && token.split('.').length === 3) {
            this.users = this.jwtHelper.decodeToken(token);
            return this.users;
        }
    }

    setActive = function (menuItem: any) {
        localStorage.setItem('activeMenu', menuItem);
    };

    getProfilepercentage(): void {
        this.myprofileService.getProfilepercentage(this.ProfileSelected.profile_type, this.ProfileSelected.profile_str)
            .then((result) => {
                this.Profilepercentage = result;
            });
    }

    getVisitorSeller(): void {
        this.myprofileService.getVisitorSeller(this.ProfileSelected.profile_type, this.ProfileSelected.profile_str)
            .then((result) => {
                this.totalclickthreemonth = result.threemoncount;
                this.vistorlist = result.vistor;
            });
    }

    onChangeObj(profiletype) {
        localStorage.setItem('ProfileType', profiletype.profile_name);
        localStorage.removeItem('activeMenu');
        this.ProfileSelected = profiletype;
        appConfig.setNewProfileDetails(this.userID, this.ProfileSelected.profile_type, this.ProfileSelected.profile_id);
        this.getVisitorSeller();
        this.getProfilepercentage();
        if (this.ProfileSelected.profile_type === 2) {
            this.getcheckBxInvestorType();
        }
        if (this.ProfileSelected.profile_type === 3) {
            this.getcheckBxLenderType();
        }

        /*
            using output decorator to send the profile change for updating right section
            this happens only when user is on myaccount page
        */
        if (this.router.url === '/dashboard/myaccount') {
            this.ProfileChange.emit(profiletype);
        }
        this.router.navigate(['/dashboard/myaccount']);

    }

    ngOnInit() {
       // console.log(this.decode)
    
        this.typecheck();
        this.getUserProfile();
        this.getProfilepercentage();
        this.getVisitorSeller();
        this.getUserRegisterProfileType();
        this.getUserDetails();
    }

    ngAfterViewChecked(): void {
        const chkwidth = $('.safari .myAccount .profile-detail').width() - 78;
        $('.safari .myAccount .profile-detail .profile-info').css('width', chkwidth);
    }

    typecheck() {
        this.openMenu();
        if (localStorage.ProfileType !== undefined) {
            const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
            this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
        } else {
            this.ProfileSelected = this.profileObjects[0];
        }

        if (this.ProfileSelected.profile_type !== 0 && this.ProfileSelected.profile_status === 3) {
            appConfig.setNewProfileDetails(this.userID, this.ProfileSelected.profile_type, this.ProfileSelected.profile_id);
        }

        if (this.ProfileSelected.profile_type === 2) {
            this.getcheckBxInvestorType();
        }
        if (this.ProfileSelected.profile_type === 3) {
            this.getcheckBxLenderType();
        }
    }

    private openMenu() {
        if (localStorage.getItem('activeMenu') !== null) {
            this.activeMenu = localStorage.getItem('activeMenu');
            const myInteraction = ['myinteraction', 'contactHistory', 'instaResponse', 'bxproposal'];
            const profileNav = ['pNav1', 'pNav2', 'pNav3', 'pNav4', 'pNav5', 'pNav6', 'pNav7', 'pNav8', 'pNav9'];
            if ($.inArray(this.activeMenu, myInteraction) > -1) {
                setTimeout(function () {
                    $('#intraction_1').slideDown(300).addClass('open');
                }, 100);
            }
            if ($.inArray(this.activeMenu, profileNav) > -1) {
                setTimeout(function () {
                    $('#profileNav_1').slideDown(300).addClass('open');
                }, 100);
            }
        }
    }
}
