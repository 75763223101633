import {Injectable} from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn:'root'
})

export class LoginGuard implements CanActivate{

    constructor (private auth:AuthenticationService,protected router:Router){}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        if(!this.auth.isAuthenticated()){
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }

}

