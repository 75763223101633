import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MyProfileService} from '../../../../services/my-profile.service';
import {isPlatformBrowser} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {PROFILE_TYPE} from '../../../../config/app-config.constants';

declare var $: any;
@Component({
    templateUrl: './business-proposal.component.html'
})
export class BxproposalComponent implements OnInit {
    jwtHelper = new JwtHelperService();
    users = {};
    token: any;
    decode;
    profileObjects;
    userID;
    notifycreatedate;
    requestlist;
    increamented;
    totalrequest;
    requestlimit: number = 10;
    msg;
    public profiles = PROFILE_TYPE;
    messageLoading: boolean;

    constructor(private meta: Meta,
                private title: Title,
                private myprofileService: MyProfileService,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Business Proposal - BusinessEx');

    }

    check(): void {
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.profileObjects = this.decode.userProfile;
            this.userID = this.decode.sub;
            this.notifycreatedate = this.decode.notify_date;
        }
    }

    getRequestlist(): void {
        this.messageLoading = true;
        this.myprofileService.getRequestlist(this.userID)
            .then((result) => {
                this.messageLoading = false;
                if (result.massge === 'null') {
                    this.totalrequest = 0;
                } else {
                    this.requestlist = result;
                    this.totalrequest = this.requestlist.length;
                    if (this.totalrequest > 0) {
                        this.myprofileService.updateProposalViewedStatus(this.userID);
                    }
                }
            });
    }

    requestListingLoadMore(requestlist): void {
        this.requestlist = requestlist;
        this.increamented = this.requestlimit + 5;
        if (this.increamented > this.requestlist.length) {
            this.requestlimit = this.requestlist.length;
            $('#requestList').hide();
        } else {
            this.requestlimit = this.increamented;
        }

    }

    handleRequest(proposals, requestStatus): void {
        this.myprofileService.handleRequest(proposals, requestStatus)
            .then((result) => {
                if (result.massge === 'Accept Reguest successfully' || result.massge === 'Reject Reguest successfully') {
                    this.getRequestlist();
                }
            });
    }

    ngOnInit() {
        this.check();
        this.getRequestlist();
    }

}
