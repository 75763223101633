<div class="col-md-3 mdy-width-23 profile-lft-pnl">
    <div class="left-menu bdr">
        <div class="profile-detail">
            <div class="profile-pic">
                <img src={{decode?.profile_pic}}  class="img-responsive" alt="profile pic"/>
                <div class="camera-icn">
                    <img class="cm-icn" src="assets/images/cm_icn.png"/>
                    <input type="file" name="input-img-preview" class="file-upload" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)">
                </div>
            </div>
            <div class="profile-info">
                <div class="location" *ngIf="decode?.location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{decode?.location}}</div>
                <div class="location" *ngIf="!decode?.location"><i class="fa fa-map-marker" aria-hidden="true"></i> India</div>
                <div class="name" *ngIf="decode?.name != ''">{{decode?.name}}</div>
                <div class="designation tpmr" *ngIf="decode?.designation != ''">{{decode?.designation}}</div>
                <div class="designation" *ngIf="decode?.company_name != ''">{{decode?.company_name}}</div>
            </div>
            <a class="edit-pfl" [routerLink]="" data-toggle="modal" data-target="#edit-info"><i class="fa fa-pencil" aria-hidden="true"></i></a>
        </div>

        <div class="cat-listing investor menu-verify">
            <div class="verified-sec">
                <div class="email active" *ngIf="decode?.email != ''">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i> Email
                    <div class="verified-pop-over">
                        <span><i class="fa fa-check" aria-hidden="true"></i></span>
                        <span>{{decode?.email}}</span>
                    </div>
                </div>
                <div class="email active" *ngIf="decode?.email == ''">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i> Email
                    <div class="verified-pop-over grey">
                        <span><i class="fa fa-check" aria-hidden="true"></i></span>
                        <span>Not Verified Email</span>
                    </div>
                </div>
                <div class="phone active" *ngIf="decode?.mobile != 0">
                    <i class="fa fa-phone" aria-hidden="true"></i> Phone
                    <div class="verified-pop-over">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        {{decode?.mobile}}
                    </div>
                </div>
                <div class="phone" *ngIf="decode?.mobile == 0">
                    <i class="fa fa-phone" aria-hidden="true"></i> Phone
                    <div class="verified-pop-over grey">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        Not Verified Mobile
                    </div>
                </div>

                <div class="social">
                <span *ngIf="users.reg_source == '2'">
                <a [routerLink]="" class="facebook active"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </span>
                    <span *ngIf="users.reg_source != '2'">
                <a [routerLink]="" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </span>
                    <span *ngIf="users.reg_source == '3'">
                <a [routerLink]="" class="twitter active"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                </span>
                    <span *ngIf="users.reg_source != '3'">
                <a [routerLink]="" class="twitter"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                </span>
                    <span *ngIf="users.reg_source == '4'">
                <a [routerLink]="" class="linkedin active"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                </span>
                    <span *ngIf="users.reg_source != '4'">
                <a [routerLink]="" class="linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                </span>
                </div>
            </div>
        </div>
    <!--<div class="investor-profile">-->
        <!--<img *ngIf="useraccount?.profile_pic != ''" src={{useraccount?.profile_pic}}  class="img-responsive"/>-->
        <!--<img *ngIf="useraccount?.profile_pic == '' || useraccount?.profile_pic == null" src="assets/images/userprofilepic.gif" alt="profile pic"/>-->

        <!--<div class="band">-->
            <!--<div class="social">-->
                <!--<span *ngIf="users.reg_source == '2'">-->
                <!--<a [routerLink]="" class="facebook active"><i class="fa fa-facebook" aria-hidden="true"></i></a>-->
                <!--</span>-->
                <!--<span *ngIf="users.reg_source != '2'">-->
                <!--<a [routerLink]="" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>-->
                <!--</span>-->
                <!--<span *ngIf="users.reg_source == '3'">-->
                <!--<a [routerLink]="" class="twitter active"><i class="fa fa-google-plus" aria-hidden="true"></i></a>-->
                <!--</span>-->
                <!--<span *ngIf="users.reg_source != '3'">-->
                <!--<a [routerLink]="" class="twitter"><i class="fa fa-google-plus" aria-hidden="true"></i></a>-->
                <!--</span>-->
                <!--<span *ngIf="users.reg_source == '4'">-->
                <!--<a [routerLink]="" class="linkedin active"><i class="fa fa-linkedin" aria-hidden="true"></i></a>-->
                <!--</span>-->
                <!--<span *ngIf="users.reg_source != '4'">-->
                <!--<a [routerLink]="" class="linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a>-->
                <!--</span>-->
            <!--</div>-->
             <!--<div class="camera-icn">-->
                <!--<img src="assets/images/camera.png"/>-->
                <!--<input type="file" name="input-img-preview" class="file-upload" accept="image/png, image/jpeg, image/gif" (change)="fileChangeEvent($event)">-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
    <!--<div class="investor-detail bdr-detail">-->
        <!--<div class="location" *ngIf="users?.location"><i class="fa fa-map-marker" aria-hidden="true"></i> {{users?.location}}</div>-->
        <!--<div class="name" *ngIf="useraccount?.name != ''">{{useraccount?.name}}</div>-->
        <!--<div class="designation" *ngIf="useraccount?.designation != ''">{{useraccount?.designation}}</div>-->
        <!--<div class="designation" *ngIf="useraccount?.company_name != ''">{{useraccount?.company_name}}</div>-->
        <!--<div class="contact" *ngIf="useraccount?.mobile != 0">MOBILE<div>{{useraccount?.mobile}}</div></div>-->
        <!--<div class="contact"*ngIf="useraccount?.email != ''">EMAIL<div>{{useraccount?.email}}</div></div>-->
        <!--<a class="upgrade edit" [routerLink]="" data-toggle="modal" data-target="#edit-info">Edit Info</a>-->
    <!--</div>-->
    <div *ngIf="userProfileType?.reg_profile != 'Broker'">
    <div class="completed-profile investor-detail" *ngIf="users.sub != '' && ProfileSelected?.profile_id != 0">
        <div *ngIf="users.sub != '' && ProfileSelected?.profile_id != 0" class="contact padrm">PROFILE
        <div class="btm-pad">
            <select class="selectpicker crt-prfile" (ngModelChange)="onChangeObj($event)" [ngModel]="ProfileSelected" name="profiletype">
                 <option   [ngValue]="profiletype" *ngFor="let profiletype of profileObjects">{{profiletype.profile_name}}</option>
            </select>
        </div>
        </div>

        <div *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0">
            <!-- activate account option, if profile status is 3 -->
            <a *ngIf="ProfileSelected?.profile_status == 3" [ngClass]="{active : activeMenu === 'upgradeInv'}" (click)="setActive('upgradeInv')"  routerLink="/dashboard/activateAccount" class="upgrade">ACTIVATE ACCOUNT</a>

            <!--Upgrade profile option is not applicable for Platinum member-->
            <a *ngIf="(ProfileSelected?.profile_type == profiles.Business) && ProfileSelected?.profile_status != 3" [ngClass]="{active : activeMenu === 'upgrade'}" (click)="setActive('upgrade')" routerLink="/dashboard/upgrade/{{ProfileSelected?.profile_str}}" class="upgrade">MY PLAN</a>

            <a *ngIf="(ProfileSelected?.profile_type == profiles.Startup) && ProfileSelected?.profile_status != 3" [ngClass]="{active : activeMenu === 'upgrade'}" (click)="setActive('upgrade')" routerLink="/dashboard/upgradeStarup/{{ProfileSelected?.profile_str}}" class="upgrade">MY PLAN</a>

            <a *ngIf="(ProfileSelected?.profile_type == profiles.Investor || ProfileSelected?.profile_type == profiles.Mentor) && ProfileSelected?.profile_status != 3" [ngClass]="{active : activeMenu === 'upgradeInv'}" (click)="setActive('upgradeInv')"  routerLink="/dashboard/upgradeInv/{{ProfileSelected?.profile_str}}" class="upgrade">MY PLAN</a>

        </div>
    </div>
    </div>
    <!--<div *ngIf="userProfileType?.reg_profile == 'Broker'">
    <div class="investor-detail" *ngIf="users.sub != '' && ProfileSelected?.profile_id != 0">
        <div *ngIf="users.sub != '' && ProfileSelected?.profile_id != 0" class="contact padrm">PROFILE
        <div class="btm-pad">
            <select class="selectpicker crt-prfile" (ngModelChange)="onChangeObj($event)" [ngModel]="ProfileSelected" name="profiletype">
            <option   [ngValue]="profiletype" *ngFor="let profiletype of profileObjects | brokerProfileTypeFilter">{{profiletype.profile_name}}</option>
            </select>
        </div>
        </div>
    </div>
    </div>-->
    <!--<div class="completed-profile" *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0">-->
        <!--<div class="profile-completion">You completed <span>{{ Profilepercentage }} %</span> Profile</div>-->
        <!--<div class="progress">-->
            <!--<div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>-->
        <!--</div>-->
    <!--</div>-->
    <div class="sidebar-menu my-account-dropdown">
        <ul>
            <li><a routerLink="/dashboard/myaccount" [ngClass]="{'active' : activeMenu === 'dashboard'}"  (click)="setActive('dashboard')">
                <img src="assets/images/dashboard.png"/>Dashboard</a></li>
            <li *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_status === 1" >
                <a routerLink="/dashboard/profileview" [ngClass]="{'active' : activeMenu === 'profileView'}"  (click)="setActive('profileView')"><img src="assets/images/profile_views.png"/>Profile Views <span class="badge">{{(profileViewCount > totalclickthreemonth) ? profileViewCount: totalclickthreemonth}}</span></a>
            </li>
            <li *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_status === 1">
                  <a *ngIf="ProfileSelected?.profile_type == '1'" [ngClass]="{'active' : activeMenu === 'mybusiness'}"  (click)="setActive('mybusiness')"  routerLink="/dashboard/mybusiness/{{ProfileSelected?.profile_str}}">
                     <img src="assets/images/my_profile.png"/>My Profile
                  </a>
                  <a *ngIf="ProfileSelected?.profile_type == '2'" [ngClass]="{'active' : activeMenu === 'myinvestor'}"  (click)="setActive('myinvestor')" routerLink="/dashboard/myinvestor/{{ProfileSelected?.profile_str}}">
                    <img src="assets/images/my_profile.png"/>My Profile
                  </a>
                  <a *ngIf="ProfileSelected?.profile_type == '3'" [ngClass]="{'active' : activeMenu === 'mylender'}"  (click)="setActive('mylender')" routerLink="/dashboard/mylender/{{ProfileSelected?.profile_str}}">
                    <img src="assets/images/my_profile.png"/>My Profile
                  </a>
                  <a *ngIf="ProfileSelected?.profile_type == '4'" [ngClass]="{'active' : activeMenu === 'mymentor'}"  (click)="setActive('mymentor')" routerLink="/dashboard/mymentor/{{ProfileSelected?.profile_str}}">
                    <img src="assets/images/my_profile.png"/>My Profile
                  </a>
                  <a *ngIf="ProfileSelected?.profile_type == '7'" [ngClass]="{'active' : activeMenu === 'mystartup'}"  (click)="setActive('mystartup')" routerLink="/dashboard/mystartup/{{ProfileSelected?.profile_str}}">
                    <img src="assets/images/my_profile.png"/>My Profile
                  </a>
            </li>
            <li class="intraction" *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0 && ProfileSelected?.profile_status === 1">
                <a [routerLink]="" class="accordian-arrow" id="intraction" onclick="myAccountAccordian('accordian-arrow')"><img src="assets/images/intraction.png"/>My Interactions <span class="fa fa-chevron-down"></span>
                    <span class="fa fa-chevron-up"></span>
                </a>
                <ul class="intraction-list accordian-arrow_1" id="intraction_1">
                    <li><a routerLink="/dashboard/myinteraction" [ngClass]="{'active' : activeMenu === 'myinteraction'}"  (click)="setActive('myinteraction')">BX Inbox <span class="badge"></span></a></li>
                    <li><a routerLink="/dashboard/contactHistory" [ngClass]="{'active' : activeMenu === 'contactHistory'}"  (click)="setActive('contactHistory')">Proposals Sent</a></li>
                    <li><a routerLink="/dashboard/instaResponse" [ngClass]="{'active' : activeMenu === 'instaResponse'}"  (click)="setActive('instaResponse')">Instant Responses</a></li>
                    <li><a routerLink="/dashboard/bxproposal" [ngClass]="{'active' : activeMenu === 'bxproposal'}"  (click)="setActive('bxproposal')">Proposals Received</a></li>
                </ul>
            </li>
            <li class="intraction" *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0 && userProfileType?.reg_profile != 'Broker'">
                <a [routerLink]="" class="accordian-arrow accordian-arrow-manage" id="profileNav" onclick="myAccountAccordian('accordian-arrow-manage')"><img src="assets/images/manage.png"/>Manage <span class="fa fa-chevron-down">

                </span>
                <span class="fa fa-chevron-up"></span>
            </a>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '1'">
                    <li><a routerLink="/dashboard/businessConfidential/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/businessAdvDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Advert Details</a></li>
                    <li><a routerLink="/dashboard/businessInformation/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Business Info</a></li>
                    <li><a routerLink="/dashboard/businessFinanceDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">Financial Details</a></li>
                    <li><a routerLink="/dashboard/businessTeamDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav5'}"  (click)="setActive('pNav5')">Team Details</a></li>
                    <li><a routerLink="/dashboard/businessLocationDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav6'}"  (click)="setActive('pNav6')">Headquarters</a></li>
                    <li><a routerLink="/dashboard/businessRequirement/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav7'}"  (click)="setActive('pNav7')">Requirements</a></li>
                    <li><a routerLink="/dashboard/businessAttachment/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav8'}"  (click)="setActive('pNav8')">Attachments</a></li>
                </ul>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '2'">
                    <li><a routerLink="/dashboard/investorConfidentials/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/investorAdvertisement/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Advert Details</a></li>
                    <li><a *ngIf="bxInvestorType?.type==1" routerLink="/dashboard/investorFirmType/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Business Info</a></li>
                    <li><a *ngIf="bxInvestorType?.type==2" routerLink="/dashboard/investorIndividualType/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav5'}"  (click)="setActive('pNav5')">Profile Info</a></li>
                    <li><a routerLink="/dashboard/investorMultiPref/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav6'}"  (click)="setActive('pNav6')">Preferences</a></li>
                </ul>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '3'">
                    <li><a routerLink="/dashboard/lenderConfidentials/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/lenderAdvertisement/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Advert Details</a></li>
                    <li><a routerLink="/dashboard/privateLender/{{ProfileSelected?.profile_str}}" *ngIf="bxLenderType?.type==2" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Private Lender Info</a></li>
                    <li><a routerLink="/dashboard/nbfcLender/{{ProfileSelected?.profile_str}}"  *ngIf="bxLenderType?.type==3"  [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">NBFC Personnel Info </a></li>
                    <li><a routerLink="/dashboard/lenderPreference/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav5'}"  (click)="setActive('pNav5')">Preferences</a></li>
                </ul>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '4'">
                    <li><a routerLink="/dashboard/mentorConfidentials/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/mentorAdvDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Advert Details</a></li>
                    <li><a routerLink="/dashboard/mentorDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Profile Info</a></li>
                    <li><a routerLink="/dashboard/mentorPreference/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">Preferences </a></li>
                </ul>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '5'">
                    <li><a routerLink="/dashboard/incubatorConfidentials/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/incubatorAdvertisement/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Advert Details</a></li>
                    <li><a routerLink="/dashboard/incubatorDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Incubator Details</a></li>
                    <li><a routerLink="/dashboard/incubatorPreference/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">Preferences </a></li>
                    <li><a routerLink="/dashboard/incubatorAttachment/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav5'}"  (click)="setActive('pNav5')">Attachments</a></li>
                </ul>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '7'">
                    <li><a routerLink="/dashboard/startupConfidentials/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/startupAdvertisement/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Advert Details</a></li>
                    <li><a routerLink="/dashboard/startupCompanyDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Business Info</a></li>
                    <li><a routerLink="/dashboard/startupFinancial/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">Financial Details</a></li>
                    <li><a routerLink="/dashboard/startupLocation/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav5'}"  (click)="setActive('pNav5')">Headquarters </a></li>
                    <li><a routerLink="/dashboard/startupTeamDetails/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav6'}"  (click)="setActive('pNav6')">Team Details </a></li>
                    <li><a routerLink="/dashboard/startupOther/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav7'}"  (click)="setActive('pNav7')">Business Plan </a></li>
                    <li><a routerLink="/dashboard/startupRequirement/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav8'}"  (click)="setActive('pNav8')"> Requirement </a></li>
                    <li><a routerLink="/dashboard/startupAttachment/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav9'}"  (click)="setActive('pNav9')"> Attachments </a></li>
                </ul>
                <ul class="intraction-list accordian-arrow-manage_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '6'">
                    <li><a routerLink="/dashboard/brokerConfidential/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/brokerBusiness/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Business Details</a></li>
                    <li><a routerLink="/dashboard/brokerLocation/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Location Info</a></li>
                    <li><a routerLink="/dashboard/brokerOther/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">Other Info</a></li>
                </ul>
            </li>
            <li class="intraction" *ngIf="users.sub != '' && ProfileSelected?.profile_id !== 0 && userProfileType?.reg_profile == 'Broker'">
                <a [routerLink]="" class="accordian-arrow" id="profileNav" onclick="myAccountAccordian('accordian-arrow')"><img src="assets/images/manage.png"/>Manage <span class="fa fa-chevron-down"></span><span class="fa fa-chevron-up"></span></a>
                <ul class="intraction-list accordian-arrow_1" id="profileNav_1" *ngIf="ProfileSelected?.profile_type == '6'">
                    <li><a routerLink="/dashboard/brokerConfidential/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav1'}"  (click)="setActive('pNav1')">Confidential Info</a></li>
                    <li><a routerLink="/dashboard/brokerBusiness/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav2'}"  (click)="setActive('pNav2')">Business Details</a></li>
                    <li><a routerLink="/dashboard/brokerLocation/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav3'}"  (click)="setActive('pNav3')">Location Info</a></li>
                    <li><a routerLink="/dashboard/brokerOther/{{ProfileSelected?.profile_str}}" [ngClass]="{'active' : activeMenu === 'pNav4'}"  (click)="setActive('pNav4')">Other Info</a></li>
                </ul>
            </li>
            <li *ngIf="userProfileType?.reg_profile == 'Broker'">
                <a routerLink="/dashboard/brokerbusinesslisting" [ngClass]="{'active' : activeMenu === 'bbListing'}"  (click)="setActive('bbListing')">
                    <img src="assets/images/business_listing.jpg"/>Business Listing</a>
            </li>
            <li><a routerLink="/dashboard/changePassword" [ngClass]="{'active' : activeMenu === 'password'}"  (click)="setActive('password')"><img src="assets/images/change_password.png"/>Change Password</a></li>
        </ul>
    </div>
</div>
</div>