<div class="container bex-main">
    <div class="row">
        <div class="col-md-12">
            <h1 class="headblk">The page you requested is not available</h1>
            <p>
                Sorry, we are having a problem executing your request. It is possible your bookmark is old one or you
                just meet broken link. Please refer to link about information.
                <br><br>
                <a [routerLink]="" (click)="back()">Go back</a> | Return to the <a routerLink="/">homepage</a>
            </p>
        </div>
    </div>
</div>