import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sortBy'})
export class SortPipe implements PipeTransform {
    transform(array: Array<string>, sortBy: string, direction = 'ASC'): Array<string> {
        if (array === undefined) {
            return array;
        }
        array.sort((a: any, b: any) => {
            if(direction === 'DESC') {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                } else if (a[sortBy] > b[sortBy]) {
                    return -1;
                } else {
                    return 0;
                }
            }else {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                } else if (a[sortBy] > b[sortBy]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        return array;
    }
}
