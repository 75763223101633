import {AfterViewChecked, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {ContactService} from '../../../../services/contact.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {LinkService} from '../../../../services/link.service';
import {MyProfileService} from '../../../../services/my-profile.service';
import {APP_CONFIG, PROFILE_ARRAY} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {appConfig} from '../../../frontend/app.config';


declare var $: any;

@Component({
    selector: 'add-on',
    templateUrl: './add-on.component.html',
})
export class AddOnComponent implements OnInit, AfterViewChecked, OnDestroy {

    jwtHelper = new JwtHelperService();
    users: any;
    token;
    decode;
    profileObjects;
    userID;
    ProfileSelected;
    unique_str;
    responsevalue;
    submitMessage;
    private isViewChecked = false;
    addOnDetails = [];
    addOnCharges = [];
    creditBalance = [];
    profileViewAmount = '';
    topRecommendationAmount = '';
    interactionAmount = '';
    instaResponseAmount = '';
    acceleratedMarketingAmount = '';
    topBuyerSellerAmount = '';
    addOn1 = '';
    addOn2 = '';
    addOn5 = '';
    addOn6 = '';
    value501;
    value502;
    value503;
    value504;
    value505;
    value506;
    selectedProfilePlan = '';
    addOnFormError = '';
    @Input() isAddOnPage = true;
    profileArray = PROFILE_ARRAY;
    _profile = PROFILE_ARRAY[1];

    constructor(private linkService: LinkService,
                private meta: Meta,
                private title: Title,
                private router: Router,
                private http: HttpClient,
                private myprofileService: MyProfileService,
                private contactService: ContactService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig) {
        linkService.removeCanonicalLink();
        linkService.addTag({rel: 'canonical', href: 'https://www.businessex.com/add-on'});
        title.setTitle('BusinessEx Add on Plan');

    }

    ngOnInit() {
        this.getAddOnConfig();
        if (isPlatformBrowser(this.platformId) && localStorage.getItem('currentUser') !== null) {
            this.token = localStorage.getItem('currentUser');
            this.decode = this.jwtHelper.decodeToken(this.token);
            this.profileObjects = this.decode.userProfile;
            this.userID = this.decode.sub;
            /*if (this.profileObjects[0].profile_id == 0) { // if not any profile, send to quick registration
                this.router.navigate(['/registration/quick-registration']);
            }*/
            this.getPlanDetails(this.userID);
            this.typeCheck();
        } else {
            this.profileObjects = [{
                'profile_id': 0,
                'profile_type': '',
                'profile_status': '',
                'profile_name': '',
                'profile_str': '',
                'payment_status': 0
            }];
            this.userID = '';
            if (isPlatformBrowser(this.platformId)) {
                $('#modalLogin_sales').modal({
                    backdrop: 'static',
                    keyboard: false
                });
            }
        }
    }

    typeCheck(): void {
        if (!this.isAddOnPage) {
            if (localStorage.ProfileType !== undefined) {
                const _profile = PROFILE_ARRAY.filter(item => (item.name == localStorage.ProfileType))[0];
                this.ProfileSelected = this.profileObjects.filter(item => (item.profile_type == _profile.id))[0];
            } else {
                this.ProfileSelected = this.profileObjects[0];
            }
            this._profile = PROFILE_ARRAY.filter(item => (item.id == this.ProfileSelected.profile_type))[0];
        }
    }

    onChangeObj($event) {
        if (this.profileObjects === undefined) {
            return;
        }
        const selectedProfile = this.profileObjects.filter(item => (item.profile_str === $event))[0];
        this._profile = PROFILE_ARRAY.filter(item => (item.id == selectedProfile.profile_type))[0];
        localStorage.setItem('ProfileType', selectedProfile.profile_name); // need to show the appropriate payment plan
        this.ProfileSelected = selectedProfile;
        if (selectedProfile !== undefined) {
            this.unique_str = selectedProfile.profile_str;
            this.checkAddOnExist();
        }
    }

    checkAddOnExist(): void {
        if (this.creditBalance.length > 0) {
            this.selectedProfilePlan = this.creditBalance
                .filter(item => (item.profileType === this.ProfileSelected.profile_type))[0];
        }
        if (this.addOnDetails.length > 0) {
            this.addOn1 = this.addOnDetails
                .filter(item => (item.profileType === this.ProfileSelected.profile_type && item.planId == 501))[0];
            this.addOn2 = this.addOnDetails
                .filter(item => (item.profileType === this.ProfileSelected.profile_type && item.planId == 502))[0];
            this.addOn5 = this.addOnDetails
                .filter(item => (item.profileType === this.ProfileSelected.profile_type && item.planId == 505))[0];
            this.addOn6 = this.addOnDetails
                .filter(item => (item.profileType === this.ProfileSelected.profile_type && item.planId == 506))[0];
        }
    }

    getAddOnConfig(): void {
        this.myprofileService.getAddOnConfig()
            .then((result) => {
                this.addOnCharges = result.addOnCharges;
                if (this.addOnCharges) {
                    this.value501 = Object.keys(this.addOnCharges[501])[0];
                    this.value502 = Object.keys(this.addOnCharges[502])[0];
                    this.value503 = Object.keys(this.addOnCharges[503])[0];
                    this.value504 = Object.keys(this.addOnCharges[504])[0];
                    this.value505 = Object.keys(this.addOnCharges[505])[0];
                    this.value506 = Object.keys(this.addOnCharges[506])[0];
                    this.changeAddOnAmount(this.value501, 501);
                    this.changeAddOnAmount(this.value502, 502);
                    this.changeAddOnAmount(this.value503, 503);
                    this.changeAddOnAmount(this.value504, 504);
                    this.changeAddOnAmount(this.value505, 505);
                    this.changeAddOnAmount(this.value506, 506);
                }
            });
    }

    getPlanDetails(userId): void {
        this.myprofileService.getPlanDetails(userId)
            .then((result) => {
                this.addOnDetails = result.addOns;
                this.creditBalance = result.creditBalance;
                if (!this.isAddOnPage) {
                    this.checkAddOnExist(); // from dashboard checking existing add ons
                }
            });
    }

    // changing add on amount as per selection
    changeAddOnAmount(duration, type) {
        if (type === 501) {
            this.profileViewAmount = this.addOnCharges[type][duration];
        }
        if (type === 502) {
            this.topRecommendationAmount = this.addOnCharges[type][duration];
        }
        if (type === 503) {
            this.interactionAmount = this.addOnCharges[type][duration];
        }
        if (type === 504) {
            this.instaResponseAmount = this.addOnCharges[type][duration];
        }
        if (type === 505) {
            this.acceleratedMarketingAmount = this.addOnCharges[type][duration];
        }
        if (type === 506) {
            this.topBuyerSellerAmount = this.addOnCharges[type][duration];
        }
    }

    saveAddOn(data) {
        const formSelector = $('form[name="addOnForm"]');
        if (formSelector.validator('validate').has('.has-error').length || $('.role:checkbox:checked').length === 0) {
            if ($('.role:checkbox:checked').length === 0) {
                this.addOnFormError = 'Please Choose at least one add on plan';
            }
            return;
        }
        data.user_id = this.userID;
        data.profile_type = this.ProfileSelected.profile_type;
        data.profile_id = this.ProfileSelected.profile_id;
        data.membership_plan = 500; // addon plan

        // Post values here
        formSelector.find('button[type="submit"]').button('loading');
        this.http.post(this.config.apiEndpoint + '/upgrade/' + this.unique_str, data).subscribe(
            response => {
                console.log('add on save successful');
                this.responsevalue = response;
                appConfig.submitPayFORM('https://secure.payu.in/_payment', 'POST', {
                    key: this.responsevalue.merchant_key,
                    hash: this.responsevalue.hash,
                    txnid: this.responsevalue.txnId,
                    surl: this.responsevalue.surl,
                    furl: this.responsevalue.furl,
                    curl: this.responsevalue.curl,
                    amount: this.responsevalue.amount,
                    firstname: this.responsevalue.firstname,
                    email: this.responsevalue.email,
                    phone: this.responsevalue.phone,
                    productinfo: this.responsevalue.productinfo,
                    city: this.responsevalue.city,
                    country: this.responsevalue.country
                });
            },
            error => {
                console.log('add on save failed');
                formSelector.find('button[type="submit"]').button('reset');
            });
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="addOnForm"]').validator();
        }
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            $('#modalLogin_sales').modal('hide');
        }
    }

    isChecked(): void {
        this.addOnFormError = ($('.role:checkbox:checked').length === 0) ? 'Please Choose at least one add on plan' : '';
    }

}
