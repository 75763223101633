<div *ngIf="loading" class="loading">
    <div style="display:table-cell; vertical-align: middle;">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>
</div>


<div class="headbg">
    <div class="row">
        <div class="container">
        <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="page-ttl"><h1>B<span>Ex</span> Shorts</h1>
            </div>
        </div>
            <!-- <div class="serblk"> 

                
                
            <form _ngcontent-c6="" class="navbar-search search-fltr ng-untouched ng-pristine ng-valid" novalidate="" role="search">
                <div _ngcontent-c6="" class="input-group">
                    <input _ngcontent-c6="" class="form-control search-pnl" id="search" placeholder="Ex: Businesses for sale" type="text">
                   
                    <div _ngcontent-c6="" class="input-group-btn search-btn">
                        <button _ngcontent-c6="" class="btn btn-search btn-default btn-blue" type="button">
                            <span _ngcontent-c6="" class="glyphicon glyphicon-search"></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>-->
        </div>
    </div>

</div>
<!-- Search Bar Section End Here -->
<div class="cat-listing news w-height">
        <div class="container" *ngIf="bxNewsListing.length > 0">

            <div class="besxsot" *ngFor="let newsdata of bxNewsListing;  let i=index; let last = last" [isLast]="last" (lastDone)="newsList()">
                        <div class="imgblkbex"> <a href="{{newsdata.reference_page_link}}">
                        <img class="img-responsive" src="{{newsdata.image}}" />
                        </a></div>
                        <div class="contblk">
                            <div class="headtxt">
                                {{newsdata.title}}
                            </div>
                            <div class="authordetail">
                                Short by BEx Bureau / BusinessEx /{{newsdata.time}}
                            </div>
                            <p>{{ newsdata.shortDesc | stripTags }}</p>

                            read more at <a href="{{newsdata.reference_page_link}}" class="" target="_blank">{{newsdata.reference_page_name }}</a>

                        </div>
            </div>

        </div>

        <div class="container" *ngIf="bxNewsListing.length <1 && !loading">
            <div class="col-xs-12 col-sm-6 col-md-3 sec-slide-effect">
                    <div style="margin-top: 30px;">No Records Found</div>
                </div>
        </div>


        <div class="container">
            <listing-pagination
                    (goPage)="goToPage($event)"
                    (goNext)="onNext()"
                    (goPrev)="onPrev()"
                    [pagesToShow]="5"
                    [page]="currentPage"
                    [perPage]="itemsPerPage"
                    [count]="totalItems">
            </listing-pagination>
        </div>

</div>



<!-- End Start Body Part -->