import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute} from '@angular/router';
import {ContactService} from '../../../../services/contact.service';
import {APP_CONFIG} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {BUSINESS_IMAGES} from '../../../../static-data/business-images';
import {appConfig} from "../../../frontend/app.config";

@Component({
    selector: 'business-details',
    templateUrl: './business.component.html'
})
export class MyBusinessComponent implements OnInit {

    profile_str;
    jwtHelper = new JwtHelperService();
    users = {};
    token: any = localStorage.getItem('currentUser');
    decode = this.jwtHelper.decodeToken(this.token);
    profileType = this.decode.userProfile;
    profileObjects = this.profileType;
    userID = this.decode.sub;
    notifycreatedate = this.decode.notify_date;
    sellerProfileData: any = [];
    public sellerProfileDatadecode;
    public sellerMentorSupport;
    public sellerAccelSupport;
    public sellerMgtTeamDetails;
    public businessImages;
    public businessDocuments = [];
    public appConfig = appConfig;

    constructor(private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                private contactService: ContactService,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    imageFunction(parentCatId: any): any {
        const images = BUSINESS_IMAGES.filter(
            item => item.parentId === parentCatId)[0];
        return images.imageSet;
    }

    ngOnInit() {
        this.myprofileService.getsellerprofile(this.profile_str)
            .then((result) => {
                this.sellerProfileDatadecode = this.jwtHelper.decodeToken(result);
                this.sellerProfileData = this.sellerProfileDatadecode.sellerData;
                this.sellerMentorSupport = this.sellerProfileDatadecode.mentorSupport;
                this.sellerAccelSupport = this.sellerProfileDatadecode.accelSupport;
                this.sellerMgtTeamDetails = this.sellerProfileDatadecode.mgtTeamDetail;
                this.businessImages = this.sellerProfileDatadecode.businessImage;
                this.businessDocuments = this.sellerProfileDatadecode.businessDocuments;
                if (Object.keys(this.sellerProfileDatadecode.businessImage).length === 0) {
                    this.businessImages = this.imageFunction(this.sellerProfileData.parentCatId);
                }
            });
    }

}
