import {AfterViewChecked, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MyProfileService} from '../../../../services/my-profile.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from '@angular/platform-browser';
import {ContactService} from '../../../../services/contact.service';
import {APP_CONFIG, PROFESSIONAL_EXPERIENCE} from '../../../../config/app-config.constants';
import {IAppConfig} from '../../../../config/app-config.interface';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
    selector: 'incubator-details',
    templateUrl: './incubator-details.component.html'
})
export class IncubatorDetailsComponent implements OnInit, AfterViewChecked {

    profile_str;
    public incubatorDetailsdata;
    public industryCategoryData;
    public incubatorProfileSuccessError;
    public incubatorProfileFailError;
    public company_city;
    public company_state;
    public company_country;
    private isViewChecked = false;
    tags = [];
    estblish_year = [];
    i;

    getAddress(place: Object) {
        this.company_city = place['address_components'][0].long_name;
        this.company_state = place['address_components'][2].short_name;
        this.company_country = place['address_components'][3].long_name;
    }

    constructor(private meta: Meta,
                private title: Title,
                private http: HttpClient,
                private contactService: ContactService,
                private myprofileService: MyProfileService,
                private route: ActivatedRoute,
                @Inject(APP_CONFIG) private config: IAppConfig,
                @Inject(PLATFORM_ID) private platformId: Object) {
        title.setTitle('Incubator Details - BusinessEx');
        route.paramMap.subscribe(
            params => this.profile_str = params.get('sellerUniqueId')
        );
    }

    professional_exp_year = PROFESSIONAL_EXPERIENCE;

    getIndustrySector(): void {
        this.contactService.getIndustrySector()
            .then((result) => {
                this.industryCategoryData = result;
            });
    }

    getEstYear(): void {
        for (this.i = 2017; this.i > 1899; this.i--) {
            this.estblish_year.push(this.i.toString());
        }
    }

    incubatorMyAccountSubmit(data) {

        if ($('form[name="incubatorForm"]').validator('validate').has('.has-error').length) {
            return;
        }

        data.incubator_profile_str = this.profile_str;
        data.company_city = this.company_city;
        data.company_state = this.company_state;
        data.company_country = this.company_country;

        this.http.post(this.config.apiEndpoint + '/updateincubatordetails/' + this.profile_str, data).subscribe(
            data => {
                //console.log("Incubator Details Updated Successfully");
                $("#contactUsForm .form-control").val("");
                this.incubatorProfileSuccessError = data;
                $("html, body").animate({scrollTop: 0}, "slow");
                setTimeout(function () {
                    $('.msg-pnl_sales').delay(0).slideToggle('slow');
                    $('.msg-pnl_sales').delay(5000).slideToggle('slow');
                }, 50);
            },
            error => {
                //console.log("Please Enter Correct Details");
                this.incubatorProfileFailError = "Please Enter Correct Details";
            });
    }

    getincubatorDetails(profile_str: any): void {
        this.myprofileService.getincubatorDetails(this.profile_str)
            .then((result) => {
                this.incubatorDetailsdata = result;
            });
    }

    ngOnInit() {
        this.getincubatorDetails(this.profile_str);
        this.getIndustrySector();
        this.getEstYear();
    }

    ngAfterViewChecked(): void {
        if (this.isViewChecked === false && isPlatformBrowser(this.platformId)) {
            this.isViewChecked = true;
            $('form[name="incubatorForm"]').validator();
        }
    }

}
