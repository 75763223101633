import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import {Pipe} from '@angular/core';
@Pipe({
    name: 'limitTo'
})
export class LimitTo {
    transform(value: string, args: string): string {
        
        if(value == null){
            return value;
        }

        let limit = args ? parseInt(args, 10) : 10;
        
        let trail = '...';
        
        let pos = value.indexOf(' ', limit);

        if(pos==-1){
            pos = value.indexOf(',',limit);
        }

        return (value.length > limit && pos > -1) ? value.substring(0, pos) + trail : value;
    }
}
