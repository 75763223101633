
<div class="myAccount">
<div class="container">
<div class="">
<!-- Filter Panel end -->
<div class="">
<!-- Page Title -->
<div class="row">                               
<!-- Profile Detail Left Panel start here -->
    <profile-detail></profile-detail>
<!-- Profile Detail Left Panel end here -->
<div class="col-xs-12 col-sm-12 col-md-9 mdy-width-77">
<div class="bdr">   
<div class="cat-list">
    <div class="cat-sec-ttl">
        MANAGE FITM TYPE INVESTOR INFORMATION
    </div>

    <!-- search keywords start -->
    <div class="colorstrip">
        <ul>
            <li><a routerLink="/dashboard/investorConfidentials/{{profile_str}}">Confidential Informaion</a></li>
            <li><a routerLink="/dashboard/investorAdvertisement/{{profile_str}}">Advertisement Details</a></li>
            <li class="selected"><a *ngIf="bxInvestorType?.type==1" routerLink="/dashboard/investorFirmType/{{profile_str}}">Business Information</a></li>
            <li><a *ngIf="bxInvestorType?.type==2" routerLink="/dashboard/investorIndividualType/{{profile_str}}">Profile Information</a></li>
            <li><a routerLink="/dashboard/investorMultiPref/{{profile_str}}">Preferences</a></li>
        </ul>
    </div>
    <!-- search keywords end -->

</div>         
<div class="margin-20">
    <div class="row form-sec">
        <div class="col-xs-12 col-sm-12 col-md-12">
        <form name="investorForm" #investorForm="ngForm" (ngSubmit)="investorMyAccountSubmit(investorForm.value)"
              class="form-horizontal" role="form">
            
            <div class="msg-pnl_sales" *ngIf="investorProfileSuccessError" style="margin-bottom:20px; text-align:center;">{{investorProfileSuccessError}}</div> 
            <div class="msg-pnl_sales" *ngIf="investorProfileFailError" style="margin-bottom:20px; text-align:center;">{{investorProfileFailError}}</div> 
            
            <div class="sec-slide-effect">
                <!-- <div class="row">
                    <div class="col-xs-12"><div class="frm-sec-ttl">FITM TYPE INVESTOR</div></div>                                          
                </div> -->
                
            <div id="type_firm">


                <div class="row form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Firm Type<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <select class="form-control" name="firm_type" [ngModel]="investorFirmTypedata?.firm_type" data-error="Please fill out this field." required>
                                <option *ngFor="let firmType of firm_types" [value]="firmType.id" [selected]="firmType.id == 'investorFirmTypedata.firm_type'">{{firmType.name}}</option>
                            </select>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>                                          
                        </div>
                        <div class="help-block with-errors"></div> 
                    </div>
                </div>

                <div class="row form-group other-detail has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Investment Preference<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-8 col-md-8">
                        <div class="row" [ngClass]="{'has-error': isLookingFor==true}">
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="checkbox steychk">
                                    <label><input name="invest_pref"
                                                  [ngModel]="investorFirmTypedata?.invest_pref" value="1" type="checkbox"
                                                  (change)="isChecked('invest_pref','investment')"
                                    >Investment</label> 
                                    <label><input name="full_acquisition"
                                                  [ngModel]="investorFirmTypedata?.full_acquisition" value="1" type="checkbox"
                                                  (change)="isChecked('full_acquisition','acquisition')"
                                    >Full Acquisition</label>
                                </div> 
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="help-block with-errors" *ngIf="isLookingFor">Please Select atleast one</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--For Investment -->
                <div class="lookingfr investment">
                    <div class="row frm-sec-ttl">
                        <div class="col-xs-12 col-sm-6 col-md-4">For Investment</div>
                    </div>
                    <div class="input-sec">                       
                        <div class="row form-group has-feedback">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Investment Size :</label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-6 pad-bottom">
                                        <div class="form-group has-feedback">
                                        <div class="input-group">
                                            <input type="text" name="invest_size_min" [ngModel]="investorFirmTypedata?.invest_size_min" class="form-control" placeholder="Enter Min Amount" data-error="Enter Min Investment." required>
                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <div class="form-group has-feedback">
                                        <div class="input-group">
                                            <input type="text" name="invest_size_max" [ngModel]="investorFirmTypedata?.invest_size_max" class="form-control" placeholder="Enter Max Amount" data-error="Enter Max Investment." required>
                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        </div>
                                    </div>

                                </div>
                            </div>                                                
                        </div>
                        <div class="row form-group">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Investment Stake Preference :</label>
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                    <div class="input-group tl-tip">
                                    <div class="input-group-addon grey-tooltip" data-toggle="tooltip" data-placement="top" title="Percentage of business you expect to own in return of investment."><!-- <i class="fa fa-info" aria-hidden="true"></i> --></div>
                                    <input type="text" name="invest_stake" [ngModel]="investorFirmTypedata?.invest_stake" class="form-control"  placeholder="Enter %" data-error="Please fill out this field.">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!--For Acquisition -->
                <div class="lookingfr acquisition">
                    <div class="row frm-sec-ttl">
                        <div class="col-xs-12 col-sm-6 col-md-4">Acquisition</div>
                    </div>
                    <div class="input-sec">                       
                        <div class="row form-group">
                            <label class="col-xs-12 col-sm-6 col-md-4 control-label">Purchasing Capacity :</label>                                                                                        
                            <div class="col-xs-12 col-sm-6 col-md-7">
                                <!--<div class="row">                                                    -->
                                    <div class="col-xs-12 col-sm-6 col-md-6">
                                        <div class="form-group has-feedback">
                                        <div class="input-group">
                                            <input type="text" name="purchase_capacity_min" [ngModel]="investorFirmTypedata?.purchase_capacity_min" class="form-control" placeholder="Enter Min" data-error="Please fill out this field." required>
                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 has-feedback">
                                        <div class="form-group has-feedback">
                                        <div class="input-group">
                                            <input type="text" name="purchase_capacity_max" [ngModel]="investorFirmTypedata?.purchase_capacity_max" class="form-control" placeholder="Enter Max" data-error="Please fill out this field." required>
                                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        </div>
                                    </div>

                                <!--</div>-->
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="row form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">About Yourself<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <textarea name="inv_abt_urself" [ngModel]="investorFirmTypedata?.inv_abt_urself" rows="3" rows="3" class="form-control" placeholder="Enter Advertisement Headline" data-error="Please fill out this field." required></textarea>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Your LinkedIn Profile <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" name="linkedin_profile" [ngModel]="investorFirmTypedata?.linkedin_profile" class="form-control" placeholder="Enter URL">
                        </div>                                        
                    </div>
                </div>
                <!-- <div class="form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Location Preference<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Location Preference" data-error="Please fill out this field." required>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div> -->
                <div class="row form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Name<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" name="company_name" [ngModel]="investorFirmTypedata?.company_name" class="form-control" placeholder="Enter your Company Name" data-error="Please fill out this field." required>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="row form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Designation<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" name="company_designation" [ngModel]="investorFirmTypedata?.company_designation" class="form-control" placeholder="Enter your Designation" data-error="Please fill out this field." required>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="row form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Location<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" name="company_city" [ngModel]="investorFirmTypedata?.company_location" (setAddress) = "getAddress($event)" class="form-control" placeholder="Enter Your Location" data-error="Please select location" googleplace  required>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="row form-group has-feedback">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Pin Code<span class="star">*</span> <span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" name="company_pincode" [ngModel]="investorFirmTypedata?.company_pincode" class="form-control" placeholder="Enter Pin Code" data-error="Please fill out this field." required>
                            <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
                        </div>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Website<span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <input type="text" name="company_website" [ngModel]="investorFirmTypedata?.company_website" class="form-control" placeholder="Enter URL">
                        </div>                                        
                    </div>
                </div>                         
                <div class="row form-group">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Logo<span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                            <img *ngIf="investorFirmTypedata?.company_logo_path != '' && investorFirmTypedata?.company_logo_path != null" src="{{investorFirmTypedata?.company_logo_path}}" height="150" width="150">
                        <div class="input-group image-preview">
                            <input type="text" class="form-control image-preview-filename" placeholder="Accepted formats - png, jpeg, gif" disabled="disabled">
                            <input type="hidden" name="company_logo_path_old" [ngModel]="investorFirmTypedata?.company_logo_path">
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-default rm-img image-preview-clear" style="display:none;">
                                        <span class="glyphicon glyphicon-remove"></span> Clear
                                    </button>
                                <!-- image-preview-input -->
                                <div class="btn btn-default btn-blue btn-file rm-img image-preview-input">
                                    <span class="glyphicon glyphicon-folder-open" style="margin-top: 5px;"></span>
                                    <span class="image-preview-input-title" style="margin-left: 5px;">Browse</span>
                                    <input type="file" accept="image/png, image/jpeg, image/gif" (change)="logoChangeEvent($event)" name="input-img-preview"/> <!-- rename it -->
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-xs-12 col-sm-6 col-md-4 control-label">Company Summary<span>:</span></label>
                    <div class="col-xs-12 col-sm-6 col-md-7">
                        <div class="input-group">
                            <textarea rows="3" name="company_summary" [ngModel]="investorFirmTypedata?.company_summary" class="form-control" placeholder="Enter Company Summary"></textarea>
                        </div>                                        
                    </div>
                </div>                                
            </div>
            
                
            </div>    

            <div class="sec-slide-effect txt-cen">                                  
                <div class="submitfrm">
                    <button type="submit" class="btn btn-default btn-blue">SUBMIT</button>
                </div>
            </div>
        </form>

        </div>
    </div>
</div>
</div>
</div>            
</div>                                
</div>
</div>
</div>                    
</div>
<!-- End Start Body Part -->

